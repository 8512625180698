import { TooltipProps as TooltipPropsMui } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { pxToRem } from 'src/utils/getFontValue';
import { ColorSchema } from '../palette';

// ----------------------------------------------------------------------

declare module '@mui/material/Tooltip' {
  // @ts-ignore
  interface TooltipProps extends TooltipPropsMui {
    variant?: ColorSchema | 'white' | string;
  }
};

// ----------------------------------------------------------------------

const getBackgroundColor = (variant: TooltipPropsMui['variant'], theme: Theme) => {
  const isLight = theme.palette.mode === 'light';
  let color = theme.palette.common.white;
  let backgroundColor = theme.palette.grey[isLight ? 700 : 600];

  if (variant) {
    if (variant.includes('.')) {
      const splitted = variant.split('.');
      backgroundColor = theme.palette[splitted[0]]?.[splitted[1]];
    } else if (variant === 'white') {
      color = theme.palette.text.primary;
      backgroundColor = theme.palette.common.white;
    } else {
      color = theme.palette[variant as ColorSchema]?.contrastText;
      backgroundColor = theme.palette[variant as ColorSchema]?.main;
    }
  }

  return { color, backgroundColor }
};

export default function Tooltip(theme: Theme) {

  return {
    MuiTooltip: {
      defaultProps: {
        placement: 'top',
      },
      styleOverrides: {
        tooltip: ({ ownerState }) => ({
          color: getBackgroundColor(ownerState.variant, theme).color,
          backgroundColor: getBackgroundColor(ownerState.variant, theme).backgroundColor,
          borderRadius: theme.spacing(0.5),
          padding: theme.spacing(0.5, 0.75),
          opacity: 0.95,
          lineHeight: 1.5,
          fontSize: pxToRem(12),
          fontWeight: 600,
        }),
        arrow: ({ ownerState }) => ({
          opacity: 0.95,
          color: getBackgroundColor(ownerState.variant, theme).backgroundColor,
          fontSize: '8px',
          "&::before": {
            borderRadius: '2px',
          }
        })
      }
    }
  };
}
