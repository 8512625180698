import { dispatch, store } from "src/redux/store";
import UnifiedInboxApi from "src/api/unified-inbox";
import { initialState, slice } from './index'
import { PayloadAction } from "@reduxjs/toolkit";
import { ChangeableFolderPopup, Conversation, ConversationState, Folder, UnifiedInboxState } from "src/@types/unified-inbox";
import { removeDuplicatedValuesOfArray } from "src/utils/arrayUtils";
import { batch } from "react-redux";
import { format } from "date-fns-tz";

let index;
let conversations;
const changeOrder = (order, conversationId) => {
  const newOrder: any[] = [];
  newOrder.push(conversationId);
  order.forEach((id) => {
    if (id !== conversationId) {
      newOrder.push(id);
    }
  });
  return newOrder;
};

export const conversationReducers = {
  setConversations(state, action: PayloadAction<any>) {
    state.conversation = { ...state.conversation, conversations: { ...state.conversation.conversations, ...action.payload } };
  },
  setConversationsOrder(state, action: PayloadAction<any>) {
    state.conversation = {
      ...state.conversation,
      conversationsOrder: removeDuplicatedValuesOfArray(
          action.payload.isScrollingUp
          ? [...action.payload.conversationsOrder, ...state.conversation.conversationsOrder]
          : [...state.conversation.conversationsOrder, ...action.payload.conversationsOrder]
      ),
    };
  },
  nextPage(state) {
    state.conversation = {
      ...state.conversation,
      nextPageToLoad: state.conversation.nextPageToLoad + 1,
    };
  },
  prevPage(state) {
    state.conversation = {
      ...state.conversation,
      prevPageToLoad: state.conversation.prevPageToLoad - 1,
    };
  },
  setFirstPage(state, action: PayloadAction<number>) {
    state.conversation = {
      ...state.conversation,
      prevPageToLoad: action.payload,
    };
  },
  setLastPage(state, action: PayloadAction<number>) {
    state.conversation = {
      ...state.conversation,
      nextPageToLoad: action.payload,
    };
  },
  setHasConnectionProblem(state, action: PayloadAction<boolean>) {
    state.conversation = {
      ...state.conversation,
      hasConnectionProblem: action.payload,
    };
  },
  setScrollButtonBehaviour(state, action: PayloadAction<string>) {
    state.conversation = {
      ...state.conversation,
      scrollButtonBehaviour: action.payload,
    };
  },
  changeLoadingStatus(state, action: PayloadAction<boolean>) {
    state.conversation = { ...state.conversation, isLoading: action.payload };
  },
  changeLoadingMoreStatus(state, action: PayloadAction<boolean>) {
    state.conversation = { ...state.conversation, isLoadingMore: action.payload };
  },
  selectConversation(state, action: PayloadAction<number>) {
    state.conversation = { ...state.conversation, selectedConversation: state.conversation.conversations[action.payload] };
  },
  deselectConversation(state) {
    state.conversation = { ...state.conversation, selectedConversation: initialState.conversation.selectedConversation };
  },
  setOnHoverConversation(state, action: PayloadAction<any>) {
    state.conversation = {
      ...state.conversation,
      hoveredConversation: action.payload ? state.conversation.conversations[action.payload] : {},
    };
  },
  setConversationsFilter(state, action: PayloadAction<any>) {
    state.conversation = {
      ...initialState.conversation,
      filter: action.payload,
      search: state.conversation.search,
      folders: state.conversation.folders,
      unseenInboxMessagesCount: state.conversation.unseenInboxMessagesCount,
      totalUnseenMessagesCount: state.conversation.totalUnseenMessagesCount,
    };
  },
  clearConversationList(state) {
    state.conversation = {
      ...state.conversation,
      conversationsOrder: [],
      conversations: {},
      nextPageToLoad: 0,
      prevPageToLoad: 0,
    };
  },
  changeSeenHistoryLoadingStatus(state, action: PayloadAction<any>) {
    state.conversation = {
      ...state.conversation,
      conversations: {
        ...state.conversation.conversations,
        [action.payload.conversationId]: {
          ...state.conversation.conversations[action.payload.conversationId],
          loadingSeenHistory: action.payload.status,
        },
      },
    };
  },
  changeLoadingMessagesStatus(state, action: PayloadAction<any>) {
    state.conversation = {
      ...state.conversation,
      conversations: {
        ...state.conversation.conversations,
        [action.payload.conversationId]: {
          ...state.conversation.conversations[action.payload.conversationId],
          loadingMessages: action.payload.status,
        },
      },
    };
  },
  reset(state) {
    state.conversation = {
      ...initialState.conversation,
      filter: state.conversation.filter,
      search: state.conversation.search,
      scrollButtonBehaviour: state.conversation.scrollButtonBehaviour,
      selectedConversation: state.conversation.selectedConversation,
      folders: state.conversation.folders,
      unseenInboxMessagesCount: state.conversation.unseenInboxMessagesCount,
      totalUnseenMessagesCount: state.conversation.totalUnseenMessagesCount,
    };
  },
  setConversationLastMessageSeenHistory(state, action: PayloadAction<any>) {
    state.conversation = {
      ...state.conversation,
      conversations: {
        ...state.conversation.conversations,
        [action.payload.conversationId]: {
          ...state.conversation.conversations[action.payload.conversationId],
          seen_histories: action.payload.seenHistories,
        },
      },
    };
  },
  setConversationReadStatus(state, action: PayloadAction<any>) {
    state.conversation = {
      ...state.conversation,
      conversations: {
        ...state.conversation.conversations,
        [action.payload.conversationId]: {
          ...state.conversation.conversations[action.payload.conversationId],
          read_status: action.payload.readStatus,
        },
      },
    };
  },
  updateConversationDraftSummary(state, action: PayloadAction<any>) {
    state.conversation = {
      ...state.conversation,
      conversations: {
        ...state.conversation.conversations,
        [action.payload.conversationId]: {
          ...state.conversation.conversations[action.payload.conversationId],
          draft_summary: action.payload.draftSummary,
        },
      },
    };
  },
  updateConversationList(state, action: PayloadAction<any>) {
    const { conversation } = action.payload;
    if (conversation) {
      const isSelected = conversation.new_conversation_id
        === state.conversation.selectedConversation.new_conversation_id;
      const conversationId = conversation.new_conversation_id;
      state.conversation = {
        ...state.conversation,
        selectedConversation: isSelected ? conversation : state.conversation.selectedConversation,
        conversations: {
          ...state.conversation.conversations,
          [conversationId]: conversation,
        },
        conversationsOrder: changeOrder(state.conversation.conversationsOrder, conversationId),
      };
    }
    const conversationId = state.conversation.selectedConversation.new_conversation_id;
    const updatedConversation = {
      ...state.conversation.selectedConversation,
      summary: action.payload.summary,
      created_at: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      updated_at: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      updated_at_desktop: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
    };
    state.conversation = {
      ...state.conversation,
      selectedConversation: updatedConversation,
      conversations: {
        ...state.conversation.conversations,
        [conversationId]: updatedConversation,
      },
      conversationsOrder: changeOrder(state.conversation.conversationsOrder, conversationId),
    };
  },
  addToArchive(state, action: PayloadAction<{ conversationId : number }>) {
    conversations = state.conversation.conversationsOrder;
    index = conversations.indexOf(action.payload.conversationId);
    conversations.splice(index, 1);
    state.conversation = {
      ...state.conversation,
      conversationsOrder: [...conversations],
    };
  },
  addToArchiveAllConversation(state, action: PayloadAction<{ conversationId : number }>) {
    state.conversation = {
      ...state.conversation,
      conversations: {
        ...state.conversation.conversations,
        [action.payload.conversationId]:
          { ...state.conversation.conversations[action.payload.conversationId], is_archived: true },
      },
    };
  },
  removeFromArchive(state, action: PayloadAction<{ conversationId : number }>) {
    state.conversation = {
      ...state.conversation,
      conversations: {
        ...state.conversation.conversations,
        [action.payload.conversationId]:
          { ...state.conversation.conversations[action.payload.conversationId], is_archived: false },
      },
    };
  },
  removeConversationFromConversationsOrder(state, action: PayloadAction<{ conversationId : number }>) {
    conversations = state.conversation.conversationsOrder;
    index = conversations.indexOf(action.payload.conversationId);
    if (index > -1) {
      conversations.splice(index, 1);
      state.conversation = {
        ...state.conversation,
        conversationsOrder: [...conversations],
      };
    }
  },
  setSearch(state, action: PayloadAction<string>) {
    state.conversation = {
      ...state.conversation,
      search: action.payload,
    };
  },
  setShouldScrollToConversation(state, action: PayloadAction<boolean>) {
    state.conversation = {
      ...state.conversation,
      shouldScrollToConversation: action.payload,
    };
  },
  setShouldScrollToTop(state, action: PayloadAction<boolean>) {
    state.conversation = {
      ...state.conversation,
      shouldScrollToTop: action.payload,
    };
  },
  setContactOfSelectedConversation(state, action: PayloadAction<any>) {
    state.conversation = {
      ...state.conversation,
      conversations: {
        ...state.conversation.conversations,
        [action.payload.conversationId]: {
          ...state.conversation.conversations[action.payload.conversationId],
          contact_fullname: action.payload.contactFullname,
          contact: {
            ...state.conversation.selectedConversation.contact,
            ...action.payload.contact,
          },
        },
      },
      selectedConversation: {
        ...state.conversation.selectedConversation,
        contact_fullname: action.payload.contactFullname,
        contact: {
          ...state.conversation.selectedConversation.contact,
          ...action.payload.contact,
        },
      },
    };
  },
  setFolders(state: UnifiedInboxState, action: PayloadAction<Folder[]>) {
    const folderList = action.payload;
    state.conversation.folders = { 
      ...state.conversation.folders,
      list: action.payload.map((folder) => ({ ...folder, unseenMessagesCount: 0 })),
      firstInitial: false,
      defaultFolderId: folderList.find((folder) => folder.is_default)?.id || null,
    };
    state.conversation.filter = folderList.find((folder) => folder.is_default)?.name || 'inbox';
  },
  setNewFolder(state: UnifiedInboxState, action: PayloadAction<Folder>) {
    state.conversation.folders.list = [...state.conversation.folders.list, action.payload];
  },
  renameFolder(state: UnifiedInboxState, action: PayloadAction<{ id: number, newName: string }>) {
    const { folders, filter } = state.conversation;
    const folderIndex = folders.list.findIndex((folder) => folder.id === action.payload.id);
    if (folderIndex > -1) {
      if (folders.list[folderIndex].name === filter) state.conversation.filter = action.payload.newName;
      folders.list[folderIndex].name = action.payload.newName;
    }
  },
  deleteFolder(state: UnifiedInboxState, action: PayloadAction<number>) {
    const { folders, filter } = state.conversation;
    const { list, defaultFolderId } = folders;
    const folderIndex = list.findIndex((folder) => folder.id === action.payload);
    if (folderIndex > -1) {
      if (list[folderIndex].name === filter) state.conversation.filter = 'inbox';
      if (list[folderIndex].id === defaultFolderId) state.conversation.folders.defaultFolderId = null;
      list.splice(folderIndex, 1);
      state.conversation.folders.list = [...list];
    }
  },
  updateFolderPopup(state: UnifiedInboxState, action: PayloadAction<ChangeableFolderPopup>) {
    if (action.payload.hasOwnProperty('isOpen') && !action.payload.isOpen) {
      state.conversation.folders.folderPopup = initialState.conversation.folders.folderPopup;
    } else {
      state.conversation.folders.folderPopup = { ...state.conversation.folders.folderPopup, ...action.payload };
    }
  },
  moveToFolder(state: UnifiedInboxState, action: PayloadAction<{ conversationId: number, folderId: number }>) {
    const { folderId, conversationId } = action.payload;
    conversations = state.conversation.conversationsOrder;
    index = conversations.indexOf(conversationId);
    if (state.conversation.filter !== 'allConversations') {
      conversations.splice(index, 1);
      state.conversation.conversationsOrder =  [...conversations];
    } else {
      if (state.conversation.conversations[conversations[index]]?.folder_id === folderId) {
        state.conversation.conversations[conversations[index]].folder_id = null;
      } else {
        state.conversation.conversations[conversations[index]].folder_id = folderId;
      }
    }
  },
  setSelectedFolder(state: UnifiedInboxState, action: PayloadAction<number | null>) {
    state.conversation.folders.selectedFolderId = action.payload;
  },
  changeDefaultFolder(state: UnifiedInboxState, action: PayloadAction<number | null>) {
    state.conversation.folders.defaultFolderId = action.payload;
  },
  setNumberOfUnseenMessages(state: UnifiedInboxState, action: PayloadAction<[{ key: 'inbox', count: [ { count: string } ] },{ key: 'folder', count: { folder_id: number, count: string }[] }]>) {
    const inboxCount = action.payload[0].count[0].count;
    const folderCounts = action.payload[1].count;
    let totalUnSeenMessages = Number(inboxCount);
    state.conversation.unseenInboxMessagesCount = Number(inboxCount);
    folderCounts.forEach(({ folder_id, count }) => {
      const findFolderIndex = state.conversation.folders.list.findIndex((folder) => folder.id === folder_id);
      if (findFolderIndex > -1) state.conversation.folders.list[findFolderIndex].unseenMessagesCount = Number(count);
      totalUnSeenMessages += Number(count);
    })
    state.conversation.totalUnseenMessagesCount = totalUnSeenMessages;
  },
  reduceUnseenMessage(state: UnifiedInboxState, action: PayloadAction<{ conversationId: number }>) {
    const { conversationId } =action.payload;
    const { folders, conversations, unseenInboxMessagesCount } = state.conversation;
    const { selectedFolderId, list } = folders;
    if (selectedFolderId && selectedFolderId === conversations[conversationId]?.folder_id) {
      const findFolderIndex = list.findIndex((folder) => folder.id === selectedFolderId);
      if (list[findFolderIndex].unseenMessagesCount > 0) {
        state.conversation.folders.list[findFolderIndex].unseenMessagesCount -= 1;
        state.conversation.totalUnseenMessagesCount -= 1;
      }
    } else if (unseenInboxMessagesCount > 0) {
      state.conversation.unseenInboxMessagesCount -= 1;
      state.conversation.totalUnseenMessagesCount -= 1;
    }
  }
};

// ----------------------------------------------------------------------

let getConversationsController: AbortController | null = new AbortController();
export function getConversations(
  isScrollingUp = false,
  page,
  resetAll = false,
  loadMore = false,
  shouldCancelPreviousRequest,
  filter,
  search,
  hrefConversationId,
) {
  return async () => {
    if (getConversationsController && shouldCancelPreviousRequest) {
      getConversationsController.abort();
      getConversationsController = new AbortController();
    };
    dispatch(slice.actions.setHasConnectionProblem(false));
    if (loadMore) {
      dispatch(slice.actions.changeLoadingMoreStatus(true));
    } else {
      dispatch(slice.actions.changeLoadingStatus(true));
    }
    dispatch(getNumberOfUnseenMessages());
    hrefConversationId = hrefConversationId ? hrefConversationId.split('_')[0] : '';
    const { unifiedInbox: state }: { unifiedInbox: UnifiedInboxState } = store.getState();
    let { list: FoldersList } = state.conversation.folders;
    const selectedFolderId = FoldersList.find((folder) => folder.name.toLowerCase() === filter?.toLowerCase())?.id || null;
    dispatch(slice.actions.setSelectedFolder(selectedFolderId));
    UnifiedInboxApi.fetchConversations(filter, page, search, hrefConversationId, selectedFolderId || undefined, getConversationsController?.signal)
      .then((response) => {
        getConversationsController = null;
        if (response.data && response.data.length > 0) {
          const conversationsArray = response.data;
          const conversationsObject = {};
          const conversationsOrder = conversationsArray.map(
            (conversation) => conversation.new_conversation_id,
          );
          for (let i = 0; i < conversationsArray.length; i += 1) {
            conversationsObject[conversationsArray[i].new_conversation_id] = conversationsArray[i];
          }
          if (resetAll) dispatch(slice.actions.reset());
          dispatch(slice.actions.setConversations(conversationsObject));
          dispatch(slice.actions.setConversationsOrder({ conversationsOrder, isScrollingUp }));
          if (hrefConversationId) {
            const selectedConversation = response.data.find(
              (data) => data.new_conversation_id === parseInt(hrefConversationId, 10),
            );

            if (selectedConversation) {
              dispatch(slice.actions.selectConversation(selectedConversation.new_conversation_id));
              dispatch(slice.actions.setFirstPage(parseInt(selectedConversation.data_page_number, 10)));
              dispatch(slice.actions.prevPage());
              dispatch(slice.actions.setLastPage(parseInt(selectedConversation.data_page_number, 10)));
              dispatch(slice.actions.nextPage());
              dispatch(slice.actions.setShouldScrollToConversation(true));
            } else {
              window.location.href = `${document.location.href.split('#')[0]}#`;
              dispatch(slice.actions.setFirstPage(0));
              dispatch(slice.actions.setLastPage(0));
            }
          }
          if (isScrollingUp) {
            dispatch(slice.actions.setFirstPage(parseInt(response.data[0].data_page_number, 10)));
            dispatch(slice.actions.prevPage());
          } else {
            dispatch(slice.actions.setLastPage(parseInt(response.data[0].data_page_number, 10)));
            dispatch(slice.actions.nextPage());
            if (parseInt(response.data[0].data_page_number, 10) === 0) {
              dispatch(slice.actions.prevPage());
            }
          }
        }
      })
      .catch((error) => {
        dispatch(slice.actions.setHasConnectionProblem(true));
      })
      .finally(() => {
        if (!getConversationsController) {
          getConversationsController = new AbortController();
          if (loadMore) dispatch(slice.actions.changeLoadingMoreStatus(false));
          else dispatch(slice.actions.changeLoadingStatus(false));
        }
      });
  };
}

export function addToArchive(conversationId, bookingId, contactId, newConversationId, filterIsAllConversations = false) {
  return async () => {
    if (filterIsAllConversations) {
      dispatch(slice.actions.addToArchive({ conversationId }));
    } else {
      dispatch(slice.actions.addToArchiveAllConversation({ conversationId }));
    }
    UnifiedInboxApi.addToArchive({ bookingId, contactId, conversationId: newConversationId });
    dispatch(getNumberOfUnseenMessages());
  };
}

export function removeFromArchive(conversationId, bookingId, contactId, newConversationId) {
  return async () => {
    try {
      dispatch(slice.actions.removeFromArchive({ conversationId }));
      UnifiedInboxApi.removeFromArchive({ bookingId, contactId, conversationId: newConversationId });
      dispatch(getNumberOfUnseenMessages());
    } catch (error) {}
  };
}

export function moveToTrash(conversationId, bookingId, contactId, newConversationId) {
  return async () => {
    try {
      dispatch(slice.actions.removeConversationFromConversationsOrder({ conversationId }));
      UnifiedInboxApi.moveToTrash({ bookingId, contactId, conversationId: newConversationId });
      dispatch(getNumberOfUnseenMessages());
    } catch (error) {}
  };
}

export function removeFromTrash(conversationId, bookingId, contactId, newConversationId) {
  return async () => {
    try {
      dispatch(slice.actions.removeConversationFromConversationsOrder({ conversationId }));
      UnifiedInboxApi.removeFromTrash({ bookingId, contactId, conversationId: newConversationId });
      dispatch(getNumberOfUnseenMessages());
    } catch (error) {}
  };
}

export function getFolders() {
  return async () => {
    try {
      dispatch(slice.actions.changeLoadingStatus(true));
      const foldersRes = await UnifiedInboxApi.getFolders();
      dispatch(slice.actions.setFolders(foldersRes.data));
    } catch (error) {}
  }
}

export function createOrUpdateFolder(name: string, id?: number) {
  return async () => {
    try {
      dispatch(slice.actions.updateFolderPopup({ isLoading: true }));
      const checkExistFolderRes = await UnifiedInboxApi.checkExistFolder(name);
      if (checkExistFolderRes.data.result) {
        if (id) {
          const renameRes = await UnifiedInboxApi.renameFolderName(id, name);
          if (renameRes.data.result) {
            dispatch(slice.actions.renameFolder({ id, newName: name }));
          }
        } else {
          const createFolderRes = await UnifiedInboxApi.createFolder(name);
          dispatch(slice.actions.setNewFolder({ name, id: createFolderRes.data.id, is_default: false, unseenMessagesCount: 0 }));
        }
        dispatch(slice.actions.updateFolderPopup({ isOpen: false }));
      } else {
        dispatch(slice.actions.updateFolderPopup({ hasExistFolderName: true }));
      }
    } catch (error) {
      if (error.data && error.data.hasOwnProperty('result') && !error.data.result) {
        dispatch(slice.actions.updateFolderPopup({ hasExistFolderName: true }));
      }
    }
    finally {
      dispatch(slice.actions.updateFolderPopup({ isLoading: false }));
    }
  }
}

export function deleteFolder(id: number) {
  return async () => {
    try {
      dispatch(slice.actions.updateFolderPopup({ isLoading: true }));
      await UnifiedInboxApi.deleteFolder(id);
      dispatch(getNumberOfUnseenMessages());
      dispatch(slice.actions.deleteFolder(id));
      dispatch(slice.actions.updateFolderPopup({ isLoading: false, isOpen: false }));
    } catch (e) {}
  }
}

export function moveToFolder(conversationId: number, folderId: number) {
  return async () => {
    try {
      dispatch(slice.actions.moveToFolder({ conversationId, folderId }));
      await UnifiedInboxApi.moveToFolder(conversationId, folderId);
      dispatch(getNumberOfUnseenMessages());
    } catch (e) {}
  }
}

export function undoFromFolder(conversationId: number, folderId: number) {
  return async () => {
    try {
      dispatch(slice.actions.moveToFolder({ conversationId, folderId }));
      await UnifiedInboxApi.undoFromFolder(conversationId);
      dispatch(getNumberOfUnseenMessages());
    } catch (e) {}
  }
}

export function changeDefaultFolder(folderId: number | null, prevDefaultId: number | null) {
  return async () => {
    try {
      dispatch(slice.actions.changeDefaultFolder(folderId));
      await UnifiedInboxApi.changeDefaultFolder(folderId);
    } catch (e) {
      dispatch(slice.actions.changeDefaultFolder(prevDefaultId));
    }
  }
}

export function getNumberOfUnseenMessages() {
  return async () => {
    try {
      const result = await UnifiedInboxApi.getNumberOfUnseenMessages();
      dispatch(slice.actions.setNumberOfUnseenMessages(result.data));
    } catch(e) {}
  }
}

export function handleWebsocketCallback(data: string) {
  return async () => {
    const newMessage: { message: any; updated_conversation: any } = JSON.parse(data);
    const { unifiedInbox: state }: { unifiedInbox: UnifiedInboxState } = store.getState();
    const { filter, search, prevPageToLoad, folders, selectedConversation } = state.conversation;

    if (
      newMessage.updated_conversation.new_conversation_id ===
      selectedConversation?.new_conversation_id
    ) {
      dispatch(
        getConversations(
          false,
          0,
          true,
          true,
          true,
          filter,
          search,
          newMessage.updated_conversation.new_conversation_id.toString()
        )
      );
      dispatch(slice.actions.setShowNewMessagesAlert(true));
    } else if (
      filter === 'allConversations' ||
      (newMessage.updated_conversation.folder_id
        ? newMessage.updated_conversation.folder_id === folders.selectedFolderId
        : newMessage.updated_conversation.key_word === filter)
    ) {
      dispatch(
        slice.actions.removeConversationFromConversationsOrder({
          conversationId: newMessage.updated_conversation.new_conversation_id,
        })
      );
      if (prevPageToLoad > -1) {
        dispatch(
          getConversations(true, prevPageToLoad + 1, false, true, false, filter, search, '')
        );
      } else {
        try {
          // checking user has access to conversation
          const res = await UnifiedInboxApi.fetchConversations('allConversations', 0, '', newMessage.updated_conversation.new_conversation_id, undefined, undefined, 1);
          const resConversations: Conversation[] = res.data;
          if (resConversations.find((con) => con.new_conversation_id === newMessage.updated_conversation.new_conversation_id)) {
            batch(() => {
              dispatch(
                slice.actions.setConversationsOrder({
                  conversationsOrder: [newMessage.updated_conversation.new_conversation_id],
                  isScrollingUp: true,
                })
              );
              dispatch(
                slice.actions.setConversations({
                  [newMessage.updated_conversation.new_conversation_id]: newMessage.updated_conversation,
                })
              );
              dispatch(getNumberOfUnseenMessages());
            });
          }
        } catch(e) {}
      }
    } else {
      dispatch(getNumberOfUnseenMessages());
    }
    dispatch(
      slice.actions.setWebsocketMessage({
        message: newMessage.message,
        conversationId: newMessage.updated_conversation.new_conversation_id,
      })
    );
  };
}
