import UnifiedInboxApi from 'src/api/unified-inbox';
import { dispatch, store } from 'src/redux/store';
import { convertHtmlToText } from 'src/utils/convertHtmlToText';
import DOMPurify from 'dompurify';
import { setAttachment, slice } from './index';
import { draftGenerator, identifyLinksOfText, lineInTextRegExp } from 'src/utils/unifiedInbox';
import { generateUniqSerial } from 'src/utils/generateUniqSerial';
import { provideVariables } from 'src/utils/automatedMessage';
import { mergeObjectOfArrays } from 'src/utils/arrayUtils';
import { AttachmentStatus, UnifiedInboxState } from 'src/@types/unified-inbox';
import { generateEmailTemplate } from 'src/_mock/email-template-header-and-footer';
import { getNumberOfUnseenMessages } from './conversation';
import { AxiosRequestConfig } from 'axios';
import attachment from 'src/api/attachment';
import { File as FileType } from 'src/@types/common';
import { format } from 'date-fns';
import { batch } from 'react-redux';
import { Attachment } from 'src/@types/unified-inbox';
import { convertTextToHtmlEntities } from 'src/utils/stringUtils';

export function getConversationMessages(conversationId, contactId) {
  return async () => {
    dispatch(slice.actions.changeLoadingMessagesStatus({ conversationId, status: true }));
    UnifiedInboxApi.getConversationMessagesRoute(conversationId, contactId)
      .then((response) => {
        const { data } = response;
        const messages = data.filter((message) => !message.is_draft);
        const draftMessage = data.find((message) => message.is_draft);
        if (draftMessage)
          dispatch(
            slice.actions.setDraftsForConversation({
              conversationId,
              draft: { ...draftMessage.draft, attachments: draftMessage.attachments },
            })
          );
        dispatch(slice.actions.setMessagesForConversation({ conversationId, messages }));
      })
      .catch(() => {
        dispatch(slice.actions.setMessagesForConversation({ conversationId, messages: [] }));
      })
      .finally(() => {
        dispatch(slice.actions.changeLoadingMessagesStatus({ conversationId, status: false }));
      });
  };
}

export function getUserSenderEmails(conversationId) {
  return async () => {
    UnifiedInboxApi.getUserSenderEmailsRoute(conversationId)
      .then((response) => {
        dispatch(slice.actions.setEmailsForConversation(response.data));
      })
      .catch();
  };
}

export function getCcBccEmails(key, page, isGettingCc) {
  return async () => {
    if (isGettingCc) {
      dispatch(slice.actions.setCcNotFound(false));
    } else {
      dispatch(slice.actions.setBccNotFound(false));
    }
    UnifiedInboxApi.getCcBccEmailsRoute(key, page)
      .then((response) => {
        if (isGettingCc) {
          dispatch(
            slice.actions.setCcList(response.data.filter((guest) => guest?.emails?.[0]?.email))
          );
          dispatch(slice.actions.nextCcPage());
        } else {
          dispatch(
            slice.actions.setBccList(response.data.filter((guest) => guest?.emails?.[0]?.email))
          );
          dispatch(slice.actions.nextBccPage());
        }
        if (response.data.length === 0) {
          if (isGettingCc) {
            dispatch(slice.actions.setCcNotFound(true));
          } else {
            dispatch(slice.actions.setBccNotFound(true));
          }
        }
      })
      .catch();
  };
}

const handleSeenResponse = (response, state, dispatch, conversationId) => {
  dispatch(slice.actions.reduceUnseenMessage(conversationId));
  response.data.forEach((message) => {
    dispatch(slice.actions.setMessage({ message, conversationId }));
  });
  const messages = state.message.messages[conversationId];
  const incomeMessages = messages.filter((message) => message.is_income === true);
  const lastIncomeMessageId =
    incomeMessages.length > 0 ? incomeMessages[0].id : messages[0] ? messages[0].id : 0;
  const isRead = response.data.some((message) => message.id === lastIncomeMessageId);
  if (isRead) {
    dispatch(slice.actions.setConversationReadStatus({ conversationId, readStatus: 'read' }));
  }
};

export function seenMessages(lastSeenMessage, conversationId) {
  return async () => {
    dispatch(slice.actions.changeSeenHistoryLoadingStatus({ conversationId, status: true }));
    const { unifiedInbox: state } = store.getState();
    UnifiedInboxApi.getSeenMessagesRoute({
      contact: state.user.contact.id,
      last_seen_message: lastSeenMessage,
      conversation_id: conversationId,
    })
      .then((response) => {
        handleSeenResponse(response, state, dispatch, conversationId);
      })
      .catch()
      .finally(() => {
        dispatch(slice.actions.changeSeenHistoryLoadingStatus({ conversationId, status: false }));
      });
  };
}

export function seenConversation(conversationId, newConversationId) {
  return async () => {
    dispatch(slice.actions.changeSeenHistoryLoadingStatus({ conversationId, status: true }));
    const { unifiedInbox: state } = store.getState();
    dispatch(
      slice.actions.seenConversationMessages({ conversationId, contact: state.user.contact })
    );
    UnifiedInboxApi.getSeenConversationRoute({
      contact: state.user.contact.id,
      conversation: [newConversationId],
    })
      .then((response) => {
        dispatch(
          slice.actions.setConversationReadStatus({
            conversationId,
            readStatus: response.data.pop().read_status,
          })
        );
        dispatch(getNumberOfUnseenMessages());
      })
      .catch()
      .finally(() => {
        dispatch(slice.actions.changeSeenHistoryLoadingStatus({ conversationId, status: false }));
      });
  };
}

export function unseenConversation(conversationId, newConversationId) {
  return async () => {
    dispatch(slice.actions.changeSeenHistoryLoadingStatus({ conversationId, status: true }));
    const { unifiedInbox: state } = store.getState();
    dispatch(
      slice.actions.unseenConversationMessages({ conversationId, contactId: state.user.contact.id })
    );
    UnifiedInboxApi.getUnseenConversationRoute({
      contact: state.user.contact.id,
      conversation: [newConversationId],
    })
      .then((response) => {
        dispatch(
          slice.actions.setConversationReadStatus({
            conversationId,
            readStatus: response.data.pop().read_status,
          })
        );
        dispatch(getNumberOfUnseenMessages());
      })
      .catch()
      .finally(() => {
        dispatch(slice.actions.changeSeenHistoryLoadingStatus({ conversationId, status: false }));
      });
  };
}

export function sendEmail(
  subject,
  body,
  html,
  from,
  fromContact,
  to,
  cc,
  bcc,
  contactId,
  bookingId,
  conversationId,
  attachments: FileType[]
) {
  return async () => {
    const { unifiedInbox: state } = store.getState();
    const newMessageId = generateUniqSerial();
    dispatch(slice.actions.setSendEmailLoading(true));
    dispatch(slice.actions.emptyEmail());
    dispatch(
      slice.actions.updateConversationList({
        summary: subject || body || (html && html.substring(0, 30)) || '(Empty Message)',
        conversation: null,
      })
    );
    dispatch(slice.actions.setCurrentSentMessageId(newMessageId));
    dispatch(
      slice.actions.newEmail({
        subject,
        status: 'pending',
        body,
        html,
        conversationId: state.conversation.selectedConversation.new_conversation_id,
        fromContact,
        created_at: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        uuid: newMessageId,
        contact: state.conversation.selectedConversation.contact,
        userContact: state.user.contact,
        from,
        to,
        cc,
        bcc,
        attachments,
      })
    );
    UnifiedInboxApi.getSendEmailRoute({
      subject,
      body: html || body,
      from,
      fromContactId: fromContact.id,
      to,
      cc,
      bcc,
      contactId,
      bookingId,
      conversationId,
      attachments: attachments.map((file) => ({ id: file.id })),
    })
      .then((res) => {
        dispatch(
          slice.actions.setNewMessageStatus({
            conversationId: state.conversation.selectedConversation.new_conversation_id,
            messageId: newMessageId,
            status: 'sent',
            newMessageId: res.data.message_id,
          })
        );
      })
      .catch(() => {
        dispatch(
          slice.actions.setNewMessageStatus({
            conversationId: state.conversation.selectedConversation.new_conversation_id,
            messageId: newMessageId,
            status: 'failed',
          })
        );
      })
      .finally(() => {
        dispatch(slice.actions.setSendEmailLoading(false));
      });
  };
}

export function sendSms(message, to, contactId, bookingId, conversationId) {
  return async () => {
    const newMessageId = generateUniqSerial();
    const { unifiedInbox: state } = store.getState();
    dispatch(slice.actions.updateConversationList({ summary: message, conversation: null }));
    dispatch(slice.actions.setCurrentSentMessageId(newMessageId));
    dispatch(
      slice.actions.newSms({
        message,
        status: 'pending',
        conversationId: state.conversation.selectedConversation.new_conversation_id,
        fromContact: state.user.contact,
        to,
        contact: state.conversation.selectedConversation.contact,
        userContact: state.user.contact,
        created_at: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        uuid: newMessageId,
      })
    );
    UnifiedInboxApi.getSendSmsRoute({
      message,
      to,
      fromContactId: state.user.contact.id,
      contactId,
      bookingId,
      subject: '',
      conversationId,
    })
      .then((res) => {
        dispatch(
          slice.actions.setNewMessageStatus({
            conversationId: state.conversation.selectedConversation.new_conversation_id,
            messageId: newMessageId,
            status: 'sent',
            newMessageId: res.data.message_id,
          })
        );
      })
      .catch(() => {
        dispatch(
          slice.actions.setNewMessageStatus({
            conversationId: state.conversation.selectedConversation.new_conversation_id,
            messageId: newMessageId,
            status: 'failed',
          })
        );
      });
  };
}

export function sendApiMessage(messagingApiId, message, contactId, bookingId, conversationId) {
  return async () => {
    const newMessageId = generateUniqSerial();
    const { unifiedInbox: state } = store.getState();
    dispatch(slice.actions.updateConversationList({ summary: message, conversation: null }));
    dispatch(slice.actions.setCurrentSentMessageId(newMessageId));
    dispatch(
      slice.actions.newApiMessage({
        status: 'pending',
        message,
        conversationId: state.conversation.selectedConversation.new_conversation_id,
        contact: state.conversation.selectedConversation.contact,
        userContact: state.user.contact,
        fromContact: state.user.contact,
        created_at: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        uuid: newMessageId,
        channelName: state.conversation.selectedConversation.channel_name,
      })
    );
    UnifiedInboxApi.getSendApiMessageRoute({
      message,
      contactId,
      bookingId,
      fromContactId: state.user.contact.id,
      messagingApiId,
      conversationId,
    })
      .then((res) => {
        dispatch(
          slice.actions.setNewMessageStatus({
            conversationId: state.conversation.selectedConversation.new_conversation_id,
            messageId: newMessageId,
            status: 'sent',
            newMessageId: res.data.message_id,
          })
        );
      })
      .catch((error) => {
        console.error(error.response);
        dispatch(
          slice.actions.setNewMessageStatus({
            conversationId: state.conversation.selectedConversation.new_conversation_id,
            messageId: newMessageId,
            status: 'failed',
          })
        );
      });
  };
}

export function resendEmail(
  subject,
  body,
  html,
  from,
  fromContact,
  to,
  cc,
  bcc,
  contactId,
  bookingId,
  messageId,
  conversationId,
  attachments: FileType[]
) {
  return async () => {
    const { unifiedInbox: state } = store.getState();
    dispatch(slice.actions.setSendEmailLoading(true));
    dispatch(
      slice.actions.setNewMessageStatus({
        conversationId: state.conversation.selectedConversation.new_conversation_id,
        messageId,
        status: 'pending',
      })
    );
    dispatch(slice.actions.setCurrentSentMessageId(messageId));
    UnifiedInboxApi.getSendEmailRoute({
      subject,
      body: html || body,
      from,
      fromContactId: fromContact.id,
      to,
      cc,
      bcc,
      contactId,
      bookingId,
      conversationId,
      attachments: attachments.map((file) => ({ id: file.id })),
    })
      .then((res) => {
        dispatch(
          slice.actions.setNewMessageStatus({
            conversationId: state.conversation.selectedConversation.new_conversation_id,
            messageId,
            status: 'sent',
            newMessageId: res.data.message_id,
          })
        );
      })
      .catch(() => {
        dispatch(
          slice.actions.setNewMessageStatus({
            conversationId: state.conversation.selectedConversation.new_conversation_id,
            messageId,
            status: 'failed',
          })
        );
      })
      .finally(() => {
        dispatch(slice.actions.setSendEmailLoading(false));
      });
  };
}

export function resendSms(message, to, contactId, bookingId, messageId, conversationId) {
  return async () => {
    const { unifiedInbox: state } = store.getState();
    dispatch(
      slice.actions.setNewMessageStatus({
        conversationId: state.conversation.selectedConversation.new_conversation_id,
        messageId,
        status: 'pending',
      })
    );
    dispatch(slice.actions.setCurrentSentMessageId(messageId));
    UnifiedInboxApi.getSendSmsRoute({
      message,
      to,
      fromContactId: state.user.contact.id,
      contactId,
      bookingId,
      subject: '',
      conversationId,
    })
      .then((res) => {
        dispatch(
          slice.actions.setNewMessageStatus({
            conversationId: state.conversation.selectedConversation.new_conversation_id,
            messageId,
            status: 'sent',
            newMessageId: res.data.message_id,
          })
        );
      })
      .catch(() => {
        dispatch(
          slice.actions.setNewMessageStatus({
            conversationId: state.conversation.selectedConversation.new_conversation_id,
            messageId,
            status: 'failed',
          })
        );
      });
  };
}

export function resendApiMessage(
  messagingApiId,
  message,
  contactId,
  bookingId,
  messageId,
  conversationId
) {
  return async () => {
    const { unifiedInbox: state } = store.getState();
    dispatch(
      slice.actions.setNewMessageStatus({
        conversationId: state.conversation.selectedConversation.new_conversation_id,
        messageId,
        status: 'pending',
      })
    );
    dispatch(slice.actions.setCurrentSentMessageId(messageId));
    UnifiedInboxApi.getSendApiMessageRoute({
      message,
      contactId,
      bookingId,
      fromContactId: state.user.contact.id,
      messagingApiId,
      conversationId,
    })
      .then((res) => {
        dispatch(
          slice.actions.setNewMessageStatus({
            conversationId: state.conversation.selectedConversation.new_conversation_id,
            messageId,
            status: 'sent',
            newMessageId: res.data.message_id,
          })
        );
      })
      .catch((error) => {
        console.error(error.response);
        dispatch(
          slice.actions.setNewMessageStatus({
            conversationId: state.conversation.selectedConversation.new_conversation_id,
            messageId,
            status: 'failed',
          })
        );
      });
  };
}

export function getEmailTemplates(messageType: string) {
  return async () => {
    try {
      const { unifiedInbox: state }: { unifiedInbox: UnifiedInboxState } = store.getState();
      dispatch(slice.actions.setTemplateListIsLoadingStatus(true));
      let { variables, languages } = state.message.templateForm;
      const emailTemplatesRes = await UnifiedInboxApi.getEmailTemplatesRouteV2(
        messageType.toLowerCase()
      );
      if (languages === null) {
        const automatedVariablesRes = await UnifiedInboxApi.getAutomatedVariables(
          state.conversation.selectedConversation.booking_id
        );
        variables = mergeObjectOfArrays(provideVariables(automatedVariablesRes.data));
        dispatch(slice.actions.setVariables(variables));
        const languagesRes = await UnifiedInboxApi.getLanguages();
        languages = languagesRes.data;
        dispatch(slice.actions.setLanguages(languages));
      }
      dispatch(slice.actions.setTemplatesList({ ...emailTemplatesRes.data, variables, languages }));
    } catch (e) {
    } finally {
      dispatch(slice.actions.setTemplateListIsLoadingStatus(false));
    }
  };
}

export function getRenderedEmailTemplate() {
  return async () => {
    const { unifiedInbox: state }: { unifiedInbox: UnifiedInboxState } = store.getState();
    dispatch(slice.actions.setTemplateIsLoading(true));
    dispatch(slice.actions.setIsInsertingTemplate(false));
    dispatch(slice.actions.setIsHtmlTemplate(false));
    const { selectedTemplate, selectedTemplateIsHtml, selectedTemplateContentId } =
      state.message.templateForm;
    const { booking_id, new_conversation_id } = state.conversation.selectedConversation;
    const isHtml = selectedTemplateIsHtml.changeable;
    try {
      if (selectedTemplate) {
        if (selectedTemplate.oldTemplateData) {
          const renderedOldTemplateRes = await UnifiedInboxApi.getRenderedEmailRoute(
            selectedTemplate.id,
            booking_id
          );
          const { subject, content, customized_plaintext } = renderedOldTemplateRes.data;
          const pureContent = DOMPurify.sanitize(content) || '';
          const plainTextContent = customized_plaintext
            ? customized_plaintext.trim()
            : convertHtmlToText(pureContent)?.trim();
          const convertedTextToHtml = plainTextContent.replace(/(?:\r\n|\r|\n)/g, '<br>');
          dispatch(slice.actions.setIsInsertingTemplate(true));
          dispatch(slice.actions.setEmailSubject(subject || ''));
          dispatch(
            slice.actions.setMessageContents({
              messageContent: plainTextContent || '',
              htmlMessageContent: isHtml ? pureContent : convertedTextToHtml,
            })
          );
          dispatch(
            slice.actions.setTemplateContent({
              isHtml,
              ...renderedOldTemplateRes.data,
            })
          );
        } else {
          const renderedAutomatedTemplateRes = await UnifiedInboxApi.getRenderedEmailRouteV2(
            selectedTemplate.children[0].id,
            booking_id
          );
          const findExistContent = renderedAutomatedTemplateRes.data?.find(
            (content) => content.body_id === selectedTemplateContentId
          );
          if (findExistContent) {
            const { subject, content, customized_plaintext, header, footer } = findExistContent;
            const pureContent = DOMPurify.sanitize(content) || '';
            const plainTextContent = customized_plaintext
              ? customized_plaintext.trim()
              : convertHtmlToText(pureContent)?.trim();
            const convertedTextToHtml = plainTextContent.replace(/(?:\r\n|\r|\n)/g, '<br>');
            dispatch(slice.actions.setIsInsertingTemplate(true));
            dispatch(slice.actions.setEmailSubject(subject || ''));
            dispatch(
              slice.actions.setMessageContents({
                messageContent: plainTextContent || '',
                htmlMessageContent: isHtml
                  ? generateEmailTemplate(header || '', pureContent, footer || '')
                  : convertedTextToHtml,
              })
            );
            dispatch(
              slice.actions.setTemplateContent({
                isHtml,
                ...findExistContent,
              })
            );
            findExistContent.attachments.forEach((file: any) => {
              const { id, file_name, file_size, type } = file;
              const newName = `Zeevou Unified inbox - ${file_name}`;
              const controller = new AbortController();
              const attachment: Attachment = {
                id,
                name: newName,
                size: file_size,
                controller,
                status: 'draft',
                type,
                uploadedPercent: 100,
                uploadedFileDetail: file,
              };
              batch(() => {
                dispatch(setAttachment(attachment));
              });
            });
          }
        }
        if (isHtml) dispatch(slice.actions.setIsHtmlTemplate(true));
      }
    } catch (error) {
      if (error.response && error.response.data === 'failed_to_render') {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        dispatch(deleteDraft(new_conversation_id, false, false));
        dispatch(slice.actions.emptyMessageContents());
        dispatch(slice.actions.setEmailSubject(''));
      }
    } finally {
      dispatch(slice.actions.setTemplateIsLoading(false));
    }
  };
}

export function convertAndSetMessageContents(messageValue, isValueOfHtmlEditor) {
  return async () => {
    if (isValueOfHtmlEditor) {
      const cleanValue = DOMPurify.sanitize(messageValue);
      const plainText = convertHtmlToText(cleanValue);
      dispatch(
        slice.actions.setMessageContents({
          messageContent: plainText,
          htmlMessageContent: cleanValue,
        })
      );
    } else {
      const valueWithConvertedLinks = identifyLinksOfText(convertTextToHtmlEntities(messageValue));
      const valueWithConvertedNewLines = valueWithConvertedLinks.replace(lineInTextRegExp, '<br>');
      dispatch(
        slice.actions.setMessageContents({
          messageContent: messageValue,
          htmlMessageContent: valueWithConvertedNewLines,
        })
      );
    }
  };
}

export function saveDraft(conversationId, details) {
  return async () => {
    const draftRequestBody = draftGenerator(details, true);
    const draftForConversation = draftGenerator(details, false);
    dispatch(
      slice.actions.setDraftsForConversation({ conversationId, draft: draftForConversation })
    );
    UnifiedInboxApi.getSaveDraftRoute(conversationId, draftRequestBody)
      .then(() => {
        draftRequestBody.attachments?.forEach((attach) => {
          dispatch(slice.actions.updateAttachment({ id: attach.id, update: { status: 'draft' } }));
        });
      })
      .catch();
  };
}

export function deleteDraft(
  conversationId,
  shouldRemoveConversationFromDraft,
  shouldDeselectConversation,
  stillAttachmentsInDraft: boolean = false
) {
  return async () => {
    const { unifiedInbox: state } = store.getState();
    const { selectedConversation } = state.conversation;
    const isSelected = selectedConversation.new_conversation_id === conversationId;
    const isInsideDraftFolder = state.conversation.filter === 'draft';
    dispatch(slice.actions.removeConversationDraft(conversationId));
    UnifiedInboxApi.getDeleteDraftRoute(conversationId)
      .then(() => {
        if (isInsideDraftFolder && shouldRemoveConversationFromDraft) {
          dispatch(slice.actions.removeConversationFromConversationsOrder(conversationId));
        }
        if (isSelected && shouldDeselectConversation) {
          dispatch(slice.actions.deselectConversation());
        }
        if (stillAttachmentsInDraft) dispatch(slice.actions.setCanSaveAttachmentsInDraft(true));
      })
      .catch();
  };
}

export function setDraftDetail(draft) {
  return async () => {
    const { unifiedInbox: state }: { unifiedInbox: UnifiedInboxState } = store.getState();
    const { selectedConversation } = state.conversation;
    const { emails, phones } = selectedConversation?.contact;
    if (draft) {
      const { recipients, attachments } = draft;
      const { showCc, showBcc } = state.message;
      if (recipients) {
        const cc: any[] = [];
        const bcc: any[] = [];
        const ccChips: any[] = [];
        const bccChips: any[] = [];
        recipients.forEach((recipent) => {
          switch (recipent.type) {
            case 'to': {
              if (emails && emails.length > 0) {
                const foundEmail = emails.find((item) => item.email === recipent.content);
                if (foundEmail) dispatch(slice.actions.setEmailTo(recipent.content));
              }
              break;
            }
            case 'phone':
              if (phones && phones.length > 0) {
                const foundPhone = phones.find((item) => item.phone === recipent.content);
                if (foundPhone) dispatch(slice.actions.setSmsTo(recipent.content));
              }
              break;
            case 'cc':
              cc.push(recipent.content);
              ccChips.push({
                ...recipent,
                id: recipent.contact_id,
                isValid: recipent.is_valid,
                isOutLined: recipent.is_outlined,
                emails: [{ email: recipent.content }],
              });
              if (!showCc) dispatch(slice.actions.setShowCc(true));
              break;
            case 'bcc':
              bcc.push(recipent.content);
              bccChips.push({
                ...recipent,
                id: recipent.contact_id,
                isValid: recipent.is_valid,
                isOutLined: recipent.is_outlined,
                emails: [{ email: recipent.content }],
              });
              if (!showBcc) dispatch(slice.actions.setShowBcc(true));
              break;
            default:
              break;
          }
        });
        dispatch(slice.actions.setEmailCc(cc));
        dispatch(slice.actions.setEmailBcc(bcc));
        dispatch(slice.actions.setCcChips(ccChips));
        dispatch(slice.actions.setBccChips(bccChips));
        dispatch(slice.actions.setEmailSubject(draft.draft_subject));
        dispatch(slice.actions.setMessageType(draft.message_type));
        dispatch(convertAndSetMessageContents(draft.draft_text, true));
        if (draft.draft_has_template) {
          dispatch(
            slice.actions.setTemplateContent({
              isHtml: true,
              subject: draft.draft_subject,
              content: draft.draft_text,
            })
          );
          dispatch(slice.actions.setIsHtmlTemplate(true));
        } else {
          dispatch(slice.actions.setTemplateContent(null));
          dispatch(slice.actions.setIsHtmlTemplate(false));
        }
        attachments?.forEach((attachment: FileType) => {
          const { id, mime_type, original_file_name, file_size } = attachment;
          if (!state.message.attachFiles.attachments.find((attach) => attach.id === id)) {
            dispatch(
              slice.actions.setAttachment({
                id,
                name: original_file_name,
                size: file_size,
                type: mime_type,
                controller: null,
                uploadedPercent: 0,
                uploadedFileDetail: attachment,
                status: 'draft',
              })
            );
          }
        });
      }
    }
  };
}

export const uploadAttachment =
  (file: File, id: string, name: string, signal: AbortSignal) => async () => {
    let timeInterval: NodeJS.Timer | undefined;
    try {
      let uploadedPercent = 0;
      const uploadProgressFunc = () => {
        /** Assuming client's upload speed is 1 MB/s */
        const progressPerSecond = Math.floor(((1000000 / (file.size || 1)) * 100) / 2);
        if (!timeInterval) {
          timeInterval = setInterval(() => {
            const randomNumber = Math.random() < 0.5 ? -1 : 1;
            uploadedPercent =
              uploadedPercent + progressPerSecond >= 95
                ? 95
                : uploadedPercent + progressPerSecond + randomNumber;
            dispatch(slice.actions.updateAttachment({ id, update: { uploadedPercent } }));
          }, 1000);
        }
      };
      const formData = new FormData();
      formData.append('file', file, name);
      const config: AxiosRequestConfig<FormData> = {
        signal,
        onUploadProgress: (progressEvent: ProgressEvent) => {
          uploadProgressFunc();
        },
      };
      const response = await attachment.uploadFile(formData, config);
      dispatch(
        slice.actions.updateAttachment({
          id,
          update: {
            id: response.data.id,
            status: 'uploaded',
            controller: null,
            uploadedPercent: 100,
            uploadedFileDetail: response.data,
          },
        })
      );
      if (response.data) dispatch(slice.actions.setCanSaveAttachmentsInDraft(true));
    } catch (e) {
      dispatch(slice.actions.updateAttachment({ id, update: { status: 'error' } }));
    } finally {
      if (timeInterval) {
        clearInterval(timeInterval);
        timeInterval = undefined;
      }
    }
  };

export const removeAttachment =
  (id: number | string, controller: AbortController | null, attachmentStatus: AttachmentStatus) =>
  async () => {
    try {
      if (controller) controller.abort();
      dispatch(
        slice.actions.updateAttachment({ id, update: { status: 'removing', controller: null } })
      );
      dispatch(slice.actions.removeAttachment(id));
      if (['draft', 'uploaded'].includes(attachmentStatus))
        dispatch(slice.actions.setCanSaveAttachmentsInDraft(true));
    } catch (e) {}
  };
