import axios from '../utils/axios';
function notificationsAPI() {
  return {
    fetchNotificationList: (pageNumber: number, language: string) => {
      const url = '/apis/notification_bell/get_notification_list';
      return axios.get(url, { params: { page: pageNumber, limit: 5, language } });
    },
    fetchNotificationListAmount: () => axios.get('/apis/notification_bell/get_unread_amount'),
    updateNotificationRead: (body: {is_read: boolean}) => {
      const url = '/apis/notification_bell/read_and_unread_all_notification';
      return axios.post(url, body)
    }
  };
}

export default notificationsAPI();
