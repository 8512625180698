import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
  Slide,
  Button,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Iconify from '../../../../../components/Iconify';
import useLocales from '../../../../../hooks/useLocales';
import DateRangePicker from 'src/components/date-picker/DateRangePicker';
import React, { forwardRef, ReactElement, Ref, useEffect, useState } from 'react';
import GuestsField from 'src/sections/wizard/booking/components/search/GuestsField';
import SearchLocation, { SearchOption } from '../../../../../components/SearchLocation';
import { CityOption, filters } from '../../../../../_mock/searchOptions';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { TransitionProps } from '@mui/material/transitions';
import { debounce } from 'lodash';
import BookingApi from '../../../../../api/booking';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import addDays from 'date-fns/addDays';
import useResponsive from 'src/hooks/useResponsive';

type Props = {
  open: boolean;
  handleClose: () => void;
  handleSearch: Function;
  dialogTitle: string;
  arrivalDate?: Date | null;
  departureDate?: Date | null;
  destination?: SearchOption | null;
  defaultFilter?: SearchOption | null;
  adults?: number;
  children?: number;
  units?: number;
};
const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    maxWidth: 'unset',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    padding: theme.spacing(3),
    position: 'absolute',
    top: 40,
  },
}));
const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  padding: theme.spacing(0.5),
}));
const StyledIconButton = styled(Button)(({ theme }) => ({
  minWidth: 48,
  height: 48,
  padding: theme.spacing(0),
  borderRadius: 8,
  '& .MuiButton-endIcon': {
    margin: theme.spacing(0),
  },
}));

export default function SearchDialog({
  open,
  handleClose,
  handleSearch,
  dialogTitle,
  arrivalDate = null,
  departureDate = null,
  destination = null,
  defaultFilter = null,
  adults = 1,
  children = 0,
  units = 1,
}: Props) {
  const theme = useTheme();
  const isMobile = useResponsive('down', 'sm');
  const { translate } = useLocales('bookingWizard');
  const [searchValue, setSearchValue] = React.useState<string>('');

  const onOptionSelect = (option: SearchOption) => {
    setFieldValue('selectedOption', option);
  };
  const onFilterChange = (option: SearchOption | null) => {
    setFieldValue('filter', option ?? null);
  };
  const handleGuestsChange = (guestsNum: number, childrenNum: number, unitsNum: number) => {
    setFieldValue('guestsNum', guestsNum);
    setFieldValue('childrenNum', childrenNum);
    setFieldValue('unitsNum', unitsNum);
    setTimeout(() => setFieldTouched('guestsNum', true));
  };

  const onClose = () => {
    setSearchLocationError(false);
    resetForm();
    handleClose();
  };

  const [locationDropdownLoading, setLocationDropdownLoading] = useState<boolean>(true);
  const [searchLocationError, setSearchLocationError] = useState<boolean>(false);
  const [locations, setLocations] = useState<SearchOption[]>([]);

  function loadLocationOptions(search = '', type = '', page = 1, offset = 50, append = false) {
    if (search !== '') {
      setSearchLocationError(false);
      BookingApi.searchLocation(type !== 'undefined' ? type : '', search)
        .then((response) => {
          setLocations(append ? [...locations, ...response.data] : response.data);
          setLocationDropdownLoading(false);

          if (append || response.data.length > 0) {
            setSearchLocationError(false);
          } else {
            setSearchLocationError(true);
          }
        })
        .catch((error) => {
          setSearchLocationError(true);
          console.error(error);
        });
    }
  }

  const inputLocationChangeHandler = React.useRef(
    debounce((type = '', search = '') => loadLocationOptions(search, type), 500)
  ).current;
  const locationLoader = (type = '', search = '') => {
    setSearchValue(search);
    setLocationDropdownLoading(true);
    setLocations([]);
    inputLocationChangeHandler(type, search);
  };

  useEffect(() => {
    loadLocationOptions();
  }, []);

  const handleDateValue = (newValue) => {
    setFieldValue('arrivalDate', newValue[0]);
    setFieldValue('departureDate', newValue[1]);
  };

  const disableDate = (date: Date) =>
    date.toISOString() === values.arrivalDate?.toISOString() ||
    date.toISOString() === values.departureDate?.toISOString() ||
    false;

  const SearchSchema = Yup.object().shape({
    arrivalDate: Yup.date().required(),
    departureDate: Yup.date().required(),
    guestsNum: Yup.number()
      .integer()
      .min(1, translate('bookingWizard.accommodationType.errors.min_adult_guest')),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      arrivalDate: arrivalDate,
      departureDate: departureDate,
      guestsNum: adults,
      childrenNum: children,
      unitsNum: units,
      selectedOption: destination,
      filter: defaultFilter,
    },
    validationSchema: SearchSchema,
    onSubmit: (values) => {
      if (values.selectedOption && values.selectedOption.type !== 'undefined') {
        handleSearch(
          values.selectedOption,
          [values.arrivalDate, values.departureDate],
          values.guestsNum,
          values.childrenNum,
          values.unitsNum
        );
        resetForm();
      } else {
        handleSearch(
          values.selectedOption,
          [values.arrivalDate, values.departureDate],
          values.guestsNum,
          values.childrenNum,
          values.unitsNum,
          searchValue
        );
        resetForm();
      }
      onClose();
    },
  });
  const { setFieldValue, setFieldTouched, values, errors, isSubmitting, isValid, resetForm } =
    formik;

  return (
    <StyledDialog open={open} TransitionComponent={Transition} onClose={onClose}>
      <StyledDialogContent>
        <FormikProvider value={formik}>
          <Form autoComplete="off" onSubmit={formik.handleSubmit}>
            <Box display="flex" flexDirection="column" gap={theme.spacing(0.5)}>
              <Box display="flex" justifyContent={'space-between'} mb={2}>
                <Typography sx={{ letterSpacing: 0.15, color: theme.palette.text.secondary }}>
                  {dialogTitle}
                </Typography>
                <IconButton onClick={onClose} sx={{ padding: theme.spacing(0) }}>
                  <Iconify
                    icon="ic:round-close"
                    width={isMobile ? 20 : 24}
                    height={isMobile ? 20 : 24}
                  />
                </IconButton>
              </Box>
            </Box>
            <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={theme.spacing(3)}>
              <Box sx={{ width: 280, '& > div': { padding: theme.spacing(0) } }}>
                <Box sx={{ mb: 0.5, ml: 1, typography: 'body2', fontWeight: 600 }}>
                  {translate('bookingWizard.search.location')}
                </Box>
                <SearchLocation
                  data={locations}
                  defaultInputValue={
                    values.selectedOption?.destination !== 'undefined'
                      ? values.selectedOption?.destination
                      : ''
                  }
                  filters={filters}
                  filter={values.filter?.type !== 'undefined' ? values.filter : null}
                  setFilter={onFilterChange}
                  onOptionSelect={onOptionSelect}
                  onFilterChange={onFilterChange}
                  loading={locationDropdownLoading}
                  onInputValueChange={(event, value) => locationLoader(values.filter?.type, value)}
                  renderOption={(props, option: CityOption, { inputValue }) => {
                    const matches = match(option.destination, inputValue, {
                      insideWords: true,
                      findAllOccurrences: true,
                    });
                    const parts = parse(option.destination, matches);

                    return (
                      <li {...props} key={option.id}>
                        <Stack width={'100%'}>
                          {option.variant === 'filter' ? (
                            <Typography variant={'body1'}>
                              {translate(`bookingWizard.searchLocationType.${option.type}`)}
                            </Typography>
                          ) : (
                            <>
                              <Stack direction={'row'}>
                                <Typography variant="subtitle1" noWrap flexGrow={1}>
                                  {parts.map((part, index) => (
                                    <span
                                      key={index}
                                      style={{ fontWeight: part.highlight ? 400 : 700 }}
                                    >
                                      {part.text}
                                    </span>
                                  ))}
                                </Typography>
                                <Typography
                                  variant="body1"
                                  color={'text.secondary'}
                                  flexShrink={0}
                                  ml={1}
                                >
                                  {translate(`bookingWizard.searchLocationType.${option.type}`)}
                                </Typography>
                              </Stack>
                              <Typography variant="caption" color={'text.secondary'}>
                                {option.parent}
                              </Typography>
                            </>
                          )}
                        </Stack>
                      </li>
                    );
                  }}
                  error={searchLocationError}
                />
              </Box>
              <Box sx={{ maxWidth: 280 }}>
                <DateRangePicker
                  value={[values.arrivalDate, values.departureDate]}
                  onChange={handleDateValue}
                  maxDate={addDays(new Date(), 730)}
                  shouldDisableDate={disableDate}
                  slotProps={{ textField: { error: false } }}
                />
              </Box>
              <Box>
                <Box sx={{ mb: 0.5, ml: 1, typography: 'body2', fontWeight: 600 }}>
                  {translate('bookingWizard.search.guests')}
                </Box>
                <GuestsField
                  onChange={handleGuestsChange}
                  adults={values.guestsNum}
                  children={values.childrenNum}
                  units={values.unitsNum}
                  error={Boolean(errors.guestsNum)}
                />

                {errors.guestsNum ? (
                  <Typography variant="caption" color="error">
                    {errors.guestsNum}
                  </Typography>
                ) : null}
              </Box>
              <Box sx={{ mt: 3.3 }}>
                <StyledIconButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting || !isValid}
                  fullWidth={isMobile}
                  endIcon={<Iconify icon={'eva:search-fill'} />}
                />
              </Box>
            </Box>
          </Form>
        </FormikProvider>
      </StyledDialogContent>
    </StyledDialog>
  );
}
