import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  size?: number;
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, size = 40, sx }: Props) {
  const theme = useTheme();
  
  
  
  

  const logo = (
    <Box sx={{ width: size, height: size, ...sx }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 59 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.5 59C45.7924 59 59 45.7924 59 29.5C59 13.2076 45.7924 0 29.5 0C13.2076 0 0 13.2076 0 29.5C0 45.7924 13.2076 59 29.5 59Z"
          fill="#4A148C"
        />
        <path
          d="M22.4968 37.0034C23.8509 35.6209 27.1394 32.2785 28.7982 30.5872C29.4061 29.9681 29.7476 29.129 29.7476 28.2542C29.7476 27.3794 29.4061 26.5404 28.7982 25.9213C28.1905 25.3033 27.3664 24.9558 26.5069 24.9548C25.6474 24.9539 24.8226 25.2997 24.2136 25.9164C22.6458 27.5035 19.5865 30.6157 18.6318 31.6335C17.3639 32.9236 16.6046 34.6411 16.4972 36.4616C16.4306 37.5561 16.6244 38.6508 17.0621 39.6529C17.4999 40.6551 18.1687 41.5353 19.0119 42.219C20.1958 43.2599 21.6973 43.8526 23.2619 43.8966C23.2619 43.8553 23.2667 43.8347 23.2619 43.8317C23.1651 43.7678 23.0684 43.7127 22.9717 43.6468C22.3059 43.254 21.7686 42.6706 21.4267 41.9694C21.0848 41.2682 20.9534 40.4803 21.0489 39.7037C21.2132 38.6616 21.7243 37.7084 22.4968 37.0034Z"
          fill="#FFC701"
        />
        <path
          d="M31.2632 15.0328C30.8062 14.8976 30.3303 14.8405 29.8549 14.8637H21.6819C20.4417 14.8486 19.2446 15.3252 18.3443 16.1926C17.444 17.0599 16.9115 18.2496 16.8596 19.5095C16.8078 20.7693 17.2407 22.0002 18.0666 22.9409C18.8925 23.8816 20.0463 24.4583 21.2834 24.5485C22.5012 24.6105 25.3854 24.5879 25.3854 24.5879C25.3854 24.5879 29.5589 20.3743 31.502 18.3929C31.7991 18.1031 32.0471 17.7656 32.2362 17.3938C32.3666 17.1806 32.445 16.9388 32.4647 16.6886C32.4844 16.4383 32.445 16.1869 32.3497 15.9553C32.2543 15.7237 32.1059 15.5187 31.9167 15.3574C31.7275 15.196 31.5031 15.0831 31.2622 15.0279L31.2632 15.0328Z"
          fill="#FFC701"
        />
        <path
          d="M36.9306 21.7543C35.5765 23.1368 32.288 26.4792 30.6302 28.1725C30.3289 28.4787 30.0898 28.8424 29.9267 29.2426C29.7636 29.6428 29.6797 30.0717 29.6797 30.5049C29.6797 30.9381 29.7636 31.3671 29.9267 31.7673C30.0898 32.1675 30.3289 32.5311 30.6302 32.8374C31.2375 33.4554 32.0611 33.8031 32.9203 33.8042C33.7795 33.8053 34.604 33.4597 35.2128 32.8433C36.7807 31.2562 39.84 28.144 40.7946 27.1262C42.0626 25.8353 42.822 24.1172 42.9293 22.2961C42.9958 21.2016 42.8019 20.107 42.3642 19.1048C41.9265 18.1026 41.2577 17.2224 40.4145 16.5387C39.2305 15.498 37.7291 14.9053 36.1646 14.8611C36.1646 14.9014 36.1598 14.9221 36.1646 14.926C36.2613 14.9879 36.358 15.045 36.4547 15.1108C37.1208 15.5034 37.6583 16.0868 38.0002 16.788C38.3422 17.4893 38.4734 18.2774 38.3776 19.054C38.2131 20.0958 37.7024 21.0489 36.9306 21.7543Z"
          fill="#FFC701"
        />
        <path
          d="M28.1711 43.722C28.6281 43.8572 29.104 43.9143 29.5794 43.8911H37.7523C38.9955 43.9108 40.197 43.4363 41.101 42.5685C42.005 41.7008 42.5399 40.5084 42.5919 39.2455C42.6438 37.9827 42.2087 36.7491 41.3791 35.8076C40.5496 34.8661 39.3912 34.2912 38.1508 34.2053C36.9331 34.1443 34.0489 34.1669 34.0489 34.1669C34.0489 34.1669 29.8754 38.3805 27.9322 40.3619C27.635 40.6515 27.3869 40.9891 27.1981 41.361C27.0689 41.574 26.9915 41.8153 26.9724 42.0648C26.9533 42.3143 26.9931 42.5648 27.0883 42.7956C27.1835 43.0264 27.3315 43.2307 27.52 43.3917C27.7085 43.5526 27.9321 43.6655 28.1721 43.721L28.1711 43.722Z"
          fill="#FFC701"
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
