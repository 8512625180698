import { dispatch } from '../store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// api
import accountAPI from 'src/api/account';
// utils
import { billingSettingsNormalizer, organisationNormalizer } from 'src/utils/account';
// @types
import { BillingSettings, Brand, Currency, OrganisationDetails, PageState} from 'src/@types/account';
import { UserAddressProps, UserCardProps } from 'src/@types/user';

const initialState: PageState = {
  loading: false,
  organisationDetails: null,
  billingSettings: {
    contactEmail: null,
    customerId: 0,
    defaultBillingAddress: null,
    defaultCard: null,
    paymentMethod: '',
  },
  drawers: {
    addBrandOpen: false,
  },
  dialogs: {
    addBrandOpen: false,
    deleteAccountOpen: false,
  },
};

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    setOrganisationDetails(
      state,
      action: PayloadAction<{ organisationDetails: OrganisationDetails | null }>
    ) {
      state.organisationDetails = action.payload.organisationDetails;
    },

    setOrganisationName(state, action: PayloadAction<{ newName: string }>) {
      if (state.organisationDetails) state.organisationDetails.name = action.payload.newName;
    },

    setCompanyDetails(
      state,
      action: PayloadAction<{
        currency: Currency;
        registeredName: string;
        registeredNumber: string | null;
        dataProtectionNumber: string | null;
      }>
    ) {
      if (state.organisationDetails) {
        state.organisationDetails.currency = action.payload.currency;
        state.organisationDetails.registeredName = action.payload.registeredName;
        state.organisationDetails.registeredNumber = action.payload.registeredNumber;
        state.organisationDetails.dataProtectionNumber = action.payload.dataProtectionNumber;
      }
    },

    setOrganisationBrandName(state, action: PayloadAction<{ brandId: number; newName: string }>) {
      if (state.organisationDetails) {
        const foundIndex = state.organisationDetails.brands.findIndex(
          (brand) => brand.id === action.payload.brandId
        );
        if (foundIndex >= 0) {
          state.organisationDetails.brands[foundIndex].name = action.payload.newName;
        }
      }
    },

    setAddBrandDrawerOpen(state, action: PayloadAction<boolean>) {
      state.drawers.addBrandOpen = action.payload;
    },

    setAddBrandDialogOpen(state, action: PayloadAction<boolean>) {
      state.dialogs.addBrandOpen = action.payload;
    },

    setDeleteAccountDialogOpen(state, action: PayloadAction<boolean>) {
      state.dialogs.deleteAccountOpen = action.payload;
    },

    setBrands(state, action: PayloadAction<Brand[]>) {
      if (state.organisationDetails) {
        state.organisationDetails.brands = action.payload;
      }
    },

    setBillingSettings(state, action: PayloadAction<BillingSettings>) {
      state.billingSettings = action.payload;
    },

    updateBillingContact(state, action: PayloadAction<string | null>) {
      state.billingSettings.contactEmail = action.payload;
    },

    updateDefaultBillingAddress(state, action: PayloadAction<UserAddressProps | null>) {
      state.billingSettings.defaultBillingAddress = action.payload;
    },

    updateDefaultCard(state, action: PayloadAction<UserCardProps | null>) {
      state.billingSettings.defaultCard = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setLoading,
  setOrganisationDetails,
  setOrganisationName,
  setCompanyDetails,
  setOrganisationBrandName,
  setAddBrandDrawerOpen,
  setAddBrandDialogOpen,
  setDeleteAccountDialogOpen,
  setBrands,
  updateBillingContact,
  updateDefaultBillingAddress,
  updateDefaultCard,
} = slice.actions;

export function getOrganisationDetails() {
  return async () => {
    dispatch(slice.actions.setLoading(true));
    try {
      const response = await accountAPI.getOrganisationDetail();
      dispatch(
        slice.actions.setOrganisationDetails({
          organisationDetails: response.data ? organisationNormalizer(response.data) : null,
        })
      );
      dispatch(
        slice.actions.setBillingSettings(billingSettingsNormalizer(response.data.billing_setting))
      )
    } catch (error) {}
    dispatch(slice.actions.setLoading(false));
  };
}

export function updateOrganisationName(
  organisationId: number,
  organisationNewName: string,
  setLoading: (succeed: boolean) => void,
  callback: (status: boolean, error: string | null) => void
) {
  return async () => {
    setLoading(true);
    try {
      await accountAPI.renameOrganisation(organisationId, organisationNewName).then(() => {
        dispatch(slice.actions.setOrganisationName({ newName: organisationNewName }));
        callback(true, null);
      });
    } catch (error) {
      callback(false, error.data && error.data.message ? error.data.message : null);
    } finally {
      setLoading(false);
    }
  };
}

export function deleteAccount(
  contractId: number,
  setLoading: (state: boolean) => void,
  callback: (succeed: boolean, response: any) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const response = await accountAPI.cancelSubscription(contractId);
      callback?.(true, response);
    } catch (error) {
      callback?.(false, error);
    } finally {
      setLoading?.(false);
    }
  };
}
