import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  CustomQuestion,
  Guest,
  GuestDocument,
  GuestState,
  GuestTag,
  UpdateableGuestFields,
} from 'src/@types/guest';
import guestAPI from 'src/api/guest';
import corpAPI from 'src/api/corporation';
import commonAPI from 'src/api/api';
import { dispatch } from '../store';
import { Meta, Review, Nationality, InternalNote } from 'src/@types/common';

const initialState: GuestState = {
  tabs: ['profile', 'bookings', 'balance'],
  loading: false,
  guestDetails: null,
  balanceTab: {
    invoices: [],
    payments: [],
  },
  drawers: {
    emailAddressAction: {
      mode: 'add',
      defaultValue: null,
      open: false,
      isSecondary: false,
    },
    phoneNumberAction: {
      mode: 'add',
      defaultValue: null,
      open: false,
      isLandline: false,
    },
    addressAction: {
      mode: 'add',
      defaultValue: null,
      open: false,
    },
    nameAction: {
      open: false,
    },
  },
  reviews: null,
  nationalities: [],
};

const slice = createSlice({
  name: 'guest',
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },
    setGuestDetails(state, action: PayloadAction<Guest>) {
      state.guestDetails = {
        ...{ tags: [] },
        ...action.payload,
      };
    },
    setBalanceTabData(state, action: PayloadAction<GuestState['balanceTab']>) {
      state.balanceTab = { ...action.payload };
    },
    updateGuestDetails(state, action: PayloadAction<UpdateableGuestFields>) {
      if (state.guestDetails) {
        state.guestDetails = { ...state.guestDetails, ...action.payload };
      }
    },
    setGuestTags(state, action: PayloadAction<GuestTag[]>) {
      if (state.guestDetails?.tags) state.guestDetails.tags = action.payload;
    },
    setReviews(state, action: PayloadAction<{ data: Review[]; meta: Meta; page: number }>) {
      const { data, meta, page } = action.payload;
      state.reviews = {
        data: page === 1 ? data : [...(state.reviews?.data || []), ...data],
        meta,
      };
    },
    removeGuestTag(state, action: PayloadAction<number>) {
      if (state.guestDetails?.tags)
        state.guestDetails.tags = state.guestDetails.tags.filter(
          (tag) => tag.tagging_id !== action.payload
        );
    },
    updatePhoneNumberActionDrawer(
      state,
      action: PayloadAction<Partial<GuestState['drawers']['phoneNumberAction']>>
    ) {
      state.drawers.phoneNumberAction = { ...state.drawers.phoneNumberAction, ...action.payload };
    },
    updateEmailAddressActionDrawer(
      state,
      action: PayloadAction<Partial<GuestState['drawers']['emailAddressAction']>>
    ) {
      state.drawers.emailAddressAction = { ...state.drawers.emailAddressAction, ...action.payload };
    },
    updateAddressActionDrawer(
      state,
      action: PayloadAction<Partial<GuestState['drawers']['addressAction']>>
    ) {
      state.drawers.addressAction = { ...state.drawers.addressAction, ...action.payload };
    },
    updateNameActionDrawer(
      state,
      action: PayloadAction<Partial<GuestState['drawers']['nameAction']>>
    ) {
      state.drawers.nameAction = { ...state.drawers.nameAction, ...action.payload };
    },
    updateGuestDocument(
      state,
      action: PayloadAction<{ newValues: Partial<GuestDocument>; id: number }>
    ) {
      if (state.guestDetails?.guest_documents) {
        const findIndex = state.guestDetails.guest_documents.findIndex(
          (document) => document.id === action.payload.id
        );
        if (findIndex > -1) {
          state.guestDetails.guest_documents[findIndex] = {
            ...state.guestDetails.guest_documents[findIndex],
            ...action.payload.newValues,
          };
        }
      }
    },
    updateCustomQuestion(
      state,
      action: PayloadAction<{ id: number; newValues: Partial<CustomQuestion> }>
    ) {
      if (state.guestDetails?.custom_questions) {
        const findIndex = state.guestDetails.custom_questions.findIndex(
          (question) => question.id === action.payload.id
        );
        if (findIndex > -1) {
          state.guestDetails.custom_questions[findIndex] = {
            ...state.guestDetails.custom_questions[findIndex],
            ...action.payload.newValues,
          };
        }
      }
    },
    updateReviews(state, action: PayloadAction<Review>) {
      const index = state.reviews?.data.findIndex((review) => review.id === action.payload.id);
      if (index !== undefined && index > -1) {
        const copyOfReviews = [...(state.reviews?.data || [])];
        copyOfReviews.splice(index, 1, action.payload);
        state.reviews = { ...state.reviews, data: copyOfReviews }
      } else {
        state.reviews = {
          data: [action.payload, ...(state.reviews?.data || [])],
          meta: { ...state.reviews?.meta as Meta, total_items: (state.reviews?.meta?.total_items as number) + 1 }
        }
      }
    },
    deleteAReview(state, action: PayloadAction<number>) {
      if (state.reviews) {
        state.reviews = {
          data: state.reviews?.data.filter((review) => review.id !== action.payload) || [],
          meta: { ...state.reviews.meta as Meta, total_items: (state.reviews.meta?.total_items as number) - 1 }
        }
      }
    },
    setNationalities(state, action: PayloadAction<Nationality[]>) {
      state.nationalities = action.payload;
    },
    addInternalNote(state, action: PayloadAction<InternalNote>) {
      if (state.guestDetails) state.guestDetails.notes = [...state.guestDetails.notes, action.payload];
    },
    updateInternalNote(state, action: PayloadAction<InternalNote>) {
      if (state.guestDetails) {
        const noteIndex = state.guestDetails.notes.findIndex((note) => note.id === action.payload.id);
        if (noteIndex > -1) {
          state.guestDetails.notes[noteIndex] = { ...state.guestDetails.notes[noteIndex], ...action.payload };
        }
      }
    },
    deleteIternalNote(state, action: PayloadAction<number>) {
      if (state.guestDetails) {
        state.guestDetails.notes = state.guestDetails.notes.filter(
          (note) => note.id !== action.payload
        );
      }
    },
  },
});

export const {
  startLoading,
  stopLoading,
  setGuestDetails,
  updateGuestDetails,
  setGuestTags,
  removeGuestTag,
  updatePhoneNumberActionDrawer,
  updateEmailAddressActionDrawer,
  updateAddressActionDrawer,
  updateNameActionDrawer,
  updateGuestDocument,
  updateCustomQuestion,
  setReviews,
  updateReviews,
  deleteAReview,
} = slice.actions;
export default slice.reducer;

export function getGuestDetails(id: number) {
  return async () => {
    try {
      dispatch(slice.actions.startLoading());
      const res = await guestAPI.fetchGuest(id);
      dispatch(slice.actions.setGuestDetails(res.data));
    } catch (e) {
    } finally {
      dispatch(slice.actions.stopLoading());
    }
  };
}

export function getBalanceTabData(guestId: number, setLoading?: (status: boolean) => void) {
  return async () => {
    setLoading?.(true);
    try {
      const invoiceRes = await guestAPI.fetchInvoices(guestId);
      const paymentsRes = await guestAPI.fetchPayments(guestId);
      dispatch(slice.actions.setBalanceTabData({ invoices: invoiceRes.data, payments: paymentsRes.data }));
    } catch (e) {
    } finally {
      setLoading?.(false);
    }
  };
}

export function updateGuest(
  guestId: number,
  fieldToUpdate: UpdateableGuestFields | { [x: string]: any },
  setLoading?: (succeed: boolean) => void,
  callback?: (response: AxiosResponse<any, any> | null, status: number) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const res = await guestAPI.updateGuest(guestId, fieldToUpdate);
      dispatch(slice.actions.updateGuestDetails(res.data));
      callback?.(res, res.status);
    } catch (e) {
      callback?.(null, e?.status || 500);
    } finally {
      setLoading?.(false);
    }
  };
}

export function updateGuestTags(
  newTags: string[],
  guestId: number,
  setLoading?: (succeed: boolean) => void,
  callback?: (status: boolean) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const response = await commonAPI.createAndUpdateTags(guestId, 'guest', newTags);
      dispatch(slice.actions.setGuestTags(response.data));
      callback?.(true);
    } catch (e) {
      callback?.(false);
    } finally {
      setLoading?.(false);
    }
  };
}

export function deleteGuestTag(taggingId: number, setLoading?: (succeed: boolean) => void) {
  return async () => {
    try {
      setLoading?.(true);
      dispatch(slice.actions.removeGuestTag(taggingId));
      await commonAPI.deleteTag(taggingId);
    } catch (e) {
    } finally {
      setLoading?.(false);
    }
  };
}

export function updateGuestDocumentVerifiedStatus(
  documentId: number,
  newStatus: boolean | null,
  oldStatus: boolean | null
) {
  return async () => {
    try {
      dispatch(
        slice.actions.updateGuestDocument({ newValues: { is_verified: newStatus }, id: documentId })
      );
      await guestAPI.updateGuestDocument(documentId, { is_verified: newStatus });
    } catch (e) {
      dispatch(
        slice.actions.updateGuestDocument({ newValues: { is_verified: oldStatus }, id: documentId })
      );
    }
  };
}

export function updateCustomQuestionAnswer(
  customQuestionId: number,
  newAnswer: string,
  setLoading?: (succeed: boolean) => void,
  callback?: (status: boolean) => void
) {
  return async () => {
    try {
      setLoading?.(true);
      await guestAPI.updateCustomQuestion(customQuestionId, { answer: newAnswer });
      dispatch(
        slice.actions.updateCustomQuestion({
          id: customQuestionId,
          newValues: { answer: newAnswer },
        })
      );
      callback?.(true);
    } catch (e) {
      callback?.(false);
    } finally {
      setLoading?.(false);
    }
  };
}

export function getReviews(args: {
  guestId: number;
  page: number;
  limit: number;
  setIsLoading?: (state: boolean) => void;
}) {
  const { guestId, page, limit, setIsLoading } = args;
  return async () => {
    try {
      setIsLoading?.(true);
      const { data } = await guestAPI.fetchReviews(guestId, page, limit);
      dispatch(slice.actions.setReviews({ ...data, page }));
    } catch (e) {
    } finally {
      setIsLoading?.(false);
    }
  }
}

export function getNationalities() {
  return async () => {
    try {
      const res = await commonAPI.fetchNationalities();
      dispatch(slice.actions.setNationalities(res.data));
    } catch (e) {}
  };
}

export function addGuestInternalNote(
  guestId: number,
  message: string,
  setLoading?: (succeed: boolean) => void,
  callback?: (status: boolean) => void
) {
  return async () => {
    try {
      setLoading?.(true);
      const res = await corpAPI.addInternalNote(guestId, 'guest', message);
      dispatch(slice.actions.addInternalNote(res.data));
      callback?.(true);
    } catch (e) {
      callback?.(false);
    } finally {
      setLoading?.(false);
    }
  };
}

export function updateGuestInternalNote(
  id: number,
  message: string,
  setLoading?: (succeed: boolean) => void,
  callback?: (status: boolean) => void
) {
  return async () => {
    try {
      setLoading?.(true);
      const res = await corpAPI.updateInternalNote(id, message);
      dispatch(slice.actions.updateInternalNote(res.data));
      callback?.(true);
    } catch (e) {
      callback?.(false);
    } finally {
      setLoading?.(false);
    }
  }
}

export function deleteGuestInternalNote(
  id: number,
  setLoading?: (succeed: boolean) => void,
  callback?: (status: boolean) => void
) {
  return async () => {
    try {
      setLoading?.(true);
      await corpAPI.deleteInternalNote(id);
      dispatch(slice.actions.deleteIternalNote(id));
      callback?.(true);
    } catch (e) {
      callback?.(false);
    } finally {
      setLoading?.(false);
    }
  }
}
