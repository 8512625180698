// api
import BookingApi from 'src/api/booking';
// redux
import { dispatch } from '../store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// @types
import {
  Account,
  CostElement,
  ExpenseType,
  ExpenseTypeEdit,
  ExpenseTypesState,
} from 'src/@types/expense-list';

const initialState: ExpenseTypesState = {
  loading: false,
  expenseTypes: {
    list: [],
    rowCount: 0,
    gridConfigs: null,
    controller: new AbortController(),
  },
  drawers: {
    newEditExpenseTypeDrawer: {
      isOpen: false,
      onAction: () => {},
    },
  },
};

const slice = createSlice({
  name: 'expenseTypes',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    setGridConfigs(state, action) {
      state.expenseTypes.gridConfigs = action.payload;
    },

    setRowCount(state, action) {
      state.expenseTypes.rowCount = action.payload;
    },

    setExpenseTypeList(state, action: PayloadAction<Array<ExpenseType>>) {
      state.loading = false;
      state.expenseTypes.list = action.payload;
    },

    setExpenseTypeListController(state, action: PayloadAction<AbortController | null>) {
      state.expenseTypes.controller = action.payload;
    },

    updateExpenseTypeListDeleteAction(state, action: PayloadAction<{ activeId: number }>) {
      state.expenseTypes.list = state.expenseTypes.list?.filter(
        (item) => item.id !== action.payload.activeId
      );
      state.expenseTypes.rowCount = --state.expenseTypes.rowCount;
    },

    updateExpenseTypeListEditAction(
      state,
      action: PayloadAction<{ activeId: number; newValue: ExpenseTypeEdit }>
    ) {
      const { activeId, newValue } = action.payload;
      const expenseTypeIndex = state.expenseTypes.list.findIndex((item) => item.id === activeId);
      if (expenseTypeIndex >= 0) {
        state.expenseTypes.list[expenseTypeIndex] = {
          ...state.expenseTypes.list[expenseTypeIndex],
          name: newValue.name,
          cost_element: newValue.cost_element as CostElement,
          account: newValue.account as Account,
        };
      }
    },

    setNewEditExpenseTypeDrawer(
      state,
      action: PayloadAction<{
        open: boolean;
        onAction: (data: any) => void;
        expenseType?: ExpenseTypeEdit;
      }>
    ) {
      state.drawers.newEditExpenseTypeDrawer.isOpen = action.payload.open;
      state.drawers.newEditExpenseTypeDrawer.onAction = action.payload.onAction;
      state.drawers.newEditExpenseTypeDrawer.expenseType = action.payload.expenseType;
    },
  },
});

// reducer
export default slice.reducer;

// actions
export const {
  setLoading,
  setGridConfigs,
  setRowCount,
  setExpenseTypeList,
  setExpenseTypeListController,
  setNewEditExpenseTypeDrawer,
  updateExpenseTypeListDeleteAction,
  updateExpenseTypeListEditAction,
} = slice.actions;

export function getExpenseTypeList(
  page,
  limit,
  sortModel,
  filtersState,
  setLoading,
  advanceFilterState,
  controller
) {
  return async () => {
    dispatch(setGridConfigs({ page, limit, sortModel, filtersState, advanceFilterState }));
    if (controller) {
      controller.abort();
      dispatch(setExpenseTypeListController(new AbortController()));
    }
    try {
      setLoading(true);
      const advanceFilterParam =
        advanceFilterState && advanceFilterState.value
          ? {
              key: advanceFilterState.key ? advanceFilterState.key : 'searchKeyword',
              value: advanceFilterState?.value || '',
            }
          : undefined;

      const response = await BookingApi.fetchAddonsList({
        page,
        limit,
        filtersState,
        advanceFilterParam,
        category: 'Expenses',
      });
      dispatch(setExpenseTypeList(response.data.data));
      dispatch(setRowCount(response.data.meta.total_items));
      dispatch(setExpenseTypeListController(null));
    } catch (e) {
    } finally {
      if (!controller) {
        dispatch(setExpenseTypeListController(new AbortController()));
        setLoading(false);
      }
    }
  };
}
