import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { Corporation, CorpState, GuestSearchApiResponse, ContactSearchApiResponse } from 'src/@types/corporation';
import corpAPI from 'src/api/corporation';
import { dispatch } from '../store';
import { GuestTag } from 'src/@types/guest';
import commonAPI from 'src/api/api';
import { batch } from 'react-redux';
import { InternalNote } from 'src/@types/common';

const initialState: CorpState = {
  tabs: ['profile', 'bookings', 'balance'],
  loading: false,
  corpDetails: null,
  staff: [],
  loadingStaffList: true,
  balanceTab: {
    invoices: [],
    payments: [],
  },
  guestsOfOrganizationState: {
    isLoading: false,
    page: 0,
    limit: 15,
    guests: [],
    searchTerm: '',
  },
  contactsOfOrganizationState: {
    isLoading: false,
    page: 0,
    limit: 15,
    contacts: [],
    searchTerm: '',
  },
  drawers: {
    emailAddressAction: {
      mode: 'add',
      defaultValue: null,
      open: false,
      isSecondary: false,
    },
    phoneNumberAction: {
      mode: 'add',
      defaultValue: null,
      open: false,
      isLandline: true,
    },
    addressAction: {
      mode: 'add',
      defaultValue: null,
      open: false,
    },
    nameAction: {
      open: false,
    },
    faxAction: {
      mode: 'add',
      defaultValue: null,
      open: false,
    },
    createGuest: {
      defaultValue: '',
      isOpen: false,
    },
    createContact: {
      defaultValue: '',
      isOpen: false,
    }
  },
  isContactOwnerVisible: false,
};

const slice = createSlice({
  name: 'corporation',
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },
    setCorpDetails(state, action: PayloadAction<Corporation>) {
      state.corpDetails = {
        ...{ tags: [] },
        ...action.payload,
      };
    },
    setCorpTags(state, action: PayloadAction<GuestTag[]>) {
      if (state.corpDetails?.tags) state.corpDetails.tags = action.payload;
    },
    setStaffList(state, action: PayloadAction<any>) {
      state.staff = action.payload;
      state.loadingStaffList = false;
    },
    setGuestsOfOrganization(state, action: PayloadAction<Partial<CorpState['guestsOfOrganizationState']>>) {
      state.guestsOfOrganizationState = { ...state.guestsOfOrganizationState, ...action.payload };
    },
    setContactsOfOrganization(state, action: PayloadAction<Partial<CorpState['contactsOfOrganizationState']>>) {
      state.contactsOfOrganizationState = { ...state.contactsOfOrganizationState, ...action.payload };
    },
    setBalanceTabData(state, action: PayloadAction<CorpState['balanceTab']>) {
      state.balanceTab = { ...action.payload };
    },
    setIsContactOwnerVisible(state, action: PayloadAction<boolean>) {
      state.isContactOwnerVisible = action.payload;
    },
    removeCorpTag(state, action: PayloadAction<number>) {
      if (state.corpDetails?.tags)
        state.corpDetails.tags = state.corpDetails.tags.filter(
          (tag) => tag.tagging_id !== action.payload
        );
    },
    updateCorpDetails(state, action: PayloadAction<Partial<Corporation>>) {
      if (state.corpDetails) {
        state.corpDetails = { ...state.corpDetails, ...action.payload };
      }
    },
    updatePhoneNumberActionDrawer(
      state,
      action: PayloadAction<Partial<CorpState['drawers']['phoneNumberAction']>>
    ) {
      state.drawers.phoneNumberAction = { ...state.drawers.phoneNumberAction, ...action.payload };
    },
    updateEmailAddressActionDrawer(
      state,
      action: PayloadAction<Partial<CorpState['drawers']['emailAddressAction']>>
    ) {
      state.drawers.emailAddressAction = { ...state.drawers.emailAddressAction, ...action.payload };
    },
    updateAddressActionDrawer(
      state,
      action: PayloadAction<Partial<CorpState['drawers']['addressAction']>>
    ) {
      state.drawers.addressAction = { ...state.drawers.addressAction, ...action.payload };
    },
    updateNameActionDrawer(
      state,
      action: PayloadAction<Partial<CorpState['drawers']['nameAction']>>
    ) {
      state.drawers.nameAction = { ...state.drawers.nameAction, ...action.payload };
    },
    updateFaxDrawer(
      state,
      action: PayloadAction<Partial<CorpState['drawers']['faxAction']>>
    ) {
      state.drawers.faxAction = { ...state.drawers.faxAction, ...action.payload };
    },
    updateCreateGuestDrawer(
      state,
      action: PayloadAction<Partial<CorpState['drawers']['createGuest']>>
    ) {
      state.drawers.createGuest = { ...state.drawers.createGuest, ...action.payload };
    },
    updateCreateContactDrawer(
      state,
      action: PayloadAction<Partial<CorpState['drawers']['createContact']>>
    ) {
      state.drawers.createContact = { ...state.drawers.createContact, ...action.payload };
    },
    addInternalNote(state, action: PayloadAction<InternalNote>) {
      if (state.corpDetails) state.corpDetails.notes = [...state.corpDetails.notes, action.payload];
    },
    updateInternalNote(state, action: PayloadAction<InternalNote>) {
      if (state.corpDetails) {
        const noteIndex = state.corpDetails.notes.findIndex((note) => note.id === action.payload.id);
        if (noteIndex > -1) {
          state.corpDetails.notes[noteIndex] = { ...state.corpDetails.notes[noteIndex], ...action.payload };
        }
      }
    },
    deleteIternalNote(state, action: PayloadAction<number>) {
      if (state.corpDetails) {
        state.corpDetails.notes = state.corpDetails.notes.filter(
          (note) => note.id !== action.payload
        );
      }
    },
  },
});

export const {
  startLoading,
  stopLoading,
  setCorpDetails,
  updateCorpDetails,
  setCorpTags,
  setGuestsOfOrganization,
  setContactsOfOrganization,
  removeCorpTag,
  updatePhoneNumberActionDrawer,
  updateEmailAddressActionDrawer,
  updateAddressActionDrawer,
  updateNameActionDrawer,
  updateFaxDrawer,
  updateCreateGuestDrawer,
  updateCreateContactDrawer,
  setIsContactOwnerVisible,
} = slice.actions;
export default slice.reducer;

export function getCorpDetails(id: number) {
  return async () => {
    try {
      dispatch(slice.actions.startLoading());
      const res = await corpAPI.fetchCorporation(id);
      dispatch(slice.actions.setCorpDetails(res.data));
      dispatch(slice.actions.setIsContactOwnerVisible(!!res.data.account_manager?.user?.id));
    } catch (e) {
    } finally {
      dispatch(slice.actions.stopLoading());
    }
  };
}

export function getStaff(organization_id: number) {
  return async () => {
    try {
      const res = await corpAPI.fetchAllStaff(organization_id);
      dispatch(slice.actions.setStaffList(res.data));
    } catch (e) {
    }
  };
}

export function getBalanceTabData(guestId: number, setLoading?: (status: boolean) => void) {
  return async () => {
    setLoading?.(true);
    try {
      const invoiceRes = await corpAPI.fetchInvoices(guestId);
      const paymentsRes = await corpAPI.fetchPayments(guestId);
      dispatch(slice.actions.setBalanceTabData({ invoices: invoiceRes.data, payments: paymentsRes.data }));
    } catch (e) {
    } finally {
      setLoading?.(false);
    }
  };
}

export function updateCorporation(
  corporationId: number,
  fieldToUpdate: Partial<Corporation> | { [x: string]: any },
  setLoading?: (succeed: boolean) => void,
  callback?: (response: AxiosResponse<any, any> | null, status: number) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const res = await corpAPI.updateCorp(corporationId, fieldToUpdate);
      dispatch(slice.actions.updateCorpDetails(res.data));
      callback?.(res, res.status);
    } catch (e) {
      callback?.(null, e?.status || 500);
    } finally {
      setLoading?.(false);
    }
  };
};

export function getGuestsOfOrganization({
  corpId,
  page,
  limit = 15,
  currentGuestsList,
  searchTerm,
}: {
  corpId: number;
  page: number;
  currentGuestsList: GuestSearchApiResponse[];
  limit?: number;
  searchTerm?: string;
}) {
  return async () => {
    dispatch(slice.actions.setGuestsOfOrganization({
      isLoading: true,
      page,
      ...((typeof searchTerm === 'string') && { searchTerm }),
    }));
    try {
      const res = await corpAPI.fetchGuestsOfOrganization(corpId, page, limit, (searchTerm || ''));
      dispatch(slice.actions.setGuestsOfOrganization({
        guests: currentGuestsList ? [...currentGuestsList, ...res.data] : res.data,
        isLoading: false,
      }));
    } catch (e) {
    } finally {
      dispatch(slice.actions.setGuestsOfOrganization({ isLoading: false }));
    }
  };
};

export function getContactsOfOrganization({
  corpId,
  page,
  limit = 15,
  currentContactsList,
  searchTerm,
}: {
  corpId: number;
  page: number;
  currentContactsList: ContactSearchApiResponse[];
  limit?: number;
  searchTerm?: string;
}) {
  return async () => {
    dispatch(slice.actions.setContactsOfOrganization({
      isLoading: true,
      page,
      ...((typeof searchTerm === 'string') && { searchTerm }),
    }));
    try {
      const res = await corpAPI.fetchContactsOfOrganization(corpId, page, limit, (searchTerm || ''));
      dispatch(slice.actions.setContactsOfOrganization({
        contacts: currentContactsList ? [...currentContactsList, ...res.data] : res.data,
        isLoading: false,
      }));
    } catch (e) {
    } finally {
      dispatch(slice.actions.setContactsOfOrganization({ isLoading: false }));
    }
  };
};

export function createAGuest({
  corpDetails,
  firstName,
  lastName,
  email,
  phoneNumber,
  setLoading,
  callback,
}: {
  corpDetails: Corporation | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
  setLoading?: (isLoading: boolean) => void,
  callback?: (response: AxiosResponse<any, any> | null, status: number) => void
}) {
  return async () => {
    setLoading?.(true);
    try {
      const res = await commonAPI.createGuest({
        first_name: firstName,
        last_name: lastName,
        email,
        mobile_number: phoneNumber
      });
      batch(() => {
        dispatch(slice.actions.setGuestsOfOrganization({ page: 0, guests: [], searchTerm: '' }));
        if (corpDetails) {
          const idsOfExistingGuests = corpDetails.guests?.map((guest) => guest.id);
          dispatch(
            updateCorporation(
              corpDetails.id,
              { guests: corpDetails.guests ? [...idsOfExistingGuests, res.data.id] : [res.data.id] },
              (state) => dispatch(setGuestsOfOrganization({ isLoading: state })),
              (response) => {
                if (response) {
                  dispatch(setGuestsOfOrganization({ isLoading: false }));
                  setLoading?.(false);
                  callback?.(response, response.status);
                }
              })
          );
        } else {
          setLoading?.(false);
          callback?.(null, 0);
        }
      });
    } catch (e) {
      setLoading?.(false);
      callback?.(null, e?.status || 500);
    }
  };
}

export function createAContact({
  corpDetails,
  firstName,
  lastName,
  email,
  phoneNumber,
  setLoading,
  callback,
}: {
  corpDetails: Corporation | null;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  setLoading?: (isLoading: boolean) => void,
  callback?: (response: AxiosResponse<any, any> | null, status: number) => void
}) {
  return async () => {
    setLoading?.(true);
    try {
      const res = await corpAPI.createContact({
        first_name: firstName,
        last_name: lastName,
        email,
        mobile_number: phoneNumber
      });
      batch(() => {
        dispatch(slice.actions.setContactsOfOrganization({ page: 0, contacts: [], searchTerm: '' }));
        if (corpDetails) {
          const idsOfExistingContacts = corpDetails.contacts?.map((contact) => contact.id);
          dispatch(
            updateCorporation(
              corpDetails.id,
              { contacts: corpDetails.contacts ? [...idsOfExistingContacts, res.data.contact_id] : [res.data.contact_id] },
              (state) => dispatch(setContactsOfOrganization({ isLoading: state })),
              (response, status) => {
                if (response) {
                  dispatch(setContactsOfOrganization({ isLoading: false }));
                  setLoading?.(false);
                  callback?.(response, response.status);
                }
                if (response === null) {
                  setLoading?.(false);
                  callback?.(null, status);
                }
              })
          );
        } else {
          setLoading?.(false);
          callback?.(null, 0);
        }
      });
    } catch (e) {
      setLoading?.(false);
      callback?.(null, e?.status || 500);
    }
  };
}

export function updateCorporationTags(
  newTags: string[],
  corpId: number,
  setLoading?: (succeed: boolean) => void,
  callback?: (status: boolean) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const response = await commonAPI.createAndUpdateTags(corpId, 'corporation', newTags);
      dispatch(slice.actions.setCorpTags(response.data));
      callback?.(true);
    } catch (e) {
      callback?.(false);
    } finally {
      setLoading?.(false);
    }
  };
}

export function deleteCorporationTag(taggingId: number, setLoading?: (succeed: boolean) => void) {
  return async () => {
    try {
      setLoading?.(true);
      dispatch(slice.actions.removeCorpTag(taggingId));
      await commonAPI.deleteTag(taggingId);
    } catch (e) {
    } finally {
      setLoading?.(false);
    }
  };
}

export function addCorpInternalNote(
  corpId: number,
  message: string,
  setLoading?: (succeed: boolean) => void,
  callback?: (status: boolean) => void
) {
  return async () => {
    try {
      setLoading?.(true);
      const res = await corpAPI.addInternalNote(corpId, 'corporation', message);
      dispatch(slice.actions.addInternalNote(res.data));
      callback?.(true);
    } catch (e) {
      callback?.(false);
    } finally {
      setLoading?.(false);
    }
  };
}

export function updateCorpInternalNote(
  id: number,
  message: string,
  setLoading?: (succeed: boolean) => void,
  callback?: (status: boolean) => void
) {
  return async () => {
    try {
      setLoading?.(true);
      const res = await corpAPI.updateInternalNote(id, message);
      dispatch(slice.actions.updateInternalNote(res.data));
      callback?.(true);
    } catch (e) {
      callback?.(false);
    } finally {
      setLoading?.(false);
    }
  }
}

export function deleteCorpInternalNote(
  id: number,
  setLoading?: (succeed: boolean) => void,
  callback?: (status: boolean) => void
) {
  return async () => {
    try {
      setLoading?.(true);
      await corpAPI.deleteInternalNote(id);
      dispatch(slice.actions.deleteIternalNote(id));
      callback?.(true);
    } catch (e) {
      callback?.(false);
    } finally {
      setLoading?.(false);
    }
  }
}