import { dispatch } from '../store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// api
import BookingApi from 'src/api/booking';
// @types
import { BlockedDateFormValues, BlockedDatesState, UnitAvailability } from 'src/@types/blocked-dates';
import orgConfigAPI from 'src/api/org-config';
import calendarAPI from 'src/api/calendar';
import { Brand } from 'src/@types/org-config';
import { uniqBy } from 'lodash';

const initialState: BlockedDatesState = {
  error: null,
  refresh: false,
  loading: false,
  isLoading: false,
  currentTab: 'active',
  gridConfigs: null,
  dialogs: {
    removeBlockDateDialog: {
      isOpen: false,
      isLoading: false,
      activeBlockedDateId: null,
      isFromIcal: false,
    },
  },
  drawers: {
    createAndEditBlockedDate: {
      blockedDateOpen: false,
      unitsAvailabilities: {},
      blockedDateDefaultValue: {
        blockedDateId: null,
        blockType: '',
        reason: '',
        timezone: '',
        property: null,
        unit: null,
        units: [],
        fromDate: null,
        fromTime: '',
        toDate: null,
        toTime: '',
        customDates: [],
      },
    },
    blockedDateSetting: {
      blockedDateSettingOpen: false,
    },
  },
  rowCount: 0,
  blockedDatesList: [],
  brands: {},
  selectedBrand: null,
};

const slice = createSlice({
  name: 'blockedDates',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // REFRESH
    setRefresh(state, action) {
      state.refresh = action.payload;
    },

    // START LOADING
    startLoading(state) {
      state.loading = true;
    },

    setLoading(state, action) {
      state.loading = action.payload;
    },

    // CURRENT TAB
    setCurrentTab(state, action) {
      state.currentTab = action.payload;
    },

    setGridConfigs(state, action) {
      state.gridConfigs = action.payload;
    },

    // SET BLOCK DATE LIST
    setBlockDatesListSuccess(state, action) {
      state.isLoading = false;
      state.blockedDatesList = action.payload;
    },

    // SET BLOCK DATE LIST ROW COUNT
    setBlockDatesListRowCount(state, action) {
      state.rowCount = action.payload;
    },

    // SET BLOCKED DATE ARCHIVED
    setBlockedDateArchivedSuccess(state, action) {
      state.isLoading = false;
      const index = state.blockedDatesList.findIndex(
        (item) => item.id === action.payload.blockedDateId
      );
      if (index >= 0) {
        state.blockedDatesList[index].is_active = false;
      }
    },

    setBlockedDateRemoveDialog(state, action) {
      state.dialogs.removeBlockDateDialog.isOpen = action.payload.open;
      state.dialogs.removeBlockDateDialog.isLoading = action.payload.loading;
      state.dialogs.removeBlockDateDialog.activeBlockedDateId = action.payload.activeId;
      state.dialogs.removeBlockDateDialog.isFromIcal = action.payload.isFromIcal;
    },

    setBlockedDateRemoveDialogOpen(state, action) {
      state.dialogs.removeBlockDateDialog.isOpen = action.payload.activeId;
    },

    setBlockedDateRemoveDialogLoading(state, action) {
      state.dialogs.removeBlockDateDialog.isLoading = action.payload;
    },

    setBlockedDateRemoveDialogActiveBlockedDateId(state, action) {
      state.dialogs.removeBlockDateDialog.activeBlockedDateId = action.payload.activeId;
    },

    setBlockedDateCreateAndEditDrawerOpen(state, action: PayloadAction<{open?: boolean, defaultValue?: Partial<BlockedDateFormValues>}>) {
      state.drawers.createAndEditBlockedDate = {
        ...state.drawers.createAndEditBlockedDate,
        blockedDateOpen: action.payload.open ?? state.drawers.createAndEditBlockedDate.blockedDateOpen,
        blockedDateDefaultValue: action.payload.open ? {
          ...state.drawers.createAndEditBlockedDate.blockedDateDefaultValue,
          ...action.payload.defaultValue,
        } : initialState.drawers.createAndEditBlockedDate.blockedDateDefaultValue
      };
    },

    setUnitsAvailabilities(state, action: PayloadAction<UnitAvailability[]>) {
      action.payload.forEach(({ id, blocks, bookings, ...other }) => {
        const availability = state.drawers.createAndEditBlockedDate.unitsAvailabilities[id];
        state.drawers.createAndEditBlockedDate.unitsAvailabilities[id] = {
          ...(availability ?? other),
          id,
          bookings: uniqBy([...(availability?.bookings ?? []), ...bookings], 'id'),
          blocks: uniqBy([...(availability?.blocks ?? []), ...blocks], 'id'),
        }
      });
    },

    setBlockedDateSettingDrawerOpen(state, action) {
      state.isLoading = false;
      state.drawers.blockedDateSetting.blockedDateSettingOpen = action.payload;
    },

    addBlockedDates(state, action) {
      if (state.gridConfigs?.page > 1) {
        state.gridConfigs.page = 1;
      } else {
        state.blockedDatesList = [...action.payload, ...state.blockedDatesList];
      }
    },

    editBlockedDate(state, action) {
      const index = state.blockedDatesList.findIndex(
        (blockedDate) => blockedDate.id === action.payload.id
      );
      if (index >= 0) {
        state.blockedDatesList.splice(index, 1, action.payload.data);
      }
    },

    setAllBrands(state, action: PayloadAction<Brand[]>) {
      const tempObject = {};
      action.payload.forEach((brand, i) => {
        tempObject[i] = { ...brand };
      });
      state.brands = { ...tempObject };
    },

    setSelectedBrand(state, action) {
      state.selectedBrand = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  hasError,
  setRefresh,
  startLoading,
  setLoading,
  setCurrentTab,
  setGridConfigs,
  setBlockDatesListSuccess,
  setBlockDatesListRowCount,
  setBlockedDateRemoveDialog,
  setBlockedDateRemoveDialogOpen,
  setBlockedDateRemoveDialogLoading,
  setBlockedDateRemoveDialogActiveBlockedDateId,
  setBlockedDateCreateAndEditDrawerOpen,
  setBlockedDateSettingDrawerOpen,
  addBlockedDates,
  editBlockedDate,
  setSelectedBrand,
} = slice.actions;

export function archiveBlockedDate(
  blockedDateId: number,
  setLoading: (succeed: boolean) => void,
  callback?: (succeed: boolean, response: any) => void
) {
  return async () => {
    setLoading(true);
    try {
      const response = await BookingApi.archiveBlockedDate(blockedDateId);
      dispatch(slice.actions.setBlockedDateArchivedSuccess({ blockedDateId: blockedDateId }));
      callback?.(true, response);
    } catch (error) {
      callback?.(false, error);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
}

export function disableBlockedDates(
  blockedDateIds: number | string,
  setLoading: (succeed: boolean) => void,
  callback?: (succeed: boolean, response: any) => void
) {
  return async () => {
    setLoading(true);
    try {
      const response = await BookingApi.disableBlockedDates(blockedDateIds);
      callback?.(true, response);
    } catch (error) {
      callback?.(false, error);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
}

export function createBlockedDate(
  requestBody: any,
  setLoading: (succeed: boolean) => void,
  callback?: (succeed: boolean, response: any) => void
) {
  return async () => {
    setLoading(true);
    try {
      const response = await BookingApi.createBatchBlockedDates(requestBody);
      dispatch(slice.actions.addBlockedDates(response.data));
      callback?.(true, response);
    } catch (error) {
      callback?.(false, error);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
}

export function updateBlockedDate(
  blockedDateId: number,
  requestBody: any,
  setLoading: (succeed: boolean) => void,
  callback?: (succeed: boolean, response: any) => void
) {
  return async () => {
    setLoading(true);
    try {
      const response = await BookingApi.updateBlockedDate(blockedDateId, requestBody);
      dispatch(slice.actions.editBlockedDate({ id: blockedDateId, data: response.data }));
      callback?.(true, response);
    } catch (error) {
      callback?.(false, error);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
}

export function getUnitsAvailabilities(
  propertyId: number,
  from: string,
  to: string,
  setLoading: (succeed: boolean) => void,
  callback?: (succeed: boolean, response: any) => void
) {
  return async () => {
    setLoading(true);
    try {
      const response = await calendarAPI.fetchBookings(from, to, '0', [propertyId], false, true);
      const unitsAvailabilities: UnitAvailability[] = response.data.properties[0].unitTypes.flatMap(({ id: unitTypeId, name: unitTypeName, units }) => (
        units.map(({ id, name, blocks, bookings }) => ({ id, name, blocks, bookings, unitTypeId, unitTypeName }))
      ));
      dispatch(slice.actions.setUnitsAvailabilities(unitsAvailabilities));
      callback?.(true, response);
    } catch (error) {
      callback?.(false, error);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
}

export function getAllBrands(callback?: ({ loading }: { loading: boolean }) => void) {
  return async () => {
    callback?.({ loading: true });
    try {
      const response = await orgConfigAPI().fetchAllBrands();
      dispatch(slice.actions.setAllBrands(response.data));
      dispatch(
        slice.actions.setSelectedBrand({
          id: response.data[0].id,
          name: response.data[0].name,
        })
      );
    } catch (error) {
    } finally {
      callback?.({ loading: false });
    }
  };
}
