export const testFilesSize = (files?: File[]) => {
  const MAX_FILE_SIZE = 10 * 1000 * 1000; // 10 Mb
  let valid = true;
  if (files) {
    files.forEach((file) => {
      if (file.size > MAX_FILE_SIZE) {
        valid = false;
      }
    });
  }
  return valid;
};

export const FILE_FORMATS = [
  'application/msword',
  'application/vnd.ms-excel',
  'application/pdf',
  'application/zip',
  'image/gif',
  'image/jpeg',
  'image/png',
  'application/vnd.ms-office',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

export const filteredFileFormats = (allowed: string[] = [], exclude: string[] = []) => (
  FILE_FORMATS.filter((format) => (
    allowed.length === 0 ? true : allowed.some((allowed) => format.includes(allowed))
      && exclude.length === 0 ? true : !exclude.some((allowed) => format.includes(allowed))
  ))
);

export const testFileTypes = (files?: File[], allowed: string[] = [], exclude: string[] = []) => {
  let valid = true;

  if (files) {
    files.forEach((file) => {
      const formats = filteredFileFormats(allowed, exclude);
      if (!formats.includes(file.type)) {
        valid = false;
      }
    });
  }
  return valid;
};

export const totalFilesSize = (files: File[]): number =>
  files.reduce((total, file) => {
    total += file.size;
    return total;
  }, 0);

export const downloadFile = (url: string, fileName: string) => {
  if (document) {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
    URL.revokeObjectURL(url);
  }
};