// utils
import axios from 'src/utils/axios';
// @types
import { FilterValue } from 'src/@types/filter';
import { SortDirection } from 'src/@types/booking-list';

function issuesAPI() {
  return {
    getIssue: (issueId: number) => axios.get(`/apis/issues/${issueId}`),
    updateStatus(issueId: number, status: 'rejected' | 'resolved') {
      return axios.put(`/apis/issues/${issueId}`, { status });
    },
    addNote: (issueId: number, message: string) => {
      const url = `/apis/issue/add_note/${issueId}`;
      return axios.post(url, { message });
    },
    deleteNote: (noteId: number) => {
      const url = `/apis/staff/delete_additional_info/${noteId}`;
      return axios.delete(url);
    },
    getIssueList: ({
      page,
      limit,
      order,
      signal,
      filtersState,
      advanceFilterParam,
    }: {
      page: number;
      limit: number;
      order?: { field: string; sort: SortDirection };
      signal?: AbortSignal;
      filtersState: {
        created_at?: { from?: string; to?: string };
        updated_at?: { from?: string; to?: string };
        processed_statuses?: boolean;
        property?: FilterValue;
        priority?: FilterValue;
      };
      advanceFilterParam?: { key: string; value: string };
    }) =>
      axios.get('/apis/list/issues', {
        signal,
        params: {
          page,
          limit,
          ...(order && { [`order[${order.field}]`]: order.sort }),
          'created_at[after]': filtersState.created_at ? filtersState.created_at?.from : undefined,
          'created_at[strictly_before]': filtersState.created_at
            ? filtersState.created_at?.to
            : undefined,
          'updated_at[after]': filtersState.updated_at ? filtersState.updated_at?.from : undefined,
          'updated_at[strictly_before]': filtersState.updated_at
            ? filtersState.updated_at?.to
            : undefined,
          ...(filtersState?.property && {
            [`property.short_name`]: filtersState.property.label,
          }),
          ...(filtersState?.priority && {
            priority: filtersState.priority?.value,
          }),
          ...(!filtersState.processed_statuses && {
            status: 'init',
          }),
          ...(advanceFilterParam && { [`${advanceFilterParam.key}`]: advanceFilterParam.value }),
        },
      }),
    fetchIssueTypes: () => axios.get('/apis/issue/get_issue_type'),
    fetchStaffList: (propertyId?: number, unitId?: number, key: 'staff' | 'company' = 'staff') => {
      const url = '/apis/tasks_view/create_new_task_staff';
      return axios.get(url, { params: { property_id: propertyId, unit_id: unitId, key } });
    },
    createMaintenanceTask: (
      issueId: number | null,
      propertyId: number,
      type: string,
      description: string,
      attachmentFileIds: string,
      assigneeId: number | null,
      priority: string,
      additionalNote: string,
      canStartFrom: string | null,
      mustFinishBy: string | null,
      unitId?: number
    ) => {
      const url = '/apis/task/create_maintenance_task';
      return axios.post(url, {
        issue_id: issueId,
        property_id: propertyId,
        unit_id: unitId ?? null,
        type: type,
        description: description,
        file_id: attachmentFileIds,
        assignee_id: assigneeId,
        priority: priority,
        note_message: additionalNote,
        can_start_from: canStartFrom,
        must_finish_by: mustFinishBy,
      });
    },
    fetchIssuePriority: () => axios.get('/apis/issue/get_issue_priority'),
    createAndEditIssue: (body: {
      listing_id: number;
      unit_id: number | null;
      type: number;
      priority: string;
      description: string | null;
      file_ids: number[];
      issue_id: number | null;
    }) => axios.post('/apis/issue/create_and_edit_issue', body),
  };
}

export default issuesAPI();
