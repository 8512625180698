import axios from '../utils/axios';

function calendarAPI() {
  return {
    fetchCalendarConfig: (city: string, showCancelled: boolean, showPastUnallocated: boolean = false) => {
      const url = `/apis/cal/config`;
      return axios.get(url, {
        params: {
          city_id: city,
          show_cancelled: showCancelled,
          count_unallocated_from_today: showPastUnallocated ? 0 : 1,
        },
      });
    },
    fetchRates: (from: string, to: string, city: string, properties: number[]) => {
      const url = `/apis/cal/rates`;
      return axios.post(url, {
        from: from,
        to: to,
        city_id: city,
        properties: properties,
      });
    },
    fetchBookings: (
      from: string,
      to: string,
      city: string,
      properties: number[],
      showCancelled: boolean,
      showUnitAvailabilities: boolean,
    ) => {
      const url = `/apis/cal/bookings`;
      return axios.post(url, {
        from: from,
        to: to,
        city_id: city,
        properties: properties,
        show_cancelled: showCancelled,
        show_unit_availabilities: showUnitAvailabilities,
      });
    },
    fetchBookingOverview: (bookingId: number) => {
      const url = `/apis/cal/booking/overview`;
      return axios.post(url, {
        booking_id: bookingId,
      });
    },
    changeUnitStatus: (unitId: number) => {
      const url = `/apis/cal/change_status`;
      return axios.post(url, {
        id: unitId,
      });
    },
    unnalocateBooking: (bookingId: string) => {
      const url = '/apis/bookings/unnallocate';
      return axios.post(url, {
        booking_id: bookingId,
      });
    },
    updateRate: (
      id: string | number,
      from: string,
      to: string,
      minStay: string | number,
      maxStay: string | number,
      price: string | number,
      stopCheckins: boolean | number,
      stopCheckouts: boolean | number,
      stopSell: boolean | number
    ) => {
      const url = `/apis/cal/update_rates`;
      return axios.post(url, {
        id,
        from,
        to,
        minStay,
        maxStay,
        price,
        stopCheckins,
        stopCheckouts,
        stopSell,
      });
    },
    updateBooking: (data: FormData) => {
      const url = `/apis/cal/save_data_booking`;
      return axios.post(url, data);
    },
  };
}

export default calendarAPI();
