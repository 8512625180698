import { useState } from 'react';
import { ClickAwayListener, Tooltip, TooltipProps } from '@mui/material';

interface Props {
  children: JSX.Element;
  title: string;
  tooltipProps?: Partial<TooltipProps>;
  stopPropagation?: boolean;
}

export default function ResponsiveTooltip({
  children,
  title,
  tooltipProps,
  stopPropagation = false,
}: Props) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setIsTooltipOpen(false)}>
      <Tooltip
        disableFocusListener
        disableHoverListener
        disableTouchListener
        arrow
        placement={tooltipProps?.placement || 'top'}
        open={isTooltipOpen}
        title={title}
        {...tooltipProps}
      >
        <div
          onClick={(e) => {
            stopPropagation && e.stopPropagation();
            setIsTooltipOpen(true);
          }}
          onPointerEnter={() => setIsTooltipOpen(true)}
          onPointerLeave={() => setIsTooltipOpen(false)}
          className="responsive-tooltip-child-wrapper"
        >
          {children}
        </div>
      </Tooltip>
    </ClickAwayListener>
  );
}
