import { useEffect, useRef, useState } from 'react';
import {
  Stack,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Review } from 'src/@types/common';
import Drawer from 'src/components/DrawerWithAction';
import useLocales from 'src/hooks/useLocales';
import Input from '../Input/Input';
import InputWrapper from '../InputWrapper';
import Scrollbar from '../Scrollbar';
import commonAPI from 'src/api/api';
import { fNumber } from 'src/utils/formatNumber';
import ReviewRating from './ReviewRating';
import ScrollToErrorWrapper from '../ScrollToErrorWrapper';

interface Props {
  review: Review;
  isOpen: boolean;
  setClose: VoidFunction;
  update: (review: Review) => void;
  mode?: 'edit' | 'create';
}

export default function EditReviewDrawer({
  review,
  isOpen,
  setClose,
  update,
  mode = 'edit',
}: Props) {
  const { translate } = useLocales(['listings', 'common']);

  const isReviewFromChannels = !!(review.origin && review.origin !== 'manual');
  const ratingsArray = [
    {
      value: 5,
      label: translate('fantastic'),
    },
    {
      value: 4,
      label: translate('very_good'),
    },
    {
      value: 3,
      label: translate('satisfied'),
    },
    {
      value: 2,
      label: translate('average'),
    },
    {
      value: 1,
      label: translate('dissatisfied'),
    },
  ];

  const initialState = {
    title: review.title,
    review_text: review.review_text,
    rate: review.rate,
    review_date: review.review_date,
    approved: !!review.approved,
    feature_on_home_page: review.feature_on_home_page,
  };
  const formValues = useRef<{
    title: Review['title'];
    review_text: Review['review_text'];
    rate: Review['rate'];
    review_date: Review['review_date'];
    approved: Review['approved'];
    feature_on_home_page: Review['feature_on_home_page'];
  }>(initialState);

  const [contentError, setContentError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(review.review_date);
  const [rateState, setRateState] = useState({ value: review.rate || undefined, error: false });
  const [isPublishOnWebsite, setIsPublishOnWebsite] = useState(review.approved);
  const [isFeaturedOnHomepage, setIsFeaturedOnHomepage] = useState(review.feature_on_home_page);

  const onClose = () => {
    setClose();
  };

  useEffect(() => {
    if (isOpen) {
      formValues.current = initialState;
      setContentError(false);
      setDate(review.review_date);
      setRateState((prev) => ({ ...prev, value: review.rate || undefined, error: false }));
      setIsPublishOnWebsite(review.approved);
      setIsFeaturedOnHomepage(review.feature_on_home_page);
    }
  }, [isOpen]);

  const onSave = () => {
    setIsLoading(true);
    const { rate, review_text } = formValues.current;
    if (
      (mode === 'create' || (mode === 'edit' && review.origin === 'manual')) &&
      ((!rate && rate !== 0) || !review_text)
    ) {
      if (!rate && rate !== 0) {
        setRateState((prev) => ({ ...prev, error: true }));
      }
      if (!review_text) {
        setContentError(true);
      }
      setIsLoading(false);
    } else {
      (mode === 'edit'
        ? commonAPI.updateAReview(review.id, formValues.current)
        : commonAPI.createAReview({
            ...formValues.current,
            guest: review.guest.id,
            booking: review.booking?.id,
          })
      )
        .then((res) => {
          setIsLoading(false);
          update(res.data);
          onClose();
        })
        .catch(() => {});
    }
  };

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      anchor="right"
      title={mode === 'edit' ? translate('edit_review') : translate('add_a_review')}
      actions={
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Button variant="underline" onClick={onClose} color="inherit">
            {translate('cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={isLoading}
            onClick={onSave}
            sx={(theme) => ({
              minWidth: '64px',
              minHeight: '36px',
            })}
          >
            {mode === 'edit' ? translate('save') : translate('add')}
          </LoadingButton>
        </Stack>
      }
    >
      <Scrollbar>
        <Stack gap={2}>
          <Stack sx={(theme) => ({ background: theme.palette.grey[200], px: 3, py: 2 })}>
            {[
              {
                content: review.booking?.booking_no,
                label: translate('booking_number'),
              },
              {
                content:
                  !review.guest?.first_name && !review.guest?.last_name
                    ? translate('anonymous')
                    : `${review.guest.first_name || ''} ${review.guest.last_name || ''}`.trim(),
                label: translate('guest'),
              },
              ...(review.property?.name
                ? [
                    {
                      content: review.property.name,
                      label: translate('listing'),
                    },
                  ]
                : []),
            ].map((item) =>
              item.content ? (
                <Stack
                  key={item.content}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={1}
                  gap={2}
                >
                  <Typography variant="body2">{item.content}</Typography>
                  <Typography variant="caption" color="text.disabled">
                    {item.label}
                  </Typography>
                </Stack>
              ) : null
            )}
          </Stack>
          <ScrollToErrorWrapper trigger={contentError}>
            <Stack mx={3} gap={3}>
              {!isReviewFromChannels || mode === 'create' ? (
                <>
                  <Input
                    label={translate('title')}
                    defaultValue={review.title || ''}
                    isOptional
                    callback={(val) => {
                      formValues.current.title = val;
                    }}
                  />
                  <Input
                    label={translate('content')}
                    defaultValue={formValues.current.review_text || ''}
                    callback={(val) => {
                      formValues.current.review_text = val;
                      if (!val) setContentError(true);
                      else setContentError(false);
                    }}
                    textFieldProps={{
                      error: contentError,
                      multiline: true,
                      minRows: 5,
                      helperText: contentError ? translate('please_write_a_content') : '',
                      sx: (theme) => ({
                        mb: 4,
                      }),
                    }}
                  />
                  <InputWrapper title={translate('rate')}>
                    <Select
                      labelId="rate-select-label"
                      id="rate-select"
                      value={rateState.value}
                      renderValue={(value) => (
                        <Typography variant="body1">
                          {ratingsArray.find((item) => item.value === Number(fNumber(value)))?.label}
                          <Typography variant="caption" color="text.secondary">
                            &nbsp; | &nbsp;
                            {`${value} ${translate(value === 1 ? 'star' : 'stars')}`}
                          </Typography>
                        </Typography>
                      )}
                      onChange={(e) => {
                        formValues.current.rate = Number(e.target.value as string);
                        setRateState((prev) => ({
                          ...prev,
                          value: Number(e.target.value as string),
                          error: false,
                        }));
                      }}
                      error={rateState.error}
                      sx={{ height: '48px' }}
                    >
                      {ratingsArray.map((item) => (
                        <MenuItem key={item.label} value={item.value}>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            width="100%"
                            height={28}
                          >
                            <Typography>{item.label}</Typography>
                            <ReviewRating rate={item.value} hideRateNumber />
                          </Stack>
                        </MenuItem>
                      ))}
                    </Select>
                    {rateState.error ? (
                      <FormHelperText sx={(theme) => ({ color: theme.palette.error.main })}>
                        {translate('please_select_a_rate')}
                      </FormHelperText>
                    ) : null}
                  </InputWrapper>
                  <InputWrapper title={translate('date')}>
                    <DatePicker
                      format="dd/MM/yyyy"
                      value={new Date(date || '')}
                      onChange={(date) => {
                        const dateInString = date?.toISOString();
                        setDate(dateInString || '');
                        formValues.current.review_date = dateInString || '';
                      }}
                    />
                  </InputWrapper>
                </>
              ) : (
                <Typography variant="body1" color="text.secondary">
                  {translate('edit_review_from_channel_description')}
                </Typography>
              )}
              <FormControlLabel
                control={<Checkbox />}
                label={<>{translate('publish_on_website')}</>}
                onChange={(e, checked) => {
                  formValues.current.approved = checked;
                  setIsPublishOnWebsite(checked);
                }}
                checked={!!isPublishOnWebsite}
              />
              {isPublishOnWebsite ? (
                <FormControlLabel
                  control={<Checkbox />}
                  label={<>{translate('feature_on_homepage')}</>}
                  onChange={(e, checked) => {
                    formValues.current.feature_on_home_page = checked;
                    setIsFeaturedOnHomepage(checked);
                  }}
                  checked={!!isFeaturedOnHomepage}
                />
              ) : null}
            </Stack>
          </ScrollToErrorWrapper>
        </Stack>
      </Scrollbar>
    </Drawer>
  );
}
