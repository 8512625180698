import {
  Button, Stack, Chip,
  Checkbox, Box, Typography, Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  CheckboxProps,
  MenuItemProps,
  ChipProps,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderGroupParams,
  AutocompleteRenderOptionState,
} from "@mui/material";
import i18next from "i18next";
import { HTMLAttributes, ReactNode } from "react";
import Iconify from "src/components/Iconify";
import TextMaxLine from "src/components/TextMaxLine";
import boldPartOfText from "src/utils/boldPartOfText";
import { generateUniqSerial } from "./generateUniqSerial";

export const popupIcon: ReactNode = (
  <Iconify
    width={24}
    height={24}
    icon="mdi:chevron-down"
  />
);

export const getLimitTagsText = (more: number): ReactNode => (
  <Button variant="outlined" color="primary">
    {`${more} ${i18next.t('more')}`}
  </Button>
);

export const renderTags = (getOptionLabel?: (option: any) => string, ChipProps?: ChipProps) => (value: any[], getTagProps: AutocompleteRenderGetTagProps): ReactNode => (
  value.map((option, index) => (
    <Chip
      {...getTagProps({ index })}
      key={getTagProps({ index }).key}
      variant="outlined"
      label={(
        <TextMaxLine fontWeight="fontWeightSemiBold" variant="body2" whiteSpace="normal" line={1}>
          {getOptionLabel?.(option)}
        </TextMaxLine>
      )}
      {...ChipProps}
    />
  ))
);

export const renderGroup = ({ group, key, children }: AutocompleteRenderGroupParams): ReactNode => {
  const firstGroup = +key === 0;
  return (
    <Stack>
      {!firstGroup && <Divider sx={{ my: 1.5 }} />}
      <Stack component="li" position="relative" key={key}>
        <Box position="sticky" top={0} left={0} zIndex={1} bgcolor="background.paper" width="100%">
          <Typography variant="overline" color="text.secondary" fontWeight="fontWeightBold" ml={0.5} gutterBottom>
            {group}
          </Typography>
        </Box>
        <Stack gap={0.5}>
          {children}
        </Stack>
      </Stack>
    </Stack>
  );
};

export const renderOption = ({
  checkbox = false,
  boldSubtitle = false,
  CheckBoxProps,
  MenuItemProps,
  getOptionLabel,
  getOptionDetail,
  getCheckBoxProps,
  getMenuItemProps,
  getOptionSubtitle,
}:{
  checkbox?: boolean,
  boldSubtitle?: boolean;
  CheckBoxProps?: CheckboxProps;
  MenuItemProps?: MenuItemProps;
  getOptionLabel: (option: any) => string,
  getOptionDetail?: (option: any) => JSX.Element | undefined,
  getOptionSubtitle?: (option: any) => string | JSX.Element,
  getCheckBoxProps?: (option: any) => CheckboxProps;
  getMenuItemProps?: (option: any) => MenuItemProps;
}) => (props: HTMLAttributes<HTMLLIElement>, option: any, { selected, inputValue }: AutocompleteRenderOptionState): ReactNode => {
  const title = getOptionLabel?.(option) || '';
  const subtitle = getOptionSubtitle?.(option) || '';

  const renderTitle = (title: string, bold: boolean) => (
    <Box
      noWrap
      line={1}
      whiteSpace="normal"
      component={TextMaxLine}
      fontWeight="fontWeightRegular"
      dangerouslySetInnerHTML={{ __html: bold ? boldPartOfText(title, inputValue) : title }}
    />
  );

  return (
    <MenuItem
      {...props}
      key={generateUniqSerial()}
      selected={selected}
      {...MenuItemProps}
      {...getMenuItemProps?.(option)}
      sx={(theme) => ({ gap: 1, padding: `${theme.spacing(0.5, 1)} !important` })}
    >
      {checkbox && (
        <ListItemIcon>
          <Checkbox
            size="small"
            checked={selected}
            {...CheckBoxProps}
            {...getCheckBoxProps?.(option)}
          />
        </ListItemIcon>
      )}
      <ListItemText
        primary={renderTitle(title, true)}
        {...(subtitle && {
          secondary: typeof subtitle === 'string' ? renderTitle(subtitle, boldSubtitle) : subtitle,
        })}
      />
      {getOptionDetail?.(option)}
    </MenuItem>
  );
};

