import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Preference, PreferenceSetting } from 'src/@types/preference';
import preferenceApi from 'src/api/preference';
import { dispatch } from '../store';

const getSettingStateValue = (state: PreferenceState, categoryName: string, settingKey: string) => {
  let oldSetting;
  let currentPreferenceIndex;
  let categoryIndex;
  let settingIndex;
  const currentPreference = state.preferences.find((tab) => tab.group === state.currentGroup);
  if (currentPreference) {
    const category = currentPreference.categories.find(
      (category) => category.name === categoryName
    );
    if (category) {
      oldSetting = category.settings.find((setting) => setting.key === settingKey);
      if (oldSetting) {
        currentPreferenceIndex = state.preferences.indexOf(currentPreference);
        categoryIndex = currentPreference.categories.indexOf(category);
        settingIndex = category.settings.indexOf(oldSetting);
      }
    }
  }
  return {
    oldSetting,
    currentPreferenceIndex,
    categoryIndex,
    settingIndex,
  };
};

// types
type PreferenceState = {
  groups: string[];
  currentGroup: string;
  preferences: Preference[];
  selectedBrandId: number | null;
  paymentMethods: string[];
};

const initialState: PreferenceState = {
  groups: [],
  currentGroup: '',
  preferences: [],
  selectedBrandId: null,
  paymentMethods: [],
};

const slice = createSlice({
  name: 'preference',
  initialState,
  reducers: {
    setCurrentGroup(state, action: PayloadAction<string>) {
      state.currentGroup = action.payload;
    },
    setPreferences(state, action: PayloadAction<Preference[]>) {
      state.preferences = action.payload;
      state.groups = action.payload.map((pref) => pref.group);
      state.currentGroup = action.payload[0]?.group || 'booking';
    },
    setSelectedBrandId(state, action: PayloadAction<number>) {
      state.selectedBrandId = action.payload;
    },
    setPaymentMethods(state, action: PayloadAction<{ [x: string]: string }>) {
      state.paymentMethods = Object.values(action.payload);
    },
    updateSettingValue(
      state,
      action: PayloadAction<{
        categoryName: string;
        settingKey: string;
        settingValue: any;
      }>
    ) {
      const { oldSetting, currentPreferenceIndex, categoryIndex, settingIndex } =
        getSettingStateValue(state, action.payload.categoryName, action.payload.settingKey);
      state.preferences[currentPreferenceIndex].categories[categoryIndex].settings[settingIndex] = {
        ...oldSetting,
        value: action.payload.settingValue,
      };
    },
  },
});

// reducer
export default slice.reducer;
// actions
export const {
  setCurrentGroup,
  setPreferences,
  setSelectedBrandId,
  updateSettingValue,
  setPaymentMethods,
} = slice.actions;
// ----------------------------------------------------------
export function updatePreferenceSetting(
  brandId: number,
  categoryName: string,
  setting: PreferenceSetting,
  value
) {
  return async () => {
    try {
      dispatch(
        slice.actions.updateSettingValue({
          categoryName,
          settingKey: setting.key,
          settingValue: value,
        })
      );
      await preferenceApi.updatePreferenceSetting({ brand:brandId, key:setting.key, value: value });
    } catch (error) {
      dispatch(
        slice.actions.updateSettingValue({
          categoryName,
          settingKey: setting.key,
          settingValue: setting.value,
        })
      );
    }
  };
}
