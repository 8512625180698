import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notificatonBellType } from 'src/@types/common';
import notificationsAPI from 'src/api/notifications';
import { dispatch } from '../store';

type NotificationState = {
  notificationResults: notificatonBellType[];
  notificationAmount: number;
  hasMoreNotification: boolean;
  loading: boolean;
};

export const getNotificationResult = createAsyncThunk(
  'notification/getNotification', async ({ pageNumber, language }:{pageNumber: number, language: string}) => {
    try {
      const normalizedNotificationResults: NotificationState = {
        notificationResults: [],
        notificationAmount: 0,
        hasMoreNotification: true,
        loading: false,
      };
      const response = await notificationsAPI.fetchNotificationList(pageNumber, language);
      normalizedNotificationResults.notificationResults = response.data.response;
      normalizedNotificationResults.hasMoreNotification = response.data.response.length % 5 === 0 && response.data.response.length !== 0;
      return normalizedNotificationResults;
    } catch (error) {}
  }
)

const initialState: NotificationState = {
  notificationResults: [],
  notificationAmount: 0,
  hasMoreNotification: true,
  loading: false,
};

const slice = createSlice({
  name: 'notificationBell',
  initialState,
  reducers: {
    setNotificationResults(state, action) {
      state.notificationResults = action.payload;
    },
    addNotificationResults(state, action) {
      state.notificationResults = [action.payload, ...state.notificationResults];
    },
    setNotificationAmount(state, action) {
      state.notificationAmount = action.payload;
    },
    clearNotificationList: (state) => {
      state.hasMoreNotification = initialState.hasMoreNotification;
      state.loading = initialState.loading;
      state.notificationResults = initialState.notificationResults;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotificationResult.fulfilled, (state, action) =>{
      if(action.payload?.notificationResults){
        state.notificationResults = state.notificationResults.concat(action.payload.notificationResults);
        state.hasMoreNotification = action.payload.hasMoreNotification;
      }
      state.loading = false;
    });
    builder.addCase(getNotificationResult.pending, (state, action) => {
      state.loading = true;
    });
  }
})

export default slice.reducer;
export const { setNotificationResults, addNotificationResults, clearNotificationList, setNotificationAmount } = slice.actions;


export function getNotificationCount () {
  return async () => {
    try {
      const res = await notificationsAPI.fetchNotificationListAmount();
      dispatch(slice.actions.setNotificationAmount(res.data.amount))
    } catch (error) {}
  };
}
