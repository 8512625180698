import { createSlice } from '@reduxjs/toolkit';
import { Dispatch, SetStateAction } from 'react';
import { batch } from 'react-redux';
import { TaskPriority, TaskStatus, TaskViewState } from 'src/@types/tasks';
import tasksAPI from 'src/api/tasks';
import { PATH_PAGE } from 'src/routes/paths';
import { fTimestamp } from 'src/utils/formatTime';
import { dispatch } from '../../store';

// ----------------------------------------------------------------------

const initialState: TaskViewState = {
  loading: false,
  taskDetail: {
    id: null,
    name: '',
    status: '',
    priority: '',
    type: '',
    category: '',
    property_id: null,
    unit_id: null,
    assignee: null,
    isUnassigned: false,
    can_start_from: 0,
    must_finished_by: 0,
    timezone: '',
    estimation: 0,
    address: '',
    bed_config: [],
    upcoming_booking: null,
    previous_booking: null,
    notes: [],
    startTime: null,
    endTime: null,
    duration: null,
    attachments: [],
    reports: [],
    createdAt: 0,
    updatedAt: 0,
    housekeeping_location: {
      start_latitude: '',
      end_latitude: '',
      start_longitude: '',
      end_longitude: '',
    },
  },
};

const slice = createSlice({
  name: 'task-view',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setTaskDetail(state, action) {
      state.taskDetail = action.payload;
    },
    setPriority(state, action) {
      state.taskDetail.priority = action.payload;
    },
    setStatus(state, action) {
      state.taskDetail.status = action.payload;
    },
    setStartTime(state, action) {
      state.taskDetail.startTime = action.payload;
    },
    setEndTime(state, action) {
      state.taskDetail.endTime = action.payload;
    },
    setAssignee(state, action) {
      state.taskDetail.assignee = action.payload;
      state.taskDetail.isUnassigned = action.payload === null ? true : false;
    },
    setCanStartFrom(state, action) {
      state.taskDetail.can_start_from = action.payload;
    },
    setMustFinishBy(state, action) {
      state.taskDetail.must_finished_by = action.payload;
    },
    setEstimation(state, action) {
      state.taskDetail.estimation = action.payload;
    },
    addNote(state, action) {
      state.taskDetail.notes = [
        ...state.taskDetail.notes,
        action.payload,
      ];
    },
    editNote(state, action) {
      const index = state.taskDetail.notes.findIndex((note) => note.note_id === action.payload.note_id);
      state.taskDetail.notes.splice(index, 1, action.payload);
    },
    deleteNote(state, action) {
      state.taskDetail.notes = state.taskDetail.notes.filter((note) => note.note_id !== action.payload);
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addNote,
  setAssignee,
  setStatus,
  setCanStartFrom,
  setMustFinishBy,
  setEstimation,
} = slice.actions;

// ----------------------------------------------------------------------

export function getTaskDetail(taskId: number, navigate: Function) {
  return async () => {
    dispatch(slice.actions.setLoading(true));
    try {
      const response = await tasksAPI.fetchTask(taskId);
      const { progress_status, status, assignee } = response.data;
      dispatch(slice.actions.setTaskDetail({
        ...response.data,
        status: progress_status || 'not_started',
        isUnassigned: assignee.full_name.trim() === 'nobody',
        assignee: assignee.full_name.trim() === 'nobody' ? null : assignee,
        ...(status?.toLowerCase() === 'pending' && { startTime: response.data.updatedAt }),
      }));
    } catch (error) {
      if (~error?.data?.message?.indexOf('There is no id')) {
        navigate(PATH_PAGE.page404);
      } else if (error?.data?.code === 403) {
        navigate(PATH_PAGE.page403);
      }
    }
    dispatch(slice.actions.setLoading(false));
  };
};

export function changeTaskPriority(taskId: number, newPriority: TaskPriority, prevPriority: TaskPriority) {
  return async () => {
    try {
      dispatch(slice.actions.setPriority(newPriority));
      await tasksAPI.changeTaskPriority(taskId, newPriority);
    } catch (error) {
      dispatch(slice.actions.setPriority(prevPriority));
    }
  };
};

export function changeTaskStatus(
  taskId: number,
  status: TaskStatus,
  setLoading: Dispatch<SetStateAction<boolean>>,
  callback: () => void,
  startedAt?: string,
  finishedAt?: string,
) {
  return async () => {
    setLoading(true);
    try {
      const response = await tasksAPI.changeTaskStatus(taskId, status, startedAt, finishedAt);
      dispatch(slice.actions.setStatus(response.data.status.toLowerCase()));
      if (status === 'cancelled') dispatch(slice.actions.setAssignee(null));
      if (startedAt && finishedAt) {
        batch(() => {
          dispatch(slice.actions.setStartTime(fTimestamp(new Date(response.data.started_at))));
          dispatch(slice.actions.setEndTime(fTimestamp(new Date(response.data.finished_at))));
        })
      }
      callback();
    } catch (error) {}
    setLoading(false);
  };
};

export function reopenTaskAutomatically(
  taskId: number,
  setLoading: Dispatch<SetStateAction<boolean>>,
  callback: () => void,
) {
  return async () => {
    setLoading(true);
    try {
      await tasksAPI.reopenTaskAutomatically(taskId);
      dispatch(setStatus('not_started'));
      callback();
    } catch (error) {}
    setLoading(false);
  };
};

export function reopenTaskManually(
  taskId: number,
  body: {
    can_start_from: string,
    must_finish_by: string,
    estimation: number,
    housekeeper_id?: number,
    priority: TaskPriority,
    note?: string,
  },
  setLoading: Dispatch<SetStateAction<boolean>>,
  callback: () => void,
) {
  return async () => {
    setLoading(true);
    try {
      const response = await tasksAPI.reopenTaskManually(taskId, body);
      const {
        can_start_from,
        must_finished_by,
        noteId,
        avatar_url,
        full_name,
        housekeeper_id,
      } = response.data
      dispatch(slice.actions.setStatus('not_started'));
      dispatch(slice.actions.setCanStartFrom(can_start_from));
      dispatch(slice.actions.setMustFinishBy(must_finished_by));
      dispatch(slice.actions.setEstimation(body.estimation));
      dispatch(slice.actions.setAssignee({ user_id: housekeeper_id, full_name, avatar_url }));
      dispatch(slice.actions.setPriority(body.priority));
      if (body.note) dispatch(slice.actions.addNote({ note_id: noteId, text: body.note }));
      callback();
    } catch (error) {}
    setLoading(false);
  };
};

export function approveTask(
  taskId: number,
  setLoading: Dispatch<SetStateAction<boolean>>,
  callback: () => void,
) {
  return async () => {
    setLoading(true);
    try {
      await tasksAPI.approveTask(taskId);
      dispatch(slice.actions.setStatus('completed'));
      callback();
    } catch (error) {}
    setLoading(false);
  };
};

export function editTaskNote(
    taskId: number,
    noteId: number,
    note_text: string,
    setLoading: Dispatch<SetStateAction<boolean>>,
    callback: () => void,
  ) {
  return async () => {
    setLoading(true);
    try {
      await tasksAPI.editNote(taskId, noteId, note_text);
      dispatch(slice.actions.editNote({ note_id: noteId, text: note_text }));
      callback();
    } catch (error) {}
    setLoading(false);
  };
};
export function deleteTaskNote(taskId: number, noteId: number) {
  return async () => {
    try {
      await tasksAPI.deleteNote(taskId, noteId);
      dispatch(slice.actions.deleteNote(noteId));
    } catch (error) {}
  };
};

export function addNewTask(
  body: {
    property_id: number,
    unit_id: number,
    can_start_from: string,
    must_finish_by: string,
    estimation: number,
    housekeeper_id?: number,
    priority: TaskPriority,
    note?: string,
  },
  setLoading: Dispatch<SetStateAction<boolean>>,
  callback: (newTaskId: number) => void,
) {
  return async () => {
    setLoading(true);
    try {
      const response = await tasksAPI.createTask(body);
      callback(response.data.taskId);
    } catch (error) {}
    setLoading(false);
  };
};

