const mb = (margin: number) => `margin-bottom: ${margin}px;`;
const generateInlineDivider = (separator: 'dot' | 'line' | 'comma', display: boolean) => `<span data-divider="true" style="${separator === 'line' ? 'margin: 0px 8px 0px 4px; border-left: solid 1px rgba(145, 158, 171, 0.5);' : ''}; display: ${display ? 'inline' : 'none'};">${separator === 'dot' ? '·' : separator === 'comma' ? ',' : ''}</span>`.replace(/\n/g, '');

export const defaultHeader = `
  <div data-container-of="logo" style="display: none; ${mb(8)}"></div>
  <div data-group-display="block" style="margin-top: 8px;">
    <div data-container-of="company_name" style="display: none;"></div>
  </div>
`;

export const defaultFooter = `
  <div data-container-of="slogan" style="display: none; font-size: 17px; line-height: 24px; ${mb(24)}"></div>
  <div data-group-display="block" style="${mb(24)}">
    <div data-container-of="facebook_account" style="display: none; margin: 0 8px;"></div>
    <div data-container-of="instagram_account" style="display: none; margin: 0 8px;"></div>
    <div data-container-of="linkedin_account" style="display: none; margin: 0 8px;"></div>
    <div data-container-of="twitter_account" style="display: none; margin: 0 8px;"></div>
    <div data-container-of="pinterest_account" style="display: none; margin: 0 8px;"></div>
    <div data-container-of="youtube_account" style="display: none; margin: 0 8px;"></div>
    <div data-container-of="whatsapp_account" style="display: none; margin: 0 8px;"></div>
    <div data-container-of="discord_account" style="display: none; margin: 0 8px;"></div>
  </div>
  <div data-group-display="block" data-contains-divider="true" style="${mb(8)}">
    <div data-container-of="primary_phone" style="display: none;"></div>
    ${generateInlineDivider('line', false)}
    <div data-container-of="secondary_phone" style="display: none;"></div>
  </div>
  <div data-container-of="lines_open" style="display: none; ${mb(8)}"></div>
  <div data-group-display="block" data-contains-divider="true" style="display: none; color: rgb(24, 144, 255); ${mb(24)}">
    <div data-container-of="display_email" style="display: none;"></div>
    ${generateInlineDivider('line', false)}
    <div data-container-of="web_address" style="display: none;"></div>
    ${generateInlineDivider('line', false)}
    <div data-container-of="zeevou_subdomain" style="display: none;"></div>
  </div>
  <div data-group-display="block" data-contains-divider="true" style="display: none !important; ${mb(24)}">
    <span data-container-of="brand_first_line_of_address" style="display: none;"></span>${generateInlineDivider('comma', false)}
    <span data-container-of="brand_second_line_of_address" style="display: none;"></span>${generateInlineDivider('comma', false)}
    <span data-container-of="brand_city" style="display: none;"></span>${generateInlineDivider('comma', false)}
    <span data-container-of="brand_county_state" style="display: none;"></span>${generateInlineDivider('comma', false)}
    <span data-container-of="brand_country" style="display: none;"></span>${generateInlineDivider('comma', false)}
    <span data-container-of="brand_postal_code" style="display: none;"></span>
  </div>
  <div data-group-display="block" data-contains-divider="true" style="${mb(24)}">
    <div data-container-of="registered_company_name" style="display: none;"></div>
    ${generateInlineDivider('line', false)}
    <div data-container-of="company_registration_number" style="display: none;"></div>
  </div>
  <div style="color: #919EAB;">Powered by Zeevou</div>
`.replace(/\n/g, '');

const wrapInTable = (content: string) => `
<center style="width: 100%; table-layout: fixed; background-color: transparent;">
  <table style="background-color: #ffffff; margin: 0 auto; width: 100%; max-width: 600px; border-spacing: 0; padding: 0 32px;">
    ${content}
  </table>
</center>
`;
export const generateEmailTemplate = (header: string, body: string, footer: string) => wrapInTable(`
  <tr id="header">
    <td style="text-align: center; padding: 32px 0;">
      ${header}
    </td>
  </tr>
  <tr id="body">
    <td>
      ${body}
    </td>
  </tr>
  <tr id="footer">
    <td style="text-align: center; font-size: 12px; line-height: 16px; color: #454F5B; padding: 24px 0 16px">
      ${footer}
    </td>
  </tr>
`);
export const generateEmailTemplateHeader = (header: string) => wrapInTable(`
  <tr id="header">
    <td style="text-align: center; padding: 32px 0;">
      ${header}
    </td>
  </tr>
`);
export const generateEmailTemplateFooter = (footer: string) => wrapInTable(`
  <tr id="footer">
    <td style="text-align: center; font-size: 12px; line-height: 16px; color: #454F5B; padding: 24px 0 16px">
      ${footer}
    </td>
  </tr>
`);
