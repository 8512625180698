import {
  SettingKey,
  WhenRadioValues,
  WhenState,
  SelectedCondition,
  GuestNotification,
  AutomatedMessage,
  AutomatedMessageNotificationRow,
  ScheduledAutomatedMessageCard,
  MessageContent,
  MessageType,
  MessageAttachment,
} from "src/@types/automated-message";
import { Variable } from "src/@types/common";
import { generateVariableElement } from "./variables";
import automatedMessageEnLocale from 'src/locales/en/automatedMessage.json';
import { TFunction } from "react-i18next";
import MOCK_CONDITIONS from "src/_mock/conditions";
import { compareTimeStrings, fDateSeparated } from "./formatTime";
import { isAfter } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { isEmptyString } from "./stringUtils";

export const MESSAGE_TYPES_CONFIG: MessageType[] = ['email', 'sms', 'api'];

export const provideVariables = (variablesObject) => {
  const { header, body, footer } = variablesObject;
  const headerVariables = Object.values<Variable>(header);
  const bodyVariables = Object.values<Variable>(body);
  const footerGroupNames = Object.keys(footer);
  const footerVariables: Variable[] = [];
  footerGroupNames.forEach((groupName) => {
    const variables = Object.values<Variable>(footer[groupName]);
    variables.forEach((variable) => {
      variable.group_name = groupName.split('.')[2];
      footerVariables.push(variable);
    });
  });
  return { headerVariables, bodyVariables, footerVariables };
}

export const checkShouldDisplayDivider = (divider) => {
  let nextSibling = divider.nextElementSibling;
  let previousSibling = divider.previousElementSibling;
  let nextSiblingExists = false;
  let previousSiblingExists = false;
  while (nextSibling) {
    if (nextSibling?.getAttribute('data-divider') === 'true' && nextSibling.style.display === 'inline') break;
    if (nextSibling.style.display === 'inline-block') {
      nextSiblingExists = true;
      break;
    }
    nextSibling = nextSibling.nextElementSibling;
  }
  while (previousSibling) {
    if (previousSibling?.getAttribute('data-divider') === 'true' && previousSibling.style.display === 'inline') break;
    if (previousSibling.style.display === 'inline-block') {
      previousSiblingExists = true;
      break;
    }
    previousSibling = previousSibling.previousElementSibling;
  }
  return nextSiblingExists && previousSiblingExists;
};

const getIdOfVariableContainer = (variableValue: string) => variableValue.replace('{{ entity | ', '').split(' ')[0];
export const onVariableSelection = (content: string, checked: boolean, variable: any) => {
  const div = document.createElement('div');
  div.innerHTML = content;
  const container: HTMLElement | null = div.querySelector(`[data-container-of="${getIdOfVariableContainer(variable.value)}"]`);
  if (container) {
    const parent = container.parentElement;
    const groupContainerDisplay = parent?.getAttribute('data-group-display');
    if (checked) {
      if (parent && groupContainerDisplay) parent.style.display = groupContainerDisplay;
      container.innerHTML = generateVariableElement(variable);
      container.style.display = 'inline-block';
    } else {
      let isThereVariableToShow = false;
      container.innerHTML = '';
      container.style.display = 'none';
      if (parent?.children.length !== undefined) {
        for (let i = 0; i < parent?.children.length; i++) {
          const child = parent?.children[i] as HTMLElement | null;
          if (child?.style.display !== 'none') {
            isThereVariableToShow = true;
            break;
          }
        }
      }
      if (parent && groupContainerDisplay && !isThereVariableToShow) parent.style.display = 'none';
    }
    if (parent?.getAttribute('data-contains-divider') === 'true') {
      const dividerElements: NodeListOf<HTMLElement> = parent.querySelectorAll('[data-divider="true"]');
      dividerElements.forEach((divider) => {
        divider.style.display = 'none';
        const shouldDisplayDivider = checkShouldDisplayDivider(divider);
        if (shouldDisplayDivider) divider.style.display = 'inline';
      })
    }
  }
  return div.innerHTML;
};

export const convertWhen = (automatedMessageWhen) => {
  if (!automatedMessageWhen) {
    return { whenRadioValue: 'after_all_conditions' as WhenRadioValues }
  }
  return {
    whenRadioValue: 'based_on_booking_timeline' as WhenRadioValues,
    timelineValue: automatedMessageWhen.variable || 'createdAt',
    timeComparisonValue: automatedMessageWhen.operator_1 || 'on',
    timeVariationAmountValue: automatedMessageWhen.value || 1,
    timeVariationValue: automatedMessageWhen.operator_2 || 'day',
  }
};

const generateConditionValueByGroupName = (value: null | string, value2: null | string, groupName: string) => {
  const newValues = value?.split(',') || [];
  const newValues2 = value2?.split(',') || [];
  switch (groupName) {
    case 'property':
      return newValues.map((val, i) => ({ id: Number(val) || val, name: newValues2[i] }));
    default:
      return newValues;
  }
};

export const convertConditions = (automatedMessageConditions) => ({
  name: automatedMessageConditions.label,
  group_name: automatedMessageConditions.group_name,
  value: generateConditionValueByGroupName(automatedMessageConditions.value, automatedMessageConditions.value2, automatedMessageConditions.group_name),
  operators: [automatedMessageConditions.operator_1, automatedMessageConditions.operator_2],
  variable: automatedMessageConditions.variable?.split(',') || []
});

export const getSettingName = (key: SettingKey) => {
  switch (key) {
    case 'auto response':
      return 'autoResponse'
    case 'email signature':
      return 'emailSignitures'
    case 'language':
      return 'languages'
    case 'reply to':
      return 'replyTos'
    default:
      return ''
  }
}

export const getWhenSummaryText = (when: WhenState, translate?: TFunction<any[], undefined>): string => {
  const getContentByTranslateKey = (key: string) => translate ? translate(key) : automatedMessageEnLocale[key] || key;
  const {
    whenRadioValue,
    timeComparisonValue,
    timeVariationAmountValue,
    timeVariationValue,
    timelineValue,
    timeFrame
  } = when;
  const isPlural = timeVariationAmountValue > 1;
  const isOn = timeComparisonValue === 'on';
  let text = '';
  switch (whenRadioValue) {
    case 'based_on_booking_timeline':
      text = [
        isOn ? '' : timeVariationAmountValue,
        isOn ? '' : getContentByTranslateKey(isPlural ? `${timeVariationValue}s` : timeVariationValue),
        getContentByTranslateKey(timeComparisonValue),
        getContentByTranslateKey(timelineValue)
      ].filter((text) => text).join(' ');
      text += timeFrame[0] && timeFrame[1] ? [
        ',',
        getContentByTranslateKey('between'),
        timeFrame[0].toLowerCase(),
        getContentByTranslateKey('to').toLowerCase(),
        timeFrame[1].toLowerCase(),
        ...(compareTimeStrings(timeFrame[0], timeFrame[1]) === 1
          ? [getContentByTranslateKey('the_following_day')]
          : []),
      ].join(' ') : '';
      break;
    case 'after_all_conditions':
      text = getContentByTranslateKey('after_all_conditions');
      break;
    default:
      text = '';
      break;
  }
  return `${text}.`;
}

export const getConditionSummaryText = (condition: SelectedCondition, translate?: TFunction<any[], undefined>): string => {
  const getContentByTranslateKey = (key: string) => translate ? translate(key) : automatedMessageEnLocale[key] || key;
  const { name, operators, value, variable, group_name } = condition;
  let sentence: string[] = [];
  MOCK_CONDITIONS(translate)[name].content.forEach((content, index, array) => {
    let text = '';
    if (content.type === 'text') text = content.options;
    if (content.type === 'date') text = fDateSeparated(value);
    if (content.type === 'button') text = getContentByTranslateKey(value);
    if (content.type === 'variable') text = getContentByTranslateKey(value);
    if (content.type === 'multi-select') {
      text = value?.map((item) => group_name === 'property' ? item.name : getContentByTranslateKey(item)).join(` ${getContentByTranslateKey('or')} `);
    }
    if (content.type === 'multi-button') {
      text = value?.map((item) => getContentByTranslateKey(item)).join(` ${getContentByTranslateKey('and')} `);
    }
    if (content.type === 'select' || content.type === 'select-range') {
      if (content.options === 'variables' && Array.isArray(variable)) {
        text = getContentByTranslateKey(variable?.join(','));
      } else if (content.options === 'operator_1') {
        text = getContentByTranslateKey(operators[0]);
      } else if (content.options === 'operator_2') {
        text = getContentByTranslateKey(operators[1]);
      } else text = getContentByTranslateKey(value.join(''));
    }
    text = index === array.length - 1 && content.type !== 'hint' && !text.endsWith('.') ? `${text}.` : text;
    sentence = [...sentence, text];
  });
  return sentence.join(' ');
}

export const provideConditionsSummaryText = (when: WhenState, selectedConditions: SelectedCondition[]): string => {
  let conditionsSentences: string[] = [];
  conditionsSentences = [...conditionsSentences, getWhenSummaryText(when)];
  selectedConditions.forEach((condition) => conditionsSentences = [...conditionsSentences, getConditionSummaryText(condition)]);
  return conditionsSentences.join(' ');
}

export const createNotificationStatus = (isEnabled: boolean, isSending: boolean, isSent: boolean, activationDate: Date | string | number, activationDateTimeStamp: number, expirationDate: Date | string | number, expirationDateTimeStamp: number, id: number, errorMessage: string | null, errorType: string | null, type: string): string => {
  const currentDate = utcToZonedTime(new Date(), 'UTC');
  const activationDateUTC = utcToZonedTime(new Date(activationDate), 'UTC');
  const expirationDateUTC = utcToZonedTime(new Date(expirationDate), 'UTC');

  if (isSending && isSent) {
    if (type === 'sms' && (errorMessage || errorType)) return 'error';
    return 'sent';
  } else if ((isEnabled && isAfter(currentDate, activationDateUTC) && isAfter(currentDate, expirationDateUTC) && isSending && !isSent) ||
    (isEnabled && isAfter(currentDate, activationDateUTC) && isAfter(currentDate, expirationDateUTC) && !isSending && !isSent) ||
    (isEnabled && !isAfter(currentDate, activationDateUTC) && !isAfter(currentDate, expirationDateUTC) && isSending && !isSent) ||
    (isEnabled && isAfter(currentDate, activationDateUTC) && !isAfter(currentDate, expirationDateUTC) && isSending && !isSent && errorMessage)
  ) {
    return 'error';
  } else if (isEnabled && !isAfter(currentDate, activationDateUTC) && !isAfter(currentDate, expirationDateUTC) && !isSending && !isSent) {
    return 'pending';
  } else if ((isEnabled && isAfter(currentDate, activationDateUTC) && !isAfter(currentDate, expirationDateUTC) && isSending && !isSent) ||
    (isEnabled && isAfter(currentDate, activationDateUTC) && !isAfter(currentDate, expirationDateUTC) && !isSending && !isSent)) {
    return 'sending';
  } else if (!isEnabled && !isAfter(currentDate, activationDateUTC) && !isAfter(currentDate, expirationDateUTC) && !isSending && !isSent) {
    return 'disabled';
  } else if ((!isEnabled && isAfter(currentDate, activationDateUTC) && !isAfter(currentDate, expirationDateUTC) && !isSending && !isSent) ||
    (!isEnabled && isAfter(currentDate, activationDateUTC) && isAfter(currentDate, expirationDateUTC) && !isSending && !isSent) ||
    (!isEnabled && isAfter(currentDate, activationDateUTC) && !isAfter(currentDate, expirationDateUTC) && isSending && !isSent) ||
    (!isEnabled && isAfter(currentDate, activationDateUTC) && isAfter(currentDate, expirationDateUTC) && isSending && !isSent)) {
    return 'cancelled';
  }
  return '';
}

export const createAutomatedMessageNotificationRow = (automatedMessageNotification: GuestNotification): AutomatedMessageNotificationRow => {
  const status = createNotificationStatus(
    automatedMessageNotification.enabled,
    automatedMessageNotification.sending,
    automatedMessageNotification.send,
    automatedMessageNotification.activation_date,
    automatedMessageNotification.activation_date_time_stamp,
    automatedMessageNotification.expiry_date,
    automatedMessageNotification.expiration_date_time_stamp,
    automatedMessageNotification.id,
    automatedMessageNotification.error_message,
    automatedMessageNotification.errorType,
    automatedMessageNotification.type);

  return {
    id: automatedMessageNotification.id,
    type: automatedMessageNotification.type,
    status: status,
    activation_date: automatedMessageNotification.activation_date,
    activation_date_time_stamp: automatedMessageNotification.activation_date_time_stamp,
    sent_at: automatedMessageNotification.sent_at,
    enabled: automatedMessageNotification.enabled,
    sent_manually: automatedMessageNotification.send_manually,
    error_type: automatedMessageNotification.errorType,
    error_message: automatedMessageNotification.error_message,
    message_body: automatedMessageNotification.messageBody,
    message_template: automatedMessageNotification.zeevou_message_template,
    to: automatedMessageNotification.to,
  }
};

export const createAutomatedMessageCard = (disabledAutomatedMessages: Array<number>, automatedMessage: AutomatedMessage, automatedMessageNotifications: Array<GuestNotification>): ScheduledAutomatedMessageCard => {
  let notifications = automatedMessageNotifications.map(notification => createAutomatedMessageNotificationRow(notification));
  let smsNotifications = notifications.filter(item => item.type === 'sms');
  let emailNotifications = notifications.filter(item => item.type === 'email');
  let apiNotifications = notifications.filter(item => item.type === 'api');
  notifications = [ ...smsNotifications, ...emailNotifications, ...apiNotifications];

  return {
    id: automatedMessage.id,
    name: automatedMessage.name,
    is_enabled: !disabledAutomatedMessages.includes(automatedMessage.id),
    is_active: automatedMessage.is_active,
    is_archived: automatedMessage.is_archived,
    is_notification_sent: !notifications.some(notification => notification.status !== 'sent'),
    notifications: notifications
  }
}

export const hasEmptyContentMessageOfAutomatedTemplate = (contents: MessageContent[]): boolean =>
  !!contents.find((content) => isEmptyString(content.messageText));

export const isEmptyToFieldOfAutomatedTemplate = (type: MessageType, toSelectedValues: any[]) =>
  type !== 'api' && toSelectedValues.length === 0;

export const hasUploadingAttachmentOfAutomatedTemplate = (attachments: MessageAttachment[]) =>
  attachments.some((attachment) => typeof attachment.uploadedPercent !== 'undefined')
