import { alpha, Theme } from '@mui/material/styles';
import { pxToRem } from 'src/utils/getFontValue';

// ----------------------------------------------------------------------

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    underline: true;
  }
}

// ----------------------------------------------------------------------

export default function Button(theme: Theme) {
  return {
    MuiButton: {
      variants: [
        {
          props: { variant: 'underline' },
          style: {
            textDecoration: 'underline',
            backgroundColor: 'transparent',
            '&:hover': {
              textDecoration: 'underline',
              backgroundColor: 'transparent',
            },
          },
        },
      ],
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.variant === 'underline' &&
            ownerState.color !== 'inherit' && {
              color: alpha(theme.palette[ownerState.color].main, 0.64),
            }),
          ...(ownerState.variant === 'underline' &&
            ownerState.color === 'inherit' && {
              color: theme.palette.text.secondary,
            }),
          '&:hover': {
            ...(ownerState.variant === 'underline' &&
              ownerState.color !== 'inherit' && {
                color: theme.palette[ownerState.color].main,
              }),
            ...(ownerState.variant === 'underline' &&
              ownerState.color === 'inherit' && {
                color: theme.palette.text.primary,
              }),
            boxShadow: 'none',
          },
        }),
        sizeSmall: {
          height: 32,
          fontSize: pxToRem(12),
          fontWeight: 600,
          lineHeight: 16 / 12,
          letterSpacing: '1.25px',
        },
        sizeMedium: {
          height: 40,
          fontSize: pxToRem(14),
          fontWeight: 600,
          lineHeight: 24 / 14,
          letterSpacing: '1.25px',
        },
        sizeLarge: {
          height: 48,
          fontSize: pxToRem(16),
          fontWeight: 600,
          lineHeight: 24 / 16,
          letterSpacing: '1.25px',
        },
        // contained
        containedSizeLarge: {
          paddingLeft: pxToRem(24),
          paddingRight: pxToRem(24),
          paddingTop: pxToRem(12),
          paddingBottom: pxToRem(12),
        },
        containedSizeMedium: {
          paddingLeft: pxToRem(16),
          paddingRight: pxToRem(16),
          paddingTop: pxToRem(8),
          paddingBottom: pxToRem(8),
        },
        containedSizeSmall: {
          paddingLeft: pxToRem(12),
          paddingRight: pxToRem(12),
          paddingTop: pxToRem(8),
          paddingBottom: pxToRem(8),
        },
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          boxShadow: theme.customShadows.primary,
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
        },
        containedInfo: {
          boxShadow: theme.customShadows.info,
        },
        containedSuccess: {
          color: theme.palette.common.white,
          boxShadow: theme.customShadows.success,
        },
        containedWarning: {
          boxShadow: theme.customShadows.warning,
        },
        containedError: {
          boxShadow: theme.customShadows.error,
        },
        // outlined
        outlinedSizeLarge: {
          paddingLeft: pxToRem(24),
          paddingRight: pxToRem(24),
          paddingTop: pxToRem(12),
          paddingBottom: pxToRem(12),
        },
        outlinedSizeMedium: {
          paddingLeft: pxToRem(16),
          paddingRight: pxToRem(16),
          paddingTop: pxToRem(8),
          paddingBottom: pxToRem(8),
        },
        outlinedSizeSmall: {
          paddingLeft: pxToRem(12),
          paddingRight: pxToRem(12),
          paddingTop: pxToRem(8),
          paddingBottom: pxToRem(8),
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        outlinedWarning: {
          color: theme.palette.warning.dark,
          '&:hover': {
            color: theme.palette.warning.darker,
          },
        },
        textSizeLarge: {
          paddingLeft: pxToRem(16),
          paddingRight: pxToRem(16),
          paddingTop: pxToRem(12),
          paddingBottom: pxToRem(12),
        },
        textSizeMedium: {
          paddingLeft: pxToRem(8),
          paddingRight: pxToRem(8),
          paddingTop: pxToRem(8),
          paddingBottom: pxToRem(8),
        },
        textSizeSmall: {
          paddingLeft: pxToRem(8),
          paddingRight: pxToRem(8),
          paddingTop: pxToRem(8),
          paddingBottom: pxToRem(8),
        },
        text: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        textInherit: {
          color: theme.palette.text.secondary,
          '&:hover': {
            color: theme.palette.text.primary,
          },
        },
        textPrimary: {
          '&:hover': {
            color: theme.palette.primary.dark,
          },
        },
        textSecondary: {
          '&:hover': {
            color: theme.palette.secondary.dark,
          },
        },
        textInfo: {
          '&:hover': {
            color: theme.palette.info.dark,
          },
        },
        textSuccess: {
          '&:hover': {
            color: theme.palette.success.dark,
          },
        },
        textWarning: {
          color: theme.palette.warning.dark,
          '&:hover': {
            color: theme.palette.warning.darker,
          },
        },
        textError: {
          '&:hover': {
            color: theme.palette.error.dark,
          },
        },
        underlineSizeLarge: {
          paddingLeft: pxToRem(16),
          paddingRight: pxToRem(16),
          paddingTop: pxToRem(12),
          paddingBottom: pxToRem(12),
        },
        underlineSizeMedium: {
          paddingLeft: pxToRem(8),
          paddingRight: pxToRem(8),
          paddingTop: pxToRem(8),
          paddingBottom: pxToRem(8),
        },
        underlineSizeSmall: {
          paddingLeft: pxToRem(8),
          paddingRight: pxToRem(8),
          paddingTop: pxToRem(8),
          paddingBottom: pxToRem(8),
        },
        underlineInherit: {
          color: theme.palette.text.secondary,
          '&:hover': {
            color: theme.palette.text.primary,
          },
        },
        underlinePrimary: {
          color: theme.palette.primary.main,
          '&:hover': {
            color: theme.palette.primary.dark,
          },
        },
        underlineSecondary: {
          color: theme.palette.secondary.main,
          '&:hover': {
            color: theme.palette.secondary.dark,
          },
        },
        underlineInfo: {
          color: theme.palette.info.main,
          '&:hover': {
            color: theme.palette.info.dark,
          },
        },
        underlineSuccess: {
          color: theme.palette.success.main,
          '&:hover': {
            color: theme.palette.success.dark,
          },
        },
        underlineWarning: {
          color: theme.palette.warning.dark,
          '&:hover': {
            color: theme.palette.warning.darker,
          },
        },
        underlineError: {
          color: theme.palette.error.main,
          '&:hover': {
            color: theme.palette.error.dark,
          },
        },
      },
    },
  };
}
