import { dispatch } from '../store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// api
import integrationsApi from 'src/api/integrations';
// utils

import { KeySetData, PageState } from 'src/@types/guest';
import { Meta } from 'src/@types/common';

const initialState: PageState = {
  loading: false,
  keySetData: null,
  keySetDatas: null,
};

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    setKeySetData(state, action: PayloadAction<{ keySetData: KeySetData | null }>) {
      state.keySetData = action.payload.keySetData;
    },
    setKeySetDatas(
      state,
      action: PayloadAction<{ keySetDatas: { data: KeySetData[]; meta: Meta } | null }>
    ) {
      state.keySetDatas = action.payload.keySetDatas;
      state.loading = false;
    },

    setKeySetName(state, action: PayloadAction<{ newName: string }>) {
      if (state.keySetData) state.keySetData.name = action.payload.newName;
    },
    setKeySetProperty(state, action: PayloadAction<{ newProperty: { id: number; name: string } }>) {
      if (state.keySetData) state.keySetData.property = action.payload.newProperty;
    },
    setKeySetPropertyUnit(
      state,
      action: PayloadAction<{ newPropertyUnit: { id: number; name: string } }>
    ) {
      if (state.keySetData) state.keySetData.property_unit = action.payload.newPropertyUnit;
    },
    setKeySetValues(state, action: PayloadAction<{ newkeySetData: KeySetData }>) {
      if (state.keySetDatas && action.payload.newkeySetData.id) {
        let index = state.keySetDatas?.data?.findIndex(function (element) {
          return element.id === action.payload.newkeySetData.id;
        });
        if (index > -1) state.keySetDatas.data[index] = action.payload.newkeySetData;
      }
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setLoading,
  setKeySetData,
  setKeySetName,
  setKeySetProperty,
  setKeySetPropertyUnit,
} = slice.actions;

export function getKeySetDetails(id: number) {
  return async () => {
    dispatch(slice.actions.setLoading(true));
    try {
      const response = await integrationsApi.getKeyNestKeySetDetailsById(id);
      dispatch(
        slice.actions.setKeySetData({
          keySetData: response.data ? response.data : null,
        })
      );
    } catch (error) {}
    dispatch(slice.actions.setLoading(false));
  };
}

export function getKeySetDatas(
  page: number,
  limit: number,
) {
  return async () => {
    dispatch(slice.actions.setLoading(true));
    try {
      const response = await integrationsApi.getKeyNestKeySetDetails(
        page,limit
      );
      dispatch(
        slice.actions.setKeySetDatas({
          keySetDatas: response.data ? response.data : null,
        })
      );
    } catch (error) {}
    dispatch(slice.actions.setLoading(false));
  };
}

export function fetchKeyNestKeySetDetailsList(
  page: number,
  limit: number,
  filtersState: {
    only_show_not_set_property?: boolean;
    status?: {
      id: string;
      label: string;
      value: string;
    };
    property?: [
      {
        id: number;
        label: string;
        value: number;
      }
    ];
  },
  advanceFilterParams?: { key: string; value: string }
) {
  return async () => {
    try {
      setLoading(true);
      const response = await integrationsApi.fetchKeyNestKeySetDetailsList({
        page: page,
        limit: limit,
        filtersState,
        advanceFilterParams,
      });
      dispatch(
        slice.actions.setKeySetDatas({
          keySetDatas: response.data ? response.data : null,
        })
      );
    } catch (error) {}
    dispatch(slice.actions.setLoading(false));
  };
}

export function updateKeySetName(
  id: number,
  name: string,
  setLoading: (succeed: boolean) => void,
  callback: (status: boolean, error: string | null) => void
) {
  return async () => {
    setLoading(true);
    try {
      await integrationsApi.renameKeySet(id, name).then(() => {
        dispatch(slice.actions.setKeySetName({ newName: name }));

        callback(true, null);
      });
    } catch (error) {
      callback(false, error.data && error.data.message ? error.data.message : null);
    } finally {
      setLoading(false);
    }
  };
}

export function updateKeyProperty(
  id: number,
  property: number,
  setLoading: (succeed: boolean) => void,
  callback: (status: boolean, error: string | null) => void
) {
  return async () => {
    setLoading(true);
    try {
      await integrationsApi.changeKeySetProperty(id, property).then((result) => {
        dispatch(slice.actions.setKeySetProperty({ newProperty: result.data.property }));

        callback(true, null);
      });
    } catch (error) {
      callback(false, error.data && error.data.message ? error.data.message : null);
    } finally {
      setLoading(false);
    }
  };
}

export function updateKeyUnit(
  id: number,
  propertyUnit: number,
  setLoading: (succeed: boolean) => void,
  callback: (status: boolean, error: string | null) => void
) {
  return async () => {
    setLoading(true);
    try {
      await integrationsApi.changeKeySetPropertyUnit(id, propertyUnit).then((result) => {
        dispatch(
          slice.actions.setKeySetPropertyUnit({ newPropertyUnit: result.data.property_unit })
        );

        callback(true, null);
      });
    } catch (error) {
      callback(false, error.data && error.data.message ? error.data.message : null);
    } finally {
      setLoading(false);
    }
  };
}

export function updateKeySet(id: number | undefined, keySetData: any, onSuccess?: () => void) {
  return async () => {
    try {
      if (!id) {
        return;
      }
      await integrationsApi.updateSetKey(id, keySetData).then((response) => {
        dispatch(slice.actions.setKeySetValues({ newkeySetData: response.data }));
        if (onSuccess) {
          onSuccess();
        }
      });
    } catch (error) {
    } finally {
    }
  };
}
export function updateKeySetForOneRow(
  id: number | undefined,
  keySetData: any,
  onSuccess?: () => void
) {
  return async () => {
    try {
      if (!id) {
        return;
      }
      await integrationsApi.updateSetKey(id, keySetData).then((response) => {
        dispatch(slice.actions.setKeySetData({ keySetData: response.data }));
        if (onSuccess) {
          onSuccess();
        }
      });
    } catch (error) {
    } finally {
    }
  };
}
