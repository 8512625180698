import { Stack, TextField, Typography } from '@mui/material';
import { DateRangePicker, DateRangePickerProps } from '@mui/x-date-pickers-pro/DateRangePicker';
import useLocales from 'src/hooks/useLocales';
import DateRangePickerDayStyle from 'src/components/DateRangePickerDayStyle';


interface Props extends DateRangePickerProps<Date> {
  textFieldHeight?: number;
}

const CustomWrappedTextField = (props: any & { startInputTitle?: string; endInputTitle?: string }) => {
  const { startInputTitle = '', endInputTitle = '', ownerState, ...other } = props;

  return (
    <Stack>
      <Typography variant="subtitle2" fontWeight="fontWeightSemiBold" ml={1}>
        {ownerState.position === 'start' ? startInputTitle : endInputTitle}
      </Typography>
      <TextField {...other}/>
    </Stack>
  );
};

export default function DateRangePickerWithWrappedInputFields({ textFieldHeight = 48, ...other }: Props) {
  const { translate, shortDateFormat } = useLocales('common');

  return (
    <DateRangePicker
      format={shortDateFormat.format}
      slots={{
        textField: CustomWrappedTextField,
        day: DateRangePickerDayStyle
      }}
      {...other}
      slotProps={{
        ...other.slotProps,
        textField: {
          label: '',
          readOnly: true,
          startInputTitle: translate('check_in'),
          endInputTitle: translate('check_out'),
          onKeyDown: (e) => e.preventDefault(),
          sx: {
            height: textFieldHeight,
            '& .MuiInputBase-root': { height: textFieldHeight }
          },
          ...other.slotProps?.textField
        } as any,
        fieldSeparator: {
          sx: { display: 'none' },
          ...other.slotProps?.fieldSeparator
        } as any,
      }}
    />
  );
}
