import ReactQuill from "react-quill";
import { Variable } from "src/@types/common";
import boldPartOfText from "./boldPartOfText";
import { replaceNewLineInStringWithBrTag } from "./stringUtils";

export const initialMentionConfig = {
  isolateCharacter: true,
  allowedChars: /^[A-Za-z\sÅÄÖåäö-]*$/,
  renderItem: (item, searchTerm) => boldPartOfText(item.name, searchTerm),
  onSelect: (item, insertItem) => {},
  source: (searchTerm, renderList, mentionChar) => {},
};

export const setVariablesMentionConfig = (ref: ReactQuill, variables: Variable[]) => {
  const quill = ref.getEditor();
  const mention = quill?.getModule('mention');
  if (mention) {
    mention.options.onSelect = (item, insertItem) => {
      const foundVariable = variables.find((variable) => variable.value === item.value);
      if (foundVariable) {
        const { name, value } = foundVariable;
        insertItem({ type: 'variable', denotationChar: '@', value: name, variable: value });
      }
    };
    mention.options.source = (searchTerm, renderList, mentionChar) => {
      const matches = variables.filter((item) => (
        item.name?.toLowerCase().includes(searchTerm.toLowerCase())
      ));
      renderList(matches, searchTerm);
    };
  }
};

export const insertVariableOfDropdown = (quillRef, selectedVariable) => {
  const quill = quillRef?.current?.getEditor();
  const mention = quill?.getModule('mention');
  const { name, value } = selectedVariable;
  mention.insertItem({
    type: 'variable',
    denotationChar: '@',
    value: name,
    variable: value,
  }, true);
};

export const generateVariableElement = (variable: Variable) => {
  const { value, name } = variable;
  const element = `<span class="mention" data-type="variable" data-denotation-char="@" data-value="${name}" data-variable="${value}"><span contenteditable="false"><span class="ql-mention-denotation-char">@</span>${variable.name}</span></span>`;
  return element;
};

export const getSpecificVariable = (variables: Variable[], variableValue: string) => {
  const specificVariable = variables.find((variable) => variable.value === variableValue);
  return specificVariable;
};

export const removeNewLineFromEntity = (content: string) => content.replace(/{{([\s\S]*?)}}/g,(matched) => matched.replace(/\n/g, ' '));

export const makeContentPreviewable = (content: string, variables: Variable[]) => {
  let convertedContent = content;
  if (content.indexOf('data-type="variable"') > -1) {
    const div = document.createElement('div');
    div.innerHTML = content;
    const variableElements = div.querySelectorAll('[data-type="variable"]');
    variableElements.forEach((variableElement) => {
      const variableValue = variableElement.getAttribute('data-variable') || '';
      const variable = getSpecificVariable(variables, variableValue);
      let element = '';
      if (variable) {
        const { example } = variable;
        if (example) element = example;
      }
      convertedContent = convertedContent.replace(variableElement.outerHTML, element);
    });
  }
  return convertedContent;
};

export const replaceContentVariablesWithEntity = (content: string, removeNextLines = false) => {
  let convertedContent = removeNextLines ? content.replace(/\n/g, '') : content;
  if (content.includes('data-type="variable"')) {
    const div = document.createElement('div');
    div.innerHTML = content;
    const variableElements = div.querySelectorAll('[data-type="variable"]');
    variableElements.forEach((variableElement) => {
      const variableValue = variableElement.getAttribute('data-variable') || '';
      convertedContent = convertedContent.replace(variableElement.outerHTML, variableValue);
    });
  }
  return convertedContent;
};

export const replaceContentEntitiesWithVariables = (content: string, variables: Variable[]) => {
  let convertedContent = content;
  if (content.includes('{{')) {
    convertedContent = removeNewLineFromEntity(convertedContent);
    const entityRegex = /{{([\s\S]*?)}}/g;
    convertedContent = convertedContent.replace(entityRegex, (matched) => {
      const variable = variables.find((variable) => variable.value === matched);
      if (variable) return generateVariableElement(variable);
      return matched;
    })
  }
  convertedContent = replaceNewLineInStringWithBrTag(convertedContent);
  convertedContent =
    convertedContent && !convertedContent.trim().startsWith('<')
      ? `<div>${convertedContent}</div>`
      : convertedContent;
  return convertedContent;
}
