import React from "react";
// @mui
import { Stack, Typography, Theme, SxProps, StackProps } from "@mui/material";
import { useTheme } from "@mui/material/styles";
// hooks
import useResponsive from "src/hooks/useResponsive";


// ----------------------------------------------------------------------

type Props = {
  title: string;
  actions?: React.ReactNode;
  subtitle?: React.ReactNode;
  headerStackProps?: StackProps;
  sx?: SxProps<Theme>;
};

const CardHeader = ({ title, actions, subtitle, headerStackProps, sx }: Props) => {
  const theme = useTheme();
  const isMobile = useResponsive('down', 'sm');
  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      sx={[
        { px: 3, py: 1, minHeight: 56 },
        ...(sx && Array.isArray(sx) ? sx : [sx || {}]),
      ]}
    >
      <Stack direction='row' alignItems='center' {...headerStackProps}>
        <Typography variant={isMobile ? 'subtitle2' : 'subtitle1'} sx={{ mr: 1, color: theme.palette.grey['800'] }}>{title}</Typography>
        {subtitle ?? <></>}
      </Stack>
      {actions ?? <></>}
    </Stack>
  );
};

export default CardHeader;
