import React from 'react';
// @mui
import {
  Select,
  MenuItem,
  Typography,
  InputBase,
  InputAdornment,
  IconButton,
  Divider,
} from '@mui/material';
// hooks
import useAuth from 'src/hooks/useAuth';
import { useNavigate } from 'react-router';

// react-window
import { FixedSizeList as List } from 'react-window';
import useLocalStorage from 'src/hooks/useLocalStorage';
import useLocales from 'src/hooks/useLocales';
import Iconify from 'src/components/Iconify';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

// ----------------------------------------------------------------------
type Organization = {
  id: number;
  name: string;
};

const OrganizationItem = ({ data, index, style }: any) => {
  const { translate } = useLocales('common');
  const { filteredOrganizations, search, currentOrganization, handleOrganizationChanged } = data;

  if (filteredOrganizations.length === 0) {
    return (
      <Typography
        style={{ ...style, width: 300, margin: 0, padding: 16 }}
        noWrap
        component="span"
        variant="subtitle1"
      >
        {translate('no_result')}
      </Typography>
    );
  }

  const { id, name } = filteredOrganizations[index];
  const isSelected = filteredOrganizations[index].id === currentOrganization;

  const matches = match(name, search, { insideWords: true });
  const parts = parse(name, matches);

  return (
    <MenuItem
      value={id}
      key={id}
      style={{ ...style, width: 300, margin: 0 }}
      selected={isSelected}
      onClick={() => handleOrganizationChanged(filteredOrganizations[index])}
    >
      {parts.map((part, index) => (
        <Typography
          noWrap={parts.length - 1 === index}
          sx={{ whiteSpace: 'pre' }}
          key={index}
          component="span"
          variant="subtitle1"
          fontWeight={part.highlight ? 'fontWeightBold' : 'fontWeightRegular'}
          color={part.highlight ? 'text.primary' : 'text.secondary'}
        >
          {part.text}
        </Typography>
      ))}
    </MenuItem>
  );
};

const OrganizationSelect = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { translate } = useLocales('common');

  const [search, setSearch] = React.useState('');

  const [currentOrganization, setCurrentOrganization] = useLocalStorage(
    'currentOrganization',
    user?.current_organization.id
  );

  const organizations: Organization[] = user?.organizations ?? [];
  const [filteredOrganizations, setFilteredOrganizations] =
    React.useState<Organization[]>(organizations);

  const handleOrganizationChanged = (organization: Organization) => {
    setCurrentOrganization(organization.id);
    navigate(0);
  };

  React.useEffect(() => {
    setFilteredOrganizations(
      organizations.filter((organization) =>
        organization.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
    );
  }, [search, organizations]);

  if (organizations.length > 1) {
    return (
      <Select
        size="small"
        displayEmpty
        renderValue={() => user?.organizations.find(org => org.id === currentOrganization).name}
        sx={{ maxWidth: 'calc(100% - 268px)' }}
        MenuProps={{
          sx: { '& .MuiMenu-paper': { padding: 1, minWidth: '320px !important' } },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        <InputBase
          sx={{ height: 48, width: '100%', p: 1.5, fontSize: 'inherit' }}
          inputProps={{
            value: search,
          }}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          placeholder={translate('search')}
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-outline" width={20} height={20} />
            </InputAdornment>
          }
          endAdornment={
            search !== '' && (
              <IconButton onClick={() => setSearch('')}>
                <Iconify icon="eva:close-fill" width={20} height={20} />
              </IconButton>
            )
          }
        />

        <Divider sx={{ mb: 1 }} />

        <List
          itemCount={filteredOrganizations.length > 0 ? filteredOrganizations.length : 1}
          itemSize={48}
          itemData={{
            filteredOrganizations,
            search,
            currentOrganization,
            handleOrganizationChanged,
          }}
          height={
            filteredOrganizations.length > 7
              ? 336
              : filteredOrganizations.length === 0
              ? 48
              : filteredOrganizations.length * 48
          }
          width={320}
        >
          {OrganizationItem}
        </List>
      </Select>
    );
  }

  return null;
};

export default OrganizationSelect;
