import { Node } from "react-checkbox-tree";

export const groupArrayBy = (array: Array<any>, property: string) => array.reduce((memo, x) => {
  if (!memo[x[property]]) memo[x[property]] = [];
  memo[x[property]].push(x);
  return memo;
}, {});

export const removeDuplicatedValuesOfArray = (array: Array<any>) => {
  const filteredArray = array.filter((item, index) => array.indexOf(item) === index);
  return filteredArray;
};

export const sortAlphabetically = (array: Array<any>, property?: string, secondProperty?: string) => {
  array.sort((a, b) => {
    if (property && secondProperty) return a[property][secondProperty].localeCompare(b[property][secondProperty]);
    if (property) return a[property].localeCompare(b[property]);
    return -b.localeCompare(a);
  })
  return array;
};

export const mergeArrays = (arrays: Array<any[]>) => {
  let mergedArray: any[] = [];
  arrays.forEach((array) => mergedArray = [...mergedArray, ...array]);
  return mergedArray;
};

export const mergeObjectOfArrays = (object: { [key: string]: any[] }) => {
  const values = Object.values(object);
  return mergeArrays(values);
}

/**
 * This function converts an array to an array of arrays, its child arrays will have equal lengths
 * For example [1, 2, 3, 4] will be converted to [[1, 2], [3, 4]]
 * */
export const convertArrayToArrayOfEqualLengthedArrays = (originalArray: any[], lengthOfEachChildArray: number) => {
  const result = Array.from({ length: Math.ceil(originalArray.length / lengthOfEachChildArray) }, (v, i) => originalArray.slice(i * lengthOfEachChildArray, (i * lengthOfEachChildArray) + lengthOfEachChildArray));
  return result;
}

export const flattenTreeNode = (children: Node[]) =>
  children.flatMap(({ children = [], ...rest }) => [rest, ...flattenTreeNode(children)])