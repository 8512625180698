import useAuth from "./useAuth";
import { ACL, ACLExecuteOnly, ACLKey, ACLKeyExecuteOnly } from "src/@types/acls";

type ACLType<T extends ACLKey | ACLKeyExecuteOnly> = T extends ACLKeyExecuteOnly ? ACLExecuteOnly : ACL;

const getFields = (fields?: [] | ACL['fields']) => Array.isArray(fields) ? {} : fields ?? {};
const isUserACLExecuteOnly = (acl): acl is ACLExecuteOnly => acl.EXECUTE !== undefined;

const useAcl = <T extends ACLKey | ACLKeyExecuteOnly>(key: T | T[]): ACLType<T> => {
  const { user } = useAuth();
  const defaultAcl = {
    VIEW: false,
    CREATE: false,
    EDIT: false,
    DELETE: false,
    ASSIGN: false,
    fields: {},
  };
  const defaultAclExecuteOnly = {
    EXECUTE: false,
  };

  if (typeof key === 'string') {
    const userAcl: ACLType<T> = user?.acls[key] || {};
    return isUserACLExecuteOnly(userAcl) ? {
      ...defaultAclExecuteOnly,
      ...userAcl,
    } : {
      ...defaultAcl,
      ...userAcl,
      fields: getFields(userAcl.fields),
    };
  };

  const acl = key.reduce((acc: any, key) => {
    const userAcl: ACLType<T> = user?.acls[key] || {};
    return isUserACLExecuteOnly(userAcl) ? {
      EXECUTE: acc.EXECUTE && (userAcl.EXECUTE ?? defaultAclExecuteOnly.EXECUTE),
    } : {
      VIEW: acc.VIEW && (userAcl.VIEW ?? defaultAcl.VIEW),
      CREATE: acc.CREATE && (userAcl.CREATE ?? defaultAcl.CREATE),
      EDIT: acc.EDIT && (userAcl.EDIT ?? defaultAcl.EDIT),
      DELETE: acc.DELETE && (userAcl.DELETE ?? defaultAcl.DELETE),
      ASSIGN: acc.ASSIGN && (userAcl.ASSIGN ?? defaultAcl.ASSIGN),
      fields: { ...getFields(acc.fields), ...getFields(userAcl.fields) },
    };
  }, { VIEW: true, CREATE: true, EDIT: true, DELETE: true, ASSIGN: true, EXECUTE: true, fields: {} });

  return acl;
};

export default useAcl;