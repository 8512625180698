import { ReactNode, useState } from 'react';
// @mui
import {
  Card,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  IconButton,
  CardProps,
  SxProps,
} from '@mui/material';
// components
import Iconify from 'src/components/Iconify';
import { EditableCard, StyledCardHeader } from 'src/components/card';

interface Props extends CardProps {
  children: ReactNode;
  title: string;
  isExpanded?: boolean;
  subtitle?: ReactNode;
  action?: ReactNode;
  isEditableCard?: boolean;
  styledCardHeaderSx?: SxProps;
  accordionSx?: SxProps;
  accordionSummarySx?: SxProps;
  accordionDetailsSx?: SxProps;
}

export default function AccordionCard({
                                        title,
                                        subtitle,
                                        children,
                                        isExpanded = false,
                                        action,
                                        isEditableCard,
                                        styledCardHeaderSx,
                                        accordionSx,
                                        accordionSummarySx,
                                        accordionDetailsSx,
                                        ...other
                                      }: Props) {
  const [expanded, setExpanded] = useState<boolean>(isExpanded);

  const content = (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} sx={{ ...accordionSx }}>
      <AccordionSummary
        expandIcon={
          <IconButton>
            <Iconify fontSize={'20px'} icon={'material-symbols:expand-more-rounded'}/>
          </IconButton>
        }
        sx={{ '& .MuiAccordionSummary-content': { m: '0 !important' }, p: 2, ...accordionSx }}
      >
        <StyledCardHeader
          title={title}
          subheader={subtitle}
          action={action}
          sx={{ ...styledCardHeaderSx }}
        />
      </AccordionSummary>
      <Divider flexItem/>
      <AccordionDetails sx={{ ...accordionDetailsSx }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );

  return isEditableCard ? (
    <EditableCard sx={{ mb: 3 }} {...other}>
      {content}
    </EditableCard>
  ) : (
    <Card sx={{ mb: 3 }} {...other}>
      {content}
    </Card>
  );
}
