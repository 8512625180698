import axios from '../utils/axios';
import { Meta, Property } from 'src/@types/common';

function PropertyApi() {
  return {
    createListing: (
      listing_id_url: string,
      property_id: number,
      isAddToAnExistingProperty: boolean,
      import_type: string,
      cancelToken
    ) => {
      const isNumber = !isNaN(parseInt(listing_id_url));
      var url = '/apis/listing/abb_import';
      if (isNumber === true) {
        url += '?listing_id=' + listing_id_url;
      } else {
        const urlPattern = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
        var isValid = listing_id_url.match(urlPattern);
        if (isValid === null) {
          if (!listing_id_url.includes('https://')) {
            listing_id_url = 'https://' + listing_id_url;
          }
          if (!listing_id_url.includes('www.')) {
            var index_position = listing_id_url.indexOf('https://') + 8;
            listing_id_url =
              listing_id_url.slice(0, index_position) +
              'www.' +
              listing_id_url.slice(index_position);
          }
        }
        url += '?listing_url=' + encodeURIComponent(listing_id_url);
      }
      if (isAddToAnExistingProperty === true && property_id !== 0) {
        url += '&property=' + property_id;
      }
      return axios.get(url + `&import_type=${import_type}`, {
        cancelToken,
      });
    },
    createProperty: (data) => axios.post('/apis/properties', data),
    uploadImages: (ids = '', data) => {
      const url = `/apis/listing/upload_images${ids}`;
      return axios.post(url, data, {
        headers: {
          'Content-Type': `multipart/form-data`,
        },
      });
    },
    uploadUnitImage: (data) => {
      const url = `/apis/unit_images`;
      return axios.post(url, data, {
        headers: {
          'Content-Type': `multipart/form-data`,
        },
      });
    },
    getCityAndAreaId: (city, country) =>
      axios.post('/apis/listing/area_by_address', {
        city,
        country,
      }),
    retrieveProperty: (property_id) => {
      return axios.get('/apis/properties/' + property_id);
    },
    retrievePropertyV2: (
      property_id?: number | string,
      params?: Partial<{
        page: number;
        limit: number;
        organization: number | string;
        name: string | string[];
        properties: string[];
      }>
    ) =>
      axios.get<{ data: Property[]; meta: Meta }>(`/apis/v2/properties/${property_id || ''}`, {
        params,
      }),
    retrieveProperties: () => {
      return axios.get('/apis/properties');
    },
    retriveUnitType: (unittype_id) => {
      return axios.get('/apis/unit_types/' + unittype_id);
    },
    retriveRatePlan: (rateplanUrl) => {
      return axios.get(rateplanUrl);
    },
    getCity: () => {},
    fetchListingData: (wizard_id) => {
      return axios.get('/apis/wizard/' + wizard_id);
    },
    createWizard: () => {
      return axios.post('/apis/wizard/create');
    },
    saveListingData: (wizard_id: string, data: null | {}) => {
      return axios.post(
        '/apis/wizard/' + wizard_id,
        data,
        wizard_id.includes('/attach')
          ? {
              headers: {
                'Content-Type': `multipart/form-data`,
              },
            }
          : {}
      );
    },
    fetchAmenities: () => axios.get('/apis/v2/amenities?pagination_enabled=0'),
    fetchCheckInTypes: () => axios.get(`/apis/check_in_types`),
    fetchCurrencies: () => axios.get(`/apis/currencies`),
    fetchUnitCategories: () => axios.get(`/apis/unit_categories`),
    fetchPropertyCategories: () => axios.get('/apis/property_categories'),
    fetchProperties: (short_name: string, page: number) =>
      axios.get('/apis/properties', {
        params: { limit: 20, page: page + 1, 'order[name]': 'asc', short_name },
      }),
    fetchUnitTypes: () => axios.get('/apis/unit_types'),
    fetchUnits: () => axios.get('/apis/units'),
    fetchBuildings: (property: number | number[], name?: string) => {
      const url = `/apis/buildings`;
      return axios.get(url, {
        params: {
          property,
          name,
        },
      });
    },
    fetchFloors: (building: number | number[], name?: string) => {
      const url = `/apis/floors`;
      return axios.get(url, {
        params: {
          building,
          name,
        },
      });
    },
    fetchChannels: (name: string, page: number, isActive: string = '') =>
      axios.get('/apis/channels', {
        params: { limit: 20, page: page + 1, 'order[name]': 'asc', name, is_active: isActive },
      }),
    fetchAllChannels: (isActive: string = '') =>
      axios.get('/apis/channels', {
        params: { limit: 200, is_active: isActive },
      }),
    fetchBedTypes: () => {
      return axios.get(`/apis/bed_types`);
    },
    fetchBedSizes: () => {
      return axios.get(`/apis/bed_sizes`);
    },
    validateUnitInternalName: (internal_name: string, organization: string | number) => {
      return axios.post(`/apis/listing/validate/unit/internal_name`, {
        internal_name,
        organization,
      });
    },
    validateUnitTypeInternalName: (id, internal_name) =>
      axios.post(`/apis/listing/validate/unit_type/internal_name`, { id, internal_name }),
    validatePropertyInternalName: (id, internal_name) =>
      axios.post(`/apis/listing/validate/property/internal_name`, {
        id,
        internal_name,
      }),
    validatePropertyMarketingHeadline: (id, marketing_name) =>
      axios.post('/apis/listing/validate/property/marketing_name', { id, marketing_name }),
    validateUnitTypeMarketingHeadline: (id, marketing_name) =>
      axios.post('/apis/listing/validate/unit_type/marketing_name', { id, marketing_name }),
    validateUnitTypeName: (name) => axios.post(`/apis/unit_type/name_availability`, { name }),
    validateUnitName: (name) => axios.post(`/apis/unit/name_availability`, { name }),
    validateUniqueUnitNameInUnitType: (internal_name: string, property: string | number) =>
      axios.post(`/apis/listing/validate/unit/internal_name`, { internal_name, property }),
    splitBooking: (data) => axios.post('/apis/bookings/split', data),
    extendBooking: (data) => axios.post('/apis/bookings/extend', data),
    changeBookingStatus: (data) => axios.post('/apis/bookings/changeStatus', data),
    fetchActiveProperties: () => axios.get('/apis/active_properties'),
    fetchFeeAddons: () =>
      axios.get('/apis/add-ons', {
        params: { limit: 1, page: 1, name: 'Cleaning fee', 'archive':'false', 'default_for_nightly':'true', 'order[created_at]': 'asc' },
      }),
  };
}

export default PropertyApi();
