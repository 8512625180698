import { Theme } from '@mui/material/styles';
import { alpha } from '@mui/material';

// ----------------------------------------------------------------------

export default function DataGrid(theme: Theme) {
  return {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          border: `1px solid transparent`,
          '& .MuiTablePagination-root': {
            borderTop: 0,
          },
          '& .MuiDataGrid-toolbarContainer': {
            padding: theme.spacing(1.5, 1),
            backgroundColor: theme.palette.background.paper,
            display: 'block',
            '& .MuiButton-root': {
              marginRight: theme.spacing(1.5),
              color: theme.palette.text.primary,
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            },
          },
          '& .MuiDataGrid-cell, .MuiDataGrid-columnsContainer': {
            borderBottom: `1px solid ${theme.palette.divider}`,
          },
          '& .MuiDataGrid-columnSeparator': {
            color: theme.palette.divider,
          },
          '& .MuiDataGrid-columnHeader': {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(2),
          },
          '& .MuiDataGrid-columnHeaderDraggableContainer': {
            '&:hover': { 
              '& .MuiDataGrid-columnHeaderTitleContainer': {
                '& .MuiDataGrid-columnHeaderTitleContainerContent .drag-icon': {
                  display: 'block',
                }
              }
            }
          },
          '& .MuiDataGrid-columnHeader[data-field="action"], & .MuiDataGrid-columnHeader[data-field="actions"]': {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
          },
          '& .MuiDataGrid-columnHeader[data-field="__check__"]': {
            padding: 0,
          },
          '& .MuiDataGrid-pinnedColumnHeaders': {
            backgroundColor: theme.palette.background.neutral,
          },
          '& .MuiDataGrid-pinnedColumns': {
            '& .MuiDataGrid-row': {
              padding: 0,
            }
          },
          '& .MuiDataGrid-columnHeaders': {
            ...theme.typography.subtitle2,
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.neutral,
            margin: 0,
            borderRadius: theme.spacing(1),
          },
          '& .MuiDataGrid-columnHeader, & .MuiDataGrid-columnHeader div, & .MuiDataGrid-cell': {
            '&:focus, &:focus-within': {
              outline: 'none !important',
            },
          },
          '& .MuiDataGrid-iconSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-cell': {
            padding: theme.spacing(0, 2),
            border: 'none',
          },
          '& .warning-row': {
            backgroundColor: alpha(theme.palette.warning.main, 0.12),
            '&:hover': {
              backgroundColor: alpha(theme.palette.warning.main, 0.24),
            },
            '&.Mui-selected': {
              backgroundColor: alpha(theme.palette.warning.main, 0.32),
              '&:hover': {
                backgroundColor: alpha(theme.palette.warning.main, 0.4),
              },
            },
          },
        },
        menu: {
          boxShadow: theme.customShadows.z20,
          borderRadius: theme.shape.borderRadius,
          '& .MuiMenuItem-root': {
            ...theme.typography.body2,
          },
        }
      },
    },
    MuiGridFilterForm: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1.5, 0),
          '& .MuiFormControl-root': {
            margin: theme.spacing(0, 0.5),
          },
          '& .MuiInput-root': {
            marginTop: theme.spacing(3),
            '&::before, &::after': {
              display: 'none',
            },
            '& .MuiNativeSelect-select, .MuiInput-input': {
              ...theme.typography.body2,
              padding: theme.spacing(0.75, 1),
              borderRadius: theme.shape.borderRadius,
              backgroundColor: theme.palette.background.neutral,
            },
            '& .MuiSvgIcon-root': {
              right: 4,
            },
          },
        },
      },
    },
    MuiGridPanelFooter: {
      styleOverrides: {
        root: {
          padding: theme.spacing(2),
          justifyContent: 'flex-end',
          '& .MuiButton-root': {
            '&:first-of-type': {
              marginRight: theme.spacing(1.5),
              color: theme.palette.text.primary,
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            },
            '&:last-of-type': {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            },
          },
        },
      },
    },
  };
}
