import { Paper, styled } from "@mui/material";
import { ColorSchema } from "src/theme/palette";

const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'color'
})<{ color: ColorSchema }>(({ theme, color }) => ({
  borderRadius: theme.spacing(1),
  borderColor: theme.palette[color].light,
  backgroundColor: theme.palette[color].lighter,
}));

export default StyledPaper;