import { Stack, Typography } from '@mui/material';
import useLocales from 'src/hooks/useLocales';

export default function SnackbarContent() {
  const { translate } = useLocales(['common']);

  return (
    <Stack ml={1.5}>
      <Typography variant="subtitle2">{translate('preparing_your_file_for_download')}</Typography>
      <Typography variant="caption">{translate('it_might_take_a_few_minutes')}</Typography>
    </Stack>
  );
}
