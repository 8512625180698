// @mui
import { Link } from '@mui/material';
// components
import { IconButtonAnimate } from 'src/components/animate';
import Iconify from 'src/components/Iconify';

// ----------------------------------------------------------------------

export default function HelpCenter() {
  return (
    <IconButtonAnimate size="medium">
      <Link
        target="_blank"
        rel="noopener"
        href="https://zeevou.com/support/"
        sx={{ width: 24, height: 24 }}
        color={'inherit'}
      >
        <Iconify icon={'mdi:help-circle-outline'} />
      </Link>
    </IconButtonAnimate>
  );
}
