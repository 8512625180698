import { notificatonBellType } from "src/@types/common";
import { PATH_DASHBOARD } from "src/routes/paths";

export const isExternalLink = (path: string) => path?.includes('http');

export const getRedirectLink = (notification: notificatonBellType): string => {
  if (notification.redirect_id){
    switch (notification.type){
      case 'unified_inbox':
        return `${PATH_DASHBOARD.inbox.root}#${notification.redirect_id}_inbox`;
      case 'bookings':
        return `${PATH_DASHBOARD.booking.booking}/${notification.redirect_id}/details`;
      case 'finance':
        return `${PATH_DASHBOARD.booking.booking}/${notification.redirect_id}/finance`;
      default: break;
    }
  }
  return notification.redirect_link ?? '#';
}