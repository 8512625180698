import { useEffect, useRef } from 'react';
import { htmlToText } from 'html-to-text';
import validator from 'validator';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { fDT, isDateForToday, isDateForYesterday } from './formatTime';
import { utcToZonedTime } from 'date-fns-tz';
import * as Yup from 'yup';

export const defaultBcc = 'qc@zeevou.com';
export const lineInTextRegExp = /(?:\r\n|\r|\n|\\n)/g;

export function useInterval(callback, delay) {
  const savedCallback = useRef(() => {});

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function getContactName(contact) {
  if (contact) {
    if (contact.first_name && contact.last_name) {
      return `${contact.first_name} ${contact.last_name}`;
    }
    if (contact.first_name) {
      return contact.first_name;
    }
    if (contact.last_name) {
      return contact.last_name;
    }
    let primaryEmail: any = null;
    if (contact && contact.emails) {
      primaryEmail = contact.emails.find((email) => email.primary);
    }
    if (primaryEmail) {
      return primaryEmail.email;
    }
    let primaryPhone: any = null;
    if (contact && contact.phones) {
      primaryPhone = contact.phones.find((phone) => phone.primary);
    }
    return primaryPhone ? primaryPhone.phone : '';
  }
  return '';
}

function fallbackCopyTextToClipboard(text) {
  const tempTextArea = document.createElement('textarea');
  tempTextArea.value = text;
  // Avoid scrolling to bottom
  tempTextArea.style.top = '0';
  tempTextArea.style.left = '0';
  tempTextArea.style.position = 'fixed';
  document.body.appendChild(tempTextArea);
  tempTextArea.focus();
  tempTextArea.select();
  try {
    document.execCommand('copy');
  } catch (err) {}
  document.body.removeChild(tempTextArea);
}
export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(() => {}, (err) => {});
}

export const formatTime = (time, dateFormatPattern, timeFormatPattern, timeZone, isForMessages) => {
  const format = (pattern) => fDT({
    date: utcToZonedTime(time, timeZone ?? 'UTC'),
    pattern: pattern,
  });

  if (isForMessages) {
    if (isDateForToday(time, timeZone) || isDateForYesterday(time, timeZone)) {
      return `${isDateForToday(time, timeZone) ? 'Today, ' : isDateForYesterday(time, timeZone) ? 'Yesterday, ' : ''}${format(timeFormatPattern)}`;
    }
    return `${format(dateFormatPattern)}, ${format(timeFormatPattern)}`;
  }
  if(isDateForToday(time, timeZone)) {
    return format(timeFormatPattern);
  }
  return format(dateFormatPattern);

};

export const getCaretPosition = (input) => {
  if ('selectionStart' in input && document.activeElement === input) {
    return {
      startIndex: input.selectionStart,
      endIndex: input.selectionEnd,
    };
  }
  if (input.createTextRange) {
    const doc: any = document;
    const sel = doc.selection.createRange();
    let position;
    let length;
    if (sel.parentElement() === input) {
      const range = input.createTextRange();
      range.moveToBookmark(sel.getBookmark());
      for (length = 0;
        range.compareEndPoints('EndToStart', range) > 0;
        range.moveEnd('character', -1)) {
        length += 1;
      }
      range.setEndPoint('StartToStart', input.createTextRange());
      for (position = { startIndex: 0, endIndex: length };
        range.compareEndPoints('EndToStart', range) > 0;
        range.moveEnd('character', -1)) {
        position.start += 1;
        position.end += 1;
      }
      return position;
    }
  }
  return false;
};

export const convertHtmlToText = (html) => {
  const content = html
    ?.replaceAll('<!--', ' ')
    ?.replaceAll('-->', ' ')
    ?.trim();
  return htmlToText(content, {
    tags: {
      img: {
        format: 'skip',
      },
      a: {
        options: { hideLinkHrefIfSameAsText: true },
      },
    },
  });
};

export const boldPartOfText = (text, part) => {
  const regex = part.replace('+', '');
  const match = new RegExp(regex, 'gi');
  return text && text.replace(match, (matched) => `<b>${matched}</b>`);
};

export const checkIsValidEmail = (email) =>  Yup.string().email().isValidSync(email);

export const checkIsTextANumber = (text) => {
  const textWithNoSpace = text.replace(/ /g, '');
  const numberShape = /^\d+$/; // only numbers like "23894981"
  const mobileNumberShape = /^\+\d+$/; // numbers and plus (+) like "+44555432789"
  return mobileNumberShape.test(textWithNoSpace) || numberShape.test(textWithNoSpace);
};

export const checkIsValidUrl = (url) => validator.isURL(url);

export const identifyLinksOfText = (text) => {
  const lines = text.split(lineInTextRegExp);
  const recognizedLinksInEveryLine = lines.map((line) => {
    const words = line.split(' ');
    const separatedLinksAndWords = words.map((word) => {
      if (checkIsValidEmail(word)) {
        return `<a href="mailto:${word}">${word}</a>`;
      }
      if (isValidPhoneNumber(word)) {
        return `<a href="tel:${word}">${word}</a>`;
      }
      if (checkIsValidUrl(word)) {
        const containsProtocol = word.indexOf('http://') === 0 || word.indexOf('https://') === 0;
        const link = containsProtocol ? word : `http://${word}`;
        return `<a href="${link}">${word}</a>`;
      }
      return word;
    });
    return separatedLinksAndWords.join(' ');
  });
  return recognizedLinksInEveryLine.join('\n');
};

const setRecipents = (chipsArray, shouldContainName, chipType) => (
  chipsArray.map((chip) => {
    const chipNames = shouldContainName ? {
      first_name: chip.first_name,
      middle_name: chip.middle_name,
      last_name: chip.last_name,
    } : {};
    return {
      ...chipNames,
      contact_id: chip.id || null,
      type: chipType,
      content: chip.emails[0].email,
      is_valid: chip.isValid,
      is_outlined: chip.isOutLined,
    };
  })
);
const getDraftInfo = (details, isForBodyRequest: boolean) => {
  const {
    subject,
    emailTo,
    messageType,
    smsTo,
    ccChips,
    bccChips,
    htmlMessageContent,
    templateContent,
    attachments,
  } = details;
  const hasTemplate = (templateContent && templateContent.isHtml) || false;
  const ccRecipients = setRecipents(ccChips, !isForBodyRequest, 'cc');
  const bccRecipients = setRecipents(bccChips, !isForBodyRequest, 'bcc');
  const recipients = [
    ...ccRecipients,
    ...bccRecipients,
  ];
  if (smsTo) {
    recipients.push({
      contact_id: null,
      type: 'to',
      content: smsTo || null,
      is_valid: true,
      is_outlined: true,
    });
  }
  if (emailTo) {
    recipients.push({
      contact_id: null,
      type: 'to',
      content: emailTo || null,
      is_valid: true,
      is_outlined: true,
    });
  }
  return {
    draftText: htmlMessageContent,
    subject: subject || null,
    type: messageType,
    hasTemplate,
    recipients,
    attachments: isForBodyRequest ? attachments?.map((attach) => ({ id: attach.id })) : attachments,
  };
};
export const draftGenerator = (details, isForBodyRequest: boolean) => {
  const draftInfo = getDraftInfo(details, isForBodyRequest);
  return {
    draft_text: draftInfo.draftText,
    draft_subject: draftInfo.subject,
    type: 'draft',
    message_type: draftInfo.type,
    draft_has_template: draftInfo.hasTemplate,
    recipients: draftInfo.recipients,
    attachments: draftInfo.attachments,
  };
};