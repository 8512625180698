import { Currency, Price, SyncToChannelManagerEntity } from 'src/@types/common';
import axios from '../utils/axios';
import { AddonPriceType, AddonType, CostElement } from 'src/@types/addon';

export type CreateUpsellBody = {
  label: string;
  name: string;
  description?: string;
  price_type: AddonPriceType;
  type: AddonType;
  sales_price: Price;
  default_price_currency?: string;
  purchase_price: Price;
  purchase_price_currency?: string;
  cost_element?: number | string;
  images?: Array<number | { file: number }>;
  related_expense?: number | null;
  excluded_fees?: number[];
};

function rateAPI() {
  return {
    syncToChannelManager: (body: {
      id: number;
      entity: SyncToChannelManagerEntity;
      status?: string;
    }) => {
      const url = '/apis/listing/cm/sync';
      return axios.post(url, body);
    },
    getRatePlans: (type?: 'Nightly' | 'Weekly' | 'Monthly') => {
      const url = '/apis/listing/tree/rate_plan';
      return axios.get(url, { params: { type } });
    },
    getAddons: (params?: {
      category?: string;
      group?: string;
      upsell?: boolean;
      archive?: boolean;
      excludeType: AddonType;
      only_for_early_late_checkin_checkout: boolean;
      limit?: number;
    }) => {
      const url = '/apis/add-ons';
      return axios.get(url, {
        params: {
          limit: 200,
          ...params,
          ...(params?.excludeType && { 'type[neq]': params.excludeType }),
        },
      });
    },
    getCostElements: (params: any) => axios.get('/apis/cost_elements', { params }),
    createCostElements: (body: Partial<CostElement>) => axios.post('/apis/cost_elements', body),
    createUpsell: (body: CreateUpsellBody) => {
      const url = '/apis/add-ons';
      return axios.post(url, {
        ...body,
        upsell: true,
        group: 'UPSELL',
        category: 'Service',
      });
    },
    updateUpsell: (id: number, body: CreateUpsellBody) => {
      const url = `/apis/add-ons/${id}`;
      return axios.put(url, body);
    },
    updateUnitTypeUpsell: (id: number, body: any) => {
      const url = `/apis/unit_type_upsells/${id}`;
      return axios.put(url, body);
    },
    createExpenseType: (body: any) => {
      const url = '/apis/add-ons';
      return axios.post(url, {
        ...body,
        category: 'Expenses',
      });
    },
    updateAddon: (
      addonId: number,
      name: string,
      costElementId: number,
      relatedExpenseId?: number,
      accountId?: number
    ) =>
      axios.put(`/apis/add-ons/${addonId}`, {
        name: name,
        cost_element: costElementId,
        ...(relatedExpenseId && { related_expense: relatedExpenseId }),
        ...(accountId && { account: accountId }),
      }),
    deleteAddon: (addonId: number) => axios.delete(`/apis/add-ons/${addonId}`),
    createExpenseTypeCategory: (name: string) =>
      axios.post('/apis/expenses/create_expense_type_category', { name }),
    createRateAddon: (body: any) => {
      const url = '/apis/rate_addons';
      return axios.post(url, body);
    },
    editRateAddon: (id: number, body: any) => {
      const url = `/apis/rate_addons/${id}`;
      return axios.put(url, body);
    },
    removeRateAddon: (id: number) => axios.delete(`/apis/rate_addons/${id}`),
    postRateLogs: (type: 'single' | 'multiple', body) => {
      const url = '/apis/rates_batch';
      return axios.post(url, body, { params: { type } });
    },
    checkRateRuleConflicts: (body) => {
      const url = '/apis/rate_logs/rate_rule_conflicts';
      return axios.post(url, body);
    },
    updateRatePlan: (id: string | number, body: any) => {
      const url = `/apis/rate_plans/${id}`;
      return axios.put(url, body);
    },
    removeRatePlan: (id: string | number) => axios.delete(`/apis/rate_plans/${id}`),
    createRatePlan: (body: any) => axios.post('/apis/rate_plans', body),
    addUpsell: (
      upsells: string[] | number[],
      unit_type_id: number[] | string[],
      sales_price: number | null,
      purchase_price: number | null
    ) => {
      const url = `/apis/type/upsells`;
      return axios.post(url, {
        sales_price,
        purchase_price,
        upsells: upsells.map((id: any) => `${id}`).join(','),
        unit_type_id: unit_type_id.map((unit_type_id: any) => `${unit_type_id}`).join(','),
      });
    },
    removeUpsell: (
      upsellId: string | number,
      unitTypeId: number | string,
      remove_req: boolean,
      property_level: boolean,
      organization_level: boolean
    ) => {
      const url = `/apis/upsell/remove`;
      return axios.delete(url, {
        data: {
          upsellId,
          unitTypeId,
          remove_req,
          property_level,
          organization_level,
        },
      });
    },
  };
}

export default rateAPI();
