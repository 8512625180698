import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConnectionSetup, Integrations, RelatedIntegration } from 'src/@types/integrations';
import integrations from 'src/api/integrations';
import { dispatch } from '../store';
import { Brand } from 'src/@types/common';
import API from 'src/api/api';

// types
type IntegrationsState = {
  integrations: Integrations[];
  currentTab: string;
  selectedIntegration: Integrations | null;
  relatedIntegration: RelatedIntegration | null;
  redirectType: string;
  brands: Brand[];
  xeroConnectionSetups: ConnectionSetup[];
};

const initialState: IntegrationsState = {
  integrations: [],
  currentTab: 'all',
  selectedIntegration: null,
  relatedIntegration: null,
  redirectType: '',
  brands: [],
  xeroConnectionSetups: [],
};

const slice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    setIntegrations(state, action: PayloadAction<Integrations[]>) {
      state.integrations = action.payload;
    },
    setCurrentTab(state, action: PayloadAction<string>) {
      state.currentTab = action.payload;
    },
    setRedirectType(state, action: PayloadAction<string>) {
      state.redirectType = action.payload;
    },
    setIsConnected(state, action: PayloadAction<boolean>) {
      if (state.relatedIntegration) {
        state.relatedIntegration.is_connected = action.payload;
      }
    },
    setIntegrationById(state, action: PayloadAction<Integrations>) {
      state.selectedIntegration = action.payload;
    },
    getRelatedIntegration(state, action: PayloadAction<RelatedIntegration>) {
      state.relatedIntegration = action.payload;
    },
    setBrands(state, action: PayloadAction<Brand[]>) {
      state.brands = action.payload;
    },
    setXeroConnectionSetups(state, action: PayloadAction<ConnectionSetup[]>) {
      state.xeroConnectionSetups = action.payload;
    },
  },
});

// reducer
export default slice.reducer;
// actions
export const {
  setIntegrations,
  getRelatedIntegration,
  setIntegrationById,
  setCurrentTab,
  setIsConnected,
  setBrands,
} = slice.actions;

// ----------------------------------------------------

export function connectXero(code, type, callback: (succeed: boolean) => void) {
  return async () => {
    try {
      await integrations.connectXero(code);
      dispatch(slice.actions.setRedirectType(type));
      callback(true);
    } catch (error) {
      callback(false);
    }
  };
}
export function connectStripe(integrationId, type, callback: (succeed: boolean) => void) {
  return async () => {
    try {
      await integrations.connectStripe(integrationId);
      dispatch(slice.actions.setRedirectType(type));
      callback(true);
    } catch (error) {
      callback(false);
    }
  };
}

export function connectKeynest(code, type, callback: (succeed: boolean) => void) {
  return async () => {
    try {
      await integrations.connectKeynest(code);     
      dispatch(slice.actions.setRedirectType(type));
      callback(true);
    } catch (error) {
      callback(false);
    }
  };
}
export function connectRemotelock(code, type, callback: (succeed: boolean) => void) {
  return async () => {
    try {
      await integrations.connectRemotelock(code);     
      dispatch(slice.actions.setRedirectType(type));
      callback(true);
    } catch (error) {
      callback(false);
    }
  };
}

export function getBrands() {
  return async () => {
    try {
      const response = await API.fetchBrands();
      dispatch(slice.actions.setBrands(response.data));
    } catch (error) {}
  };
}

export function disconnectIntegration(integrationId: number) {
  return async () => {
    if (integrationId) {
      await integrations.disconnectIntegration(integrationId);
      dispatch(slice.actions.setIsConnected(false));
    }
  };
}

export function getXeroConnectionSetups() {
  return async () => {
    try {
      const response = await integrations.fetchXeroConnectionSetups();
      dispatch(slice.actions.setXeroConnectionSetups(response.data.connection_setups));
    } catch (error) {
    }
  };
}

export function syncTrackingCategories(
  setLoading?: (succeed: boolean) => void,
  callback?: (succeed: boolean, response: any) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const response = await integrations.syncTrackingCategoriesWithXero();
      callback?.(true, response);
    } catch (error) {
      callback?.(false, error);
    } finally {
      setLoading?.(false);
    }
  };
}