import Resizer from 'react-image-file-resizer';
import { HOST_API } from 'src/config';

const SVG_MIME_TYPE = 'image/svg+xml';
export const isSvgFileType = (file: File) => file.type === SVG_MIME_TYPE;

type Options = {
  maxWidth?: number;
  maxHeight?: number;
  compressFormat?: 'jpeg' | 'png' | 'webp';
  quality?: number;
  rotation?: number;
  outputType?: 'base64' | 'file' | 'blob';
  minWidth?: number;
  minHeight?: number;
};

export const resizeImageFile = (
  file: Blob,
  options?: Options
): Promise<string | Blob | File | ProgressEvent<FileReader>> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      options?.maxWidth || 1000,
      options?.maxHeight || 2000,
      options?.compressFormat || 'jpeg',
      options?.quality || 100,
      options?.rotation || 0,
      (value) => {
        resolve(value);
      },
      options?.outputType || 'blob',
      options?.minWidth,
      options?.minHeight,
    );
  });

export function dataURLtoBlob(dataUrl) {
  var arr = dataUrl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

/** This method creates URL of images based on their id and file name returned in the API response */
export function getImagePath(id?: number, fileName?: string, width?: number, height?: number, removeHost = false): string {
  if (!id || !fileName) return '';
  if (!width && !height) return `${removeHost ? '' : HOST_API}/apis/attachments/fullsize/${id}/${fileName}`;
  return `${removeHost ? '' : HOST_API}/apis/attachments/resize/${id}/${width}/${height}/${fileName}`;
};

export const getFileFromSystem = (
  callback: (file: File | null) => void,
  acceptFormats: string = '*'
) => {
  const fileHolder = document.createElement('input');
  fileHolder.setAttribute('type', 'file');
  fileHolder.setAttribute('accept', acceptFormats);
  fileHolder.setAttribute('style', 'visibility:hidden');
  document.body.appendChild(fileHolder);
  fileHolder.click();
  fileHolder.onchange = () => {
    const file = fileHolder?.files?.[0] ?? null;
    callback(file);
  };
  fileHolder.oncancel = () => callback(null);
};