import { eachDayOfInterval, isWithinInterval } from "date-fns";
import { TFunction } from "react-i18next";
import { GridSortModel } from "@mui/x-data-grid-pro";
// @types
import {
  BlockedDateListView,
  BlockedDateTabType,
  BlockedDateType,
  Filters,
  CustomDate,
  Unit,
  UnitType,
  Property,
  CreatedBy,
  BlockDateFromICal,
  UnitAvailability,
  UnitBooking,
  BlockedDateFormValues
} from 'src/@types/blocked-dates';

export const getDefaultFiltersByViewMode = (
  blockedDateListViewValue: BlockedDateTabType
): Filters => {
  switch (blockedDateListViewValue) {
    case 'active':
      return {
        is_active: true,
      };
    case 'removed':
      return {
        is_active: false,
      };
    default:
      return {};
  }
};

export const getDefaultSortModelByViewMode = (
  blockedDateListViewValue: BlockedDateTabType
): GridSortModel => {
  switch (blockedDateListViewValue) {
    case 'active':
    case 'removed':
      return [{ field: 'created_at', sort: 'asc' }];
    default:
      return [];
  }
};

export const defaultBlockedDateListViews: Record<BlockedDateTabType, BlockedDateListView> = {
  active: {
    value: 'active',
    defaultFilters: getDefaultFiltersByViewMode('active'),
    defaultSortModel: getDefaultSortModelByViewMode('active'),
  },
  removed: {
    value: 'removed',
    defaultFilters: getDefaultFiltersByViewMode('removed'),
    defaultSortModel: getDefaultSortModelByViewMode('removed'),
  }
};

export const blockedDateNormalizer = (obj): BlockedDateType => ({
    id: obj.id,
    blockType: obj.block_type,
    reason: obj.reason,
    isActive: obj.is_active,
    numberOfNights: obj.number_of_nights,
    unit: blockDateUnitNormalizer(obj.unit),
    unitType: blockDateUnitTypeNormalizer(obj.unit.unit_type),
    property: blockDatePropertyNormalizer(obj.unit.unit_type.property),
    createdBy: blockDateCreatedByNormalizer(obj.created_by),
    blockFromIcal: blockDateFromICalNormalizer(obj.block_from_i_cal),
    from: obj.from,
    to: obj.to,
    createdAt: obj.created_at,
    updatedAt: obj.updated_at,
  })

export const blockDateFromICalNormalizer = (obj): BlockDateFromICal | null => {
  if (!obj) return null;
  return {
    blockFromIcalId: obj.id,
    icalLink: obj.i_cal_link ?? obj.ical_link
  };
}

export const blockDateCreatedByNormalizer = (obj): CreatedBy | null => {
  if (!obj) return null;
  return {
    id: obj.id,
    firstName: obj.first_name,
    lastName: obj.last_name
  };
}

export const blockDateUnitNormalizer = (obj): Unit => ({
    id: obj.id,
    name: obj.name,
    iCalImports: obj.ical_imports
  })

export const blockDateUnitTypeNormalizer = (obj): UnitType => ({
    id: obj.id,
    name: obj.name,
    unitCount: obj.unit_count,
    isListed: obj.is_listed
  })

export const blockDatePropertyNormalizer = (obj): Property => ({
    id: obj.id,
    name: obj.name,
    shortName: obj.short_name,
    isActive: obj.is_active,
    unitTypeCount: obj.unitTypeCount,
    timezone: obj.timezone,
  })

export const generateCustomDateOptions = ({ bookings, blocks }: UnitAvailability, translate: TFunction<any[]>) => {
  const unavailableItems = [
    ...bookings.map((booking) => ({ ...booking, type: 'booking' })),
    ...blocks.map((block) => ({ ...block, type: 'block' })),
  ];

  const customOptions: CustomDate[] = unavailableItems.flatMap(({ type, from, to }) => (
    eachDayOfInterval({ start: new Date(from), end: new Date(to) }).map((date, index, dates) => ({
      date,
      isStart: index === 0,
      isEnd: index === dates.length - 1,
      isHighlight: dates.length > 0,
      isSingle: true,
      status: type === 'booking' ? 'default' : 'info',
      tooltip: translate(`blocked_date.${type === 'booking' ? 'sold_out' : 'blocked'}`)
    }))
  ));

  return customOptions;
}

export const getBookingsWithin = (start: Date, end: Date, availabilities: UnitAvailability[]) => {
  const interval = { start, end };
  const bookings: UnitBooking[] = [];
  availabilities.forEach((unit) => {
    unit.bookings.forEach((booking) => {
      if (isWithinInterval(new Date(booking.from), interval)
      || isWithinInterval(new Date(booking.to), interval)) {
        bookings.push({ ...booking, unitId: unit.id, unitName: unit.name })
      }
    });
  });
  return bookings;
};

export const getExactFromToDate = (values: BlockedDateFormValues) => {
  const { fromDate, toDate, fromTime, toTime } = values;
  if (fromDate && toDate) {
    if (fromTime) {
      const [fromTimeHour, fromTimeSecond] = fromTime.split(':');
      fromDate.setHours(+fromTimeHour, +fromTimeSecond, 0);
    }
    if (toTime) {
      const [toTimeHour, toTimeSecond] = toTime.split(':');
      toDate.setHours(+toTimeHour, +toTimeSecond, 0);
    }
    return { fromDate, toDate }
  }
  return { fromDate: null, toDate: null };
}
