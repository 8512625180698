import axios from 'src/utils/axios';

function BookingRequestApi() {
  return {
    fetchBookingRequest: (bookingRequestId: number) => axios.get(`/apis/booking_requests/${bookingRequestId}`),
    declineBookingRequest: (id: number) => axios.post(`/apis/booking_requests/${id}/decline`),
    acceptBookingRequest: (
      id: number,
      confirmed: boolean | null = null,
      availabilityIssueConfirmed: boolean | null = null,
      unitId?: number
    ) => axios.post(`/apis/booking_requests/${id}/accept`, {
      confirmed: Boolean(confirmed) ? 'confirmed' : null,
      availability_issue_confirmed: Boolean(availabilityIssueConfirmed) ? 'availability_issue_confirmed' : null,
      unit: unitId ?? null,
    }),
  };
}

export default BookingRequestApi();
