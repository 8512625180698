import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dispatch } from 'src/redux/store';
import BookingRequestApi from 'src/api/booking-request';
import { BookingRequest, BookingRequestState } from 'src/@types/booking-request';


const initialState: BookingRequestState = {
  tabs: ['details'],
  loading: false,
  error: null,
  bookingRequest: null,
};

const slice = createSlice({
  name: 'bookingRequest',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    setHasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    setBookingRequest(state, action: PayloadAction<BookingRequest>) {
      state.bookingRequest = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setBookingRequest,
} = slice.actions;


export function getBookingRequest(id: number) {
  return async () => {
    dispatch(slice.actions.setLoading(true));
    try {
      const response = await BookingRequestApi.fetchBookingRequest(id);
      dispatch(slice.actions.setBookingRequest(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.setHasError(error));
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };
}