import { ReactNode } from 'react';
import LoadingScreen from 'src/components/LoadingScreen';
// hooks
import useAuth from '../hooks/useAuth';
// routes

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function SignupGuard({ children }: GuestGuardProps) {
  const {  isInitialized } = useAuth();

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
}
