import { ClickSendAccountDetails, Params, RemoteLockData } from 'src/@types/integrations';
import axios from 'src/utils/axios';
import { FilterValue } from '../@types/filter';
import { SortDirection } from '../@types/booking-list';
import { KeySetData } from 'src/@types/guest';
import { Meta } from 'src/@types/common';
import { DateCondition } from 'src/sections/@dashboard/guest/data-grid/DateCustomAccordionFilter';
import { fYearMonthDay } from 'src/utils/formatTime';

function integrationsAPI() {
  return {
    fetchIntegrations: (isApproved: string = '', userId: number | null = null) => {
      const url = '/apis/integration_partners';
      return axios.get(url, {
        params: {
          approve: isApproved,
          userId: userId,
        },
      });
    },
    getIntegrationById: (id: number) => axios.get(`/apis/integration_partners/${id}`),
    getRelatedIntegration: (integration_partner_id: number, investorId: number | null = null) => {
      const url = '/apis/get_related_integration';
      return axios.get(url, {
        params: {
          integration_partner_id,
          investor_id: investorId,
        },
      });
    },
    syncKeyNest: () => {
      const url = '/apis/sync_keynest';
      return axios.post(url);
    },
    suggestIntegration: (body: { name: string; websiteAddress? }) => {
      const url = '/apis/integration_suggestions';
      return axios.post(url, body);
    },
    changeParameter: (
      integration_id: number,
      integration_partner_id: number,
      params: Params[],
      investorId: number | null = null
    ) => {
      const url = '/apis/change_parameter';
      return axios.post(url, {
        integration_id,
        integration_partner_id,
        params,
        investor_id: investorId,
      });
    },
    changeClickSendParameter: (body: any) => {
      const url = '/apis/change_clicksend_parameter';
      return axios.post(url, { ...body });
    },
    changeSignableParameter: (body: any) => {
      const url = '/apis/change_signable_parameter';
      return axios.post(url, { ...body });
    },
    changeTagManagerParameter: (body: any) => {
      const url = '/apis/change_google_tag_management_parameter';
      return axios.post(url, { ...body });
    },
    changeGoogleAnalyticsParameter: (body: any) => {
      const url = '/apis/change_googleanalytics_parameter';
      return axios.post(url, { ...body });
    },
    disconnectIntegration: (integration_id: number) => {
      const url = '/apis/disconnect_integration';
      return axios.post(url, {
        integration_id,
      });
    },
    reconnectIntegration: (
      integrationId: number,
      integrationPartnerId: number,
      investorId: number | null = null
    ) => {
      const url = '/apis/reconnect_redirect_type_integrations';
      return axios.post(url, {
        integration_id: integrationId,
        integration_partner_id: integrationPartnerId,
        investor_id: investorId,
      });
    },
    connectXero: (code) => {
      const url = '/apis/xero_authenticate';
      return axios.get(`${url}?code=${code}`);
    },
    connectStripe: (integrationId: number) => {
      const url = `/apis/stripe_authenticate/${integrationId}`;
      return axios.get(url);
    },
    connectKeynest: (code) => {
      const url = '/apis/keynest_authenticate';
      return axios.get(`${url}?code=${code}`);
    },
    connectRemotelock: (code) => {
      const url = '/apis/smart_lock_authenticate';
      return axios.get(`${url}?code=${code}`);
    },
    fetchXeroConnectionSetups: () => axios.get('/apis/xero/get_xero_connection_setups'),
    fetchXeroTaxSchemaList: ({
      page,
      limit,
      advanceFilterParams,
    }: {
      page: number;
      limit: number;
      advanceFilterParams?: { key: string; value: string };
    }) =>
      axios.get('/apis/list/taxSchemas', {
        params: {
          page,
          limit,
          ...(advanceFilterParams && { [`${advanceFilterParams.key}`]: advanceFilterParams.value }),
        },
      }),
    syncXeroTaxRates: () => axios.get('/apis/xero/sync_xero_tax_rates'),
    fetchTrackingCategoriesForXero: () => axios.get('/apis/xero_tracking_categories'),
    syncTrackingCategoriesWithXero: () => axios.get('/apis/xero/sync_tracking_categories'),
    syncTrackingCategoryOptionsWithXero: (trackingCategoryId: number, categoryType: string) =>
      axios.post(`/apis/xero/sync_tracking_category_options/${trackingCategoryId}`, {
        category_type: categoryType,
      }),
    updateTrackingCategoryOptionForXero: (trackingCategoryOptionId: number, targetName: string) =>
      axios.put(`/apis/xero/edit_xero_tracking_category_option/${trackingCategoryOptionId}`, {
        target_name: targetName,
      }),
    fetchAccountingSchemaList: ({
      page,
      limit,
      filtersState,
      order,
      signal,
      advanceFilterParam,
      headers,
      responseType,
      fields,
    }: {
      page?: number;
      limit?: number;
      filtersState: {
        created_at?: { from?: string | null; to?: string | null };
        defaultSchema?: FilterValue;
      };
      order?: { field: string; sort: SortDirection };
      signal?: AbortSignal;
      advanceFilterParam?: { key: string; value: string };
      headers?: {
        Accept: 'text/csv' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      };
      responseType?: 'blob';
      fields?: any[];
    }) => {
      const url = '/apis/list/accountingSchemas';
      return axios.get(url, {
        signal,
        params: {
          page,
          limit,
          'created_at[after]': filtersState.created_at ? filtersState.created_at?.from : undefined,
          'created_at[strictly_before]': filtersState.created_at
            ? filtersState.created_at?.to
            : undefined,
          ...(filtersState?.defaultSchema && { defaultSchema: filtersState.defaultSchema.value }),
          ...(order && { [`order[${order.field}]`]: order.sort }),
          ...(advanceFilterParam && { [`${advanceFilterParam.key}`]: advanceFilterParam.value }),
          ...(fields && { fields }),
        },
        ...(headers && { headers }),
        ...(responseType && { responseType }),
      });
    },
    createAccountingSchema: ({
      defaultSchema,
      investorDeal,
      accommodationRevenue,
      addonRevenue,
      penaltyRevenue,
      otherRevenue,
      addonExpense,
      otherExpense,
      otaCommission,
      paymentProcessingExpense,
      refundExpense,
      managementFeeRevenue,
    }: {
      defaultSchema: boolean;
      investorDeal?: number;
      accommodationRevenue?: number;
      addonRevenue?: number;
      penaltyRevenue?: number;
      otherRevenue?: number;
      addonExpense?: number;
      otherExpense?: number;
      otaCommission?: number;
      paymentProcessingExpense?: number;
      refundExpense?: number;
      managementFeeRevenue?: number;
    }) => {
      const url = '/apis/accounting_schema_defaults';
      return axios.post(url, {
        default_schema: defaultSchema,
        ...(investorDeal && { investor_deal: investorDeal }),
        ...(accommodationRevenue && { accommodation_revenue: accommodationRevenue }),
        ...(addonRevenue && { addon_revenue: addonRevenue }),
        ...(penaltyRevenue && { penalty_revenue: penaltyRevenue }),
        ...(otherRevenue && { other_revenue: otherRevenue }),
        ...(addonExpense && { addon_expense: addonExpense }),
        ...(otherExpense && { other_expense: otherExpense }),
        ...(otaCommission && { ota_commission: otaCommission }),
        ...(paymentProcessingExpense && { payment_processing_expense: paymentProcessingExpense }),
        ...(refundExpense && { refund_expense: refundExpense }),
        ...(managementFeeRevenue && { management_fee_revenue: managementFeeRevenue }),
      });
    },
    updateAccountingSchema: (
      id: number,
      {
        accommodationRevenue,
        addonRevenue,
        penaltyRevenue,
        otherRevenue,
        addonExpense,
        otherExpense,
        otaCommission,
        paymentProcessingExpense,
        refundExpense,
        managementFeeRevenue,
      }: {
        accommodationRevenue?: number;
        addonRevenue?: number;
        penaltyRevenue?: number;
        otherRevenue?: number;
        addonExpense?: number;
        otherExpense?: number;
        otaCommission?: number;
        paymentProcessingExpense?: number;
        refundExpense?: number;
        managementFeeRevenue?: number;
      }
    ) => {
      const url = `/apis/accounting_schema_defaults/${id}`;
      return axios.put(url, {
        ...(accommodationRevenue && { accommodationRevenue: accommodationRevenue }),
        ...(addonRevenue && { addonRevenue: addonRevenue }),
        ...(penaltyRevenue && { penaltyRevenue: penaltyRevenue }),
        ...(otherRevenue && { otherRevenue: otherRevenue }),
        ...(addonExpense && { addonExpense: addonExpense }),
        ...(otherExpense && { otherExpense: otherExpense }),
        ...(otaCommission && { otaCommission: otaCommission }),
        ...(paymentProcessingExpense && { paymentProcessingExpense: paymentProcessingExpense }),
        ...(refundExpense && { refundExpense: refundExpense }),
        ...(managementFeeRevenue && { managementFeeRevenue: managementFeeRevenue }),
      });
    },
    deleteAccountingSchema: (id: number | string) => {
      const url = '/apis/xero/delete_accounting_schema';
      return axios.delete(url, {
        data: {
          id: id,
        },
      });
    },
    fetchDealsWithoutAccountSchema: (page: number, name?: string, limit?: number) =>
      axios.get('/apis/deal/no_accounting_schema', {
        params: {
          limit: limit ?? 50,
          page: page + 1,
          ...(name && { name: name }),
        },
      }),
    syncAccounts: () => axios.get('/apis/xero/sync_accounts'),
    fetchAccountList: ({
      page,
      limit,
      filtersState,
      order,
      signal,
      advanceFilterParam,
      headers,
      responseType,
      fields,
    }: {
      page?: number;
      limit?: number;
      filtersState?: {
        type?: FilterValue;
      };
      order?: { field: string; sort: SortDirection };
      signal?: AbortSignal;
      advanceFilterParam?: { key: string; value: string };
      headers?: {
        Accept: 'text/csv' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      };
      responseType?: 'blob';
      fields?: any[];
    }) => {
      const url = '/apis/list/accounts';
      return axios.get(url, {
        signal,
        params: {
          page,
          limit,
          ...(filtersState?.type && { type: filtersState.type.value }),
          ...(order && { [`order[${order.field}]`]: order.sort }),
          ...(advanceFilterParam && { [`${advanceFilterParam.key}`]: advanceFilterParam.value }),
          ...(fields && { fields }),
        },
        ...(headers && { headers }),
        ...(responseType && { responseType }),
      });
    },
    deleteAccount: (id: number | string) => {
      const url = '/apis/xero/delete_accounts';
      return axios.delete(url, {
        data: {
          id: id,
        },
      });
    },
    deleteTrackingCategoryOptionForXero: (trackingCategoryOptionId: number) =>
      axios.delete(`/apis/xero/delete_xero_tracking_category_options/${trackingCategoryOptionId}`),
    deleteTrackingCategoryForXero: (trackingCategoryId: number) =>
      axios.delete(`/apis/xero/delete_xero_tracking_category/${trackingCategoryId}`),
    getTrackingCategoryTypes: () => axios.get('/apis/xero/tracking_category_type'),
    getDeals: () => axios.get('/apis/deals'),
    getClickSendAccountDetail: () =>
      axios.get<ClickSendAccountDetails>('/apis/clickSendAccountDetails/get'),
    getKeyNestKeySetDetails: (page, limit) =>
      axios.get<{ data: KeySetData[]; meta: Meta }>('/apis/key-set-data-grid?meta=1', {
        params: {
          page,
          limit,
        },
      }),

    getRemoteLockDetails: (page: number, limit: number) =>
      axios.get<{ data: RemoteLockData[]; meta: Meta }>('/apis/smart-lock-data-grid?meta=1', {
        params: {
          page,
          limit,
        },
      }),
    getRemockLockDetailsById: (id: number) =>
      axios.get<RemoteLockData | null>(`/apis/smart_locks/${id}`),

    fetchKeyNestKeySetDetailsList: ({
      page,
      limit,
      filtersState,
      advanceFilterParams,
    }: {
      page: number;
      limit: number;

      filtersState: {
        only_show_not_set_property?: boolean;
        status?: {
          id: string;
          label: string;
          value: string;
        };
        property?: [
          {
            id: number;
            label: string;
            value: number;
          }
        ];
      };
      advanceFilterParams?: { key: string; value: string };
    }) =>
      axios.get('/apis/key-set-data-grid?meta=1', {
        params: {
          page,
          limit,
          ...(advanceFilterParams && { [`${advanceFilterParams.key}`]: advanceFilterParams.value }),
          ...(filtersState?.only_show_not_set_property && {
            only_show_not_set_property: filtersState?.only_show_not_set_property,
          }),
          ...(filtersState?.property && {
            property: filtersState.property.map((property) => property.id),
          }),
          ...(filtersState?.status && {
            validity: filtersState.status.value,
          }),
        },
      }),

    fetchRemoteLockDetailsList: ({
      page,
      limit,
      filtersState,
      advanceFilterParams,
    }: {
      page: number;
      limit: number;
      filtersState?: {
        type?: { value: string };
        property?: [
          {
            id: number;
            label: string;
            value: number;
          }
        ];
        unit?: [
          {
            id: number;
            label: string;
            value: number;
          }
        ];
      };
      advanceFilterParams?: { key: string; value: string };
    }) =>
      axios.get('/apis/smart-lock-data-grid?meta=1', {
        params: {
          page,
          limit,
          ...(advanceFilterParams && { [`${advanceFilterParams.key}`]: advanceFilterParams.value }),

          ...(filtersState?.property && {
            property: filtersState.property.map((property) => property.id),
          }),
          ...(filtersState?.unit && {
            unit: filtersState.unit.map((unit) => unit.id),
          }),
          ...(filtersState?.type && {
            smartLockType: filtersState?.type?.value,
          }),
        },
      }),

    getKeyNestKeySetDetailsById: (keySetId) => axios.get<KeySetData>(`/apis/key_sets/${keySetId}`),

    fetchSmsPanelConfigs: () => axios.get<ClickSendAccountDetails>('/apis/sms_panel_configs'),

    updateClicksend: (
      id: number | string,
      body: {
        title: string;
        username: string;
        password: string;
        line_number: string;
        custom_string: string;
      }
    ) => axios.put(`/apis/sms_panel_configs/${id}`, { ...body }),
    createSmsPanel: (body: {
      title: string;
      username: string;
      password: string;
      line_number: string;
      custom_string: string;
      type: string;
    }) => axios.post('/apis/sms_panel_configs', { ...body }),
    disconnectClicksend: (id: number | string) => axios.delete(`/apis/sms_panel_configs/${id}`),

    updateKeyNestKest: (
      id: number | string,
      body: {
        name: string;
      }
    ) => axios.put(`/apis/key_sets/${id}`, { ...body }),

    updateSetKey: (id: number, keySetData: any) => {
      const url = `/apis/key_sets/${id}`;
      return axios.put(url, keySetData);
    },

    updateRemoteLock: (remoteLockData: any) => {
      const url = `/apis/smart_lock/v1/edit_settings`;
      return axios.post(url, remoteLockData);
    },

    renameKeySet: (id: number, name: string) => {
      const url = `/apis/key_sets/${id}`;
      return axios.put(url, {
        name: name,
      });
    },
    renameRemoteLock: (id: number, name: string) => {
      const url = `/apis/smart_locks/${id}`;
      return axios.put(url, {
        zeevou_name: name,
      });
    },
    getBookingAndCodeWithSearchParamsApi: ({
      id,
      page,
      limit,
      advanceFilterParams,
    }: {
      id: number;
      page: number;
      limit: number;

      advanceFilterParams?: { key: string; value: string };
    }) =>
      axios.get(`/apis/key-set-booking-code-data-grid?key_set=${id}&bookingExist=true&meta=1`, {
        params: {
          page,
          limit,
          ...(advanceFilterParams && {
            [`${advanceFilterParams.key}`]: advanceFilterParams.value,
          }),
        },
      }),

    getSmartBookingAndCodeWithSearchParamsApi: ({
      id,
      page,
      limit,
      advanceFilterParams,
      filtersState,
    }: {
      id: number;
      page: number;
      limit: number;
      advanceFilterParams?: { key: string; value: string };
      filtersState?: {
        starts_at?: FilterValue;
        ends_at?: FilterValue;
      };
    }) => {
      const getStartAtSearchParams = (value: {
        condition: DateCondition;
        start: Date;
        end: Date | null;
      }): { [key: string]: string | null } => {
        const { condition, start, end } = value;
        const startValue = `${fYearMonthDay(start)}T00:00:00`;
        const endValue = end ? `${fYearMonthDay(end)}T23:59:59` : null;
        switch (condition) {
          case 'between':
            return {
              'starts_at[after]': startValue,
              'starts_at[before]': endValue,
            };
          case 'later_than':
            return { 'starts_at[strictly_after]': startValue };
          case 'earlier_than':
            return { 'starts_at[strictly_before]': startValue };
          case 'equal':
            return {
              'starts_at[after]': startValue,
              'starts_at[before]': `${fYearMonthDay(start)}T23:59:59`,
            };
          default:
            return {};
        }
      };

      const getEndAtSearchParams = (value: {
        condition: DateCondition;
        start: Date;
        end: Date | null;
      }): { [key: string]: string | null } => {
        const { condition, start, end } = value;
        const startValue = `${fYearMonthDay(start)}T00:00:00`;
        const endValue = end ? `${fYearMonthDay(end)}T23:59:59` : null;
        switch (condition) {
          case 'between':
            return {
              'ends_at[after]': startValue,
              'ends_at[before]': endValue,
            };
          case 'later_than':
            return { 'ends_at[strictly_after]': startValue };
          case 'earlier_than':
            return { 'ends_at[strictly_before]': startValue };
          case 'equal':
            return {
              'ends_at[after]': startValue,
              'ends_at[before]': `${fYearMonthDay(start)}T23:59:59`,
            };
          default:
            return {};
        }
      };

      return axios.get(`/apis/smart-lock-booking-data-grid?meta=1&smart_lock_id=${id}`, {
        params: {
          page,
          limit,
          ...(advanceFilterParams && { [`${advanceFilterParams.key}`]: advanceFilterParams.value }),
          ...(filtersState?.starts_at && {
            ...getStartAtSearchParams((filtersState?.starts_at as FilterValue).value),
          }),
          ...(filtersState?.ends_at && {
            ...getEndAtSearchParams((filtersState?.ends_at as FilterValue).value),
          }),
        },
      });
    },

    changeKeySetProperty: (id: number, property: number) => {
      const url = `/apis/key_sets/${id}`;
      return axios.put(url, {
        property: property,
      });
    },
    changeKeySetPropertyUnit: (id: number, propertyUnit: number) => {
      const url = `/apis/key_sets/${id}`;
      return axios.put(url, {
        propertyUnit: propertyUnit,
      });
    },

    getKeyNestKeySetBookingCodeDetailsById: (keySetId) =>
      axios.get<{ data: KeySetData; meta: Meta }>(
        `/apis/key-set-booking-code-data-grid?key_set=${keySetId}&bookingExist=true&meta=1`
      ),

    getRemoteLockBookingCodeDetailsById: (smartLockId) =>
      axios.get<{ data: KeySetData; meta: Meta }>(
        `/apis/smart-lock-booking-data-grid?meta=1&smart_lock_id=${smartLockId}`
      ),

    removeAccessForRemoteLock: (smartLockId, smartLockBookingId) =>
      axios.post<{}>(`/apis/remove_access`, { id: smartLockBookingId, slid: smartLockId }),
    getStripeIntegrations: () =>
      axios.get<{}>(`/apis/integrations?label=${'stripe'}&stripeDetailsSubmitted=1`, {}),

    deleteIntegration: (id: number) => {
      const url = `/apis/integrations/${id}`;
      return axios.delete(url);
    },
    saveStripeIntegraion: (body: any) => {
      const url = `/apis/create_new_stripe_connection`;
      return axios.post(url, body);
    },
  };
}

export default integrationsAPI();
