import React, { useEffect, useRef } from "react";
// @mui
import { Box, Button, ButtonProps, Collapse, Stack, StackProps, TypographyProps } from '@mui/material';
import { Variant } from "@mui/material/styles/createTypography";
// hooks
import useLocales from "src/hooks/useLocales";
// utils
import GetFontValue from "src/utils/getFontValue";
// components
import TextMaxLine from "src/components/TextMaxLine";


interface Props extends TypographyProps {
  line: number;
  variant: Variant;
  overriddenExpanded?: boolean;
  button?: React.ReactNode;
  ButtonProps?: ButtonProps;
  WrapperProps?: StackProps;
}

const ReadMore = ({ line, overriddenExpanded, button, children, ButtonProps, WrapperProps, ref, ...other }: Props) => {
  const { translate } = useLocales('common');
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [needReadMore, setNeedReadMore] = React.useState<boolean>(true);
  const [isInitiateRender, setIsInitiateRender] = React.useState<boolean>(true);
  const textRef = useRef<HTMLAnchorElement>(null);
  const { lineHeight } = GetFontValue(other.variant);
  const [size, setSize] = React.useState<number>(lineHeight * line);

  useEffect(() => {
    if (typeof overriddenExpanded !== 'undefined') {
      setExpanded(overriddenExpanded);
    }
  }, [overriddenExpanded]);

  useEffect(() => {
    const toggleNeedReadMore = () => {
      if (textRef?.current?.scrollHeight) {
        setIsInitiateRender(false);
        if (textRef?.current?.scrollHeight > size) {
          setNeedReadMore(true);
          setSize(lineHeight * line);
        } else {
          setNeedReadMore(false);
          setSize(textRef?.current?.scrollHeight);
        }
      }
    };
    toggleNeedReadMore();
    window.addEventListener('resize', () => toggleNeedReadMore());
    return () => {
      window.removeEventListener('resize', () => toggleNeedReadMore());
    };
  }, [textRef?.current?.scrollHeight]);


  return (
    <Stack {...WrapperProps}>
      <Collapse in={expanded} collapsedSize={size}>
        <TextMaxLine ref={textRef} {...other} line={line} disabledLine={expanded || isInitiateRender}>
          {children}
        </TextMaxLine>
      </Collapse>
      {needReadMore && (
        button
          ? button
          : (
            <Box color="text.secondary">
              <Button
                onClick={() => setExpanded(!expanded)}
                color="inherit" {...ButtonProps}
                sx={(theme) => ({
                  alignSelf: 'start',
                  color: expanded ? theme.palette.info.main : 'inherit',
                  '&:hover': { background: 'none', color: expanded ? theme.palette.info.main : theme.palette.text.primary }
                })}
              >
                {expanded ? translate('read_less') : translate('read_more')}
              </Button>
            </Box>
          )
      )}
    </Stack>
  );
}

export default ReadMore;
