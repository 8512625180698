// @types
import { BillingSettings, Brand, CardType, ContractLine, OrganisationDetails } from 'src/@types/account';
import { UserAddressProps } from 'src/@types/user';

export enum ServiceNameEnum {
  ROOM = 'room',
  ENTIRE_PLACE = 'entire place',
}

export const brandNormalizer = (obj): Brand => ({
  id: obj.id,
  name: obj.name,
  listings: obj.listings && obj.listings.length > 0
    ? obj.listings.map((item) => ({
      id: item.id,
      name: item.name,
      shortName: item.short_name,
    }))
    : [],
  logo: {
    fileId: obj.logo.file_id,
    fileName: obj.logo.file_name,
  },
  bookingEngineLink: obj.booking_engine_link,
});

export const organisationNormalizer = (obj): OrganisationDetails => ({
  id: obj.id,
  name: obj.general_info.name,
  commitmentName: obj.general_info.commitment_name,
  featureTypeName: obj.general_info.feature_type_name,
  contractId: obj.general_info.contract_id,
  contractLines: obj.general_info?.contract_lines && obj.general_info?.contract_lines.length > 0
      ? obj.general_info.contract_lines.map((item) => contractLineNormalizer(item))
      : [],
  organizationConfigurationId: obj.company_details.organization_configuration_id,
  registeredName: obj.company_details.registered_name,
  registeredNumber: obj.company_details.registered_number,
  dataProtectionNumber: obj.company_details.data_protection_number,
  currency: obj.company_details.currency,
  brands: obj.brands.map((brand) => brandNormalizer(brand)),
  actions: {
    showAddABrand: obj.actions.show_add_a_brand_button,
    showManagementAccount: obj.actions.show_manage_account_button,
  },
});

export const contractLineNormalizer = (item): ContractLine => ({
  id: item.id,
  quantity: item.quantity,
  serviceName: item.service_name,
});

export const billingSettingsNormalizer = (obj): BillingSettings => ({
  contactEmail: obj.contact_email,
  customerId: obj.customer_id,
  defaultBillingAddress: obj.default_billing_address,
  defaultCard: obj.default_card,
  paymentMethod: obj.payment_method,
});

export const getCardIconFromType = (cardType: CardType) => {
  switch (cardType) {
    case 'VisaDebit':
    case 'Visa':
      return '/icons/paymentCard/ic_visa.svg';
    case 'MasterCard':
    case 'DebitMasterCard':
      return '/icons/paymentCard/ic_master_card.svg';
    case 'American-Express':
      return '/icons/paymentCard/ic_american_express.svg';
    default:
      return '';
  }
}

export const getAddressFromType = (address: UserAddressProps) => {
  return [address.first_line, address.city, address.country?.iso2_code].join(', ');
}