import { SearchOption } from "src/components/SearchLocation";

export interface CityOption extends SearchOption {
  tag?: string;
  subtitle?: string;
}

export const data: CityOption[] = [
  {
    "id": 0,
    "type": "zeevou_active_city",
    "destination": "London",
    "parent": "City",
  },
  {
    "id": 1,
    "type": "zeevou_active_city",
    "destination": "london",
    "parent": "City",
  },
  {
    "id": 2,
    "type": "zeevou_active_property",
    "destination": "London",
    "parent": "Property",
  },
  {
    "id": 3,
    "type": "zeevou_active_property",
    "destination": "London Place",
    "parent": "Property",
    "subtitle": "London"
  },
  {
    "id": 4,
    "type": "zeevou_active_property",
    "destination": "London Caraway Heights",
    "parent": "Property",
    "subtitle": "London"
  },
  {
    "id": 5,
    "type": "zeevou_active_property",
    "destination": "62 London Road",
    "parent": "Property",
    "subtitle": "London"
  },
  {
    "id": 6,
    "type": "zeevou_active_property",
    "destination": "London Russell Square",
    "parent": "Property",
    "subtitle": "London"
  },
  {
    "id": 7,
    "type": "zeevou_active_property",
    "destination": "London, free parking and wifi",
    "parent": "Property",
    "subtitle": "London"
  },
  {
    "id": 8,
    "type": "zeevou_active_property",
    "destination": "London Heathrow Living Serviced Apartments by Ferndale",
    "parent": "Property",
    "subtitle": "London"
  },
  {
    "id": 9,
    "type": "zeevou_active_property",
    "destination": "London Unique Cosy 2 Bed Duplex in Canary Wharf - Parking",
    "parent": "Property",
    "subtitle": "London"
  },
  {
    "id": 10,
    "type": "zeevou_active_property",
    "destination": "Downtown Condo",
    "parent": "Property",
    "subtitle": "London"
  },
  {
    "id": 11,
    "type": "zeevou_active_property",
    "destination": "1BR/1BA few steps to London's East end",
    "parent": "Property",
    "subtitle": "London"
  }
];

export const filters: SearchOption[] = [
  { id: 'country', type: 'country', destination: 'Country:', variant: 'filter' },
  { id: 'city', type: 'city', destination: 'City:', variant: 'filter' },
  { id: 'region', type: 'region', destination: 'Region:', variant: 'filter' },
  { id: 'property', type: 'property', destination: 'Property:', variant: 'filter' },
  { id: 'unitType', type: 'unitType', destination: 'Unit Type:', variant: 'filter' },
  { id: 'unit', type: 'unit', destination: 'Unit:', variant: 'filter' },
  // { id: 'zipCode', type: 'zipCode', destination: 'Zip Code:', variant: 'filter' },
];