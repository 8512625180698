import { UpdateableGuestFields, Invoice, Payment, Guest } from 'src/@types/guest';
import axios from '../utils/axios';
import qs from 'qs';
import { FilterState } from 'src/@types/filter';
import { Meta } from 'src/@types/common';

function guestAPI() {
  return {
    fetchGuest: (id: number) => axios.get<Guest>(`/apis/guests/${id}`),
    fetchGuestsList: ({
      page,
      limit,
      signal,
      searchParams,
      headers,
      responseType,
      fields,
    }: {
      page?: number;
      limit?: number;
      signal?: AbortSignal;
      searchParams?: { [key: string]: any };
      headers?: {
        Accept: 'text/csv' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      };
      responseType?: 'blob';
      fields?: any[];
    }) =>
      axios.get('/apis/guest-data-grid', {
        signal,
        params: {
          ...(page && { page }),
          ...(limit && { limit }),
          ...searchParams,
          ...(fields && { fields }),
        },
        ...(headers && {
          headers,
        }),
        ...(responseType && { responseType }),
      }),
    fetchInvoices: (guestId: number) =>
      axios.get<Invoice[]>(`/apis/guest/booking_data_grid?guest_id=${guestId}`),
    fetchPayments: (guestId: number) =>
      axios.get<Payment[]>(`/apis/guest/payment_data_grid?guest_id=${guestId}`),
    updateGuest: (id: number, body: UpdateableGuestFields | { [x: string]: any }) =>
      axios.put(`/apis/guests/${id}`, body),
    checkDuplicateGuest: (email?: string, mobile?: string) =>
      axios.get<{ isMatch: boolean }>('/apis/guest/guest_duplication_check', {
        params: {
          email,
          mobile,
        },
        paramsSerializer: (params) => qs.stringify(params),
      }),
    getCountryRegions: (code: string) => axios.get(`/apis/countries/${code}/regions`),
    updateGuestDocument: (id: number, body: { [x: string]: any }) =>
      axios.put(`/apis/guest_documents/${id}`, body),
    updateCustomQuestion: (id: number, body: { [x: string]: any }) =>
      axios.put(`/apis/custom_questions/${id}`, body),
    createPayment: (
      guestId: number,
      amount: number,
      currency: string,
      date: string,
      time: string,
      type: string,
      reference: string | null,
      description: string | null,
      file_ids: number[] | null,
      transactionCostSales: number,
      transactionCostPurchase: number,
      currencyConversionSales: number,
      currencyConversionPurchase: number
    ) =>
      axios.post('/apis/guest/add_payment', {
        guest_id: guestId,
        amount,
        currency,
        date,
        time,
        type,
        reference,
        description,
        file_ids,
        transaction_cost_sales: transactionCostSales,
        transaction_cost_purchase: transactionCostPurchase,
        currency_conversion_sales: currencyConversionSales,
        currency_conversion_purchase: currencyConversionPurchase,
      }),
    fetchRelatedGuestBookings: (
      id: number,
      signal?: AbortSignal,
      pageNo?: number,
      limit?: number,
      searchTerm?: string
    ) =>
      axios.get('/apis/guest/list/bookings', {
        signal,
        params: { guest: id, search: searchTerm || '', page: pageNo || 1, limit },
      }),
    fetchReviews: (guestId: number, page: number, limit: number) =>
      axios.get('/apis/v2/review', { params: { guest: guestId, page, limit } }),
  };
}

export default guestAPI();
