import { TFunction } from 'react-i18next';
import { generatePath } from 'react-router';
import {
  AcceptableTabsOnMarketingPageSettings,
  ContentPage,
  Brand,
  FrontendMenu,
  MarketingSubTab,
  MarketingTabName,
  MarketingTabs,
  GeneralSettingsTab,
  PagesTab,
  BlogsTab,
  BrandConfig,
  City,
  Country,
  TagAndCategory,
  MarketingDashboardTab,
  AllBrandsApiResType,
  Property,
} from 'src/@types/marketing';
import { TLink } from 'src/components/Breadcrumbs';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { hasReviewViewAccess } from 'src/components/reviews';

export const marketingTabs: MarketingTabs = {
  general_settings: [
    'theme_and_design',
    'header',
    'footer',
    'site_management',
    'chat_via_Whatsapp',
  ],
  pages: ['single_pages' /*, 'listings', 'cities', 'countries'*/],
  blogs: [/*'blog_homepage',*/ 'posts', 'categories', 'tags'],
};

export const defaultPagesBaseUrl = {
  '/': {
    title: 'Homepage',
    url: '/',
    page_id: 'homepage',
  },
  '/destinations': {
    title: 'Destinations',
    url: '/destinations',
    page_id: 'destinations',
  },
  '/properties': {
    title: 'Properties',
    url: '/properties',
    page_id: 'properties',
  },
  '/contact-us': {
    title: 'Contact Us',
    url: '/contact-us',
    page_id: 'contact-us',
  },
  '/blog': {
    title: 'Blog',
    url: '/blog',
    page_id: 'blog',
  },
  '/search': {
    title: 'Search',
    url: '/search',
    page_id: 'search',
  },
};

export const defaultPagesBasePageId = {
  home_page: {
    title: 'Homepage',
    url: '/',
    page_id: 'homepage',
  },
  destinations: {
    title: 'Destinations',
    url: '/destinations',
    page_id: 'destinations',
  },
  properties: {
    title: 'Properties',
    url: '/properties',
    page_id: 'properties',
  },
  contact_us: {
    title: 'Contact Us',
    url: '/contact-us',
    page_id: 'contact_us',
  },
  blog: {
    title: 'Blog',
    url: '/blog',
    page_id: 'blog',
  },
  search: {
    title: 'Search',
    url: '/search',
    page_id: 'search',
  },
};

export const findLastBrandMenusWeight = (menus: FrontendMenu[]) => {
  let maxWeight = 1;
  menus.forEach((menu) => {
    if (menu.weight > maxWeight) maxWeight = menu.weight;
  });
  return maxWeight;
};

export const sortBrandMenus = (menus: FrontendMenu[]): FrontendMenu[] =>
  menus.sort((a, b) => a.weight - b.weight);

export const hasHomepagePrimaryMenuItem = (menus: FrontendMenu[]): boolean =>
  !!menus.find((menu) => menu.link === '/' && menu.position === 'primary');

export const hasMoreThanPrimaryMenuItemsLimit = (menus: FrontendMenu[]): boolean =>
  menus.filter((menu) => menu.position === 'primary').length > 7;

export const acceptableTabsOnMarketingPageSettings: AcceptableTabsOnMarketingPageSettings = {
  pages: ['single_pages', 'listings', 'cities', 'countries'],
  blogs: ['posts', 'categories', 'tags'],
};

export const generateMarketingPath = (
  brandId: number,
  tab: MarketingTabName,
  subTab: MarketingSubTab,
  pageId?: string | number
): string => {
  if (pageId) {
    return generatePath(PATH_DASHBOARD.marketing.pageSettings, {
      brandId: String(brandId),
      tab,
      subTab,
      pageId: String(pageId),
    });
  }
  return generatePath(PATH_DASHBOARD.marketing.websiteSettings, {
    brandId: String(brandId),
    tab,
    subTab,
  });
};

export const getMarketingPageStructure = (
  marketingTabs: MarketingTabs,
  translate: TFunction<any[], undefined>,
  pageId: string | undefined,
  brand: Brand | null,
  tab: MarketingTabName,
  subTab: MarketingSubTab,
  contentPage: ContentPage | null,
  selectedBlogPage: ContentPage | null,
  cityConfig: City | null,
  countryConfig: Country | null,
  selectedTagOrCategory: TagAndCategory | null,
  listing: Property | null
): { title: string; breadcrumbsLinks: TLink[] } => {
  let title = '';
  let breadcrumbsLinks: TLink[] = [
    {
      name: translate('marketing'),
      href: generatePath(PATH_DASHBOARD.marketing.root, { tab: 'websites' }),
    },
  ];
  if (brand) {
    breadcrumbsLinks = [
      ...breadcrumbsLinks,
      {
        name: brand.name,
        href: generateMarketingPath(
          brand.id,
          'general_settings',
          marketingTabs[Object.keys(marketingTabs)[0]][0]
        ),
      },
    ];
    if (pageId) {
      breadcrumbsLinks = [
        ...breadcrumbsLinks,
        {
          name: translate(tab),
          href: generateMarketingPath(brand.id, tab, marketingTabs[tab][0]),
        },
        {
          name: translate(subTab),
          href: generateMarketingPath(brand.id, tab, subTab),
        },
      ];
      switch (tab) {
        case 'pages': {
          switch (subTab) {
            case 'single_pages':
              const pageName =
                defaultPagesBasePageId[pageId]?.title ||
                (contentPage?.id === Number(pageId) ? contentPage?.title : null);
              title = pageName || '';
              breadcrumbsLinks = [
                ...breadcrumbsLinks,
                ...(pageName
                  ? [
                      {
                        name: pageName,
                      },
                    ]
                  : ([] as TLink[])),
              ];
              break;
            case 'listings':
              title = listing?.name || '';
              breadcrumbsLinks = [
                ...breadcrumbsLinks,
                ...(listing ? [{ name: listing.name }] : ([] as TLink[])),
              ];
              break;
            case 'cities':
              title = cityConfig?.name || '';
              breadcrumbsLinks = [
                ...breadcrumbsLinks,
                ...(cityConfig ? [{ name: cityConfig.name }] : ([] as TLink[])),
              ];
              break;
            case 'countries':
              title = countryConfig?.name || '';
              breadcrumbsLinks = [
                ...breadcrumbsLinks,
                ...(countryConfig ? [{ name: countryConfig.name }] : ([] as TLink[])),
              ];
          }
          break;
        }
        case 'blogs': {
          const isCreateBlog = pageId.toLowerCase() === 'create';
          title = isCreateBlog ? translate('create_a_blog_post') : selectedBlogPage?.title || '';

          const getName = () => {
            if (isCreateBlog) return translate('create');
            else if (!selectedTagOrCategory) return selectedBlogPage?.title || '';
            return selectedTagOrCategory.title || '';
          };

          breadcrumbsLinks = [
            ...breadcrumbsLinks,
            {
              name: getName(),
            },
          ];
        }
      }
    } else {
      title = brand.name;
    }
  }
  return { title, breadcrumbsLinks };
};

//acls
export const hasBrandViewAccess = (user) => user.acls.PeymansPeymansOrganizationBundleBrand.VIEW;
export const hasBrandCreateAccess = (user) =>
  user.acls.PeymansPeymansOrganizationBundleBrand.CREATE;
export const hasBrandEditAccess = (user) => user.acls.PeymansPeymansOrganizationBundleBrand.EDIT;

export const hasFrontendStyleViewAccess = (user) =>
  user.acls.PeymansFrontendApiBundleFrontendStyle.VIEW;
export const hasFrontendStyleEditAccess = (user) =>
  user.acls.PeymansFrontendApiBundleFrontendStyle.EDIT;

export const hasBrandConfigViewAccess = (user) =>
  user.acls.PeymansPeymansOrganizationBundleOrganizationConfiguration.VIEW;
export const hasBrandConfigEditAccess = (user) =>
  user.acls.PeymansPeymansOrganizationBundleOrganizationConfiguration.EDIT;

export const hasFrontendMenuViewAccess = (user) =>
  user.acls.PeymansFrontendApiBundleFrontendMenu.VIEW;
export const hasFrontendMenuCreateAccess = (user) =>
  user.acls.PeymansFrontendApiBundleFrontendMenu.CREATE;
export const hasFrontendMenuEditAccess = (user) =>
  user.acls.PeymansFrontendApiBundleFrontendMenu.EDIT;
export const hasFrontendMenuDeleteAccess = (user) =>
  user.acls.PeymansFrontendApiBundleFrontendMenu.DELETE;

export const hasContactConfigViewAccess = (user) =>
  user.acls.PeymansFrontendApiBundleContactConfig.VIEW;
export const hasContactConfigEditAccess = (user) =>
  user.acls.PeymansFrontendApiBundleContactConfig.EDIT;

export const hasDestinationConfigViewAccess = (user) =>
  user.acls.PeymansFrontendApiBundleDestinationConfig.VIEW;
export const hasDestinationConfigEditAccess = (user) =>
  user.acls.PeymansFrontendApiBundleDestinationConfig.EDIT;

export const hasCustomDomainExecuteAccess = (user) =>
  user.acls.peymans_peymansOrganization_organization_bundle_custom_domain.EXECUTE;

export const hasContentPageViewAccess = (user) => user.acls.PeymansFrontendApiBundleContent.VIEW;
export const hasContentPageCreateAccess = (user) =>
  user.acls.PeymansFrontendApiBundleContent.CREATE;
export const hasContentPageEditAccess = (user) => user.acls.PeymansFrontendApiBundleContent.EDIT;
export const hasContentPageDeleteAccess = (user) =>
  user.acls.PeymansFrontendApiBundleContent.DELETE;
export const hasRouteConfigViewAccess = (user) => user.acls.PeymansBookingEngineBundleRoute.VIEW;
export const hasRouteConfigEditAccess = (user) => user.acls.PeymansBookingEngineBundleRoute.EDIT;

export const hasPropertyViewAccess = (user) => user.acls.PeymansPropertyBundleProperty.VIEW;
export const hasPropertyEditAccess = (user) => user.acls.PeymansPropertyBundleProperty.EDIT;

export const hasCityConfigViewAccess = (user) => user.acls.PeymansCommonBundleCity.VIEW;
export const hasCityConfigEditAccess = (user) => user.acls.PeymansCommonBundleCity.EDIT;
export const hasCityConfigCreateAccess = (user) => user.acls.PeymansCommonBundleCity.CREATE;

export const hasCountryConfigViewAccess = (user) => user.acls.PeymansCommonBundleCountryConfig.VIEW;
export const hasCountryConfigEditAccess = (user) => user.acls.PeymansCommonBundleCountryConfig.EDIT;
export const hasCountryConfigCreateAccess = (user) =>
  user.acls.PeymansCommonBundleCountryConfig.CREATE;

export const hasTagsAndCategoriesViewAccess = (user) =>
  user.acls.PeymansFrontendApiBundleTagsAndCategories.VIEW;
export const hasTagsAndCategoriesCreateAccess = (user) =>
  user.acls.PeymansFrontendApiBundleTagsAndCategories.CREATE;
export const hasTagsAndCategoriesEditAccess = (user) =>
  user.acls.PeymansFrontendApiBundleTagsAndCategories.EDIT;
export const hasTagsAndCategoriesDeleteAccess = (user) =>
  user.acls.PeymansFrontendApiBundleTagsAndCategories.DELETE;

export const hasNewsletterSubscriberViewAccess = (user) =>
  user.acls.PeymansFrontendApiBundleNewsletterSubscriber.VIEW;
export const hasNewsletterSubscriberCreateAccess = (user) =>
  user.acls.PeymansFrontendApiBundleNewsletterSubscriber.CREATE;
export const hasNewsletterSubscriberEditAcces = (user) =>
  user.acls.PeymansFrontendApiBundleNewsletterSubscriber.EDIT;
export const hasNewsletterSubscriberDeleteAcces = (user) =>
  user.acls.PeymansFrontendApiBundleNewsletterSubscriber.DELETE;

export const checkMarketingTabsAcls = (user, tab: MarketingTabName): boolean => {
  if (hasBrandViewAccess(user)) {
    switch (tab) {
      case 'general_settings':
        return hasFrontendStyleViewAccess(user) || hasBrandConfigViewAccess(user);
      case 'pages':
        return (
          hasContentPageViewAccess(user) ||
          hasPropertyViewAccess(user) ||
          hasCityConfigViewAccess(user) ||
          hasCountryConfigViewAccess(user)
        );
      case 'blogs':
        return hasContentPageViewAccess(user) || hasTagsAndCategoriesViewAccess(user);
    }
  }
  return false;
};

export const checkMarketingSubTabsAcls = (user, subTab: MarketingSubTab): boolean => {
  switch (subTab) {
    case 'theme_and_design':
      return hasFrontendStyleViewAccess(user);
    case 'header':
      return hasFrontendMenuViewAccess(user);
    case 'footer':
      return hasFrontendMenuViewAccess(user) || hasNewsletterSubscriberViewAccess(user);
    case 'site_management':
      return hasBrandConfigViewAccess(user);
    case 'chat_via_Whatsapp':
      return hasBrandConfigViewAccess(user) && hasContactConfigViewAccess(user);
    case 'single_pages':
      return hasContentPageViewAccess(user);
    case 'listings':
      return hasPropertyViewAccess(user);
    case 'cities':
      return hasCityConfigViewAccess(user);
    case 'countries':
      return hasCountryConfigViewAccess(user);
    case 'posts':
      return hasContentPageViewAccess(user);
    case 'tags':
    case 'categories':
      return hasTagsAndCategoriesViewAccess(user);
    default:
      return false;
  }
};

export const getMarketingTabs = (user): MarketingTabs =>
  hasBrandViewAccess(user)
    ? ({
        ...(checkMarketingTabsAcls(user, 'general_settings') && {
          general_settings: [
            ...(checkMarketingSubTabsAcls(user, 'theme_and_design') ? ['theme_and_design'] : []),
            ...(checkMarketingSubTabsAcls(user, 'header') ? ['header'] : []),
            ...(checkMarketingSubTabsAcls(user, 'footer') ? ['footer'] : []),
            ...(checkMarketingSubTabsAcls(user, 'site_management') ? ['site_management'] : []),
            ...(checkMarketingSubTabsAcls(user, 'chat_via_Whatsapp') ? ['chat_via_Whatsapp'] : []),
          ] as GeneralSettingsTab[],
        }),
        ...(checkMarketingTabsAcls(user, 'pages') && {
          pages: [
            ...(checkMarketingSubTabsAcls(user, 'single_pages') ? ['single_pages'] : []),
            ...(checkMarketingSubTabsAcls(user, 'listings') ? ['listings'] : []),
            ...(checkMarketingSubTabsAcls(user, 'cities') ? ['cities'] : []),
            ...(checkMarketingSubTabsAcls(user, 'countries') ? ['countries'] : []),
          ] as PagesTab[],
        }),
        ...(checkMarketingTabsAcls(user, 'blogs') && {
          blogs: [
            /*'blog_homepage',*/
            ...(checkMarketingSubTabsAcls(user, 'posts') ? ['posts'] : []),
            ...(checkMarketingSubTabsAcls(user, 'categories') ? ['categories', 'tags'] : []),
          ] as BlogsTab[],
        }),
      } as MarketingTabs)
    : ({} as MarketingTabs);

export const getBookingEngUrl = (config: BrandConfig) => {
  if (config.domain_approved && config.acceptable_custom_domain)
    return `https://${config.acceptable_custom_domain}`;
  return config.booking_engine_link;
};

export const getMarketingDashboardTabs = (user): MarketingDashboardTab[] =>
  [
    ...(hasBrandViewAccess(user) ? ['websites'] : []),
    ...(hasReviewViewAccess(user) ? ['reviews'] : []),
    ...(hasNewsletterSubscriberViewAccess(user) ? ['newsletter_subscriptions'] : []),
  ] as MarketingDashboardTab[];

export const getNewBrand = (data: any): AllBrandsApiResType => ({
  id: data.id,
  name: data.name,
  config: {
    booking_engine_link: data.booking_engine_link,
    acceptable_custom_domain: data.acceptable_custom_domain ?? null,
    domain_approved: data.domain_approved ?? false,
  },
  frontend_style: {
    image: {
      id: 0,
      file: {
        id: data.logo.file_id,
        file_name: data.logo.file_name,
        original_file_name: data.logo.original_file_name ?? '',
        mime_type: '',
        file_size: 0,
      },
    },
  },
  created_at: data.created_at ?? new Date(),
  updated_at: data.updated_at ?? new Date(),
});
