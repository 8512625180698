import { Theme, alpha } from '@mui/material/styles';
import {  getLimitTagsText, renderGroup, popupIcon } from 'src/utils/autocomplete';

// ----------------------------------------------------------------------

export default function Autocomplete(theme: Theme) {
  const isLight = theme.palette.mode === 'light';

  const boxShadow = `0 0 2px 0 ${alpha(
    isLight ? theme.palette.grey[500] : theme.palette.common.black,
    0.2
  )}, ${theme.customShadows.z20}`;

  return {
    MuiAutocomplete: {
      defaultProps: {
        popupIcon,
        renderGroup,
        getLimitTagsText,
      },
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            gap: theme.spacing(1),
          },
        },
        popper: {
          // minWidth: 260,
          // maxWidth: 260,
        },
        paper: {
          boxShadow,
        },
        popupIndicatorOpen: {
          color: theme.palette.primary.main,
        },
        listbox: ({ ownerState }) => ({
          padding: theme.spacing(1),
          '& .MuiAutocomplete-option': {
            padding: theme.spacing(ownerState.multiple ? 0.5 : 1.5, 1),
            margin: theme.spacing(ownerState.groupBy ? 0 : 0.5, 0),
            borderRadius: theme.shape.borderRadius,
          },
        }),
      },
    },
  };
}
