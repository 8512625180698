import { NotificationSettingUpdateBody } from 'src/@types/setting';
import axios from '../utils/axios';

function settingAPI() {
  return {
    fetchNotificationSettings: () => axios.get('/apis/notification_setting/get_notification_setting'),
    updateNotificationSetting: (
      id: number,
      body: NotificationSettingUpdateBody,
    ) => {
      const url = `/apis/notification_setting/update_request/${id}`;
      return axios.put(url, body);
    },
    
  };
};

export default settingAPI();
