import * as React from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { SxProps } from '@mui/material';
import Stack from '@mui/material/Stack';
import Autocomplete, {
  AutocompleteInputChangeReason,
  AutocompleteProps,
} from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import useLocales from '../hooks/useLocales';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  width: '100%',
  boxSizing: 'border-box',
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  alignItems: 'center',
  padding: '12px 16px',
}));

// ----------------------------------------------------------------------
export interface SearchOption {
  id: number | string;
  type: string;
  destination: string;
  parent?: string;
  variant?: 'filter' | 'search';
}

interface Props<T extends SearchOption>
  extends Partial<AutocompleteProps<any, boolean, boolean, boolean>> {
  data?: T[];
  defaultInputValue?: string;
  filters: SearchOption[];
  filter: SearchOption | null;
  query?: string;
  onOptionSelect?: (option: T) => void;
  onFilterChange?: (filter: SearchOption | null) => void;
  onInputValueChange?: (
    event?: React.SyntheticEvent<Element, Event>,
    value?: string,
    reason?: AutocompleteInputChangeReason
  ) => void;
  containerSx?: SxProps;
  textFieldProps?: TextFieldProps;
  error?: boolean;

  [x: string]: any;
}

function instanceOfSearchOption(object: any): object is SearchOption {
  return object ? 'type' in object : false;
}

const StyledChip = styled(Chip)(({ theme }) => ({
  textTransform: 'capitalize',
  backgroundColor: 'transparent',
  color: theme.palette.text.secondary,
  '& .MuiChip-label': {
    padding: theme.spacing(0, 0, 0, 1),
  },
}));

export default function SearchLocation<T extends SearchOption>({
  data,
  defaultInputValue,
  filters,
  filter,
  query,
  containerSx,
  textFieldProps,
  onOptionSelect,
  onFilterChange = () => {},
  onInputValueChange,
  error = false,
  ...rest
}: Props<T>) {
  const [value, setValue] = React.useState<string | null>();
  const [inputValue, setInputValue] = React.useState<string>(defaultInputValue || '');
  const { translate } = useLocales(['bookingWizard', 'common']);
  const filteredData = data || [];

  return (
    <RootStyle sx={containerSx}>
      <Autocomplete
        freeSolo
        fullWidth
        sx={{
          '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            pr: 5,
          },
        }}
        value={value ?? ''}
        onChange={(
          event: React.SyntheticEvent<Element, Event>,
          newValue: SearchOption | string | null,
          reason: string
        ) => {
          if (inputValue === '') {
            setValue(typeof newValue === 'string' ? newValue : newValue?.destination);
            if (instanceOfSearchOption(newValue)) {
              onFilterChange(newValue);
            } else {
              onFilterChange(null);
            }
          } else {
            setInputValue(typeof newValue === 'string' ? newValue : newValue?.destination ?? '');
            if (reason === 'selectOption') {
              onOptionSelect && onOptionSelect(newValue as T);
            } else if (reason === 'clear') {
              onInputValueChange && onInputValueChange(event, '');
              onOptionSelect && onOptionSelect(newValue as T);
            }
          }
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          if (event?.type === 'change') {
            setInputValue(newInputValue);
            onInputValueChange && onInputValueChange(event, newInputValue);
          }
        }}
        options={inputValue ? filteredData : filters}
        getOptionLabel={(option) =>
          inputValue ? option.destination ?? '' : translate(option.type)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={!filter ? translate('bookingWizard.search.locationPlaceholder') : ''}
            size="small"
            {...textFieldProps}
            onKeyDown={(event) => {
              if ((event as React.KeyboardEvent).key === 'Backspace') {
                if (inputValue === '') {
                  setValue(null);
                  onFilterChange(null);
                }
              }
            }}
            InputProps={{
              ...params.InputProps,
              ...textFieldProps?.InputProps,
              startAdornment: (
                <Stack direction={'row'} alignItems={'center'} height={28}>
                  {textFieldProps?.InputProps?.startAdornment}
                  {filter && (
                    <StyledChip
                      label={translate(`bookingWizard.searchLocationType.${filter.type}`) + ':'}
                    />
                  )}
                </Stack>
              ),
              style: { paddingBottom: 10, paddingTop: 10 },
            }}
            error={Boolean(error)}
            helperText={Boolean(error) && translate('bookingWizard.common.search_location_error')}
          />
        )}
        {...rest}
      />
    </RootStyle>
  );
}
