import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CostElement, Upsell, UpsellParams, UpsellsState } from 'src/@types/upsell';
import { dispatch } from '../store';
import upsellAPI from 'src/api/upsells';
import rateAPI, { CreateUpsellBody } from 'src/api/rate';

// ----------------------------------------------------------------------

const initialState: UpsellsState = {
  loading: false,
  hasError: false,
  rowCount: 0,
  upsells: [],
  drawers: {
    editUpsell: {
      isOpen: false,
      currentUpsell: null,
    },
  },
};

const slice = createSlice({
  name: 'upsells',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setHasError(state, action: PayloadAction<boolean>) {
      state.hasError = action.payload;
    },
    setUpsells(state, action: PayloadAction<Upsell[]>) {
      state.upsells = action.payload;
    },
    setRowCount(state, action: PayloadAction<number>) {
      state.rowCount = action.payload;
    },
    openUpsellEditDrawer(
      state,
      action: PayloadAction<{
        open: boolean;
        currentUpsell: Upsell | null;
      }>
    ) {
      const { open, currentUpsell } = action.payload;
      state.drawers.editUpsell.isOpen = open;
      if (typeof currentUpsell !== 'undefined') {
        state.drawers.editUpsell.currentUpsell = currentUpsell;
      }
    },
    updateUpsell(state, action: PayloadAction<{ id: number; newField: Partial<Upsell> }>) {
      const { id, newField } = action.payload;
      const { upsells } = state;
      const index = upsells.findIndex((upsell) => upsell.id === id);
      state.upsells[index] = {
        ...state.upsells[index],
        ...newField
      }
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setLoading,
  setRowCount,
  setUpsells,
  openUpsellEditDrawer,
  updateUpsell,
} = slice.actions;

export function getUpsells(params: UpsellParams, isArchive: boolean, signal?: AbortSignal) {
  return async () => {
    dispatch(slice.actions.setLoading(true));
    try {
      const { data } = await upsellAPI.fetchAddonUpsells({ upsell: true, archive: isArchive, ...params }, signal);
      dispatch(slice.actions.setUpsells(data.data));
      dispatch(slice.actions.setRowCount(data.meta.total_items));
      dispatch(slice.actions.setLoading(false));
    } catch (error) {
      dispatch(slice.actions.setHasError(false));
    }
  };
}

export function createOrUpdateUpsell(
  id: number | undefined,
  upsell: CreateUpsellBody,
  setLoading?: (succeed: boolean) => void,
  callback?: (succeed: boolean, response: any, newCostElement?: CostElement) => void,
) {
  return async () => {
    setLoading?.(true);
    try {
      let newCostElement: CostElement | undefined = undefined;
      if (typeof upsell.cost_element === 'string') {
        const response = await rateAPI.createCostElements({
          name: upsell.cost_element,
          category: 'Service',
        });
        upsell.cost_element = response.data.id;
        newCostElement = response.data;
      }
      const response = id ? await rateAPI.updateUpsell(id, upsell) : await rateAPI.createUpsell(upsell);
      callback?.(true, response, newCostElement);
    } catch (error) {
      callback?.(false, error)
    };
    setLoading?.(false);
  }
}
