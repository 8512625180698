import { styled } from '@mui/material/styles';
import { Card, Link, Typography, Button, Stack } from '@mui/material';
import Logo from '../../components/Logo';
import useLocales from 'src/hooks/useLocales';
import { FC, ReactNode } from 'react';
import { PATH_AUTH, PATH_ZEEVOU } from 'src/routes/paths';


const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: theme.spacing(2, 2, 2, 2),
  backgroundImage: `url(${"/images/login-bg.svg"})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderRadius: '20px',
}));

type AuthPage = 'login' | 'register' | 'reset_password';

type Props = {
  page: AuthPage;
};

type InfoConfig = {
  title: string | ReactNode;
  subtitle: string | ReactNode;
  actionTitle: string | ReactNode;
  actionHref: string;
};

const InfoSection: FC<Props> = ({ page }) => {
  const { translate } = useLocales(['common', 'auth']);
  
  const { title, subtitle, actionTitle, actionHref } = ({
    login: {
      title: translate('new_to_zeevou'),
      subtitle: translate('sign_up_text'),
      actionTitle: translate('sign_up').toLocaleUpperCase(),
      actionHref: PATH_ZEEVOU.signup,
    },
    reset_password: {
      title: translate('our_partner_host'),
      subtitle: translate('welcome_back_login_text'),
      actionTitle: translate('login').toLocaleUpperCase(),
      actionHref: PATH_AUTH.login,
    },
  } as {[kei in AuthPage]: InfoConfig})[page];

  return (
    <SectionStyle>
      <Logo size={70} />
      <Stack gap={1} p={5}>
        <Typography align="center" variant="h3" color="primary.contrastText">
          {title}
        </Typography>
        <Typography align="center" variant="h6" whiteSpace="pre-wrap" color="primary.contrastText">
          {subtitle}
        </Typography>
      </Stack>
      <Button variant="contained" size="large" href={actionHref}>
        {actionTitle}
      </Button>
      {page === 'login' ? (
        <Typography align="center" variant="body2" sx={{ mt: { md: 20 }, color: 'white' }}>
          {translate('want_to_know_more')}{' '}
          <Link variant="subtitle2" color="warning.main" href={PATH_ZEEVOU.freeDemo} target={'_blank'}>
            {translate('free_demo')}
          </Link>
          .
        </Typography>
      ) : null}
    </SectionStyle>
  );
};

export default InfoSection;