import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import {
  GrantType,
  isValidToken,
  setRefreshToken,
  setSession,
  setOrganization,
  setUserCredentials,
} from '../utils/oauth2';
// @types
import { ActionMap, AuthState, AuthUser, OAuth2ContextType } from '../@types/auth';
// config
import { OAUTH2_API } from '../config';
import useLocales from 'src/hooks/useLocales';
import api from 'src/api/api';
import { FullStory as FS, init as fsInit } from '@fullstory/browser';

// ----------------------------------------------------------------------


enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
  UpdateProfile = 'UPDATE_PROFILE',
  ResetPassword = 'RESET_PASSWORD',
}

type OAuth2Payload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
  [Types.UpdateProfile]: {
    user: AuthUser;
  };
  [Types.ResetPassword]: {
    user: AuthUser;
  };
};

export type OAuth2Actions = ActionMap<OAuth2Payload>[keyof ActionMap<OAuth2Payload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const OAuth2Reducer = (state: AuthState, action: OAuth2Actions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'UPDATE_PROFILE':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'RESET_PASSWORD':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<OAuth2ContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(OAuth2Reducer, initialState);
  const {
    currentLang,
    onChangeLang,
    onChangeTimezone,
    onChangeShortDateFormat,
    onChangeLongDateFormat,
    onChangeTimeFormat,
  } = useLocales();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const refreshToken = window.localStorage.getItem('refreshToken');
        const currentOrganization = window.localStorage.getItem('currentOrganization');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get('/apis/webapp/user/info');
          const { user } = response.data;

          setOrganization(currentOrganization ?? user.current_organization.id);

          if (currentLang !== user.localization.languageCode) {
            onChangeLang(user.localization.languageCode);
          }

          onChangeTimezone(user.localization.timezone);
          onChangeShortDateFormat(user.localization.shortDateFormat);
          onChangeLongDateFormat(user.localization.longDateFormat);
          onChangeTimeFormat(user.localization.timeFormat);

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else if (refreshToken) {
          const response = await axios.post('/oauth2-token', {
            grant_type: GrantType.RefreshToken,
            ...OAUTH2_API,
            refresh_token: refreshToken,
          });
          const { access_token, refresh_token } = response.data;
          setSession(access_token);
          setRefreshToken(refresh_token);

          const userResponse = await axios.get('/apis/webapp/user/info');
          const { user } = userResponse.data;

          setOrganization(currentOrganization ?? user.current_organization.id);

          if (currentLang !== user.localization.languageCode) {
            onChangeLang(user.localization.languageCode);
          }
          fsInit({ orgId: "o-1WAE7G-na1" });
          FS('setIdentity', {
            uid: user.userId,
            properties: {
              displayName: user.userName,
              email: user.email,
              custom_field: 1
            }
          });
          FS('setProperties', {
            type: 'user',
            properties: {
              displayName: user.userName,
              email: user.email,
              isFreePlan: user.isFreePlan,
              organizationId: user.organizationId,
              organizationName: user.organizationName
            }
          });
          onChangeTimezone(user.localization.timezone);
          onChangeShortDateFormat(user.localization.shortDateFormat);
          onChangeLongDateFormat(user.localization.longDateFormat);
          onChangeTimeFormat(user.localization.timeFormat);

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (username: string, password: string) => {
    const response = await axios.post('/oauth2-token', {
      grant_type: GrantType.Password,
      ...OAUTH2_API,
      username: username.trim(),
      password,
    });
    const { access_token, refresh_token } = response.data;
    setSession(access_token);
    setRefreshToken(refresh_token);
    setUserCredentials(username.trim(), password);

    const userResponse = await axios.get('/apis/webapp/user/info');
    const { user } = userResponse.data;

    const currentOrganization = window.localStorage.getItem('currentOrganization');
    let org = user?.organizations.find(org => org.id === currentOrganization)
    if (org === undefined) {
      setOrganization(user.current_organization.id);
    }

    if (currentLang !== user.localization.languageCode) {
      onChangeLang(user.localization.languageCode);
    }

    onChangeTimezone(user.localization.timezone);
    onChangeShortDateFormat(user.localization.shortDateFormat);
    onChangeLongDateFormat(user.localization.longDateFormat);
    onChangeTimeFormat(user.localization.timeFormat);

    dispatch({
      type: Types.Login,
      payload: {
        user,
      },
    });
  };
  const register = async (email: string, password: string) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    setRefreshToken(null);
    setOrganization(null);
    setUserCredentials(null, null);
    dispatch({ type: Types.Logout });
  };

  const resetPassword = async (user) => {
    dispatch({ type: Types.ResetPassword, payload: { user } });
  };

  const sendResetPasswordInstructions = async (email: string) => api.sendForgotPasswordLink(email);
  
  const resetPasswordWithToken = async (token: string, password: string) => api.forgotPasswordReset(token, password);

  const getUserByToken = async (token: string) => api.getUserByToken(token);

  const updateProfile = async (user) => {
    dispatch({ type: Types.UpdateProfile, payload: { user } });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'oauth',
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
        getUserByToken,
        sendResetPasswordInstructions,
        resetPasswordWithToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
