import { ReactNode } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
  GridAlignment,
  GridRenderEditCellParams,
  GridTreeNodeWithRender,
} from '@mui/x-data-grid-pro';
import { TypographyProps } from '@mui/material/Typography';
import { Stack, Tooltip, TooltipProps, Typography } from '@mui/material';
// hooks
import useLocales from 'src/hooks/useLocales';

export type CustomRenderCellProps = {
  title: ReactNode;
  subTitle?: ReactNode;
  tooltipTitle?: TooltipProps['title'];
  titleProps?: TypographyProps;
  subtitleProps?: TypographyProps;
  disabledTooltip?: boolean;
  overrideNotSetText?: string;
  cellAlignment?: GridAlignment;
} & Partial<GridRenderEditCellParams<any, any, any, GridTreeNodeWithRender>>;

const StyledStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'align',
})<{ align: 'left' | 'right' | 'center' | undefined }>(({ align }) => ({
  fontWeight: 600,
  alignItems: 'flex-start',
  ...(align === 'right' && {
    alignItems: 'flex-end',
  }),
  ...(align === 'center' && {
    alignItems: align,
  }),
}));

const CustomRenderCell = ({
  title,
  subTitle = null,
  tooltipTitle,
  titleProps,
  subtitleProps,
  disabledTooltip = false,
  overrideNotSetText,
  cellAlignment,
  // id is required for editable cells
  id,
}: CustomRenderCellProps) => {
  const { translate } = useLocales('common');

  const content = (
    <StyledStack
      aria-describedby={id?.toString() || ''}
      width="100%"
      align={cellAlignment}
      className="custom-render-cell-content"
    >
      {title ? (
        typeof title === 'string' ? (
          <Typography variant="subtitle2" noWrap maxWidth="100%" {...titleProps}>
            {title}
          </Typography>
        ) : (
          title
        )
      ) : (
        <Typography variant="body" color={'text.disabled'} noWrap>
          {overrideNotSetText || translate('not_set')}
        </Typography>
      )}
      {typeof subTitle === 'string' ? (
        <Typography variant="caption" color="text.secondary" noWrap {...subtitleProps}>
          {subTitle}
        </Typography>
      ) : (
        subTitle
      )}
    </StyledStack>
  );
  return ((title && typeof title === 'string') || subTitle || tooltipTitle) && !disabledTooltip ? (
    <Tooltip
      title={
        tooltipTitle || (
          <Stack>
            <Typography variant="caption" fontWeight="fontWeightBold">
              {title}
            </Typography>
            <Typography variant="caption">{subTitle}</Typography>
          </Stack>
        )
      }
      arrow
      placement="top-start"
    >
      {content}
    </Tooltip>
  ) : (
    content
  );
};

export default CustomRenderCell;
