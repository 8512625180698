import { ReactNode } from 'react';
// hooks
import useLocales from 'src/hooks/useLocales';
// @mui
import { AdapterDateFns as AdapterDateFnsPro } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LocalizationProvider as LocalizationProviderPro } from '@mui/x-date-pickers-pro';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// date-fns
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';

// ----------------------------------------------------------------------

type LocalizationProviderProps = {
  children: ReactNode;
};

const localeMap = {
  en: enLocale,
  de: deLocale,
};

export default function UserLocalizationProvider({ children }: LocalizationProviderProps) {
  const { currentLang } = useLocales();

  return (
    <LocalizationProviderPro dateAdapter={AdapterDateFnsPro} adapterLocale={localeMap[currentLang.value]}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={localeMap[currentLang.value]}
      >
        {children}
      </LocalizationProvider>
    </LocalizationProviderPro>
  );
}
