import UnifiedInboxApi from "src/api/unified-inbox";
import { dispatch } from "src/redux/store";
import { initialState, slice } from './index'
import { PayloadAction } from "@reduxjs/toolkit";

export const userReducers = {
  initializeState: () => initialState,
  setUser(state, action: PayloadAction<any>) {
    state.user = { ...state.user, ...action.payload };
  },
  setUserContact(state, action: PayloadAction<any>) {
    state.user = { ...state.user, contact: action.payload };
  },
  setEmails(state, action: PayloadAction<any>) {
    state.user = { ...state.user, emails: action.payload };
  },
  setBrands(state, action: PayloadAction<any>) {
    state.user = { ...state.user, brands: action.payload };
  },
};

// ----------------------------------------------------------------------

export function getUser() {
  return async () => {
    UnifiedInboxApi.getCurrentUserInformation()
      .then((response) => {
        dispatch(slice.actions.setUser(response.data.user));
        dispatch(slice.actions.setUserContact(response.data.contact));
        dispatch(slice.actions.setEmails(response.data.emails));
        const brandsArray = response.data.brands;
        const brandsObject = {};
        for (let i = 0; i < brandsArray.length; i += 1) {
          brandsObject[brandsArray[i].id] = brandsArray[i];
        }
        dispatch(slice.actions.setBrands(brandsObject));
      })
      .catch();
  };
}