import { AxiosRequestConfig } from 'axios';
import {
  SelectedCondition,
  MessageType,
  SettingKey,
  FailedRenderMessage,
  UpdateAutomatedMessageConfigBodyType,
  AutomatedMessageConfig,
  AutomatedMessage,
} from 'src/@types/automated-message';
import { ApiFormat, File, VariableApiType } from 'src/@types/common';
import axios from '../utils/axios';

function automatedMessageAPI() {
  return {
    fetchOrganizationBrands: () => axios.get('/apis/organization/all_brands'),
    saveSetting: (body) => axios.post('/apis/automated_message/v1/save_settings', body),
    fetchCountries: () => axios.post('/apis/automated_message/v1/get_all_country'),
    fetchSetting: (key: SettingKey, template_id?: number) =>
      axios.get('/apis/automated_message/v1/retrieve_settings', {
        params: { key, template_id },
      }),
    fetchConditionsDropdown: (title: string) =>
      axios.get(`/apis/automated_message/v1/get_all_${title}`),
    removeFile: (id: number) => axios.delete(`/apis/automated_message/v1/delete/file/${id}`),
    removeTemplate: (id: number) =>
      axios.delete(`/apis/automated_message/v1/delete/template/${id}`),
    fetchConditionsPopup: () => axios.get('/apis/automated_message/v1/condition_popup'),
    fetchVariables: (type: VariableApiType, id: number | null) => {
      const url = '/apis/automated_message/v1/get_variables';
      return axios.get(url, {
        params: { id, type },
      });
    },
    fetchLanguages: () => axios.get('/apis/automated_message/v1/get_language'),
    fetchAttachments: () => axios.get('/apis/automated_message/v1/get_attachment_variable'),
    checkNameExistance: (name: string, id?: number) => {
      const url = `/apis/automated_message/v1/check_exist_automated_message${id ? `/${id}` : ''}`;
      return axios.get(url, {
        params: { name: encodeURIComponent(name) },
      });
    },
    fetchRecipients: ({ key, page, type }: { key: string; page: number; type: MessageType }) => {
      const url = '/apis/automated_message/v1/get_recipients';
      return axios.get(url, {
        params: { key: encodeURIComponent(key), type, page, offset: 15 },
      });
    },
    fetchSampleBookings: ({ key, page }: { key: string; page: number }) => {
      const url = '/apis/automated_message/v1/get_sample_booking';
      return axios.get(url, {
        params: { key: encodeURIComponent(key), page, offset: 15 },
      });
    },
    uploadFile: (file: FormData, config: AxiosRequestConfig<FormData>) => {
      const url = '/apis/files';
      return axios.post<File>(url, file, config);
    },
    findBookings: (id: number | null, conditions: SelectedCondition[]) => {
      const url = '/apis/automated_message/v1/booking_finder';
      return axios.post(url, { id, conditions });
    },
    sendTestAsEmail: (body: {
      body: string;
      subject: string;
      from: string;
      fromName: string;
      to: any[];
      file_id: any[] | null;
      variables_name: any[] | null;
      booking_id?: number | null;
    }) => {
      const url = `/apis/automated_message/v1/send_test_email`;
      return axios.post(url, body);
    },
    sendTestAsSms: (body: { body: string; to: any[] }) => {
      const url = `/apis/automated_message/v1/send_test_sms`;
      return axios.post(url, body);
    },
    renderTemplate: (booking_id: number, template: string, subject: string) => {
      const url = '/apis/automated_message/v1/renderer';
      return axios.post(url, {
        booking_id,
        template,
        subject,
      });
    },
    saveTemplates: (id: number, templates) => {
      const url = '/apis/automated_message/v1/save_new_templates';
      return axios.post(url, { id, templates });
    },
    saveConditions: (
      id: number,
      conditions: SelectedCondition[],
      timeFrame: string[],
      conditionsText: string
    ) => {
      const url = '/apis/automated_message/v1/save_new_trigger_rules';
      return axios.post(url, { id, conditions, timeFrame, conditions_text: conditionsText });
    },
    postAutomatedMessage: (body) => {
      const url = `/apis/automated_message/v1/save_all`;
      return axios.post(url, body);
    },
    fetchAutomatedMessages: (
      body?: {
        page?: number;
        category?: string;
        search_key?: { all?: string; name?: string; subject?: string };
        filters?: {
          active?: boolean;
          message_type?: boolean;
          favorite?: boolean;
          attachment?: boolean;
          send_via?: MessageType[];
          send_to?: string[];
          language?: string[];
        };
        offset?: number;
        saved_message?: boolean;
      },
      signal?: AbortSignal
    ) => {
      const url = '/apis/automated_message/v1/retrieve_all';
      return axios.post<ApiFormat<AutomatedMessage[]>>(
        url,
        { ...body, saved_message: body?.saved_message || false, offset: body?.offset || 15 },
        { signal }
      );
    },
    fetchAutomatedMessage: (id: number) => axios.get(`/apis/automated_message/v1/retrieve/${id}`),
    setFavorite: (id: number, isFavorite: boolean) => {
      const url = '/apis/automated_message/v1/change_favorite_state';
      return axios.post(url, { id, is_favorite: isFavorite });
    },
    cloneAutomatedMessage: (id: number) => {
      const url = '/apis/automated_message/v1/clone';
      return axios.post(url, { id });
    },
    archiveAutomatedMessage: (id: number, isArchived: boolean) => {
      const url = '/apis/automated_message/v1/change_archive_state';
      return axios.post(url, { id, is_archive: isArchived, enable_archive: true });
    },
    deleteAutomatedMessage: (id: number) => {
      const url = '/apis/automated_message/delete';
      return axios.delete(url, { data: { id, enable_delete: true } });
    },
    changeAutomatedMessageActiveState: (
      id: number,
      is_active: boolean,
      found_booking_ids: number[],
      is_from_now: boolean
    ) => {
      const url = '/apis/automated_message/v1/change_active_state';
      return axios.post(url, { id, is_active, found_booking_ids, is_from_now });
    },
    renderTemplates: (templates: any) => {
      const url = '/apis/automated_message/render_templates';
      return axios.post<FailedRenderMessage[]>(url, { templates });
    },
    fetchAutomatedMessageNotifications: (bookingId: number) =>
      axios.get(`apis/automated_message_notifications/get_all_automated_messages/${bookingId}`),
    sendAutomatedMessageNotification(notificationId: number) {
      return axios.put(`/apis/automated_message_notifications/send_now/${notificationId}`);
    },
    resendAutomatedMessageNotification(notificationId: number) {
      return axios.post(`/apis/automated_message_notifications/resend/${notificationId}`);
    },
    retrySendAutomatedMessageNotification(notificationId: number) {
      return axios.put(`/apis/automated_message_notifications/retry_send/${notificationId}`);
    },
    getBookingAllAutomatedMessages: (bookingId: number) =>
      axios.get(`/apis/automated_message_notifications/get_all_automated_messages/${bookingId}`),
    enableDisablePotentialAutomatedMessage: (
      bookingId: number,
      automatedMsgId: number,
      action: string
    ) =>
      axios.put(
        `/apis/automated_message/v1/enable_disable_booking/${bookingId}/${automatedMsgId}/${action}`
      ),
    fetchAutomatedMessageConfigs: (brandId?: number) =>
      axios.get<AutomatedMessageConfig[]>(
        `/apis/automated_message_configs${brandId ? `?brand=${brandId}` : ''}`
      ),
    updateAutomatedMessageConfig: (configId: number, body: UpdateAutomatedMessageConfigBodyType) =>
      axios.put<AutomatedMessageConfig>(`/apis/automated_message_configs/${configId}`, body),
  };
}

export default automatedMessageAPI();
