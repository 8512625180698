// @mui
import CardHeader, { cardHeaderClasses } from '@mui/material/CardHeader';
import { styled } from "@mui/material/styles";

export default styled(CardHeader, {
  shouldForwardProp: (prop) => prop !== 'contentDirection'
})<{ contentDirection?: 'row' | 'column' }>(({ theme, contentDirection = 'row' }) => ({
  [`&.${cardHeaderClasses.root}`]: {
    height: theme.spacing(7),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [`& .${cardHeaderClasses.content}`]: {
      display: 'flex',
      flexDirection: contentDirection,
      [`& .${cardHeaderClasses.title}`]: {
        ...theme.typography.subtitle1,
      },
      [`& .${cardHeaderClasses.subheader}`]: {
        ...theme.typography.caption,
        marginLeft: contentDirection === 'row' ? theme.spacing(1) : 0,
      }
    },
    [`& .${cardHeaderClasses.action}`]: {
      alignSelf: 'auto',
    },
  },
}));
