import { GridSortModel } from '@mui/x-data-grid-pro';
import { TFunction } from 'react-i18next';
import { format } from 'date-fns';
import { GridCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import {
  BookingListDashboardValue, BookingListDashboardView,
  BookingListView,
  BookingListViewValue,
  ColumnField,
  Filters,
} from 'src/@types/booking-list';
import { BookingFinalizationStatusEnum, CheckInTypeEnum } from 'src/utils/booking';
import { DateFilterSelectValue } from 'src/@types/filter';
import { NumberCondition } from 'src/sections/@dashboard/guest/data-grid/NumberCustomAccordionFilter';

const TODAY_DATE = format(new Date(), 'yyyy-MM-dd');

export const getDefaultHideColumnsByViewMode = (
  bookingListViewValue: BookingListViewValue
): ColumnField[] => {
  switch (bookingListViewValue) {
    case 'arrivals':
      return [
        'attachments',
        'payment_type',
        'rate_plan_type',
        'channel_total',
        'sd_charged',
        'total_due',
        'total_sale',
        'payment_collection_type',
        'deposit_status',
        'creation_source',
        'updated_date',
        'finalization_status',
        'person_booking',
        'check_out_time',
        'penalties',
        'booking_code',
        'booking_reference',
        'terms_and_condition_status',
        'channel_commission',
        'channel_promotion',
        'late_check_out_fee'
      ];
    case 'departures':
      return [
        'arrival_date',
        'arrival_time',
        'check_in_ready',
        'total_sale',
        'payment_collection_type',
        'deposit_status',
        'creation_source',
        'created_at',
        'id_verified',
        'attachments',
        'payment_type',
        'rate_plan_type',
        'channel_total',
        'booking_payment_status',
        'group_booking_payment_status',
        'updated_date',
        'booking_purpose',
        'finalization_status',
        'person_booking',
        'penalties',
        'booking_code',
        'booking_reference',
        'terms_and_condition_status',
        'check_in_method',
        'check_in_agent',
        'booking_warnings',
        'access_code',
        'channel_commission',
        'signable',
        'booking_confirmed',
        'channel_promotion'
      ];
    case 'stay-overs':
      return [
        'check_in_ready',
        'booking_payment_status',
        'group_booking_payment_status',
        'payment_collection_type',
        'check_in_method',
        'access_code',
        'signable',
        'booking_confirmed',
        'channel_promotion',
        'late_check_out_fee',
      ];
    case 'all_bookings':
      return [
        'access_code',
        'signable',
        'channel_promotion',
        'late_check_out_fee'
      ];
    case 'awaiting_finalisation':
      return [
        'contact_info',
        'id_verified',
        'attachments',
        'payment_type',
        'listings_marketing_name',
        'city',
        'channel_total',
        'sd_charged',
        'booking_payment_status',
        'group_booking_payment_status',
        'deposit_status',
        'creation_source',
        'updated_date',
        'booking_purpose',
        'check_out_time',
        'guest_email_address',
        'guest_number',
        'upsells',
        'fees',
        'booking_code',
        'booking_reference',
        'terms_and_condition_status',
        'check_in_method',
        'check_in_agent',
        'booking_warnings',
        'access_code',
        'signable',
        'applied_promotions',
        'booking_confirmed',
        'late_check_out_fee'
      ];
    default:
      return [];
  }
};

export const getDefaultColumnVisibilityByViewMode = (
  bookingListViewValue: BookingListViewValue
): { [x in ColumnField]?: boolean } => {
  const defaultHides = { id: false };

  switch (bookingListViewValue) {
    case 'all_bookings':
      return {
        ...defaultHides,
        contact_info: false,
        guest_email_address: false,
        guest_number: false,
        arrival_time: false,
        departure_time: false,
        person_booking: false,
        attachments: false,
        payment_type: false,
        number_of_nights: false,
        number_of_guests: false,
        number_of_adult_guests: false,
        number_of_child_guests: false,
        check_out_time: false,
        unit_type: false,
        unit: false,
        listings_marketing_name: false,
        city: false,
        booking_no: false,
        rate_plan: false,
        rate_plan_type: false,
        finalization_status: false,
        booking_purpose: false,
        sd_charged: false,
        total_due: false,
        channel_total: false,
        total_sale: false,
        payment_collection_type: false,
        booking_payment_status: false,
        group_booking_payment_status: false,
        deposit_status: false,
        creation_source: false,
        created_at: false,
        updated_date: false,
        applied_promotions: false,
        booking_tags: false,
        guest_level: false,
        company_level: false,
        penalties: false,
        fees: false,
        upsells: false,
        channel_commission: false,
        booking_confirmed: false,
        booking_code: false,
        booking_reference: false,
        terms_and_condition_status: false,
        id_verified: false,
        check_in_agent: false,
        check_in_method: false,
        booking_warnings: false,
      };
    case 'arrivals':
      return {
        ...defaultHides,
        guest_email_address: false,
        guest_number: false,
        arrival_time: false,
        departure_time: false,
        signable: false,
        number_of_nights: false,
        number_of_guests: false,
        number_of_adult_guests: false,
        number_of_child_guests: false,
        unit_type: false,
        unit: false,
        listings_marketing_name: false,
        city: false,
        channel: false,
        booking_no: false,
        rate_plan: false,
        booking_status: false,
        booking_purpose: false,
        booking_payment_status: false,
        group_booking_payment_status: false,
        deposit_status: false,
        created_at: false,
        applied_promotions: false,
        booking_tags: false,
        guest_level: false,
        company_level: false,
        fees: false,
        upsells: false,
        booking_confirmed: false,
        id_verified: false,
        check_in_agent: false,
        access_code: false,
        check_in_method: false,
        booking_warnings: false,
      };
    case 'stay-overs':
      return {
        ...defaultHides,
        contact_info: false,
        guest_email_address: false,
        guest_number: false,
        arrival_date: false,
        arrival_time: false,
        departure_time: false,
        person_booking: false,
        attachments: false,
        payment_type: false,
        number_of_nights: false,
        number_of_guests: false,
        number_of_adult_guests: false,
        number_of_child_guests: false,
        check_out_time: false,
        unit_type: false,
        unit: false,
        listings_marketing_name: false,
        city: false,
        rate_plan: false,
        rate_plan_type: false,
        booking_status: false,
        finalization_status: false,
        booking_purpose: false,
        sd_charged: false,
        channel_total: false,
        total_sale: false,
        creation_source: false,
        created_at: false,
        updated_date: false,
        applied_promotions: false,
        booking_tags: false,
        guest_level: false,
        company_level: false,
        penalties: false,
        fees: false,
        upsells: false,
        channel_commission: false,
        booking_code: false,
        booking_reference: false,
        terms_and_condition_status: false,
        id_verified: false,
      };
    case 'departures':
      return {
        ...defaultHides,
        contact_info: false,
        guest_email_address: false,
        guest_number: false,
        departure_time: false,
        number_of_guests: false,
        number_of_adult_guests: false,
        number_of_child_guests: false,
        check_out_time: false,
        unit_type: false,
        unit: false,
        listings_marketing_name: false,
        city: false,
        booking_no: false,
        rate_plan: false,
        booking_status: false,
        deposit_status: false,
        applied_promotions: false,
        booking_tags: false,
        guest_level: false,
        company_level: false,
        fees: false,
        upsells: false,
        late_check_out_fee: false,
      };
    case 'awaiting_finalisation':
      return {
        ...defaultHides,
        arrival_time: false,
        departure_time: false,
        person_booking: false,
        number_of_nights: false,
        number_of_guests: false,
        number_of_adult_guests: false,
        number_of_child_guests: false,
        check_in_ready: false,
        unit_type: false,
        unit: false,
        rate_plan: false,
        rate_plan_type: false,
        finalization_status: false,
        total_due: false,
        total_sale: false,
        payment_collection_type: false,
        deposit_status: false,
        created_at: false,
        booking_tags: false,
        guest_level: false,
        company_level: false,
        penalties: false,
        channel_commission: false,
        channel_promotion: false,
      };
    default:
      return defaultHides;
  }
};

export const getDefaultFiltersByViewMode = (
  viewValue: string
): Filters => {
  switch (viewValue) {
    case 'arrivals':
      return {
        arrival_date: {
          from: TODAY_DATE,
        },
        booking_status: ['Booked'],
      };
    case 'departures':
      return {
        departure_date: {
          from: TODAY_DATE,
        },
        booking_status: ['Booked', 'Checked in'],
      };
    case 'stay-overs':
      return {
        arrival_date: {
          to: TODAY_DATE,
        },
        booking_status: ['Checked in'],
      };
    case 'awaiting_finalisation':
      return {
        booking_status: ['Checked out'],
        finalization_status: [BookingFinalizationStatusEnum.AWAITING_FINALIZATION_STATUS],
      };
    case 'scheduled_for_cancellation':
      return {
        booking_status: ['Scheduled for Cancellation'],
      };
    case 'unallocated_bookings':
      return {
        booking_status: ['Booked', 'Checked in', 'Checked out'],
        unallocated: true,
      };
    default:
      return {};
  }
};

export const getDefaultHideColumnsByDashboardViewMode = (
  bookingListDashboardValue: BookingListDashboardValue
): ColumnField[] => {
  switch (bookingListDashboardValue) {
    case 'arrivals':
      return [
        'channel',
        'number_of_nights',
        'booking_no',
        'sd_charged',
        'total_due',
        'created_at',
        'booking_status',
      ];
    case 'departures':
      return [
        'contact_info',
        'arrival_date',
        'check_in_ready',
        'booking_no',
        'booking_status',
        'created_at',
      ];
    case 'all_bookings':
      return [
        'contact_info',
        'number_of_nights',
        'booking_no',
        'sd_charged',
        'total_due',
        'created_at',
      ];
    case 'scheduled_for_cancellation':
      return [
        'contact_info',
        'number_of_nights',
        'check_in_ready',
        'channel',
        'booking_status',
        'sd_charged',
        'total_due',
       ];
    case 'unallocated_bookings':
      return [
        'contact_info',
        'number_of_nights',
        'check_in_ready',
        'channel',
        'booking_status',
        'sd_charged',
        'total_due',
      ];
    default:
      return [];
  }
};

export const getDefaultSortModelByViewMode = (
  viewValue: string
): GridSortModel => {
  switch (viewValue) {
    case 'arrivals':
    case 'stay-overs':
      return [{ field: 'arrival_date', sort: 'asc' }];
    case 'departures':
      return [{ field: 'departure_date', sort: 'asc' }];
    case 'awaiting_finalisation':
      return [{ field: 'departure_date', sort: 'desc' }];
    case 'scheduled_for_cancellation':
      return [{ field: 'created_at', sort: 'asc' }];
    default:
      return [];
  }
};

export const defaultBookingsListViews: BookingListView[] = [
  {
    value: 'all_bookings',
    defaultFilters: getDefaultFiltersByViewMode('all_bookings'),
    defaultHideColumns: getDefaultHideColumnsByViewMode('all_bookings'),
    defaultSortModel: getDefaultSortModelByViewMode('all_bookings'),
  },
  {
    value: 'arrivals',
    defaultFilters: getDefaultFiltersByViewMode('arrivals'),
    defaultHideColumns: getDefaultHideColumnsByViewMode('arrivals'),
    defaultSortModel: getDefaultSortModelByViewMode('arrivals'),
  },
  {
    value: 'stay-overs',
    defaultFilters: getDefaultFiltersByViewMode('stay-overs'),
    defaultHideColumns: getDefaultHideColumnsByViewMode('stay-overs'),
    defaultSortModel: getDefaultSortModelByViewMode('stay-overs'),
  },
  {
    value: 'departures',
    defaultFilters: getDefaultFiltersByViewMode('departures'),
    defaultHideColumns: getDefaultHideColumnsByViewMode('departures'),
    defaultSortModel: getDefaultSortModelByViewMode('departures'),
  },
  {
    value: 'awaiting_finalisation',
    defaultFilters: getDefaultFiltersByViewMode('awaiting_finalisation'),
    defaultHideColumns: getDefaultHideColumnsByViewMode('awaiting_finalisation'),
    defaultSortModel: getDefaultSortModelByViewMode('awaiting_finalisation'),
  },
];

export const defaultBookingsListDashboardViews: BookingListDashboardView[] = [
  {
    value: 'all_bookings',
    defaultFilters: getDefaultFiltersByViewMode('all_bookings'),
    defaultHideColumns: getDefaultHideColumnsByDashboardViewMode('all_bookings'),
    defaultSortModel: getDefaultSortModelByViewMode('all_bookings'),
  },
  {
    value: 'arrivals',
    defaultFilters: getDefaultFiltersByViewMode('arrivals'),
    defaultHideColumns: getDefaultHideColumnsByDashboardViewMode('arrivals'),
    defaultSortModel: getDefaultSortModelByViewMode('arrivals'),
  },
  {
    value: 'departures',
    defaultFilters: getDefaultFiltersByViewMode('departures'),
    defaultHideColumns: getDefaultHideColumnsByDashboardViewMode('departures'),
    defaultSortModel: getDefaultSortModelByViewMode('departures'),
  },
  {
    value: 'unallocated_bookings',
    defaultFilters: getDefaultFiltersByViewMode('unallocated_bookings'),
    defaultHideColumns: getDefaultHideColumnsByDashboardViewMode('unallocated_bookings'),
    defaultSortModel: getDefaultSortModelByViewMode('unallocated_bookings'),
  },
  {
    value: 'scheduled_for_cancellation',
    defaultFilters: getDefaultFiltersByViewMode('scheduled_for_cancellation'),
    defaultHideColumns: getDefaultHideColumnsByDashboardViewMode('scheduled_for_cancellation'),
    defaultSortModel: getDefaultSortModelByViewMode('scheduled_for_cancellation'),
  },
];

export const CREATION_SOURCE_STATUS_OPTION = [
  {
    id: 'CHANNEL_MANAGER',
    value: 'Channel Manager',
    label: 'CHANNEL_MANAGER',
  },
  {
    id: 'API',
    value: 'API',
    label: 'API',
  },
  {
    id: 'IMPORTED',
    value: 'Imported',
    label: 'IMPORTED',
  },
  {
    id: 'MANUALLY',
    value: 'Manually',
    label: 'MANUALLY',
  },
  {
    id: 'WEBSITE',
    value: 'WEBSITE',
    label: 'Website',
  },
];

export const SECURITY_DEPOSIT_STATUS_OPTIONS = [
  {
    id: 'NOT_AUTHORIZED_STATUS',
    value: 'NOT_AUTHORIZED',
    label: 'NOT_AUTHORIZED',
  },
  {
    id: 'AUTHORIZED_STATUS',
    value: 'AUTHORIZED',
    label: 'AUTHORIZED',
  },
  {
    id: 'CHARGED_STATUS',
    value: 'CHARGED',
    label: 'CHARGED',
  },
  {
    id: 'PARTIALLY_CHARGED_STATUS',
    value: 'PARTIALLY_CHARGED',
    label: 'CHARGED_RELEASED',
  },
  {
    id: 'RELEASED_STATUS',
    value: 'RELEASED',
    label: 'RELEASED',
  },
  {
    id: 'PARTIALLY_AUTHORIZED_STATUS',
    value: 'PARTIALLY_AUTHORIZED',
    label: 'PARTIALLY_AUTHORIZED',
  },
  {
    id: 'GUARANTEED_BY_CHANNEL_STATUS',
    value: 'GUARANTEED_BY_CHANNEL',
    label: 'GUARANTEED_BY_CHANNEL',
  },
  {
    id: 'NOT_APPLICABLE_STATUS',
    value: 'NOT_APPLICABLE',
    label: 'NOT_APPLICABLE',
  },
  {
    id: 'AWAITING_AUTHORIZATION_STATUS',
    value: 'AWAITING_AUTHORIZATION',
    label: 'AWAITING_AUTHORIZATION',
  },
  {
    id: 'EXPIRED_STATUS',
    value: 'EXPIRED',
    label: 'EXPIRED',
  },
];

export const CREATED_AT_SHORTCUT_ITEMS: DateFilterSelectValue[] = [
  'today',
  'yesterday',
  'past_7_days',
  'past_30_days',
  'this_week',
  'this_month',
  'this_quarter',
  'this_year',
  'last_month',
  'past_12_months',
  'last_year'
];

export const getPaymentCollectionTypeOptions = (translate: TFunction<any[]>) => ([
  { id: 'channel_collect', label: translate('channel_collect'), value: 'Channel Collect' },
  { id: 'hotel_collect', label: translate('host_collect'), value: 'Hotel Collect' },
]);

export const getYesAndNoOptions = (translate: TFunction<any[]>) => ([
  { id: 'yes', label: translate('yes'), value: 'yes' },
  { id: 'no', label: translate('no'), value: 'no' },
]);

export const getArrivalDateSelectOptions = (
  view: BookingListViewValue
): DateFilterSelectValue[] => {
  switch (view) {
    case 'arrivals':
      return [
        'all',
        'today',
        'next_7_days',
        'next_30_days',
        'this_month',
        'next_month',
        'this_year',
        'next_year',
        'custom',
      ];
    case 'departures':
    case 'all_bookings':
      return [
        'all',
        'today',
        'next_7_days',
        'next_30_days',
        'this_month',
        'next_month',
        'past_7_days',
        'last_month',
        'this_year',
        'next_year',
        'start_of_week',
        'start_of_month',
        'start_of_quarter',
        'start_of_year',
        'custom',
      ];
    case 'stay-overs':
    case 'awaiting_finalisation':
      return [
        'all',
        'today',
        'past_7_days',
        'last_month',
        'start_of_week',
        'start_of_month',
        'start_of_quarter',
        'start_of_year',
        'custom',
      ];
    default:
      return [];
  }
};

export const getDepartureDateSelectOptions = (
  view: BookingListViewValue
): DateFilterSelectValue[] => {
  switch (view) {
    case 'arrivals':
    case 'departures':
      return [
        'all',
        'today',
        'next_7_days',
        'next_30_days',
        'this_month',
        'next_month',
        'this_year',
        'next_year',
        'custom',
      ];
    case 'stay-overs':
      return [
        'all',
        'today',
        'next_7_days',
        'next_30_days',
        'this_month',
        'next_month',
        'this_year',
        'next_year',
        'start_of_week',
        'start_of_month',
        'start_of_quarter',
        'start_of_year',
        'custom',
      ];
    case 'all_bookings':
      return [
        'all',
        'today',
        'next_7_days',
        'next_30_days',
        'this_month',
        'next_month',
        'past_7_days',
        'last_month',
        'this_year',
        'next_year',
        'start_of_week',
        'start_of_month',
        'start_of_quarter',
        'start_of_year',
        'custom',
      ];
    case 'awaiting_finalisation':
      return [
        'all',
        'past_7_days',
        'last_month',
        'start_of_week',
        'start_of_month',
        'start_of_quarter',
        'start_of_year',
        'custom',
      ];
    default:
      return [];
  }
};

export const getNumberAccordionFilterParams = (
  stateKey: string,
  value: {
    condition: NumberCondition;
    start: number;
    end: number | null;
  }
): { [key: string]: number | null } => {
  const { condition, start, end } = value;
  switch (condition) {
    case 'between':
      return {
        [`${stateKey}[gte]`]: start,
        [`${stateKey}[lse]`]: end,
      };
    case 'above':
      return { [`${stateKey}[gt]`]: start };
    case 'below':
      return { [`${stateKey}[ls]`]: start };
    case 'equal':
      return { [`${stateKey}`]: start };
    default:
      return {};
  }
};

export const generateFilterQuery = (defaultFilters: any, gridConfigs: any) => {
  const advanceFilterParams =
    gridConfigs.advanceFilterState && gridConfigs.advanceFilterState.value
      ? {
          key: gridConfigs.advanceFilterState.key
            ? gridConfigs.advanceFilterState.key
            : 'searchKeyword',
          value: gridConfigs.advanceFilterState?.value || '',
        }
      : undefined;

  let query = '';
  if (gridConfigs.filtersState.arrival_date?.from || defaultFilters.arrival_date?.from)
    query += `arrival_date[after]=${
      gridConfigs.filtersState.arrival_date?.from
        ? gridConfigs.filtersState.arrival_date.from
        : defaultFilters.arrival_date.from
    }&`;
  if (gridConfigs.filtersState.arrival_date?.to || defaultFilters.arrival_date?.to)
    query += `arrival_date[strictly_before]=${
      gridConfigs.filtersState.arrival_date?.to
        ? gridConfigs.filtersState.arrival_date.to
        : defaultFilters.arrival_date?.to
    }&`;
  if (gridConfigs.filtersState.departure_date?.from || defaultFilters.departure_date?.from)
    query += `departure_date[after]=${
      gridConfigs.filtersState.departure_date?.from
        ? gridConfigs.filtersState.departure_date.from
        : defaultFilters.departure_date?.from
    }&`;
  if (gridConfigs.filtersState.departure_date?.to || defaultFilters.departure_date?.to)
    query += `departure_date[strictly_before]=${
      gridConfigs.filtersState.departure_date?.to
        ? gridConfigs.filtersState.departure_date.to
        : defaultFilters.departure_date?.to
    }&`;
  if (defaultFilters.booking_status)
    defaultFilters.booking_status.forEach((status: any) => (query += `booking_status[]=${status}&`));
  if (gridConfigs.filtersState.cancelled)
    ['Cancelled', 'Scheduled for Cancellation'].forEach(
      (status) => (query += `booking_status[]=${status}&`)
    );
  if (Array.isArray(gridConfigs.filtersState?.deposit_status))
    gridConfigs.filtersState.deposit_status.forEach(
      (status: any) => (query += `depositStatus[]=${status.value}&`)
    );
  if (Array.isArray(gridConfigs.filtersState?.guest))
    gridConfigs.filtersState.guest.forEach((item: any) => (query += `guest[]=${item.id}&`));
  if (Array.isArray(gridConfigs.filtersState?.property))
    gridConfigs.filtersState.property.forEach((item: any) => (query += `property[]=${item.id}&`));
  if (Array.isArray(gridConfigs.filtersState?.unit))
    gridConfigs.filtersState.unit.forEach((item: any) => (query += `unit[]=${item.id}&`));
  if (gridConfigs.filtersState?.unallocated || defaultFilters.unallocated)
    query += `exists[unit]=false&`;
  if (gridConfigs.filtersState?.unmapped || defaultFilters.unmapped)
    query += `exists[unitType]=false&`;
  if (advanceFilterParams) query += `${advanceFilterParams.key}=${advanceFilterParams.value}`;

  return query;
};

export const checkIsCellEditable = (
  params: GridCellParams<any, any>,
  hasBookingEditAccess: boolean
) => {
  const { guest, finalization_status, check_in_method, check_in_agent } = params.row;
  switch (params.field) {
    case 'id_verified':
      return hasBookingEditAccess && Boolean(guest?.id);
    case 'guest_number':
      return hasBookingEditAccess && Boolean(guest?.id);
    case 'check_in_method':
      return hasBookingEditAccess &&
        finalization_status !== BookingFinalizationStatusEnum.MARKED_AS_FINALIZED_STATUS &&
        finalization_status !== BookingFinalizationStatusEnum.FINALIZED_STATUS;
    case 'access_code':
      return hasBookingEditAccess &&
        finalization_status !== BookingFinalizationStatusEnum.MARKED_AS_FINALIZED_STATUS &&
        finalization_status !== BookingFinalizationStatusEnum.FINALIZED_STATUS &&
        Boolean(check_in_method?.editable);
    case 'check_in_agent':
      return hasBookingEditAccess &&
        Boolean(check_in_method?.name.toLowerCase() === CheckInTypeEnum.MEET_AND_GREET ||
        check_in_method?.name.toLowerCase() === CheckInTypeEnum.CONCIERGE) &&
        Boolean(check_in_agent.check_in_agent_task_id);
    default:
      return true;
  }
};