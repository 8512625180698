import { dispatch } from 'src/redux/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MaintenanceTask, MaintenanceTaskState } from 'src/@types/tasks';
import { SortDirection } from 'src/@types/booking-list';
import TasksAPI from 'src/api/tasks';

const initialState: MaintenanceTaskState = {
  loading: false,
  maintenanceTask: {
    list: [],
    rowCount: 0,
    gridConfigs: null,
  },
};

const slice = createSlice({
  name: 'maintenance-tasks',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setList(state, action: PayloadAction<MaintenanceTask[]>) {
      state.maintenanceTask.list = action.payload;
    },
    setRowCount(state, action: PayloadAction<number>) {
      state.maintenanceTask.rowCount = action.payload;
    },
    setGridConfigs(state, action) {
      state.maintenanceTask.gridConfigs = action.payload;
    },
    updateMaintenanceTask(state, action: PayloadAction<{ taskId: number, newValue: Partial<MaintenanceTask> }>) {
      const { taskId, newValue } = action.payload;
      const foundTaskIndex = state.maintenanceTask.list.findIndex((task) => task.id === taskId);
      if (foundTaskIndex >= 0) {
        state.maintenanceTask.list[foundTaskIndex] = {
          ...state.maintenanceTask.list[foundTaskIndex],
          ...newValue
        }
      }
    },
  }
});

export default slice.reducer;

export const {
  setLoading,
  setRowCount,
  setGridConfigs,
  updateMaintenanceTask,
} = slice.actions;

export function getMaintenanceTaskList({
  page,
  limit,
  filtersState,
  order,
  signal,
  advanceFilterParam,
}: {
  page: number;
  limit: number;
  filtersState: {};
  order?: { field: string; sort: SortDirection };
  signal?: AbortSignal;
  advanceFilterParam?: { key: string; value: string };
}) {
  return async () => {
    dispatch(slice.actions.setLoading(true));
    try {
      const response = await TasksAPI.fetchMaintenanceTasks({
        page,
        limit,
        order,
        signal,
        filtersState,
        advanceFilterParam,
      });
      dispatch(slice.actions.setList(response.data.data));
      dispatch(slice.actions.setRowCount(response.data.meta.total_items));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };
}

