import { Box } from '@mui/material';
import { FC, ReactNode, useEffect, useRef } from 'react'

type Props = {
  trigger: any;
  children: ReactNode;
  disabled?: boolean;
  anchorClass?: string;
  callback?: VoidFunction;
};

const ScrollToErrorWrapper: FC<Props> = ({ trigger, disabled, anchorClass = 'Mui-error', callback, children }) => {
  const wrapper = useRef<HTMLDivElement>(null);
    
  useEffect(() => {
    if (!disabled) {
      const element = wrapper.current?.getElementsByClassName(anchorClass);
      element?.[0]?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      callback?.();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  return (
    <Box ref={wrapper}>
      {children}
    </Box>
  );
};

export default ScrollToErrorWrapper;