import { Box, Divider, Skeleton, Stack } from '@mui/material';
import Iconify from '../Iconify';

const SkeletonChangeHistory = () => (
  <Stack gap={2} px={3}>
    <Skeleton variant="rectangular" height={40} />
    <Stack gap={2} divider={<Divider flexItem />}>
      {Array.from(Array(3).keys()).map((iKey) => (
        <Stack key={iKey} pt={2}>
          <Skeleton variant="text" width={78} />
          <Skeleton variant="text" width={102} />
          <Stack py={2.5} px={1.5} gap={3} flexDirection="column">
            <Skeleton variant="text" width={332} />
            <Skeleton variant="text" width={350} />
            <Skeleton variant="text" width={266} />
          </Stack>
        </Stack>
      ))}
    </Stack>
  </Stack>
);

export default SkeletonChangeHistory;
