import { useEffect, useRef, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import {
  Box,
  List,
  Badge,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
  Link,
  Button,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
// utils
import { fToNow, timestampToDate } from '../../../utils/formatTime';
// hooks
import useAuth from 'src/hooks/useAuth';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import {
  getNotificationCount,
  getNotificationResult,
  clearNotificationList,
  setNotificationAmount,
  addNotificationResults,
} from 'src/redux/slices/notificationsSlice';
import { dispatch, useSelector } from 'src/redux/store';
import notificationsAPI from 'src/api/notifications';
import useLocales from 'src/hooks/useLocales';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { notificatonBellType } from 'src/@types/common';
import useWebsocket from 'src/hooks/useWebsocket';
import { useSnackbar } from 'notistack';
import { getRedirectLink, isExternalLink } from 'src/utils/notification';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { user } = useAuth();
  const { subscribe } = useWebsocket();
  const { translate } = useLocales('common');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const langStorage = localStorage.getItem('i18nextLng');
  const anchorRef = useRef(null);
  const { notificationAmount, hasMoreNotification, loading, notificationResults } = useSelector(
    (state) => state.notification
  );
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState<number>(1);
  const hasNewNotification = notificationResults.filter((item) => {
    const date = new Date().valueOf() - new Date(item.created_at).valueOf();
    return date < 172800000;
  });
  useEffect(() => {
    if (user) {
      subscribe({
        namespace: `notification/${user?.userId}`,
        onEvent: (data: string) => {
          const newNotification: notificatonBellType = JSON.parse(data);
          const isExternal = isExternalLink(getRedirectLink(newNotification));
          dispatch(setNotificationAmount(notificationAmount + 1));
          dispatch(addNotificationResults(newNotification));

          enqueueSnackbar(newNotification.title, {
            variant: 'default',
            action: getRedirectLink(newNotification) ? (
              <Button
                color="inherit"
                size="small"
                {...isExternal ? {
                  target: '_blank',
                  LinkComponent: Link,
                  href: getRedirectLink(newNotification),
                } : {
                  LinkComponent: RouterLink,
                  to: getRedirectLink(newNotification),
                }}
                sx={(theme) => ({ '&:hover': { color: theme.palette.common.white } })}
              >
                {translate('view')}
              </Button>
            ) : undefined,
          });
        },
      });

      return function closeWebsocket() {
        // close ws
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getNotificationCount());
  }, []);

  useEffect(() => {
    dispatch(getNotificationResult({ pageNumber: page, language: langStorage || 'en' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    setPage(1);
    dispatch(clearNotificationList());
  }, [langStorage]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleMarkAllAsRead().then();
  };

  const handleMarkAllAsRead = async () => {
    try {
      await notificationsAPI.updateNotificationRead({ is_read: true });
      dispatch(setNotificationAmount(0));
    } catch (error) {}
  };

  return (
    <>
      <IconButtonAnimate
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
      >
        <Badge badgeContent={notificationAmount} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">{translate('notifications')}</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {notificationAmount > 1
                ? translate('unread_messages', { notificationAmount })
                : translate('unread_message', { notificationAmount })}
            </Typography>
          </Box>

          <Tooltip title={`${translate('notification_setting')}`}>
            <IconButtonAnimate
              size="medium"
              onClick={() => navigate(PATH_DASHBOARD.profile.notifications)}
            >
              <Iconify icon="uil:setting" />
            </IconButtonAnimate>
          </Tooltip>
        </Box>

        {notificationResults.length > 0 ? (
          <>
            <Divider />
            <Scrollbar sx={{ maxHeight: { xs: 340, sm: '70vh' }, pb: 1 }}>
              {hasNewNotification.length > 0 ? (
                <List disablePadding>
                  {hasNewNotification?.map((notification) => (
                    <NotificationItem
                      key={notification.notification_id}
                      notification={notification}
                      notificationAmount={notificationAmount}
                    />
                  ))}
                </List>
              ) : null}

              {hasNewNotification.length < notificationResults.length ? (
                <List disablePadding>
                  {notificationResults?.map((notification) => {
                    const date = new Date().valueOf() - new Date(notification.created_at).valueOf();
                    if (date >= 172800000) {
                      return (
                        <NotificationItem
                          key={notification.notification_id}
                          notification={notification}
                          notificationAmount={notificationAmount}
                        />
                      );
                    }
                    return null;
                  })}
                </List>
              ) : null}
            </Scrollbar>
          </>
        ) : null}

        {hasMoreNotification ? (
          <>
            <Divider />
            <Box sx={{ p: 1 }}>
              <LoadingButton fullWidth onClick={() => setPage(page + 1)} loading={loading}>
                {translate('load_more')}
              </LoadingButton>
            </Box>
          </>
        ) : null}
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

function NotificationItem({
  notification,
  notificationAmount,
}: {
  notification: notificatonBellType;
  notificationAmount: number;
}) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      {...(isExternalLink(getRedirectLink(notification))
        ? {
            component: Link,
            href: getRedirectLink(notification),
            target: '_blank',
            rel: 'noopener',
          }
        : getRedirectLink(notification)
        ? {
            component: RouterLink,
            to: getRedirectLink(notification),
          }
        : {})}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        alignItems: 'flex-start',
        ...(!notification.is_read && notificationAmount !== 0
          ? {
              bgcolor: 'action.selected',
            }
          : {}),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'transparent' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(timestampToDate(notification.created_at))}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

const getIconMenu = (type: string) => {
  switch (type) {
    case 'tasks':
    case 'my_tasks':
    case 'task_management':
      return <SvgIconStyle sx={{ width: 24, height: 24 }} src={`/icons/ic_tasks.svg`} />;
    case 'bookings':
      return <SvgIconStyle sx={{ width: 24, height: 24 }} src={`/icons/ic_bookings.svg`} />;
    case 'blocked_dates':
      return <SvgIconStyle sx={{ width: 24, height: 24 }} src={`/icons/ic_calendar.svg`} />;
    case 'messages':
    case 'unified_inbox':
      return <SvgIconStyle sx={{ width: 24, height: 24 }} src={`/icons/ic_inbox.svg`} />;
    case 'finance':
      return <SvgIconStyle sx={{ width: 24, height: 24 }} src={`/icons/ic_finance.svg`} />;
  }
};

function renderContent(notification: notificatonBellType) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {notification.description}
      </Typography>
    </Typography>
  );

  return {
    avatar: getIconMenu(notification.type),
    title,
  };
}
