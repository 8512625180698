import { Theme } from '@mui/material/styles';
import useResponsive from 'src/hooks/useResponsive';

// ----------------------------------------------------------------------

export default function Menu(theme: Theme) {
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
            '&:hover': {
              backgroundColor: theme.palette.action.hover
            }
          },
          [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.pxToRem(12)
          },
        }
      }
    }
  };
}
