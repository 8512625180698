import { PayloadAction } from "@reduxjs/toolkit";
import { AutomatedMessageConfig, AutomatedMessageState, AutomatedMessageTabValues, MessageType, OverrideSetting, Setting, SettingKey, UpdateAutomatedMessageConfigBodyType } from "src/@types/automated-message";
import { Variable } from "src/@types/common";
import automatedMessageAPI from 'src/api/automated-message';
import { dispatch } from "src/redux/store";
import { convertConditions, getSettingName } from "src/utils/automatedMessage";
import { initialState, setOverrideSettings, slice } from ".";
import { AxiosResponse } from "axios";

export const landingPage = {
  setAutomatedMessages(state: AutomatedMessageState, action: PayloadAction<any>) {
    const automatedMessages = action.payload.data;
    automatedMessages.forEach((message, index) => {
      automatedMessages[index] = {
        ...message,
        conditions: message.conditions.map((condition) => convertConditions(condition)),
      }
    }) 
    if (action.payload.isLoadingMore) {
      state.landingPage.automatedMessages = [
        ...state.landingPage.automatedMessages,
        ...automatedMessages,
      ];
    } else state.landingPage.automatedMessages = automatedMessages;
  },
  setIsActiveAutomatedMessage(state: AutomatedMessageState, action: PayloadAction<any>) {
      state.landingPage.automatedMessages = state.landingPage.automatedMessages.map((automatedMessage) => {
        if (automatedMessage.id === action.payload.id) return {
          ...automatedMessage, is_active: action.payload.isActive,
        }
        else return automatedMessage;
      })
  },
  setIsFavoriteAutomatedMessage(state: AutomatedMessageState, action: PayloadAction<{ id: number, isFavorite: boolean }>) {
    state.landingPage.automatedMessages = state.landingPage.automatedMessages.map((automatedMessage) => {
      if (automatedMessage.id === action.payload.id) return {
        ...automatedMessage, is_favorite: action.payload.isFavorite,
      }
      else return automatedMessage;
    })
  },
  setClonedAutomatedMessage(state: AutomatedMessageState, action: PayloadAction<any>) {
    state.landingPage.automatedMessages = [
      { ...action.payload, conditions: action.payload.conditions.map((condition) => convertConditions(condition)) },
      ...state.landingPage.automatedMessages,
    ];
  },
  setCategory(state: AutomatedMessageState, action: PayloadAction<AutomatedMessageTabValues>) {
    state.landingPage.category = action.payload;
  },
  setStatus(state, action: PayloadAction<any>) {
    state.landingPage.filters.status = action.payload;
  },
  setSendVia(state: AutomatedMessageState, action: PayloadAction<MessageType>) {
    state.landingPage.filters.sendVia = [...state.landingPage.filters.sendVia, action.payload];
  },
  removeSendVia(state: AutomatedMessageState, action: PayloadAction<MessageType>) {
    state.landingPage.filters.sendVia = state.landingPage.filters.sendVia.filter(
      (item) => item !== action.payload
    );
  },
  setMessageFilterType(state: AutomatedMessageState, action: PayloadAction<any>) {
    state.landingPage.filters.messageType = action.payload;
  },
  setToFilteredValue(state: AutomatedMessageState, action: PayloadAction<any[]>) {
    state.landingPage.filters.toFilteredValues = action.payload;
  },
  setLanguageFilteredValues(state: AutomatedMessageState, action: PayloadAction<any[]>) {
    state.landingPage.filters.languageFilteredValues = action.payload;
  },
  removeLanguage(state: AutomatedMessageState, action: PayloadAction<string>) {
    state.landingPage.filters.languageFilteredValues =
      state.landingPage.filters.languageFilteredValues.filter(
        (item) => item.label !== action.payload
      );
  },
  setHasAttachment(state: AutomatedMessageState, action: PayloadAction<boolean>) {
    state.landingPage.filters.hasAttachments = action.payload;
  },
  setIsFavorite(state: AutomatedMessageState, action: PayloadAction<boolean>) {
    state.landingPage.filters.isFavorite = action.payload;
  },
  setLoading(state: AutomatedMessageState, action: PayloadAction<boolean>) {
    state.landingPage.loading = action.payload;
  },
  setHasMore(state: AutomatedMessageState, action: PayloadAction<boolean>) {
    state.landingPage.hasMore = action.payload;
  },
  setPage(state: AutomatedMessageState, action: PayloadAction<number>) {
    state.landingPage.pageNumber = action.payload;
  },
  removeAutomatedMessageFromList(state: AutomatedMessageState, action: PayloadAction<number>) {
    state.landingPage.automatedMessages = state.landingPage.automatedMessages.filter(
      (item) => item.id !== action.payload
    );
  },
  removeFilters(state) {
    state.landingPage.filters = initialState.landingPage.filters;
  },
  setSetting(state: AutomatedMessageState, action: PayloadAction<{ name: string, setting: any }>) {
    const { setting } = action.payload;
    state.landingPage.setting[action.payload.name] = setting;
  },
  removeLanguageConfig(state: AutomatedMessageState, action: PayloadAction<any>) {
    const foundConfig = state.landingPage.setting.languages.find(
      (config) => config.brand.id === action.payload.brandId
    );
    if (foundConfig) {
      const index = state.landingPage.setting.languages.indexOf(foundConfig);
      if (index > -1) {
        state.landingPage.setting.languages[index].language_config = state.landingPage.setting.languages[index].language_config.filter(
          (config, index) => index !== action.payload.indexToRemove
        );
      }
    }
  },
  addLanguageConfig(state: AutomatedMessageState, action: PayloadAction<any>) {
    const newConfig = {
      country: {
        name: action.payload.countryName,
        iso2_code: action.payload.countryCode,
      },
      language: {
        title: action.payload.languageTitle,
        code: action.payload.languageCode,
      },
    };
    const foundConfig = state.landingPage.setting.languages.find(
      (config) => config.brand.id === action.payload.brand.id
    );
    if (foundConfig) {
      const index = state.landingPage.setting.languages.indexOf(foundConfig);
      if (index > -1) {
        state.landingPage.setting.languages[index].language_config = [
          ...state.landingPage.setting.languages[index].language_config,
          newConfig,
        ];
      }
    } else {
      state.landingPage.setting.languages = [
        ...state.landingPage.setting.languages,
        {
          brand: action.payload.brand,
          language_config: [newConfig],
        }
      ];
    }
  },
  setReplyToValue(state: AutomatedMessageState, action: PayloadAction<any>) {
    const foundReplyTo = state.landingPage.setting.replyTos.find(
      (replyTo) => replyTo.brand.id === action.payload.brand.id
    );
    if (foundReplyTo) {
      const index = state.landingPage.setting.replyTos.indexOf(foundReplyTo);
      if (index > -1) {
        if (state.landingPage.setting.replyTos[index].reply_to) {
          state.landingPage.setting.replyTos[index].reply_to[action.payload.property] = action.payload.value;
        } else {
          state.landingPage.setting.replyTos[index].reply_to = { 
            name: action.payload.property === 'name' ? action.payload.value : '',
            reply_to_email: action.payload.property === 'reply_to_email' ? action.payload.value : '',
          }
        }
      }
    } else {
      state.landingPage.setting.replyTos = [...state.landingPage.setting.replyTos, {
        brand: action.payload.brand,
        reply_to: action.payload.property === 'name' ? {
          name: action.payload.value,
          reply_to_email: '',
        } : {
          name: '',
          reply_to_email: action.payload.value,
        }
      }];
    }
  },
  setAutoResponseVariables(state: AutomatedMessageState, action: PayloadAction<Variable[]>) {
    state.landingPage.autoResponseVariables = action.payload;
  },
  setAutomatedMessageConfigs(state: AutomatedMessageState, action: PayloadAction<AutomatedMessageConfig[]>) {
    action.payload.forEach((config) => {
      state.landingPage.automatedMessageConfigs[config.brand.id] = config;
    });
  },
  setUpdateAutomatedMessageConfig(state: AutomatedMessageState, action: PayloadAction<AutomatedMessageConfig>) {
    state.landingPage.automatedMessageConfigs[action.payload.brand.id] = action.payload;
  }
};

export function getSettings(key: SettingKey, template_id?: number, successCallBack?: () => void) {
  return async () => {
    try {
      const response = await automatedMessageAPI.fetchSetting(key, template_id);
      if (typeof template_id === 'undefined') {
        dispatch(slice.actions.setSetting({ name: getSettingName(key), setting: response.data }));
      } else {
        const overrideSettings: OverrideSetting[] = response.data.map((data) => ({
          name: data.reply_to.name,
          email: data.reply_to.reply_to_email,
          brandId: data.brand.id,
        }));
        dispatch(setOverrideSettings(overrideSettings));
      }
      successCallBack && successCallBack();
    } catch (error) {}
  };
};

export function saveSetting(apiKey: SettingKey, settingsState: Setting) {
  return async () => {
    const settings: any[] = [];
    switch (apiKey) {
      case "reply to":
        settingsState.replyTos.forEach((replyTo) => {
          settings.push({
            brand_id: replyTo.brand.id,
            reply_to_name: replyTo.reply_to.name,
            email: replyTo.reply_to.reply_to_email,
          });
        });
        break;
      case "language":
        settingsState.languages.forEach((language) => {
          settings.push({
            brand_id: language.brand.id,
            language_settings: language.language_config.map((config) => ({
              language: config.language.code,
              country: config.country.iso2_code,
            })),
          });
        });
        break;
    }
    try {
      await automatedMessageAPI.saveSetting({
        key: apiKey,
        settings,
      });
      dispatch(getSettings(apiKey));
    } catch (error) {}
  };
};

export function getAutomatedMessageConfigs(
  brandId?: number,
  setLoading?: (state: boolean) => void,
  callback?: (response: AxiosResponse<any, any> | null, status: number) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const response = await automatedMessageAPI.fetchAutomatedMessageConfigs(brandId);
      dispatch(slice.actions.setAutomatedMessageConfigs(response.data));
      callback?.(response, response.status);
    } catch (error) {
      callback?.(null, error?.status || 500);
    } finally {
      setLoading?.(false);
    }
  };
}

export function updateAutomatedMessageConfig(
  configId: number,
  requestBody: UpdateAutomatedMessageConfigBodyType,
  setLoading?: (state: boolean) => void,
  callback?: (response: AxiosResponse<any, any> | null, status: number) => void
) {
  return async () => {
    try {
      setLoading?.(true);
      const response = await automatedMessageAPI.updateAutomatedMessageConfig(configId, requestBody);
      dispatch(slice.actions.setUpdateAutomatedMessageConfig(response.data));
      callback?.(response, response.status);
    } catch (error) {
      callback?.(null, error?.status || 500);
    } finally {
      setLoading?.(false);
    }
  };
}