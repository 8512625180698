import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// @mui
import {
  Stack,
  Alert,
  Checkbox,
  TextField,
  Link,
  FormControlLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import useLocales from 'src/hooks/useLocales';
import SensitiveInput from 'src/components/Input/SensitiveInput';
import InputWrapper from 'src/components/InputWrapper';
import { Link as RouterLink } from 'react-router-dom';
import { PATH_AUTH } from 'src/routes/paths';

// ----------------------------------------------------------------------

type InitialValues = {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};

export default function LoginForm() {
  const { login } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { translate } = useLocales(['common', 'auth']);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().trim().email(translate('errors.valid_email')).required(translate('errors.email')),
    password: Yup.string().required(translate('errors.password')),
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      email: '',
      password: '',
      remember: true,
      afterSubmit: '',
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        await login(values.email, values.password);
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.data.message });
        }
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={1}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

          <InputWrapper title={translate('email_address')}>
            <TextField
              fullWidth
              autoFocus
              autoComplete="username"
              type="text"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </InputWrapper>

          <InputWrapper title={translate('password')}>
            <SensitiveInput
              fullWidth
              autoComplete="current-password"
              {...getFieldProps('password')}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </InputWrapper>

        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember}/>}
            label={translate('remember_me').toString()}
          />
          <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
            {translate('forgot_password')}
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          {translate('login')}
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
