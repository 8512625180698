import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { utcToZonedTime } from 'date-fns-tz';
import { Menu, MenuItem, Typography } from '@mui/material';
import { GridRenderCellParams, GridSortModel } from '@mui/x-data-grid-pro';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { downloadFile } from 'src/utils/file';
import { fDT, fYearMonthDay } from 'src/utils/formatTime';
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
import suppliersAPI from 'src/api/suppliers';
import { FilterState } from 'src/@types/filter';
import { ExportFileType } from 'src/@types/common';
import { dispatch, useSelector } from 'src/redux/store';
import { deleteSupplier, setRowCount, setSuppliers } from 'src/redux/slices/suppliers';
import {
  ALL_SUPPLIER_COLUMN_FIELDS,
  SupplierDataGridColumn,
  SupplierGridRow,
  SupplierList,
} from 'src/@types/suppliers';
import CardAction, { ActionType } from 'src/components/card/CardAction';
import ExportButton from 'src/components/data-grid/ExportButton';
import CustomRenderCell from 'src/components/data-grid/CustomRenderCell';
import SnackbarContent from 'src/components/data-grid/ExportLoadingSnackbarContent';
import { DateCondition } from 'src/sections/@dashboard/guest/data-grid/DateCustomAccordionFilter';
import useAcl from 'src/hooks/useAcl';

const DEFAULT_COLUMN_CONFIG = {
  editable: false,
  sortable: false,
  disableColumnMenu: true,
};

const RowActions = ({ activeSupplier }: { activeSupplier: SupplierGridRow }) => {
  const navigate = useNavigate();
  const { translate } = useLocales(['suppliers', 'common']);
  const { supplier } = useSelector((state) => state.suppliers);
  const [deletingSupplier, setDeletingSupplier] = useState<boolean>(false);
  const supplierIndex = supplier.list.findIndex((supplier) => supplier.id === activeSupplier.id);
  const { DELETE: hasDeleteAccess } = useAcl('PeymansStaffBundleCompany');

  const handleDeleteSupplier = () => {
    dispatch(
      deleteSupplier(activeSupplier.id, setDeletingSupplier, (succeed) => {
        if (succeed) {
          let totalItems = supplier.rowCount;
          const newSuppliers = supplier.list.filter(
            (supplier) => supplier.id !== activeSupplier.id
          );
          dispatch(setSuppliers(newSuppliers));
          dispatch(setRowCount((totalItems -= 1)));
        }
      })
    );
  };

  const ACTIONS: ActionType[] = [
    {
      type: 'more',
      label: translate('view'),
      onAction: () => {
        navigate(`${PATH_DASHBOARD.contacts.supplier.view}/${activeSupplier.id}`);
      },
    },
    ...(hasDeleteAccess
      ? ([
          {
            type: 'more',
            label: translate('delete'),
            onAction: handleDeleteSupplier,
          },
        ] as ActionType[])
      : []),
  ];

  return (
    <CardAction
      mode="collapsed"
      actions={ACTIONS}
      loading={Boolean(
        deletingSupplier &&
          supplierIndex >= 0 &&
          supplier.list[supplierIndex].id === activeSupplier.id
      )}
    />
  );
};

export const SUPPLIER_LIST_COLUMNS = (): SupplierDataGridColumn[] => {
  const { translate, shortDateFormat, currentLang } = useLocales(['suppliers', 'common']);

  const formatDate = (date: Date, pattern: any) =>
    fDT({
      date: utcToZonedTime(date, 'UTC'),
      pattern,
      options: { locale: currentLang.dateLocale },
    });

  return [
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'id',
      headerName: translate('id'),
      type: 'number',
      hideable: false,
    },
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'name',
      headerName: translate('name'),
      width: 200,
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, SupplierGridRow['name']>) => (
        <CustomRenderCell disabledTooltip title={params.value} />
      ),
    },
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'additionalInfo',
      headerName: translate('additionalInfo'),
      width: 200,
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, SupplierGridRow['additionalInfo']>) => (
        <CustomRenderCell disabledTooltip title={params.value} />
      ),
    },
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'createdAt',
      headerName: translate('createdAt'),
      width: 160,
      renderCell: (params: GridRenderCellParams<any, SupplierGridRow['createdAt']>) => (
        <CustomRenderCell
          disabledTooltip
          title={params.value ? formatDate(params.value, shortDateFormat) : ''}
        />
      ),
    },
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'updatedAt',
      headerName: translate('updatedAt'),
      width: 160,
      renderCell: (params: GridRenderCellParams<any, SupplierGridRow['updatedAt']>) => (
        <CustomRenderCell
          disabledTooltip
          title={params.value ? formatDate(params.value, shortDateFormat) : ''}
        />
      ),
    },
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'action',
      headerName: translate('actions'),
      hideable: false,
      width: 80,
      align: 'center',
      headerAlign: 'center',
      disableReorder: true,
      renderCell: (params) => {
        const selectedRow = params.row;
        return <RowActions activeSupplier={selectedRow} />;
      },
    },
  ];
};

export const SUPPLIER_LIST_ROWS = (suppliers: SupplierList[]): SupplierGridRow[] =>
  suppliers.map((supplier) => ({
    id: supplier.id,
    name: supplier.name,
    additionalInfo: supplier.description ?? '',
    createdAt: new Date(supplier.created_at),
    updatedAt: new Date(supplier.updated_at),
  }));

export const getCreatedAtSearchParams = (value: {
  condition: DateCondition;
  start: Date;
  end: Date | null;
}): { [key: string]: string | null } => {
  const { condition, start, end } = value;
  const startValue = `${fYearMonthDay(start)}T00:00:00`;
  const endValue = end ? `${fYearMonthDay(end)}T23:59:59` : null;
  switch (condition) {
    case 'between':
      return {
        'createdAt[after]': startValue,
        'createdAt[before]': endValue,
      };
    case 'later_than':
      return { 'createdAt[strictly_after]': startValue };
    case 'earlier_than':
      return { 'createdAt[strictly_before]': startValue };
    case 'equal':
      return {
        'createdAt[after]': startValue,
        'createdAt[before]': `${fYearMonthDay(start)}T23:59:59`,
      };
    default:
      return {};
  }
};

export const getUpdatedAtSearchParams = (value: {
  condition: DateCondition;
  start: Date;
  end: Date | null;
}): { [key: string]: string | null } => {
  const { condition, start, end } = value;
  const startValue = `${fYearMonthDay(start)}T00:00:00`;
  const endValue = end ? `${fYearMonthDay(end)}T23:59:59` : null;
  switch (condition) {
    case 'between':
      return {
        'updatedAt[after]': startValue,
        'updatedAt[before]': endValue,
      };
    case 'later_than':
      return { 'updatedAt[strictly_after]': startValue };
    case 'earlier_than':
      return { 'updatedAt[strictly_before]': startValue };
    case 'equal':
      return {
        'updatedAt[after]': startValue,
        'updatedAt[before]': `${fYearMonthDay(start)}T23:59:59`,
      };
    default:
      return {};
  }
};

export const CustomExportButton = ({
  gridConfigs,
  columnVisibility,
}: {
  gridConfigs?: {
    page: number;
    limit: number;
    sortModel: GridSortModel;
    filtersState: FilterState;
    advanceFilterState: { key: string; value: string } | undefined;
  };
  columnVisibility: any;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useResponsive('down', 'sm');
  const { translate } = useLocales(['suppliers', 'common']);
  const [actionAnchorEl, setActionAnchorEl] = useState<null | HTMLElement>(null);

  const closeMenu = () => {
    setActionAnchorEl(null);
  };

  const RenderExportList = (AllGridList: any[], gridConfigVisibility: any[]) =>
    AllGridList.map(
      (item) =>
        gridConfigVisibility[item] !== false && {
          key: item,
          label: translate(item),
        }
    ).filter((item) => item !== false);

  const download = (fileType: ExportFileType) => {
    closeMenu();
    enqueueSnackbar(<SnackbarContent />, { variant: 'info' });
    const advanceFilterParam =
      gridConfigs?.advanceFilterState && gridConfigs?.advanceFilterState.value
        ? {
            key: gridConfigs?.advanceFilterState.key ? gridConfigs.advanceFilterState.key : 'name',
            value: gridConfigs?.advanceFilterState.value || '',
          }
        : undefined;

    suppliersAPI
      .fetchSupplierList({
        filtersState: gridConfigs?.filtersState,
        advanceFilterParam: advanceFilterParam,
        headers: { Accept: fileType },
        responseType: 'blob',
        fields: RenderExportList(ALL_SUPPLIER_COLUMN_FIELDS, columnVisibility),
      })
      .then((res) => {
        const blob = new Blob([res.data], { type: fileType });
        const url = URL.createObjectURL(blob);
        downloadFile(url, `suppliers_${new Date().getTime()}`);
      });
  };

  return (
    <>
      <ExportButton
        onClick={(event) => setActionAnchorEl(event.currentTarget)}
        isSelected={Boolean(actionAnchorEl)}
      />
      <Menu
        onClose={closeMenu}
        anchorEl={actionAnchorEl}
        open={Boolean(actionAnchorEl)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        elevation={0}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '16px',
          },
        }}
      >
        <MenuItem
          onClick={() =>
            download('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
          }
        >
          <Typography variant={isMobile ? 'body2' : 'body1'}>{translate('xlsx_file')}</Typography>
        </MenuItem>
        <MenuItem onClick={() => download('text/csv')}>
          <Typography variant={isMobile ? 'body2' : 'body1'}>{translate('csv_file')}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export const SUPPLIERS_LIST_COLUMNS_VISIBILITY = {
  id: false,
};
