import tasksAPI from 'src/api/tasks';
import { combineReducers } from 'redux';
import housekeepingReducer from './housekeeping-tasks';
import taskViewReducer from './task-view';
import maintenanceReducer from './maintenance-tasks';
import { Dispatch, SetStateAction } from 'react';
import { AxiosResponse } from 'axios';
import { TaskPriority } from 'src/@types/tasks';

// ----------------------------------------------------------------------

export default combineReducers({
  housekeeping: housekeepingReducer,
  taskView: taskViewReducer,
  maintenance: maintenanceReducer,
});

// ----------------------------------------------------------------------

export function addTaskNote(
  taskId: number,
  note_text: string,
  setLoading: Dispatch<SetStateAction<boolean>>,
  callback: (response: AxiosResponse<any, any>) => void,
  ) {
  return async () => {
    setLoading(true);
    try {
      const response = await tasksAPI.addNote(taskId, note_text);
      callback(response);
    } catch (error) {}
    setLoading(false);
  };
}
export function changeTaskAssignee(
  taskId: number,
  housekeeperId: number | null,
  setLoading: Dispatch<SetStateAction<boolean>>,
  callback: (response: AxiosResponse<any, any>) => void,
) {
  return async () => {
    setLoading(true);
    try {
      const response = await tasksAPI.changeTaskAssignee(taskId, housekeeperId);
      callback(response);
    } catch (error) {}
    setLoading(false);
  };
}
export function changeTaskTimeFrame(
  taskId: number,
  canStartFrom: string,
  mustFinishBy: string,
  estimation: number,
  setLoading: Dispatch<SetStateAction<boolean>>,
  callback: (response: AxiosResponse) => void,
) {
  return async () => {
    setLoading(true);
    try {
      const response = await tasksAPI.changeTaskTimeFrame(
        taskId,
        canStartFrom,
        mustFinishBy,
        estimation,
      );
      callback(response);
    } catch (error) {}
    setLoading(false);
  };
}
export function changeTaskPriority(
  taskId: number,
  priority: TaskPriority,
  setLoading: (succeed: boolean) => void,
  callback: (succeed: boolean, response?: any) => void,
) {
  return async () => {
    setLoading(true);
    try {
      const response = await tasksAPI.changeTaskPriority(taskId, priority);
      callback(true, response);
    } catch (error) {
      callback(false);
    } finally {
      setLoading(false);
    }
  };
}