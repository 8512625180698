import addDays from 'date-fns/addDays';
import { useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { IconButton, Tooltip } from '@mui/material';
import { DateRangePickerProps } from '@mui/x-date-pickers-pro/DateRangePicker';
// hooks
import useLocales from 'src/hooks/useLocales';
// redux
import { useDispatch, useSelector } from 'src/redux/store';
import { closeDialog, openDialog } from 'src/redux/slices/booking/createBookingDialog';
// components
import Iconify from 'src/components/Iconify';
import { SearchOption } from 'src/components/SearchLocation';
// sections
import SearchDialog from '../../../sections/wizard/booking/components/search/SearchDialog';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: '1.5rem !important',
  height: '1.5rem !important',
  padding: theme.spacing(0),
  borderRadius: 4,
  backgroundColor: `${theme.palette.primary.main} !important`,
  '&:hover': {
    backgroundColor: `${theme.palette.primary.dark} !important`,
  },
}));

export default function CreateNewBooking() {
  const navigate = useNavigate();
  const { translate } = useLocales('bookingWizard');
  const dialogTitle = translate('bookingWizard.search.dialog_title.create_booking');
  const open = useSelector((state) => state.createBooking.open);
  const title = useSelector((state) => state.createBooking.title);
  const destination = useSelector((state) => state.createBooking.destination);
  const defaultFilter = useSelector((state) => state.createBooking.defaultFilter);
  const arrival = useSelector((state) => state.createBooking.arrival);
  const departure = useSelector((state) => state.createBooking.departure);
  const adultGuests = useSelector((state) => state.createBooking.adultGuests);
  const childGuests = useSelector((state) => state.createBooking.childGuests);
  const additionalURLParams = useSelector((state) => state.createBooking.additionalURLParams);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeDialog());
  };

  const objectToQueryString = (object: any) => {
    const keys = Object.keys(object);
    const keyValuePairs = keys.map(key =>
      encodeURIComponent(key) + '=' + encodeURIComponent(object[key]));
    return keyValuePairs.join('&');
  };

  const handleSearch = (
    option: SearchOption | null,
    date: DateRangePickerProps<Date>,
    guestsNum: number,
    childrenNum: number,
    unitsNum: number,
    searchQuery?: string
  ) => {
    const currDate = new Date().toISOString().split('T')[0];
    const addDay = addDays(new Date(currDate), 2);
    const addedCurrDate = addDay.toISOString().split('T')[0];
    const cloneLocationModified = additionalURLParams.hasOwnProperty('clonedFromId') && Boolean(
      defaultFilter?.type !== option?.type ||
      defaultFilter?.id !== option?.id ||
      defaultFilter?.destination !== option?.destination
    )

    const arrivalDate = date[0]
      ? JSON.stringify(
          new Date(
            Date.UTC(
              date[0]?.getFullYear() as number,
              date[0]?.getMonth() as number,
              date[0]?.getDate() as number
            )
          )
        ).slice(1, 11)
      : currDate;

    const departureDate = date[1]
      ? JSON.stringify(
          new Date(
            Date.UTC(
              date[1]?.getFullYear() as number,
              date[1]?.getMonth() as number,
              date[1]?.getDate() as number
            )
          )
        ).slice(1, 11)
      : addedCurrDate;

    const wizardURL = `/wizard/booking?arrivalDate=${arrivalDate}&departureDate=${departureDate}&destinationType=${
      option?.type
    }&destination=${option?.id ? option?.id : encodeURIComponent(searchQuery ? searchQuery : '')
    }&destinationLabel=${encodeURIComponent(
      option?.destination ? option?.destination : searchQuery ? searchQuery : ''
    )}&numberOfAdultGuest=${guestsNum}&numberOfChildGuest=${childrenNum}&units=${unitsNum
    }${Object.keys(additionalURLParams).length > 0 ? '&' + objectToQueryString(additionalURLParams) : ''
    }${additionalURLParams.hasOwnProperty('clonedFromId') ? '&locationModified=' + cloneLocationModified : ''}`;
    window.open(wizardURL, '_blank');
  };

  return (
    <>
      <Tooltip arrow title={dialogTitle}>
        <StyledIconButton onClick={() => dispatch(openDialog())}>
          <Iconify icon={'ic:round-plus'} color="white" />
        </StyledIconButton>
      </Tooltip>
      <SearchDialog
        open={open}
        destination={destination}
        defaultFilter={defaultFilter}
        arrivalDate={arrival}
        departureDate={departure}
        adults={adultGuests}
        children={childGuests}
        handleClose={handleClose}
        handleSearch={handleSearch}
        dialogTitle={title || dialogTitle}
      />
    </>
  );
}
