import UnifiedInboxApi from "src/api/unified-inbox";
import { dispatch, store } from "src/redux/store";
import { initialState, slice, getConversations, setContactOfSelectedConversation } from './index'
import { PayloadAction } from "@reduxjs/toolkit";

export const formReducers = {
  setFormOpen(state, action: PayloadAction<boolean>) {
    state.form = { ...state.form, isOpen: action.payload };
  },
  clearBookingForms(state) {
    state.form = {
      ...initialState.form,
      isOpen: state.form.isOpen,
      formType: state.formType,
    };
  },
  setNewConversationType(state, action: PayloadAction<string>) {
    state.form = { ...state.form, formType: action.payload };
  },
  setIsJustEditingContact(state, action: PayloadAction<boolean>) {
    state.form = { ...state.form, isJustEditingContact: action.payload };
  },
  setContacts(state, action: PayloadAction<any[]>) {
    state.form = { ...state.form, contacts: [...state.form.contacts, ...action.payload] };
  },
  selectContact(state, action: PayloadAction<any>) {
    state.form = { ...state.form, selectedContact: action.payload };
  },
  clearContacts(state) {
    state.form = {
      ...state.form,
      contacts: [],
      contactsPage: 0,
      contactNotFound: false,
    };
  },
  nextContactsPage(state) {
    state.form = { ...state.form, contactsPage: state.form.contactsPage + 1 };
  },
  setContactsPageIsLoadingStatus(state, action: PayloadAction<boolean>) {
    state.form = { ...state.form, contactsPageIsLoading: action.payload };
  },
  setContactNotFound(state, action: PayloadAction<boolean>) {
    state.form = { ...state.form, contactNotFound: action.payload };
  },
  setBookings(state, action: PayloadAction<any[]>) {
    state.form = { ...state.form, bookings: [...state.form.bookings, ...action.payload] };
  },
  selectBooking(state, action: PayloadAction<any>) {
    state.form = { ...state.form, selectedBooking: action.payload };
  },
  clearBookings(state) {
    state.form = {
      ...state.form,
      bookings: [],
      bookingsPage: 0,
      bookingNotFound: false,
    };
  },
  nextBookingsPage(state) {
    state.form = { ...state.form, bookingsPage: state.form.bookingsPage + 1 };
  },
  setBookingsPageIsLoadingStatus(state, action: PayloadAction<boolean>) {
    state.form = { ...state.form, bookingsPageIsLoading: action.payload };
  },
  setBookingNotFound(state, action: PayloadAction<boolean>) {
    state.form = { ...state.form, bookingNotFound: action.payload };
  },
  setIndependentValueType(state, action: PayloadAction<string>) {
    state.form = { ...state.form, independentValueType: action.payload };
  },
  setIndependentValue(state, action: PayloadAction<string>) {
    state.form = { ...state.form, independentValue: action.payload };
  },
  setContactFormHasError(state, action: PayloadAction<boolean>) {
    state.form = { ...state.form, contactFormHasError: action.payload };
  },
  setContact(state, action: PayloadAction<any>) {
    state.form = { ...state.form, contact: { ...state.form.contact, ...action.payload } };
  },
  resetContact(state) {
    state.form = { ...state.form, contact: initialState.form.contact };
  },
  setContactEmails(state, action: PayloadAction<any>) {
    state.form = {
      ...state.form,
      contact: {
        ...state.form.contact,
        emails: action.payload,
      },
    };
  },
  setContactMobilePhones(state, action: PayloadAction<any>) {
    state.form = {
      ...state.form,
      contact: {
        ...state.form.contact,
        mobilePhones: action.payload,
      },
    };
  },
  setNewAddedDetailType(state, action: PayloadAction<string>) {
    state.form = { ...state.form, newAddedDetailType: action.payload };
  },
  clearForms(state) {
    state.form = { ...initialState.form, contact: initialState.form.contact };
  },
};

// ----------------------------------------------------------------------


export function getContacts(page, key, loadMore) {
  return async () => {
    if (loadMore) dispatch(slice.actions.setContactsPageIsLoadingStatus(true));
    UnifiedInboxApi.getContactsWithGuestRoute(page, key, true)
      .then((response) => {
        dispatch(slice.actions.setContacts(response.data));
        dispatch(slice.actions.nextContactsPage());
        if (loadMore) dispatch(slice.actions.setContactsPageIsLoadingStatus(false));
        if (!response.data) {
          dispatch(slice.actions.setContactNotFound(true));
        } else {
          dispatch(slice.actions.setContactNotFound(false));
        }
      })
      .catch();
  };
}
export function getBookingsWithContact(contactId) {
  return async () => {
    UnifiedInboxApi.getBookingsWithContactRoute(contactId)
      .then((response) => {
        // const bookingsDetails = Object.values(response.data);
        // const bookingsId = Object.keys(response.data);
        // const bookings = bookingsDetails.map(
        //   (booking, index) => ({ ...booking, booking_id: bookingsId[index] }),
        // );
        // dispatch(slice.actions.setBookings(bookings));
        // if (bookings.length === 1) dispatch(slice.actions.selectBooking(bookings[0]));
        dispatch(slice.actions.setBookings(response.data));
        if (response.data.length === 1) dispatch(slice.actions.selectBooking(response.data[0]));
      })
      .catch();
  };
}

export function getBookings(page, bookingCode, loadMore) {
  return async () => {
    if (loadMore) dispatch(slice.actions.setBookingsPageIsLoadingStatus(true));
    UnifiedInboxApi.getBookingsWithCodeRoute(page, bookingCode)
      .then((response) => {
        dispatch(slice.actions.setBookings(response.data));
        dispatch(slice.actions.nextBookingsPage());
        if (loadMore) dispatch(slice.actions.setBookingsPageIsLoadingStatus(false));
        if (!response.data) {
          dispatch(slice.actions.setBookingNotFound(true));
        } else {
          dispatch(slice.actions.setBookingNotFound(false));
        }
      })
      .catch();
  };
}
export function getContactWithBooking(bookingId) {
  return async () => {
    UnifiedInboxApi.getContactWithBookingRoute(bookingId)
      .then((response) => {
        dispatch(slice.actions.setContacts(response.data));
        dispatch(slice.actions.selectContact(response.data[0]));
      })
      .catch();
  };
}

export function createUrlOfBooking(selectedBooking) {
  return async () => {
    UnifiedInboxApi.getConversationWithBookingRoute(selectedBooking.booking_id)
      .then((response) => {
        const { folder } = response.data;
        const conversationId = response.data.conversation_id;
        if (conversationId && folder) {
          const { href } = window.location;
          const urlConversationId = `${conversationId}_${folder}`;
          window.location.href = `${href.split('#')[0]}#${urlConversationId}`;
          dispatch(slice.actions.setConversationsFilter(folder));
          dispatch(slice.actions.setSearch(''));
          dispatch(getConversations(false, 0, false, false, true, folder, '', urlConversationId));
          dispatch(slice.actions.setShouldScrollToConversation(true));
          dispatch(slice.actions.setNewConversationType(''));
          dispatch(slice.actions.clearBookingForms());
        }
      })
      .catch();
  };
}

export function setEmails(inputindex, inputValue) {
  return async () => {
    const { unifiedInbox: state } = store.getState();
    const { emails } = state.form.contact;
    const emailsInstance = [...emails]; 
    emailsInstance.splice(inputindex, 1, inputValue);
    dispatch(slice.actions.setContactEmails(emailsInstance));
  };
}
export function removeEmail(inputindex) {
  return async () => {
    const { unifiedInbox: state } = store.getState();
    const { emails } = state.form.contact;
    const emailsInstance = [...emails]; 
    emailsInstance.splice(inputindex, 1);
    dispatch(slice.actions.setContactEmails(emailsInstance));
  };
}
export function setMobilePhones(inputindex, inputValue) {
  return async () => {
    const { unifiedInbox: state } = store.getState();
    const { mobilePhones } = state.form.contact;
    const mobilePhonesInstance = [...mobilePhones];
    mobilePhonesInstance.splice(inputindex, 1, inputValue);
    dispatch(slice.actions.setContactMobilePhones(mobilePhonesInstance));
  };
}
export function removePhone(inputindex) {
  return async () => {
    const { unifiedInbox: state } = store.getState();
    const { mobilePhones } = state.form.contact;
    const mobilePhonesInstance = [...mobilePhones]; 
    mobilePhonesInstance.splice(inputindex, 1);
    dispatch(slice.actions.setContactMobilePhones(mobilePhonesInstance));
  };
}

export function getOrganizationContacts(page, key, loadMore, withBooking) {
  return async () => {
    if (loadMore) dispatch(slice.actions.setContactsPageIsLoadingStatus(true));
    UnifiedInboxApi.getContactsOfOrganizationRoute(page, key, withBooking)
      .then((response) => {
        dispatch(slice.actions.setContacts(Object.values(response.data)));
        dispatch(slice.actions.nextContactsPage());
        if (loadMore) dispatch(slice.actions.setContactsPageIsLoadingStatus(false));
        if (!response.data) {
          dispatch(slice.actions.setContactNotFound(true));
        } else {
          dispatch(slice.actions.setContactNotFound(false));
        }
      })
      .catch();
  };
}
export function createNewConversation(bookingId, contactId) {
  return async () => {
    UnifiedInboxApi.createNewConversationRoute({
      booking_id: bookingId,
      contact_id: contactId,
    })
      .then((response) => {
        if (response.data.conversation_id) {
          const { href } = window.location;
          const urlConversationId = `${response.data.conversation_id}_allConversations`;
          window.location.href = `${href.split('#')[0]}#${urlConversationId}`;
          dispatch(slice.actions.setConversationsFilter('allConversations'));
          dispatch(slice.actions.setSearch(''));
          dispatch(getConversations(false, 0, false, false, true, 'allConversations', '', urlConversationId));
          dispatch(slice.actions.setShouldScrollToConversation(true));
          dispatch(slice.actions.setNewConversationType(''));
          dispatch(slice.actions.clearBookingForms());
          dispatch(slice.actions.setIsOpen(true));
        }
      })
      .catch()
      .finally(() => dispatch(slice.actions.clearForms()));
  };
}
export function provideContactAndCreateConversation(contact, isContactForm) {
  return async () => {
    const { unifiedInbox: state } = store.getState();
    const phones = contact.phones.length !== 0
      ? contact.phones.filter((phone, index) => phone && contact.phones.indexOf(phone) === index)
      : null;
    const emails = contact.emails.length !== 0
      ? contact.emails.filter((email, index) => email && contact.emails.indexOf(email) === index)
      : null;
    const mobilePhones = contact.mobilePhones.length !== 0
      ? contact.mobilePhones.filter((mobilePhone) => mobilePhone)
      : null;
    UnifiedInboxApi.createAndUpdateContactRoute({
      contact_id: contact.id,
      first_name: contact.firstName || null,
      middle_name: contact.middleName || null,
      last_name: contact.lastName || null,
      phones,
      mobile_phones: mobilePhones,
      emails,
    })
      .then((res) => {
        dispatch(createNewConversation(null, res.data.contact_id));
        if (isContactForm) {
          if (state.form.newAddedDetailType === 'Email') {
            dispatch(slice.actions.setMessageType('Email'));
            if (emails.length > 0) {
              dispatch(slice.actions.setDefaultEmail(emails[emails.length - 1]));
            }
          } else if (state.form.newAddedDetailType === 'Phone') {
            dispatch(slice.actions.setMessageType('SMS'));
            if (mobilePhones.length > 0) {
              dispatch(slice.actions.setDefaultPhone(mobilePhones[mobilePhones.length - 1]));
            }
          }
        } else {
          dispatch(slice.actions.setMessageType(emails.length > 0 ? 'Email' : 'SMS'));
          if (emails.length > 0) {
            dispatch(slice.actions.setDefaultEmail(emails[emails.length - 1]));
          }
          if (mobilePhones.length > 0) {
            dispatch(slice.actions.setDefaultPhone(mobilePhones[mobilePhones.length - 1]));
          }
        }
      })
      .catch()
      .finally(() => dispatch(slice.actions.clearForms()));
  };
}
export function updateContact(contact) {
  return async () => {
    const { unifiedInbox: state } = store.getState();
    const phones = contact.phones.length !== 0
      ? contact.phones.filter((phone, index) => phone && contact.phones.indexOf(phone) === index)
      : null;
    const emails = contact.emails.length !== 0
      ? contact.emails.filter((email, index) => email && contact.emails.indexOf(email) === index)
      : null;
    const mobilePhones = contact.mobilePhones.length !== 0
      ? contact.mobilePhones.filter((mobilePhone) => mobilePhone)
      : null;
    UnifiedInboxApi.createAndUpdateContactRoute({
      contact_id: contact.id,
      first_name: contact.firstName || null,
      middle_name: contact.middleName || null,
      last_name: contact.lastName || null,
      phones,
      mobile_phones: mobilePhones,
      emails,
    })
      .then(() => {
        const contactData = {
          id: contact.id,
          first_name: contact.firstName,
          middle_name: contact.middleName,
          last_name: contact.lastName,
          emails: emails.map((detail) => ({
            email: detail,
            primary: false,
          })),
          phones: mobilePhones.map((detail) => ({
            phone: detail,
            primary: false,
          })),
        };
        const contactFullname = contactData.first_name || contactData.last_name
          ? `${contactData.first_name ? contactData.first_name : ''} ${contactData.last_name ? contactData.last_name : ''}`
          : `${contactData?.emails?.[0]?.email || contactData?.phones?.[0]?.phone || ' '}`;
        dispatch(setContactOfSelectedConversation({
          contact: contactData,
          contactFullname,
          conversationId: state.conversation.selectedConversation.new_conversation_id,
        }));
        if (state.form.newAddedDetailType === 'Email') {
          dispatch(slice.actions.setMessageType('Email'));
          if (emails.length > 0) {
            dispatch(slice.actions.setDefaultEmail(emails[emails.length - 1]));
          }
        } else if (state.form.newAddedDetailType === 'Phone') {
          dispatch(slice.actions.setMessageType('SMS'));
          if (mobilePhones.length > 0) {
            dispatch(slice.actions.setDefaultPhone(mobilePhones[mobilePhones.length - 1]));
          }
        }
      })
      .catch(() => (null))
      .finally(() => dispatch(slice.actions.clearForms()));
  };
}
