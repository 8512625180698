// icons
import { Icon, IconifyIcon } from '@iconify/react';
// @mui
import { Box, BoxProps, SxProps, Theme } from '@mui/material';

// ----------------------------------------------------------------------

export interface IconifyProps extends BoxProps {
  sx?: SxProps<Theme>;
  icon?: IconifyIcon | string;
}

export default function Iconify({ icon, sx, ...other }: IconifyProps) {
  return <Box component={Icon} icon={icon} sx={sx} {...other} />;
}
