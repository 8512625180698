import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Button, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { fYearMonthDay } from 'src/utils/formatTime';
import {
  getDateTime,
  getLeaveStatusConfig,
  hasAdminModeAccess,
  hasLeaveEditAccess,
  hasStaffModeAccess
} from 'src/utils/leaves';
import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import { LeaveDataGridColumn, Leave } from 'src/@types/leaves';
import { dispatch, useSelector } from 'src/redux/store';
import {
  cancelLeave,
  restoreLeave,
  setLeaveCancelDialogOpen,
  setLeaveCreateEditDrawerOpen,
  setLeaveViewDrawerOpen,
} from 'src/redux/slices/leaves';
import DialogBox from 'src/components/dialog-box/DialogBox';
import CardAction, { ActionType } from 'src/components/card/CardAction';
import CustomRenderCell from 'src/components/data-grid/CustomRenderCell';
import { DateCondition } from 'src/sections/@dashboard/guest/data-grid/DateCustomAccordionFilter';

const DEFAULT_COLUMN_CONFIG = {
  editable: false,
  sortable: false,
  disableColumnMenu: true,
};

const RowActions = ({ activeLeave }: { activeLeave: Leave; }) => {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales(['leaves', 'common']);
  const { leaves } = useSelector((state) => state.leaves);
  const { leaveCancel } = useSelector((state) => state.leaves.dialogs);
  const leaveIndex = leaves.list.findIndex(leave => leave.id === activeLeave.id);
  const [restoring, setRestoring] = useState(false);

  const handleRestore = async () => {
    try {
      setRestoring(true);
      await dispatch(restoreLeave(activeLeave.id));
      enqueueSnackbar(translate('leave_restored_successfully'));
      setRestoring(false);
    } catch (error) {
      console.error(error);
      setRestoring(false);
    }
  };

  const handleCancel = () => {
    if (leaveCancel.activeLeaveId) {
      dispatch(cancelLeave(leaveCancel.activeLeaveId));
    }
  };

  const ACTIONS: ActionType[] = [
    {
      type: 'more',
      label: translate('view'),
      onAction: () => {
        dispatch(setLeaveViewDrawerOpen({ open: true, activeLeave }));
      },
    },
    ...(hasLeaveEditAccess(user) && activeLeave.status === 'Pending' ? [{
      type: 'more',
      label: translate('edit'),
      onAction: () => {
        dispatch(setLeaveCreateEditDrawerOpen({ open: true, defaultValue: activeLeave }));
      },
    }] as ActionType[] : []),
    ...(hasAdminModeAccess(user) && activeLeave.status !== 'Pending' ? [{
      type: 'more',
      label: translate('restore_leave'),
      onAction: handleRestore,
    }] as ActionType[] : []),
    ...(hasStaffModeAccess(user) && !hasAdminModeAccess(user) && activeLeave.status === 'Pending' ? [{
      type: 'more',
      label: translate('cancel_request'),
      onAction: () => {
        dispatch(setLeaveCancelDialogOpen({
          open: true,
          activeLeaveId: activeLeave.id
        }))
      },
    }] as ActionType[] : []),
  ];

  return (
    <>
      <CardAction
        mode="collapsed"
        actions={ACTIONS}
        loading={Boolean(
          restoring &&
          leaveIndex >= 0 &&
          leaves.list[leaveIndex].id === activeLeave.id
        )}
      />

      <DialogBox
        severity="error"
        children={null}
        openState={leaveCancel.open}
        title={`${translate('cancel_request')}?`}
        DialogProps={{
          fullWidth: true,
          onClose: !leaveCancel.loading
            ? () => dispatch(setLeaveCancelDialogOpen({ open: false }))
            : undefined,
        }}
        action={(
          <Stack direction="row" justifyContent="flex-end">
            <Button
              color="inherit"
              disabled={leaveCancel.loading}
              onClick={() => dispatch(setLeaveCancelDialogOpen({ open: false }))}
            >
              {translate('keep_request')}
            </Button>
            <LoadingButton color="error" loading={leaveCancel.loading} onClick={handleCancel}>
              {translate('cancel')}
            </LoadingButton>
          </Stack>
        )}
      />
    </>
  );
};

export const LEAVE_LIST_COLUMNS = (): LeaveDataGridColumn[] => {
  const { user } = useAuth();
  const { translate, shortDateFormat, timeFormat, currentLang } = useLocales(['leaves', 'common']);

  return [
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'id',
      headerName: translate('id'),
      type: 'number'
    },
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'staff',
      headerName: translate('staff'),
      width: 160,
      renderCell: (params: GridRenderCellParams<any, Leave['staff']>) => {
        if (params.value) {
          const { fullName, role } = params.value;
          return (
            <CustomRenderCell
              disabledTooltip
              title={fullName}
              subTitle={role}
              titleProps={{ width: 'inherit' }}
              subtitleProps={{ width: 'inherit' }}
            />
          );
        } else {
          return <CustomRenderCell disabledTooltip title={undefined}/>;
        }
      },
    },
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'duration',
      headerName: translate('duration'),
      width: 170,
      renderCell: (params: GridRenderCellParams<any, Leave['duration'] & Leave['fullDay']>) => {
        if (params.row) {
          const { duration, fullDay } = params.row;
          return (
            <CustomRenderCell
              disabledTooltip
              title={`
                ${getDateTime(new Date(duration.from), currentLang.dateLocale, shortDateFormat)} - 
                ${getDateTime(new Date(duration.to), currentLang.dateLocale, shortDateFormat)}
              `}
              subTitle={!Boolean(fullDay) ? `
                ${getDateTime(new Date(duration.from), currentLang.dateLocale, timeFormat)} - 
                ${getDateTime(new Date(duration.to), currentLang.dateLocale, timeFormat)}
              ` : undefined}
            />
          );
        }
      },
    },
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'category',
      headerName: translate('category'),
      width: 160,
      renderCell: (params: GridRenderCellParams<any, Leave['category']>) => {
        if (params.value) {
          return (
            <CustomRenderCell
              disabledTooltip
              title={params.value}
              titleProps={{ width: 'inherit' }}
            />
          );
        }
      },
    },
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'explanation',
      headerName: translate('explanation'),
      minWidth: 160,
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, Leave['explanation']>) => (
        <CustomRenderCell title={params.value ?? undefined} titleProps={{ width: 'inherit' }}/>
      ),
    },
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'status',
      headerName: translate('status'),
      width: 140,
      renderCell: (params: GridRenderCellParams<any, Leave['status']>) => {
        if (params.value) {
          return (
            <CustomRenderCell
              disabledTooltip
              title={translate(getLeaveStatusConfig(params.value, user).label)}
              titleProps={{
                letterSpacing: 1,
                variant: 'overline',
                color: getLeaveStatusConfig(params.value, user).color,
              }}
            />
          );
        }
      },
    },
    {
      ...DEFAULT_COLUMN_CONFIG,
      field: 'action',
      headerName: translate('action'),
      width: 80,
      align: 'center',
      headerAlign: 'center',
      hideable: false,
      disableReorder: true,
      renderCell: (params) => {
        const selectedRow = params.row;
        return <RowActions activeLeave={selectedRow}/>;
      },
    },
  ];
};

export const LEAVE_LIST_ROWS = (leaves: Leave[]): Leave[] => leaves;

export const getStartDateSearchParams = (
  value: { condition: DateCondition; start: Date; end: Date | null; }
): { [key: string]: string | null } => {
  const { condition, start, end } = value;
  const startValue = `${fYearMonthDay(start)}T00:00:00`;
  const endValue = end ? `${fYearMonthDay(end)}T23:59:59` : null;
  switch (condition) {
    case 'between':
      return {
        'from[after]': startValue,
        'from[before]': endValue,
      };
    case 'later_than':
      return { 'from[strictly_after]': startValue };
    case 'earlier_than':
      return { 'from[strictly_before]': startValue };
    case 'equal':
      return {
        'from[after]': startValue,
        'from[before]': `${fYearMonthDay(start)}T23:59:59`,
      };
    default:
      return {};
  }
};

export const getEndDateSearchParams = (
  value: { condition: DateCondition; start: Date; end: Date | null; }
): { [key: string]: string | null } => {
  const { condition, start, end } = value;
  const startValue = `${fYearMonthDay(start)}T00:00:00`;
  const endValue = end ? `${fYearMonthDay(end)}T23:59:59` : null;
  switch (condition) {
    case 'between':
      return {
        'to[after]': startValue,
        'to[before]': endValue,
      };
    case 'later_than':
      return { 'to[strictly_after]': startValue };
    case 'earlier_than':
      return { 'to[strictly_before]': startValue };
    case 'equal':
      return {
        'to[after]': startValue,
        'to[before]': `${fYearMonthDay(start)}T23:59:59`,
      };
    default:
      return {};
  }
};

