import { Box, Button, Divider, Popover, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import InputStepper from "src/components/InputStepper";
import { alpha, styled, useTheme } from "@mui/material/styles";
import Iconify from "../../../../../components/Iconify";
import useLocales from "../../../../../hooks/useLocales";

const GuestFieldBox = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isOpen"
})<{ isOpen: boolean, error: boolean }>(({ theme, isOpen, error }) => ({
  width: "100%",
  padding: theme.spacing(1.5, 2),
  border: `1px solid ${isOpen ? error ? theme.palette.error.main : theme.palette.primary.main : error ? theme.palette.error.main : alpha(theme.palette.common.black, 0.1)}`,
  borderRadius: 8,
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.common.white,
    borderColor: error ? theme.palette.error.dark : theme.palette.grey[700],

  },
}));

const GuestFieldText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  letterSpacing: 0.5,
  color: theme.palette.text.primary,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

const DropDownBox = styled(Stack)(({ theme }) => ({
  flexDirection: "column",
  padding: theme.spacing(1.5, 3),
  backgroundColor: theme.palette.common.white,
  boxShadow: `0px 24px 48px ${alpha(theme.palette.grey["500"], 0.20)}`,
  borderRadius: 16,
}));

const DropDownItem = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  padding: theme.spacing(1.5, 0),
}));

const DropDownTypo = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle1,
  color: theme.palette.common.black,
  fontWeight: 400,
  letterSpacing: 0.15,
}));

const DropDownDivider = styled(Divider)(({ theme }) => ({
  paddingTop: 12,
  marginBottom: 12
}));

interface Params {
  onChange: Function;
  adults?: number;
  children?: number;
  units?: number;
  error?: boolean;
}

export default function GuestsField({ onChange, adults = 1, children = 0, units = 1, error = false }: Params) {
  const theme = useTheme();
  const { translate } = useLocales('common');
  const [guestsNum, setGuestsNum] = useState(adults);
  const [childrenNum, setChildrenNum] = useState(children);
  const [unitsNum, setUnitsNum] = useState(units);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = anchorEl !== null;
  const width = 280;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  //
  useEffect(() => {
    onChange(guestsNum, childrenNum, unitsNum);
  }, [guestsNum, childrenNum, unitsNum]);
  return (
    <Box width={width}>
      <GuestFieldBox isOpen={open} onClick={handleClick} error={error}>
        <GuestFieldText>
          {guestsNum}
          {
            guestsNum === 1 ?
              ` ${translate("adult")} • ` :
              ` ${translate("adults")} • `
          }
          {childrenNum}
          {
            childrenNum === 1 ?
              ` ${translate("child")} • ` :
              ` ${translate("children")} • `
          }
          {unitsNum}
          {
            unitsNum === 1 ?
              ` ${translate("unit")} ` :
              ` ${translate("units")} `
          }
        </GuestFieldText>
        <Iconify width={20} height={20} icon={open ? "mdi:chevron-up" : "mdi:chevron-down"}
                 color={open ? theme.palette.primary.main : theme.palette.grey["600"]}/>
      </GuestFieldBox>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <DropDownBox width={width}>
          <DropDownItem>
            <DropDownTypo>
              {translate("adults")}
            </DropDownTypo>
            <InputStepper initVal={guestsNum} minValue={0} maxValue={999} onChange={(v) => setGuestsNum(v)}/>
          </DropDownItem>
          <DropDownItem>
            <DropDownTypo>
              {translate("children")}
            </DropDownTypo>
            <InputStepper initVal={childrenNum} minValue={0} maxValue={999} onChange={(v) => setChildrenNum(v)}/>
          </DropDownItem>
          <DropDownDivider/>
          <DropDownItem>
            <DropDownTypo>
              {translate("units")}
            </DropDownTypo>
            <InputStepper initVal={unitsNum} minValue={1} maxValue={999} onChange={(v) => setUnitsNum(v)}/>
          </DropDownItem>
        </DropDownBox>
      </Popover>
    </Box>
  );
};