import { utcToZonedTime } from 'date-fns-tz';
// routes
import { PATH_DASHBOARD } from 'src/routes/paths';
// @types
import { Attachment, Issue, Note, Priority, Status, File, TaskPriority } from 'src/@types/issues';
// utils
import { fDT } from 'src/utils/formatTime';
import { blockedDateNormalizer } from 'src/utils/blockedDates';

export const getDateTime = (date: string, dateLocale: Locale, pattern: string) => (
  fDT({
    date: utcToZonedTime(new Date(date), 'UTC'),
    pattern: pattern,
    options: { locale: dateLocale }
  })
);

export const getStatusConfig = (status: Status) => {
  switch (status) {
    case 'init':
      return { label: 'pending', color: 'error.main' };
    case 'rejected':
      return { label: 'dismissed', color: 'text.disabled' };
    case 'resolved':
    case 'notification sent':
      return { label: 'resolved', color: 'success.main' };
    case 'verified':
    case 'assigned':
      return { label: 'converted', color: 'text.secondary' };
    default:
      return { label: 'unknown', color: 'text.disabled' };
  }
};

export const getPriorityConfig = (priority: Priority) => {
  switch (priority) {
    case 'Urgent':
      return { label: 'urgent', color: 'error.darker' };
    case 'Important':
      return { label: 'important', color: 'error.main' };
    case 'Moderate':
      return { label: 'moderate', color: 'warning.main' };
    case 'Low':
      return { label: 'low', color: 'grey.500' };
    default:
      return { label: 'unknown', color: 'text.disabled' };
  }
};

export const getLocationLink = (hasUnit: boolean, id: number): string => hasUnit
    ? `${PATH_DASHBOARD.listings.unitPage}/${id}/details`
    : `${PATH_DASHBOARD.listings.propertyPage}/${id}/details`;

export const getReporterLink = (reporterId: number, reporterType: string): string => {
  switch (reporterType) {
    case 'owner':
      return `${PATH_DASHBOARD.contacts.investor.view}/${reporterId}`;
    case 'staff':
      return `${PATH_DASHBOARD.contacts.staff.view}/${reporterId}`;
    case 'guest':
      return `${PATH_DASHBOARD.booking.guest}/${reporterId}`;
    default:
      return '';
  }
};

export const getAssigneeConfig = (type: string) => {
  switch (type.toLowerCase()) {
    case 'individuals':
      return { icon: 'ic:round-person-outline', tooltip: 'staff' };
    case 'supplier':
      return { icon: 'ic:round-apartment', tooltip: 'supplier' };
    default:
      return { icon: '', tooltip: '' }
  }
};

export const convertIssuePriorityToTaskPriority = (issuePriority: Priority): TaskPriority => {
  switch (issuePriority) {
    case 'Urgent':
      return 'Urgent';
    case 'Important':
      return 'High';
    case 'Moderate':
      return 'Normal';
    case 'Low':
      return 'Low';
    default:
      return 'Low';
  }
};

export const issueNormalizer = (obj: any): Issue => ({
  id: obj.id,
  description: obj.description,
  issueType: obj.issue_type ? obj.issue_type.name : null,
  priority: obj.priority,
  status: obj.status,
  blockedDate: obj.blocked_date ? blockedDateNormalizer(obj.blocked_date) : null,
  maintenanceTask: obj.maintenance_task ? {
    id: obj.maintenance_task.id,
    status: obj.maintenance_task.status,
  } : null,
  reportedBy: {
    id: obj.reported_by.id ?? 0,
    fullName: obj.reported_by.full_name ?? '',
    type: obj.reported_by.type ?? '',
  },
  relatedTaskId: obj.housekeeping ? obj.housekeeping.task_id : null,
  property: {
    id: obj.property.id,
    name: obj.property.name,
    shortName: obj.property.short_name,
    unitCount: obj.property.unit_count,
  },
  unitType: obj.unit_type ?? null,
  unit: obj.unit ?? null,
  attachments: obj.attachments
    ? obj.attachments.map((attachment: any) => attachmentNormalizer(attachment))
    : [],
  notes: obj.notes
    ? obj.notes.map((note: any) => noteNormalizer(note))
    : [],
  createdAt: obj.created_at,
  updatedAt: obj.updated_at,
  dismissedAt: obj.dismissed_at ?? null,
  resolvedAt: obj.resolved_at ?? null,
});

export const attachmentNormalizer = (obj: any): Attachment => ({
  attachmentId: obj.attachment_id,
  fileId: obj.file_id,
  fileName: obj.file_name,
  originalName: obj.original_name,
  mimeType: obj.mime_type,
  extension: obj.extension,
  fileSize: obj.file_size,
  owner: obj.owner,
  createdAt: obj.created_at,
});

export const fileNormalizer = (obj: any): File => ({
  fileId: obj.file_id,
  fileName: obj.file_name,
  fileSize: obj.file_size,
  originalName: obj.original_name,
});

export const noteNormalizer = (obj: any): Note => ({
  id: obj.id,
  message: obj.message,
  createdAt: obj.created_at,
  files: obj.files && obj.files.length > 0
    ? obj.files.map((file: any) => fileNormalizer(file))
    : []
});