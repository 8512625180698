import { 
  format, 
  getTime, 
  formatDistanceToNow,
  addMilliseconds,
  fromUnixTime,
  intervalToDuration,
  getUnixTime,
  isThisYear,
  startOfYear,
  endOfYear,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  differenceInDays,
  startOfDay,
  parse,
} from 'date-fns';
import { getTimezoneOffset, utcToZonedTime } from 'date-fns-tz';

// ----------------------------------------------------------------------
const defaultTimeZone = "UTC";
// ----------------------------------------------------------------------
type DynamicDatePattern = {
  minutePattern?: string;
  minutesPattern?: string;
  hourPattern?: string;
  hoursPattern?: string;
  dayPattern?: string;
  daysPattern?: string;
  monthPattern?: string;
  monthsPattern?: string;
  yearPattern?: string;
  yearsPattern?: string;
};

type FormatDateParams = {
  date: Date | string | number; 
  pattern?: string;
  options?: {
    locale?: Locale;
  } 
}

type DatePattern = {
  thisYearFormat: string; 
  format: string;
}

type FormatDateTimeParams = {
  date: Date | string | number; 
  pattern?: DatePattern | string;
  options?: {
    locale?: Locale;
  } 
}

function isDatePattern(pattern: DatePattern | string): pattern is DatePattern {
  return (<DatePattern>pattern).thisYearFormat !== undefined && (<DatePattern>pattern).format !== undefined;
}

// ----------------------------------------------------------------------

export function fDate(date: Date | string | number, pattern = 'dd MMMM yyyy') {
  return format(new Date(date), pattern);
}

export function fDateLocale(params: FormatDateParams) {
  return format(new Date(params.date), params.pattern ?? 'dd MMMM yyyy', params.options);
}

export function fDT(params: FormatDateTimeParams) {
  let pattern = params.pattern ?? 'dd MMMM yyyy'
  if (isDatePattern(pattern)) {
    const p = (<DatePattern>pattern)
    pattern = isThisYear(new Date(params.date)) ? p.thisYearFormat : p.format;
  }
  return format(new Date(params.date), pattern, params.options);
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fTimestamp(date: Date | string | number, inMillisecond = true) {
  return inMillisecond ? getTime(new Date(date)) : getUnixTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fDateSeparated(date: Date | string | number, separator = '/') {
  return format(new Date(date), `dd${separator}MM${separator}yyyy`);
}

export function fYearMonthDay(date: Date | string | number, separator = '-') {
  return format(new Date(date), `yyyy${separator}MM${separator}dd`);
}

export function timestampToDate(timestamp: number) {
  return fromUnixTime(timestamp);
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function fIndexToWeekDay(index: number | string) {
  const weekDays = ['sunday', 'monday', 'tuesday',  'wednesday',  'thursday',  'friday',  'saturday'];
  return weekDays[index];
};

export function fDateToUTC(date: Date | number, timeZone: string | null = null) {
  if (!timeZone) {
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  return addMilliseconds(date, getTimezoneOffset(timeZone, date));
}

export function fIntervalToEstimation(interval: number, type: 'short' | 'half' | 'full' = 'short') {
  const duration = intervalToDuration({ start: 0, end: interval });
  const units = Object.keys(duration);
  const estimations = units.filter((unit) => duration[unit]).map((unit) => {
    switch (type) {
      case 'full': 
        return `${duration[unit]} ${duration[unit] === 1 ? unit.slice(0, -1) : unit}`;
      case 'half': 
        return `${duration[unit]} ${unit.slice(0, 3)}`;
      case 'short': 
        return `${duration[unit]}${unit.slice(0, 1)}`;
    }
  });
  return estimations.join(' ');
}

export function getYearDynamicPattern(date: Date | string | number, currentPattern = "dd MMM", yearPattern = "MMM dd, yyyy") {
  const start = startOfYear(new Date());
  const end = endOfYear(new Date());
  const dateIsThisYear = isAfter(new Date(date), start) && isBefore(new Date(date), end);
  return dateIsThisYear ? currentPattern : yearPattern;
};

export function fDateDynamically(date: Date | string | number, patterns?: DynamicDatePattern) {
  const defaultPatterns = {
    minutePattern: 'HH:mm',
    minutesPattern: 'HH:mm',
    hourPattern: 'HH:mm',
    hoursPattern: 'HH:mm',
    dayPattern: 'dd MMM',
    daysPattern: 'dd MMM',
    monthPattern: 'dd MMM',
    monthsPattern: 'dd MMM',
    yearPattern: 'dd/MM/yyyy',
    yearsPattern: 'dd/MM/yyyy',
  };
  const { minutesPattern, minutePattern, hoursPattern, hourPattern, dayPattern, daysPattern, monthPattern, monthsPattern, yearPattern, yearsPattern } = { ...defaultPatterns, ...patterns };
  const variant = fToNow(date);
  if (variant.includes('minutes')) return format(new Date(date), minutesPattern);
  if (variant.includes('minute')) return format(new Date(date), minutePattern);
  if (variant.includes('hours')) return format(new Date(date), hoursPattern);
  if (variant.includes('hour')) return format(new Date(date), hourPattern);
  if (variant.includes('days')) return format(new Date(date), daysPattern);
  if (variant.includes('day')) return format(new Date(date), dayPattern);
  if (variant.includes('months')) return format(new Date(date), monthsPattern);
  if (variant.includes('month')) return format(new Date(date), monthPattern);
  if (variant.includes('years')) return format(new Date(date), yearsPattern);
  if (variant.includes('year')) return format(new Date(date), yearPattern);
  return date;
}

export function isDateForToday(date: Date | string | number, timeZone?: string | null) {
  return isSameDay(utcToZonedTime(new Date(date), timeZone ?? defaultTimeZone), utcToZonedTime(new Date(), timeZone ?? defaultTimeZone));
}

export function isDateForYesterday(date: Date | string | number, timeZone?: string | null) {
  return differenceInDays(utcToZonedTime(new Date(), timeZone ?? defaultTimeZone), startOfDay(utcToZonedTime(new Date(date), timeZone ?? defaultTimeZone))) === 1;
}

export function compareTimeStrings(timeFrameFrom: string, timeFrameTo: string, timePattern: string = 'hh:mm a') {
  const parseTime = (time: string) => parse(time, timePattern, new Date());
  const fromTime = parseTime(timeFrameFrom);
  const toTime = parseTime(timeFrameTo);
  if (isAfter(fromTime, toTime)) return 1;
  if (isEqual(fromTime, toTime)) return 0;
  return -1;
}