// api
import IssueApi from 'src/api/issues';
// redux
import { dispatch } from 'src/redux/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// @types
import { SortDirection } from 'src/@types/booking-list';
import { Issue, IssuesState, Status } from 'src/@types/issues';
// utils
import { issueNormalizer } from 'src/utils/issues';

const initialState: IssuesState = {
  loading: false,
  issues: {
    list: [],
    rowCount: 0,
    gridConfigs: null,
  },
  drawers: {
    viewIssue: {
      isOpen: false,
      isLoading: false,
      activeIssue: null,
    },
    viewBlockedDate: {
      isOpen: false,
      defaultValue: null,
    },
    createMaintenanceTask: {
      isOpen: false,
      defaultValue: null,
    },
  },
  dialogs: {
    dismiss: {
      isOpen: false,
      activeIssueId: null,
    },
    resolve: {
      isOpen: false,
      activeIssueId: null,
    },
  },
};

const slice = createSlice({
  name: 'issues',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    setStatus(state, action: PayloadAction<{ issueId: number, status: Status }>) {
      const foundIssueIndex = state.issues.list.findIndex(issue => issue.id === action.payload.issueId);
      if (foundIssueIndex >= 0) {
        state.issues.list[foundIssueIndex].status = action.payload.status;
      }
      if (state.drawers.viewIssue.activeIssue) {
        state.drawers.viewIssue.activeIssue.status = action.payload.status;
      }
    },

    setViewIssueDrawerOpen(
      state,
      action: PayloadAction<{
        open: boolean,
        activeIssue?: Issue | null,
        activeIssueId?: number,
        loading?: boolean
      }>
    ) {
      const { open, activeIssue, activeIssueId, loading } = action.payload;
      state.drawers.viewIssue.isOpen = open;
      state.drawers.viewIssue.isLoading = loading ?? false;
      if (typeof activeIssue !== 'undefined') {
        state.drawers.viewIssue.activeIssue = activeIssue;
      }
      if (activeIssueId && state.drawers.viewIssue.activeIssue) {
        state.drawers.viewIssue.activeIssue.id = activeIssueId;
      }
    },

    setDismissDialogOpen(state, action: PayloadAction<{ open: boolean, activeIssueId: number | null }>) {
      state.dialogs.dismiss.isOpen = action.payload.open;
      state.dialogs.dismiss.activeIssueId = action.payload.activeIssueId;
    },

    setResolveDialogOpen(state, action: PayloadAction<{ open: boolean, activeIssueId: number | null }>) {
      state.dialogs.resolve.isOpen = action.payload.open;
      state.dialogs.resolve.activeIssueId = action.payload.activeIssueId;
    },

    setIssueGridConfigs(state, action) {
      state.issues.gridConfigs = action.payload;
    },

    setRowCount(state, action) {
      state.issues.rowCount = action.payload;
    },

    setIssueList(state, action: PayloadAction<Issue[]>) {
      state.loading = false;
      state.issues.list = action.payload;
    },

    setViewBlockedDateDrawerOpen(state, action: PayloadAction<{open: boolean, defaultValue: any}>) {
      state.drawers.viewBlockedDate.isOpen = action.payload.open;
      state.drawers.viewBlockedDate.defaultValue = action.payload.defaultValue;
    },

    updateIssue(state, action: PayloadAction<Partial<Issue>>) {
      if (state.drawers.viewIssue.activeIssue) {
        state.drawers.viewIssue.activeIssue = {
          ...state.drawers.viewIssue.activeIssue,
          ...action.payload
        };
      }
    },

    archiveBlockedDate(state) {
      if (state.drawers.viewIssue.activeIssue && state.drawers.viewIssue.activeIssue.blockedDate) {
        state.drawers.viewIssue.activeIssue.blockedDate.isActive = false;
      }
    },

    setCreateMaintenanceTaskDrawerOpen(state, action: PayloadAction<{open: boolean, defaultValue: any}>) {
      state.drawers.createMaintenanceTask.isOpen = action.payload.open;
      state.drawers.createMaintenanceTask.defaultValue = action.payload.defaultValue;
    },
  },
});

// reducer
export default slice.reducer;

// actions
export const {
  setStatus,
  setViewIssueDrawerOpen,
  setViewBlockedDateDrawerOpen,
  setDismissDialogOpen,
  setResolveDialogOpen,
  setIssueGridConfigs,
  setRowCount,
  updateIssue,
  archiveBlockedDate,
  setCreateMaintenanceTaskDrawerOpen
} = slice.actions;

export function getIssueList({
                               page,
                               limit,
                               filtersState,
                               order,
                               signal,
                               advanceFilterParam,
                             }: {
  page: number;
  limit: number;
  filtersState: {};
  order?: { field: string; sort: SortDirection };
  signal?: AbortSignal;
  advanceFilterParam?: { key: string; value: string };
}) {
  return async () => {
    dispatch(slice.actions.setLoading(true));
    try {
      const response = await IssueApi.getIssueList({
        page,
        limit,
        filtersState,
        order,
        signal,
        advanceFilterParam,
      });
      dispatch(slice.actions.setIssueList(response.data.data.map((issue: any) => issueNormalizer(issue))));
      dispatch(slice.actions.setRowCount(response.data.meta.total_items));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };
}

export function addMaintenanceTask(
  requestBody: {
    issueId: number | null,
    propertyId: number,
    type: string,
    description: string,
    attachmentFileIds: string,
    assigneeId: number | null,
    priority: string,
    additionalNote: string,
    canStartFrom: string | null,
    mustFinishBy: string | null,
    unitId?: number,
  },
  setLoading: (succeed: boolean) => void,
  callback: (response: any, succeed: boolean) => void,
) {
  return async () => {
    setLoading(true);
    try {
      const response = await IssueApi.createMaintenanceTask(
        requestBody.issueId,
        requestBody.propertyId,
        requestBody.type,
        requestBody.description,
        requestBody.attachmentFileIds,
        requestBody.assigneeId,
        requestBody.priority,
        requestBody.additionalNote,
        requestBody.canStartFrom,
        requestBody.mustFinishBy,
        requestBody.unitId,
      );
      callback(response, true);
    } catch (error) {
      callback(error, false);
    } finally {
      setLoading(false);
    }
  };
}