import axios from "src/utils/axios";
import { FilterValue } from "src/@types/filter";

function monthlyProfitReportAPI() {
  return {
    fetchHostHeaderInfo: (mprId: number) => axios.get('/apis/monthly_profit_report/get_header_info', {
        params: {
          mpr_id: mprId
        }
      }),
    fetchAddonData: (mprId: number, subcategory: string) => axios.get('/apis/monthly_profit_report/get_addons', {
        params: {
          mpr_id: mprId,
          subcategory: subcategory
        }
      }),
    fetchInvestorAddonData: (mprId: number, subcategory: string) => axios.get('/apis/investor_profit_report/get_addons', {
        params: {
          mpr_id: mprId,
          subcategory: subcategory
        }
      }),
    fetchExpenses: (mprId: number) => axios.get('/apis/monthly_profit_report/get_expenses', {
        params: {
          mpr_id: mprId
        }
      }),
    fetchInvestorExpenses: (mprId: number) => axios.get('/apis/investor_profit_report/get_expenses', {
        params: {
          mpr_id: mprId
        }
      }),
    fetchTaxes: (mprId: number) => axios.get('/apis/monthly_profit_report/get_taxes', {
        params: {
          mpr_id: mprId
        }
      }),
    fetchInvestorTaxes: (mprId: number) => axios.get('/apis/investor_profit_report/get_taxes', {
        params: {
          mpr_id: mprId
        }
      }),
    fetchBookings: ({ mprId, page, limit, filtersState, advanceFilterParams }: {
      mprId: string | number,
      page: number,
      limit: number,
      filtersState: {
        bookingStatus?: FilterValue,
        arrivalDate?: {
          from?: string;
          to?: string;
        },
        departureDate?: {
          from?: string;
          to?: string;
        },
        channelName?: FilterValue
      }
      advanceFilterParams?: { key: string, value: string }
    }) => {
      const url = '/apis/monthly_profit_report/get_bookings';
      return axios.get(url, {
        params: {
          mpr_id: mprId,
          page,
          limit,
          ...(advanceFilterParams && { [`${advanceFilterParams.key}`]: advanceFilterParams.value }),
          arrivalDateAfter: filtersState.arrivalDate ? filtersState.arrivalDate?.from : undefined,
          arrivalDateBefore: filtersState.arrivalDate ? filtersState.arrivalDate?.to : undefined,
          departureDateAfter: filtersState.departureDate ? filtersState.departureDate?.from : undefined,
          departureDateBefore: filtersState.departureDate ? filtersState.departureDate?.to : undefined,
          bookingStatus: filtersState.bookingStatus ? filtersState.bookingStatus?.value : undefined,
          channelName: filtersState.channelName ? filtersState.channelName?.value : undefined,
        }
      });
    },
    fetchAddonDetails: (month: number | string, year: number | string, dealId: number | null, costElementId: number) => {
      const url = '/apis/monthly_profit_report/get_addon_detail';
      return axios.get(url, {
        params: {
          month: month,
          year: year,
          dealID: dealId,
          costElementID: costElementId
        }
      });
    },
    fetchExpenseDetails: (
      month: number | string,
      year: number | string,
      dealId: number | null,
      costElementId: number,
      addonId: number,
      page: number,
      limit: number
    ) => {
      const url = '/apis/monthly_profit_report/get_expense_detail';
      return axios.get(url, {
        params: {
          month: month,
          year: year,
          dealID: dealId,
          costElementID: costElementId,
          addonID: addonId,
          page: page,
          limit: limit
        }
      });
    },
    fetchInvestorHeaderInfo: (mprId: number) => axios.get('/apis/investor_profit_report/get_header_info', {
        params: {
          mpr_id: mprId
        }
      }),
    getMonthlyProfitReportPDFExport: (mprId: number) => {
      const url = `/apis/monthly_profit_report/export_pdf/${mprId}`;
      return axios.get(url, {
        responseType: 'blob',
        headers: {
          Accept: 'application/octet-stream',
        }
      });
    },
    getInvestorMonthlyProfitReportPDFExport: (mprId: number) => {
      const url = `/apis/investor_profit_report/export_pdf/${mprId}`;
      return axios.get(url, {
        responseType: 'blob',
        headers: {
          Accept: 'application/octet-stream',
        }
      });
    },
    getMonthlyProfitReportCSVExport: (mprId: number, columns: Array<string>) => {
      const url = `/apis/monthly_profit_report/export_csv`;
      const cols = {};
      columns.map(column => cols[column] = true);
      return axios.get(url, {
        params: {
          ReportID: mprId,
          ...cols
        },
        responseType: 'blob',
        headers: {
          Accept: 'application/octet-stream',
        }
      });
    },
    getInvestorMonthlyProfitReportCSVExport: (mprId: number, columns: Array<string>) => {
      const url = `/apis/investor_profit_report/export_csv`;
      const cols = {};
      columns.map(column => cols[column] = true);
      return axios.get(url, {
        params: {
          ReportID: mprId,
          ...cols
        },
        responseType: 'blob',
        headers: {
          Accept: 'application/octet-stream',
        }
      });
    },
    getMonthlyProfitReportExcelExport: (mprId: number, columns: Array<string>) => {
      const url = `/apis/monthly_profit_report/export_excel`;
      const cols = {};
      columns.map(column => cols[column] = true);
      return axios.get(url, {
        params: {
          ReportID: mprId,
          ...cols
        },
        responseType: 'blob',
        headers: {
          Accept: 'application/octet-stream',
        }
      });
    },
    getInvestorMonthlyProfitReportExcelExport: (mprId: number, columns: Array<string>) => {
      const url = `/apis/investor_profit_report/export_excel`;
      const cols = {};
      columns.map(column => cols[column] = true);
      return axios.get(url, {
        params: {
          ReportID: mprId,
          ...cols
        },
        responseType: 'blob',
        headers: {
          Accept: 'application/octet-stream',
        }
      });
    },
    recalculateMpr: (mprId: number) => axios.post('/apis/monthly_profit_report/recalculate', {
        mpr_id: mprId
      }),
  }
}

export default monthlyProfitReportAPI();