import { FC, useState } from "react";
import {
  TextField,
  IconButton,
  InputAdornment,
  TextFieldProps,
} from '@mui/material';
import Iconify from "../Iconify";

const SensitiveInput: FC<TextFieldProps> = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleShowPassword} edge="end">
              <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}/>
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default SensitiveInput;