import * as Yup from 'yup';
import { isArray } from 'lodash';
// @types
import { TFunction } from 'react-i18next';
import {
  Unit,
  Deal,
  InvestorDeal,
  InvestorDealUnit,
  InvestorDealLandlord,
  InvestorDealTemplate,
  InvestorDealMPR,
  InvestorDealMPRQueue,
  DealTemplateLine,
} from 'src/@types/investor-deal';

export const investorDealNormalizer = (obj): InvestorDeal => ({
  id: obj.id,
  title: obj.title,
  units: obj.deal_units.data.map((item) => investorDealUnitNormalizer(item)),
  template: obj.deal_template,
  deal_type: obj.deal_type,
  land_lord: obj.landlord,
  customer_invoice_method: obj.customer_invoice_method,
  income_tax_schema: obj.income_tax_schema,
  expense_tax_schema: obj.expense_tax_schema,
  fee: obj.fee,
  fix_rent: obj.fixed_rent,
  fix_management_fee: obj.fix_management_fee,
  from: obj.from,
  to: obj.to,
  created_at: obj.created_at,
  updated_at: obj.updated_at,
  can_extend: obj.can_extend,
  mprStatus: obj.MPRStatus,
});

export const investorDealUnitNormalizer = (obj): Unit => ({
  id: obj.property_unit.id,
  name: obj.property_unit.name,
});

export const dealNormalizer = (obj): Deal => ({
  id: obj.id,
  title: obj.title,
  description: obj.description ? obj.description : '',
  mprStatus: obj.MPRStatus,
  units: obj.deal_units.map((item) => dealUnitNormalizer(item)),
  landlord: obj.landlord ? dealLandlordNormalizer(obj.landlord) : null,
  dealTemplate: obj.deal_template ? dealTemplateNormalizer(obj.deal_template) : null,
  extendedFrom: obj.extended_from ? dealNormalizer(obj.extended_from) : null,
  extendedTo: obj.extended_to ? obj.extended_to : null,
  incomeTaxSchema: obj.income_tax_schema,
  expenseTaxSchema: obj.expense_tax_schema,
  fixedRent: obj.fixed_rent,
  fixedManagementFee: obj.fix_management_fee,
  gateways: obj.gateways,
  bankAccountInformation: obj.bank_account_information,
  hasXeroIntegration: obj.hasXeroIntegration,
  hasBillManagement: obj.bill_management,
  isCustomBankAccount: obj.is_custom_bank_account,
  isCustomGatewayPayment: obj.is_custome_gateway_payment,
  isCustomGatewayDeposit: obj.is_custome_gateway_deposit,
  from: obj.from,
  to: obj.to,
  createdDate: obj.created_date,
  updatedDate: obj.updated_date,
  canExtend: obj.can_extend,
});

export const dealUnitNormalizer = (obj): InvestorDealUnit => ({
  id: obj.id,
  propertyUnit: {
    id: obj.property_unit.id,
    name: obj.property_unit.name,
  },
});

export const dealLandlordNormalizer = (obj): InvestorDealLandlord => ({
  id: obj.id,
  firstName: obj.first_name,
  lastName: obj.last_name,
  middleName: obj.middle_name,
  bankAccountInformation: obj?.bank_account_information,
});

export const dealTemplateLineNormalizer = (obj): DealTemplateLine => ({
  id: obj.id,
  costElement: obj.cost_element,
  hostPercent: obj.host_percent,
  investorPercent: obj.investor_percent,
  formula: obj.formula_string,
  chargeTo: obj.charge_to,
});

export const dealTemplateNormalizer = (obj): InvestorDealTemplate => ({
  id: obj.id,
  name: obj.name,
  templateType: obj.template_type,
  active: obj.active,
  managementFeeIncomeFormula: obj.management_fee_income_formula,
  dealTemplateLines: obj.deal_template_lines
    ? obj.deal_template_lines.map((item) => dealTemplateLineNormalizer(item))
    : [],
});

export const dealMPRNormalizer = (obj): InvestorDealMPR => ({
  id: obj.id,
  deal: {
    id: obj.deal.id,
    title: obj.deal.title,
    landlord: obj.deal.landlord,
    dealTemplate: obj.deal.deal_template,
  },
  year: obj.year,
  month: obj.month,
  currency: obj.total_host_income_currency,
  hostIncomeShare: obj.hostIncomeShare,
  hostExpenseShare: obj.hostExpenseShare,
  investorIncomeShare: obj.investorIncomeShare,
  investorExpenseShare: obj.investorExpenseShare,
  hostProfitValue: obj.hostProfitValue,
  investorProfitValue: obj.investorProfitValue,
  totalTax: obj.total_tax,
  totalHostIncomeValue: obj.total_host_income_value,
  totalHostExpenseValue: obj.total_host_expense_value,
  totalInvestorIncomeValue: obj.total_investor_income_value,
  totalInvestorExpenseValue: obj.total_investor_expense_value,
});

export const dealMPRQueueNormalizer = (obj): InvestorDealMPRQueue => ({
  id: obj.id,
  title: obj.title,
  body: obj.body,
  jsonBody: dealMPRQueueJsonBodyNormalizer(JSON.parse(obj.json_body as string)),
  messageType: obj.message_type,
  status: obj.status,
  startTime: obj.start_time,
  completionTime: obj.completion_time,
  processed: obj.processed,
  errorMessage: obj.error_message,
  responseMessage: obj.response_message,
  booking: obj.booking,
  bookingOrderLine: obj.booking_order_line,
  deal: obj.deal,
  priority: obj.priority,
});

export const dealMPRQueueJsonBodyNormalizer = (obj) => ({
  organizationId: obj.Organization_ID,
  dealId: obj.Deal_ID,
  month: obj.Month,
  year: obj.Year,
  orderLine: obj.Order_Lines,
  paymentFees: obj.Payment_Fees,
  channelCommissions: obj.Channel_Commissions,
  normalExpenses: obj.Normal_Expenses,
});

export const convertMPRQueueJsonBodyToFactorString = (factors, translate) => {
  if (factors) {
    let factorList: Array<string> = [];
    Object.entries(factors).forEach(([key, value]) => {
      if (value)
        factorList.push(translate(`investor_deal.monthly_profit_report_queue.factor_item.${key}`));
    });
    return factorList.join(' & ');
  }
  return null;
};

export const recalculateMPRSchema = (translate: TFunction<any[]>) =>
  Yup.object().shape({
    investorDealId: Yup.number().nullable().required(),
    date: Yup.date().nullable().required(),
    factorComponents: Yup.array().test({
      name: 'factorComponents',
      test: function (values) {
        if (isArray(values) && values.some((item) => Boolean(item.value))) return true;
        return this.createError({
          path: 'factorComponents',
          message: translate(
            'investor_deal.monthly_profit_report_queue.recalculate_mpr_factors_error'
          ),
        });
      },
    }),
  });
