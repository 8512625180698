import UnifiedInboxApi from "src/api/unified-inbox";
import { dispatch } from "src/redux/store";
import { slice } from './index'

export const bookingReducers = {
  changeBookingLoadingStatus(state) {
    state.booking = { ...state.booking, isLoading: !state.booking.isLoading };
  },
  setBooking(state, action) {
    state.booking = {
      ...state.booking,
      bookings: {
        ...state.booking.bookings,
        [action.payload.id]: action.payload,
      },
      currentBookingId: action.payload.id,
    };
  },
};

// ----------------------------------------------------------------------

export function getBooking(id) {
  return async () => {
    dispatch(slice.actions.changeBookingLoadingStatus());
    UnifiedInboxApi.getBookingDetailsRoute(id)
      .then((response) => {
        dispatch(slice.actions.setBooking(response.data));
        dispatch(slice.actions.changeBookingLoadingStatus());
      }).catch((err) => console.error(err));
  };
}