import { Stack, StackProps, Typography, TypographyProps } from '@mui/material';
import { FC, ReactNode } from 'react';

// ----------------------------------------------------------------------

interface InputWrapperProps extends Omit<StackProps, 'title'> {
  title: string | ReactNode;
  detail?: string;
  secondaryTitle?: string | ReactNode;
  titleProps?: TypographyProps;
  detailProps?: TypographyProps;
  secondaryTitleProps?: TypographyProps;
}

const InputWrapper: FC<InputWrapperProps> = ({
  title,
  detail,
  secondaryTitle,
  titleProps,
  detailProps,
  secondaryTitleProps,
  children,
  ...other
}) => (
  <Stack {...other}>
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      my={0.5}
      mx={other.direction === 'row' ? 0 : 1}
    >
      <Stack direction="row" alignItems="center" gap={0.5}>
        {typeof title === 'string'
          ? <Typography variant="subtitle2" fontWeight="fontWeightSemiBold" {...titleProps}>
            {title}
          </Typography>
          : title
        }
        <Typography variant="caption" color="text.secondary" {...detailProps}>
          {detail}
        </Typography>
      </Stack>
      {typeof secondaryTitle === 'string' ? (
        <Typography
          variant="caption"
          color="text.secondary"
          fontWeight="fontWeightRegular"
          {...secondaryTitleProps}
        >
          {secondaryTitle}
        </Typography>
      ) : (
        secondaryTitle
      )}
    </Stack>
    {children}
  </Stack>
);

export default InputWrapper;
