import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreateTicket, TicketFormValueProps } from 'src/@types/ticket';

// ----------------------------------------------------------------------

const initialState: CreateTicket = {
  open: false,
  send: false,
  defaultValues: {
    subject: '',
    handType: '',
    urgencyLevel: 'Low',
    description: '',
    attachments: [],
    sendDiagnostics: true,
  },
};

const slice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    // OPEN
    openDialog(state) {
      state.open = true;
    },

    // CLOSE
    closeDialog(state) {
      state.open = false;
    },

    // send (for refresh hand list API)
    refreshHandAPI(state) {
      state.send = !state.send;
    },

    // SET SUBJECT
    setDefaultValues(state, action: PayloadAction<TicketFormValueProps>) {
      state.defaultValues = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openDialog, closeDialog, setDefaultValues, refreshHandAPI } = slice.actions;
