import { Upsell, UpsellParams } from 'src/@types/upsell';
import axios from '../utils/axios';
import { Meta } from 'src/@types/common';

function upsellsAPI() {
  return {
    fetchAddonUpsells: (params: { upsell: boolean, archive: boolean } & UpsellParams, signal?: AbortSignal) => {
      const url = `/apis/add-ons`;
      return axios.get<{ data: Upsell[]; meta: Meta }>(url, { params, signal });
    },
    getUsedCurrencies: () => {
      const url = `/apis/get_used_currencies`;
      return axios.get(url);
    },
    deleteAddonUpsell: (id: string | number) => axios.delete(`/apis/add-ons/${id}`),
    validateUpsellRemoval: (id: string | number) =>
      axios.get(`/apis/add-ons/validate/removal/${id}`),
    archiveUpsell: (id: string | number, archive: boolean) => {
      const url = `/apis/add-ons/${id}`;
      return axios.put(url, {
        archive,
      });
    },
  };
}

export default upsellsAPI();
