import { Node } from 'react-checkbox-tree';
import { MonthKey, PropertyTree, RatesErrors, RatePlanTree, RatePlansCitiesTree, RateSetting, RateSettingType, RateState, RateType, RateTypeKeys, RawRate, UnitTypeTree, WeekDayKey, SettingKeys } from "src/@types/rate";
import { generateUniqSerial } from './generateUniqSerial';
import { addDays, daysInWeek, eachDayOfInterval, eachMonthOfInterval, format, isAfter, isBefore, isToday, monthsInYear } from 'date-fns';
import { fDate } from './formatTime';

export const RATE_DAYS: WeekDayKey[] = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
export const RATE_MONTHS: MonthKey[] = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

export const generateInitialRate = (): RawRate => ({
  id: generateUniqSerial(),
  isRemoved: false,
  rateFormType: 'fixed',
  timePeriod: [null, null],
  settings: generateInitialSettings(),
});

export const generateInitialSettings = () => {
  const settings = {};
  const settingTypes: RateSettingType[] = [...RATE_DAYS, ...RATE_MONTHS, 'fixed'];
  const rawSetting: RateSetting = {
    price: null,
    extraGuestPrice: null,
    minStay: null,
    maxStay: null,
    stopCheckIns: null,
    stopCheckOuts: null,
    stopSell: null,
    overriddenFields: null,
  };
  settingTypes.forEach((type) => settings[type] = rawSetting);
  return settings as RawRate['settings'];
};

export const generateOptions = (tree: RatePlansCitiesTree[]) => {
  const currencies: string[] = [];
  
  const handleRatePlans = (ratePlans: RatePlanTree[]) => ratePlans.map(({ id, name }) => ({
    value: `r-${id}`,
    label: name,
  }));
  
  const handleUnitTypes = (unitTypes: UnitTypeTree[]) => unitTypes.map(({ id, name, rate_plans }) => {
    const children = handleRatePlans(rate_plans);
    if (!rate_plans || !children || children.length === 0) return null;
    return { value: `u-${id}`, label: name, children };
  }).filter((unitTypes) => unitTypes !== null);

  const handleProperties = (properties: PropertyTree[]) => properties.map((property) => {
    const { id, short_name, unit_types, default_currency } = property;
    const { code: currency } = default_currency;
    const children = handleUnitTypes(unit_types);
    if (!unit_types || !children || children.length === 0) return null;
    if (!currencies.includes(currency)) currencies.push(currency);
    return { value: `p/${currency}-${id}`, label: short_name, children };
  }).filter((property) => property !== null);

  const handleCities = (cities: RatePlansCitiesTree[]) => cities.map(({ id, name, properties }) => {
    const children = handleProperties(properties);
    if (!properties || !children || children.length === 0) return null;
    return { value: `c-${id}`, label: name, children };
  }).filter((city) => city !== null);

  const convertedTree = handleCities(tree) as Node[];
  return { convertedTree, currencies };
};

export const filterRatePlansByCurrency = (tree: Node[], currency: string | null): Node[] => tree.map((branch) => ({
  ...branch,
  ...(branch.children && {
    children: branch.children.filter((property) => {
      const propertyCurrency = property.value.split('/')[1].split('-')[0];
      return propertyCurrency === currency;
    }),
  }),
})).filter((branch) => branch.children && branch.children.length > 0);

export const getRateStayErrors = (rateType: RateType, minStay: number | null, maxStay: number | null) => {
  const minDayForWeekly = 7;
  const minDayForMonthly = 28;

  const getMinimumStayAmount = () => {
    if (rateType === 'weekly') return minDayForWeekly;
    if (rateType === 'monthly') return minDayForMonthly;
    return 0;
  };

  const intervalError = Boolean(maxStay !== null && minStay !== null && (minStay > maxStay));
  const minStayError = Boolean(minStay !== null && minStay < getMinimumStayAmount());
  const maxStayError = Boolean(maxStay !== null && maxStay < getMinimumStayAmount());
  const monthlyError = Boolean(rateType === 'monthly' && (maxStayError || minStayError));
  const weeklyError = Boolean(rateType === 'weekly' && (maxStayError || minStayError));
  const rateStaysHasError = maxStayError || minStayError || intervalError;
  const hasError = monthlyError || weeklyError || intervalError;

  return {
    monthlyError,
    weeklyError,
    intervalError,
    minStayError,
    maxStayError,
    rateStaysHasError,
    hasError,
  };
};

export const generateRateLog = (
  rate: RawRate,
  rateType: RateState['rateType'],
  selectedCurrency: RateState['selectedCurrency'],
  tableSettingTypes: RateState['options']['tableSettingTypes'],
) => {
  const { rateFormType, settings, timePeriod } = rate;

  const KEYS: Record<SettingKeys, string> = {
    price: 'price',
    minStay: 'min_stay',
    maxStay: 'max_stay',
    stopSell: 'stop_sell',
    stopCheckIns: 'close_to_arrival',
    stopCheckOuts: 'close_to_departure',
    extraGuestPrice: 'extra_guest_price',
  };
  const RATE_TYPE_KEYS: {[key in RateType]: RateTypeKeys} = {
    weekly: 'week',
    nightly: 'nightly',
    monthly: 'monthly',
  };
  const SETTING_PROPERTIES = ['minStay', 'maxStay', 'stopSell', 'stopCheckIns', 'stopCheckOuts'];
  const PRICE_PROPERTIES = ['price', 'extraGuestPrice'];

  const rateLog = {
    origin: 'Manual',
    from: timePeriod[0] && !isNaN(timePeriod[0].getTime()) ? fDate(timePeriod[0], 'yyyy-MM-dd') : null,
    to: timePeriod[1] && !isNaN(timePeriod[1].getTime()) ? fDate(timePeriod[1], 'yyyy-MM-dd') : null,
  };
  
  const addRateLogFields = (type: WeekDayKey | MonthKey | RateTypeKeys, settingType: RateSettingType) => {
    SETTING_PROPERTIES.forEach((property) => {
      rateLog[`${type}_${KEYS[property]}`] = settings[settingType][property];
    });
    PRICE_PROPERTIES.forEach((property) => {
      const value = settings[settingType][property];
      rateLog[`${type}_${KEYS[property]}`] = value !== null ? {
        value,
        currency: selectedCurrency || 'GBP',
      } : null;
    });
  };

  if (rateFormType === 'fixed') {
    addRateLogFields(RATE_TYPE_KEYS[rateType], 'fixed');
  } else {
    tableSettingTypes.forEach((type) => type !== 'fixed' && addRateLogFields(type, type));
  }

  return rateLog;
};

export const generateMultiRateLogs = (rateState: RateState) => {
  const {
    rates,
    selectedRatePlans,
    rateType,
    selectedCurrency,
    shouldOverrideRateRules,
    options: { tableSettingTypes },
  } = rateState;
  const ratesCommon = rates.filter(({ isRemoved }) => !isRemoved)
    .map((rate) => generateRateLog(rate, rateType, selectedCurrency, tableSettingTypes));
  const rateLogs = ratesCommon.flatMap((commons) => (
    selectedRatePlans.map((ratePlanId) => ({
      ...commons,
      rate_plan: ratePlanId,
      override_rate_rules: shouldOverrideRateRules,
    }))
  ));
  return rateLogs;
};

export const checkIfSettingHasFilledPart = (setting: RateSetting) => {
  const hasValue = Object.keys(setting).some((key) => setting[key] !== null);
  return hasValue;
};
export const checkSettingsHasError = (rate: RawRate, tableSettingTypes: RateState['options']['tableSettingTypes']) => {
  const { rateFormType, settings } = rate;
  let hasValue = false;
  if (rateFormType === 'fixed') {
    hasValue = checkIfSettingHasFilledPart(settings.fixed);
  } else {
    hasValue = tableSettingTypes.some((type) => checkIfSettingHasFilledPart(settings[type]));
  }
  return !hasValue;
};
export const checkRateStaysError = (rate: RawRate, tableSettingTypes: RateState['options']['tableSettingTypes'], rateType: RateType) => {
  const { rateFormType, settings } = rate;
  let hasError = false;
  if (rateFormType === 'fixed') {
    const { minStay, maxStay } = settings.fixed;
    const { rateStaysHasError } = getRateStayErrors(rateType, minStay, maxStay);
    hasError = rateStaysHasError;
  } else {
    tableSettingTypes.some((type) => {
      const { minStay, maxStay } = settings[type];
      const { rateStaysHasError } = getRateStayErrors(rateType, minStay, maxStay);
      return rateStaysHasError;
    });
  }
  return hasError;
};

export const extractPropertiesOfRatePlans = (cities: RatePlansCitiesTree[]) => (
  cities.flatMap(({ properties }) => properties)
);

export const extractRatePlans = (cities: RatePlansCitiesTree[]) => (
  extractPropertiesOfRatePlans(cities).flatMap(({ unit_types }) => unit_types.flatMap(({ rate_plans }) => rate_plans))
);

export const validateRates = (rateState: RateState) => {
  const errors: RatesErrors = {};
  const { rates, rateType, options: { tableSettingTypes } } = rateState;
  rates.filter(({ isRemoved }) => !isRemoved).forEach((rate) => {
    const { timePeriod } = rate;
    const selectedDaysBefore = Boolean(
      timePeriod[0] && !isToday(timePeriod[0]) && isBefore(timePeriod[0], new Date())
    );
    const isAfterLimit = Boolean(timePeriod[1] && isAfter(timePeriod[1], addDays(new Date(), 730)));
    const timePeriodHasError = !timePeriod[0] || !timePeriod[1] || selectedDaysBefore || isAfterLimit;
    const isTimePeriodInvalid =
      JSON.stringify(timePeriod[0]) === 'null' || JSON.stringify(timePeriod[1]) === 'null';
    const settingsHasError = checkSettingsHasError(rate, tableSettingTypes);
    const rateStaysHasError = checkRateStaysError(rate, tableSettingTypes, rateType);
    errors[rate.id] = {
      timePeriodHasError,
      isTimePeriodInvalid,
      settingsHasError,
      rateStaysHasError,
      hasError: timePeriodHasError || isTimePeriodInvalid || settingsHasError || rateStaysHasError,
    };
  });
  const hasAnyError = Object.values(errors).some(({ hasError }) => hasError);
  return { hasAnyError, errors };
};

export function generateTableSettingTypes(rateType: RateState['rateType'], timePeriod: RawRate['timePeriod']) {
  let settingTypes: RateSettingType[] = [];
  if (rateType === 'nightly') {
    settingTypes = RATE_DAYS;
    if (timePeriod[0] && timePeriod[1]) {
      const interval = eachDayOfInterval({
        start: timePeriod[0],
        end: timePeriod[1],
      });
      if (interval.length < daysInWeek) {
        settingTypes = interval.map((day) => format(day, 'EEE').toLowerCase() as RateSettingType);
      }
    }
  } else if (rateType === 'monthly') {
    settingTypes = RATE_MONTHS;
    if (timePeriod[0] && timePeriod[1]) {
      const interval = eachMonthOfInterval({
        start: timePeriod[0],
        end: timePeriod[1],
      });
      if (interval.length < monthsInYear) {
        settingTypes = interval.map((day) => format(day, 'MMM').toLowerCase() as RateSettingType);
      }
    }
  }
  return settingTypes;
};