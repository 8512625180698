// api
import BookingApi from 'src/api/booking';
// redux
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// @types
import { dispatch } from '../store';
import { CostElement, RefundTypeEdit, RefundTypes, RefundTypesState } from 'src/@types/refund-list';

const initialState: RefundTypesState = {
  loading: false,
  refundTypes: {
    list: [],
    rowCount: 0,
    gridConfigs: null,
    controller: new AbortController(),
  },
  drawers: {
    newEditRefundTypeDrawer: {
      isOpen: false,
      onAction: undefined,
    },
  },
};

const slice = createSlice({
  name: 'refundTypes',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    setGridConfigs(state, action) {
      state.refundTypes.gridConfigs = action.payload;
    },

    setRowCount(state, action) {
      state.refundTypes.rowCount = action.payload;
    },

    setRefundTypeList(state, action: PayloadAction<Array<RefundTypes>>) {
      state.loading = false;
      state.refundTypes.list = action.payload;
    },

    setRefundTypeListController(state, action: PayloadAction<AbortController | null>) {
      state.refundTypes.controller = action.payload;
    },

    updateRefundTypeListDeleteAction(state, action: PayloadAction<{ activeId: number }>) {
      state.refundTypes.list = state.refundTypes.list?.filter(
        (item) => item.id !== action.payload.activeId
      );
      state.refundTypes.rowCount = --state.refundTypes.rowCount;
    },

    updateRefundTypeListEditAction(
      state,
      action: PayloadAction<{ activeId: number; newValue: RefundTypeEdit }>
    ) {
      const { activeId, newValue } = action.payload;
      const refundTypeIndex = state.refundTypes.list.findIndex((item) => item.id === activeId);
      if (refundTypeIndex >= 0) {
        state.refundTypes.list[refundTypeIndex] = {
          ...state.refundTypes.list[refundTypeIndex],
          name: newValue.name,
          cost_element: newValue.cost_element as CostElement,
          related_expense: newValue.related_expense,
        };
      }
    },

    setNewEditRefundTypeDrawer(
      state,
      action: PayloadAction<{
        open: boolean;
        onAction?: (data: any) => void;
        refundType?: RefundTypeEdit;
      }>
    ) {
      state.drawers.newEditRefundTypeDrawer.isOpen = action.payload.open;
      state.drawers.newEditRefundTypeDrawer.onAction = action.payload?.onAction;
      state.drawers.newEditRefundTypeDrawer.refundType = action.payload.refundType;
    },
  },
});

// reducer
export default slice.reducer;

// actions
export const {
  setLoading,
  setGridConfigs,
  setRowCount,
  setRefundTypeList,
  setRefundTypeListController,
  setNewEditRefundTypeDrawer,
  updateRefundTypeListDeleteAction,
  updateRefundTypeListEditAction,
} = slice.actions;

export function getRefundTypeList(
  page,
  limit,
  sortModel,
  filtersState,
  setLoading,
  advanceFilterState,
  controller
) {
  return async () => {
    dispatch(setGridConfigs({ page, limit, sortModel, filtersState, advanceFilterState }));
    if (controller) {
      controller.abort();
      dispatch(setRefundTypeListController(new AbortController()));
    }
    try {
      setLoading(true);
      const advanceFilterParam =
        advanceFilterState && advanceFilterState.value
          ? {
              key: advanceFilterState.key ? advanceFilterState.key : 'searchKeyword',
              value: advanceFilterState?.value || '',
            }
          : undefined;

      const response = await BookingApi.fetchAddonsList({
        page,
        limit,
        filtersState,
        advanceFilterParam,
        category: 'Refund',
      });
      dispatch(setRefundTypeList(response.data.data));
      dispatch(setRowCount(response.data.meta.total_items));
      dispatch(setRefundTypeListController(null));
    } catch (e) {
    } finally {
      if (!controller) {
        dispatch(setRefundTypeListController(new AbortController()));
        setLoading(false);
      }
    }
  };
}
