import { useState, useEffect } from 'react';
import { Stack, Typography, Box } from '@mui/material';
import Review from './Review';
import { Review as ReviewType } from 'src/@types/common';
import useLocales from 'src/hooks/useLocales';
import Iconify from '../Iconify';
import { LoadingButton } from '@mui/lab';

export const hasReviewViewAccess = (user) => user.acls.PeymansBookingBundleReview.VIEW;
export const hasReviewEditAccess = (user) => user.acls.PeymansBookingBundleReview.EDIT;
export const hasReviewCreateAccess = (user) => user.acls.PeymansBookingBundleReview.CREATE;

interface Props {
  reviews: ReviewType[];
  limit: number;
  totalItems: number;
  currentPage: number;
  lastPage: number;
  changePage: (pageNo: number) => void;
  update: (review: ReviewType) => void;
  remove: (reviewId: number) => void;
  actions?: JSX.Element;
  disableActions?: boolean;
  title?: string;
  noReviewsComponent?: JSX.Element;
}

export default function Reviews({
  reviews,
  limit,
  totalItems,
  currentPage,
  lastPage,
  changePage,
  update,
  remove,
  actions,
  title,
  noReviewsComponent,
  disableActions,
}: Props) {
  const { translate } = useLocales(['listings', 'common']);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, [reviews]);

  return (
    <Stack>
      {title ? (
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          alignItems={{ xs: 'start', md: 'center' }}
          mb={{ xs: 3 }}
          mx={3}
          mt={3}
          gap={{ xs: 1, md: 3 }}
        >
          <Box display="flex" alignItems="center">
            <Typography variant="body2" fontWeight="fontWeightSemiBold">
              {title}
            </Typography>
            &nbsp;
            <Typography variant="body2" color="text.secondary">{`(${totalItems})`}</Typography>
          </Box>
          {actions || null}
        </Stack>
      ) : null}
      <>
        {reviews.map((review) => (
          <Review key={review.id} review={review} update={update} remove={remove} canEdit={!disableActions} />
        ))}
        {!reviews.length && noReviewsComponent ? <>{noReviewsComponent}</> : null}
        {reviews.length && lastPage > currentPage ? (
          <LoadingButton
            startIcon={<Iconify icon="ph:arrow-down-bold" />}
            variant="outlined"
            loading={isLoading}
            onClick={() => {
              setIsLoading(true);
              changePage(reviews.length / limit + 1);
            }}
            sx={(theme) => ({
              mx: 3,
              width: '95%',
              [theme.breakpoints.down('md')]: { width: '85%' },
            })}
          >
            {translate('show_more')}
          </LoadingButton>
        ) : null}
      </>
    </Stack>
  );
}
