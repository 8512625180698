import axios from '../utils/axios';

function invoiceAPI() {
  return {
    fetchInvoice: (invoiceId: number) => axios.get(`/apis/invoices/${invoiceId}`),
    updateInvoiceDate: (invoiceId: number, date: string | Date) =>
      axios.put(`/apis/invoices/${invoiceId}`, {
        doc_date: date,
      }),
    updateXeroLineAmountType: (invoiceId: number, xeroType: string) =>
      axios.post(`/apis/invoice/change_xero_line_amount_type/${invoiceId}`, {
        xero_line_amount_type: xeroType,
      }),
    createPayment: (
      id: number,
      amount: number,
      currency: string,
      date: Date | string | number,
      time: string,
      type: string,
      reference: string,
      description: string,
      paymentId: number | null,
      file_ids: number[] | null,
      transactionCostSales: number,
      transactionCostPurchase: number,
      currencyConversionSales: number,
      currencyConversionPurchase: number
    ) =>
      axios.post(`/apis/booking/add_payment`, {
        booking_id: id,
        amount: amount,
        currency: currency,
        date: date,
        time: time,
        payment_type: type,
        reference: reference,
        description: description,
        payment_id: paymentId,
        file_ids:file_ids,
        transaction_cost_sales: transactionCostSales,
        transaction_cost_purchase: transactionCostPurchase,
        currency_conversion_sales: currencyConversionSales,
        currency_conversion_purchase: currencyConversionPurchase,
      }),
    changeToDraft: (invoice_id: number) =>
      axios.post(`/apis/invoice/change_to_draft/${invoice_id}`),
    reGenerateInvoice: (invoice_id: number) =>
      axios.post(`/apis/invoice/regenerate_customer_invoice/${invoice_id}`),
    postInvoiceToXero: (invoiceId: number) =>
      axios.post('/apis/invoice/post_invoice_to_xero', {
        invoice_id: invoiceId,
      }),
    voidInvoice: (invoiceId: number) =>
      axios.post('/apis/invoice/void_invoice_from_xero', {
        invoice_id: invoiceId,
      }),
  };
}

export default invoiceAPI();
