import { dispatch } from '../store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// api
import InvestorApi from 'src/api/investor';
// @types
import { SortDirection } from 'src/@types/booking-list';
import { InitialPageState } from 'src/@types/investor-deal';
// utils
import { dealMPRNormalizer, dealMPRQueueNormalizer, dealNormalizer } from 'src/utils/investorDeal';

const initialState: InitialPageState = {
  isLoading: false,
  isRecalculating: false,
  investorDeal: null,
  investorDealMPR: {
    isLoading: false,
    gridConfigs: null,
    list: [],
    rowCount: 0,
  },
  investorDealMPRQueue: {
    isLoading: false,
    gridConfigs: null,
    list: [],
    rowCount: 0,
  },
  dialogs: {
    modifyContractInfoOpen: false,
  },
  drawers: {
    modifyContractOpen: false,
  },
};

const slice = createSlice({
  name: 'dealView',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },

    setRecalculationStatus(state, action: PayloadAction<boolean>) {
      state.isRecalculating = action.payload;
    },

    setInvestorDeal(state, action) {
      state.isLoading = false;
      state.investorDeal = action.payload;
    },

    setInvestorDealGeneralInfo(state, action) {
      if (state.investorDeal) {
        state.investorDeal.title = action.payload.title;
        state.investorDeal.description = action.payload.description;
        state.investorDeal.updatedDate = action.payload.updatedDate;
      }
    },

    setInvestorDealFinancialTaxSchemas(state, action) {
      if (state.investorDeal) {
        state.investorDeal.incomeTaxSchema = action.payload.incomeTaxSchema;
        state.investorDeal.expenseTaxSchema = action.payload.expenseTaxSchema;
        state.investorDeal.updatedDate = action.payload.updatedDate;
      }
    },

    setInvestorDealBankAccountInfo(state, action) {
      if (state.investorDeal) {
        state.investorDeal.bankAccountInformation = action.payload.bankAccountInformation;
        state.investorDeal.updatedDate = action.payload.updatedDate;
      }
    },

    setInvestorDealCustomBankAccountConfig(state, action) {
      if (state.investorDeal) {
        state.investorDeal.isCustomBankAccount = action.payload.isCustomBankAccount;
        state.investorDeal.updatedDate = action.payload.updatedDate;
      }
    },

    setInvestorDealCustomGatewayPaymentConfig(state, action) {
      if (state.investorDeal) {
        state.investorDeal.isCustomGatewayPayment = action.payload.isCustomGatewayPayment;
        state.investorDeal.updatedDate = action.payload.updatedDate;
      }
    },

    setInvestorDealCustomGatewayDepositConfig(state, action) {
      if (state.investorDeal) {
        state.investorDeal.isCustomGatewayDeposit = action.payload.isCustomGatewayDeposit;
        state.investorDeal.updatedDate = action.payload.updatedDate;
      }
    },

    setInvestorDealMPRLoading(state, action) {
      state.investorDealMPR.isLoading = action.payload;
    },

    setInvestorDealMPRGridConfigs(state, action) {
      state.investorDealMPR.gridConfigs = action.payload;
    },

    setInvestorDealMPRList(state, action) {
      state.investorDealMPR.isLoading = false;
      state.investorDealMPR.list = action.payload;
    },

    setInvestorDealMPRQueueLoading(state, action) {
      state.investorDealMPRQueue.isLoading = action.payload;
    },

    setInvestorDealMPRQueueList(state, action) {
      state.investorDealMPRQueue.isLoading = false;
      state.investorDealMPRQueue.list = action.payload;
    },

    setInvestorDealMPRStatus(state, action) {
      if (state.investorDeal) {
        state.investorDeal.mprStatus = action.payload;
      }
    },

    setModifyContractInfoDialog(state, action) {
      state.dialogs.modifyContractInfoOpen = action.payload;
    },

    setModifyContractDrawer(state, action) {
      state.drawers.modifyContractOpen = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setLoading,
  setInvestorDeal,
  setRecalculationStatus,
  setInvestorDealGeneralInfo,
  setInvestorDealFinancialTaxSchemas,
  setInvestorDealBankAccountInfo,
  setInvestorDealCustomBankAccountConfig,
  setInvestorDealCustomGatewayPaymentConfig,
  setInvestorDealCustomGatewayDepositConfig,
  setInvestorDealMPRLoading,
  setInvestorDealMPRGridConfigs,
  setInvestorDealMPRList,
  setInvestorDealMPRStatus,
  setModifyContractInfoDialog,
  setModifyContractDrawer,
} = slice.actions;

export function getInvestorDeal(dealId: number) {
  return async () => {
    dispatch(slice.actions.setLoading(true));
    try {
      const response = await InvestorApi.fetchInvestorDeal(dealId);
      dispatch(slice.actions.setInvestorDeal(dealNormalizer(response.data)));
      dispatch(
        slice.actions.setRecalculationStatus(!Boolean(response.data.MPRStatus === 'Completed'))
      );
    } catch (error) {}
    dispatch(slice.actions.setLoading(false));
  };
}

export function getInvestorDealMonthlyProfitReportList({
  dealId,
  page,
  limit,
  filtersState,
  order,
  signal,
  advanceFilterParam,
}: {
  dealId: number;
  page: number;
  limit: number;
  filtersState: {};
  order?: { field: string; sort: SortDirection };
  signal?: AbortSignal;
  advanceFilterParam?: { key: string; value: string };
}) {
  return async () => {
    dispatch(slice.actions.setInvestorDealMPRLoading(true));
    try {
      const response = await InvestorApi.fetchInvestorDealMonthlyProfitReportList({
        dealId,
        page,
        limit,
        filtersState,
        order,
        signal,
        advanceFilterParam,
      });
      dispatch(
        slice.actions.setInvestorDealMPRList(response.data.map((item) => dealMPRNormalizer(item)))
      );
    } catch (error) {}
    dispatch(slice.actions.setInvestorDealMPRLoading(false));
  };
}

export function getInvestorDealMonthlyProfitReportQueueList(dealId) {
  return async () => {
    dispatch(slice.actions.setInvestorDealMPRQueueLoading(true));
    try {
      const response = await InvestorApi.fetchInvestorDealMonthlyProfitReportQueueList(dealId);
      dispatch(
        slice.actions.setInvestorDealMPRQueueList(
          response.data.map((item) => dealMPRQueueNormalizer(item))
        )
      );
    } catch (error) {}
    dispatch(slice.actions.setInvestorDealMPRQueueLoading(false));
  };
}
