import {
  Corporation,
  GuestSearchApiResponse,
  ContactSearchApiResponse,
  FetchCompaniesListDataType,
} from 'src/@types/corporation';
import { InternalNote, Staff } from 'src/@types/common';
import { Payment, Invoice } from 'src/@types/guest';
import axios from '../utils/axios';

function corporationAPI() {
  return {
    fetchCorporation: (id: number) => axios.get<Corporation>(`/apis/corporations/${id}`),
    fetchAllStaff: (organization_id: number) =>
      axios.get<Staff[]>(
        `/apis/corporation_staff?groups[]=corporation_staff:read&organization_id=${organization_id}`
      ),
    fetchRelatedCorporationBookings: (id: number, signal?: AbortSignal) =>
      axios.get(`/apis/list/bookings`, { signal, params: { corporation: id } }),
    fetchGuestsOfOrganization: (corpId: number, page: number, limit: number, searchTerm: string) =>
      axios.get<GuestSearchApiResponse[]>(
        searchTerm
          ? `/apis/guest/search?search=${searchTerm}&page=${page}&offset=${limit}&exclude_corporation_id=${corpId}`
          : `/apis/guests?page=${page + 1}&limit=${limit}&exclude_corporation_id=${corpId}`
      ),
    fetchContactsOfOrganization: (
      corpId: number,
      page: number,
      limit: number,
      searchTerm: string
    ) =>
      axios.get<ContactSearchApiResponse[]>(
        `/apis/inbox/get-contacts-of-organization?offset=${limit}&page=${page}&key=${searchTerm}&withBooking=false&exclude_corporation_id=${corpId}`
      ),
    fetchInvoices: (corpId: number) =>
      axios.get<Invoice[]>(`/apis/corporation/booking_data_grid?corporation_id=${corpId}`),
    fetchPayments: (corpId: number) =>
      axios.get<Payment[]>(`/apis/corporation/payment_data_grid?corporation_id=${corpId}`),
    fetchCompaniesList: (params: {
      page?: number;
      limit?: number;
      signal?: AbortSignal;
      searchParams?: { [key: string]: any };
      headers?: {
        Accept: 'text/csv' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      };
      responseType?: 'blob';
      fields?: any[];
    }) => {
      const { page, limit = 0, signal, searchParams, headers, responseType, fields } = params;
      return axios.get<FetchCompaniesListDataType>('/apis/corporation-data-grid', {
        signal,
        params: {
          ...(page && { page }),
          ...searchParams,
          limit,
          ...(fields && { fields }),
        },
        ...(headers && {
          headers,
        }),
        ...(responseType && { responseType }),
      });
    },
    createContact: (contact: {
      first_name: string;
      last_name: string;
      email: string;
      mobile_number: string;
    }) =>
      axios.post('/apis/inbox/create-update-contact', {
        first_name: contact.first_name,
        last_name: contact.last_name,
        mobile_phones: [contact.mobile_number],
        emails: [contact.email],
      }),
    updateCorp: (id: number, body: Partial<Corporation> | { [x: string]: any }) =>
      axios.put(`/apis/corporations/${id}`, body),
    createPayment: (
      corporationId: number,
      amount: number,
      currency: string,
      date: string,
      time: string,
      type: string,
      reference: string | null,
      description: string | null,
      file_ids: number[] | null,
      transactionCostSales: number,
      transactionCostPurchase: number,
      currencyConversionSales: number,
      currencyConversionPurchase: number
    ) =>
      axios.post('/apis/corporation/add_payment', {
        corporation_id: corporationId,
        amount,
        currency,
        date,
        time,
        type,
        reference,
        description,
        file_ids,
        transaction_cost_sales: transactionCostSales,
        transaction_cost_purchase: transactionCostPurchase,
        currency_conversion_sales: currencyConversionSales,
        currency_conversion_purchase: currencyConversionPurchase,
      }),
    createCompany: (body: { name: string; email: string; phone: string }) =>
      axios.post('/apis/corporations', {
        name: body.name,
        email: body.email,
        phones: body.phone ? [{ label: 'landline', phone: body.phone }] : [],
      }),
    addInternalNote: (entityId: number, entity: 'guest' | 'corporation', message: string) =>
      axios.post<InternalNote>(`/apis/${entity}/add_note/${entityId}`, { message }),
    updateInternalNote: (id: number, message: string) =>
      axios.put<InternalNote>(`/apis/edit_note/${id}`, { message }),
    deleteInternalNote: (id: number) => axios.delete(`/apis/delete_note/${id}`),
  };
}

export default corporationAPI();
