// axios
import axios, { AxiosResponse } from 'axios';
// api
import tasksAPI from 'src/api/tasks';
import BookingApi from 'src/api/booking';
import marketingAPI from 'src/api/marketing';
import orgConfigAPI from 'src/api/org-config';
import automatedMessageAPI from 'src/api/automated-message';
// @types
import { Staff } from 'src/@types/common';
import { TaskSetting } from 'src/@types/tasks';
import { Brand as MarketingBrand } from 'src/@types/marketing';
import {
  AutomatedMessageConfig,
  UpdateAutomatedMessageConfigBodyType,
} from 'src/@types/automated-message';
import {
  Brand,
  ContactInfo,
  OrgConfigState,
  CheckInReadyConditions,
  MessagingTabData,
  TransactionCostSetting,
  AutoCheckInCheckOutSettings,
  PreferencesSetting,
  SocialMedia,
  InvoiceSetting,
  CountryConfig,
} from 'src/@types/org-config';
// redux
import { dispatch } from '../store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// utils
import { sortBrandMenus } from 'src/utils/marketing';

const initialState: OrgConfigState = {
  tabs: ['contact_details', 'booking', 'finance', 'messaging', 'task', 'localisation'],
  loading: false,
  bookingTabData: {},
  messagingTabData: { staff: [], loading: false },
  taskTabData: {},
  brand: null,
  brands: {},
  financeTabData: {},
  contactDetailsTabData: {},
  localisationTabData: {
    countryConfigs: [],
  },
};

const slice = createSlice({
  name: 'orgConfig',
  initialState,
  reducers: {
    setOrgConfigLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setBookingTabData(
      state,
      action: PayloadAction<{
        checkInReadyCondition: CheckInReadyConditions[];
        autoCheckInCheckOutSetting: AutoCheckInCheckOutSettings[];
      }>
    ) {
      const tempObject = { ...state.bookingTabData };
      action.payload.checkInReadyCondition.forEach((element) => {
        tempObject[element.brand.id] = {
          ...tempObject[element.brand.id],
          checkInReadyCondition: element,
        };
      });
      action.payload.autoCheckInCheckOutSetting.forEach((element) => {
        tempObject[element.brandId] = {
          ...tempObject[element.brandId],
          autoCheckInCheckOutSettings: element,
        }; // Updated property name
      });
      state.bookingTabData = { ...tempObject };
    },

    updateBookingTabData(
      state,
      action: PayloadAction<{
        brandId: number;
        checkInReadyCondition?: CheckInReadyConditions;
        autoCheckInCheckOutSetting?: AutoCheckInCheckOutSettings;
      }>
    ) {
      if (action.payload.checkInReadyCondition) {
        state.bookingTabData[action.payload.brandId].checkInReadyCondition =
          action.payload.checkInReadyCondition;
      }
      if (action.payload.autoCheckInCheckOutSetting) {
        state.bookingTabData[action.payload.brandId].autoCheckInCheckOutSettings =
          action.payload.autoCheckInCheckOutSetting;
      }
    },

    setFinanceTabData(
      state,
      action: PayloadAction<{
        transactionCostSetting: TransactionCostSetting[];
        bankAccountInfo: PreferencesSetting[];
        invoiceSetting: InvoiceSetting[];
      }>
    ) {
      const tempObject = { ...state.financeTabData };
      action.payload.transactionCostSetting.forEach((element) => {
        tempObject[element.brandId] = {
          ...tempObject[element.brandId],
          transactionCostSetting: element,
        };
      });
      action.payload.bankAccountInfo.forEach((element) => {
        tempObject[element.brandId] = { ...tempObject[element.brandId], bankAccountInfo: element };
      });
      action.payload.invoiceSetting.forEach((element) => {
        tempObject[element.brandId] = { ...tempObject[element.brandId], invoiceSetting: element };
      });
      state.financeTabData = { ...tempObject };
    },

    setTaskTabData(state, action: PayloadAction<{ taskSetting: TaskSetting[] }>) {
      const tempObject = { ...state.taskTabData };
      action.payload.taskSetting.forEach((element) => {
        tempObject[element.brand_id] = { ...tempObject[element.brand_id], taskSetting: element };
      });
      state.taskTabData = { ...tempObject };
    },

    updateTaskTabData(
      state,
      action: PayloadAction<{ brandId: number; taskSetting?: Partial<TaskSetting> }>
    ) {
      if (action.payload.taskSetting) {
        state.taskTabData[action.payload.brandId].taskSetting = {
          ...state.taskTabData[action.payload.brandId].taskSetting,
          ...action.payload.taskSetting,
        };
      }
    },

    updateFinanceTabData(
      state,
      action: PayloadAction<{
        brandId: number;
        transactionCostSetting?: TransactionCostSetting;
        bankAccountInfo?: PreferencesSetting;
        invoiceSetting?: InvoiceSetting;
      }>
    ) {
      if (action.payload.transactionCostSetting) {
        state.financeTabData[action.payload.brandId].transactionCostSetting =
          action.payload.transactionCostSetting;
      }
      if (action.payload.bankAccountInfo) {
        state.financeTabData[action.payload.brandId].bankAccountInfo =
          action.payload.bankAccountInfo;
      }
      if (action.payload.invoiceSetting) {
        state.financeTabData[action.payload.brandId].invoiceSetting = action.payload.invoiceSetting;
      }
    },

    setAllBrands(state, action: PayloadAction<Brand[]>) {
      const tempObject = {};
      action.payload.forEach((brand, i) => {
        tempObject[i] = { ...brand };
      });
      state.brands = { ...tempObject };
    },
    setMessagingTabLoading(state, action: PayloadAction<boolean>) {
      state.messagingTabData.loading = action.payload;
    },
    setMessagingTabData(
      state,
      action: PayloadAction<{
        brands: OrgConfigState['brands'];
        staff: (Staff & { brands: Brand[] })[];
        automatedMessageConfigs: AutomatedMessageConfig[];
      }>
    ) {
      const tempObject = { ...state.messagingTabData };

      if (action.payload.automatedMessageConfigs.length) {
        const staff = action.payload.staff.map((s) => ({
          ...s,
          displayValue: `${s.user.first_name || ''} ${s.user.last_name || ''}`,
        }));
        tempObject.staff = [...staff];
      }

      action.payload.automatedMessageConfigs.forEach((config) => {
        const { id, booking_auto_response, enquiry_auto_response, email_signature } = config;
        const authorizedRecipients = config.authorized_recipients.map((r) => ({
          ...r,
          displayValue: `${r.user.first_name || ''} ${r.user.last_name || ''}`,
        }));
        tempObject[config.brand.id] = {
          ...(tempObject[config.brand.id] || {}),
          authorizedRecipients,
          forwardingEmailAddress: config.forward_email?.mail_box?.email || '',
          id,
          booking_auto_response,
          enquiry_auto_response,
          email_signature,
        };
      });
      state.messagingTabData = { ...tempObject };
    },
    updateMessagingTab(
      state,
      action: PayloadAction<{ brandId: number; newObject: Partial<MessagingTabData> }>
    ) {
      const { brandId, newObject } = action.payload;
      state.messagingTabData[brandId] = { ...state.messagingTabData[brandId], ...newObject };
    },
    setContactDetailsTabData(
      state,
      action: PayloadAction<{ contactInfo: ContactInfo[]; socialMedia: SocialMedia[] }>
    ) {
      const tempObject = { ...state.contactDetailsTabData };
      action.payload.contactInfo.forEach((element) => {
        tempObject[element.brandId] = { ...tempObject[element.brandId], contactInfo: element };
      });
      action.payload.socialMedia.forEach((element) => {
        tempObject[element.brandId] = { ...tempObject[element.brandId], socialMedia: element };
      });
      state.contactDetailsTabData = { ...tempObject };
    },
    updateContactDetailsTabData(
      state,
      action: PayloadAction<{
        brandId: number;
        contactInfo?: Partial<ContactInfo>;
        socialMedia?: SocialMedia;
      }>
    ) {
      if (action.payload.contactInfo) {
        state.contactDetailsTabData[action.payload.brandId].contactInfo = {
          ...state.contactDetailsTabData[action.payload.brandId].contactInfo,
          ...action.payload.contactInfo,
        };
      }
      if (action.payload.socialMedia) {
        state.contactDetailsTabData[action.payload.brandId].socialMedia =
          action.payload.socialMedia;
      }
    },

    setBrand(state, action: PayloadAction<MarketingBrand>) {
      state.brand = { ...action.payload, menus: sortBrandMenus(action.payload.menus) };
    },

    setLocalisationTabData(
      state,
      action: PayloadAction<Partial<OrgConfigState['localisationTabData']>>
    ) {
      state.localisationTabData = {
        ...state.localisationTabData,
        ...action.payload,
      };
    },

    updateCountryConfigState(
      state,
      action: PayloadAction<{ id: number; fields: Partial<CountryConfig> }>
    ) {
      const index = state.localisationTabData.countryConfigs.findIndex(
        ({ id }) => id === action.payload.id
      );
      if (index > -1) {
        state.localisationTabData.countryConfigs[index] = {
          ...state.localisationTabData.countryConfigs[index],
          ...action.payload.fields,
        };
      }
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setBookingTabData,
  setAllBrands,
  updateMessagingTab,
  updateBookingTabData,
  setTaskTabData,
  updateTaskTabData,
  setFinanceTabData,
  updateFinanceTabData,
  setContactDetailsTabData,
  updateContactDetailsTabData,
  setLocalisationTabData,
  updateCountryConfigState,
} = slice.actions;

// ----------------------------------------------------------------------
export function getBrand(id: number) {
  return async () => {
    try {
      dispatch(slice.actions.setOrgConfigLoading(true));
      const res = await marketingAPI.fetchBrand(id);
      dispatch(slice.actions.setBrand(res.data));
    } catch (e) {
    } finally {
      dispatch(slice.actions.setOrgConfigLoading(false));
    }
  };
}

export function getAllBrands(callback?: ({ loading }: { loading: boolean }) => void) {
  return async () => {
    callback?.({ loading: true });
    try {
      const response = await orgConfigAPI().fetchAllBrands();
      dispatch(slice.actions.setAllBrands(response.data));
    } catch (error) {
    } finally {
      callback?.({ loading: false });
    }
  };
}

export function getBookingTabData() {
  return async () => {
    try {
      const response = await BookingApi.fetchCheckInReadyConditions();
      const response2 = await orgConfigAPI().fetchPreferences();
      const autoCheckInCheckOutSettingDate = [
        ...response2.data?.map((item) => ({
          brandId: item.brand_id,
          ...item.preferences
            ?.find((preference) => preference.group === 'booking')
            ?.categories.find((category) => category.name === 'Check-in, Check-out')
            ?.settings.find((setting) => setting.key === 'auto_checkout_bookings'),
        })),
      ];
      dispatch(
        slice.actions.setBookingTabData({
          checkInReadyCondition: response.data,
          autoCheckInCheckOutSetting: autoCheckInCheckOutSettingDate,
        })
      );
    } catch (error) {}
    dispatch(slice.actions.setOrgConfigLoading(false));
  };
}

export function getMessagingTabData({
  organizationId,
  brands,
}: {
  organizationId: number;
  brands: OrgConfigState['brands'];
}) {
  return async () => {
    try {
      dispatch(slice.actions.setMessagingTabLoading(true));

      const getStaff = async () => {
        try {
          const response = await orgConfigAPI().fetchStaff(organizationId);
          return response.data;
        } catch {
          return [];
        }
      };
      const getAutomatedmessageConfigs = async () => {
        try {
          const response = await automatedMessageAPI.fetchAutomatedMessageConfigs();
          return response.data;
        } catch {
          return [];
        }
      };

      const staff = await getStaff();
      const automatedMessageConfigs = await getAutomatedmessageConfigs();

      dispatch(
        slice.actions.setMessagingTabData({
          brands,
          staff,
          automatedMessageConfigs,
        })
      );
    } catch {
    } finally {
      dispatch(slice.actions.setMessagingTabLoading(false));
    }
  };
}

export function getTaskTabData() {
  return async () => {
    dispatch(slice.actions.setOrgConfigLoading(true));
    try {
      const response = await tasksAPI.fetchSetting('settings');
      const HKData: TaskSetting[] = response.data?.map((data) => ({
        mandatoryHousekeepingPhotos: data.mandatory_housekeeping_photos,
        midStayHousekeepingPeriod: data.mid_stay_housekeeping_period,
        autoApproval: data.auto_approval,
        brand_id: data.brands[0].id,
      }));
      dispatch(slice.actions.setTaskTabData({ taskSetting: HKData }));
    } catch (error) {}
    dispatch(slice.actions.setOrgConfigLoading(false));
  };
}

export function updateAutomatedMessageConfig(
  brandId: number,
  configId: number,
  requestBody: UpdateAutomatedMessageConfigBodyType,
  fieldToUpdate?: Partial<MessagingTabData>,
  setLoading?: (state: boolean) => void,
  callback?: (response: AxiosResponse<any, any> | null, status: number) => void
) {
  return async () => {
    try {
      setLoading?.(true);
      const response = await automatedMessageAPI.updateAutomatedMessageConfig(
        configId,
        requestBody
      );
      fieldToUpdate &&
        dispatch(slice.actions.updateMessagingTab({ brandId, newObject: fieldToUpdate }));
      callback?.(response, response.status);
    } catch (error) {
      callback?.(null, error?.status || 500);
    } finally {
      setLoading?.(false);
    }
  };
}

export function getFinanceTabData() {
  return async () => {
    dispatch(slice.actions.setOrgConfigLoading(true));
    try {
      const response = await orgConfigAPI().fetchPreferences();
      const preparedDate = [
        ...response.data?.map((item) => ({
          brandId: item.brand_id,
          ...item.preferences
            ?.find((preference) => preference.group === 'finance')
            ?.categories.find((category) => category.name === 'Gateways')
            ?.settings.find((setting) => setting.key === 'transaction_cost'),
        })),
      ];
      const bankAccountInfo = [
        ...response.data?.map((item) => ({
          brandId: item.brand_id,
          ...item.preferences
            ?.find((preference) => preference.group === 'finance')
            ?.categories.find((category) => category.name === 'Bank Account Information')
            ?.settings.find((setting) => setting.key === 'show_bank_account_information')
            ?.childSettings?.find((setting) => setting.key === 'bank_account_information'),
        })),
      ];
      const invoiceData = [
        ...response.data?.map((item) => ({
          brandId: item.brand_id,
          settings: item.preferences
            ?.find((preference) => preference.group === 'finance')
            ?.categories.find((category) => category.name === 'Invoice')?.settings,
        })),
      ];
      dispatch(
        slice.actions.setFinanceTabData({
          transactionCostSetting: preparedDate,
          bankAccountInfo: bankAccountInfo,
          invoiceSetting: invoiceData,
        })
      );
    } catch (error) {}
    dispatch(slice.actions.setOrgConfigLoading(false));
  };
}

export function getContactDetailsTabData() {
  return async () => {
    dispatch(slice.actions.setOrgConfigLoading(true));
    try {
      const response = await orgConfigAPI().fetchContactContactDetails();
      const contactInfo: ContactInfo[] = [];
      const socialMedia: SocialMedia[] = [];
      response.data?.forEach((element) => {
        contactInfo.push({
          brandId: element.brand_id,
          addresses: element.addresses,
          emails: element.emails,
          phones: element.phones,
          web_address: element.web_address,
          lines_open: element.lines_open,
        });
        socialMedia.push({ brandId: element.brand_id, ...element.socialMedias });
      });
      dispatch(
        slice.actions.setContactDetailsTabData({
          contactInfo: contactInfo,
          socialMedia: socialMedia,
        })
      );
    } catch (error) {}
    dispatch(slice.actions.setOrgConfigLoading(false));
  };
}

export function updateAutoApprovalAction(
  brandId: number,
  autoApproval: boolean,
  setLoadingState: (state: boolean) => void,
  callback: (succeed: boolean) => void
) {
  setLoadingState(true);
  return async () => {
    try {
      const response = await tasksAPI.updateTaskAutoApproval(brandId, autoApproval);
      if (response) {
        dispatch(
          slice.actions.updateTaskTabData({
            brandId: brandId,
            taskSetting: { autoApproval: autoApproval },
          })
        );
        callback(true);
      }
    } catch (error) {
      callback(false);
    }
    setLoadingState(false);
  };
}

export function updateMandatoryHousekeepingPhotosAction(
  brandId: number,
  mandatoryHousekeepingPhotos: boolean,
  setLoadingState: (state: boolean) => void,
  callback: (succeed: boolean) => void
) {
  setLoadingState(true);
  return async () => {
    try {
      const response = await tasksAPI.updateTaskMandatoryHousekeepingPhotos(
        brandId,
        mandatoryHousekeepingPhotos
      );
      if (response) {
        dispatch(
          slice.actions.updateTaskTabData({
            brandId: brandId,
            taskSetting: { mandatoryHousekeepingPhotos: mandatoryHousekeepingPhotos },
          })
        );
        callback(true);
      }
    } catch (error) {
      callback(false);
    }
    setLoadingState(false);
  };
}

export function updateMidStayHousekeepingPeriod(
  brandId: number,
  midStayHousekeepingPeriod: number,
  setLoadingState: (state: boolean) => void,
  callback: (succeed: boolean) => void
) {
  setLoadingState(true);
  return async () => {
    try {
      const response = await tasksAPI.updateTaskMidStayHousekeepingPhotos(
        brandId,
        midStayHousekeepingPeriod
      );
      if (response) {
        dispatch(
          slice.actions.updateTaskTabData({
            brandId: brandId,
            taskSetting: { midStayHousekeepingPeriod: midStayHousekeepingPeriod },
          })
        );
        callback(true);
      }
    } catch (error) {
      callback(false);
    }
    setLoadingState(false);
  };
}

export function updateContactDetails(
  brandId: number,
  body: any,
  updateFields?: { contactInfo?: Partial<ContactInfo>; socialMedia?: SocialMedia },
  setLoading?: (state: boolean) => void,
  callback?: (response: AxiosResponse<any, any> | null) => void
) {
  return async () => {
    try {
      setLoading?.(true);
      await orgConfigAPI().setContactInfo({ brand: brandId, ...body });
      updateFields &&
        dispatch(
          slice.actions.updateContactDetailsTabData({
            brandId,
            ...updateFields,
          })
        );
    } catch (e) {
      callback?.(null);
    } finally {
      setLoading?.(false);
    }
  };
}

export function getLocalisationTabData() {
  return async () => {
    dispatch(slice.actions.setOrgConfigLoading(true));
    try {
      const response = await orgConfigAPI().fetchCountryConfigs();
      dispatch(
        slice.actions.setLocalisationTabData({
          countryConfigs: response.data.map(({ id, country, weekend_days: weekendDays }) => ({
            id,
            country,
            weekendDays,
          })),
        })
      );
    } catch (error) {}
    dispatch(slice.actions.setOrgConfigLoading(false));
  };
}

export function updateCountryConfigs(
  requestsBody: { id: number; body: { weekend_days: string | null } }[],
  setLoading?: (state: boolean) => void,
  callback?: (response: AxiosResponse<any, any>[] | null) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const response = await axios.all(
        requestsBody.map(({ id, body }) => orgConfigAPI().updateCountryConfigs(id, body))
      );
      callback?.(response);
    } catch (error) {
      callback?.(null);
      console.log(error);
    }
    setLoading?.(false);
  };
}
