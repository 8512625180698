import axios from '../utils/axios';

function preferenceApi() {
  return {
    getPreference: (brandId: number) => {
      const url = `/apis/preferences/get?brand=${brandId}`;
      return axios.get(url);
    },
    updatePreferenceSetting: (body: { brand: number, key: string, value: any }) => {
      const url = `/apis/preferences/set`;
      return axios.put(url, body);
    },
    fetchBrands: (name: string, page: number) => {
      const url = '/apis/brands';
      return axios.get(url, {
        params: { limit: 10, page: page + 1, name, 'order[name]': 'asc' },
      });
    },
    getPaymentGateways: () => {
      const url = `/apis/payments/get_payment_gateways`;
      return axios.get(url);
    },
  };
}

export default preferenceApi();
