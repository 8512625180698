import { PayloadAction } from '@reduxjs/toolkit';
import { Variable } from 'src/@types/common';
import { Attachment, AutomatedMessage, OldTemplate, TemplateListItem, UnifiedInboxState, UpdatableAttachmentValues } from 'src/@types/unified-inbox';
import { convertHtmlToText } from 'src/utils/convertHtmlToText';
import { makeContentPreviewable, replaceContentEntitiesWithVariables } from 'src/utils/variables';
import { initialState } from './index'

let conversationMessages;
let index;
let messages;
let messageIndex;

export const messageReducers = {
  setNewMessage(state, action: PayloadAction<any>) {
    state.message = {
      ...state.message,
      messages: {
        ...state.message.messages,
        [action.payload.conversationId]: [
          action.payload.message,
          ...state.message.messages[action.payload.conversationId],
        ],
      },
    };
  },
  setNewMessageStatus(
    state,
    action: PayloadAction<{
      conversationId: number;
      messageId: number | string;
      status: 'sent' | 'pending' | 'failed';
      newMessageId?: number;
    }>
  ) {
    messages = state.message.messages[action.payload.conversationId];
    if (messages) {
      messageIndex = messages.map((msg) => msg.id).indexOf(action.payload.messageId);
      if (messageIndex >= 0) {
        messages[messageIndex] = {
          ...messages[messageIndex],
          status: action.payload.status,
          ...(action.payload.newMessageId && { id: action.payload.newMessageId }),
        };
        state.message = {
          ...state.message,
          messages: { ...state.message.messages, [action.payload.conversationId]: messages },
        };
      }
    }
    // state.message = state;
  },
  deleteMessage(state, action: PayloadAction<{
    conversationId: number;
    messageId: number | string;
  }>) {
    messages = state.message.messages[action.payload.conversationId];
    if (messages) {
      messageIndex = messages.map((msg) => msg.id).indexOf(action.payload.messageId);
      if (messageIndex >= 0) {
        messages.splice(messageIndex, 1);
        state.message = {
          ...state.message,
          messages: { ...state.message.messages, [action.payload.conversationId]: [...messages] },
        };
      }
    }
    // state.message = state;
  },
  setCheckSeen(state, action: PayloadAction<boolean>) {
    state.message = { ...state.message, checkSeen: action.payload };
  },
  changeMessageLoadingStatus(state) {
    state.message = { ...state.message, isLoading: !state.message.isLoading };
  },
  setEmailsForConversation(state, action: PayloadAction<any>) {
    state.message = { ...state.message, senderEmails: action.payload };
  },
  setDraftsForConversation(state, action: PayloadAction<any>) {
    state.message = {
      ...state.message,
      drafts: {
        ...state.message.drafts,
        [action.payload.conversationId]: action.payload.draft,
      },
    };
  },
  removeConversationDraft(state, action: PayloadAction<string>) {
    state.message = {
      ...state.message,
      drafts: {
        ...state.message.drafts,
        [action.payload]: undefined,
      },
    };
  },
  setMessagesForConversation(state, action: PayloadAction<{
    conversationId: number;
    messages: any;
  }>) {
    state.message = {
      ...state.message,
      messages: { ...state.message.messages, [action.payload.conversationId]: action.payload.messages },
    };
  },
  setMessage(state, action: PayloadAction<any>) {
    index = state.message.messages[action.payload.conversationId]
      .findIndex((message) => message.id === action.payload.message.id);
    messages = [...state.message.messages[action.payload.conversationId]];
    messages[index] = action.payload.message;
    state.message = {
      ...state.message,
      messages: {
        ...state.message.messages,
        [action.payload.conversationId]: messages,
      },
    };
  },
  setWebsocketMessage(
    state: UnifiedInboxState,
    action: PayloadAction<{ message: any, conversationId: number }>
  ) {
    if (state.message.messages[action.payload.conversationId]) {
      messages = [...state.message.messages[action.payload.conversationId]];
      index = messages.findIndex((message) => message.id === action.payload.message.id);
      if (index !== -1) messages[index] = action.payload.message;
      else messages = [action.payload.message, ...messages];
      state.message.messages = { ...state.message.messages, [action.payload.conversationId]: messages };
    }
  },
  setMessageType(state, action: PayloadAction<string>) {
    state.message = { ...state.message, messageType: action.payload };
  },
  setOutgoingMessageType(state, action: PayloadAction<string | null>) {
    state.message = { ...state.message, outgoingMessageType: action.payload };
  },
  setSendEmailLoading(state, action: PayloadAction<boolean>) {
    state.message = { ...state.message, sendEmailLoading: action.payload };
  },
  setEmailSubject(state, action: PayloadAction<string>) {
    state.message = { ...state.message, email: { ...state.message.email, subject: action.payload } };
  },
  setEmailBody(state, action: PayloadAction<string>) {
    state.message = { ...state.message, email: { ...state.message.email, body: action.payload } };
  },
  setEmailHtml(state, action: PayloadAction<string>) {
    state.message = { ...state.message, email: { ...state.message.email, html: action.payload } };
  },
  setEmailFrom(state, action: PayloadAction<any>) {
    state.message = {
      ...state.message,
      email:
        {
          ...state.message.email,
          from: action.payload.from,
          fromContact: action.payload.contact,
        },
    };
  },
  setEmailTo(state, action: PayloadAction<any>) {
    state.message = { ...state.message, email: { ...state.message.email, to: action.payload } };
  },
  setEmailCc(state, action: PayloadAction<any>) {
    state.message = { ...state.message, email: { ...state.message.email, cc: action.payload } };
  },
  deleteCc(state, action: PayloadAction<any>) {
    state.message.email.ccChips.splice(action.payload, 1);
    state.message.email.cc.splice(action.payload, 1);
  },
  deleteBcc(state, action: PayloadAction<any>) {
    state.message.email.bccChips.splice(action.payload, 1);
    state.message.email.bcc.splice(action.payload, 1);
  },
  setEmailBcc(state, action: PayloadAction<any>) {
    state.message = { ...state.message, email: { ...state.message.email, bcc: action.payload } };
  },
  setCcChips(state, action: PayloadAction<any>) {
    state.message = { ...state.message, email: { ...state.message.email, ccChips: action.payload } };
  },
  setBccChips(state, action: PayloadAction<any>) {
    state.message = { ...state.message, email: { ...state.message.email, bccChips: action.payload } };
  },
  setShowCc(state, action: PayloadAction<any>) {
    state.message = { ...state.message, showCc: action.payload };
  },
  setShowBcc(state, action: PayloadAction<any>) {
    state.message = { ...state.message, showBcc: action.payload };
  },
  setCcNotFound(state, action: PayloadAction<any>) {
    state.message = { ...state.message, email: { ...state.message.email, ccNotFound: action.payload } };
  },
  setBccNotFound(state, action: PayloadAction<any>) {
    state.message = { ...state.message, email: { ...state.message.email, bccNotFound: action.payload } };
  },
  setEmailPanelHeight(state, action: PayloadAction<any>) {
    state.message = { ...state.message, defaultPanelHeight: action.payload };
  },
  setSmsTo(state, action: PayloadAction<any>) {
    state.message = { ...state.message, smsTo: action.payload };
  },
  emptyEmail(state: UnifiedInboxState) {
    state.message = {
      ...state.message,
      showCc: initialState.message.showCc,
      showBcc: initialState.message.showBcc,
      email: {
        ...initialState.message.email,
        to: state.message.email.to,
        from: state.message.email.from,
        fromContact: state.message.email.fromContact,
      },
      templateContent: initialState.message.templateContent,
      messageContent: initialState.message.messageContent,
      htmlMessageContent: initialState.message.htmlMessageContent,
      attachFiles: initialState.message.attachFiles,
      isHtmlTemplate: initialState.message.isHtmlTemplate,
    };
  },
  setMessageContents(state, action: PayloadAction<{ 
    messageContent: string;
    htmlMessageContent: string; 
  }>) {
    state.message = {
      ...state.message,
      messageContent: action.payload.messageContent,
      htmlMessageContent: action.payload.htmlMessageContent,
    };
  },
  emptyMessageContents(state) {
    state.message = {
      ...state.message,
      messageContent: initialState.message.messageContent,
      htmlMessageContent: initialState.message.htmlMessageContent,
      templateContent: initialState.message.templateContent,
    };
  },
  setEditorSelectedValue(state, action: PayloadAction<string>) {
    state.message = { ...state.message, editorSelectedValue: action.payload };
  },
  newEmail(state, action: PayloadAction<any>) {
    state.message = {
      ...state.message,
      messages: {
        ...state.message.messages,
        [action.payload.conversationId]: [
          {
            id: action.payload.uuid,
            status: action.payload.status,
            email_body: {
              subject: action.payload.subject,
              text_body: action.payload.body,
              html_body: action.payload.html,
              to: action.payload.to,
              cc: action.payload.cc,
              bcc: action.payload.bcc,
              created: action.payload.created_at,
            },
            inner_contact: { ...action.payload.fromContact },
            from: action.payload.from,
            seen_histories: [{ contact: action.payload.userContact }],
            sender: {
              first_name: action.payload.fromContact.first_name,
              last_name: action.payload.fromContact.last_name,
            },
            contact: {
              ...action.payload.contact,
            },
            attachments: action.payload.attachments,
            created_at: action.payload.created_at,
            updated_at: action.payload.created_at,
          },
          ...state.message.messages[action.payload.conversationId],
        ],
      },
    };
  },
  newSms(state, action: PayloadAction<any>) {
    state.message = {
      ...state.message,
      messages: {
        ...state.message.messages,
        [action.payload.conversationId]: [
          {
            id: action.payload.uuid,
            status: action.payload.status,
            sms: {
              subject: '',
              text: action.payload.message,
            },
            inner_contact: action.payload.fromContact,
            to: action.payload.to,
            seen_histories: [{ contact: action.payload.userContact }],
            sender: {
              first_name: action.payload.contact.first_name,
              last_name: action.payload.contact.last_name,
            },
            contact: action.payload.contact,
            created_at: action.payload.created_at,
            updated_at: action.payload.created_at,
          },
          ...state.message.messages[action.payload.conversationId],
        ],
      },
    };
  },
  newApiMessage(state, action: PayloadAction<any>) {
    state.message = {
      ...state.message,
      messages: {
        ...state.message.messages,
        [action.payload.conversationId]: [
          {
            id: action.payload.uuid,
            status: action.payload.status,
            messaging_api_line: {
              response_message: action.payload.message,
              created_at: action.payload.created_at,
              channel_name: action.payload.channelName,
            },
            inner_contact: action.payload.userContact,
            seen_histories: [{ contact: action.payload.userContact }],
            sender: {
              first_name: action.payload.contact.first_name,
              last_name: action.payload.contact.last_name,
            },
            contact: action.payload.contact,
            created_at: action.payload.created_at,
            updated_at: action.payload.created_at,
          },
          ...state.message.messages[action.payload.conversationId],
        ],
      },
    };
  },
  seenConversationMessages(state, action: PayloadAction<any>) {
    conversationMessages = state.message.messages[action.payload.conversationId];
    if (conversationMessages) {
      conversationMessages = conversationMessages.map((message) => {
        if (message) {
          const isRead = message.seen_histories.some(
            (data) => data.contact.id === action.payload.contact.id,
          );
          if (!isRead) message.seen_histories.push({ contact: action.payload.contact });
        }
        return message;
        // state.message = message;
      });
      state.message = {
        ...state.message,
        messages: {
          ...state.message.messages,
          [action.payload.conversationId]: conversationMessages,
        },
      };
    }
    // state.message = state;
  },
  unseenConversationMessages(state, action: PayloadAction<any>) {
    conversationMessages = state.message.messages[action.payload.conversationId];
    if (conversationMessages) {
      conversationMessages = conversationMessages.map((message) => {
        if (message) {
          const indexOfContact = message.seen_histories.map((history) => history.contact.id)
            .indexOf(action.payload.contactId);
          if (indexOfContact !== -1) {
            message.seen_histories.splice(indexOfContact, 1);
          }
        }
        return message;
        // state.message = message;
      });
      state.message = {
        ...state.message,
        messages: {
          ...state.message.messages,
          [action.payload.conversationId]: conversationMessages,
        },
      };
    }
    // state.message = state;
  },
  setIsOpen(state, action: PayloadAction<boolean>) {
    state.message = {
      ...state.message,
      isOpen: action.payload,
    };
  },
  setLanguages(state: UnifiedInboxState, action: PayloadAction<{ [x: string]: string } | null>) {
    state.message.templateForm.languages = action.payload;
  },
  setVariables(state: UnifiedInboxState, action: PayloadAction<Variable[]>) {
    state.message.templateForm.variables = action.payload;
  },
  setTemplateFormIsOpen(state, action: PayloadAction<boolean>) {
    state.message = { ...state.message, templateForm: { ...state.message.templateForm, formIsOpen: action.payload } };
  },
  setTemplatesList(state, action: PayloadAction<{ v1: OldTemplate[], v2: AutomatedMessage[], variables: Variable[], languages: { [x: string]: string } }>) {
    const { v1, v2, variables, languages } = action.payload;
    const v1List: TemplateListItem[] = v1.map((item) => ({
      id: item.id,
      name: item.name,
      oldTemplateData: item,
      automatedMessageData: null,
      children: [],
    }));
    const v2List: TemplateListItem[] = v2.map((item) => ({
      id: item.id,
      name: item.name,
      automatedMessageData: item,
      oldTemplateData: null,
      children: item.templates.map((temp) => ({
        id: temp.id,
        name: convertHtmlToText(makeContentPreviewable(
          replaceContentEntitiesWithVariables(temp.content[0].subject || temp.content[0].messageText || '', variables),
          variables
        )),
        type: temp.type,
        automatedMessageTemplateData: temp,
        contentLanguages: temp.content.map((content) => ({
          value: content.language,
          label: languages[content.language],
          bodyContentId: content.body_id,
        }))
      })),
    }));
    state.message.templateForm.templateList = [...v1List, ...v2List];
  },
  setTemplateListIsLoadingStatus(state, action: PayloadAction<boolean>) {
    state.message = { ...state.message, templateForm: { ...state.message.templateForm, isLoading: action.payload } };
  },
  setSelectedTemplate(state, action: PayloadAction<TemplateListItem | null>) {
    state.message.templateForm.selectedTemplate = action.payload;
  },
  setSelectedTemplateIsHtml(state, action: PayloadAction<{ original?: boolean, changeable?: boolean }>) {
    state.message.templateForm.selectedTemplateIsHtml = {
      ...state.message.templateForm.selectedTemplateIsHtml,
      ...action.payload,
    };
  },
  setSelectedTemplateContentId(state, action: PayloadAction<number | null>) {
    state.message.templateForm.selectedTemplateContentId = action.payload;
  },
  setTemplateContent(state, action: PayloadAction<any>) {
    state.message = { ...state.message, templateContent: action.payload };
  },
  setIsInsertingTemplate(state, action: PayloadAction<boolean>) {
    state.message = { ...state.message, isInsertingTemplate: action.payload };
  },
  setIsHtmlTemplate(state, action: PayloadAction<boolean>) {
    state.message = { ...state.message, isHtmlTemplate: action.payload };
  },
  clearForm(state: UnifiedInboxState) {
    state.message = {
      ...state.message,
      insertLinkForm: { ...initialState.message.insertLinkForm },
      templateForm: {
        ...initialState.message.templateForm,
        languages: state.message.templateForm.languages,
        variables: state.message.templateForm.variables
      },
    };
  },
  setCcList(state, action: PayloadAction<any>) {
    state.message = {
      ...state.message,
      email: {
        ...state.message.email,
        ccList: [...state.message.email.ccList, ...action.payload],
      },
    };
  },
  nextCcPage(state) {
    state.message = {
      ...state.message,
      email: {
        ...state.message.email,
        ccPage: state.message.email.ccPage + 1,
      },
    };
  },
  resetCcList(state) {
    state.message = {
      ...state.message,
      email: {
        ...state.message.email,
        ccList: [],
        ccPage: 0,
      },
    };
  },
  setBccList(state, action: PayloadAction<any>) {
    state.message = {
      ...state.message,
      email: {
        ...state.message.email,
        bccList: [...state.message.email.bccList, ...action.payload],
      },
    };
  },
  nextBccPage(state) {
    state.message = {
      ...state.message,
      email: {
        ...state.message.email,
        bccPage: state.message.email.bccPage + 1,
      },
    };
  },
  resetBccList(state) {
    state.message = {
      ...state.message,
      email: {
        ...state.message.email,
        bccList: [],
        bccPage: 0,
      },
    };
  },
  clearMessage(state: UnifiedInboxState) {
    state.message.attachFiles.attachments.forEach((attach) => {
      if (attach.status === 'uploading' && attach.controller) attach.controller.abort();
    });
    state.message = {
      ...state.message,
      showCc: initialState.message.showCc,
      showBcc: initialState.message.showBcc,
      email: initialState.message.email,
      smsTo: initialState.message.smsTo,
      messageContent: initialState.message.messageContent,
      htmlMessageContent: initialState.message.htmlMessageContent,
      attachFiles: initialState.message.attachFiles,
      isHtmlTemplate: initialState.message.isHtmlTemplate,
    };
  },
  setDefaultEmail(state, action: PayloadAction<string>) {
    state.message = {
      ...state.message,
      defaultEmail: action.payload,
    };
  },
  setDefaultPhone(state, action: PayloadAction<string>) {
    state.message = {
      ...state.message,
      defaultPhone: action.payload,
    };
  },
  setShouldScrollToLastMessage(state, action: PayloadAction<boolean>) {
    state.message = {
      ...state.message,
      shouldScrollToLastMessage: action.payload,
    };
  },
  setTemplateIsLoading(state, action: PayloadAction<boolean>) {
    state.message = { ...state.message, templateIsLoading: action.payload };
  },
  setToolbarIsOpen(state, action: PayloadAction<any>) {
    state.message = {
      ...state.message,
      toolbarIsOpen: action.payload,
      defaultPanelHeight: action.payload
        ? state.message.defaultPanelHeight + 48
        : state.message.defaultPanelHeight - 48,
    };
  },
  setInsertLinkFormIsOpen(state, action: PayloadAction<boolean>) {
    state.message = {
      ...state.message,
      insertLinkForm: {
        ...state.message.insertLinkForm,
        formIsOpen: action.payload,
      },
    };
  },
  setInsertLinkTextToDisplay(state, action: PayloadAction<string>) {
    state.message = {
      ...state.message,
      insertLinkForm: {
        ...state.message.insertLinkForm,
        textToDisplay: action.payload,
      },
    };
  },
  setInsertLinkLinkUrl(state, action: PayloadAction<string>) {
    state.message = {
      ...state.message,
      insertLinkForm: {
        ...state.message.insertLinkForm,
        linkUrl: action.payload,
      },
    };
  },
  setHasInsertedLink(state, action: PayloadAction<boolean>) {
    state.message = { ...state.message, hasInsertedLink: action.payload };
  },
  setCaretPosition(state, action: PayloadAction<any>) {
    state.message = { ...state.message, caretPosition: action.payload };
  },
  setCurrentSentMessageId(state, action: PayloadAction<any>) {
    state.message = { ...state.message, currentSentMessageId: action.payload };
  },
  SELECT_CONVERSATION(state) {
    state.message = { ...initialState.message, messages: { ...state.message.messages }, drafts: { ...state.message.drafts } };
  },
  setAttachment(state: UnifiedInboxState, action: PayloadAction<Attachment>) {
    state.message.attachFiles.attachments = [...state.message.attachFiles.attachments, action.payload];
  },
  updateAttachment(state: UnifiedInboxState, action: PayloadAction<{ id: number | string, update: UpdatableAttachmentValues }>) {
    const { attachments } = state.message.attachFiles;
    const findIndex = attachments.findIndex((attachment) => attachment.id === action.payload.id);
    if (findIndex > -1) state.message.attachFiles.attachments[findIndex] = { ...attachments[findIndex], ...action.payload.update };
  },
  removeAttachment(state: UnifiedInboxState, action: PayloadAction<number | string>) {
    state.message.attachFiles.attachments = state.message.attachFiles.attachments.filter((attachment) => attachment.id !== action.payload);
  },
  setCanSaveAttachmentsInDraft(state: UnifiedInboxState, action: PayloadAction<boolean>) {
    state.message.attachFiles.canSaveAttachmentsInDraft = action.payload;
  },
  updateAttachFilesErrorPopup(state: UnifiedInboxState, action: PayloadAction<{ open: boolean, title?: 'huge_files' | 'unsupported_file_format' }>) {
    state.message.attachFiles.errorPopup = { open: action.payload.open, title: action.payload.title ?? 'huge_files' };
  },
  setShowNewMessagesAlert(state: UnifiedInboxState, action: PayloadAction<boolean>) {
    state.message.showNewMessagesAlert = action.payload;
  },
  setTotalUnseenMessagesCount(state: UnifiedInboxState, action: PayloadAction<number>) {
    state.message.totalUnseenMessagesCount = action.payload;
  },
};

// ----------------------------------------------------------------------
