import { useState, useEffect, useCallback } from 'react';

// ----------------------------------------------------------------------

export default function useOffSetTop(top: number) {
  const [offsetTop, setOffSetTop] = useState(false);
  const isTop = top || 100;

  const handleScroll = useCallback(() => {
    if (window.pageYOffset > isTop) {
      setOffSetTop(true);
    } else {
      setOffSetTop(false);
    }
  }, [top])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isTop]);

  return offsetTop;
}

// Usage
// const offset = useOffSetTop(100);
