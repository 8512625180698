import { DateFilterSelectValue } from 'src/@types/filter';
import { fYearMonthDay } from 'src/utils/formatTime';
import {
  addDays,
  addMonths,
  subDays,
  subMonths,
  subYears,
  startOfMonth,
  startOfYear,
  addWeeks,
  addYears,
  startOfWeek,
  startOfQuarter,
  endOfQuarter,
} from 'date-fns';

export const getDateFilterFromToDate = (option: DateFilterSelectValue | null) => {
  let from: Date | null = null;
  let to: Date | null = null;

  switch (option) {
    case 'all':
      from = null;
      to = null;
      break;
    case 'upcoming':
      from = new Date();
      to = null;
      break;
    case 'previous':
      from = null;
      to = new Date();
      break;
    case 'today':
      from = new Date();
      to = addDays(new Date(), 1);
      break;
    case 'tomorrow':
      from = addDays(new Date(), 1);
      to = addDays(new Date(), 1);
      break;
    case 'yesterday':
      from = subDays(new Date(), 1);
      to = new Date();
      break;
    case 'this_week':
      from = startOfWeek(new Date(), { weekStartsOn: 1 });
      to = startOfWeek(addWeeks(new Date(), 1), { weekStartsOn: 1 });
      break;
    case 'next_7_days':
      from = new Date();
      to = addDays(new Date(), 7);
      break;
    case 'past_7_days':
      from = subDays(new Date(), 7);
      to = new Date();
      break;
    case 'next_30_days':
      from = new Date();
      to = addDays(new Date(), 30);
      break;
    case 'past_30_days':
      from = subDays(new Date(), 30);
      to = new Date();
      break;
    case 'this_quarter':
      from = startOfQuarter(new Date());
      to = addDays(endOfQuarter(new Date()), 1);
      break;
    case 'this_month':
      from = startOfMonth(new Date());
      to = startOfMonth(addMonths(new Date(), 1));
      break;
    case 'next_month':
      from = startOfMonth(addMonths(new Date(), 1));
      to = startOfMonth(addMonths(new Date(), 2));
      break;
    case 'last_month':
      from = startOfMonth(subMonths(new Date(), 1));
      to = startOfMonth(new Date());
      break;
    case 'past_12_months':
      from = subMonths(new Date(), 12);
      to = new Date();
      break;
    case 'last_year':
      from = startOfYear(subYears(new Date(), 1));
      to = startOfYear(new Date());
      break;
    case 'this_year':
      from = startOfYear(new Date());
      to = startOfYear(addYears(new Date(), 1));
      break;
    case 'next_year':
      from = startOfYear(addYears(new Date(), 1));
      to = startOfYear(addYears(new Date(), 2));
      break;
    case 'start_of_week':
      from = startOfWeek(new Date(), { weekStartsOn: 1 });
      to = addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 1);
      break;
    case 'start_of_month':
      from = startOfMonth(new Date());
      to = addDays(startOfMonth(new Date()), 1);
      break;
    case 'start_of_quarter':
      from = startOfQuarter(new Date());
      to = addDays(startOfQuarter(new Date()), 1);
      break;
    case 'start_of_year':
      from = startOfYear(new Date());
      to = addDays(startOfYear(new Date()), 1);
      break;
    default:
      break;
  }

  return { from, to };
};

export const getDateFilterFromTo = (option: DateFilterSelectValue | null) => {
  let from: string | null = null;
  let to: string | null = null;

  switch (option) {
    case 'all':
      from = null;
      to = null;
      break;
    case 'upcoming':
      from = fYearMonthDay(new Date());
      to = null;
      break;
    case 'previous':
      from = null;
      to = fYearMonthDay(new Date());
      break;
    case 'today':
      from = fYearMonthDay(new Date());
      to = fYearMonthDay(addDays(new Date(), 1));
      break;
    case 'tomorrow':
      from = fYearMonthDay(addDays(new Date(), 1));
      to = fYearMonthDay(addDays(new Date(), 2));
      break;
    case 'yesterday':
      from = fYearMonthDay(subDays(new Date(), 1));
      to = fYearMonthDay(new Date());
      break;
    case 'this_week':
      from = fYearMonthDay(startOfWeek(new Date(), { weekStartsOn: 1 }));
      to = fYearMonthDay(startOfWeek(addWeeks(new Date(), 1), { weekStartsOn: 1 }));
      break;
    case 'next_7_days':
      from = fYearMonthDay(new Date());
      to = fYearMonthDay(addDays(new Date(), 7));
      break;
    case 'past_7_days':
      from = fYearMonthDay(subDays(new Date(), 7));
      to = fYearMonthDay(new Date());
      break;
    case 'next_30_days':
      from = fYearMonthDay(new Date());
      to = fYearMonthDay(addDays(new Date(), 30));
      break;
    case 'past_30_days':
      from = fYearMonthDay(subDays(new Date(), 30));
      to = fYearMonthDay(new Date());
      break;
    case 'this_quarter':
      from = fYearMonthDay(startOfQuarter(new Date()));
      to = fYearMonthDay(addDays(endOfQuarter(new Date()), 1));
      break;
    case 'this_month':
      from = fYearMonthDay(startOfMonth(new Date()));
      to = fYearMonthDay(startOfMonth(addMonths(new Date(), 1)));
      break;
    case 'next_month':
      from = fYearMonthDay(startOfMonth(addMonths(new Date(), 1)));
      to = fYearMonthDay(startOfMonth(addMonths(new Date(), 2)));
      break;
    case 'last_month':
      from = fYearMonthDay(startOfMonth(subMonths(new Date(), 1)));
      to = fYearMonthDay(startOfMonth(new Date()));
      break;
    case 'past_12_months':
      from = fYearMonthDay(subMonths(new Date(), 12));
      to = fYearMonthDay(new Date());
      break;
    case 'last_year':
      from = fYearMonthDay(startOfYear(subYears(new Date(), 1)));
      to = fYearMonthDay(startOfYear(new Date()));
      break;
    case 'this_year':
      from = fYearMonthDay(startOfYear(new Date()));
      to = fYearMonthDay(startOfYear(addYears(new Date(), 1)));
      break;
    case 'next_year':
      from = fYearMonthDay(startOfYear(addYears(new Date(), 1)));
      to = fYearMonthDay(startOfYear(addYears(new Date(), 2)));
      break;
    case 'start_of_week':
      from = fYearMonthDay(startOfWeek(new Date(), { weekStartsOn: 1 }));
      to = fYearMonthDay(addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 1));
      break;
    case 'start_of_month':
      from = fYearMonthDay(startOfMonth(new Date()));
      to = fYearMonthDay(addDays(startOfMonth(new Date()), 1));
      break;
    case 'start_of_quarter':
      from = fYearMonthDay(startOfQuarter(new Date()));
      to = fYearMonthDay(addDays(startOfQuarter(new Date()), 1));
      break;
    case 'start_of_year':
      from = fYearMonthDay(startOfYear(new Date()));
      to = fYearMonthDay(addDays(startOfYear(new Date()), 1));
      break;
    default:
      break;
  }
  return { from, to };
};
