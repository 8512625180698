import { Stack } from '@mui/material';
import SkeletonTabSingleContent from './SkeletonTabSingleContent';

const SkeletonTabContent = () => (
  <Stack gap={3}>
    <SkeletonTabSingleContent />
    <SkeletonTabSingleContent size="large" />
  </Stack>
);

export default SkeletonTabContent;
