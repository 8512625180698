// @mui
import { Stack, Skeleton, StackProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function SkeletonMap({ direction = 'column', count = 5, height = 560, spacing = 8, width = 1 }) {
  return (
    <Stack direction={direction as StackProps['direction']} spacing={spacing}>
      {[...Array(count)].map((_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          sx={{ width, height, borderRadius: 2 }}
        />
      ))}
    </Stack>
  );
}
