import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { AllTaskType, HousekeepingState, Task, TaskPriority, TaskStatus, TaskTab, TaskType } from '../../../@types/tasks';
//
import { dispatch, RootState } from '../../store';
import tasksAPI from 'src/api/tasks';
import filterAPI from 'src/api/filter';
import { FilterValue } from 'src/@types/filter';
import { getTimeFilterOfView } from 'src/utils/tasks';
import { getDateFilterFromTo } from 'src/utils/getDateFilterFromTo';
// ----------------------------------------------------------------------

const initialState: HousekeepingState = {
  isLoading: false,
  hasMorePage: true,
  page: 1,
  limit: 30,
  error: null,
  tableData: [],
  drawers: {
    activeTaskId: null,
    assigneeOpen: false,
    timeFrameOpen: false,
    addNoteOpen: false,
  },
  filters: {
    tabValue: 'upcoming',
    type: 'housekeeping',
    timeFrameOrder: 'asc',
    taskType: null,
    status: null,
    booking: null,
    cities: [],
    property: null,
    unit: null,
    priority: null,
    assignee: null,
    showCancelled: false,
    showOnlyUnassigned: false,
    showOnlyEarlyCheckIn: false,
    showOnlyNotCompleted: false,
    date: {
      ...(getDateFilterFromTo('next_30_days')),
      selectValue: 'next_30_days',
    },
  },
  options: {
    loading: false,
    statusOptions: [],
    cityOptions: [],
    propertyOptions: [],
    priorityOptions: [],
    assigneeOptions: [],
    taskTypeOptions: [],
  },
  setting: {
    brands: [],
    settingsData: [],
  }
};

export const loadTasks = createAsyncThunk<
  { tasks: Task[], isLoadingMore: boolean },
  boolean,
  { state: RootState; rejectValue: string }
>(
  'housekeeping/loadTasks',
  async (isLoadingMore, thunkApi) => {
    if (!isLoadingMore) {
      thunkApi.dispatch(setTableData([]));
      thunkApi.dispatch(setHasMorePage(true));
      thunkApi.dispatch(setPage(1));
    }

    const { limit, page, filters } = thunkApi.getState().tasks.housekeeping;
    const { date, ...otherFilters } = filters;
    const response = await tasksAPI.fetchHousekeepingTasks({
      limit,
      page,
      ...otherFilters,
      dueDateFrom: date.from,
      dueDateTo: date.to,
      signal: thunkApi.signal,
    });

    if (response.status !== 200) {
      return thunkApi.rejectWithValue('Failed to fetch tasks.');
    }

    return { tasks: response.data.data, isLoadingMore };
  }
);

const slice = createSlice({
  name: 'housekeeping',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // SET ACTIVE TASK ID
    setActiveTaskId(state, action) {
      state.drawers.activeTaskId = action.payload;
    },

    // SET ASSIGNEE DRAWER OPEN
    setAssigneeOpen(state, action) {
      state.drawers.assigneeOpen = action.payload;
    },

    // SET ASSIGNEE DRAWER OPEN
    setTimeFrameOpen(state, action) {
      state.drawers.timeFrameOpen = action.payload;
    },

    // SET ADD NOTE DRAWER OPEN
    setAddNoteOpen(state, action) {
      state.drawers.addNoteOpen = action.payload;
    },

    // SET HAS MORE PAGE
    setHasMorePage(state, action) {
      state.hasMorePage = action.payload;
    },

    // SET TABLE DATA
    setTableData(state, action) {
      state.tableData = action.payload;
    },

    // CHANGE TASK PRIORITY
    changeTaskPriority(state, action) {
      const index = state.tableData.findIndex((data) => data.id === action.payload.id);
      if (index > -1) {
        state.tableData[index] = {
          ...state.tableData[index],
          priority: action.payload.priority,
        };
      }
    },

    // CHANGE TASK PRIORITY
    changeTaskAssignee(state, action) {
      const index = state.tableData.findIndex((data) => data.id === action.payload.id);
      if (index > -1) {
        state.tableData[index] = {
          ...state.tableData[index],
          assignee: action.payload.assignee,
          isUnassigned: false,
        };
      }
    },

    // CHANGE TASK PRIORITY
    changeTaskStatus(state, action) {
      const index = state.tableData.findIndex((data) => data.id === action.payload.id);
      if (index > -1) {
        state.tableData[index] = {
          ...state.tableData[index],
          status: action.payload.status,
        };
      }
    },

    // CHANGE TASK TIME FRAME
    changeTaskTimeFrame(state, action: PayloadAction<{ id: number | null, estimation: number, canStart: Date, mustFinish: Date }>) {
      const { id, canStart, estimation, mustFinish } = action.payload;
      const index = state.tableData.findIndex((data) => data.id === id);
      if (index > -1) {
        state.tableData[index] = {
          ...state.tableData[index],
          canStart,
          estimation,
          mustFinish,
        };
      }
    },

    // APPEND TABLE DATA
    appendTableData(state, action) {
      state.tableData = [...state.tableData, ...action.payload];
    },

    // SET TAB VALUE
    setTabValue(state, action: PayloadAction<TaskTab>) {
      const newTabValue = action.payload;
      state.filters = {
        ...state.filters,
        tabValue: newTabValue,
        timeFrameOrder: newTabValue === 'previous' ? 'desc' : 'asc',
        showOnlyUnassigned: newTabValue === 'unassigned' ? true :
          state.filters.tabValue === 'unassigned' ? false : state.filters.showOnlyUnassigned,
        status: newTabValue === 'unassigned' ? null : state.filters.status,
        showOnlyEarlyCheckIn: newTabValue === 'early_checkin' ? true :
          state.filters.tabValue === 'early_checkin' ? false : state.filters.showOnlyEarlyCheckIn,
        date: {
          selectValue: newTabValue === 'all' ? null : getTimeFilterOfView(newTabValue),
          ...getDateFilterFromTo(getTimeFilterOfView(newTabValue)),
        }
      };
    },

    // SET FILERTS
    setFilters(state, action: PayloadAction<{
      type?: TaskType | null;
      status?: TaskStatus | null;
      taskType?: AllTaskType | null;
      booking?: number | null;
      cities?: FilterValue[];
      property?: number | null;
      unit?: number | null;
      priority?: TaskPriority | null;
      assignee?: number | null;
      showCancelled?: boolean;
      showOnlyUnassigned?: boolean;
      showOnlyEarlyCheckIn?: boolean;
      showOnlyNotCompleted?: boolean;
      timeFrameOrder?: 'asc' | 'desc';
    }>) {
      const {
        type, status, booking, cities, property, unit, priority, assignee, showCancelled,
        showOnlyUnassigned, showOnlyEarlyCheckIn, timeFrameOrder, taskType, showOnlyNotCompleted,
      } = action.payload;
      state.filters = {
        ...state.filters,
        ...(typeof type !== 'undefined' && { type }),
        ...(typeof status !== 'undefined' && { status }),
        ...(typeof booking !== 'undefined' && { booking }),
        ...(typeof taskType !== 'undefined' && { taskType }),
        ...(typeof cities !== 'undefined' && { cities }),
        ...(typeof property !== 'undefined' && { property }),
        ...(typeof unit !== 'undefined' && { unit }),
        ...(typeof priority !== 'undefined' && { priority }),
        ...(typeof assignee !== 'undefined' && { assignee }),
        ...(typeof showCancelled !== 'undefined' && { showCancelled }),
        ...(typeof showOnlyNotCompleted !== 'undefined' && { showOnlyNotCompleted }),
        ...(typeof timeFrameOrder !== 'undefined' && { timeFrameOrder }),
        ...(typeof showOnlyUnassigned !== 'undefined' && { showOnlyUnassigned }),
        ...(typeof showOnlyEarlyCheckIn !== 'undefined' && { showOnlyEarlyCheckIn }),

        ...(typeof status !== 'undefined' && { showCancelled: status === 'cancelled' }),
        ...(assignee && { showOnlyUnassigned: false }),
        ...(showOnlyUnassigned && { assignee: null }),
      };
    },

    // SET OPTIONS
    setOptions(state, action) {
      state.options = action.payload;
    },
    setLoadingOptions(state, action) {
      state.options.loading = action.payload;
    },

    // SET PAGE
    setPage(state, action) {
      state.page = action.payload;
    },

    // SET DATE
    setDate(state, action) {
      const descValues = ['past_30_days', 'past_7_days', 'last_month', null];
      const { from, to, selectValue } = action.payload;
      state.filters.date = {
        ...state.filters.date,
        selectValue,
        from,
        to,
      };
      state.filters.timeFrameOrder = descValues.includes(selectValue) ? 'desc' : 'asc';
    },

    setBrands(state, action) {
      state.setting.brands = action.payload;
    },

    setSettingsData(state, action) {
      const defaultSetting = {
        brand_id: action.payload.brandId,
        autoApproval: false,
        mandatoryHousekeepingPhotos: false,
        midStayHousekeepingPeriod: 0,
        ...action.payload.data
      };
      const hasSetting = state.setting.settingsData.find(
        (setting) => setting.brand_id === action.payload.brandId
      );
      if (hasSetting) {
        const index = state.setting.settingsData.indexOf(hasSetting);
        if (index > -1) {
          state.setting.settingsData[index] = {...state.setting.settingsData[index], ...action.payload.data};
        }
      } else {
        state.setting.settingsData.push(defaultSetting)
      }
    },

    setSetting(state, action) {
      state.setting.settingsData = action.payload;
    },

    // RESET FILTERS
    resetFilters(state) {
      state.filters = initialState.filters;
    },
  },
  extraReducers: (builder) => {
    // LOAD TASKS
    builder.addCase(loadTasks.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(loadTasks.fulfilled, (state, { payload }) => {
      if (payload.tasks.length < state.limit) state.hasMorePage = false;
      state.page = state.page + 1;
      state.tableData = payload.isLoadingMore ? [...state.tableData, ...mapDataToTasks(payload.tasks)] : mapDataToTasks(payload.tasks);
      state.isLoading = false;
    });

    builder.addCase(loadTasks.rejected, (state, { payload, meta }) => {
      state.error = payload || '';
      if (!meta.aborted) {
        state.isLoading = false;
        state.hasMorePage = false;
      }
    });
  },
});

// ReducergetInitialState
export default slice.reducer;

// Actions
export const {
  startLoading,
  setHasMorePage,
  setPage,
  setTabValue,
  setActiveTaskId,
  setAssigneeOpen,
  setTimeFrameOpen,
  changeTaskAssignee,
  changeTaskStatus,
  changeTaskTimeFrame,
  setAddNoteOpen,
  setTableData,
  setFilters,
  setOptions,
  setDate,
  resetFilters,
  setSettingsData,
  setBrands,
} = slice.actions;

// ----------------------------------------------------------------------
export function loadFilters() {
  return async () => {
    dispatch(slice.actions.setLoadingOptions(true));
    try {
      await axios
        .all([
          filterAPI.fetchStatus(),
          filterAPI.fetchCities(),
          filterAPI.fetchProperty(),
          filterAPI.fetchPriority(),
          filterAPI.fetchAssignee(),
          filterAPI.fetchTaskTypes(),
        ])
        .then(
          axios.spread((statusResponse, cityResponse, propertyResponse, priorityResponse, assigneeResponse, typeResponse) => {
            dispatch(
              slice.actions.setOptions({
                statusOptions: statusResponse.data.status,
                cityOptions: cityResponse?.data?.map((item) => ({ id: item.id, label: item.name, value: item.id })) || [],
                propertyOptions: propertyResponse.data,
                priorityOptions: priorityResponse.data.priority,
                assigneeOptions: assigneeResponse.data.map((assignee) => assignee.user),
                taskTypeOptions: typeResponse.data,
              })
            );
          })
        )
        .catch((error) => {});
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    dispatch(slice.actions.setLoadingOptions(false));
  };
}

function mapDataToTasks(data): Task[] {
  return data.map((task) => ({
    id: task.id,
    taskType: task.type,
    city: task.booking?.property?.area?.city?.name || '',
    property: task.booking?.property?.short_name,
    unit: task.booking?.unit?.name,
    assignee: task.owner?.first_name + ' ' + task.owner?.last_name,
    assigneeId: task.owner?.id,
    canStart: new Date(task.not_sooner),
    mustFinish: new Date(task.deadline),
    timezone: task.booking?.timezone,
    estimation: task.estimated_time,
    date: new Date(task.not_sooner),
    status: task.progress_status || 'not_started',
    priority: task.priority,
    isEarlyCheckIn: task.is_early_check_in,
    isOverdue: task.is_overdue,
    isUnassigned: !task.owner || task.owner?.first_name === 'nobody',
  }));
}

export function getSettings() {
  return async () => {
    try {
      const response = await tasksAPI.fetchSetting('settings');
      const HKData = response.data?.map((data)=>({
          mandatoryHousekeepingPhotos: data.mandatory_housekeeping_photos,
          midStayHousekeepingPeriod: data.mid_stay_housekeeping_period,
          autoApproval: data.auto_approval,
          brand_id: data.brands[0].id
      }))
      dispatch(slice.actions.setSetting(HKData));
    } catch (error) {}
  };
};

export function changeTaskPriority(taskId: number, newPriority: TaskPriority, prevPriority: TaskPriority) {
  return async () => {
    try {
      dispatch(slice.actions.changeTaskPriority({ id: taskId, priority: newPriority }));
      await tasksAPI.changeTaskPriority(taskId, newPriority);
    } catch (error) {
      dispatch(slice.actions.changeTaskPriority({ id: taskId, priority: prevPriority }));
    }
  };
};

export function updateAutoApprovalAction(brandId: number, autoApproval: boolean, setLoadingState: (state: boolean) => void) {
  setLoadingState(true);
  return async () => {
    try {
      const response = await tasksAPI.updateTaskAutoApproval(brandId, autoApproval);
      if (response) {
        dispatch(slice.actions.setSettingsData({brandId: brandId, data: {autoApproval: autoApproval}}));
      }
    } catch (error) {}
    setLoadingState(false);
  }
}

export function updateMandatoryHousekeepingPhotosAction(brandId: number, mandatoryHousekeepingPhotos: boolean, setLoadingState: (state: boolean) => void) {
  setLoadingState(true);
  return async () => {
    try {
      const response = await tasksAPI.updateTaskMandatoryHousekeepingPhotos(brandId, mandatoryHousekeepingPhotos);
      if (response) {
        dispatch(slice.actions.setSettingsData({
          brandId: brandId,
          data: {mandatoryHousekeepingPhotos: mandatoryHousekeepingPhotos}
        }));
      }
    } catch (error) {}
    setLoadingState(false);
  }
}

export function updateMidStayHousekeepingPeriod(brandId: number, midStayHousekeepingPeriod: number, setLoadingState: (state: boolean) => void) {
  setLoadingState(true);
  return async () => {
    try {
      const response = await tasksAPI.updateTaskMidStayHousekeepingPhotos(brandId, midStayHousekeepingPeriod);
      if (response) {
        dispatch(slice.actions.setSettingsData({
          brandId: brandId,
          data: {midStayHousekeepingPeriod: midStayHousekeepingPeriod}
        }));
      }
    } catch (error) {}
    setLoadingState(false);
  }
}

