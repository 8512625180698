import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, MenuItem, Typography, Stack } from '@mui/material';
// routes
import { PATH_DASHBOARD, PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import useLocales from '../../../hooks/useLocales';
import useSettings from 'src/hooks/useSettings';
import useAcl from 'src/hooks/useAcl';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const { translate } = useLocales('common');
  const { user, logout } = useAuth();
  const { setMode } = useSettings();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const { VIEW } = useAcl('PeymansZeevouCRMBundleZeevouContract');
  const { EXECUTE: staffLeaveAccess } = useAcl('zeevou_staff_leave_access');
  const { EXECUTE: hostLeaveAccess } = useAcl('zeevou_host_leave_access');

  const anchorRef = useRef(null);
  const appearanceAnchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openAppearance, setOpenAppearance] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    setOpenAppearance(false);
  };
  const handleClose = () => {
    // setOpenAppearance(false);
    setOpen(false);
  };

  const handleOpenAppearance = () => {
    setOpenAppearance(true);
  };
  const handleCloseAppearance = () => {
    setOpenAppearance(false);
    setOpen(false);
  };

  const handleThemeChange = (theme) => {
    setMode(theme);
    handleCloseAppearance();
  };

  const handleLogout = async () => {
    try {
      await logout?.();
      navigate(PATH_AUTH.login, { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  return (
    <>
      <IconButtonAnimate
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user?.firstName} {user?.lastName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider />
        <Stack spacing={0.5} sx={{ py: 1 }}>
          {/* {MENU_OPTIONS.map((option) => (
            
          ))} */}

          <MenuItem
            to={PATH_DASHBOARD.profile.root}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2 }}
          >
            {translate('profile')}
          </MenuItem>

          {/* <MenuItem
            ref={appearanceAnchorRef}
            onClick={handleOpenAppearance}
            sx={{
              typography: 'body2',
              py: 1,
              px: 2,
              ...(openAppearance && { bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08) }),
            }}
          >
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              flexGrow={1}
            >
              {translate('appearance')}
              <Iconify icon={'ic:round-chevron-right'} />
            </Stack>
            <Popover
              open={openAppearance}
              onClose={handleCloseAppearance}
              anchorEl={appearanceAnchorRef.current}
              anchorOrigin={{
                vertical: -20,
                horizontal: -14,
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              PaperProps={{
                sx: {
                  mt: 1.5,
                  ml: 0.5,
                  width: 200,
                  borderRadius: 1.5,
                  overflow: 'inherit',
                  boxShadow: (theme) => theme.customShadows.z20,
                  border: (theme) => `solid 1px ${theme.palette.grey[500_12]}`,
                },
              }}
              sx={{ width: 220 }}
            >
              <Stack spacing={0.5} sx={{ py: 1 }}>
                <Box sx={{ my: 1.5, px: 2.5 }}>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                    {translate('theme')}
                  </Typography>
                </Box>

                <Divider />
                <MenuItem
                  onClick={() => handleThemeChange('light')}
                  sx={{ typography: 'body2', py: 1, pr: 2, pl: themeMode === 'light' ? 0 : 3 }}
                >
                  <Stack
                    direction={'row'}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                    flexGrow={1}
                  >
                    {themeMode === 'light' && (
                      <Iconify icon={'mdi:circle'} color={'primary.main'} width={8} height={8} mx={1} />
                    )}
                    {translate('light')}
                  </Stack>
                </MenuItem>
                <MenuItem
                  onClick={() => handleThemeChange('dark')}
                  sx={{ typography: 'body2', py: 1, pr: 2, pl: themeMode === 'dark' ? 0 : 3 }}
                >
                  <Stack
                    direction={'row'}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                    flexGrow={1}
                  >
                    {themeMode === 'dark' && (
                      <Iconify icon={'mdi:circle'} color={'primary.main'} width={8} height={8} mx={1} />
                    )}
                    {translate('dark')}
                  </Stack>
                </MenuItem>
              </Stack>
            </Popover>
          </MenuItem> */}

          {VIEW && (
            <MenuItem
              to={PATH_DASHBOARD.account.root}
              component={RouterLink}
              onClick={handleClose}
              sx={{ typography: 'body2', py: 1, px: 2 }}
            >
              {translate('account')}
            </MenuItem>
          )}

          {staffLeaveAccess && !hostLeaveAccess && (
            <MenuItem
              to={PATH_DASHBOARD.contacts.leave.list}
              component={RouterLink}
              onClick={handleClose}
              sx={{ typography: 'body2', py: 1, px: 2 }}
            >
              {translate('leaves')}
            </MenuItem>
          )}
        </Stack>
        <Divider />

        <MenuItem onClick={handleLogout} sx={{ typography: 'body2', py: 1, px: 2, m: 1 }}>
          {translate('logout')}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
