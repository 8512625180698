import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import useSettings from '../../hooks/useSettings';
// config
import {
  DASHBOARD_NAVBAR_WIDTH,
  DASHBOARD_HEADER_MOBILE,
  DASHBOARD_HEADER_DESKTOP,
  DASHBOARD_NAVBAR_COLLAPSE_WIDTH,
  FULLSCREEN_PATHS,
} from '../../config';
//
import DashboardHeader from './header';
import DashboardNavbar from './navbar';
import { SnackbarKey, useSnackbar } from 'notistack';
import useWebsocket from 'src/hooks/useWebsocket';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    minHeight: '100%',
  },
}));

type MainStyleProps = {
  collapseClick: boolean;
  fullScreen: boolean;
};

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick' && prop !== 'fullScreen',
})<MainStyleProps>(({ collapseClick, fullScreen, theme }) => ({
  flexGrow: 1,
  paddingTop: DASHBOARD_HEADER_MOBILE,
  paddingBottom: DASHBOARD_HEADER_MOBILE - 24,
  [theme.breakpoints.up('lg')]: {
    paddingTop: fullScreen ? 0 : DASHBOARD_HEADER_DESKTOP,
    paddingBottom: DASHBOARD_HEADER_DESKTOP - 32,
    width: `calc(100% - ${DASHBOARD_NAVBAR_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: fullScreen ? 0 : DASHBOARD_NAVBAR_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { collapseClick } = useCollapseDrawer();
  const { fullScreen } = useSettings();
  const { pathname } = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { isReconnecting } = useWebsocket();
  const notistackRef = useRef<any>(null);

  const isFullScreenRoute = FULLSCREEN_PATHS.includes(pathname);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isReconnecting) {
      notistackRef.current = enqueueSnackbar('Trying to connect...', { 
        variant: 'default',
        persist: true,
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
      });
    } else {
      if (notistackRef.current !== null) {
        closeSnackbar(notistackRef.current)
      }
    }

    return () => {
      if (notistackRef.current !== null) {
        closeSnackbar(notistackRef.current)
      }
    };
  }, [isReconnecting, notistackRef])

  return (
    <RootStyle>
      {
        (!fullScreen || !isFullScreenRoute) && (
          <>
            <DashboardHeader onOpenSidebar={() => setOpen(true)} />
          
            <DashboardNavbar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
          </>
        )
      }

      <MainStyle collapseClick={collapseClick} fullScreen={fullScreen && isFullScreenRoute}>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
