import { useTranslation } from 'react-i18next';
// @mui
import { enUS, deDE } from '@mui/material/locale';
// date-fns
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';

// ----------------------------------------------------------------------

const LANGS = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    dateLocale: enLocale,
    icon: 'https://minimal-assets-api.vercel.app/assets/icons/ic_flag_en.svg',
  },
  {
    label: 'German',
    value: 'de',
    systemValue: deDE,
    dateLocale: deLocale,
    icon: 'https://minimal-assets-api.vercel.app/assets/icons/ic_flag_de.svg',
  },
];

const SHORT_DATE_FORMATS = [
  { thisYearFormat: 'dd MMM', format: 'dd MMM yy' },
  { thisYearFormat: 'MMM dd', format: 'MMM dd yy' },
  { thisYearFormat: 'dd/MM', format: 'dd/MM/yy' },
  { thisYearFormat: 'MM/dd', format: 'MM/dd/yy' },
];

const LONG_DATE_FORMATS = [
  { thisYearFormat: 'EEE, dd MMM', format: 'EEE, dd MMM yyyy' },
  { thisYearFormat: 'EEE, MMM dd', format: 'EEE, MMM dd yyyy' },
];

const TIME_FORMATS = [
  'hh:mm aa',
  'HH:mm',
];

export default function useLocales(...namespace) {
  const { i18n, t: translate } = useTranslation([...namespace]);
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[0];
  const timezoneStorage = localStorage.getItem('timezone');
  const shortDateFormatStorage = localStorage.getItem('shortDateFormat') ?? 0;
  const currentShortDateFormat = SHORT_DATE_FORMATS[shortDateFormatStorage];
  const longDateFormatStorage = localStorage.getItem('longDateFormat') ?? 0;
  const currentLongDateFormat = LONG_DATE_FORMATS[longDateFormatStorage];
  const timeFormatStorage = localStorage.getItem('timeFormat') ?? 1;
  const currentTimeFormat = TIME_FORMATS[timeFormatStorage];

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
  };

  const handleChangeTimezone = (newTimezone: string) => {
    localStorage.setItem('timezone', newTimezone);
  };

  const handleChangeShortDateFormat = (newShortDateFormat: string) => {
    localStorage.setItem('shortDateFormat', newShortDateFormat);
  };

  const handleChangeLongDateFormat = (newLongDateFormat: string) => {
    localStorage.setItem('longDateFormat', newLongDateFormat);
  };

  const handleChangeTimeFormat = (newTimeFormat: string) => {
    localStorage.setItem('timeFormat', newTimeFormat);
  };

  return {
    onChangeLang: handleChangeLanguage,
    onChangeTimezone: handleChangeTimezone,
    onChangeShortDateFormat: handleChangeShortDateFormat,
    onChangeLongDateFormat: handleChangeLongDateFormat,
    onChangeTimeFormat: handleChangeTimeFormat,
    translate,
    allLang: LANGS,
    allShortFormats: SHORT_DATE_FORMATS,
    allLongFormats: LONG_DATE_FORMATS,
    allTimeFormats: TIME_FORMATS,
    currentLang,
    timezone: timezoneStorage,
    shortDateFormat: currentShortDateFormat,
    longDateFormat: currentLongDateFormat,
    timeFormat: currentTimeFormat,
  };
}
