import { createSlice } from '@reduxjs/toolkit';
// utils
import { ListingsState } from '../../@types/listings';
//

// ----------------------------------------------------------------------

const initialState: ListingsState = {
  isLoading: false,
  error: null,
  openImport: false
};

const slice = createSlice({
  name: 'listings',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // HAS ERROR
    openImport(state) {
      state.openImport = true;
    },

    // HAS ERROR
    closeImport(state) {
      state.openImport = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  openImport,
  closeImport
} = slice.actions;

// ----------------------------------------------------------------------
