import { forwardRef, ReactNode } from 'react';
// @mui
import { Variant } from '@mui/material/styles/createTypography';
import { Typography, TypographyProps, LinkProps, Link, SxProps, Theme } from '@mui/material';
// utils
import GetFontValue from '../utils/getFontValue';

// ----------------------------------------------------------------------

type IProps = TypographyProps & LinkProps;

export interface TextMaxLineProps extends IProps {
  line?: number;
  asLink?: boolean;
  persistent?: boolean;
  children: ReactNode;
  variant?: Variant;
  disabledLine?: boolean;
}

const TextMaxLine = forwardRef<HTMLAnchorElement, TextMaxLineProps>(
  ({ asLink, variant = 'body1', line = 2, persistent = false, children, sx, disabledLine = false, ...other }, ref) => {
    const { lineHeight } = GetFontValue(variant);

    const style: SxProps<Theme> = {
      ...(!disabledLine && {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: line,
        WebkitBoxOrient: 'vertical',
        ...(persistent && {
          height: lineHeight * line,
        }),
      }),
      overflowWrap: 'anywhere',
      wordBreak: 'break-word',
      ...sx,
    };

    if (asLink) {
      return (
        <Link color="inherit" ref={ref} variant={variant} sx={{ ...style }} {...other}>
          {children}
        </Link>
      );
    }

    return (
      <Typography ref={ref} variant={variant} sx={{ ...style }} {...other}>
        {children}
      </Typography>
    );
  }
);

export default TextMaxLine;
