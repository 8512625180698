import { dispatch } from '../store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// api
import integrationsApi from 'src/api/integrations';
// utils

import { RemoteLockData, RemoteLockPageState } from 'src/@types/integrations';
import { Meta } from 'src/@types/common';

const initialState: RemoteLockPageState = {
  loading: false,
  remoteLockData: null,
  remoteLockDatas: null,
};

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    setKeySetData(state, action: PayloadAction<{ remoteLockData: RemoteLockData | null }>) {
      state.remoteLockData = action.payload.remoteLockData;
    },
    setKeySetDatas(
      state,
      action: PayloadAction<{ remoteLockDatas: { data: RemoteLockData[]; meta: Meta } | null }>
    ) {
      state.remoteLockDatas = action.payload.remoteLockDatas;
      state.loading = false;
    },

    setKeySetName(state, action: PayloadAction<{ newName: string }>) {
      if (state.remoteLockData) state.remoteLockData.zeevou_name = action.payload.newName;
    },
    setKeySetProperty(state, action: PayloadAction<{ newProperty: { id: number; name: string } }>) {
      if (state.remoteLockData) state.remoteLockData.property = action.payload.newProperty;
    },
    setKeySetPropertyUnit(
      state,
      action: PayloadAction<{ newPropertyUnit: { id: number; name: string } }>
    ) {
      if (state.remoteLockData) state.remoteLockData.property_unit = action.payload.newPropertyUnit;
    },
    setRemoteLockValue(state, action: PayloadAction<{ newkeySetData: RemoteLockData }>) {
      if (state.remoteLockDatas && action.payload.newkeySetData.id) {
        let index = state.remoteLockDatas.data.findIndex(function (element) {
          return element.id === action.payload.newkeySetData.id;
        });
        if (index > -1) state.remoteLockDatas.data[index] = action.payload.newkeySetData;
      }
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setLoading,
  setKeySetData,
  setKeySetName,
  setKeySetProperty,
  setKeySetPropertyUnit,
} = slice.actions;

export function getRemoteLockDetailById(id: number) {
  return async () => {
    dispatch(slice.actions.setLoading(true));
    try {
      const response = await integrationsApi.getRemockLockDetailsById(id);
      dispatch(
        slice.actions.setKeySetData({
          remoteLockData: response.data ? response.data : null,
        })
      );
    } catch (error) {}
    dispatch(slice.actions.setLoading(false));
  };
}

export function getRemoteLockDetails(
  page: number,
  limit: number) {
  return async () => {
    dispatch(slice.actions.setLoading(true));
    try {
      const response = await integrationsApi.getRemoteLockDetails(
        page,
        limit,
      );
      dispatch(
        slice.actions.setKeySetDatas({
          remoteLockDatas: response.data ? response.data : null,
        })
      );
    } catch (error) {}
    dispatch(slice.actions.setLoading(false));
  };
}

export function fetchRemoteLockDetailsList(
  page: number,
  limit: number,
  filtersState?: {
    type?: { value: string };
    property?: [
      {
        id: number;
        label: string;
        value: number;
      }
    ];
    unit?: [
      {
        id: number;
        label: string;
        value: number;
      }
    ];
  },
  advanceFilterParams?: { key: string; value: string }
) {
  return async () => {
    try {
      setLoading(true);
      const response = await integrationsApi.fetchRemoteLockDetailsList({
        page: page,
        limit: limit,
        filtersState,
        advanceFilterParams,
      });
      dispatch(
        slice.actions.setKeySetDatas({
          remoteLockDatas: response.data ? response.data : null,
        })
      );
    } catch (error) {}
    dispatch(slice.actions.setLoading(false));
  };
}

export function updateRemoteLockName(
  id: number,
  name: string,
  setLoading: (succeed: boolean) => void,
  callback: (status: boolean, error: string | null) => void
) {
  return async () => {
    setLoading(true);
    try {
      await integrationsApi.renameRemoteLock(id, name).then(() => {
        dispatch(slice.actions.setKeySetName({ newName: name }));

        callback(true, null);
      });
    } catch (error) {
      callback(false, error.data && error.data.message ? error.data.message : null);
    } finally {
      setLoading(false);
    }
  };
}

export function updateKeyProperty(
  id: number,
  property: number,
  setLoading: (succeed: boolean) => void,
  callback: (status: boolean, error: string | null) => void
) {
  return async () => {
    setLoading(true);
    try {
      await integrationsApi.changeKeySetProperty(id, property).then((result) => {
        dispatch(slice.actions.setKeySetProperty({ newProperty: result.data.property }));

        callback(true, null);
      });
    } catch (error) {
      callback(false, error.data && error.data.message ? error.data.message : null);
    } finally {
      setLoading(false);
    }
  };
}

export function updateKeyUnit(
  id: number,
  propertyUnit: number,
  setLoading: (succeed: boolean) => void,
  callback: (status: boolean, error: string | null) => void
) {
  return async () => {
    setLoading(true);
    try {
      await integrationsApi.changeKeySetPropertyUnit(id, propertyUnit).then((result) => {
        dispatch(
          slice.actions.setKeySetPropertyUnit({ newPropertyUnit: result.data.property_unit })
        );

        callback(true, null);
      });
    } catch (error) {
      callback(false, error.data && error.data.message ? error.data.message : null);
    } finally {
      setLoading(false);
    }
  };
}

export function updateRemoteLockAction(remoteLockData: any, onSuccess?: () => void) {
  return async () => {
    try {
      await integrationsApi.updateRemoteLock(remoteLockData).then((response) => {
        dispatch(slice.actions.setRemoteLockValue({ newkeySetData: response.data.smart_lock }));
        if (onSuccess) {
          onSuccess();
        }
      });
    } catch (error) {
    } finally {
    }
  };
}
