import { useState } from 'react';
import { TextField, InputLabel, Typography, Stack, Theme, SxProps } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import useLocales from 'src/hooks/useLocales';

interface Props {
  label: string | JSX.Element;
  defaultValue?: string;
  isOptional?: boolean;
  textFieldProps?: Partial<TextFieldProps>;
  callback?: (value: string) => void;
  sx?: SxProps<Theme>;
}

export default function Input({
  label,
  isOptional,
  textFieldProps,
  defaultValue,
  callback,
  sx,
}: Props) {
  const { translate } = useLocales(['common']);
  const [{ value, error }, setInputStates] = useState<{ value: string; error: boolean }>({
    value: defaultValue || '',
    error: false,
  });
  const inputId = `controlled-textField-${label}`;

  return (
    <Stack sx={sx}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={(theme) => ({
          mb: 0.5,
          mx: 1,
        })}
      >
        <InputLabel
          htmlFor={inputId}
          sx={(theme) => ({
            color: theme.palette.text.primary,
            fontSize: theme.typography.body2,
            fontWeight: 600,
          })}
        >
          {label}
        </InputLabel>
        {isOptional ? (
          <Typography
            variant="caption"
            className="optionalLabel"
            sx={(theme) => ({
              lineHeight: '16px',
              color: theme.palette.text.secondary,
            })}
          >
            {translate('optional')}
          </Typography>
        ) : null}
      </Stack>
      <TextField
        {...textFieldProps}
        error={!!(error || Boolean(textFieldProps?.error))}
        id={inputId}
        aria-describedby={`${inputId}-helperText`}
        value={value}
        size={textFieldProps?.size || 'small'}
        onChange={(e) => {
          if (callback) {
            callback(e.target.value);
          }
          setInputStates({ value: e.target.value, error: !!(!isOptional && !e.target?.value) });
        }}
        onBlur={(e) => {
          setInputStates((prev) => ({ ...prev, error: !!(!isOptional && !e.target?.value) }));
        }}
        FormHelperTextProps={{
          sx: (theme) => ({
            m: '4px 8px 0',
          }),
        }}
        inputProps={{
          sx: (theme: Theme) => ({
            lineHeight: '24px',
            p: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
          }),
        }}
      />
    </Stack>
  );
}
