//Omni Search
import React, { useEffect, useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
  Typography,
  Stack,
  Box,
  DialogTitle,
  DialogContent,
  Divider,
  Dialog,
  CircularProgress,
  Tab,
  Tabs,
  Chip,
} from '@mui/material';
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
import useLocales from '../../../hooks/useLocales';
import QuickSearch from '../../../components/Input/QuickSearch';
import API from '../../../api/api';
import boldPartOfText from '../../../utils/boldPartOfText';
import { SkeletonMenuItem } from '../../../components/skeleton';

export type SearchResultType = {
  dashboard_url: string;
  entity_label: string;
  entity_name: string;
  record_id: string;
  record_string: string;
  record_url: string;
};
export type SearchGroupResultType = {
  count: number;
  class: string;
  icon: string;
  label: string;
};

const StackStyled = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  justifyContent: 'space-between',
  flexDirection: 'row',
  cursor: 'pointer',
  borderRadius: 8,
  '&:hover': {
    backgroundColor: theme.palette.grey[500_8],
  },
}));
const ChipStyle = styled(Chip)(({ theme }) => ({
  textAlign: 'center',
  alignSelf: 'center',
  justifyItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1, 1),
  backgroundColor: theme.palette.grey['0'],
  border: `solid 1px ${theme.palette.grey['500']}`,
  borderRadius: '8px',
  fontSize: 12,
  fontWeight: 700,
  '&:hover': {
    backgroundColor: theme.palette.grey[0],
  },
}));
export default function Searchbar() {
  const [isOpen, setOpen] = useState(false);
  const { translate } = useLocales('common');
  const [skeletonCounter, setSkeletonCounter] = useState<number | undefined>(10);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchHighlight, setSearchHighlight] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingChangeTab, setLoadingChangeTab] = useState<boolean>(true);
  const [searchResults, setSearchResults] = useState<SearchResultType[]>([]);
  const [searchGroupResults, setSearchGroupResults] = useState<SearchGroupResultType[]>([]);
  const [tab, setTab] = useState<string>('all_results');

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };
  useEffect(() => {
    if (isOpen) {
      setSearchQuery('');
      setSearchHighlight('');
      setSearchGroupResults([]);
      setSearchResults([]);
      setLoading(false);
      setLoadingChangeTab(true);
    }
  }, [isOpen]);
  const handleClose = () => {
    setSearchQuery('');
    setSearchHighlight('');
    setSearchGroupResults([]);
    setSearchResults([]);
    setOpen(false);
    setLoading(false);
    setLoadingChangeTab(false);
  };

  let signalController: AbortController | null = new AbortController();

  const searchTEXT = (search: string) => {
    if (signalController) {
      signalController.abort();
      signalController = new AbortController();
    }
    setLoading(true);
    API.getGroupedResults({ search: search, from: tab, signal: signalController?.signal })
      .then((response: any) => {
        if (response.data.groupedResults[0].count > 0) {
          setSearchGroupResults(response.data.groupedResults);
          searchGroupByTab(search, 'all_results');
        } else {
          setSearchGroupResults([]);
          setSearchHighlight('not_found');
          setLoading(false);
        }
      })
      .catch((error: any) => {
        console.error(error);
        setSearchGroupResults([]);
        setSearchHighlight('not_found');
        setLoading(false);
      });
  };
  const searchGroupByTab = (search: string, newTab: string) => {
    if (signalController) {
      signalController.abort();
      signalController = new AbortController();
    }
    API.getSearchResults({ search: search, from: newTab, signal: signalController?.signal })
      .then((response: any) => {
        if (response.data.records_count > 0) {
          setSearchResults(response.data.data);
          setTab(newTab);
          setSearchHighlight(search);
        } else {
          setSearchResults([]);
          setSearchHighlight('not_found');
        }
        setLoadingChangeTab(false);
        setLoading(false);
      })
      .catch((error: any) => {
        console.error(error);
        setSearchResults([]);
        setSearchHighlight('not_found');
        setLoadingChangeTab(false);
        setLoading(false);
      });
  };

  return (
    <>
      <IconButtonAnimate onClick={handleOpen}>
        <Iconify icon={'eva:search-fill'} width={20} height={20} />
      </IconButtonAnimate>

      <Dialog open={isOpen} fullWidth={true} maxWidth={'md'} onClose={handleClose}>
        <DialogTitle sx={{ alignItems: 'center' }}>
          <Stack direction={'row'} alignItems={'flex-start'} flexGrow={1}>
            <QuickSearch
              placeholderLabel={translate('omni_search')}
              categories={[]}
              filterValue={''}
              searchValue={searchQuery}
              onChange={(_e, newSearchQuery) => {
                setSearchQuery(newSearchQuery);
                setTimeout(() => {
                  if (newSearchQuery.length > 3) {
                    searchTEXT(newSearchQuery);
                    setLoadingChangeTab(true);
                  } else {
                    setSearchGroupResults([]);
                    setSearchResults([]);
                  }
                }, 900);
              }}
              TextFieldProps={{
                variant: 'outlined',
                size: 'medium',
              }}
              isDebounced
              onBlurSearch={false}
            />
            {loading && (
              <CircularProgress
                color="primary"
                size={20}
                sx={{ position: 'absolute', right: 70, top: 42 }}
              />
            )}
          </Stack>
          {searchGroupResults.length > 0 && (
            <Stack mt={1}>
              <Tabs
                allowScrollButtonsMobile
                onChange={(_e, value) => {
                  setSkeletonCounter(
                    searchGroupResults.find((search) => search.label === value)?.count
                  );
                  setTab(value);
                  setLoadingChangeTab(true);
                  searchGroupByTab(searchQuery, value);
                }}
                variant="scrollable"
                scrollButtons="auto"
                value={tab}
              >
                {searchGroupResults.map((tab) => (
                  <Tab
                    disableRipple
                    key={tab.label}
                    label={`${translate(tab.label)} (${tab.count})`}
                    value={tab.label}
                  />
                ))}
              </Tabs>
              <Divider />
            </Stack>
          )}
        </DialogTitle>
        <DialogContent>
          {searchGroupResults.length > 0 ? (
            loadingChangeTab ? (
              <SkeletonMenuItem
                items={skeletonCounter ? (skeletonCounter > 10 ? 10 : skeletonCounter) : 10}
              />
            ) : (
              searchResults &&
              searchResults.length > 0 && (
                <Stack mt={1}>
                  {searchResults.map((results: SearchResultType, index: number) => (
                    <Stack
                      key={index}
                      onClick={() => {
                        window.open(results.record_url);
                      }}
                    >
                      <StackStyled>
                        <Stack>
                          <Box
                            component={Typography}
                            variant="subtitle2"
                            dangerouslySetInnerHTML={{
                              __html: boldPartOfText(results.record_string, searchHighlight),
                            }}
                          />
                          <Typography sx={{ mt: 1 }} variant={'caption'}>
                            {results.dashboard_url}
                          </Typography>
                        </Stack>
                        {tab === 'all_results' && (
                          <ChipStyle
                            size="small"
                            variant={'filled'}
                            clickable
                            label={results.entity_label}
                            onClick={() => {
                              window.open(results.record_url);
                            }}
                          />
                        )}
                      </StackStyled>
                      {searchResults.length - 1 !== index && <Divider />}
                    </Stack>
                  ))}
                </Stack>
              )
            )
          ) : (
            searchHighlight === 'not_found' && (
              <Stack mt={3} gap={1} alignItems={'center'} justifyContent={'center'}>
                <Typography variant={'inherit'} color={'text.disabled'}>
                  {translate('not_found')}
                </Typography>
              </Stack>
            )
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
