import { Stack, Typography, Button, Tooltip, TextField, InputProps } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { alpha, styled, useTheme } from '@mui/material/styles';
import Iconify, { IconifyProps } from './Iconify';

const PlusButtonStyle = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'buttonSize' && prop !== 'color',
})<{ buttonSize?: string; color: string }>(({ theme, buttonSize, color }) => ({
  background: theme.palette.background.paper,
  width: buttonSize === 'small' ? 24 : 32,
  height: buttonSize === 'small' ? 24 : 32,
  minWidth: 0,
  alignItems: 'center',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  border: '1px solid #637381',
  borderRadius: '50%',
  flexGrow: 0,
  margin: '0px 4px',
  outline: 'none',
  '*': {
    color: '#212B36',
  },
  '&:hover': {
    border: `1px solid ${color}`,
    background: `${alpha(color, 0.08)}`,
    cursor: 'pointer',
    color: color,
    '*': {
      color: color,
    },
  },
  '&:disabled': {
    border: '1px solid #c4cdd5',
    background: 'transparent',
    cursor: 'default',
    color: '#c4cdd5',
    '*': {
      color: '#c4cdd5',
    },
  },
}));

const MinusButtonStyle = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'buttonSize' && prop !== 'color',
})<{ buttonSize?: string; color: string }>(({ theme, buttonSize, color }) => ({
  background: theme.palette.background.paper,
  width: buttonSize === 'small' ? 24 : 32,
  height: buttonSize === 'small' ? 24 : 32,
  minWidth: 0,
  alignItems: 'center',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  border: '1px solid #637381',
  borderRadius: '50%',
  flexGrow: 0,
  margin: '0px 4px',
  outline: 'none',
  '*': {
    color: '#212B36',
  },
  '&:hover': {
    border: `1px solid ${color}`,
    background: `${alpha(color, 0.08)}`,
    cursor: 'pointer',
    color: color,
    '*': {
      color: color,
    },
  },
  '&:disabled': {
    border: '1px solid #c4cdd5',
    background: 'transparent',
    cursor: 'default',
    color: '#c4cdd5',
    '*': {
      color: '#c4cdd5',
    },
  },
}));

const TypographyStyle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'buttonSize' && prop !== 'isDisabled',
})<{ buttonSize?: string; isDisabled: boolean }>(({ theme, buttonSize, isDisabled }) => ({
  width: 32,
  height: buttonSize === 'small' ? 24 : 32,
  alignItems: 'center',
  textAlign: 'center',
  display: 'inline-grid',
  borderRadius: '4px',
  ...(!isDisabled && {
    ':hover': {
      cursor: 'pointer',
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
    },
  }),
}));

const TextFieldStyle = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'buttonSize',
})<{ buttonSize?: string }>(({ theme, buttonSize }) => ({
  width: 32,
  height: buttonSize === 'small' ? 24 : 32,
  fontSize: buttonSize === 'small' ? 15 : 17,
  color: '#000000',
  ':hover': {
    cursor: 'pointer',
    backgroundColor: '#ebf8f1',
  },
}));

export interface InputStepperProps {
  initVal?: number;
  minValue?: number;
  maxValue?: number;
  onChange: Function;
  onIncrement?: Function;
  onDecrement?: Function;
  onBlur?: Function;
  buttonSize?: 'small' | 'medium';
  isDisabled?: boolean;
  disableHold?: boolean;
  maxBoundary?: null | number;
  minBoundary?: null | number;
  minusBtnDisabled?: boolean;
  plusBtnDisabled?: boolean;
  minusTooltipMessage?: string;
  plusTooltipMessage?: string;
  plusBtnColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  minusBtnColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  InputProps?: InputProps;
  plusIconProps?: IconifyProps;
  minusIconProps?: IconifyProps;
}

export default function InputStepper({
  initVal = 0,
  minValue,
  maxValue,
  onChange,
  onIncrement = () => {},
  onDecrement = () => {},
  onBlur,
  buttonSize = 'medium',
  isDisabled = false,
  disableHold = false,
  maxBoundary = null,
  minBoundary = null,
  minusBtnDisabled = false,
  plusBtnDisabled = false,
  minusTooltipMessage = '',
  plusTooltipMessage = '',
  plusBtnColor = 'primary',
  minusBtnColor = 'primary',
  InputProps,
  plusIconProps,
  minusIconProps,
}: InputStepperProps) {
  const theme = useTheme();
  const [inputValue, setInputValueHandler] = useState(initVal);
  const [disablePlusBtn, setDisablePlusBtn] = useState(false);
  const [disableMinusBtn, setDisableMinusBtn] = useState(false);
  const [inputComponent, setInputComponent] = useState('typography');
  const intervalRef = React.useRef(0);
  const colors = {
    primary: theme.palette.primary.main,
    warning: theme.palette.warning.dark,
  };

  useEffect(() => {
    disableBtnHandler(initVal);
  }, [maxValue, minValue]);

  useEffect(() => {
    disableBtnHandler(initVal);
  }, []);

  useEffect(() => {
    setInputValueHandler(initVal);
    disableBtnHandler(initVal);
  }, [initVal]);

  const startPlusCounter = () => {
    if (intervalRef.current) return;
    intervalRef.current = window.setInterval(() => {
      initVal++;
      onPlusClickHandler();
    }, 300);
  };

  const stopPlusCounter = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = 0;
    }
  };

  const startMinusCounter = () => {
    if (intervalRef.current) return;
    intervalRef.current = window.setInterval(() => {
      initVal--;
      onMinusClickHandler();
    }, 300);
  };

  const stopMinusCounter = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = 0;
    }
  };
  const onMinusClickHandler = () => {
    setInputValueHandler((currentValue) => {
      currentValue = initVal;
      if (minValue && currentValue <= minValue) {
        return minValue;
      }
      if (minBoundary && currentValue <= minBoundary) {
        return minBoundary;
      }
      if (maxBoundary && currentValue >= maxBoundary) {
        currentValue = maxBoundary;
      }
      const updatedValue = currentValue - 1;
      disableBtnHandler(updatedValue);
      onChange(updatedValue);
      onDecrement(updatedValue);
      return updatedValue;
    });
  };

  const onPlusClickHandler = () => {
    setInputValueHandler((currentValue) => {
      currentValue = initVal;
      if (maxValue && currentValue >= maxValue) {
        return maxValue;
      }
      if (maxBoundary && currentValue >= maxBoundary) {
        return maxBoundary;
      }
      if (minBoundary && currentValue <= minBoundary) {
        currentValue = minBoundary;
      }
      const updatedValue = currentValue + 1;
      disableBtnHandler(currentValue + 1);
      onChange(updatedValue);
      onIncrement(updatedValue);
      return updatedValue;
    });
  };

  function disableBtnHandler(currentValue) {
    minValue !== undefined && setDisableMinusBtn(currentValue <= minValue);
    maxValue !== undefined && setDisablePlusBtn(currentValue >= maxValue);
  }

  return (
    <Stack direction="row" alignItems="center">
      <Tooltip arrow title={minusTooltipMessage} placement="top">
        <span>
          <MinusButtonStyle
            color={colors[minusBtnColor]}
            buttonSize={buttonSize}
            type="button"
            size={buttonSize}
            disabled={disableMinusBtn || isDisabled || minusBtnDisabled}
            onClick={onMinusClickHandler}
            onMouseDown={!disableHold ? startMinusCounter : undefined}
            onMouseUp={!disableHold ? stopMinusCounter : undefined}
            onMouseLeave={!disableHold ? stopMinusCounter : undefined}
            onTouchStart={!disableHold ? startMinusCounter : undefined}
            onTouchEnd={!disableHold ? stopMinusCounter : undefined}
          >
            <Iconify
              icon="mdi:minus"
              width={buttonSize === 'small' ? 18 : 20}
              height={buttonSize === 'small' ? 18 : 20}
              {...minusIconProps}
            />
          </MinusButtonStyle>
        </span>
      </Tooltip>

      {inputComponent === 'typography' ? (
        <TypographyStyle
          color={isDisabled ? 'text.disabled' : undefined}
          buttonSize={buttonSize}
          isDisabled={isDisabled}
          onClick={() => {
            !isDisabled && setInputComponent('textfield');
          }}
        >
          {initVal}
        </TypographyStyle>
      ) : (
        <TextField
          autoFocus
          value={initVal}
          onBlur={() => {
            if (onBlur) {
              onBlur();
            }
            setInputComponent('typography');
          }}
          onChange={(e) => {
            let value = parseInt(e.target.value, 10);
            if (e.target.value.length > 3 || e.target.value.length === 0) return;
            if (minValue !== undefined && value < minValue) value = minValue;
            if (maxValue !== undefined && value > maxValue) value = maxValue;
            onChange(value);
            disableBtnHandler(value);
          }}
          type="number"
          variant="standard"
          InputProps={{
            disableUnderline: true,
            inputProps: {
              style: {
                textAlign: 'center',
              },
            },
            ...InputProps,
          }}
          sx={{
            width: '32px',
            maxWidth: '32px',
            backgroundColor: alpha(theme.palette.primary.main, 0.16),
            caretColor: theme.palette.primary.main,
            borderRadius: '4px',
          }}
        />
      )}

      <Tooltip arrow title={plusTooltipMessage} placement="top">
        <span>
          <PlusButtonStyle
            color={colors[plusBtnColor]}
            buttonSize={buttonSize}
            type="button"
            size={buttonSize}
            disabled={disablePlusBtn || isDisabled || plusBtnDisabled}
            onClick={onPlusClickHandler}
            onMouseDown={!disableHold ? startPlusCounter : undefined}
            onMouseUp={!disableHold ? stopPlusCounter : undefined}
            onMouseLeave={!disableHold ? stopPlusCounter : undefined}
            onTouchStart={!disableHold ? startPlusCounter : undefined}
            onTouchEnd={!disableHold ? stopPlusCounter : undefined}
          >
            <Iconify
              icon="mdi:add"
              width={buttonSize === 'small' ? 18 : 20}
              height={buttonSize === 'small' ? 18 : 20}
              {...plusIconProps}
            />
          </PlusButtonStyle>
        </span>
      </Tooltip>
    </Stack>
  );
}
