import { Stack, Typography, Theme, SxProps } from '@mui/material';
import useLocales from 'src/hooks/useLocales';
import Iconify from '../Iconify';
import Label, { LabelColor } from '../Label';
import ResponsiveTooltip from '../responsiveTooltip';

export const getColor = (status: 'dismissed' | 'published' | 'pending'): LabelColor => {
  switch (status) {
    case 'published':
      return 'success';
    case 'dismissed':
      return 'warning';
    default:
      return 'info';
  }
};

export const getStatus = (isApproved: boolean | null) => {
  switch (isApproved) {
    case true:
      return 'published';
    case false:
      return 'dismissed';
    default:
      return 'pending';
  }
};

export default function ReviewStatus({
  status,
  isFeaturedOnHomepage,
  sx = {},
}: {
  status: 'published' | 'dismissed' | 'pending';
  isFeaturedOnHomepage: boolean;
  sx?: SxProps<Theme>;
}) {
  const { translate } = useLocales(['common']);

  return (
    <Label color={getColor(status)}>
      {translate(status)}
      {status === 'published' && isFeaturedOnHomepage ? (
        <ResponsiveTooltip title={translate('featured')}>
          <Iconify
            icon="icon-park-solid:check-one"
            sx={(theme) => ({ ml: 1, color: theme.palette.success.main })}
          />
        </ResponsiveTooltip>
      ) : null}
    </Label>
  );
}
