import { Condition, DateConditionOperator, NumberConditionOperator } from 'src/@types/common';
import {
  PromotionApplication, AccommodationCondition,
  ApplyType,
  ArrivalDateCondition,
  BookingDateRangeCondition,
  Discount,
  DisplayOnWebsiteSetting,
  LimitBookingSetting,
  NumberOfGuestCondition,
  NumberOfNightsCondition,
  PromotionApplicationBody,
  PromotionState,
  StackingSetting,
  VoucherSetting,
  PromotionMarketingInfo,
} from 'src/@types/promotion';
import { fDateToUTC } from './formatTime';
import set from "date-fns/set";
import { getCondition } from "./conditions";
import { utcToZonedTime } from 'date-fns-tz';
import { initialAccommodation, initialArrivalDate, initialBookingDateRange, initialNumberOfGuest, initialNumberOfNights } from 'src/redux/slices/promotion';

export const getDateTime = (date: Date | null, time: Date | null) => {
  if (!!date) {
    if (!!time) {
      return fDateToUTC(set(date, { hours: time.getHours(), minutes: time.getMinutes(), seconds: 0 }));
    }
    return fDateToUTC(set(date, { hours: 0, minutes: 0, seconds: 0 }));
  }
  return null;
};

export const getBookingDateFromTo = ({
  check,
  operator,
  minValue,
  maxValue,
}: BookingDateRangeCondition) => ({
  bookingDateFrom:
    check && operator !== Condition.BEFORE ? getDateTime(minValue.date, minValue.time) : null,
  bookingDateTo:
    check && operator !== Condition.AFTER && operator !== Condition.ON
      ? getDateTime(maxValue.date, maxValue.time)
      : operator === Condition.ON
      ? getDateTime(minValue.date, minValue.time)
      : null,
});

export const getArrivalDateFromTo = ({
  check,
  operator,
  minValue,
  maxValue,
}: ArrivalDateCondition) => ({
  arrivalDateFrom: check && operator !== Condition.BEFORE ? minValue && fDateToUTC(minValue) : null,
  arrivalDateTo:
    check && operator !== Condition.AFTER && operator !== Condition.ON
      ? maxValue && fDateToUTC(maxValue)
      : operator === Condition.ON
      ? minValue && fDateToUTC(minValue)
      : null,
});

export const getBookingLimit = ({ check, limit }: LimitBookingSetting) => ({
  limit: check ? limit : null,
});

export const getPromotionName = (internalName: string) => ({
  name: internalName,
});
export const getPromotionDescription = (description: string) => ({
  description,
});
export const getPromotionArchived = (archived: boolean) => ({
  archived,
});

export const getPromotionMarketingInfo = (
  marketingName: string | null,
  marketingDescription: string | null,
  displayingMarketingInfo: boolean,
): PromotionMarketingInfo => ({
  marketingName: marketingName || null,
  marketingDescription: marketingDescription || null,
  displayingMarketingInfo: marketingName === null && marketingDescription === null ? false : displayingMarketingInfo,
});

export const getAccommodationPriceBody = ({
  check,
  operator,
  minValue,
  maxValue,
}: AccommodationCondition) => ({
  minAmount: check && operator !== Condition.LESS_THAN_OR_EQUAL ? +minValue : null,
  maxAmount:
    check && operator !== Condition.MORE_THAN_OR_EQUAL && operator !== Condition.EQUAL
      ? +maxValue
      : operator === Condition.EQUAL
      ? +minValue
      : null,
});

export const getNumberOfGuestsBody = ({
  check,
  operator,
  minValue,
  maxValue,
}: NumberOfGuestCondition) => ({
  minSleepers: check && operator !== Condition.LESS_THAN_OR_EQUAL ? minValue : null,
  maxSleepers:
    check && operator !== Condition.MORE_THAN_OR_EQUAL && operator !== Condition.EQUAL
      ? maxValue
      : operator === Condition.EQUAL
      ? minValue
      : null,
});

export const getNumberOfNightsBody = ({
  check,
  operator,
  minValue,
  maxValue,
}: NumberOfNightsCondition) => ({
  minNights: check && operator !== Condition.LESS_THAN_OR_EQUAL ? minValue : null,
  maxNights:
    check && operator !== Condition.MORE_THAN_OR_EQUAL && operator !== Condition.EQUAL
      ? maxValue
      : operator === Condition.EQUAL
      ? minValue
      : null,
});

export const getDiscountTypeAmount = ({ type, amount }: Discount) => ({
  percentItem: type === 'fixed' ? null : +amount,
  fixRateItem: type === 'fixed' ? +amount : null,
});

export const getVouchers = ({ check, vouchers }: VoucherSetting) => ({
  vouchers: check ? vouchers : [],
});

export const getRatePlans = (applyType: ApplyType, selectedRateIds: number[], isActive: boolean) => ({
  unitTypeLimitApplication: !isActive || applyType === 'partial',
  ratePlans: isActive && applyType === 'partial' ? selectedRateIds : [],
});

export const getExplicit = ({ check }: DisplayOnWebsiteSetting) => ({
  explicit: check,
});

export const getMultipleApplicable = ({ check }: StackingSetting) => ({
  multipleApplicable: check,
});

export const getApplyOtherDiscounts = ({ check }: StackingSetting) => ({
  applyOtherDiscounts: check,
});

export const getRequestBodyFromPromotionForm = (
  values: PromotionState,
  isActive: boolean,
): PromotionApplicationBody => ({
  ...getBookingDateFromTo(values.conditions.bookingDateRange),
  ...getArrivalDateFromTo(values.conditions.arrivalDate),
  ...getBookingLimit(values.settings.limitBooking),
  ...getExplicit(values.settings.displayOnWebsite),
  promotion: {
    ...getPromotionArchived(values.archived),
    ...getPromotionName(values.internalName),
    ...getPromotionDescription(values.description),
    ...getDiscountTypeAmount(values.discount),
    ...getAccommodationPriceBody(values.conditions.accommodation),
    ...getNumberOfGuestsBody(values.conditions.numberOfGuest),
    ...getNumberOfNightsBody(values.conditions.numberOfNights),
    ...getApplyOtherDiscounts(values.settings.stacking),
    ...getPromotionMarketingInfo(
      values.marketingName,
      values.marketingDescription,
      values.displayingMarketingInfo,
    ),
  },
  ...getMultipleApplicable(values.settings.stacking),
  ...getVouchers(values.settings.applyVoucher),
  ...getRatePlans(values.applyType, values.selectedRateIds, isActive),
});

const convertDateToUtc = (date: Date | string | null) => date ? utcToZonedTime(date, 'UTC') : null;
const checkIfTimeExists = (date: Date | null) => date && (date.getMinutes() > 0 || date.getHours() > 0) ? date : null;

export const convertPromotionBodyToForm = ({
  id,
  promotion,
  rate_plans,
  vouchers,
  unit_type_limit_application,
  created_at,
  updated_at,
  arrival_date_from,
  arrival_date_to,
  booking_date_from,
  booking_date_to,
  limit,
  current,
  explicit
}: PromotionApplication): Partial<PromotionState> => ({
    id,
    isActive: true,
    archived: promotion.archived,
    promotionId: promotion.id,
    internalName: promotion.name,
    description: promotion.description,
    marketingName: promotion.marketing_name,
    marketingDescription: promotion.marketing_description,
    displayingMarketingInfo: promotion.displaying_marketing_info,
    discount: {
      type: promotion.percent_item === null ? 'fixed' : 'percentage',
      amount: `${promotion.percent_item ?? promotion.fix_rate_item}`,
    },
    createdAt: created_at,
    updatedAt: updated_at,
    applyType: unit_type_limit_application ? 'partial' : 'all',
    selectedRateIds: rate_plans?.map(({ id }) => id) ?? [],
    conditions: {
      accommodation: {
        check: Boolean(promotion.min_amount || promotion.max_amount),
        operator: getCondition(
          promotion.min_amount ? +promotion.min_amount : null,
          promotion.max_amount ? +promotion.max_amount : null,
          true,
          initialAccommodation.operator,
        ) as NumberConditionOperator,
        ...(Boolean(promotion.min_amount || promotion.max_amount) ? {
          minValue: promotion.min_amount?.toString() ?? '',
          maxValue: promotion.max_amount?.toString() ?? '',
        } : {
          minValue: initialAccommodation.minValue,
          maxValue: initialAccommodation.maxValue,
        })
      },
      numberOfGuest: {
        check: Boolean(promotion.min_sleepers || promotion.max_sleepers),
        operator: getCondition(
          promotion.min_sleepers ? +promotion.min_sleepers : null,
          promotion.max_sleepers ? +promotion.max_sleepers : null,
          true,
          initialNumberOfGuest.operator,
        ) as NumberConditionOperator,
        ...(Boolean(promotion.min_sleepers || promotion.max_sleepers) ? {
          minValue: promotion.min_sleepers ?? null,
          maxValue: promotion.max_sleepers ?? null,
        } : {
          minValue: initialNumberOfGuest.minValue,
          maxValue: initialNumberOfGuest.maxValue,
        })
      },
      numberOfNights: {
        check: Boolean(promotion.min_nights || promotion.max_nights),
        operator: getCondition(
          promotion.min_nights ? +promotion.min_nights : null,
          promotion.max_nights ? +promotion.max_nights : null,
          true,
          initialNumberOfNights.operator,
        ) as NumberConditionOperator,
        ...(Boolean(promotion.min_nights || promotion.max_nights) ? {
          minValue: promotion.min_nights ?? null,
          maxValue: promotion.max_nights ?? null,
        } : {
          minValue: initialNumberOfNights.minValue,
          maxValue: initialNumberOfNights.maxValue,
        })
      },
      bookingDateRange: {
        check: Boolean(booking_date_from || booking_date_to),
        operator: getCondition(
          booking_date_from,
          booking_date_to,
          true,
          initialBookingDateRange.operator,
        ) as DateConditionOperator,
        ...(Boolean(booking_date_from || booking_date_to) ? {
          minValue: {
            date: convertDateToUtc(booking_date_from) ?? null,
            time: checkIfTimeExists(convertDateToUtc(booking_date_from)) ?? null,
          },
          maxValue: {
            date: convertDateToUtc(booking_date_to) ?? null,
            time: checkIfTimeExists(convertDateToUtc(booking_date_to)) ?? null,
          },
        } : {
          minValue: {
            date: initialBookingDateRange.minValue.date,
            time: initialBookingDateRange.minValue.time,
          },
          maxValue: {
            date: initialBookingDateRange.maxValue.date,
            time: initialBookingDateRange.maxValue.time,
          },
        })
      },
      arrivalDate: {
        check: Boolean(arrival_date_from || arrival_date_to),
        operator: getCondition(
          arrival_date_from,
          arrival_date_to,
          true,
          initialArrivalDate.operator,
        ) as DateConditionOperator,
        ...(Boolean(arrival_date_from || arrival_date_to) ? {
          minValue: convertDateToUtc(arrival_date_from) ?? null,
          maxValue: convertDateToUtc(arrival_date_to) ?? null,
        } : {
          minValue: initialArrivalDate.minValue,
          maxValue: initialArrivalDate.maxValue,
        })
      },
    },
    settings: {
      limitBooking: {
        check: Boolean(limit),
        limit,
        current: current,
      },
      applyVoucher: {
        check: vouchers?.length > 0,
        vouchers,
      },
      displayOnWebsite: {
        check: explicit,
      },
      stacking: {
        check: promotion.apply_other_discounts,
        customizable: false,
        priority: Condition.FIRST,
      },
    },
});