import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Popover(theme: Theme) {
  return {
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: theme.customShadows.z12,
          '& .MuiListItemButton-root': {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            borderRadius: '0.5rem',
          },
          '& .MuiMenuItem-root': {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            borderRadius: '0.5rem',
          },
        }
      }
    }
  };
}
