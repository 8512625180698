// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';
export const ROOTS_HUB = 'https://hub.zeevou.com';
export const ROOTS_ZEEVOU = 'https://zeevou.com';
export const ROOTS_CALENDLY = 'https://calendly.com/zeevou';
export const ROOTS_ZEEVOU_SUPPORT = 'https://support.zeevou.com';
export const ROOTS_HOST = process.env.REACT_APP_HOST_API_KEY;

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  resetPasswordToken: path(ROOTS_AUTH, '/reset-password/:token'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page400: '/400',
  page403: '/403',
  page404: '/404',
  page503: '/503',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: '/',
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    sales: path(ROOTS_DASHBOARD, '/sales'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/bookings'),
    report: path(ROOTS_DASHBOARD, '/performance'),
  },
  booking: {
    booking: path(ROOTS_DASHBOARD, '/booking'),
    bookingLandingPage: path(ROOTS_DASHBOARD, '/bookings'),
    bookingListView: path(ROOTS_DASHBOARD, '/booking/list'),
    confirmationPageSettings: path(ROOTS_DASHBOARD, '/bookings/confirmation-page-settings'),
    guest: path(ROOTS_DASHBOARD, '/booking/guest'),
    corporation: path(ROOTS_DASHBOARD, '/booking/corporation'),
    bookingImport: {
      view: path(ROOTS_DASHBOARD, '/booking_import'),
      list: path(ROOTS_DASHBOARD, '/booking_import'),
    },
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey'),
  },
  listings: {
    root: path(ROOTS_DASHBOARD, '/listings'),
    archive: path(ROOTS_DASHBOARD, '/listings/archive'),
    settings: path(ROOTS_DASHBOARD, '/listing/settings'),
    propertyPage: path(ROOTS_DASHBOARD, '/property'),
    unitTypePage: path(ROOTS_DASHBOARD, '/unit-type'),
    unitPage: path(ROOTS_DASHBOARD, '/unit'),
    property: path(ROOTS_DASHBOARD, '/property/:propertyId/:tab'),
    unitType: path(ROOTS_DASHBOARD, '/unit-type/:unitTypeId/:tab'),
    unit: path(ROOTS_DASHBOARD, '/unit/:unitId/:tab'),
    channelManager: path(ROOTS_DASHBOARD, '/channel-manager'),
  },
  finances: {
    investorDeals: {
      investorDeal: path(ROOTS_DASHBOARD, '/owner/contract'),
      investorDealListView: path(ROOTS_DASHBOARD, '/owner/contracts'),
      investorDealView: path(ROOTS_DASHBOARD, '/owner/contract/:contractId'),
    },
    payments: {
      list: path(ROOTS_DASHBOARD, '/payments'),
      view: path(ROOTS_DASHBOARD, '/payment'),
    },
    refunds: {
      list: path(ROOTS_DASHBOARD, '/refunds'),
      view: path(ROOTS_DASHBOARD, '/refund'),
      refundTypeListView: path(ROOTS_DASHBOARD, '/refund/types'),
    },
    expenses: {
      expense: path(ROOTS_DASHBOARD, '/expense'),
      expenseListView: path(ROOTS_DASHBOARD, '/expenses'),
      expenseTypeListView: path(ROOTS_DASHBOARD, '/expense/types'),
      expenseClone: path(ROOTS_DASHBOARD, '/expense/clone'),
      expenseCreate: path(ROOTS_DASHBOARD, '/expense/create'),
      expenseCreateBatch: path(ROOTS_DASHBOARD, '/expense/create/batch'),
      importExpense: path(ROOTS_DASHBOARD, '/expense_import/:id'),
    },
    invoices: path(ROOTS_DASHBOARD, '/invoices'),
    invoicesView: path(ROOTS_DASHBOARD, '/invoice'),
  },
  rateRule: {
    root: path(ROOTS_DASHBOARD, '/rate-rules'),
    create: path(ROOTS_DASHBOARD, '/rate-rule/create'),
    view: path(ROOTS_DASHBOARD, '/rate-rule/:id'),
  },
  // calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  oc: path(ROOTS_DASHBOARD, '/calendar'),
  blockedDates: path(ROOTS_DASHBOARD, '/blockdates'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    financialHistory: path(ROOTS_DASHBOARD, '/user/financial-history'),
    contract: path(ROOTS_DASHBOARD, '/user/account/contract'),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post'),
  },
  inbox: {
    root: path(ROOTS_DASHBOARD, '/inbox'),
    savedResponses: {
      root: '/inbox/saved-responses',
      create: '/inbox/saved-responses/create',
      edit: '/inbox/saved-responses/edit/:id',
    },
  },
  automatedMessage: {
    root: path(ROOTS_DASHBOARD, '/automated-messages'),
    create: path(ROOTS_DASHBOARD, '/automated-messages/create'),
    edit: path(ROOTS_DASHBOARD, '/automated-messages/edit'),
    message: path(ROOTS_DASHBOARD, '/automated-messages/edit/:automatedMessageId'),
  },
  tasks: {
    root: path(ROOTS_DASHBOARD, '/tasks'),
    view: path(ROOTS_DASHBOARD, '/task'),
    housekeeping: path(ROOTS_DASHBOARD, '/task-list/housekeeping'),
  },
  account: {
    root: path(ROOTS_DASHBOARD, '/account'),
    licenses: path(ROOTS_DASHBOARD, '/account/licenses'),
    invoices: path(ROOTS_DASHBOARD, '/account/invoices'),
    payments: path(ROOTS_DASHBOARD, '/account/payments'),
    upgrade: path(ROOTS_DASHBOARD, '/account/upgrade'),
    buyMoreLicense: path(ROOTS_DASHBOARD, '/account/buy-more-licenses'),
    payment: path(ROOTS_DASHBOARD, '/account/payment'),
    paymentResult: path(ROOTS_DASHBOARD, '/account/payment/result'),
    invoice: path(ROOTS_DASHBOARD, '/account/invoice'),
  },
  profile: {
    root: path(ROOTS_DASHBOARD, '/profile'),
    brand: path(ROOTS_DASHBOARD, '/profile/brand'),
    notifications: path(ROOTS_DASHBOARD, '/profile/notifications'),
    general: path(ROOTS_DASHBOARD, '/profile/general'),
  },
  promotions: {
    root: path(ROOTS_DASHBOARD, '/promotions'),
    archived: path(ROOTS_DASHBOARD, '/promotions/archive'),
  },
  promotion: {
    root: path(ROOTS_DASHBOARD, '/promotion'),
    create: path(ROOTS_DASHBOARD, '/promotion/create'),
    view: path(ROOTS_DASHBOARD, '/promotion/:id'),
  },
  preferences: {
    root: path(ROOTS_DASHBOARD, '/preferences'),
  },
  upsells: {
    root: path(ROOTS_DASHBOARD, '/upsells'),
  },
  wizard: {
    createUnitType: path(ROOTS_DASHBOARD, '/wizard/create-unit-type/:property'),
    multiRate: {
      root: path(ROOTS_DASHBOARD, '/wizard/multi-rate/:rateType'),
      nightly: path(ROOTS_DASHBOARD, '/wizard/multi-rate/nightly'),
      weekly: path(ROOTS_DASHBOARD, '/wizard/multi-rate/weekly'),
      monthly: path(ROOTS_DASHBOARD, '/wizard/multi-rate/monthly'),
    },
  },
  integrations: {
    root: path(ROOTS_DASHBOARD, '/integrations'),
    keynest: path(ROOTS_DASHBOARD, '/integrations/keynest/:keynestId'),
    all: path(ROOTS_DASHBOARD, '/integrations/all'),
    partner: path(ROOTS_DASHBOARD, '/integrations/partner'),
    xero: {
      chartOfAccounts: path(ROOTS_DASHBOARD, '/integration/xero/chart_of_account'),
      accountingSchemas: path(ROOTS_DASHBOARD, '/integration/xero/accounting_schema'),
      taxSchemas: path(ROOTS_DASHBOARD, '/integration/xero/tax_schema'),
      trackingCategory: path(ROOTS_DASHBOARD, '/integration/xero/tracking_category'),
    },
  },
  tax: {
    root: path(ROOTS_DASHBOARD, '/tax'),
    create: path(ROOTS_DASHBOARD, '/tax/create'),
    update: path(ROOTS_DASHBOARD, '/tax/update/'),
  },
  organisation: {
    organisationConfiguration: path(ROOTS_DASHBOARD, '/setting/:brandId/:tab'),
    finance: path(ROOTS_DASHBOARD, '/setting/finance'),
  },
  marketing: {
    root: path(ROOTS_DASHBOARD, '/marketing/:tab'),
    websiteSettings: path(ROOTS_DASHBOARD, '/marketing/websites/:brandId/:tab/:subTab'),
    pageSettings: path(ROOTS_DASHBOARD, '/marketing/websites/:brandId/:tab/:subTab/:pageId'),
  },
  contacts: {
    staff: {
      list: path(ROOTS_DASHBOARD, '/management'),
      view: path(ROOTS_DASHBOARD, '/management/staff'),
    },
    investor: {
      view: path(ROOTS_DASHBOARD, '/owner'),
      list: path(ROOTS_DASHBOARD, '/owners'),
    },
    supplier: {
      view: path(ROOTS_DASHBOARD, '/supplier'),
      list: path(ROOTS_DASHBOARD, '/suppliers'),
    },
    leave: {
      list: path(ROOTS_DASHBOARD, '/leaves'),
    },
  },
  bookingRequest: {
    view: path(ROOTS_DASHBOARD, '/booking-request'),
  },
  performance: {
    monthlyProfitReports: {
      view: path(ROOTS_DASHBOARD, '/mpr'),
      investorView: path(ROOTS_DASHBOARD, '/owner/mpr'),
      list: path(ROOTS_DASHBOARD, '/mprs'),
      investorList: path(ROOTS_DASHBOARD, '/owner/mprs'),
    },
  },
  hands: {
    list: path(ROOTS_DASHBOARD, '/hands'),
    view: path(ROOTS_DASHBOARD, '/hand'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';

export const PATH_HUB = {
  root: ROOTS_HUB,
  inbox: {
    templates: path(ROOTS_HUB, '/email/emailtemplate'),
    triggerRules: path(ROOTS_HUB, '/guest-notification/index'),
  },
  booking: {
    bookingView: path(ROOTS_HUB, '/booking'),
    bookingEdit: path(ROOTS_HUB, '/booking/update'),
    allBookings: path(ROOTS_HUB, '/booking/index'),
    unallocatedBookings: path(ROOTS_HUB, '/booking/unallocated'),
    scheduledForCancellation: path(ROOTS_HUB, '/booking/scheduled-for-cancellation'),
    unmappedBookings: path(ROOTS_HUB, '/booking/unmapped'),
    bookingClone: path(ROOTS_HUB, '/booking/clone'),
    bookingImport: path(ROOTS_HUB, '/app/booking_excel'),
  },
  task: {
    root: path(ROOTS_HUB, '/'),
    myTasks: path(ROOTS_HUB, '/task/index'),
    host: {
      arrivals: path(ROOTS_HUB, '/booking/today-check-ins'),
      departures: path(ROOTS_HUB, '/booking/departures'),
    },
    cleaning: {
      housekeepingList: path(ROOTS_HUB, '/housekeeping/index'),
      upcommingHousekeepingSchedule: path(
        ROOTS_HUB,
        '/staff/report/upcoming_house_keeping_schedule'
      ),
      housekeepingSchedule: path(ROOTS_HUB, '/staff/report/house_keeping_schedule'),
    },
    maintenance: {
      issues: path(ROOTS_HUB, '/maintenance/issue/index'),
      blockedDates: path(ROOTS_HUB, '/property/unit_blocked/index'),
      blockFromIcal: path(ROOTS_HUB, '/property/block_from_ical'),
    },
  },
  pricing: {
    ratesAndAvailability: {
      rates: path(ROOTS_HUB, '/rate/unit_type/index'),
      rateLog: path(ROOTS_HUB, '/rate/rate_log/'),
      availabilityLog: path(ROOTS_HUB, '/availability_log/'),
      promotions: path(ROOTS_HUB, '/promotion/index'),
      rateRules: path(ROOTS_HUB, '/rate/rate_rule_app/index'),
    },
    channelFees: {
      channelDeal: path(ROOTS_HUB, '/property/channel_deal/index'),
      channelDealView: path(ROOTS_HUB, '/property/channel_deal/'),
    },
    addonTypes: {
      addonType: path(ROOTS_HUB, '/product/addon/'),
      upsell: path(ROOTS_HUB, '/product/upsell/'),
    },
  },
  listing: {
    countries: path(ROOTS_HUB, '/common/country/index'),
    cities: {
      root: path(ROOTS_HUB, '/common/city/index'),
      create: path(ROOTS_HUB, '/common/city/create'),
    },
    areas: path(ROOTS_HUB, '/property/area/index'),
    properties: path(ROOTS_HUB, '/property/index'),
    property: path(ROOTS_HUB, '/property/'),
    buildings: path(ROOTS_HUB, '/property/building/index'),
    floors: path(ROOTS_HUB, '/property/floor/index'),
    unitTypes: path(ROOTS_HUB, '/property/type/index'),
    units: path(ROOTS_HUB, '/property/unit/index'),
    unitType: path(ROOTS_HUB, '/property/type/'),
    unit: path(ROOTS_HUB, '/property/unit/'),
  },
  contacts: {
    guests: {
      individuals: path(ROOTS_HUB, '/booking/guest/'),
      corporations: path(ROOTS_HUB, '/booking/corporation/index'),
      corporation: path(ROOTS_HUB, '/booking/corporation'),
      corporationCreate: path(ROOTS_HUB, '/booking/corporation/create'),
    },
    staff: {
      profiles: path(ROOTS_HUB, '/staff/index'),
      view: path(ROOTS_HUB, '/staff/view/'),
      create: path(ROOTS_HUB, '/staff/create'),
      leaves: path(ROOTS_HUB, '/staff/leave/'),
      teams: path(ROOTS_HUB, '/staff/team/'),
      companies: path(ROOTS_HUB, '/staff/company/'),
      update: {
        staff: path(ROOTS_HUB, '/staff/update'),
        team: path(ROOTS_HUB, '/staff/team/update'),
        company: path(ROOTS_HUB, '/staff/company/update'),
      },
    },
    investors: {
      profiles: path(ROOTS_HUB, '/maintenance/landlord/index'),
      dealTemplates: path(ROOTS_HUB, '/finance/deal_template/index'),
      investorDeals: path(ROOTS_HUB, '/finance/investor_deal/index'),
      investorDeal: path(ROOTS_HUB, '/finance/investor_deal'),
    },
  },
  integrations: {
    integrations: path(ROOTS_HUB, '/integration_partner/indexCard'),
    clickSend: path(ROOTS_HUB, '/smspanelconfig/'),
    xeroTracingCategories: path(ROOTS_HUB, '/finance/xero_tracking_category/index'),
    dynamicPricing: path(ROOTS_HUB, '/rate/pricing_config'),
    keynestKeys: path(ROOTS_HUB, '/keynest/keyset/index'),
  },
  finances: {
    guestPayments: {
      paymentReminders: path(ROOTS_HUB, '/booking/overdue_payments'),
      payments: path(ROOTS_HUB, '/booking/payment/index'),
      payment: path(ROOTS_HUB, '/booking/payment'),
    },
    accounting: {
      accountingSchema: path(ROOTS_HUB, '/finance/accounting_schema_default/index'),
      accountingSchemaView: path(ROOTS_HUB, '/finance/accounting_schema_default/view'),
      accountingSchemaEdit: path(ROOTS_HUB, '/finance/accounting_schema_default/update'),
      accountingSchemaCreate: path(ROOTS_HUB, '/finance/accounting_schema_default/create'),
      chartOfAccounts: path(ROOTS_HUB, '/finance/chart_of_account/index'),
      taxSchemas: path(ROOTS_HUB, '/finance/tax_schema/index'),
    },
    refunds: path(ROOTS_HUB, '/finance/customer_refund/refund_report'),
    expenses: path(ROOTS_HUB, '/finance/bill/index'),
    expense: path(ROOTS_HUB, '/finance/bill'),
    invoices: path(ROOTS_HUB, '/finance/customer_invoice/index'),
    paymentTerm: {
      root: path(ROOTS_HUB, '/finance/payment_term'),
      create: path(ROOTS_HUB, '/finance/payment_term/create'),
    },
  },
  performance: {
    sales: path(ROOTS_HUB, '/booking/booking_income_report_app/index'),
    occupancy: path(ROOTS_HUB, '/report/occupancy_percentage'),
    monthlyProfit: path(ROOTS_HUB, '/booking/monthly_profit_report/index'),
    investorProfit: path(ROOTS_HUB, '/booking/investor_profit_report/index'),
    reportBeta: path(ROOTS_HUB, '/finance/dashboard'),
  },
  partners: {
    payment: {
      payments: path(ROOTS_HUB, '/zeevou/payment/index'),
      allocatedReport: path(ROOTS_HUB, '/zeevou/payment/allocated_report/index'),
    },
    hands: path(ROOTS_HUB, '/hand/'),
    customers: path(ROOTS_HUB, '/zeevou/customer/index'),
    influencers: path(ROOTS_HUB, '/zeevou/influencer/'),
    accounts: path(ROOTS_HUB, '/zeevou/account/index'),
    services: path(ROOTS_HUB, '/zeevou/service/index'),
    taxSchemas: path(ROOTS_HUB, '/zeevou/tax_schema/index'),
    plans: path(ROOTS_HUB, '/zeevou/plan/index'),
    contracts: path(ROOTS_HUB, '/zeevou/contract/index'),
    customerDeals: path(ROOTS_HUB, '/zeevou/deal/index'),
    invoices: path(ROOTS_HUB, '/zeevou/invoice/index'),
    evanglistDealTemplate: path(ROOTS_HUB, '/zeevou/evangelist_deal_template/index'),
    evanglistDeals: path(ROOTS_HUB, '/zeevou/evangelist_deal/index'),
    zeevouBills: path(ROOTS_HUB, '/zeevou/bill/index'),
    crmConfigs: path(ROOTS_HUB, '/zeevou/crm_config/index'),
  },
  marketting: {
    pages: path(ROOTS_HUB, '/content/page'),
    reviews: path(ROOTS_HUB, '/booking/review/index'),
    blog: {
      categories: path(ROOTS_HUB, '/tag_and_category/category'),
      posts: path(ROOTS_HUB, '/content/blog'),
      tags: path(ROOTS_HUB, '/tag_and_category/tag'),
    },
    settings: {
      contactDetails: path(ROOTS_HUB, '/contact_config/index'),
      urlRedirects: path(ROOTS_HUB, '/redirect_front/index'),
      websiteMenu: path(ROOTS_HUB, '/menu/frontend_menu/index'),
      designCustomizations: path(
        ROOTS_HUB,
        '/organisation/organisation_configuration/check_navigation_route_style'
      ),
    },
  },
  promotion: {
    view: path(ROOTS_HUB, '/promotion'),
    update: path(ROOTS_HUB, '/promotion/update'),
  },
  settings: {
    notifications: path(ROOTS_HUB, '/config/system#'),
    jobs: path(ROOTS_HUB, '/message-queue/jobs/'),
    menus: path(ROOTS_HUB, '/menu/global/'),
    workflows: path(ROOTS_HUB, '/workflowdefinition'),
    processess: path(ROOTS_HUB, '/processdefinition'),
    systemInformation: path(ROOTS_HUB, '/platform/information'),
    supportConfiguration: path(ROOTS_HUB, '/support/terminal'),
    systemConfiguration: {
      documentTypes: path(ROOTS_HUB, '/document/doc_type/'),
      sequences: path(ROOTS_HUB, '/document/doc_sequence/'),
    },
    userManagement: {
      groups: path(ROOTS_HUB, '/user/group'),
      businessUnits: path(ROOTS_HUB, '/organization/business_unit'),
      organization: path(ROOTS_HUB, '/organization/update_current'),
    },
    finance: {
      expenceType: path(ROOTS_HUB, '/product/expenses/'),
      refundType: path(ROOTS_HUB, '/product/refund/'),
      penaltyType: path(ROOTS_HUB, '/product/penalty/'),
    },
    integrations: {
      embeddedForms: path(ROOTS_HUB, '/embedded-form/'),
    },
    entities: {
      entityManagement: path(ROOTS_HUB, '/entity/config/'),
    },
    configuration: {
      currencies: path(ROOTS_HUB, '/common/currency/index'),
      bedSizes: path(ROOTS_HUB, '/property/bed_size/index'),
      organizationConfiguration: path(
        ROOTS_HUB,
        '/organisation/organisation_configuration/check_navigation_route'
      ),
      parkingTypes: path(ROOTS_HUB, '/property/parkingType/index'),
      propertyCategories: path(ROOTS_HUB, '/property/property_category/index'),
      organisationConfigurations: path(ROOTS_HUB, '/organisation/brand/index'),
    },
  },
  user: {
    userView: path(ROOTS_HUB, '/user/view/'),
  },
};

export const PATH_CALENDLY = {
  sparkOnBoarding: path(ROOTS_CALENDLY, '/spark-onboarding'),
};

export const PATH_ZEEVOU = {
  root: ROOTS_ZEEVOU,
  contactUs: path(ROOTS_ZEEVOU, '/contact-us'),
  freeDemo: path(ROOTS_ZEEVOU, '/free-demo'),
  signup: path(ROOTS_ZEEVOU, '/sign-up'),
};

export const PATH_ZEEVOU_SUPPORT = {
  root: ROOTS_ZEEVOU_SUPPORT,
  usingZeevou: {
    root: path(ROOTS_ZEEVOU_SUPPORT, '/using-zeevou'),
    howToCreatePromotion: path(
      ROOTS_ZEEVOU_SUPPORT,
      '/using-zeevou/how-to-create-a-promotion-a-voucher-code'
    ),
    theCrossListing: path(
      ROOTS_ZEEVOU_SUPPORT,
      '/using-zeevou/the-cross-listings'
    ),
  },
  integrationApps: {
    root: path(ROOTS_ZEEVOU_SUPPORT, '/integration-apps'),
    pricelabs: path(ROOTS_ZEEVOU_SUPPORT, '/integration-apps/link-to-pricelabs'),
    beyondPricing: path(ROOTS_ZEEVOU_SUPPORT, '/integration-apps/link-to-beyond-pricing'),
    rategenie: path(ROOTS_ZEEVOU_SUPPORT, '/integration-apps/link-to-rategenie'),
  },
};
