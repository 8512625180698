import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import settingReducer from './slices/setting';
import automatedMessageReducer from './slices/automated-message';
import unifiedInboxReducer from './slices/unified-inbox';
import confirmationPageSettingsReducer from './slices/confirmation-page-settings';
import ocReducer from './slices/oc';
import listingsReducer from './slices/listings';
import listingReducer from './slices/listing';
import rateRuleReducer from './slices/rate-rule';
import tasksReducer from './slices/tasks';
import notificationReducer from './slices/notificationsSlice';
import preferenceReducer from './slices/preference';
import bookingReducer from './slices/booking/booking';
import blockedDatesReducer from './slices/blocked-dates';
import createBookingReducer from './slices/booking/createBookingDialog';
import ticketReducer from './slices/ticket';
import integrations from './slices/integrations';
import guestReducer from './slices/guest';
import corporationReducer from './slices/corporation';
import orgConfigReducer from './slices/orgConfig';
import marketingReducer from './slices/marketing';
import bookingRequestReducer from './slices/booking-request';
import hostMonthlyProfitReportReducer from './slices/monthly-profit-report-host';
import investorMonthlyProfitReportReducer from './slices/monthly-profit-report-investor';
import invoiceViewReducer from './slices/invoice';
import dealViewReducer from './slices/deal';
import accountReducer from './slices/account';
import promotionReducer from './slices/promotion';
import issuesReducer from './slices/issues';
import suppliersReducer from './slices/suppliers';
import refundTypesReducer from './slices/refundTypes';
import expenseTypesReducer from './slices/expenseTypes';
import upsellsReducer from './slices/upsells';
import keySetReducer from './slices/keySet';
import remoteLockReducer from './slices/remoteLock';
import leavesReducer from './slices/leaves';
import archivedListings from './slices/archived-listings';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  setting: settingReducer,
  automatedMessage: automatedMessageReducer,
  unifiedInbox: unifiedInboxReducer,
  confirmationPageSettings: confirmationPageSettingsReducer,
  oc: ocReducer,
  listings: listingsReducer,
  listing: listingReducer,
  rateRule: rateRuleReducer,
  tasks: tasksReducer,
  notification: notificationReducer,
  booking: bookingReducer,
  blockedDates: blockedDatesReducer,
  preference: preferenceReducer,
  createBooking: createBookingReducer,
  ticket: ticketReducer,
  integration: integrations,
  guest: guestReducer,
  corporation: corporationReducer,
  orgConfig: orgConfigReducer,
  marketing: marketingReducer,
  bookingRequest: bookingRequestReducer,
  hostMonthlyProfitReport: hostMonthlyProfitReportReducer,
  investorMonthlyProfitReport: investorMonthlyProfitReportReducer,
  invoiceView: invoiceViewReducer,
  dealView: dealViewReducer,
  account: accountReducer,
  promotion: promotionReducer,
  issues: issuesReducer,
  suppliers: suppliersReducer,
  refundTypes: refundTypesReducer,
  expenseTypes: expenseTypesReducer,
  upsells: upsellsReducer,
  keySets: keySetReducer,
  remoteLocks:remoteLockReducer,
  leaves: leavesReducer,
  archivedListings: archivedListings,
});

export { rootPersistConfig, rootReducer };
