import { createContext, Dispatch, ReactNode, SetStateAction, useState } from "react";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
export type RowConfigType<T extends string = string> = {
  [key in T]: {
    show: boolean;
    forceEditing: boolean;
  };
};

type EditableContextType = {
  editMode: boolean,
  isHovered: boolean,
  setEditMode: Dispatch<SetStateAction<boolean>>,
  setIsHovered: Dispatch<SetStateAction<boolean>>,
  rowConfig: RowConfigType,
  setRowConfig: Dispatch<SetStateAction<RowConfigType>>,
};

const initialState: EditableContextType = {
  editMode: false,
  isHovered: false,
  rowConfig: { '': { show: false, forceEditing: false } },
  setEditMode: () => {},
  setIsHovered: () => {},
  setRowConfig: () => {},
};

const EditableContext = createContext(initialState);

function EditableProvider({ children, initialRowConfig = { '': { show: false, forceEditing: false } } }: { children: ReactNode, initialRowConfig?: RowConfigType }) {
  const [isHovered, setIsHovered] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [rowConfig, setRowConfig] = useState<RowConfigType>(initialRowConfig);

  return (
    <EditableContext.Provider
      value={{
        editMode,
        isHovered,
        rowConfig,
        setEditMode,
        setIsHovered,
        setRowConfig,
      }}
    >
      {children}
    </EditableContext.Provider>
  );
}

export { EditableProvider, EditableContext };
