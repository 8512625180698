import validator from 'validator';
import * as Yup from 'yup';

export const isEmptyString = (str: string): boolean => {
  if (str === null || /^ *$/.exec(str) !== null) return true;
  return false;
};

export const convertStringToUrlAlias = (string: string) => string.trim().toLowerCase().replace(/\s/g, '-');

export const convertUrlAliasToString = (string: string) => string.trim().toLowerCase().replace(/-/g, ' ');

export const titleCase = (string: string): string => {
  let splitStr = string.toLowerCase().split(' ');
  splitStr.map((str, index) => {
    splitStr[index] = str.charAt(0).toUpperCase() + str.substring(1);
  });
  return splitStr.join(' ');
}

export const replaceNewLineInStringWithBrTag = (string: string) => string.replace(/(?:\r\n|\n)/g, '<br>');

export const isValidUrl = (url: string, options?: { [x: string]: any }) =>
  validator.isURL(url, { disallow_auth: true, ...options });

export const isValidEmail = (email: string) => Yup.string().email().isValidSync(email);

export const isAlphanumericCharactersAndHyphensString = (string: string) => new RegExp(/^[a-zA-Z0-9-_]+$/).test(string);

export const convertTextToHtmlEntities = (text: string) =>
  text.replace(/[\u00A0-\u9999<>\&]/g, (i) => '&#' + i.charCodeAt(0) + ';');

export const getName = (object: { first_name: string | null; middle_name: string | null; last_name: string | null }) => {
  const firstName = object.first_name?.trim() || '';
  const middleName = object.middle_name?.trim() ? ` ${object.middle_name.trim()}` : '';
  const lastName = object.last_name?.trim() || '';
  const fullName = `${firstName}${middleName} ${lastName}`.trim();

  return { firstName, middleName, lastName, fullName }
}
