import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { Faq, UpdateFaqs } from 'src/@types/listings';
import {
  MarketingState,
  MarketingSubTab,
  MarketingTabName,
  UpdateFrontendStyleBody,
  BrandFrontendStyle,
  UpdateConfigBody,
  BrandConfig,
  FrontendMenu,
  UpdateFrontendMenuBody,
  AllBrandsApiResType,
  Brand,
  ContentPage,
  UpdateContentPage,
  AllPages,
  UpdateDestinationConfig,
  UpdateContactConfig,
  TagAndCategory,
  UpdateTagAndCategory,
  CreateTagAndCategory,
  UpdateCity,
  UpdateCountry,
  UpdateRouteConfig,
  RouteConfig,
  CreateContentPage,
  UpdateProperty,
  Property,
} from 'src/@types/marketing';
import marketingAPI from 'src/api/marketing';
import {
  findLastBrandMenusWeight,
  hasHomepagePrimaryMenuItem,
  hasMoreThanPrimaryMenuItemsLimit,
  sortBrandMenus,
} from 'src/utils/marketing';
import { dispatch } from '../store';

const initialState: MarketingState = {
  currentTab: 'general_settings',
  currentSubTab: 'theme_and_design',
  loading: false,
  brand: null,
  allBrands: [],
  contactConfig: {
    data: null,
    loading: false,
  },
  lastBrandMenusWeight: 1,
  allPages: { data: null, isLoading: false },
  listings: {
    publish: {
      list: [],
      loading: false,
      page: 1,
      searchKey: '',
      lastPage: 1,
      total: 0,
    },
    unpublish: {
      list: [],
      loading: false,
      page: 1,
      searchKey: '',
      lastPage: 1,
      total: 0,
    },
  },
  cities: {
    list: [],
    loading: false,
    page: 1,
    searchKey: '',
    lastPage: 1,
    total: 0,
  },
  countries: {
    list: [],
    loading: false,
    page: 1,
    searchKey: '',
    lastPage: 1,
    total: 0,
  },
  showHomepageMenuItemAlert: false,
  showHeaderMenuLimitAlert: false,
  isCheckingDns: false,
  blogPosts: null,
  contentPage: {
    data: null,
    loading: false,
  },
  destinationConfig: {
    data: null,
    loading: false,
  },
  listing: {
    data: null,
    loading: false,
  },
  cityConfig: {
    data: null,
    loading: false,
  },
  countryConfig: {
    data: null,
    loading: false,
  },
  tagsAndCategories: {
    list: [],
    isLoading: false,
    selectedTagOrCategory: null,
  },
  onSaveBlogPostClick: null,
  selectedBlog: null,
  SEO_TITLE_LIMIT: 60,
  SEO_DESCRIPTION_LIMIT: 160,
  META_ROBOTS_VALUES: ['None', 'No Image Index', 'No Archive', 'No Snippet'],
  storedBrandId: null,
  AllBrandsCount: 0,
  isMultiBrandOrg: false,
  brandSubscribersCount: 0,
};

const slice = createSlice({
  name: 'marketing',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setCurrentTab(
      state,
      action: PayloadAction<{ tab: MarketingTabName; subTab: MarketingSubTab }>
    ) {
      state.currentTab = action.payload.tab;
      state.currentSubTab = action.payload.subTab;
    },
    setBrand(state, action: PayloadAction<Brand>) {
      state.brand = { ...action.payload, menus: sortBrandMenus(action.payload.menus) };
      state.lastBrandMenusWeight = findLastBrandMenusWeight(action.payload.menus);
      state.showHomepageMenuItemAlert = !hasHomepagePrimaryMenuItem(action.payload.menus);
      state.showHeaderMenuLimitAlert = hasMoreThanPrimaryMenuItemsLimit(action.payload.menus);
    },
    setAllBrands(state, action: PayloadAction<AllBrandsApiResType[]>) {
      state.allBrands = action.payload;
    },
    setAllBrandsCount(state, action: PayloadAction<number>) {
      state.AllBrandsCount = action.payload;
      state.isMultiBrandOrg = action.payload > 1;
    },
    setBlogPosts(state, action: PayloadAction<ContentPage[]>) {
      state.blogPosts = action.payload;
    },
    setBrandId(state, action: PayloadAction<number>) {
      state.storedBrandId = action.payload;
    },
    updateBlogPosts(state, action: PayloadAction<number>) {
      state.blogPosts = state.blogPosts?.filter((blog) => blog.id !== action.payload) || [];
    },
    updateFrontendStyle(state, action: PayloadAction<Partial<BrandFrontendStyle>>) {
      if (state.brand)
        state.brand = {
          ...state.brand,
          frontend_style: { ...state.brand.frontend_style, ...action.payload },
        };
    },
    updateConfig(state, action: PayloadAction<Partial<BrandConfig>>) {
      if (state.brand)
        state.brand = {
          ...state.brand,
          config: { ...state.brand.config, ...action.payload },
        };
    },
    setContactConfig(state, action: PayloadAction<Partial<MarketingState['contactConfig']>>) {
      state.contactConfig = { ...state.contactConfig, ...action.payload };
    },
    setBrandMenus(state, action: PayloadAction<FrontendMenu[]>) {
      if (state.brand) {
        state.brand.menus = sortBrandMenus(action.payload);
        state.lastBrandMenusWeight = findLastBrandMenusWeight(action.payload);
        if (hasHomepagePrimaryMenuItem(action.payload)) state.showHomepageMenuItemAlert = false;
        state.showHeaderMenuLimitAlert = hasMoreThanPrimaryMenuItemsLimit(action.payload);
      }
    },
    setBrandFaqs(state, action: PayloadAction<Faq[]>) {
      if (state.brand) {
        state.brand.faqs = action.payload;
      }
    },
    deleteBrandMenu(state, action: PayloadAction<number>) {
      if (state.brand) {
        const newList = state.brand.menus.filter((menu) => menu.id !== action.payload);
        state.brand.menus = newList;
        state.lastBrandMenusWeight = findLastBrandMenusWeight(newList);
        state.showHeaderMenuLimitAlert = hasMoreThanPrimaryMenuItemsLimit(newList);
      }
    },
    setAllPages(state, action: PayloadAction<AllPages>) {
      state.allPages.data = action.payload;
    },
    setAllPagesLoading(state, action: PayloadAction<boolean>) {
      state.allPages.isLoading = action.payload;
    },
    setPublishListings(
      state,
      action: PayloadAction<Partial<MarketingState['listings']['publish']>>
    ) {
      state.listings.publish = {
        ...state.listings.publish,
        ...action.payload,
        list:
          typeof action.payload.page === 'number' && action.payload.page === 1
            ? [...(action.payload.list || [])]
            : [...state.listings.publish.list, ...(action.payload.list || [])],
      };
    },
    setUnpublishListings(
      state,
      action: PayloadAction<Partial<MarketingState['listings']['unpublish']>>
    ) {
      state.listings.unpublish = {
        ...state.listings.unpublish,
        ...action.payload,
        list:
          typeof action.payload.page === 'number' && action.payload.page === 1
            ? [...(action.payload.list || [])]
            : [...state.listings.unpublish.list, ...(action.payload.list || [])],
      };
    },
    setCities(state, action: PayloadAction<Partial<MarketingState['cities']>>) {
      state.cities = {
        ...state.cities,
        ...action.payload,
        list:
          typeof action.payload.page === 'number' && action.payload.page === 1
            ? [...(action.payload.list || [])]
            : [...state.cities.list, ...(action.payload.list || [])],
      };
    },
    setCountries(state, action: PayloadAction<Partial<MarketingState['countries']>>) {
      state.countries = {
        ...state.countries,
        ...action.payload,
        list:
          typeof action.payload.page === 'number' && action.payload.page === 1
            ? [...(action.payload.list || [])]
            : [...state.countries.list, ...(action.payload.list || [])],
      };
    },
    setContentPage(state, action: PayloadAction<Partial<MarketingState['contentPage']>>) {
      state.contentPage = { ...state.contentPage, ...action.payload };
    },
    addContentPage(state, action: PayloadAction<ContentPage>) {
      if (state.allPages.data) {
        state.allPages.data.pages = [
          ...state.allPages.data.pages,
          { ...action.payload, url: action.payload.route.url_ending },
        ];
      }
    },
    deleteContentPage(state, action: PayloadAction<number>) {
      if (state.allPages.data) {
        state.allPages.data.pages = state.allPages.data.pages.filter(
          (page) => page.id !== action.payload
        );
      }
    },
    setDestinationConfig(
      state,
      action: PayloadAction<Partial<MarketingState['destinationConfig']>>
    ) {
      state.destinationConfig = { ...state.destinationConfig, ...action.payload };
    },
    setListing(state, action: PayloadAction<Partial<MarketingState['listing']>>) {
      state.listing = { ...state.listing, ...action.payload };
    },
    setCityConfig(state, action: PayloadAction<Partial<MarketingState['cityConfig']>>) {
      state.cityConfig = { ...state.cityConfig, ...action.payload };
    },
    setCountryConfig(state, action: PayloadAction<Partial<MarketingState['countryConfig']>>) {
      state.countryConfig = { ...state.countryConfig, ...action.payload };
    },
    closeHomepageMenuItemAlert(state) {
      state.showHomepageMenuItemAlert = false;
    },
    closeHeaderMenuLimitAlert(state) {
      state.showHeaderMenuLimitAlert = false;
    },
    setCheckDnsStatus(state, action: PayloadAction<boolean>) {
      state.isCheckingDns = action.payload;
    },
    setTagsAndCategories(state, action: PayloadAction<TagAndCategory[]>) {
      state.tagsAndCategories.list = action.payload;
    },
    setTagsAndCategoriesLoading(state, action: PayloadAction<boolean>) {
      state.tagsAndCategories.isLoading = action.payload;
    },
    addTagAndCategory(state, action: PayloadAction<TagAndCategory>) {
      state.tagsAndCategories.list = [
        { ...action.payload, url: action.payload.route?.url_ending || '' },
        ...state.tagsAndCategories.list,
      ];
    },
    updateTagAndCategory(state, action: PayloadAction<TagAndCategory>) {
      const findIndex = state.tagsAndCategories.list.findIndex((TC) => TC.id === action.payload.id);
      if (findIndex > -1) state.tagsAndCategories.list.splice(findIndex, 1, action.payload);
    },
    deleteTagAndCategory(state, action: PayloadAction<number>) {
      state.tagsAndCategories.list = state.tagsAndCategories.list.filter(
        (TC) => TC.id !== action.payload
      );
    },
    onSaveBlogPost(state, action: PayloadAction<MarketingState['onSaveBlogPostClick']>) {
      state.onSaveBlogPostClick = action.payload;
    },
    setSelectedBlog(state, action: PayloadAction<ContentPage | null>) {
      state.selectedBlog = action.payload;
    },
    setSelectedTagOrCategory(state, action: PayloadAction<TagAndCategory | null>) {
      state.tagsAndCategories.selectedTagOrCategory = action.payload;
    },
    setBrandSubscribersCount(state, action: PayloadAction<number>) {
      state.brandSubscribersCount = action.payload;
    },
  },
});

export const {
  setCurrentTab,
  updateFrontendStyle,
  updateConfig,
  closeHomepageMenuItemAlert,
  closeHeaderMenuLimitAlert,
  setContentPage,
  setListing,
  setCountryConfig,
  setCityConfig,
  setSelectedBlog,
  setContactConfig,
  setBrandId,
  setSelectedTagOrCategory,
  setTagsAndCategories,
  setAllBrands,
  setBlogPosts,
  setAllPages,
} = slice.actions;
export default slice.reducer;

export function getBrand(id: number) {
  return async () => {
    try {
      dispatch(slice.actions.setLoading(true));
      const res = await marketingAPI.fetchBrand(id);
      dispatch(slice.actions.setBrand(res.data));
    } catch (e) {
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };
}

export function updateBrandFrontendStyle(
  frontendStyleId: number,
  requestBody: UpdateFrontendStyleBody,
  setLoading?: (succeed: boolean) => void,
  callback?: (response: AxiosResponse<any, any> | null, status: number) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const res = await marketingAPI.updateFrontendStyle(frontendStyleId, requestBody);
      dispatch(slice.actions.updateFrontendStyle(res.data));
      callback?.(res, res.status);
    } catch (e) {
      callback?.(null, e?.status || 500);
    } finally {
      setLoading?.(false);
    }
  };
}

export function updateBrandConfig(
  configId: number,
  requestBody: UpdateConfigBody,
  setLoading?: (succeed: boolean) => void,
  callback?: (response: AxiosResponse<any, any> | null, status: number) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const res = await marketingAPI.updateConfig(configId, requestBody);
      dispatch(slice.actions.updateConfig(res.data));
      callback?.(res, res.status);
    } catch (e) {
      callback?.(null, e?.status || 500);
    } finally {
      setLoading?.(false);
    }
  };
}

export function updateLiveBrandConfig(
  id: number,
  requestBody: UpdateConfigBody,
  fieldToUpdate: Partial<BrandConfig>,
  fieldBeforeUpdate: Partial<BrandConfig>
) {
  return async () => {
    try {
      dispatch(slice.actions.updateConfig(fieldToUpdate));
      await marketingAPI.updateConfig(id, requestBody);
    } catch (e) {
      dispatch(slice.actions.updateConfig(fieldBeforeUpdate));
    }
  };
}

export function getContactConfig(id: number) {
  return async () => {
    try {
      dispatch(slice.actions.setContactConfig({ loading: true }));
      const res = await marketingAPI.fetchContactConfig(id);
      dispatch(slice.actions.setContactConfig({ data: res.data }));
    } catch (e) {
    } finally {
      dispatch(slice.actions.setContactConfig({ loading: false }));
    }
  };
}

export function updateContactConfig(
  id: number,
  requestBody: UpdateContactConfig,
  setLoading?: (succeed: boolean) => void,
  callback?: (response: AxiosResponse<any, any> | null, status: number) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const res = await marketingAPI.updateContactConfig(id, requestBody);
      dispatch(slice.actions.setContactConfig({ data: res.data }));
      callback?.(res, res.status);
    } catch (e) {
      callback?.(null, e?.status || 500);
    } finally {
      setLoading?.(false);
    }
  };
}

export function getAllBrands() {
  return async () => {
    dispatch(slice.actions.setLoading(true));
    try {
      const response = await marketingAPI.fetchAllBrands();
      dispatch(slice.actions.setAllBrands(response.data));
    } catch (error) {
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };
}
export function updateBrandMenus(
  brandId: number,
  requestBody: (UpdateFrontendMenuBody | number)[],
  setLoading?: (succeed: boolean) => void,
  callback?: (response: AxiosResponse<any, any> | null, status: number) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const res = await marketingAPI.updateBrand(brandId, { menus: requestBody });
      dispatch(slice.actions.setBrandMenus(res.data.menus));
      callback?.(res, res.status);
    } catch (e) {
      callback?.(null, e?.status || 500);
    } finally {
      setLoading?.(false);
    }
  };
}

export function deleteBrandMenu(
  id: number,
  setLoading?: (succeed: boolean) => void,
  callback?: (response: AxiosResponse<any, any> | null, status: number) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const res = await marketingAPI.deleteFrontendMenu(id);
      dispatch(slice.actions.deleteBrandMenu(id));
      callback?.(res, res.status);
    } catch (e) {
      callback?.(null, e?.status || 500);
    } finally {
      setLoading?.(false);
    }
  };
}

export function updateBrandFaqs(
  brandId: number,
  requestBody: UpdateFaqs,
  setLoading?: (succeed: boolean) => void,
  callback?: (response: AxiosResponse<any, any> | null, status: number) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const res = await marketingAPI.updateBrand(brandId, { faqs: requestBody });
      dispatch(slice.actions.setBrandFaqs(res.data.faqs));
      callback?.(res, res.status);
    } catch (e) {
      callback?.(null, e?.status || 500);
    } finally {
      setLoading?.(false);
    }
  };
}

export function getAllPages(brandId: number) {
  return async () => {
    try {
      dispatch(slice.actions.setAllPagesLoading(true));
      const res = await marketingAPI.fetchAllPages(brandId);
      dispatch(slice.actions.setAllPages(res.data));
    } catch (e) {
    } finally {
      dispatch(slice.actions.setAllPagesLoading(false));
    }
  };
}

export function getAllBrandsCount() {
  return async () => {
    try {
      const res = await marketingAPI.fetchAllBrandsCount();
      dispatch(slice.actions.setAllBrandsCount(Number(res.data.count) || 0));
    } catch (e) {}
  };
}

let publishListingsController: AbortController | null = new AbortController();
export function getPublishListings(brandId: number, page = 1, searchKey = '') {
  return async () => {
    try {
      dispatch(slice.actions.setPublishListings({ page, searchKey, loading: true }));
      if (publishListingsController) {
        publishListingsController.abort();
        publishListingsController = new AbortController();
      }
      const { data } = await marketingAPI.fetchListings(
        brandId,
        true,
        page,
        searchKey,
        publishListingsController?.signal
      );
      dispatch(
        slice.actions.setPublishListings({
          list: data.data,
          lastPage: data.meta.last_page,
          ...(!searchKey && { total: data.meta.total_items }),
        })
      );
      publishListingsController = null;
    } catch (e) {
    } finally {
      if (!publishListingsController) {
        publishListingsController = new AbortController();
        dispatch(slice.actions.setPublishListings({ loading: false }));
      }
    }
  };
}

let unpublishListingsController: AbortController | null = new AbortController();
export function getUnpublishListings(brandId: number, page = 1, searchKey = '') {
  return async () => {
    try {
      dispatch(slice.actions.setUnpublishListings({ page, searchKey, loading: true }));
      if (unpublishListingsController) {
        unpublishListingsController.abort();
        unpublishListingsController = new AbortController();
      }
      const { data } = await marketingAPI.fetchListings(
        brandId,
        false,
        page,
        searchKey,
        unpublishListingsController?.signal
      );
      dispatch(
        slice.actions.setUnpublishListings({
          list: data.data,
          lastPage: data.meta.last_page,
          ...(!searchKey && { total: data.meta.total_items }),
        })
      );
      unpublishListingsController = null;
    } catch (e) {
    } finally {
      if (!unpublishListingsController) {
        unpublishListingsController = new AbortController();
        dispatch(slice.actions.setUnpublishListings({ loading: false }));
      }
    }
  };
}

let citiesController: AbortController | null = new AbortController();
export function getCities(brandId: number, page = 1, searchKey = '') {
  return async () => {
    try {
      dispatch(slice.actions.setCities({ page, searchKey, loading: true }));
      if (citiesController) {
        citiesController.abort();
        citiesController = new AbortController();
      }
      const { data } = await marketingAPI.fetchCities(
        brandId,
        page,
        searchKey,
        citiesController?.signal
      );
      dispatch(
        slice.actions.setCities({
          list: data.data,
          lastPage: data.meta.last_page,
          ...(!searchKey && { total: data.meta.total_items }),
        })
      );
      citiesController = null;
    } catch (e) {
    } finally {
      if (!citiesController) {
        citiesController = new AbortController();
        dispatch(slice.actions.setCities({ loading: false }));
      }
    }
  };
}

let countriesController: AbortController | null = new AbortController();
export function getCountries(brandId: number, page = 1, searchKey = '') {
  return async () => {
    try {
      dispatch(slice.actions.setCountries({ page, searchKey, loading: true }));
      if (countriesController) {
        countriesController.abort();
        countriesController = new AbortController();
      }
      const { data } = await marketingAPI.fetchCountries(
        brandId,
        page,
        searchKey,
        countriesController?.signal
      );
      dispatch(
        slice.actions.setCountries({
          list: data.data,
          lastPage: data.meta.last_page,
          ...(!searchKey && { total: data.meta.total_items }),
        })
      );
      countriesController = null;
    } catch (e) {
    } finally {
      if (!countriesController) {
        countriesController = new AbortController();
        dispatch(slice.actions.setCountries({ loading: false }));
      }
    }
  };
}

export function checkDns(orgConfigId: number, onError?: VoidFunction) {
  return async () => {
    try {
      dispatch(slice.actions.setCheckDnsStatus(true));
      const res = await marketingAPI.checkDns(orgConfigId);
      if (res.data.status === 'success') {
        dispatch(slice.actions.updateConfig({ domain_approved: true }));
      } else {
        onError?.();
      }
    } catch (e) {
      onError?.();
    } finally {
      dispatch(slice.actions.setCheckDnsStatus(false));
    }
  };
}
export function createContentPage(
  requestBody: CreateContentPage,
  setLoading?: (succeed: boolean) => void,
  callback?: (response: AxiosResponse<any, any> | null, status: number) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const res = await marketingAPI.createContentPage(requestBody);
      dispatch(slice.actions.addContentPage(res.data));
      callback?.(res, res.status);
    } catch (e) {
      callback?.(null, e?.status || 500);
    } finally {
      setLoading?.(false);
    }
  };
}

export function getContentPage(id: number) {
  return async () => {
    try {
      dispatch(slice.actions.setContentPage({ loading: true }));
      const res = await marketingAPI.fetchContentPage(id);
      dispatch(slice.actions.setContentPage({ data: res.data }));
    } catch (e) {
    } finally {
      dispatch(slice.actions.setContentPage({ loading: false }));
    }
  };
}

export function updateContentPage(
  id: number,
  requestBody: UpdateContentPage,
  setLoading?: (succeed: boolean) => void,
  callback?: (response: AxiosResponse<any, any> | null, status: number) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const res = await marketingAPI.updateContentPage(id, requestBody);
      dispatch(slice.actions.setContentPage({ data: res.data }));
      callback?.(res, res.status);
    } catch (e) {
      callback?.(null, e?.status || 500);
    } finally {
      setLoading?.(false);
    }
  };
}

export function deleteContentPage(
  id: number,
  setLoading?: (succeed: boolean) => void,
  callback?: (response: AxiosResponse<any, any> | null, status: number) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const res = await marketingAPI.deleteContentPage(id);
      dispatch(slice.actions.deleteContentPage(id));
      callback?.(res, res.status);
    } catch (e) {
      callback?.(null, e?.status || 500);
    } finally {
      setLoading?.(false);
    }
  };
}

export function getListing(id: number, brandId: number) {
  return async () => {
    try {
      dispatch(slice.actions.setListing({ loading: true }));
      const res = await marketingAPI.fetchListing(id, brandId);
      dispatch(slice.actions.setListing({ data: res.data }));
    } catch (e) {
    } finally {
      dispatch(slice.actions.setListing({ loading: false }));
    }
  };
}

export function updateListing(
  id: number,
  currentData: Property,
  body: UpdateProperty,
  setLoading?: (succeed: boolean) => void,
  callback?: (response: AxiosResponse<any, any> | null, status: number) => void
) {
  return async () => {
    try {
      setLoading?.(true);
      const res = await marketingAPI.updateListing(id, body);
      dispatch(slice.actions.setListing({ data: { ...currentData, ...res.data } }));
      callback?.(res, res.status);
    } catch (e) {
      callback?.(null, e?.status || 500);
    } finally {
      setLoading?.(false);
    }
  };
}

export function getCityConfig(id: number, brandId: number) {
  return async () => {
    try {
      dispatch(slice.actions.setCityConfig({ loading: true }));
      const res = await marketingAPI.fetchCity(id, brandId);
      dispatch(slice.actions.setCityConfig({ data: res.data }));
    } catch (e) {
    } finally {
      dispatch(slice.actions.setCityConfig({ loading: false }));
    }
  };
}

export function updateCityConfig(
  id: number,
  brandId: number,
  requestBody: UpdateCity,
  setLoading?: (succeed: boolean) => void,
  callback?: (response: AxiosResponse<any, any> | null, status: number) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const res = await marketingAPI.updateCity(id, brandId, requestBody);
      dispatch(slice.actions.setCityConfig({ data: res.data }));
      callback?.(res, res.status);
    } catch (e) {
      callback?.(null, e?.status || 500);
    } finally {
      setLoading?.(false);
    }
  };
}

export function getCountryConfig(id: number, brandId: number) {
  return async () => {
    try {
      dispatch(slice.actions.setCountryConfig({ loading: true }));
      const res = await marketingAPI.fetchCountry(id, brandId);
      dispatch(slice.actions.setCountryConfig({ data: res.data }));
    } catch (e) {
    } finally {
      dispatch(slice.actions.setCountryConfig({ loading: false }));
    }
  };
}

export function updateCountryConfig(
  id: number,
  brandId: number,
  requestBody: UpdateCountry,
  setLoading?: (succeed: boolean) => void,
  callback?: (response: AxiosResponse<any, any> | null, status: number) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const res = await marketingAPI.updateCountry(id, brandId, requestBody);
      dispatch(slice.actions.setCountryConfig({ data: res.data }));
      callback?.(res, res.status);
    } catch (e) {
      callback?.(null, e?.status || 500);
    } finally {
      setLoading?.(false);
    }
  };
}

export function getBlogPosts(
  brandId: number,
  setLoading?: (succeed: boolean) => void,
  callback?: (response: AxiosResponse<ContentPage[], any> | null, status: number) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const res = await marketingAPI.fetchPosts(brandId);
      dispatch(slice.actions.setBlogPosts(res.data));
      callback?.(res, res.status);
    } catch (e) {
      callback?.(null, e?.status || 500);
    } finally {
      setLoading?.(false);
    }
  };
}

export function deleteBlogPost(
  blogId: number,
  setLoading?: (succeed: boolean) => void,
  callback?: (response: AxiosResponse<any, any> | null, status: number) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const res = await marketingAPI.deleteContentPage(blogId);
      dispatch(slice.actions.updateBlogPosts(blogId));
      callback?.(res, res.status);
    } catch (e) {
      callback?.(null, e?.status || 500);
    } finally {
      setLoading?.(false);
    }
  };
}

export function getDestinationConfig(id: number) {
  return async () => {
    try {
      dispatch(slice.actions.setDestinationConfig({ loading: true }));
      const res = await marketingAPI.fetchDestinationConfig(id);
      dispatch(slice.actions.setDestinationConfig({ data: res.data }));
    } catch (e) {
    } finally {
      dispatch(slice.actions.setDestinationConfig({ loading: false }));
    }
  };
}

export function updateDestinationConfig(
  id: number,
  requestBody: UpdateDestinationConfig,
  setLoading?: (succeed: boolean) => void,
  callback?: (response: AxiosResponse<any, any> | null, status: number) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const res = await marketingAPI.updateDestinationConfig(id, requestBody);
      dispatch(slice.actions.setDestinationConfig({ data: res.data }));
      callback?.(res, res.status);
    } catch (e) {
      callback?.(null, e?.status || 500);
    } finally {
      setLoading?.(false);
    }
  };
}
export function updateBlogPost(args: {
  blogId: number;
  keysToUpdate: UpdateContentPage;
  allBlogs: ContentPage[];
  setLoading?: (succeed: boolean) => void;
  callback?: (response: AxiosResponse<any, any> | null, status?: number) => void;
}) {
  const { blogId, keysToUpdate, allBlogs, setLoading, callback } = args;

  return async () => {
    setLoading?.(true);
    try {
      let newBlogsArray = [...allBlogs];
      const modifiedBlogIndex = allBlogs.findIndex((blog) => blog.id === blogId);

      const res = await marketingAPI.updateContentPage(blogId, keysToUpdate);
      if (modifiedBlogIndex || modifiedBlogIndex === 0) {
        newBlogsArray.splice(modifiedBlogIndex, 1, { ...allBlogs[modifiedBlogIndex], ...res.data });
      }
      dispatch(slice.actions.setBlogPosts(newBlogsArray));
      callback?.(res);
    } catch (e) {
      callback?.(null);
    } finally {
      setLoading?.(false);
    }
  };
}

export function getTagsAndCategories(
  brand_id: number,
  setLoading?: (succeed: boolean) => void,
  callback?: (response: TagAndCategory[] | null, status: number) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      dispatch(slice.actions.setTagsAndCategoriesLoading(true));
      let tagsAndCategories: TagAndCategory[] = [];
      let page = 1;
      let hasMore = true;
      while (hasMore) {
        const res = await marketingAPI.fetchTagsAndCategories({ page: page++, brand: brand_id });
        tagsAndCategories = [...tagsAndCategories, ...res.data.data];
        if (res.data.meta.current_page === res.data.meta.last_page) hasMore = false;
      }
      dispatch(slice.actions.setTagsAndCategories(tagsAndCategories));
      callback?.(tagsAndCategories, 200);
    } catch (e) {
      callback?.(null, e?.status || 500);
    } finally {
      dispatch(slice.actions.setTagsAndCategoriesLoading(false));
      setLoading?.(false);
    }
  };
}

export function getTagOrCategory(
  id: number,
  setLoading?: (succeed: boolean) => void,
  callback?: (response: AxiosResponse<TagAndCategory, any> | null, status: number) => void
) {
  return async () => {
    try {
      const res = await marketingAPI.fetchTagAndCategory(id);
      dispatch(slice.actions.setSelectedTagOrCategory(res.data));
      callback?.(res, 200);
    } catch (e) {
      callback?.(null, e?.status || 500);
    } finally {
      setLoading?.(false);
    }
  };
}

export function createTagAndCategory(
  requestBody: CreateTagAndCategory,
  setLoading?: (succeed: boolean) => void,
  callback?: (response: AxiosResponse<any, any>, succeed: boolean) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const res = await marketingAPI.createTagAndCategory(requestBody);
      dispatch(slice.actions.addTagAndCategory(res.data));
      callback?.(res, true);
    } catch (e) {
      callback?.(e, false);
    } finally {
      setLoading?.(false);
    }
  };
}

export function updateTagAndCategory(
  id: number,
  requestBody: UpdateTagAndCategory,
  setLoading?: (succeed: boolean) => void,
  callback?: (response: AxiosResponse<any, any>, succeed: boolean) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const res = await marketingAPI.updateTagAndCategory(id, requestBody);
      dispatch(slice.actions.updateTagAndCategory(res.data));
      callback?.(res, true);
    } catch (e) {
      callback?.(e, false);
    } finally {
      setLoading?.(false);
    }
  };
}

export function deleteTagAndCategory(
  id: number,
  setLoading?: (succeed: boolean) => void,
  callback?: (response: AxiosResponse<any, any> | null, status: number) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const res = await marketingAPI.deleteTagAndCategory(id);
      dispatch(slice.actions.deleteTagAndCategory(id));
      callback?.(res, res.status);
    } catch (e) {
      callback?.(null, e?.status || 500);
    } finally {
      setLoading?.(false);
    }
  };
}
export function onSaveBlogPost(action: MarketingState['onSaveBlogPostClick']) {
  dispatch(slice.actions.onSaveBlogPost(action));
}

export function getBrandSubscribersCount(brandId: number) {
  return async () => {
    try {
      const res = await marketingAPI.fetchNewsletterSubscribersCount(brandId);
      dispatch(slice.actions.setBrandSubscribersCount(res.data.meta.total_items));
    } catch (e) {}
  };
}

export function updateRouteConfig(
  routeId: number,
  requestBody: UpdateRouteConfig,
  setLoading?: (succeed: boolean) => void,
  callback?: (response: AxiosResponse<RouteConfig, any>, succeed: boolean) => void
) {
  return async () => {
    setLoading?.(true);
    try {
      const res = await marketingAPI.updateRoute(routeId, requestBody);
      callback?.(res, true);
    } catch (e) {
      callback?.(e, false);
    } finally {
      setLoading?.(false);
    }
  };
}
