import { HeaderInfo, InvoiceLine, PriceBreakdown, SummaryInfo } from 'src/@types/invoice';

export function headerInfoNormalizer(data): HeaderInfo {
  return {
    invoiceId: data.id,
    createdOn: data.created_at,
    updatedAt: data.updated_at,
    docNumber: data.doc_number,
    hasIntegrationWithXero: data.has_integrations_with_Xero,
  };
}

export function SummaryInfoNormalizer(invoice): SummaryInfo {
  return {
    id: invoice.id,
    docStatus: invoice.doc_status,
    docNumber: invoice.doc_number,
    docDate: invoice.doc_date,
    entityInvoice: {
      id: invoice.entity_invoiced.id,
      firstName: invoice.entity_invoiced.first_name ?? '',
      lastName: invoice.entity_invoiced.last_name ?? '',
      middleName: invoice.entity_invoiced.middle_name ?? '',
      mobileNumber: invoice.entity_invoiced.mobile_number ?? '',
      name: invoice.entity_invoiced.name,
      email: invoice.entity_invoiced.email,
      type: invoice.entity_invoiced.type,
    },
    contact: {
      id: invoice.contact.id,
      firstName: invoice.contact.first_name ?? '',
      lastName: invoice.contact.last_name ?? '',
      middleName: invoice.contact.middle_name ?? '',
      mobileNumber: invoice.contact.mobile_number ?? '',
      email: invoice.contact.email ?? '',
    },
    corporation: invoice.corporation
      ? {
        id: invoice.corporation.id,
        name: invoice.corporation.name,
      }
      : null,
    booking: invoice.booking
      ? {
        id: invoice.booking.id,
        bookingNumber: invoice.booking.booking_no,
        canPaymentPerBooking: invoice.booking.can_payment_per_booking,
        finalizationStatus: invoice.booking.finalization_status,
      }
      : {
        id: invoice.invoice_lines[0]?.booking?.id,
        bookingNumber: invoice.invoice_lines[0]?.booking?.booking_no,
        canPaymentPerBooking: invoice.invoice_lines[0]?.booking?.can_payment_per_booking,
        finalizationStatus: invoice.invoice_lines[0]?.booking?.finalization_status,
      },
    totalAmount: {
      value: invoice?.price_breakdown?.grandTotal,
      currency: invoice?.price_breakdown?.currency,
    },
    totalDue: {
      value: invoice.booking_order?.total_due.value,
      currency: invoice.booking_order?.total_due.currency,
    },
    xeroType: invoice.xero_line_amount_type ?? null, //xero_line_amount_type // tax incloud or exclude
    remoteNumber: invoice.remote_number ?? null,
    xeroLink: invoice.view_xero_invoice_u_r_l ?? null,
    invoicePrintSettings: {
      bankAccountInfo: invoice.bank_account_information_show_on_invoice,
      paymentInfo: invoice.payment_method_show_on_invoice,
      securityDepositInfo: invoice.booking ? invoice.booking.include_s_d_in_invoice : false,
    },
  };
}

export function priceBreakdownNormalizer(invoice: any): PriceBreakdown {
  return {
    totalPrice: invoice.price_breakdown.total_price,
    total_paid: invoice.price_breakdown.total_paid,
    totalDue: invoice.price_breakdown.total_due,
    grandTotal: invoice.price_breakdown.grandTotal,
    currency: invoice.price_breakdown.currency,
    currencySymbol: invoice.price_breakdown.currency_symbol,
  };
}

export function invoiceLineNormalizer(invoiceLine: any): InvoiceLine {
  return {
    id: invoiceLine.id,
    type: invoiceLine.type,
    addon: invoiceLine.addon
      ? { name: invoiceLine.addon.name, label: invoiceLine.addon.label }
      : null,
    account: invoiceLine.account
      ? { id: invoiceLine.account.id, name: invoiceLine.account.name }
      : null,
    quantity: invoiceLine.quantity,
    description: invoiceLine.description,
    unitPrice: {
      value: invoiceLine.price.value,
      currency: invoiceLine.price.currency,
      base_value: invoiceLine.price.base_value,
    },
    taxAmount: invoiceLine.tax_amount,
    discount: {
      value: invoiceLine.discount.value,
      currency: invoiceLine.discount.currency,
      base_value: invoiceLine.discount.base_value,
    },
    totalAmount: {
      value: invoiceLine.total_amount.value,
      currency: invoiceLine.total_amount.currency,
      base_value: invoiceLine.total_amount.base_value,
    },
  };
}
