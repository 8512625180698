import numeral from 'numeral';
import { Price } from 'src/@types/common';

numeral.register('locale', 'en-gb', {
  delimiters: {
      thousands: ',',
      decimal: '.'
  },
  abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
  },
  ordinal: function (number) {
      var b = number % 10;
      return (~~ (number % 100 / 10) === 1) ? 'th' :
          (b === 1) ? 'st' :
          (b === 2) ? 'nd' :
          (b === 3) ? 'rd' : 'th';
  },
  currency: {
      symbol: '£'
  }
});

numeral.locale('en-gb');
// ----------------------------------------------------------------------

export function fCurrency(number: string | number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fCurrencyWithSymbol(number: string | number, symbol: string = '£') {
  return '\u202A'+symbol+'\u202C' + numeral(number).format('0,0.00');
}

export function fPercent(number: number) {
  return numeral(number / 100).format('0.00%');
}

export function fPercentage(number: number) {
  return numeral(number / 100).format('0%');
}

export function fNumber(number: string | number, format?: string) {
  return numeral(number).format(format);
}

export function fShortenNumber(number: string | number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number: string | number) {
  return numeral(number).format('0.0 b');
}

export function fBookingId(number: string | number) {
  return numeral(number).format('0,0').replace(',', '-');
}

export function fPriceToNumber(price: string) {
  return numeral(price).value();
}

export function fTimeZone(timeZone: string) {
  const number = parseFloat(timeZone);
  const sign = number >= 0 ? '+' : '';
  return sign + timeZone;
}
