import { useEffect, useState } from 'react';
import { addDays, subDays } from 'date-fns';
import { DatePicker, DateRange, DateRangePicker, SingleInputDateRangeField, } from '@mui/x-date-pickers-pro';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import { Autocomplete, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import useLocales from 'src/hooks/useLocales';
import useFilters from 'src/hooks/useFilterState';
import useResponsive from 'src/hooks/useResponsive';
import { fDT, fYearMonthDay } from 'src/utils/formatTime';
import { getDateFilterFromToDate } from 'src/utils/getDateFilterFromTo';
import { DateFilterSelectValue, FilterValue } from 'src/@types/filter';
import Iconify from 'src/components/Iconify';
import InputWrapper from 'src/components/InputWrapper';


export type DateCondition = 'specific_date_range' | 'earlier_than' | 'later_than';

interface SelectedType {
  condition: string;
  shortcut: string;
  start: Date | null;
  end: Date | null;
}

type Props = {
  stateKey: string;
  shortcuts: DateFilterSelectValue[];
  value?: SelectedType;
  onChange?: (value: SelectedType) => void;
}

export const getDateFilterSearchParams = (
  keyParam: string,
  value: {
    condition: DateCondition;
    start: Date;
    end: Date | null;
  }
): { [key: string]: string | null } => {
  const { condition, start, end } = value;
  const startValue = fYearMonthDay(start);
  const endValue = end ? fYearMonthDay(addDays(end, 1)) : null;
  switch (condition) {
    case 'specific_date_range':
      return {
        [`${keyParam}[after]`]: startValue,
        [`${keyParam}[before]`]: endValue,
      };
    case 'later_than':
      return { [`${keyParam}[strictly_after]`]: startValue };
    case 'earlier_than':
      return { [`${keyParam}[strictly_before]`]: startValue };
    default:
      return {};
  }
};

export const CONDITIONS: DateCondition[] = ['specific_date_range', 'earlier_than', 'later_than'];

const FilterCustomAccordionDate = ({ stateKey, shortcuts, value, onChange }: Props) => {
  const isMobile = useResponsive('down', 'sm');
  const { translate, shortDateFormat, currentLang } = useLocales('common');
  const { filtersState, setFiltersState, setAppliedFilters, updateFiltersState } = useFilters();

  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<SelectedType>({
    condition: value?.condition ?? '',
    shortcut: value?.shortcut ?? 'custom',
    start: value?.start ?? null,
    end: value?.end ?? null,
  });

  useEffect(() => {
    const currentFilter = filtersState[stateKey] as FilterValue;
    setSelected({
      condition: selected.shortcut === 'reset' ? selected.condition : currentFilter?.value?.condition || '',
      shortcut: currentFilter?.value?.shortcut || 'custom',
      start: currentFilter?.value?.start ?? null,
      end: currentFilter?.value?.end ?? null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersState[stateKey]]);

  const shortcutsItems = [
    ...shortcuts.map((option) => ({
      label: translate(option),
      value: option,
      getValue: () => {
        const { from } = getDateFilterFromToDate(option);
        const { to } = getDateFilterFromToDate(option);
        return [from, to ? subDays(to, 1) : null];
      }
    })),
    { label: translate('reset'), value: 'reset', getValue: () => [null, null] }
  ] as PickersShortcutsItem<any>[];

  const formatDate = (date: Date) =>
    fDT({
      date,
      pattern: shortDateFormat,
      options: { locale: currentLang.dateLocale },
    });

  const update = (value: { id: string; label: string; value: SelectedType | null } | null) => {
    updateFiltersState({
      type: 'custom-accordion',
      key: stateKey,
      value: value,
      reason: 'replace',
      label: value?.label || '',
    });
  };

  const handleUpdateFilterState = ({ condition, shortcut, end, start }: SelectedType) => {
    if (start) {
      if (condition === 'specific_date_range') {
        if (end) {
          update({
            id: stateKey,
            label: `${translate(condition)} ${formatDate(start)}-${formatDate(end)}`,
            value: {
              condition,
              shortcut,
              start,
              end,
            },
          });
        }
      } else {
        update({
          id: stateKey,
          label: `${translate(condition)} ${formatDate(start)}`,
          value: {
            condition,
            shortcut,
            start,
            end: null,
          },
        });
      }
    }
  };

  const resetSpecificDateRange = () => {
    setAppliedFilters((prev) => prev.filter((appliedFilter) => appliedFilter.stateKey !== stateKey));
    setFiltersState({ ...filtersState, [stateKey]: null });
  };

  const handleConditionChange = (value: string) => {
    setSelected((prev) => ({ ...prev, condition: value }));
    handleUpdateFilterState({ condition: value, shortcut: selected.shortcut, start: selected.start, end: selected.end });
  };

  const handleDateChange = (date: DateRange<Date> | Date | null, context: any) => {
    const shortcut = context.shortcut?.value || 'custom';
    const startDate = Array.isArray(date) ? date[0] || null : date;
    const endDate = Array.isArray(date) ? date[1] || null : date;
    setSelected((prev) => ({ ...prev, shortcut, start: startDate, end: endDate }));
    handleUpdateFilterState({ condition: selected.condition, shortcut, start: startDate, end: endDate });
    if (shortcut === 'reset') resetSpecificDateRange();
    if (onChange) onChange({ condition: selected.condition, shortcut, start: startDate, end: endDate });
  };

  return (
    <Stack gap={2}>
      <InputWrapper title={translate('condition')}>
        <Autocomplete
          fullWidth
          disableClearable
          options={CONDITIONS}
          value={selected.condition}
          onChange={(_e, value) => handleConditionChange(value)}
          getOptionLabel={(option) => translate(option)}
          renderInput={(params) => <TextField {...params} />}
        />
      </InputWrapper>
      {selected.condition ? (
        selected.condition === 'specific_date_range' ? (
          <DateRangePicker
            open={isOpen}
            calendars={isMobile ? 1 : 2}
            value={[selected.start, selected.end]}
            format={shortDateFormat.format}
            onChange={handleDateChange}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            slots={{ field: SingleInputDateRangeField }}
            slotProps={{
              textField: () => ({
                InputProps: {
                  placeholder: '',
                  ...(!selected.start && !selected.end && { value: '' }),
                  ...(selected.shortcut !== 'custom' && selected.shortcut !== 'reset' && {
                    value: translate(selected.shortcut)
                  }),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small">
                        <Iconify icon="ic:round-calendar-today" width={24} height={24}/>
                      </IconButton>
                    </InputAdornment>
                  ),
                },
                onClick: () => setIsOpen(!isOpen),
                onKeyDown: (event) => event.preventDefault(),
              }),
              shortcuts: {
                items: shortcutsItems,
              },
              toolbar: {
                hidden: false,
                sx: { '& .MuiPickersToolbar-content .MuiTypography-root': { alignSelf: 'center' } },
              },
              layout: {
                sx: {
                  ...(isMobile && {
                    '&.MuiPickersLayout-root': {
                      display: 'flex !important', flexDirection: 'column !important',
                      '& .MuiList-root': {
                        display: 'flex',
                        flexWrap: 'wrap',
                        maxWidth: 'inherit',
                        padding: 1,
                        gap: 0.5,
                        '& .MuiListItem-root': {
                          px: 0,
                          width: 'max-content',
                        },
                      },
                      '& .MuiPickersLayout-contentWrapper': {
                        alignItems: 'center',
                      },
                    },
                  }),
                },
              },
              popper: {
                placement: 'auto-start',
              },
              actionBar: { actions: [] },
            }}
          />
        ) : (
          <DatePicker
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            value={selected.start}
            onChange={handleDateChange}
            format={shortDateFormat.format}
            views={['year', 'month', 'day']}
            slotProps={{
              textField: {
                InputProps: {
                  placeholder: '',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small">
                        <Iconify icon="ic:round-calendar-today" width={24} height={24}/>
                      </IconButton>
                    </InputAdornment>
                  ),
                },
                onClick: () => setIsOpen(!isOpen),
                onKeyDown: (event) => event.preventDefault(),
              },
              popper: {
                placement: 'auto-start',
              },
            }}
          />
        )
      ) : null}
    </Stack>
  );
};

export default FilterCustomAccordionDate;
