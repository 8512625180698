import { Card, CardProps } from '@mui/material';
import { EditableProvider, RowConfigType } from 'src/contexts/EditableContext';
import useEditable from 'src/hooks/useEditable';

interface Props extends CardProps {
  initialRowConfig?: RowConfigType;
}

const StyledCard = ({ children, ...other }: Props) => {
  const { isHovered, editMode, setIsHovered } = useEditable();
  return (
    <Card
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      variant={isHovered || editMode ? 'outlined' : 'elevation'}
      {...other}
    >
      {children}
    </Card>
  );
};

const EditableCard = (props: Props) => (
  <EditableProvider initialRowConfig={props.initialRowConfig}>
    <StyledCard {...props} />
  </EditableProvider>
);

export default EditableCard;