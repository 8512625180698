import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SupplierList, SuppliersState, SupplierView } from 'src/@types/suppliers';
import { SortDirection } from 'src/@types/booking-list';
import { FilterState } from 'src/@types/filter';
import SuppliersApi from 'src/api/suppliers';
import { dispatch } from 'src/redux/store';

const initialState: SuppliersState = {
  loading: false,
  refresh: false,
  supplier: {
    view: null,
    list: [],
    rowCount: 0,
    gridConfigs: null,
  },
};

const slice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setRefresh(state, action: PayloadAction<boolean>) {
      state.refresh = action.payload;
    },
    setRowCount(state, action: PayloadAction<number>) {
      state.supplier.rowCount = action.payload;
    },
    setGridConfigs(state, action) {
      state.supplier.gridConfigs = action.payload;
    },
    setSuppliers(state, action: PayloadAction<SupplierList[]>) {
      state.loading = false;
      state.supplier.list = action.payload;
    },
    setSupplier(state, action: PayloadAction<SupplierView>) {
      state.supplier.view = action.payload;
    },
    updateSupplier(state, action: PayloadAction<{ newValue: Partial<SupplierView> }>) {
      const { newValue } = action.payload;
      if (state.supplier.view) {
        state.supplier.view = { ...state.supplier.view, ...newValue };
      }
    },
  },
});

// reducer
export default slice.reducer;

// actions
export const {
  setLoading,
  setRefresh,
  setRowCount,
  setGridConfigs,
  setSuppliers,
  updateSupplier,
} = slice.actions;

export function getSupplier(supplierId: number) {
  return async () => {
    dispatch(slice.actions.setLoading(true));
    try {
      const response = await SuppliersApi.fetchSupplier(supplierId);
      dispatch(slice.actions.setSupplier(response.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };
}

export function getSupplierList({
  page,
  limit,
  filtersState,
  order,
  signal,
  advanceFilterParam,
}: {
  page?: number;
  limit?: number;
  filtersState?: FilterState;
  order?: { field: string; sort: SortDirection };
  signal?: AbortSignal;
  advanceFilterParam?: { key: string; value: string };
}) {
  return async () => {
    dispatch(slice.actions.setLoading(true));
    try {
      const response = await SuppliersApi.fetchSupplierList({
        page,
        limit,
        filtersState,
        order,
        signal,
        advanceFilterParam,
      });
      dispatch(slice.actions.setSuppliers(response.data.data));
      dispatch(slice.actions.setRowCount(response.data.meta.total_items));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };
}

export function deleteSupplier(
  supplierId: number,
  setLoading: (succeed: boolean) => void,
  callback: (succeed: boolean) => void
) {
  return async () => {
    setLoading(true);
    try {
      await SuppliersApi.deleteSupplier(supplierId);
      callback(true);
    } catch (e) {
      callback(false);
    } finally {
      setLoading(false);
    }
  };
}