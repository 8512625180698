import axios from 'src/utils/axios';
import { FilterValue } from 'src/@types/filter';
import { SortDirection } from 'src/@types/booking-list';
import {
  getCreatedAtSearchParams,
  getUpdatedAtSearchParams,
} from 'src/sections/@dashboard/suppliers/data-grid/SuppliersDataGridCommon';

function suppliersAPI() {
  return {
    fetchSupplier: (supplierId: number) => axios.get(`/apis/companies/${supplierId}`),
    fetchSupplierList: ({
      page,
      limit,
      order,
      signal,
      filtersState,
      advanceFilterParam,
      headers,
      responseType,
      fields,
    }: {
      page?: number;
      limit?: number;
      order?: { field: string; sort: SortDirection };
      signal?: AbortSignal;
      filtersState?: {
        created_at?: { from?: string; to?: string };
        updated_at?: { from?: string; to?: string };
      };
      advanceFilterParam?: { key: string; value: string };
      headers?: {
        Accept: 'text/csv' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      };
      responseType?: 'blob';
      fields?: any[];
    }) =>
      axios.get('apis/list/suppliers', {
        signal,
        params: {
          ...(page && { page }),
          ...(limit && { limit }),
          ...(order && { [`order[${order.field}]`]: order.sort }),
          ...(advanceFilterParam && { [`${advanceFilterParam.key}`]: advanceFilterParam.value }),
          ...(filtersState?.created_at && {
            ...getCreatedAtSearchParams((filtersState?.created_at as FilterValue).value),
          }),
          ...(filtersState?.updated_at && {
            ...getUpdatedAtSearchParams((filtersState?.updated_at as FilterValue).value),
          }),
          ...(fields && { fields }),
        },
        ...(headers && { headers }),
        ...(responseType && { responseType }),
      }),
    updateSupplier: (supplierId: number, params?: { [key: string]: string | null }) => {
      const url = `/apis/companies/${supplierId}`;
      return axios.put(url, {
        ...params,
      });
    },
    deleteSupplier: (supplierId: number) => {
      const url = `/apis/company/delete_supplier/${supplierId}`;
      return axios.delete(url);
    },
  };
}

export default suppliersAPI();
