import { createSlice } from '@reduxjs/toolkit';
import { UnifiedInboxState } from 'src/@types/unified-inbox';
import { bookingReducers, getBooking } from './booking';
import {
  conversationReducers,
  getConversations,
  addToArchive,
  removeFromArchive,
  moveToTrash,
  removeFromTrash,
  getFolders,
  createOrUpdateFolder,
  deleteFolder,
  moveToFolder,
  undoFromFolder,
} from './conversation';
import {
  formReducers,
  getContacts,
  getBookingsWithContact,
  getBookings,
  getContactWithBooking,
  createUrlOfBooking,
  setEmails,
  removeEmail,
  setMobilePhones,
  removePhone,
  getOrganizationContacts,
  createNewConversation,
  provideContactAndCreateConversation,
  updateContact,
} from './form';
import { messageReducers } from './message';
import {
  getConversationMessages,
  getUserSenderEmails,
  getCcBccEmails,
  seenMessages,
  seenConversation,
  unseenConversation,
  sendEmail,
  sendSms,
  sendApiMessage,
  resendEmail,
  resendSms,
  resendApiMessage,
  getEmailTemplates,
  getRenderedEmailTemplate,
  convertAndSetMessageContents,
  saveDraft,
  deleteDraft,
  setDraftDetail,
} from './messageActions';
import { userReducers, getUser } from './user';

// ----------------------------------------------------------------------

export const initialState: UnifiedInboxState = {
  conversation: {
    filter: 'inbox',
    search: '',
    prevPageToLoad: 0,
    nextPageToLoad: 0,
    hasConnectionProblem: false,
    scrollButtonBehaviour: 'hidden',
    selectedConversation: {},
    hoveredConversation: {},
    isLoading: false,
    isLoadingMore: false,
    conversations: {},
    conversationsOrder: [],
    shouldScrollToConversation: false,
    shouldScrollToTop: false,
    totalUnseenMessagesCount: 0,
    unseenInboxMessagesCount: 0,
    folders: {
      list: [],
      defaultFolderId: null,
      firstInitial: true,
      selectedFolderId: null,
      folderPopup: {
        isLoading: false,
        isOpen: false,
        mode: 'add',
        hasExistFolderName: false,
        selectedFolder: null,
      },
    },
  },
  message: {
    isLoading: false,
    isOpen: false,
    messages: {},
    currentSentMessageId: null,
    drafts: {},
    senderEmails: {},
    messageType: '',
    outgoingMessageType: null,
    sendEmailLoading: false,
    checkSeen: false,
    showCc: false,
    showBcc: false,
    email: {
      subject: '',
      from: '',
      fromContact: null,
      to: null,
      cc: [],
      bcc: [],
      ccChips: [],
      bccChips: [],
      body: '',
      html: '',
      ccList: [],
      ccPage: 0,
      ccNotFound: false,
      bccList: [],
      bccPage: 0,
      bccNotFound: false,
    },
    smsTo: null,
    messageContent: '',
    htmlMessageContent: '',
    editorSelectedValue: '',
    templateForm: {
      formIsOpen: false,
      isLoading: false,
      templateList: [],
      selectedTemplate: null,
      selectedTemplateIsHtml: { original: false, changeable: false },
      selectedTemplateContentId: null,
      languages: null,
      variables: [],
    },
    insertLinkForm: {
      formIsOpen: false,
      textToDisplay: '',
      linkUrl: '',
    },
    hasInsertedLink: false,
    caretPosition: {
      startIndex: 0,
      endIndex: 0,
    },
    templateContent: null,
    templateIsLoading: false,
    isInsertingTemplate: false,
    isHtmlTemplate: false,
    toolbarIsOpen: false,
    defaultEmail: '',
    defaultPhone: '',
    defaultPanelHeight: 264,
    shouldScrollToLastMessage: false,
    attachFiles: {
      attachments: [],
      canSaveAttachmentsInDraft: false,
      errorPopup: {
        title: 'huge_files',
        open: false,
      }
    },
    showNewMessagesAlert: false,
    totalUnseenMessagesCount: 0,
  },
  user: {
    id: null,
    first_name: '',
    last_name: '',
    email: '',
    emails: {
      team_contact: null,
      personal_email: '',
      team_email: '',
    },
    organization_email: '',
    contact: null,
    brands: {},
  },
  booking: {
    isLoading: false,
    bookings: {},
    currentBookingId: 0,
  },
  form: {
    isOpen: false,
    formType: '',
    isJustEditingContact: false,
    contacts: [],
    selectedContact: null,
    contactsPage: 0,
    contactsPageIsLoading: false,
    contactNotFound: false,
    bookings: [],
    selectedBooking: null,
    bookingsPage: 0,
    bookingsPageIsLoading: false,
    bookingNotFound: false,
    independentValue: '',
    independentValueType: '',
    contactFormHasError: false,
    newAddedDetailType: '',
    contact: {
      id: null,
      firstName: '',
      middleName: '',
      lastName: '',
      phones: [],
      mobilePhones: [],
      emails: [],
    },
  },
};

export const slice = createSlice({
  name: 'unifiedInbox',
  initialState,
  reducers: {
    ...conversationReducers,
    ...messageReducers,
    ...userReducers,
    ...bookingReducers,
    ...formReducers,
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  changeBookingLoadingStatus,
  setBooking,
  setConversations,
  setConversationsOrder,
  nextPage,
  prevPage,
  setFirstPage,
  setLastPage,
  setHasConnectionProblem,
  setScrollButtonBehaviour,
  changeLoadingStatus,
  changeLoadingMoreStatus,
  selectConversation,
  deselectConversation,
  setOnHoverConversation,
  setConversationsFilter,
  clearConversationList,
  changeSeenHistoryLoadingStatus,
  changeLoadingMessagesStatus,
  reset,
  setConversationLastMessageSeenHistory,
  setConversationReadStatus,
  updateConversationDraftSummary,
  updateConversationList,
  addToArchiveAllConversation,
  removeConversationFromConversationsOrder,
  setSearch,
  setShouldScrollToConversation,
  setShouldScrollToTop,
  setContactOfSelectedConversation,
  setFormOpen,
  clearBookingForms,
  setNewConversationType,
  setIsJustEditingContact,
  setContacts,
  selectContact,
  clearContacts,
  nextContactsPage,
  setContactsPageIsLoadingStatus,
  setContactNotFound,
  setBookings,
  selectBooking,
  clearBookings,
  nextBookingsPage,
  setBookingsPageIsLoadingStatus,
  setBookingNotFound,
  setIndependentValueType,
  setIndependentValue,
  setContactFormHasError,
  setContact,
  resetContact,
  setContactEmails,
  setContactMobilePhones,
  setNewAddedDetailType,
  clearForms,
  setNewMessage,
  setNewMessageStatus,
  deleteMessage,
  setCheckSeen,
  changeMessageLoadingStatus,
  setEmailsForConversation,
  setDraftsForConversation,
  removeConversationDraft,
  setMessagesForConversation,
  setMessage,
  setWebsocketMessage,
  setMessageType,
  setOutgoingMessageType,
  setSendEmailLoading,
  setEmailSubject,
  setEmailBody,
  setEmailHtml,
  setEmailFrom,
  setEmailTo,
  setEmailCc,
  deleteCc,
  deleteBcc,
  setEmailBcc,
  setCcChips,
  setBccChips,
  setShowCc,
  setShowBcc,
  setCcNotFound,
  setBccNotFound,
  setEmailPanelHeight,
  setSmsTo,
  emptyEmail,
  setMessageContents,
  emptyMessageContents,
  setEditorSelectedValue,
  newEmail,
  newSms,
  newApiMessage,
  seenConversationMessages,
  unseenConversationMessages,
  setIsOpen,
  setTemplateFormIsOpen,
  setTemplatesList,
  setSelectedTemplate,
  setSelectedTemplateIsHtml,
  setSelectedTemplateContentId,
  setTemplateListIsLoadingStatus,
  setTemplateContent,
  setIsInsertingTemplate,
  setIsHtmlTemplate,
  clearForm,
  setCcList,
  nextCcPage,
  resetCcList,
  setBccList,
  nextBccPage,
  resetBccList,
  clearMessage,
  setDefaultEmail,
  setDefaultPhone,
  setShouldScrollToLastMessage,
  setTemplateIsLoading,
  setToolbarIsOpen,
  setInsertLinkFormIsOpen,
  setInsertLinkTextToDisplay,
  setInsertLinkLinkUrl,
  setHasInsertedLink,
  setCaretPosition,
  setCurrentSentMessageId,
  initializeState,
  setUser,
  setUserContact,
  setBrands,
  updateFolderPopup,
  setAttachment,
  updateAttachment,
  removeAttachment,
  setCanSaveAttachmentsInDraft,
  updateAttachFilesErrorPopup,
  setShowNewMessagesAlert,
  setTotalUnseenMessagesCount,
} = slice.actions;

// ----------------------------------------------------------------------

export {
  getBooking,
  getConversations,
  addToArchive,
  removeFromArchive,
  moveToTrash,
  removeFromTrash,
  getContacts,
  getBookingsWithContact,
  getBookings,
  getContactWithBooking,
  createUrlOfBooking,
  setEmails,
  removeEmail,
  setMobilePhones,
  removePhone,
  getOrganizationContacts,
  createNewConversation,
  provideContactAndCreateConversation,
  updateContact,
  getConversationMessages,
  getUserSenderEmails,
  getCcBccEmails,
  seenMessages,
  seenConversation,
  unseenConversation,
  sendEmail,
  sendSms,
  sendApiMessage,
  resendEmail,
  resendSms,
  resendApiMessage,
  getEmailTemplates,
  getRenderedEmailTemplate,
  convertAndSetMessageContents,
  saveDraft,
  deleteDraft,
  setDraftDetail,
  getUser,
  getFolders,
  createOrUpdateFolder,
  deleteFolder,
  moveToFolder,
  undoFromFolder,
};

// ----------------------------------------------------------------------
