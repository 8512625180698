import { dispatch } from '../store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RateRulesParams, RateRuleState } from 'src/@types/rate-rule';
import rateRuleAPI from 'src/api/rate-rule';

const initialState: RateRuleState = {
  loading: false,
  rowCount: 0,
  rateRules: [],
};

const slice = createSlice({
  name: 'rate',
  initialState,
  reducers: {
    reset: () => initialState,
    setLoading(state, action: PayloadAction<RateRuleState['loading']>) {
      state.loading = action.payload;
    },
    setRowCount(state, action: PayloadAction<number>) {
      state.rowCount = action.payload;
    },
    setRateRules(state, action: PayloadAction<RateRuleState['rateRules']>) {
      state.rateRules = action.payload;
    },
  },
});

// reducer
export default slice.reducer;
// actions
export const {
  reset,
} = slice.actions;

// ----------------------------------------------------------

export function getRateRules(params: RateRulesParams, signal?: AbortSignal) {
  return async () => {
    dispatch(slice.actions.setLoading(true));
    try {
      const { data: { data = [], meta } } = await rateRuleAPI.getRateRules(params, signal);
      dispatch(slice.actions.setRowCount(meta?.total_items ?? 0));
      dispatch(slice.actions.setRateRules(data));
    } catch (error) {
    }
    dispatch(slice.actions.setLoading(false));
  }
};
