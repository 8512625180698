import { utcToZonedTime } from 'date-fns-tz';
import { fDT } from 'src/utils/formatTime';
import { LeaveListView, LeavesTab, Filters, Leave } from 'src/@types/leaves';

export const hasLeaveCreateAccess = (user: any) => user?.acls.PeymansStaffBundleLeave.CREATE;
export const hasLeaveEditAccess = (user: any) => user?.acls.PeymansStaffBundleLeave.EDIT;
export const hasAdminModeAccess = (user: any) => user?.acls.zeevou_host_leave_access.EXECUTE;
export const hasStaffModeAccess = (user: any) => user?.acls.zeevou_staff_leave_access.EXECUTE;

export const getDefaultFiltersByViewMode = (leaveListViewValue: LeavesTab): Filters => {
  switch (leaveListViewValue) {
    case 'leaves':
      return { status: ['Pending', 'Accepted', 'Rejected', 'Cancel'] };
    case 'requests':
      return { status: ['Pending'] };
    default:
      return { status: [] };
  }
};

export const defaultLeaveListViews: LeaveListView[] = [
  {
    value: 'leaves',
    defaultFilters: getDefaultFiltersByViewMode('leaves'),
  },
  {
    value: 'requests',
    defaultFilters: getDefaultFiltersByViewMode('requests'),
  }
];

export const getDateTime = (date: string | Date, dateLocale: Locale, pattern: string) => (
  fDT({
    date: utcToZonedTime(typeof date === "string" ? new Date(date) : date, 'UTC'),
    pattern: pattern,
    options: { locale: dateLocale }
  })
);

export const getStaffFullName = (firstName: string | null, lastName: string | null) => (
  `${firstName ?? ''}${lastName ? (firstName ? ' ' + lastName : lastName) : ''}`
);

export const getLeaveStatusConfig = (status: string, user: any) => {
  switch (status) {
    case 'Pending':
      return {
        label: 'pending',
        color: hasAdminModeAccess(user) ? 'warning.dark' : 'text.secondary'
      };
    case 'Accepted':
      return {
        label: 'accepted',
        color: hasAdminModeAccess(user) ? 'text.secondary' : 'success.main'
      };
    case 'Rejected':
      return {
        label: 'rejected',
        color: hasAdminModeAccess(user) ? 'text.secondary' : 'error.main'
      };
    case 'Cancel':
      return {
        label: 'cancel',
        color: hasAdminModeAccess(user) ? 'text.secondary' : 'text.disabled'
      };
    default:
      return { label: status, color: 'primary.main' };
  }
};

export const leaveNormalizer = (obj: any): Leave => ({
  id: obj.id,
  staff: obj.staff ? {
    id: obj.staff.id,
    firstName: obj.staff.user.first_name ?? '',
    lastName: obj.staff.user.last_name ?? '',
    fullName: getStaffFullName(obj.staff.user.first_name, obj.staff.user.last_name),
    role: obj.staff.user.roles[0].label,
  } : null,
  duration: {
    from: obj.from,
    to: obj.to,
  },
  fullDay: obj.full_day,
  category: obj.reason,
  explanation: obj.description ?? null,
  rejectReason: obj.reject_reason ?? null,
  status: obj.status,
  createsAt: obj.created_at,
  updatesAt: obj.updated_at,
});