import { dispatch } from '../store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// api
import monthlyProfitReportAPI from 'src/api/monthly-profit-report';
// @types
import {
  Addon,
  Expense,
  HeaderInfo,
  HostViewPageState,
  Tax,
} from 'src/@types/monthly-profit-report';
// utils
import { addonNormalizer, expenseNormalizer, taxNormalizer } from 'src/utils/monthlyProfitReport';

const initialState: HostViewPageState = {
  loading: {
    isHeaderDataLoading: false,
    isFeesAndUpsellDataLoading: false,
    isPenaltyDataLoading: false,
    isRefundDataLoading: false,
    isExpenseDataLoading: false,
    isTaxDataLoading: false,
  },
  headerData: null,
  feesAndUpsellData: null,
  penaltyData: null,
  refundData: null,
  expenseData: null,
  taxData: null,
  drawers: {
    managementPackage: {
      open: false,
      packageId: null,
    },
  },
  dialogs: {
    addonDetailsDataGrid: {
      open: false,
    },
    expenseDetailsDataGrid: {
      open: false,
    },
  },
};

const slice = createSlice({
  name: 'hostMonthlyProfitReport',
  initialState,
  reducers: {
    setHeaderDataLoading(state, action: PayloadAction<boolean>) {
      state.loading.isHeaderDataLoading = action.payload;
    },
    setFeesAndUpsellDataLoading(state, action: PayloadAction<boolean>) {
      state.loading.isFeesAndUpsellDataLoading = action.payload;
    },
    setPenaltyDataLoading(state, action: PayloadAction<boolean>) {
      state.loading.isPenaltyDataLoading = action.payload;
    },
    setRefundDataLoading(state, action: PayloadAction<boolean>) {
      state.loading.isRefundDataLoading = action.payload;
    },
    setExpenseDataLoading(state, action: PayloadAction<boolean>) {
      state.loading.isExpenseDataLoading = action.payload;
    },
    setTaxDataLoading(state, action: PayloadAction<boolean>) {
      state.loading.isTaxDataLoading = action.payload;
    },
    setHeaderData(state, action: PayloadAction<{ data: HeaderInfo }>) {
      state.headerData = action.payload.data;
    },
    setVisibilityHostAction(state, action: PayloadAction<{ data: string }>) {
      if (state.headerData) state.headerData.visibility = action.payload.data;
    },
    setMPRStatusHostAction(state, action: PayloadAction<{ data: string }>) {
      if (state.headerData) state.headerData.mprStatus = action.payload.data;
    },
    setFeesAndUpsellData(state, action: PayloadAction<{ data: Array<Addon> }>) {
      state.feesAndUpsellData = action.payload.data;
    },
    setPenaltyData(state, action: PayloadAction<{ data: Array<Addon> }>) {
      state.penaltyData = action.payload.data;
    },
    setRefundData(state, action: PayloadAction<{ data: Array<Addon> }>) {
      state.refundData = action.payload.data;
    },
    setExpenseData(state, action: PayloadAction<{ data: Array<Expense> }>) {
      state.expenseData = action.payload.data;
    },
    setTaxData(state, action: PayloadAction<{ data: Array<Tax> }>) {
      state.taxData = action.payload.data;
    },
    setManagementPackageDrawer(
      state,
      action: PayloadAction<{ open: boolean; packageId: number | null }>
    ) {
      state.drawers.managementPackage = {
        open: action.payload.open,
        packageId: action.payload.packageId,
      };
    },
    setAddonDetailsDataGridDialog(
      state,
      action: PayloadAction<{
        open: boolean;
        type?: string;
        title?: string;
        costElementId?: number;
      }>
    ) {
      state.dialogs.addonDetailsDataGrid = {
        open: action.payload.open,
        type: action.payload.type,
        title: action.payload.title,
        costElementId: action.payload.costElementId,
      };
    },
    setExpenseDetailsDataGridDialog(
      state,
      action: PayloadAction<{
        open: boolean;
        title?: string;
        costElementId?: number;
        addonId?: number;
      }>
    ) {
      state.dialogs.expenseDetailsDataGrid = {
        open: action.payload.open,
        title: action.payload.title,
        costElementId: action.payload.costElementId,
        addonId: action.payload.addonId,
      };
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setVisibilityHostAction,
  setMPRStatusHostAction,
  setManagementPackageDrawer,
  setAddonDetailsDataGridDialog,
  setExpenseDetailsDataGridDialog,
} = slice.actions;

export function getHeaderInfoData(mprId: number) {
  return async () => {
    dispatch(slice.actions.setHeaderDataLoading(true));
    try {
      const response = await monthlyProfitReportAPI.fetchHostHeaderInfo(mprId);
      dispatch(slice.actions.setHeaderData({ data: response.data ? response.data : [] }));
    } catch (error) {}
    dispatch(slice.actions.setHeaderDataLoading(false));
  };
}

export function getFeesAndUpsellCardData(mprId: number) {
  return async () => {
    dispatch(slice.actions.setFeesAndUpsellDataLoading(true));
    try {
      const response = await monthlyProfitReportAPI.fetchAddonData(mprId, 'Service');
      dispatch(
        slice.actions.setFeesAndUpsellData({
          data: response.data ? response.data.map((item) => addonNormalizer(item)) : [],
        })
      );
    } catch (error) {}
    dispatch(slice.actions.setFeesAndUpsellDataLoading(false));
  };
}

export function getPenaltyCardData(mprId: number) {
  return async () => {
    dispatch(slice.actions.setPenaltyDataLoading(true));
    try {
      const response = await monthlyProfitReportAPI.fetchAddonData(mprId, 'Penalty');
      dispatch(
        slice.actions.setPenaltyData({
          data: response.data ? response.data.map((item) => addonNormalizer(item)) : [],
        })
      );
    } catch (error) {}
    dispatch(slice.actions.setPenaltyDataLoading(false));
  };
}

export function getRefundCardData(mprId: number) {
  return async () => {
    dispatch(slice.actions.setRefundDataLoading(true));
    try {
      const response = await monthlyProfitReportAPI.fetchAddonData(mprId, 'Refund');
      dispatch(
        slice.actions.setRefundData({
          data: response.data ? response.data.map((item) => addonNormalizer(item)) : [],
        })
      );
    } catch (error) {}
    dispatch(slice.actions.setRefundDataLoading(false));
  };
}

export function getExpensesCardData(mprId: number) {
  return async () => {
    dispatch(slice.actions.setExpenseDataLoading(true));
    try {
      const response = await monthlyProfitReportAPI.fetchExpenses(mprId);
      dispatch(
        slice.actions.setExpenseData({
          data: response.data ? response.data.map((item) => expenseNormalizer(item)) : [],
        })
      );
    } catch (error) {}
    dispatch(slice.actions.setExpenseDataLoading(false));
  };
}

export function getTaxCardData(mprId: number) {
  return async () => {
    dispatch(slice.actions.setTaxDataLoading(true));
    try {
      const response = await monthlyProfitReportAPI.fetchInvestorTaxes(mprId);
      dispatch(
        slice.actions.setTaxData({
          data: response.data ? response.data.map((item) => taxNormalizer(item)) : [],
        })
      );
    } catch (error) {}
    dispatch(slice.actions.setTaxDataLoading(false));
  };
}
