import { GridColTypeDef, GridSortModel } from '@mui/x-data-grid-pro';
import { FilterState } from 'src/@types/filter';

export type SupplierInfoKey = 'name' | 'email' | 'phone' | 'website' | 'description';

export type SuppliersState = {
  loading: boolean;
  refresh: boolean;
  supplier: {
    view: SupplierView | null;
    list: SupplierList[];
    rowCount: number;
    gridConfigs: {
      page: number;
      limit: number;
      sortModel: GridSortModel;
      filtersState: FilterState;
      advanceFilterState: { key: string; value: string } | undefined;
    } | null;
  };
};

export type SupplierView = {
  id: number;
  name: string;
  description: string | null;
  email: string | null;
  phone: string | null;
  website: string | null;
  created_at: string;
  updated_at: string;
};

export type SupplierList = {
  id: number;
  name: string;
  description: string | null;
  created_at: string;
  updated_at: string;
};

export interface SupplierDataGridColumn extends GridColTypeDef {
  field: ColumnField;
}

export type ColumnField = 'id' | 'name' | 'additionalInfo' | 'createdAt' | 'updatedAt' | 'action';

export type SupplierGridRow = {
  id: number;
  name: string;
  additionalInfo: string;
  createdAt: Date;
  updatedAt: Date;
};

export type Field = {
  value: string;
  errorMessage: string;
};

export const ALL_SUPPLIER_COLUMN_FIELDS = [
  'id',
  'name',
  'additionalInfo',
  'createdAt',
  'updatedAt',
];
