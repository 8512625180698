import { Stack, Skeleton } from '@mui/material';

const SkeletonBreadcrumb = ({ hasChangeHistory = false }) => (
  <Stack direction="row" justifyContent="space-between" alignItems="center">
    <Stack>
      <Skeleton height={30} width={120} sx={{ borderRadius: 1 }} />
      <Stack direction="row" gap={4.5}>
        <Skeleton height={24} width={60} sx={{ borderRadius: 1 }} />
        <Skeleton height={24} width={40} sx={{ borderRadius: 1 }} />
        <Skeleton height={24} width={120} sx={{ borderRadius: 1 }} />
      </Stack>
      {hasChangeHistory && <Skeleton height={24} width={340} sx={{ borderRadius: 1 }} /> }
    </Stack>
    <Stack flexGrow={1} />
  </Stack>
);

export default SkeletonBreadcrumb;