// @mui
import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';
// routes
import { PATH_ZEEVOU } from '../../routes/paths';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
// sections
import { LoginForm } from '../../sections/auth/login';
// icon
import useLocales from 'src/hooks/useLocales';
import InfoSection from 'src/sections/auth/InfoSection';
import AuthFormHeader from 'src/sections/auth/AuthFormHeader';
import ContentStyle from 'src/sections/auth/ContentStyle';
import AuthFormFooter from 'src/sections/auth/AuthFormFooter';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { translate } = useLocales(['common', 'auth']);
  const mdUp = useResponsive('up', 'md');

  const footers = [
    {
      title: translate('new_to_zeevou'),
      links: [
        {
          label: translate('sign_up'),
          link: PATH_ZEEVOU.signup,
          isOuter: true,
        },
        {
          label: translate('get_a_free_demo'),
          link: PATH_ZEEVOU.freeDemo,
          isOuter: true,
        },
      ]
    },
  ];

  return (
    <Page title={translate('login_page_title')}>
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <AuthFormHeader
              showLogo={!mdUp}
              title={translate('welcome_back')}
              subtitle={translate('login_page_subtitle')}
            />

            <LoginForm />

            {!mdUp && <AuthFormFooter footers={footers} />}
          </ContentStyle>
        </Container>
        {mdUp && <InfoSection page="login" />}
      </RootStyle>
    </Page>
  );
}
