// @types
import {
  MonthlyProfitReport,
  ProfitSummary,
  ProfitSummaryItem,
  Addon,
  AddonRow,
  Expense,
  ExpenseRow,
  Tax,
  TaxRow,
} from 'src/@types/monthly-profit-report';

import { fDT } from './formatTime';
import { utcToZonedTime } from 'date-fns-tz';

export enum VisibilityEnum {
  VISIBLE_TO_OWNER = 'Visible to Owner',
  INVISIBLE_TO_OWNER = 'Invisible to Owner',
}

export enum MPRStatusEnum {
  DRAFT_STATUS = 'Draft',
  FINALISED_STATUS = 'Finalised',
}

export enum OwnerMPRVisibilityEnum {
  FINALISED_MPRS_ONLY = 'Finalised MPRs Only',
  ALL_MPRS = 'All MPRs',
}

export const generateYearsBetween = (startYear = new Date().getFullYear(), endYear: number) =>
  Array(endYear - startYear + 1)
    .fill('')
    .map((_, index) => startYear + index);

export const MONTH_LIST = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const generateMPRExportFileName = (investorName, year, monthNumber, month, currentLang) =>
  `${investorName ? investorName + '_' : ''}${year}_${
    monthNumber ? monthNumber : convertMonthNameToMonthNumber(month)
  }_${fDT({
    date: utcToZonedTime(new Date(), 'UTC'),
    options: { locale: currentLang.dateLocale },
    pattern: 'HH:mm:ss',
  })}_MPR`;

export const convertMonthNumberToMonthName = (monthNum: number) => MONTH_LIST[--monthNum];

export const convertMonthNameToMonthNumber = (monthName: string) => {
  let index = MONTH_LIST.findIndex((item) => item.includes(monthName.toLowerCase()));
  return ++index;
};

export const monthlyProfitReportNormalizer = (obj): MonthlyProfitReport => ({
  id: obj.id,
  month: obj.month,
  year: obj.year,
  deal: obj.deal ? obj.deal : null,
  landlord: obj.deal?.landlord ? obj.deal.landlord : null,
  deal_template: obj.deal?.deal_template ? obj.deal.deal_template : null,
  total_host_income_value: obj.total_host_income_value,
  total_host_expense_value: obj.total_host_expense_value,
  host_income_share: obj.hostIncomeShare,
  host_expense_share: obj.hostExpenseShare,
  host_profit_value: obj.hostProfitValue,
  total_investor_income_value: obj.total_investor_income_value,
  total_investor_expense_value: obj.total_investor_expense_value,
  investor_income_share: obj.investorIncomeShare,
  investor_expense_share: obj.investorExpenseShare,
  investor_profit_value: obj.investorProfitValue,
  total_tax: obj.total_tax,
  currency: obj.total_host_income_currency,
  owner_visibility: obj.visibility,
  ownerMPRVisibility: obj.ownerMPRVisibility,
  investorId: obj.deal?.landlord ? obj.deal?.landlord.id : null,
  status: obj.status,
});

export function addonNormalizer(item): Addon {
  return {
    orderLineID: item.OrderLineID,
    costElementName: item.CostElementName,
    costElementId: item.CostElementID,
    addonName: item.AddonName,
    bookingNumber: item.BookingNumber,
    bookingID: item.BookingID,
    currency: item.Currency,
    currencySymbol: item.CurrencySymbol,
    salesValue: Number(item.SalesValue),
    purchaseValue: Number(item.PurchaseValue),
    hostIncomeValue: Number(item.HostIncomeValue),
    hostExpenseValue: Number(item.HostExpenseValue),
    investorIncomeValue: Number(item.InvestorIncomeValue),
    investorExpenseValue: Number(item.InvestorExpenseValue),
    nightsInMonth: item.NightsInMonth,
    numberOfNights: item.NumberOfNights,
    propertyName: item.PropertyName,
    unitTypeName: item.unitTypeName,
    unitName: item.unitName,
  };
}

export function addonInvestorNormalizer(item): AddonRow {
  return {
    type: item.CostElementName,
    currencySymbol: item.CurrencySymbol,
    hostShare: {
      income: '',
      expense: Number(item.HostExpenseValue),
    },
    investorShare: {
      income: Number(item.InvestorIncomeValue),
      expense: Number(item.InvestorExpenseValue),
    },
    hostShareProfit: null,
    investorShareProfit: Number(item.InvestorIncomeValue) - Number(item.InvestorExpenseValue), //
    costElementId: item.CostElementID,
    amount: Number(item.HostExpenseValue) + Number(item.InvestorExpenseValue) ?? null,
  };
}

export function generateProfitSummaryLabel(label: string, type: 'income' | 'expense' | 'profit') {
  switch (label) {
    case 'add_ons':
      if (type === 'income') return 'fees_upsell_income';
      return 'fees_upsell_expense';
    case 'penalties':
      if (type === 'income') return 'penalties_income';
      return 'penalties_expense';
    case 'accommodation_income':
    case 'refunds':
    case 'expenses':
    case 'fixed_rent':
    case 'fixed_management_fee':
    case 'total_tax':
    default:
      return label;
  }
}

export function profitSummaryItemNormalizer(
  profitSummary: Array<ProfitSummary>
): Array<ProfitSummaryItem> {
  const profitSummaryList: Array<{
    label: string;
    amount: number;
    type: 'income' | 'expense' | 'profit';
  }> = [];
  profitSummary.map((item) => {
    if (item.incomeAmount)
      profitSummaryList.push({
        label: generateProfitSummaryLabel(
          item.rowTitle
            .toLowerCase()
            .split(/[\s-]+/)
            .join('_'),
          'income'
        ),
        amount: item.incomeAmount,
        type: 'income',
      });
    if (item.expenseAmount)
      profitSummaryList.push({
        label: generateProfitSummaryLabel(
          item.rowTitle
            .toLowerCase()
            .split(/[\s-]+/)
            .join('_'),
          'expense'
        ),
        amount: item.expenseAmount,
        type: 'expense',
      });
    if (item.profitAmount && item.rowTitle === 'Total Tax')
      profitSummaryList.push({
        label: generateProfitSummaryLabel(
          item.rowTitle
            .toLowerCase()
            .split(/[\s-]+/)
            .join('_'),
          'profit'
        ),
        amount: item.profitAmount,
        type: 'profit',
      });
  });
  return profitSummaryList;
}

export function generateAddonTableRow(addon: Addon): AddonRow {
  return {
    // orderLineID: addon.orderLineID,
    type: addon.costElementName,
    currencySymbol: addon.currencySymbol,
    hostShare: {
      income: addon.hostIncomeValue,
      expense: addon.hostExpenseValue,
    },
    investorShare: {
      income: addon.investorIncomeValue,
      expense: addon.investorExpenseValue,
    },
    hostShareProfit: addon.hostIncomeValue - addon.hostExpenseValue,
    investorShareProfit: addon.investorIncomeValue - addon.investorExpenseValue,
    amount: addon.salesValue,
    costElementId: addon.costElementId,
  };
}

export const expenseNormalizer = (item): Expense => ({
  expenseLineId: item.ExpenseLineID,
  addonId: item.AddonID,
  addonName: item.AddonName,
  costElementId: item.costElementID,
  costElementName: item.costElementName,
  currency: item.Currency,
  currencySymbol: item.CurrencySymbol,
  expenseAmountValue: Number(item.ExpenseAmountValue),
  hostExpenseValue: Number(item.HostExpenseValue),
  investorExpenseValue: Number(item.InvestorExpenseValue),
  propertyUnitName: item.PropertyUnitName,
  nightsInMonth: Number(item.NightsInMonth),
  numberOfNights: item.NumberOfNights,
  totalPriceValue: Number(item.TotalPriceValue),
  expenseDescription: item.ExpenseDescription,
});

export function generateExpenseTableRow(expense: Expense): ExpenseRow {
  return {
    addonId: expense.addonId,
    costElementId: expense.costElementId,
    expenseLineId: expense.expenseLineId,
    type: expense.addonName,
    currencySymbol: expense.currencySymbol,
    amount: expense.expenseAmountValue,
    hostShare: expense.hostExpenseValue,
    investorShare: expense.investorExpenseValue,
  };
}

export function taxNormalizer(item): Tax {
  return {
    nightlyDetailID: item.NightlyDetailID,
    taxRateName: item.TaxRateName,
    taxItem: item.TaxItem,
    currency: item.Currency,
    currencySymbol: item.CurrencySymbol,
    hostIncomeTaxAmount: Number(item.HostIncomeTaxAmount),
    hostExpenseTaxAmount: Number(item.HostExpenseTaxAmount),
    investorIncomeTaxAmount: Number(item.InvestorIncomeTaxAmount),
    investorExpenseTaxAmount: Number(item.InvestorExpenseTaxAmount),
  };
}

export function generateTaxTableRow(tax: Tax, role: string): TaxRow {
  return {
    nightlyDetailID: tax.nightlyDetailID,
    taxRate: tax.taxRateName,
    taxItem: tax.taxItem,
    currencySymbol: tax.currencySymbol,
    hostShare:
      role === 'host'
        ? {
            income: tax.hostIncomeTaxAmount,
            expense: tax.hostExpenseTaxAmount,
          }
        : null,
    investorShare: {
      income: tax.investorIncomeTaxAmount,
      expense: tax.investorExpenseTaxAmount,
    },
    hostShareProfit: role === 'host' ? tax.hostIncomeTaxAmount - tax.hostExpenseTaxAmount : null,
    investorShareProfit: tax.investorIncomeTaxAmount - tax.investorExpenseTaxAmount,
  };
}
