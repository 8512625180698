import { htmlToText } from 'html-to-text';

export const convertHtmlToText = (html: string, options?: any) => {
  const content = html
    ?.replace('<!--', ' ')
    ?.replace('-->', ' ')
    ?.replace(/<div><br><\/div>/g, '<br>')
    ?.trim();
  return htmlToText(content, {
    tags: {
      img: {
        format: 'skip',
      },
      a: {
        options: { hideLinkHrefIfSameAsText: true },
      },
      ...options?.tags,
    },
    wordwrap: false,
    ...options,
  });
};

export const checkHtmlContentIsEmpty = (content) => htmlToText(content).replace(/\n/g, '').length === 0;
