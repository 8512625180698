// utils
import axios from 'src/utils/axios';
// @types
import { FilterValue } from 'src/@types/filter';
import { SortDirection } from 'src/@types/booking-list';

function InvestorApi() {
  return {
    fetchInvestor: (investorId: number) => axios.get(`/apis/investor/get_investor/${investorId}`),
    getInvestorRoles: () => axios.get(`/apis/investor/get_roles`),
    fetchInvestorList: ({
      page,
      limit,
      filtersState,
      order,
      signal,
      advanceFilterParam,
    }: {
      page: number;
      limit: number;
      filtersState: any;
      order?: {
        field: string;
        sort: SortDirection;
      };
      signal?: AbortSignal;
      advanceFilterParam?: {
        key: string;
        value: string;
      };
    }) => {
      const url = '/apis/list/investor';
      return axios.get(url, {
        signal,
        params: {
          page,
          limit,
          ...(order && { [`order[${order.field}]`]: order.sort }),
          ...(advanceFilterParam && { [`${advanceFilterParam.key}`]: advanceFilterParam.value }),
        },
      });
    },
    allInvestorList: (name: string, page: number) =>
      axios.get('/apis/list/investor', {
        params: { limit: 50, page: page + 1, investorName: name, 'order[name]': 'asc' },
      }),
    deleteInvestor: (investorId: number) =>
      axios.delete(`/apis/investor/delete_investor/${investorId}`),
    createInvestor: (body: {
      first_name: string;
      last_name: string;
      email: string | null;
      create_user: boolean;
      access_type: string | null;
      password: string | null;
      is_multiple_listing: boolean;
      listings: any | null;
      units: any | null;
    }) => axios.post(`/apis/investor/create_investor`, body),
    editInvestorAction: (
      investorId: number,
      body: {
        first_name?: string | null;
        last_name?: string | null;
        email?: string | null;
        phone?: string | null;
        access_type?: number | null;
        title?: string | null;
        gender?: string | null;
        birthday?: Date | string | null;
        ltd_company?: string | null;
        vat_register_number?: string | null;
      }
    ) => axios.put(`/apis/investor/edit_investor/${investorId}`, body),
    addAndEditAddress: (
      investorId: number,
      body: {
        address: string | null;
        city_name: string | null;
        country_code: string | null;
        postal_code: string | null;
        region_combined_code: string | null;
        address_id: number;
        latitude: number | null;
        longitude: number | null;
      }
    ) => axios.post(`/apis/investor/add_edit_address/${investorId}`, body),
    addInternalNote: (
      investor_id: number,
      body: {
        text: string;
      }
    ) => axios.post(`/apis/investor/add_internal_note/${investor_id}`, body),
    addAndEditBankAccountInfo: (
      investor_id: number,
      body: {
        bank_account_information: string | null;
      }
    ) => axios.post(`/apis/investor/add_edit_bank_account_info/${investor_id}`, body),
    deleteInvestorAction: (investor_id: number) =>
      axios.delete(`/apis/investor/delete_investor/${investor_id}`),
    enableDisableUserAction: (user_id: number, action: string) => {
      const url = `/apis/user/enable_disable_user/${user_id}/${action}`;
      return axios.post(url);
    },
    editListings: (body: { id: number; units: any }) =>
      axios.post(`/apis/investor/edit_listings`, body),
    createUserAction: (body: {
      id: number;
      email: string;
      password: string;
      access_type: string | number;
    }) => axios.post(`/apis/investor/create_user`, body),
    getIntegrations: () => axios.get(`/apis/investor/get_integrations`),
    getInvestorProperties: () =>
      axios.get('/apis/investor/properties', {
        params: {
          page: 1,
          limit: 500,
          is_active: true,
        },
      }),
    getInvestorUnitsWithPropertyFilter: (propertyID: number | string) => {
      const url = '/apis/investor/units';
      return axios.get(url, {
        params: {
          ...{ [`unit_type.property`]: propertyID },
        },
      });
    },
    // 1 Returns the available units.
    // 0 Returns all units.
    fetchInvestorUnitTree: (owner_id: null | string | number) =>
      axios.get(`/apis/listing/investor/tree/unit`, {
        params: {
          available_units: 1,
          owner_id: owner_id,
        },
      }),
    getProfitType: () => axios.get(`/apis/package/get_profit_type`),
    getExpenseType: () => axios.get(`/apis/package/get_expense_type`),
    getContractUnit: (owner_id: number, start: any, end: any) =>
      axios.get(`/apis/contract/get_contract_units`, {
        params: {
          owner_id: owner_id,
          start: start,
          end: end,
        },
      }),
    managementPackagesList: () =>
      axios.get(`/apis/list/managementPackages`, {
        params: {
          isManagementPackage: true,
        },
      }),
    createContract: (body: {
      contract_type: string;
      start: Date | string | number | null;
      end: Date | string | number | null;
      owner: number | string;
      units: any;
      management_package: number | undefined;
      income_xero_tax: number | string | null;
      expense_xero_tax: number | string | null;
      contract_title: string;
      is_custom_bank_account: boolean;
      is_custom_gateway_payment: boolean;
      is_custom_gateway_deposit: boolean;
      bank_account_info: string | null;
      fixed_rent: number | null;
      currency: string;
      profit_distribution: any | null;
      expense_distribution: any | null;
    }) => axios.post(`/apis/contract/create_contract`, body),
    createPackage: (body: {
      advanced: boolean;
      management_fee_income_formula: string;
      management_per_booking_percent: number | null;
      management_item: string;
      management_exceptions: any;
      fixed_management_fee: number | null;
      currency: string;
      profit_distribution: any | null;
      expense_distribution: any | null;
      package_title: string;
    }) => axios.post(`/apis/package/create_package`, body),
    clonePackage: (
      package_id: number,
      body: {
        advanced: boolean;
        management_fee_income_formula: string;
        management_per_booking_percent: number | null;
        management_item: string;
        management_exceptions: any;
        fixed_management_fee: number | null;
        currency: string;
        profit_distribution: any | null;
        expense_distribution: any | null;
        package_title: string;
      }
    ) => axios.post(`/apis/package/clone_management_package/${package_id}`, body),
    getManagementItems: () => axios.get(`/apis/package/get_management_item`),
    isFormulaValid: (formula: string) =>
      axios.get(`/apis/package/is_formula_valid`, {
        params: {
          formula: formula,
        },
      }),
    getFormulaCostElements: () => axios.get(`/apis/package/get_formula_cost_elements`),
    fetchInvestorDeal: (dealId: number) => axios.get(`/apis/deals/${dealId}`),
    updateInvestorDeal: ({
      dealId,
      title,
      description,
      incomeTaxSchema,
      expenseTaxSchema,
      isCustomBankAccount,
      isCustomeGatewayDeposit,
      isCustomeGatewayPayment,
      bankAccountInformation,
    }: {
      dealId: number;
      title?: string;
      description?: string;
      incomeTaxSchema?: number;
      expenseTaxSchema?: number;
      isCustomBankAccount?: boolean;
      isCustomeGatewayDeposit?: boolean;
      isCustomeGatewayPayment?: boolean;
      bankAccountInformation?: string;
    }) => {
      const url = `/apis/deals/${dealId}`;
      return axios.put(url, {
        title,
        description,
        incomeTaxSchema,
        expenseTaxSchema,
        isCustomBankAccount,
        isCustomeGatewayDeposit,
        isCustomeGatewayPayment,
        bankAccountInformation,
      });
    },
    updateUnitsManagementPackageDeal: (data: {
      dealId: number;
      units: string;
      packageId?: number;
      currency?: string;
      profit_distribution?: any[];
      expense_distribution?: any[];
      fixedRentFee?: number;
    }) =>
      axios.put(`/apis/contract/edit_contract/${data.dealId}`, {
        units: data.units,
        ...(data.packageId && { management_package: data.packageId }),
        ...(data.profit_distribution && { profit_distribution: data.profit_distribution }),
        ...(data.expense_distribution && { expense_distribution: data.expense_distribution }),
        ...(data.fixedRentFee && { fixed_rent: data.fixedRentFee }),
        ...(data.currency && { currency: data.currency }),
      }),
    fetchInvestorDealMonthlyProfitReportList: ({
      dealId,
      page,
      limit,
      filtersState,
      order,
      signal,
      advanceFilterParam,
    }: {
      dealId: number;
      page: number;
      limit: number;
      filtersState: {
        month?: FilterValue;
        year?: FilterValue;
      };
      order?: { field: string; sort: SortDirection };
      signal?: AbortSignal;
      advanceFilterParam?: { key: string; value: string };
    }) => {
      const url = `/apis/monthly_booking_income_reports?deal.id=${dealId}`;
      return axios.get(url, {
        signal,
        params: {
          page,
          limit,
          ...(filtersState.month && { ['month']: filtersState.month.value }),
          ...(filtersState.year && { ['year']: filtersState.year.value }),
          ...(advanceFilterParam && { [`${advanceFilterParam.key}`]: advanceFilterParam.value }),
        },
      });
    },
    recalculateInvestorDealMonthlyProfitReport: ({
      contractId,
      month,
      year,
      channelCommissions,
      paymentFees,
      orderLines,
      expenses,
    }: {
      contractId: number;
      month: number;
      year: number;
      channelCommissions?: boolean;
      paymentFees?: boolean;
      orderLines?: boolean;
      expenses?: boolean;
    }) =>
      axios.post(`/apis/monthly_profit_report/recalculate`, {
        contract_id: contractId,
        month,
        year,
        ...(channelCommissions ? { channelCommissions: true } : {}),
        ...(paymentFees ? { paymentFees: true } : {}),
        ...(orderLines ? { orderLines: true } : {}),
        ...(expenses ? { expenses: true } : {}),
      }),
    fetchInvestorDealMonthlyProfitReportQueueList: (dealId) => {
      const url = `/apis/financial_message_queues?messageType=Calculate MPR&deal.id=${dealId}`;
      return axios.get(url);
    },
    getOwnerMPRVisibility: () => axios.get(`/apis/investor/get_owner_mpr_visibility`),
    editMPRVisibility: (investor_id: number, visibility: string) =>
      axios.put(`/apis/investor/edit_mpr_visibility/${investor_id}`, { visibility }),
    extendInvestorDeal: (dealId, extendDate) =>
      axios.post(`apis/deal/extend`, {
        deal_id: dealId,
        extendToDate: extendDate,
      }),
    shortenInvestorDeal: (dealId: number, endDate: string) =>
      axios.post(`/apis/deal/shorten`, {
        deal_id: dealId,
        newToDate: endDate,
      }),
    getInvestorDealConflicts: (
      dealId: number,
      startDate: Date | string | number,
      endDate: Date | string | number
    ) =>
      axios.get(`apis/deal/get_conflict_deals`, {
        params: {
          deal_id: dealId,
          start_date: startDate,
          end_date: endDate,
        },
      }),
      getLandLords: () => axios.get(`/apis/list/investor`),
    
  };
}

export default InvestorApi();
