import React from 'react';
// @mui
import { Button, ButtonProps } from '@mui/material';
import { alpha, styled } from "@mui/material/styles";
// components
import SvgIconStyle from "../SvgIconStyle";

const StyledButton = styled(Button)<{ isSelected?: boolean }>(({ theme, isSelected }) => ({
  height: 'unset',
  minWidth: 'unset',
  width: 'fit-content',
  padding: theme.spacing(1),
  ...(isSelected && { backgroundColor: alpha(theme.palette.primary.lighter, 0.8) }),
  '& .MuiButton-endIcon': {
    margin: 0
  }
}));

interface Props extends ButtonProps {
  isSelected?: boolean;
}

const ExportButton = ({
                        isSelected,
                        ...other
                      }: Props) => (
    <StyledButton
      variant='outlined'
      endIcon={<SvgIconStyle
        src={'/icons/ic_download_24dp.svg'}
        sx={{ width: 16, height: 16, color: 'primary.main' }}
      />}
      isSelected={isSelected}
      {...other}
    />
  )

export default ExportButton;
