import { createSlice } from '@reduxjs/toolkit';
import { CreateBookingDialog } from 'src/@types/booking';

// ----------------------------------------------------------------------

const initialState: CreateBookingDialog = {
  open: false,
  title: '',
  destination: null,
  defaultFilter: null,
  arrival: null,
  departure: null,
  adultGuests: 2,
  childGuests: 0,
  additionalURLParams: {},
};

const slice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    // OPEN
    openDialog(state) {
      state.open = true;
    },

    // CLOSE
    closeDialog(state) {
      state.open = false;
      state.title = '';
      state.destination = null;
      state.defaultFilter = null;
      state.arrival = null;
      state.departure = null;
      state.adultGuests = 2;
      state.childGuests = 0;
      state.additionalURLParams = {};
    },

    // SET TITLE
    setDialogTitle(state, action) {
      state.title = action.payload;
    },

    // SET DESTINATION
    setDestination(state, action) {
      state.destination = action.payload;
    },

    // SET DEFAULT FILTER
    setDefaultFilter(state, action) {
      state.defaultFilter = action.payload;
    },

    // SET ARRIVAL
    setArrival(state, action) {
      state.arrival = action.payload;
    },

    // SET DEPARTURE
    setDeparture(state, action) {
      state.departure = action.payload;
    },

    // SET ADULT GUESTS
    setAdultGuests(state, action) {
      state.adultGuests = action.payload;
    },

    // SET CHILD GUESTS
    setChildGuests(state, action) {
      state.childGuests = action.payload;
    },

    // SET ADDITIONAL URL PARAMS
    setAdditionalURLParams(state, action) {
      state.additionalURLParams = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  openDialog,
  closeDialog,
  setDialogTitle,
  setDestination,
  setDefaultFilter,
  setArrival,
  setDeparture,
  setAdultGuests,
  setChildGuests,
  setAdditionalURLParams,
} = slice.actions;
