// @mui
import { Skeleton, Stack } from '@mui/material';

// ----------------------------------------------------------------------
type Props = {
  items?: number;
}

export default function SkeletonMenuItem({ items = 5 }: Props) {
  return (
    <Stack spacing={1} sx={{ p: 1 }}>
      {Array.from(Array(items).keys()).map((key) => (
        <Skeleton key={key} variant="rectangular" sx={{ width: 1, height: 65, borderRadius: 1 }} />
      ))}
    </Stack>
  );
}
