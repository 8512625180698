import { Rating, Typography, Stack, RatingProps } from '@mui/material';
import { fNumber } from 'src/utils/formatNumber';

const ReviewRating = ({
  rate,
  hideRateNumber = false,
  ratingProps,
}: {
  rate: number;
  hideRateNumber?: boolean;
  ratingProps?: RatingProps;
}) => {
  return (
    <Stack direction="row" alignItems="center" gap={0.5}>
      <Rating
        name="rating-read"
        value={rate}
        precision={0.1}
        size="small"
        readOnly
        {...ratingProps}
      />
      {!hideRateNumber && (
        <Typography variant="caption" color="text.secondary">
          {`(${fNumber(rate, '0.0')})`}
        </Typography>
      )}
    </Stack>
  );
};

export default ReviewRating;
