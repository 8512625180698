import { File } from 'src/@types/common';
import { CheckInReadyStatus, CheckInType } from 'src/@types/booking';
import { GridColTypeDef, GridColumnVisibilityModel, GridSortModel } from '@mui/x-data-grid-pro';

export type BookingStatus =
  | 'Booked'
  | 'Cancelled'
  | 'Checked in'
  | 'Checked out'
  | 'No Show'
  | 'Scheduled for Cancellation';
export type FinalizationStatus =
  | 'Not Finalisable'
  | 'Awaiting Finalisation'
  | 'Marked as Finalised'
  | 'Finalised';
export type PaymentStatus =
  | 'Paid'
  | 'Over paid'
  | 'Unpaid'
  | 'Partial'
  | 'Credit'
  | 'Not applicable';
export type SecurityDepositStatus =
  | 'Not Authorised'
  | 'Pre-authorised'
  | 'Authorised'
  | 'Charged'
  | 'Partially Charged'
  | 'Released'
  | 'Charged & Released'
  | 'Partially Authorised'
  | 'Guaranteed by Channel'
  | 'Not Applicable'
  | 'Awaiting Authorisation'
  | 'Expired';
export type BookingListViewValue =
  | 'all_bookings'
  | 'arrivals'
  | 'stay-overs'
  | 'departures'
  | 'awaiting_finalisation';
export type BookingListDashboardValue =
  | 'all_bookings'
  | 'arrivals'
  | 'departures'
  | 'unallocated_bookings'
  | 'scheduled_for_cancellation';
export type ColumnField =
  | 'id'
  | 'guest'
  | 'arrival_date'
  | 'departure_date'
  | 'city'
  | 'location'
  | 'unit_type'
  | 'rate_plan'
  | 'contact_info'
  | 'sd_charged'
  | 'total_due'
  | 'total_sale'
  | 'channel'
  | 'payment_collection_type'
  | 'booking_no'
  | 'ota_commission'
  | 'booking_status'
  | 'deposit_status'
  | 'check_in_ready'
  | 'booking_tags'
  | 'creation_source'
  | 'number_of_nights'
  | 'number_of_adult_guests'
  | 'number_of_child_guests'
  | 'id_verified'
  | 'attachments'
  | 'payment_type'
  | 'unit'
  | 'listings_marketing_name'
  | 'rate_plan_type'
  | 'arrival_time'
  | 'departure_time'
  | 'number_of_guests'
  | 'channel_total'
  | 'booking_payment_status'
  | 'group_booking_payment_status'
  | 'finalization_status'
  | 'booking_purpose'
  | 'person_booking'
  | 'check_out_time'
  | 'guest_email_address'
  | 'guest_number'
  | 'guest_verification_status'
  | 'channel_commission'
  | 'guest_level'
  | 'company_level'
  | 'penalties'
  | 'upsells'
  | 'fees'
  | 'booking_code'
  | 'booking_reference'
  | 'terms_and_condition_status'
  | 'booking_warnings'
  | 'check_in_method'
  | 'signable'
  | 'check_in_agent'
  | 'applied_promotions'
  | 'late_check_out_fee'
  | 'channel_promotion'
  | 'booking_confirmed'
  | 'access_code'
  | 'updated_date'
  | 'created_at'
  | 'action';

export type SortDirection = 'asc' | 'desc';

export const orderField = {
  guest: 'guest.first_name',
  arrival_date: 'arrival_date',
  departure_date: 'departure_date',
  city: 'property.area.city.name',
  location: 'property.short_name',
  unit: 'unit.name',
  unit_type: 'unit_type.name',
  rate_plan: 'staah_rate_plan.name',
  contact_info: 'guest.email',
  channel: 'channel.name',
  payment_collection_type: 'collection_type',
  booking_no: 'booking_n_code',
  booking_status: 'booking_status',
  deposit_status: 'deposit_status',
  creation_source: 'creation_source',
  number_of_nights: 'number_of_nights',
  number_of_adult_guests: 'number_of_adult_guests',
  number_of_child_guests: 'number_of_child_guests',
  created_at: 'created_at',
  guest_email_address: 'guest.email',
  guest_number: 'guest.mobileNumber',
  person_booking: 'bookedBy.firstName',
  check_out_time: 'departureTime',
  rate_plan_type: 'staahRatePlan.type',
  listings_marketing_name: 'property.name',
  finalization_status: 'finalizationStatus',
  booking_purpose: 'bookingPurpose',
  booking_payment_status: 'paymentStatus',
  group_booking_payment_status: 'bookingOrder.paymentStatus',
  updated_date: 'updatedAt',
  signable: 'guest.signable',
  booking_confirmed: 'checkInTimeApproved',
  check_in_method: 'checkInType.name',
  booking_reference: 'bookingReference',
  booking_code: 'booking_code',
  company_level: 'corporation.corporationLevel',
  guest_level: 'guest.guestLevel',
  id_verified: 'guest.idVerified',
};

export type PaymentCollectionType = 'Channel Collect' | 'Hotel Collect';

export type Filters = {
  arrival_date?: {
    from?: string;
    to?: string;
  };
  departure_date?: {
    from?: string;
    to?: string;
  };
  booking_status?: BookingStatus[];
  finalization_status?: FinalizationStatus[];
  unallocated?: boolean;
  unmapped?: boolean;
};

export type BookingListView = {
  value: BookingListViewValue;
  defaultFilters: Filters;
  defaultSortModel: GridSortModel;
  defaultHideColumns: ColumnField[];
  columnVisibilityModel?: GridColumnVisibilityModel;
  onColumnVisibilityModelChange?: (newModel: GridColumnVisibilityModel) => void;
  columnOrders?: string[];
  onColumnOrderChange?: (newOrders: string[]) => void;
};

export type BookingListDashboardView = {
  value: BookingListDashboardValue;
  defaultFilters: Filters;
  defaultSortModel: GridSortModel;
  defaultHideColumns: ColumnField[];
};

export interface BookingGridColumn extends GridColTypeDef {
  field: ColumnField | string;
}

export type Guest = {
  id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  email: string;
  mobile_number: string;
  id_verified: boolean;
  details_confirmed: boolean;
  is_signable: boolean;
  guest_level: string | null;
};

export type BookingWarning = {
  id: number;
  type: string;
  dismissed: boolean;
};

export type CheckInAgent = {
  full_name: string;
  check_in_agent_user_id: number | null;
  check_in_agent_task_id: number | null;
};

export type Booking = {
  id: number;
  booking_code: string;
  booking_no: string;
  guest: Guest;
  arrival_date: string;
  arrival_time: string | null;
  departure_date: string;
  departure_time: string | null;
  booking_status: BookingStatus;
  finalization_status: FinalizationStatus;
  channel: {
    id: number;
    name: string;
    logo: string;
  };
  number_of_adult_guests: number;
  number_of_child_guests: number;
  payment_status: PaymentStatus;
  property: {
    id: number;
    name: string;
    short_name: string;
    is_active: boolean;
    area?: {
      city?: { name: string };
    };
  } | null;
  unit_type: {
    id: number;
    name: string;
  } | null;
  unit: {
    id: number;
    name: string;
  } | null;
  booking_warnings: BookingWarning[];
  charged_amount: {
    value: number;
    currency: string;
    base_value: number;
  } | null;
  deposit_amount: {
    value: number;
    currency: string;
    base_value: number;
  } | null;
  deposit_status: SecurityDepositStatus;
  agreement: {
    id: number;
    agreement_template: {
      id: number;
      title: string;
      is_archived: boolean;
      body: {
        id: number;
        content: string;
      };
      is_editable: boolean;
      brand: {
        id: number;
        name: string;
      };
    };
    approved: boolean;
    signature: File;
    text: string;
    guest: Guest;
  } | null;
  price_breakdown: {
    accommodation_price: number;
    channel_addons: number;
    channel_taxes: number;
    channel_promotions: number;
    zeevou_addons: number;
    zeevou_taxes: number;
    discount: number;
    total_price: number;
    total_due: number;
    channel_total: number;
    managment_fee: number;
    total_sale: number;
    total_price_currency_symbol: string;
    total_price_currency: string;
  };
  tags?: {
    name: string;
    id: number;
    tagging_id: number;
  }[];
  booking_purpose: string;
  checkInReadyStatus: CheckInReadyStatus[];
  payment_collection_type: PaymentCollectionType;
  created_date: string;
  rate_plan: {
    name: string;
    type: string;
  } | null;
  creation_source: string | null;
  has_integrations_with_Xero: boolean;
  ota_commission: {
    value: number;
    currency: string;
  } | null;
  has_attachment: boolean;
  payment_type: string | null;
  group_booking_payment_status: PaymentStatus;
  updated_date: string;
  person_booking: {
    first_name: string | null;
    last_name: string | null;
  };
  checkout_time: string | null;
  zeevou_penalty: { value: number; currency: string };
  channel_commission: { value: number; currency: string };
  booking_reference: string | null;
  terms_and_condition_status: boolean;
  check_in_type: CheckInType;
  check_in_agent: string;
  check_in_agent_task_id: number | null;
  check_in_agent_user_id: number | null;
  access_code: string | null;
  corporation: { corporation_level: string } | null;
  applied_promotions: string | null;
  booking_confirmed: boolean;
  late_checkout_fee: number;
};

export type BookingGridRow = {
  id: number;
  guest: {
    id: number | null;
    firstName: string;
    lastName: string;
    adults: number;
    children: number;
  };
  arrival_date: {
    date: string;
    time: string | null;
  };
  departure_date: {
    date: string;
    time: string | null;
  };
  city: string;
  location: {
    property: {
      id: number;
      name: string;
    };
    unit: string | null;
  };
  contact_info: {
    mobile: string;
    email: string;
  };
  sd_charged: {
    price: number;
    currencySymbol: string;
  };
  total_due: {
    price: number;
    currencySymbol: string;
  };
  total_sale: {
    price: number;
    currencySymbol: string;
  };
  channel: {
    id: number;
    name: string;
    logo: string;
  };
  booking_no: string;
  booking_status: string;
  deposit_status: string;
  check_in_ready: CheckInReadyStatus[];
  payment_collection_type: PaymentCollectionType;
  created_at: string;
  number_of_nights: number;
  number_of_adult_guests: number;
  number_of_child_guests: number;
  unit_type: {
    id: number;
    name: string;
  };
  rate_plan: {
    name: string;
    type: string;
  };
  creation_source: string;
  booking_tags?: { id: number; name: string }[];
  id_verified: boolean;
  attachments: boolean;
  payment_type: string | null;
  unit: {
    id: number;
    name: string;
  } | null;
  listings_marketing_name: string;
  rate_plan_type: string | null;
  arrival_time: string | null;
  departure_time: string | null;
  number_of_guests: number;
  channel_total: {
    price: number;
    currencySymbol: string;
  };
  booking_payment_status: PaymentStatus;
  group_booking_payment_status: PaymentStatus;
  updated_date: string;
  finalization_status: FinalizationStatus;
  booking_purpose: string;
  person_booking: {
    first_name: string | null;
    last_name: string | null;
  };
  check_out_time: string | null;
  guest_email_address: string | null;
  guest_number: string | null;
  zeevou_penalty: { value: number; currency: string };
  upsells: {
    price: number;
    currencySymbol: string;
  };
  fees: {
    price: number;
    currencySymbol: string;
  };
  booking_code: string;
  booking_reference: string | null;
  terms_and_condition_status: boolean;
  check_in_method: CheckInType | null;
  check_in_agent: CheckInAgent;
  booking_warnings: BookingWarning[];
  access_code: string | null;
  channel_commission: { value: number; currency: string };
  signable: boolean;
  guest_level: string | null;
  company_level: string | null;
  applied_promotions: string | null;
  booking_confirmed: boolean;
  channel_promotion: {
    price: number;
    currencySymbol: string;
  };
  late_check_out_fee: {
    price: number;
    currencySymbol: string;
  };
  alerts: string[];
};

export const ALL_BOOKINGS_COLUMN_FIELDS = (
  bookingListViewValue: string,
  hasGuestViewGuest: boolean,
  hasFinanceFieldAccess: boolean,
  hasCheckInReadyConfigViewAccess: boolean
) => {
  switch (bookingListViewValue) {
    case 'arrivals':
      return [
        'id',
        ...(hasGuestViewGuest ? ['guest'] : []),
        'id_verified',
        'arrival_date',
        'departure_date',
        'city',
        'location',
        'unit_type',
        'rate_plan',
        ...(hasGuestViewGuest ? ['contact_info'] : []),
        'channel',
        'booking_no',
        'booking_status',
        'deposit_status',
        ...(hasCheckInReadyConfigViewAccess ? ['check_in_ready'] : []),
        'booking_tags',
        'number_of_nights',
        'number_of_adult_guests',
        'number_of_child_guests',
        'created_at',
        ...(hasGuestViewGuest ? ['guest_email_address'] : []),
        ...(hasGuestViewGuest ? ['guest_number'] : []),
        'arrival_time',
        'departure_time',
        ...(hasGuestViewGuest ? ['signable'] : []),
        'number_of_guests',
        'unit',
        'listings_marketing_name',
        'booking_purpose',
        'booking_payment_status',
        'group_booking_payment_status',
        'applied_promotions',
        ...(hasGuestViewGuest ? ['guest_level'] : []),
        ...(hasGuestViewGuest ? ['company_level'] : []),
        ...(hasFinanceFieldAccess ? ['fees'] : []),
        ...(hasFinanceFieldAccess ? ['upsells'] : []),
        'booking_confirmed',
        'check_in_agent',
        'access_code',
        'check_in_method',
        'booking_warnings',
      ];
    case 'all_bookings':
      return [
        'id',
        ...(hasGuestViewGuest ? ['guest'] : []),
        'arrival_date',
        'departure_date',
        'city',
        'location',
        'unit_type',
        'rate_plan',
        ...(hasGuestViewGuest ? ['contact_info'] : []),
        ...(hasFinanceFieldAccess ? ['sd_charged'] : []),
        ...(hasFinanceFieldAccess ? ['total_due'] : []),
        ...(hasFinanceFieldAccess ? ['total_sale'] : []),
        'channel',
        'payment_collection_type',
        'booking_no',
        'booking_status',
        'deposit_status',
        ...(hasCheckInReadyConfigViewAccess ? ['check_in_ready'] : []),
        'creation_source',
        'booking_tags',
        'number_of_nights',
        'number_of_adult_guests',
        'number_of_child_guests',
        'created_at',
        ...(hasGuestViewGuest ? ['guest_email_address'] : []),
        ...(hasGuestViewGuest ? ['guest_number'] : []),
        'arrival_time',
        'departure_time',
        ...(hasGuestViewGuest ? ['person_booking'] : []),
        'attachments',
        ...(hasFinanceFieldAccess ? ['payment_type'] : []),
        'number_of_guests',
        'check_out_time',
        'unit',
        'listings_marketing_name',
        'rate_plan_type',
        'finalization_status',
        'booking_purpose',
        ...(hasFinanceFieldAccess ? ['channel_total'] : []),
        'booking_payment_status',
        'group_booking_payment_status',
        'updated_date',
        'applied_promotions',
        ...(hasGuestViewGuest ? ['guest_level'] : []),
        ...(hasGuestViewGuest ? ['company_level'] : []),
        ...(hasFinanceFieldAccess ? ['penalties'] : []),
        ...(hasFinanceFieldAccess ? ['fees'] : []),
        ...(hasFinanceFieldAccess ? ['upsells'] : []),
        ...(hasFinanceFieldAccess ? ['channel_commission'] : []),
        'booking_confirmed',
        'booking_code',
        'booking_reference',
        'terms_and_condition_status',
        'id_verified',
        'check_in_agent',
        'check_in_method',
        'booking_warnings',
      ];
    case 'departures':
      return [
        'id',
        ...(hasGuestViewGuest ? ['guest'] : []),
        'departure_date',
        'city',
        'location',
        'unit_type',
        'rate_plan',
        ...(hasGuestViewGuest ? ['contact_info'] : []),
        ...(hasFinanceFieldAccess ? ['sd_charged'] : []),
        ...(hasFinanceFieldAccess ? ['total_due'] : []),
        'channel',
        'booking_no',
        'booking_status',
        'deposit_status',
        'booking_tags',
        'number_of_nights',
        'number_of_adult_guests',
        'number_of_child_guests',
        ...(hasGuestViewGuest ? ['guest_email_address'] : []),
        ...(hasGuestViewGuest ? ['guest_number'] : []),
        'departure_time',
        'number_of_guests',
        'check_out_time',
        'unit',
        'listings_marketing_name',
        'applied_promotions',
        ...(hasGuestViewGuest ? ['guest_level'] : []),
        ...(hasGuestViewGuest ? ['company_level'] : []),
        ...(hasFinanceFieldAccess ? ['fees'] : []),
        ...(hasFinanceFieldAccess ? ['upsells'] : []),
        ...(hasFinanceFieldAccess ? ['late_check_out_fee'] : []),
      ];
    case 'stay-overs':
      return [
        'id',
        ...(hasGuestViewGuest ? ['guest'] : []),
        'departure_date',
        'city',
        'location',
        'unit_type',
        'rate_plan',
        ...(hasGuestViewGuest ? ['contact_info'] : []),
        ...(hasFinanceFieldAccess ? ['sd_charged'] : []),
        ...(hasFinanceFieldAccess ? ['total_due'] : []),
        'channel',
        'booking_no',
        'booking_status',
        'deposit_status',
        'booking_tags',
        'number_of_nights',
        'number_of_adult_guests',
        'number_of_child_guests',
        ...(hasGuestViewGuest ? ['guest_email_address'] : []),
        ...(hasGuestViewGuest ? ['guest_number'] : []),
        'departure_time',
        'number_of_guests',
        'check_out_time',
        'unit',
        'listings_marketing_name',
        'applied_promotions',
        ...(hasGuestViewGuest ? ['guest_level'] : []),
        ...(hasGuestViewGuest ? ['company_level'] : []),
        ...(hasFinanceFieldAccess ? ['fees'] : []),
        ...(hasFinanceFieldAccess ? ['upsells'] : []),
      ];
    case 'awaiting_finalisation':
      return [
        'id',
        ...(hasGuestViewGuest ? ['guest'] : []),
        'departure_date',
        'location',
        'unit_type',
        'rate_plan',
        ...(hasFinanceFieldAccess ? ['total_due'] : []),
        'channel',
        'booking_no',
        'booking_status',
        'deposit_status',
        'booking_tags',
        'number_of_nights',
        'number_of_adult_guests',
        'number_of_child_guests',
        'departure_time',
        'number_of_guests',
        'unit',
        ...(hasGuestViewGuest ? ['guest_level'] : []),
        ...(hasGuestViewGuest ? ['company_level'] : []),
        'arrival_date',
        'arrival_time',
        ...(hasGuestViewGuest ? ['person_booking'] : []),
        ...(hasCheckInReadyConfigViewAccess ? ['check_in_ready'] : []),
        'rate_plan_type',
        'finalization_status',
        ...(hasFinanceFieldAccess ? ['total_sale'] : []),
        'payment_collection_type',
        'created_at',
        ...(hasFinanceFieldAccess ? ['penalties'] : []),
        ...(hasFinanceFieldAccess ? ['channel_commission'] : []),
        ...(hasFinanceFieldAccess ? ['channel_promotion'] : []),
      ];
    default:
      return [];
  }
};

export const ALL_BOOKINGS_DASHBOARD_COLUMN_FIELDS = (
  bookingListDashboardValue: BookingListDashboardValue,
  hasGuestViewGuest: boolean,
  hasFinanceFieldAccess: boolean,
  hasCheckInReadyConfigViewAccess: boolean
) => {
  switch (bookingListDashboardValue) {
    case 'arrivals':
      return [
        'id',
        ...(hasGuestViewGuest ? ['guest'] : []),
        ...(hasGuestViewGuest ? ['contact_info'] : []),
        'arrival_date',
        'departure_date',
        ...(hasCheckInReadyConfigViewAccess ? ['check_in_ready'] : []),
        'location',
      ];
    case 'all_bookings':
      return [
        'id',
        ...(hasGuestViewGuest ? ['guest'] : []),
        'arrival_date',
        'departure_date',
        ...(hasCheckInReadyConfigViewAccess ? ['check_in_ready'] : []),
        'location',
        'channel',
        'booking_status',
      ];
    case 'departures':
      return [
        'id',
        ...(hasGuestViewGuest ? ['guest'] : []),
        'departure_date',
        'number_of_nights',
        'location',
        'channel',
        ...(hasFinanceFieldAccess ? ['sd_charged'] : []),
        ...(hasFinanceFieldAccess ? ['total_due'] : []),
      ];
    case 'unallocated_bookings':
      return [
        'id',
        ...(hasGuestViewGuest ? ['guest'] : []),
        'arrival_date',
        'departure_date',
        'location',
        'booking_no',
        'created_at',
      ];
    case 'scheduled_for_cancellation':
      return [
        'id',
        ...(hasGuestViewGuest ? ['guest'] : []),
        'arrival_date',
        'departure_date',
        'location',
        'booking_no',
        'created_at',
      ];
    default:
      return [];
  }
};