import { createSlice } from '@reduxjs/toolkit';
// utils
import {
  initCollapsesFromProperties,
  initSwitches,
  calculatePropertyHeight,
  initDataFromProperties,
  initStatusFromProperties,
  getBookingPosition,
  PROPERTY_HEIGHT,
  DAY_WIDTH,
  INITIAL_START_INDEX,
  initPropertiesLoading,
  BLOCKED_STATUS_CODE,
  BOOKING_OFFSET_TOP,
  BLOCKED_OFFSET_TOP,
  loadDisplaySettingsFromLocalStorage,
  initDateConfig,
  CANCELED_STATUS_CODE,
  SCHEDULED_FOR_CANCELLATION_STATUS_CODE,
} from 'src/utils/calendar';
import calendarAPI from '../../api/calendar';
// @types
import { OcState, PropertyViewMode } from '../../@types/calendar';
//
import { RootState, dispatch } from '../store';
import { format, addDays, differenceInCalendarDays } from 'date-fns';
import { findIndex, find, filter } from 'lodash';

// ----------------------------------------------------------------------

const initialState: OcState = {
  isLoading: false,
  error: null,
  isOpenModal: false,
  dateConfig: initDateConfig(),
  cities: [],
  selectedCity: '0',
  generalView: 'booking',
  properties: [],
  propertiesLoading: [],
  totalProperties: 0,
  weekendDays: [],
  userAccesses: {},
  organizationDefaultsModified: false,
  ratePlanTypes: {
    nightly: true,
    weekly: false,
    monthly: true,
  },
  switches: [],
  collapses: [],
  data: {
    rates: [],
    units: [],
    availabilities: [],
    defaultRatePlans: [],
    unitAvailabilities: [],
  },
  status: [],
  visibleProperties: {
    startIndex: 0,
    endIndex: 0,
  },
  visibleUnits: {
    startIndex: 0,
    endIndex: 0,
  },
  visibleDates: {
    startIndex: 0,
    endIndex: 0,
  },
  drawer: {
    isLoading: true,
    open: false,
    bookingId: null,
    bookingAction: null,
    propertyIndexes: [],
  },
  blockedDateDrawer: {
    open: false,
    blockedDate: null,
  },
  scrollOffsetTop: 0,
  scrollOffsetLeft: INITIAL_START_INDEX * DAY_WIDTH,
  showCancelled: false,
  displaySettings: loadDisplaySettingsFromLocalStorage(),
  editRate: {
    open: false,
    rateId: 0,
    rate: null,
    dateIndex: 0,
    isDefault: false,
    planType: '',
  },
  editRangeRate: {
    open: false,
    rateId: 0,
    start: 0,
    end: 0,
    isDefault: false,
    planType: '',
  },
  disableSearchShadow: true,
  editBooking: {
    open: false,
    booking: null,
  },
};

const slice = createSlice({
  name: 'oc',
  initialState,
  reducers: {
    reset: () => initialState,

    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
    },

    // SELECT CITY
    setSelectedCity(state, action) {
      state.selectedCity = action.payload;
    },

    // CONFIG
    getCalendarConfig(state, action) {
      const { data, metaData } = action.payload;
      const { cities, propCount, userAccesses, weekendDays, ratePlanTypes, organization_defaults_config_modified } = metaData;
      state.isLoading = false;
      state.cities = cities;
      state.totalProperties = propCount;
      state.userAccesses = userAccesses;
      state.organizationDefaultsModified = organization_defaults_config_modified;
      state.weekendDays = weekendDays.map(weekend => String(weekend));
      state.ratePlanTypes = {
        nightly: ratePlanTypes.Nightly,
        weekly: ratePlanTypes.Weekly,
        monthly: ratePlanTypes.Monthly,
      };
      state.properties = data;
      state.propertiesLoading = initPropertiesLoading(propCount, false);
      state.switches = initSwitches(propCount, state.generalView === 'booking');
      state.collapses = initCollapsesFromProperties(
        data,
        state.generalView === 'booking',
        state.displaySettings.showPropertyRow,
        state.displaySettings.showUnitTypeRow
      );
      state.data = initDataFromProperties(data);
      state.status = initStatusFromProperties(data);
      state.visibleProperties = { startIndex: 0, endIndex: 0 };
    },

    // TOGGLE PROPERTY VIEW
    togglePropertyView(state, action) {
      const index = action.payload;
      state.propertiesLoading[index] = true;
      state.switches[index] = !state.switches[index];
      state.collapses[index].height = state.collapses[index].isOpen
        ? calculatePropertyHeight(
            state.collapses[index],
            state.switches[index],
            state.displaySettings.showPropertyRow,
            state.displaySettings.showUnitTypeRow
          )
        : 48;
    },

    // UPDATE UNIT HEIGHT
    updateUnitHeight(state, action) {
      const { index, unitTypeIndex, unitIndex, height } = action.payload;
      state.collapses[index].unitTypes[unitTypeIndex].units[unitIndex].height = height;
      state.collapses[index].height = state.collapses[index].isOpen
        ? calculatePropertyHeight(
            state.collapses[index],
            state.switches[index],
            state.displaySettings.showPropertyRow,
            state.displaySettings.showUnitTypeRow
          )
        : 48;
    },

    // UPDATE VISIBLE PROPERTIES
    updateVisibleProperties(state, action) {
      state.visibleProperties = action.payload;
    },

    // UPDATE VISIBLE PROPERTIES
    updateVisibleUnits(state, action) {
      state.visibleUnits = action.payload;
    },

    // UPDATE VISIBLE DATES
    updateVisibleDates(state, action) {
      state.visibleDates = action.payload;
    },

    // UPDATE RATES
    updateRates(state, action) {
      const { metadata, properties } = action.payload;
      const fromIndex = differenceInCalendarDays(new Date(metadata.from + 'T00:00:00'), state.dateConfig.start);
      const toIndex = differenceInCalendarDays(new Date(metadata.to + 'T00:00:00'), state.dateConfig.start);

      properties.forEach((property) => {
        let propertyIndex = findIndex(state.properties, (p: any) => p.id === property.id);
        state.propertiesLoading[propertyIndex] = false;
        for (let i = fromIndex; i <= toIndex; i++) {
          state.status[propertyIndex][i].rateStatus = 1;
        }

        property.unitTypes.forEach((unitType) => {
          unitType.plans.forEach((plan) => {
            let planIndex = findIndex(state.data.rates, (rate: any) => rate.id === plan.planId);
            for (let i = fromIndex, j = 0; i <= toIndex; i++, j++) {
              state.data.rates[planIndex].rate[i] = { status: 1, rate: plan.rates[j] };
            }
          });

          let availabilityIndex = findIndex(
            state.data.availabilities,
            (availability: any) => availability.id === unitType.id
          );
          for (let i = fromIndex, j = 0; i <= toIndex; i++, j++) {
            state.data.availabilities[availabilityIndex].availability[i] = {
              status: 1,
              availability: unitType.availabilities[j],
            };
          }
        });
      });
    },

    // UPDATE BOOKINGS
    updateBookings(state, action) {
      const { metadata, properties, removedBookingId } = action.payload;
      const fromIndex = differenceInCalendarDays(new Date(metadata.from + 'T00:00:00'), state.dateConfig.start);
      const toIndex = differenceInCalendarDays(new Date(metadata.to + 'T00:00:00'), state.dateConfig.start);

      properties.forEach((property) => {
        let propertyIndex = findIndex(state.properties, (p: any) => p.id === property.id);
        state.propertiesLoading[propertyIndex] = false;
        for (let i = fromIndex; i <= toIndex; i++) {
          state.status[propertyIndex][i].bookingStatus = 1;
        }

        property.unitTypes.forEach((unitType) => {
          // update units bookings
          unitType.units.forEach((unit) => {
            let unitIndex = findIndex(state.data.units, (u: any) => u.id === unit.id && u.name === unit.name);
            let unitAvailabilityIndex = findIndex(state.data.unitAvailabilities, (a: any) => a.id === unit.id);
            const positions = [
              ...getBookingPosition(
                unit.blocks,
                state.dateConfig.start,
                BLOCKED_OFFSET_TOP,
                state.showCancelled,
                new Date(state.properties[propertyIndex].LFCO),
                new Date(state.properties[propertyIndex].EFCI),
              ),
              ...getBookingPosition(
                unit.bookings,
                state.dateConfig.start,
                BOOKING_OFFSET_TOP,
                state.showCancelled,
                new Date(state.properties[propertyIndex].LFCO),
                new Date(state.properties[propertyIndex].EFCI),
              ),
            ];
            if (removedBookingId !== null) {
              state.data.units[unitIndex].unit.bookings = state.data.units[
                unitIndex
              ].unit.bookings.filter((b) => b.id !== removedBookingId);
            }
            positions.map((position) => {
              const hasBooking = findIndex(
                state.data.units[unitIndex].unit.bookings,
                (b: any) => b.id === position.id
              );
              if (hasBooking === -1) {
                state.data.units[unitIndex].unit.bookings.push(position);
              } else {
                state.data.units[unitIndex].unit.bookings[hasBooking] = position;
              }
            });
            
            if (unit.availabilities) {
              const dateIndexRange = toIndex - fromIndex;
              for (let i = fromIndex; i <= toIndex; i++) {
                state.data.unitAvailabilities[unitAvailabilityIndex].availabilities[i] = unit.availabilities[dateIndexRange - (toIndex - i)].available;
              }
            }
            
          });

          // update unallocated bookings
          let unitTypeIndex = findIndex(state.data.units, (u: any) => u.id === unitType.id && u.name === 'Unallocated');
          const positions = getBookingPosition(
            [...unitType.unallocatedBookings],
            state.dateConfig.start,
            BOOKING_OFFSET_TOP,
            state.showCancelled,
            new Date(state.properties[propertyIndex].LFCO),
            new Date(state.properties[propertyIndex].EFCI),
          );
          if (removedBookingId !== null) {
            state.data.units[unitTypeIndex].unit.bookings = state.data.units[
              unitTypeIndex
            ].unit.bookings.filter((b) => b.id !== removedBookingId);
          }
          positions.map((position) => {
            const hasBooking = findIndex(
              state.data.units[unitTypeIndex].unit.bookings,
              (b: any) => b.id === position.id
            );
            if (hasBooking === -1) {
              state.data.units[unitTypeIndex].unit.bookings.push(position);
            } else {
              state.data.units[unitTypeIndex].unit.bookings[hasBooking] = position;
            }
          });

          // update default rate
          let defaultRateIndex = findIndex(
            state.data.defaultRatePlans,
            (d: any) => d.id === unitType.id
          );

          let plan = unitType.defaultRatePlan.length > 0 ? unitType.defaultRatePlan[0] : null;
          if (plan) {
            state.data.defaultRatePlans[defaultRateIndex].planId = plan.planId;
            state.data.defaultRatePlans[defaultRateIndex].planType = plan.planType;
            for (let i = fromIndex, j = 0; i <= toIndex; i++, j++) {
              state.data.defaultRatePlans[defaultRateIndex].rate[i] = {
                status: 1,
                rate: plan && plan.rates[j],
              };
            }
          }
        });
      });
    },
    findPropertyIndexByPropertyId(state, action) {
      let propertyIndex = findIndex(state.properties, (p: any) => p.id === action.payload);
      state.drawer.propertyIndexes = [propertyIndex];
    },

    // OPEN DRAWER
    openDrawer(state, action) {
      state.drawer.open = true;
      state.drawer.bookingId = action.payload.id;
    },

    // OPEN DRAWER
    setDrawerBooking(state, action) {
      state.drawer.isLoading = false;
      if (action.payload.error) {
        state.drawer.bookingId = null;
      } else {
        state.drawer.bookingId = action.payload;
      }
    },

    // CLOSE DRAWER
    closeDrawer(state) {
      state.drawer.isLoading = true;
      state.drawer.open = false;
      state.drawer.bookingAction = null;
    },

    openBlockedDateDrawer(state, action) {
      state.blockedDateDrawer.open = true;
      state.blockedDateDrawer.blockedDate = action.payload;
    },

    closeBlockedDateDrawer(state) {
      state.blockedDateDrawer.open = false;
    },

    setBookingAction(state, action) {
      state.drawer.bookingAction = action.payload;
    },

    // OPEN DRAWER WITH ACTION
    openDrawerAction(state, action) {
      const { booking, bookingAction } = action.payload;
      state.drawer.open = true;
      state.drawer.bookingId = booking;
      state.drawer.bookingAction = bookingAction;
    },

    // SET PROPERTY LOADING
    setPropertiesLoading(state, action) {
      const properties = action.payload;
      properties.forEach((property) => {
        let propertyIndex = findIndex(state.collapses, (p: any) => p.id === property);
        state.propertiesLoading[propertyIndex] = true;
      });
    },

    // SET SCROLL OFFSET TOP
    setScrollOffsetTop(state, action) {
      state.scrollOffsetTop = action.payload;
    },

    // SET SCROLL OFFSET LEFT
    setScrollOffsetLeft(state, action) {
      state.scrollOffsetLeft = action.payload;
    },

    // SET SCROLL OFFSET LEFT
    addScrollOffsetLeft(state, action) {
      const amount = action.payload;
      if (amount > 4) {
        state.scrollOffsetLeft = state.scrollOffsetLeft + 42;
      } else if (amount < -4) {
        state.scrollOffsetLeft = state.scrollOffsetLeft - 42;
      }
    },

    // CHANGE UNIT STATUS
    changeUnitStatus(state, action) {
      const { propertyIndex, unitTypeIndex, unitIndex } = action.payload;
      let { status } = state.properties[propertyIndex].unitTypes[unitTypeIndex].units[unitIndex];
      if (status === 1) {
        state.properties[propertyIndex].unitTypes[unitTypeIndex].units[unitIndex].status = 2;
      } else {
        state.properties[propertyIndex].unitTypes[unitTypeIndex].units[unitIndex].status = 1;
      }
    },

    // TOGGLE PROPERTY OPEN
    togglePropertyOpen(state, action) {
      const { index } = action.payload;
      const isUnit = state.switches[index];
      state.collapses[index].height = state.collapses[index].isOpen
        ? PROPERTY_HEIGHT
        : calculatePropertyHeight(
            state.collapses[index],
            isUnit,
            state.displaySettings.showPropertyRow,
            state.displaySettings.showUnitTypeRow
          );
      state.collapses[index].isOpen = !state.collapses[index].isOpen;
    },

    // TOGGLE UNIT TYPE OPEN
    toggleUnitTypeOpen(state, action) {
      const { index, utIndex } = action.payload;
      const isUnit = state.switches[index];
      state.collapses[index].unitTypes[utIndex].isOpen =
        !state.collapses[index].unitTypes[utIndex].isOpen;
      state.collapses[index].height = calculatePropertyHeight(
        state.collapses[index],
        isUnit,
        state.displaySettings.showPropertyRow,
        state.displaySettings.showUnitTypeRow
      );
    },

    // TOGGLE SHOW CANCELLED
    toggleShowCancelled(state) {
      state.showCancelled = !state.showCancelled;
    },

    // TOGGLE SHOW PROPERTY ROW
    toggleShowPropertyRow(state) {
      state.displaySettings.showPropertyRow = !state.displaySettings.showPropertyRow;
    },

    // TOGGLE SHOW UNIT TYPE ROW
    toggleShowUnitTypeRow(state) {
      state.displaySettings.showUnitTypeRow = !state.displaySettings.showUnitTypeRow;
    },

    // SET DISPLAY SETTINGS
    setDisplaySettings(state, action) {
      state.displaySettings.showPropertyRow = action.payload.showPropertyRow;
      state.displaySettings.showUnitTypeRow = action.payload.showUnitTypeRow;
      state.displaySettings.showPastUnallocated = action.payload.showPastUnallocated;
      state.displaySettings.showAvgNightlyRate = action.payload.showAvgNightlyRate;
      state.displaySettings.showBookingDate = action.payload.showBookingDate;
      state.displaySettings.showCollectionType = action.payload.showCollectionType;
      state.displaySettings.showTotalPrice = action.payload.showTotalPrice;
    },

    // EDIT RATE PLAN
    setEditRate(state, action) {
      state.editRate = action.payload;
    },

    // EDIT RATE PLAN
    setEditRangeRate(state, action) {
      state.editRangeRate = action.payload;
    },

    // UPDATE RATE
    updateRate(state, action) {
      const {
        rateId,
        start,
        end,
        price,
        minStay,
        maxStay,
        stopCheckins,
        stopCheckouts,
        stopSell,
        isDefault = false,
      } = action.payload;
      const rate = isDefault
        ? find(state.data.defaultRatePlans, ['planId', rateId])
        : find(state.data.rates, ['id', rateId]);
      if (rate) {
        for (let index = start; index < end; index++) {
          rate.rate[index].rate = {
            ...rate.rate[index].rate,
            ...(!!price && { basePrice: price }),
            ...(!!minStay && { minStay: minStay }),
            ...(!!maxStay && { maxStay: maxStay }),
            ...(typeof stopCheckins !== 'undefined' && { stopCheckins: stopCheckins }),
            ...(typeof stopCheckouts !== 'undefined' && { stopCheckouts: stopCheckouts }),
            ...(typeof stopSell !== 'undefined' && { stopSell: stopSell }),
          };
        }
      }
    },

    // SET DISABLE SEARCH SHADOW
    setDisableSearchShadow(state, action) {
      state.disableSearchShadow = action.payload;
    },

    // OPEN EDIT BOOKING
    openEditBooking(state, action) {
      const { item, dropResult } = action.payload;
      const sourceProperty = find(
        state.properties,
        (property) => property.id === item.source.propertyId
      );
      const property = find(state.properties, (property) => property.id === dropResult.property);
      const sourceUnitType = find(
        sourceProperty.unitTypes,
        (unitType) => unitType.id === item.source.unitTypeId
      );
      const unitType = find(property.unitTypes, (unitType) => unitType.id === dropResult.unitType);
      const sourceUnit = find(sourceUnitType.units, (unit) => unit.id === item.source.unitId);
      const unit = find(unitType.units, (unit) => unit.id === dropResult.unit);
      const differenceInDays = dropResult.deltaX / DAY_WIDTH;
      state.editBooking.open = true;
      state.editBooking.booking = {
        ...item,
        ...dropResult,
        oldValue: {
          property: sourceProperty.name,
          unitType: sourceUnitType.name,
          unit: sourceUnit?.name ?? 'Unallocated',
        },
        newValue: {
          property: property.name,
          unitType: unitType.name,
          unit: unit?.name ?? 'Unallocated',
          from: addDays(new Date(item.from), differenceInDays),
          to: addDays(new Date(item.to), differenceInDays),
          differenceInDays,
        },
      };
    },

    // CLOSE EDIT BOOKING
    closeEditBooking(state, action) {
      state.editBooking.open = false;
      state.editBooking.booking = null;
    },

    // UPDATE BOOKING
    updateBooking(state, action) {
      const {
        id,
        sourceUnitId,
        unitId,
        differenceInDays,
        from,
        to,
        sourcePropertyId,
        propertyId,
        newPrice,
      } = action.payload;
      let sourceUnitIndex = findIndex(state.data.units, (u: any) => u.id === sourceUnitId);
      let unitIndex = findIndex(state.data.units, (u: any) => u.id === unitId);
      let sourceUnitAvailabilityIndex = findIndex(state.data.unitAvailabilities, (a: any) => a.id === sourceUnitId);
      let targetUnitAvailabilityIndex = findIndex(state.data.unitAvailabilities, (a: any) => a.id === unitId);

      let booking: any = find(
        state.data.units[sourceUnitIndex].unit.bookings,
        (booking: any) => booking.id === id
      );
      if (!!booking) {
        if (newPrice) {
          booking.data.price = newPrice;
        }

        if (state.data.units[sourceUnitIndex].name !== 'Unallocated') {
          // update unit availabilities
          let sourceUnitAvailabilityFromIndex = differenceInCalendarDays(new Date(booking.data.from), state.dateConfig.start);
          let sourceUnitAvailabilityToIndex = differenceInCalendarDays(new Date(booking.data.to), state.dateConfig.start);

          for (let i = sourceUnitAvailabilityFromIndex; i < sourceUnitAvailabilityToIndex; i++) {
            state.data.unitAvailabilities[sourceUnitAvailabilityIndex].availabilities[i] = true;
          }
        }

        if (state.data.units[unitIndex].name !== 'Unallocated') {
          // update unit availabilities
          let targetUnitAvailabilityFromIndex = differenceInCalendarDays(addDays(new Date(booking.data.from), differenceInDays), state.dateConfig.start);
          let targetUnitAvailabilityToIndex = differenceInCalendarDays(addDays(new Date(booking.data.to), differenceInDays), state.dateConfig.start);

          for (let i = targetUnitAvailabilityFromIndex; i < targetUnitAvailabilityToIndex; i++) {
            state.data.unitAvailabilities[targetUnitAvailabilityIndex].availabilities[i] = false;
          }
        }

        if (booking.data.from !== from && booking.data.to !== to) {
          booking.left = booking.left + differenceInDays * DAY_WIDTH;
          booking.data.from = format(
            addDays(new Date(booking.data.from), differenceInDays),
            'MM/dd/yyyy HH:mm:ss'
          );
          booking.data.to = format(
            addDays(new Date(booking.data.to), differenceInDays),
            'MM/dd/yyyy HH:mm:ss'
          );
        }

        state.data.units[sourceUnitIndex].unit.bookings = filter(
          state.data.units[sourceUnitIndex].unit.bookings,
          (booking: any) => booking.id !== id
        );
        
        state.data.units[unitIndex].unit.bookings = [
          ...state.data.units[unitIndex].unit.bookings,
          booking,
        ];

        state.data.units[sourceUnitIndex].unit.bookings = state.data.units[
          sourceUnitIndex
        ].unit.bookings.sort(
          (b1: any, b2: any) => new Date(b1.data.from).valueOf() - new Date(b2.data.from).valueOf()
        );
        state.data.units[unitIndex].unit.bookings = state.data.units[unitIndex].unit.bookings.sort(
          (b1: any, b2: any) => new Date(b1.data.from).valueOf() - new Date(b2.data.from).valueOf()
        );

        let sourcePropertyIndex = findIndex(
          state.collapses,
          (p: any) => p.id === sourcePropertyId
        );
        // if source unit is unallocated - decrease the count
        // recalculate the collapse height if the row needs to be hide
        if (state.data.units[sourceUnitIndex].name === 'Unallocated') {
          let sourceUnitTypeIndex = findIndex(
            state.properties[sourcePropertyIndex].unitTypes,
            (ut: any) => ut.id === sourceUnitId
          );
          state.properties[sourcePropertyIndex].unitTypes[
            sourceUnitTypeIndex
          ].unallocatedBookingsCount =
            state.properties[sourcePropertyIndex].unitTypes[sourceUnitTypeIndex]
              .unallocatedBookingsCount - 1;
          state.collapses[sourcePropertyIndex].unitTypes[
            sourceUnitTypeIndex
          ].unallocatedBookingsCount =
            state.collapses[sourcePropertyIndex].unitTypes[sourceUnitTypeIndex]
              .unallocatedBookingsCount - 1;
          state.collapses[sourcePropertyIndex].height = calculatePropertyHeight(
            state.collapses[sourcePropertyIndex],
            true,
            state.displaySettings.showPropertyRow,
            state.displaySettings.showUnitTypeRow
          );
        }

        let propertyIndex = findIndex(state.collapses, (p: any) => p.id === propertyId);
        // if target unit is unallocated - increase the count
        // recalculate the collapse height if the row needs to be shown
        if (state.data.units[unitIndex].name === 'Unallocated') {
          let unitTypeIndex = findIndex(
            state.properties[propertyIndex].unitTypes,
            (ut: any) => ut.id === unitId
          );
          state.properties[propertyIndex].unitTypes[unitTypeIndex].unallocatedBookingsCount =
            state.properties[propertyIndex].unitTypes[unitTypeIndex].unallocatedBookingsCount + 1;
          state.collapses[propertyIndex].unitTypes[unitTypeIndex].unallocatedBookingsCount =
            state.collapses[propertyIndex].unitTypes[unitTypeIndex].unallocatedBookingsCount + 1;
          state.collapses[propertyIndex].height = calculatePropertyHeight(
            state.collapses[propertyIndex],
            true,
            state.displaySettings.showPropertyRow,
            state.displaySettings.showUnitTypeRow
          );
        }

        const sourcePositions = [
          ...getBookingPosition(
            [...state.data.units[sourceUnitIndex].unit.bookings.filter((booking) => booking.data.status === BLOCKED_STATUS_CODE).map((booking) => booking.data)],
            state.dateConfig.start,
            BLOCKED_OFFSET_TOP,
            state.showCancelled,
            new Date(state.properties[propertyIndex].LFCO),
            new Date(state.properties[propertyIndex].EFCI),
          ),
          ...getBookingPosition(
            [...state.data.units[sourceUnitIndex].unit.bookings.filter((booking) => booking.data.status !== BLOCKED_STATUS_CODE).map((booking) => booking.data)],
            state.dateConfig.start,
            BOOKING_OFFSET_TOP,
            state.showCancelled,
            new Date(state.properties[propertyIndex].LFCO),
            new Date(state.properties[propertyIndex].EFCI),
          ),
        ];
        sourcePositions.map((position) => {
          const hasBooking = findIndex(
            state.data.units[sourceUnitIndex].unit.bookings,
            (b: any) => b.id === position.id
          );
          if (hasBooking === -1) {
            // state.data.units[sourceUnitIndex].unit.bookings.push(position);
          } else {
            state.data.units[sourceUnitIndex].unit.bookings[hasBooking] = position;
          }
        });


        const positions = [
          ...getBookingPosition(
            [...state.data.units[unitIndex].unit.bookings.filter((booking) => booking.data.status === BLOCKED_STATUS_CODE).map((booking) => booking.data)],
            state.dateConfig.start,
            BLOCKED_OFFSET_TOP,
            state.showCancelled,
            new Date(state.properties[propertyIndex].LFCO),
            new Date(state.properties[propertyIndex].EFCI),
          ),
          ...getBookingPosition(
            [...state.data.units[unitIndex].unit.bookings.filter((booking) => booking.data.status !== BLOCKED_STATUS_CODE).map((booking) => booking.data)],
            state.dateConfig.start,
            BOOKING_OFFSET_TOP,
            state.showCancelled,
            new Date(state.properties[propertyIndex].LFCO),
            new Date(state.properties[propertyIndex].EFCI),
          ),
        ];
        positions.map((position) => {
          const hasBooking = findIndex(
            state.data.units[unitIndex].unit.bookings,
            (b: any) => b.id === position.id
          );
          if (hasBooking === -1) {
            // state.data.units[unitIndex].unit.bookings.push(position);
          } else {
            state.data.units[unitIndex].unit.bookings[hasBooking] = position;
          }
        });
      }
    },

    // UPDATE UNALLOCATED ROW
    updateUnallocatedRow(state, action) {
      const {  propertyId, unitTypeId } = action.payload;

      let propertyIndex = findIndex(state.collapses, (p: any) => p.id === propertyId);
      let unitTypeIndex = findIndex(
        state.properties[propertyIndex].unitTypes,
        (ut: any) => ut.id === unitTypeId
      );
      state.properties[propertyIndex].unitTypes[unitTypeIndex].unallocatedBookingsCount =
        state.properties[propertyIndex].unitTypes[unitTypeIndex].unallocatedBookingsCount + 1;
      state.collapses[propertyIndex].unitTypes[unitTypeIndex].unallocatedBookingsCount =
        state.collapses[propertyIndex].unitTypes[unitTypeIndex].unallocatedBookingsCount + 1;
      state.collapses[propertyIndex].height = calculatePropertyHeight(
        state.collapses[propertyIndex],
        true,
        state.displaySettings.showPropertyRow,
        state.displaySettings.showUnitTypeRow
      );
    },

    // UNALLOCATE BOOKING
    unallocateBooking(state, action) {
      const { id, sourceUnitId, unitId, propertyId } = action.payload;
      let propertyIndex = findIndex(state.collapses, (p: any) => p.id === propertyId);
      let UT = find(state.properties[propertyIndex].unitTypes, (ut: any) => ut.id === unitId);
      let sourceUnitIndex = findIndex(state.data.units, (u: any) => u.id === sourceUnitId);
      let booking: any = find(
        state.data.units[sourceUnitIndex].unit.bookings,
        (booking: any) => booking.id === id
      );
      if (!!booking) {
        state.data.units[sourceUnitIndex].unit.bookings = filter(
          state.data.units[sourceUnitIndex].unit.bookings,
          (booking: any) => booking.id !== id
        );
        let unitIndex = findIndex(state.data.units, (u: any) => u.id === unitId);
        state.data.units[unitIndex].unit.bookings = [
          ...state.data.units[unitIndex].unit.bookings,
          booking,
        ];

        let unitAvailabilityIndex = findIndex(state.data.unitAvailabilities, (a: any) => a.id === sourceUnitId);

        // update unit availabilities
        let unitAvailabilityFromIndex = differenceInCalendarDays(new Date(booking.data.from), state.dateConfig.start);
        let unitAvailabilityToIndex = differenceInCalendarDays(new Date(booking.data.to), state.dateConfig.start);

        for (let i = unitAvailabilityFromIndex; i < unitAvailabilityToIndex; i++) {
          state.data.unitAvailabilities[unitAvailabilityIndex].availabilities[i] = true;
        }

        // if target unit is unallocated - increase the count
        // recalculate the collapse height if the row needs to be shown
        if (state.data.units[unitIndex].name === 'Unallocated') {
          
          let collapseUT = find(
            state.collapses[propertyIndex].unitTypes,
            (ut: any) => ut.id === unitId
          );
          UT.unallocatedBookingsCount = UT.unallocatedBookingsCount + 1;
          if (collapseUT) {
            collapseUT.unallocatedBookingsCount = collapseUT.unallocatedBookingsCount + 1;
          }
          state.collapses[propertyIndex].height = calculatePropertyHeight(
            state.collapses[propertyIndex],
            true,
            state.displaySettings.showPropertyRow,
            state.displaySettings.showUnitTypeRow
          );
        }

        const positions = getBookingPosition(
          [...state.data.units[unitIndex].unit.bookings.map((booking) => booking.data)],
          state.dateConfig.start,
          BOOKING_OFFSET_TOP,
          state.showCancelled,
          new Date(state.properties[propertyIndex].LFCO),
          new Date(state.properties[propertyIndex].EFCI),
        );
        positions.map((position) => {
          const hasBooking = findIndex(
            state.data.units[unitIndex].unit.bookings,
            (b: any) => b.id === position.id
          );
          if (hasBooking === -1) {
            // state.data.units[unitIndex].unit.bookings.push(position);
          } else {
            state.data.units[unitIndex].unit.bookings[hasBooking] = position;
          }
        });
      }
    },

    // SET GENERAL VIEW
    setGeneralView(state, action) {
      const view = action.payload;
      if (view === 'booking') {
        state.generalView = 'booking';
      } else {
        state.generalView = 'rates';
      }
    },

    // UPDATE OVERVIEW EXTENDED BOOKINGS
    updateOverviewExtendedBookings(state, action) {
      // state.drawer.booking.extendedBookingIds.push(action.payload);
    },

    // ADD BOOKING
    addBooking(state, action) {
      const { booking, propertyId, unitTypeId, unitId } = action.payload;

      // 1. check if the property data is loaded for that time
      // 2. add the booking to the array of data for the given unit
      // 3. add unallocated booking
      // 3.1. check overlap
      // 3.2. update unallocated count

      // check if the unit is unallocated
      let isUnallocated = unitId === 'unallocated';
      let isBlockDate = booking.status === BLOCKED_STATUS_CODE;
      let propertyIndex = findIndex(state.collapses, (p: any) => p.id === propertyId);
      let unitIndex = findIndex(state.data.units, (u: any) =>
        isUnallocated ? u.id === unitTypeId && u.name === 'Unallocated' : u.id === unitId
      );

      let bookings = [
        ...state.data.units[unitIndex].unit.bookings.filter(
          (booking) => booking.data.status !== BLOCKED_STATUS_CODE
        ),
      ];
      let blockDates = [
        ...state.data.units[unitIndex].unit.bookings.filter(
          (booking) => booking.data.status === BLOCKED_STATUS_CODE
        ),
      ];
      const positions = [
        ...getBookingPosition(
          [...blockDates.map((block) => block.data), ...(isBlockDate ? [booking] : [])],
          state.dateConfig.start,
          BLOCKED_OFFSET_TOP,
          state.showCancelled,
          new Date(state.properties[propertyIndex].LFCO),
          new Date(state.properties[propertyIndex].EFCI),
        ),
        ...getBookingPosition(
          [...bookings.map((booking) => booking.data), ...(!isBlockDate ? [booking] : [])],
          state.dateConfig.start,
          BOOKING_OFFSET_TOP,
          state.showCancelled,
          new Date(state.properties[propertyIndex].LFCO),
          new Date(state.properties[propertyIndex].EFCI),
        ),
      ];

      positions.map((position) => {
        const hasBooking = findIndex(
          state.data.units[unitIndex].unit.bookings,
          (b: any) => b.id === position.id
        );
        if (hasBooking === -1) {
          state.data.units[unitIndex].unit.bookings.push(position);
        } else {
          state.data.units[unitIndex].unit.bookings[hasBooking] = position;
        }
      });

      if (isUnallocated) {
        let UT = find(state.properties[propertyIndex].unitTypes, (ut: any) => ut.id === unitTypeId);
        UT.unallocatedBookingsCount = UT.unallocatedBookingsCount + 1;
        let collapseUT = find(
          state.collapses[propertyIndex].unitTypes,
          (ut: any) => ut.id === unitTypeId
        );
        if (collapseUT) {
          collapseUT.unallocatedBookingsCount = collapseUT.unallocatedBookingsCount + 1;
        }
        state.collapses[propertyIndex].height = calculatePropertyHeight(
          state.collapses[propertyIndex],
          true,
          state.displaySettings.showPropertyRow,
          state.displaySettings.showUnitTypeRow
        );
      }
    },

    // UPDATE SINGLE BOOKING
    updateSingleBooking(state, action) {
      const { booking, propertyId, unitTypeId, unitId } = action.payload;

      // 1. check if the property data is loaded for that time
      // 2. find the booking for the given unit and update it's data
      // 3. update unallocated booking
      // 3.1. check overlap

      // check if the unit is unallocated
      let isUnallocated = unitId === 'unallocated';
      let isBlockDate = booking.status === BLOCKED_STATUS_CODE;
      let propertyIndex = findIndex(state.collapses, (p: any) => p.id === propertyId);
      let unitIndex = findIndex(state.data.units, (u: any) =>
        isUnallocated ? u.id === unitTypeId && u.name === 'Unallocated' : u.id === unitId
      );
      let shouldHideCancelled = !state.showCancelled && (booking.status === CANCELED_STATUS_CODE || booking.status === SCHEDULED_FOR_CANCELLATION_STATUS_CODE); 

      const hasBooking = findIndex(
        state.data.units[unitIndex].unit.bookings,
        (b: any) => b.id === booking.id
      );
      if (hasBooking !== -1) {
        state.data.units[unitIndex].unit.bookings = filter(
          state.data.units[unitIndex].unit.bookings,
          (b: any) => b.id !== booking.id
        );
        const positions = [
          ...getBookingPosition(
            [...state.data.units[unitIndex].unit.bookings.filter((booking) => booking.data.status === BLOCKED_STATUS_CODE).map((booking) => booking.data)],
            state.dateConfig.start,
            BLOCKED_OFFSET_TOP,
            state.showCancelled,
            new Date(state.properties[propertyIndex].LFCO),
            new Date(state.properties[propertyIndex].EFCI),
          ),
          ...getBookingPosition(
            shouldHideCancelled 
            ? [...state.data.units[unitIndex].unit.bookings.filter((booking) => booking.data.status !== BLOCKED_STATUS_CODE).map((booking) => booking.data)] 
            : [...state.data.units[unitIndex].unit.bookings.filter((booking) => booking.data.status !== BLOCKED_STATUS_CODE).map((booking) => booking.data), booking],
            state.dateConfig.start,
            BOOKING_OFFSET_TOP,
            state.showCancelled,
            new Date(state.properties[propertyIndex].LFCO),
            new Date(state.properties[propertyIndex].EFCI),
          ),
        ];

        positions.map((position) => {
          const hasBooking = findIndex(
            state.data.units[unitIndex].unit.bookings,
            (b: any) => b.id === position.id
          );
          if (hasBooking === -1) {
            state.data.units[unitIndex].unit.bookings.push(position);
          } else {
            state.data.units[unitIndex].unit.bookings[hasBooking] = position;
          }
        });
      }
    },

    // DELETE BOOKING
    deleteBooking(state, action) {
      const { booking, propertyId, unitTypeId, unitId } = action.payload;

      // 1. check if the property data is loaded for that time
      // 2. find the booking for the given unit and remove it
      // 3. remove unallocated booking
      // 3.1. check overlap
      // 3.2. update unallocated count

      // check if the unit is unallocated
      let isUnallocated = unitId === 'unallocated';
      let isBlockDate = booking.status === BLOCKED_STATUS_CODE;
      let propertyIndex = findIndex(state.collapses, (p: any) => p.id === propertyId);
      let unitIndex = findIndex(state.data.units, (u: any) =>
        isUnallocated ? u.id === unitTypeId && u.name === 'Unallocated' : u.id === unitId
      );
      state.data.units[unitIndex].unit.bookings = filter(
        state.data.units[unitIndex].unit.bookings,
        (b: any) => b.id !== booking.id
      );

      const positions = [
        ...getBookingPosition(
          [...state.data.units[unitIndex].unit.bookings.filter((booking) => booking.data.status === BLOCKED_STATUS_CODE).map((booking) => booking.data)],
          state.dateConfig.start,
          BLOCKED_OFFSET_TOP,
          state.showCancelled,
          new Date(state.properties[propertyIndex].LFCO),
          new Date(state.properties[propertyIndex].EFCI),
        ),
        ...getBookingPosition(
          [...state.data.units[unitIndex].unit.bookings.filter((booking) => booking.data.status !== BLOCKED_STATUS_CODE).map((booking) => booking.data)],
          state.dateConfig.start,
          BOOKING_OFFSET_TOP,
          state.showCancelled,
          new Date(state.properties[propertyIndex].LFCO),
          new Date(state.properties[propertyIndex].EFCI),
        ),
      ];

      positions.map((position) => {
        const hasBooking = findIndex(
          state.data.units[unitIndex].unit.bookings,
          (b: any) => b.id === position.id
        );
        if (hasBooking === -1) {
          state.data.units[unitIndex].unit.bookings.push(position);
        } else {
          state.data.units[unitIndex].unit.bookings[hasBooking] = position;
        }
      });

      if (isUnallocated) {
        let UT = find(state.properties[propertyIndex].unitTypes, (ut: any) => ut.id === unitTypeId);
        UT.unallocatedBookingsCount = UT.unallocatedBookingsCount - 1;
        let collapseUT = find(
          state.collapses[propertyIndex].unitTypes,
          (ut: any) => ut.id === unitTypeId
        );
        if (collapseUT) {
          collapseUT.unallocatedBookingsCount = collapseUT.unallocatedBookingsCount - 1;
        }
        state.collapses[propertyIndex].height = calculatePropertyHeight(
          state.collapses[propertyIndex],
          true,
          state.displaySettings.showPropertyRow,
          state.displaySettings.showUnitTypeRow
        );
      }
    },
    updateFinalizedStatus(state, action) {
      const { bookingId, unitTypeId, unitId, isFinalized } = action.payload;
      let isUnallocated = unitId === 'unallocated' || !!!unitId;
      
      let unitIndex = findIndex(state.data.units, (u: any) =>
        isUnallocated ? u.id === unitTypeId && u.name === 'Unallocated' : u.id === unitId
      );

      let bookingIndex = findIndex(state.data.units[unitIndex].unit.bookings, (booking: any) =>
        booking.id === bookingId
      );

      if (bookingIndex !== -1) {
        state.data.units[unitIndex].unit.bookings[bookingIndex].data.isFinalized = isFinalized;
      }
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  reset,
  openModal,
  closeModal,
  openDrawer,
  closeDrawer,
  setBookingAction,
  openDrawerAction,
  setScrollOffsetTop,
  setScrollOffsetLeft,
  addScrollOffsetLeft,
  changeUnitStatus,
  togglePropertyOpen,
  toggleUnitTypeOpen,
  toggleShowCancelled,
  toggleShowPropertyRow,
  toggleShowUnitTypeRow,
  setDisplaySettings,
  setEditRate,
  setEditRangeRate,
  updateRate,
  setDisableSearchShadow,
  findPropertyIndexByPropertyId,
  openEditBooking,
  closeEditBooking,
  updateBooking,
  updateBookings,
  unallocateBooking,
  setGeneralView,
  updateOverviewExtendedBookings,
  updateUnallocatedRow,
  addBooking,
  updateSingleBooking,
  deleteBooking,
  openBlockedDateDrawer,
  closeBlockedDateDrawer,
  updateFinalizedStatus,
} = slice.actions;

// ----------------------------------------------------------------------

export function getCalendarConfig(
  city: string,
  showCancelled: boolean,
  showPastUnallocated: boolean
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await calendarAPI.fetchCalendarConfig(
        city,
        showCancelled,
        showPastUnallocated
      );
      dispatch(slice.actions.getCalendarConfig(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setSelectedCity(city: string) {
  dispatch(slice.actions.setSelectedCity(city));
}

export function togglePropertyView(
  index: number,
  type: boolean,
  from: string,
  to: string,
  city: string,
  properties: number[],
  showCancelled: boolean,
  showUnitAvailabilities: boolean
) {
  return async () => {
    dispatch(slice.actions.togglePropertyView(index));
    try {
      if (type) {
        const response = await calendarAPI.fetchRates(from, to, city, properties);
        dispatch(slice.actions.updateRates(response.data));
      } else {
        const response = await calendarAPI.fetchBookings(from, to, city, properties, showCancelled, showUnitAvailabilities);
        dispatch(slice.actions.updateBookings(response.data));
      }
    } catch (error) {}
  };
}

export function updateUnitHeight(
  index: number,
  unitTypeIndex: number,
  unitIndex: number,
  height: number
) {
  dispatch(slice.actions.updateUnitHeight({ index, unitTypeIndex, unitIndex, height }));
}

export function updateVisibleProperties(startIndex: number, endIndex: number) {
  dispatch(slice.actions.updateVisibleProperties({ startIndex, endIndex }));
}

export function updateVisibleUnits(startIndex: number, endIndex: number) {
  dispatch(slice.actions.updateVisibleUnits({ startIndex, endIndex }));
}

export function updateVisibleDates(startIndex: number, endIndex: number) {
  dispatch(slice.actions.updateVisibleDates({ startIndex, endIndex }));
}

export function getCalendarRate(from: string, to: string, city: string, properties: number[]) {
  return async () => {
    dispatch(slice.actions.setPropertiesLoading(properties));
    try {
      const response = await calendarAPI.fetchRates(from, to, city, properties);
      dispatch(slice.actions.updateRates(response.data));
    } catch (error) {}
  };
}

export function getCalendarBooking(
  from: string,
  to: string,
  city: string,
  properties: number[],
  showCancelled: boolean,
  showUnitAvailabilities: boolean,
  removedBookingId: number | null = null
) {
  return async () => {
    dispatch(slice.actions.setPropertiesLoading(properties));
    try {
      const response = await calendarAPI.fetchBookings(from, to, city, properties, showCancelled, showUnitAvailabilities);
      dispatch(
        slice.actions.updateBookings({
          metadata: response.data.metadata,
          properties: response.data.properties,
          removedBookingId: showCancelled ? null : removedBookingId,
        })
      );
    } catch (error) {}
  };
}

export function getBookingOverview(bookingId: number) {
  return async () => {
    try {
      const response = await calendarAPI.fetchBookingOverview(bookingId);
      dispatch(slice.actions.setDrawerBooking(response.data));
    } catch (error) {}
  };
}

// selectors
// unit view selector
export const propertyViewSelector = (state: RootState): PropertyViewMode => {
  const { showPropertyRow, showUnitTypeRow } = state.oc.displaySettings;
  if (!showPropertyRow && !showUnitTypeRow) {
    return 'unit';
  }

  if (!showPropertyRow || !showUnitTypeRow) {
    return 'property';
  }

  return 'default';
};

export const countUnallocatedBookings = (state: RootState) => {
  const { properties } = state.oc;
  return properties
    .reduce(function (
      accum: {
        count: number;
      }[],
      item
    ) {
      item.unitTypes.map(
        (unitType) =>
          // unitType.units.map((unit) =>
          accum.push({
            count: unitType.unallocatedBookingsCount,
          })
        // )
      );

      return accum;
    }, [])
    .reduce(function (accum: number, item: { count: number }) {
      return accum + item.count;
    }, 0);
};
