import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import Logo from 'src/components/Logo';

type Props = {
  title: string;
  subtitle: string;
  showLogo?: boolean;
}

const AuthFormHeader: FC<Props> = ({ title, subtitle, showLogo }) => (
  <Stack alignItems="center" mb={5} gap={2}>
    {showLogo && <Logo size={56} />}
    <Stack alignItems="center" sx={{ flexGrow: 1 }}>
      <Typography variant="h4" gutterBottom>
        {title}
      </Typography>
      <Typography color="text.secondary">
        {subtitle}
      </Typography>
    </Stack>
  </Stack>
);

export default AuthFormHeader;