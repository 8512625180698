import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Skeleton(theme: Theme) {
  return {
    MuiSkeleton: {
      defaultProps: {
        animation: 'pulse',
        animationDuration: '4s',
      },

      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.neutral
        }
      }
    }
  };
}
