import { Box, Collapse, Stack } from "@mui/material";
import { FC, ReactElement } from "react";
import ExpandButton from "src/components/ExpandButton";
import Scrollbar from "src/components/Scrollbar";
import useLocales from "src/hooks/useLocales";
import { ColorSchema } from "src/theme/palette";
import StyledPaper from "../../sections/@dashboard/listing/StyledPaper";


type Props = {
  list: any[];
  expanded: boolean;
  color: ColorSchema;
  alwaysOpen?: boolean;
  toggleExpanded: () => void;
  renderLinkRow: (item: any) => ReactElement;
  expandButtonTitle: string;
  expandButtonExpandedTitle?: string;
};

const LinkRowsCollapse: FC<Props> = ({
  expanded,
  toggleExpanded,
  expandButtonTitle,
  expandButtonExpandedTitle,
  alwaysOpen,
  list,
  color,
  renderLinkRow
}) => (
  <Stack gap={1}>
    {!alwaysOpen && (
      <ExpandButton
        showEndIcon
        disableResponsive
        expanded={expanded}
        onClick={toggleExpanded}
        title={`${expandButtonTitle} (${list.length})`}
        expandedTitle={`${expandButtonExpandedTitle || expandButtonTitle} (${list.length})`}
      />
    )}
    <Collapse in={alwaysOpen ? true : expanded} easing={'in-out'}>
      <StyledPaper variant='outlined' color={color}>
        <Scrollbar sx={{ maxHeight: 208 }}>
          <Stack gap={1} px={2} py={1.5}>
            {list.map((item, index) => (
              <Box key={index}>
                {renderLinkRow(item)}
              </Box>
            ))}
          </Stack>
        </Scrollbar>
      </StyledPaper>
    </Collapse>
  </Stack>
);

export default LinkRowsCollapse;