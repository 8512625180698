import { dispatch } from "../store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// api
import invoiceAPI from "src/api/invoice";
// @types
import { HeaderInfo, InvoiceLine, PageState, PriceBreakdown, SummaryInfo } from "src/@types/invoice";
// utils
import {
  headerInfoNormalizer,
  invoiceLineNormalizer,
  priceBreakdownNormalizer,
  SummaryInfoNormalizer
} from "src/utils/invoice";

const initialState: PageState = {
  loading: false,
  headerInfo: null,
  summaryInfo: null,
  priceBreakdown: null,
  invoiceLines: [],
}

const slice = createSlice({
  name: 'invoiceView',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setHeaderInfo(state, action: PayloadAction<{ data: HeaderInfo | null }>) {
      state.headerInfo = action.payload.data;
    },
    setSummaryInfo(state, action: PayloadAction<{ data: SummaryInfo | null }>) {
      state.summaryInfo = action.payload.data;
    },
    setPriceBreakdown(state, action: PayloadAction<{ data: PriceBreakdown | null }>) {
      state.priceBreakdown = action.payload.data;
    },
    setInvoiceLines(state, action: PayloadAction<{ data: InvoiceLine[] }>) {
      state.invoiceLines = action.payload.data;
    },
    setDocDate(state, action: PayloadAction<{ data: string | Date }>) {
      if (state.summaryInfo) state.summaryInfo.docDate = action.payload.data;
    },
    setXeroAmountType(state, action: PayloadAction<{ data: string }>) {
      if (state.summaryInfo) state.summaryInfo.xeroType = action.payload.data;
    },
    setTotalDue(state, action: PayloadAction<{ value: number, currency: string }>) {
      if (state.summaryInfo) {
        state.summaryInfo.totalDue.value = action.payload.value;
        state.summaryInfo.totalDue.currency = action.payload.currency;
      }
    },
    setInvoicePrintSettings(state, action: PayloadAction<{ bankAccountInfo: boolean, paymentInfo: boolean, securityDepositInfo: boolean }>) {
      if (state.summaryInfo) {
        state.summaryInfo.invoicePrintSettings = action.payload;
      }
    },
    setUpdatedAtDate(state, action: PayloadAction<{ data: string }>) {
      if (state.headerInfo) {
        state.headerInfo.updatedAt = action.payload.data;
      }
    }
  }
})

// Reducer
export default slice.reducer;

// Actions
export const {
  setLoading,
  setDocDate,
  setXeroAmountType,
  setTotalDue,
  setInvoicePrintSettings,
  setUpdatedAtDate
} = slice.actions;

export function getInvoiceDetails(invoiceId: number) {
  return async () => {
    dispatch(slice.actions.setLoading(true));
    try {
      const response = await invoiceAPI.fetchInvoice(invoiceId);
      dispatch(slice.actions.setHeaderInfo({ data: response.data ? headerInfoNormalizer(response.data) : null }));
      dispatch(slice.actions.setSummaryInfo({ data: response.data ? SummaryInfoNormalizer(response.data) : null }));
      dispatch(slice.actions.setPriceBreakdown({ data: response.data ? priceBreakdownNormalizer(response.data) : null }));
      dispatch(slice.actions.setInvoiceLines({
        data: response.data ? response.data.invoice_lines.map(item => invoiceLineNormalizer(item)) : []
      }));

    } catch (error) {
    }
    dispatch(slice.actions.setLoading(false));
  }
}
