import { ComponentType } from 'react';
import { DateRangePickerDay, DateRangePickerDayProps } from '@mui/x-date-pickers-pro/DateRangePickerDay';
import { alpha, styled } from '@mui/material/styles';

export type CustomRangeDayProps = {
  isStartOfWarning?: boolean;
  isEndOfWarning?: boolean;
  isWarning?: boolean;
  isIncluded?: boolean;
};

const DateRangePickerDayStyle = styled(DateRangePickerDay, {
  shouldForwardProp: (prop) =>
    prop !== 'isStartOfWarning' &&
    prop !== 'isEndOfWarning' &&
    prop !== 'isWarning' &&
    prop !== 'isIncluded',
})<CustomRangeDayProps & Partial<DateRangePickerDayProps<Date>>>(
  ({
     theme,
     isHighlighting,
     isStartOfHighlighting,
     isEndOfHighlighting,
     isStartOfWarning = false,
     isEndOfWarning = false,
     isWarning = false,
     isIncluded = false,
   }) => ({
    ...(isHighlighting && {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      '> div > .MuiDateRangePickerDay-day': {
        color: isWarning ? theme.palette.warning.dark : theme.palette.text.primary,
      },
    }),
    ...(isStartOfHighlighting && {
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
      '> div > .MuiDateRangePickerDay-day': {
        color: isWarning ? theme.palette.warning.dark : theme.palette.common.white,
        '&:hover': {
          color: theme.palette.common.white,
        },
      },
    }),
    ...(isEndOfHighlighting && {
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
      '> div > .MuiDateRangePickerDay-day': {
        color: isWarning ? theme.palette.warning.dark : theme.palette.common.white,
      },
    }),
    ...(isWarning && {
      '> div > .MuiDateRangePickerDay-day': {
        color: `${theme.palette.warning.dark} !important`,
      },
    }),
    ...(isStartOfWarning && {
      '> div > .MuiDateRangePickerDay-day': {
        color: theme.palette.warning.dark,
      },
    }),
    ...(isEndOfWarning && {
      '> div > .MuiDateRangePickerDay-day': {
        color: theme.palette.warning.dark,
      },
    }),
    '& .MuiDateRangePickerDay-rangeIntervalDayPreview': {
      backgroundColor: isWarning ? alpha(theme.palette.warning.main, 0.08) : alpha(theme.palette.primary.main, 0.08),
    },
    '& .MuiPickersDay-today': {
      border: 'none !important',
      backgroundColor: theme.palette.background.neutral,
    },
    '&:first-of-type': {
      borderTopLeftRadius: '20%',
      borderBottomLeftRadius: '20%',
      '& .MuiDateRangePickerDay-rangeIntervalPreview': {
        borderTopLeftRadius: '20%',
        borderBottomLeftRadius: '20%',
      }
    },
    '&:last-of-type': {
      borderTopRightRadius: '20%',
      borderBottomRightRadius: '20%',
      '& .MuiDateRangePickerDay-rangeIntervalPreview': {
        borderTopRightRadius: '20%',
        borderBottomRightRadius: '20%',
      }
    },
    '& .MuiDateRangePickerDay-day': {
      '&:hover': {
        backgroundColor: 'transparent',
        color: isWarning ? theme.palette.warning.dark : theme.palette.primary.main,
        border: isWarning ? `1px solid ${theme.palette.warning.main} !important` : `1px solid ${theme.palette.primary.main} !important`,
      },
    },
  })
) as ComponentType<DateRangePickerDayProps<Date> & CustomRangeDayProps>;

export default DateRangePickerDayStyle;