import { ReactNode, createContext, useState, useCallback, useEffect, useRef } from 'react';
// @type
import { WebsocketContextProps, Message, Subscription } from 'src/@types/websocket';
// api
import API from 'src/api/api';
// hooks
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

const initialState: WebsocketContextProps = {
  url: `${process.env.REACT_APP_HOST_WS}`,
  isReconnecting: false,
  emit: () => {},
  subscribe: () => {},
  unSubscribe: () => {},
};

const WebsocketContext = createContext(initialState);

type WebsocketProviderProps = {
  children: ReactNode;
};

const RECONNECT_INTERVAL = 5000;
const MAX_RETRY = 3;

function WebsocketProvider({ children }: WebsocketProviderProps) {
  const { isAuthenticated } = useAuth();
  const [state, setState] = useState<WebsocketContextProps>(initialState);
  const socket = useRef<WebSocket | null>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [hasDisconnected, setHasDisconnected] = useState<boolean>(false);
  const messageQueue = useRef<Message[]>([]);
  const subscriptions = useRef<Subscription[]>([]);
  const retryCount = useRef<number>(0);
  const retryTimeoutRef = useRef<any>();

  const emit = useCallback(
    (data: Message) => {
      if (isConnected) socket.current?.send(data);
      else messageQueue.current.push(data);
    },
    [socket, isConnected]
  );

  const subscribe = useCallback(
    (subscription: Subscription) => {
      subscriptions.current.push(subscription);
      if (isConnected) {
        socket.current?.send(`[5, "${subscription.namespace}"]`);
      }
    },
    [socket, isConnected]
  );

  const unSubscribe = useCallback(
    (namespace: string) => {
      const index = subscriptions.current.findIndex((item) => item.namespace === namespace);

      if (index !== -1) {
        subscriptions.current.splice(index, 1);
        if (isConnected) {
          socket.current?.send(`[5, "${namespace}","#"]`); // Unsubscribe message
        }
      }
    },
    [socket, isConnected]
  );

  const close = useCallback(() => {
    socket.current?.close();
    socket.current = null;
  }, []);

  useEffect(() => {
    const fetchTicket = async () => {
      if (isAuthenticated) {
        await API.fetchWebsocketTicket()
          .then((response) => {
            const { ticket } = response.data;

            socket.current = new WebSocket(`${state.url}${ticket}`);

            socket.current.onopen = (event: Event) => {
              // @ts-ignore
              socket.current?.send('[5,"oro/ping"]');

              setIsConnected(true);
              setHasDisconnected(false);
              retryCount.current = 0;
            };

            socket.current.onclose = (event: CloseEvent) => {
              setIsConnected(false);
              if (process.env.NODE_ENV === 'production' && isAuthenticated && retryCount.current < MAX_RETRY) {
                retryCount.current = retryCount.current + 1;
                setHasDisconnected(true);
                retryTimeoutRef.current = setTimeout(fetchTicket, RECONNECT_INTERVAL);
              } else {
                setHasDisconnected(false);
              }
            };
          })
          .catch(() => {
            if (process.env.NODE_ENV === 'production' && isAuthenticated && retryCount.current < MAX_RETRY) {
              retryCount.current = retryCount.current + 1;
              setHasDisconnected(true);
              retryTimeoutRef.current = setTimeout(fetchTicket, RECONNECT_INTERVAL);
            } else {
              setHasDisconnected(false);
            }
          });
      }
    };

    if (isAuthenticated) {
      fetchTicket();
    } else {
      close();
      retryCount.current = MAX_RETRY + 1;
      setHasDisconnected(false);
    }

    return function closeWebsocket() {
      close();
      subscriptions.current = [];
      retryCount.current = 0;
      clearTimeout(retryTimeoutRef.current);
    };
  }, [state, isAuthenticated]);

  useEffect(() => {
    if (isConnected) {
      messageQueue.current.splice(0).forEach((message) => {
        socket.current?.send(message);
      });

      subscriptions.current.forEach((subscription) => {
        socket.current?.send(`[5, "${subscription.namespace}"]`);
        if (hasDisconnected) {
          subscription.onReconnect?.();
        }
      });

      // @ts-ignore
      socket.current.onmessage = (event) => {
        try {
          subscriptions.current.forEach((subscription) => {
            if (JSON.parse(event.data)[1] === subscription.namespace) {
              subscription.onEvent(JSON.parse(event.data)[2]);
            }
          });
        } catch (error) {}
      };
    }
  }, [isConnected, hasDisconnected]);

  return (
    <WebsocketContext.Provider
      value={{
        ...state,
        isReconnecting: !isConnected && hasDisconnected,
        emit,
        subscribe,
        unSubscribe,
      }}
    >
      {children}
    </WebsocketContext.Provider>
  );
}

export { WebsocketProvider, WebsocketContext };
