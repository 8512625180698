import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation, Outlet } from 'react-router-dom';
// guards
import useAuth from '../hooks/useAuth';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// config
import { OUT_OF_DASHBOARD_PATHS } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import SignupGuard from 'src/guards/SignupGuard';
// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const pathnameStartsWithADashboardPath = !OUT_OF_DASHBOARD_PATHS.some(
    (path) => pathname.indexOf(path) === 0
  );
  const isDashboard = pathnameStartsWithADashboardPath && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        // {
        //   path: 'register',
        //   element: (
        //       <GuestGuard>
        //         <Register />
        //       </GuestGuard>
        //   ),
        // },
        // { path: 'login-unprotected', element: <Login /> },
        // { path: 'register-unprotected', element: <Register /> },
        {
          path: 'reset-password',
          children: [
            { index: true, element: <ResetPassword /> },
            { path: ':token', element: <ResetPassword /> },
          ],
        },
        // { path: 'verify', element: <VerifyCode /> },
      ],
    },
    {
      path: 'sign_up_not_complete',
      children: [
        {
          index: true,
          element: <CompleteSignupWizard />,
        },
      ],
    },
    {
      path: 'marketing-website',
      element: (
        <SignupGuard>
          <Outlet />
        </SignupGuard>
      ),
      children: [
        {
          element: (
            // <SignupGuard>
            <MarketingListingURLDemo />
            // </SignupGuard>
          ),
          index: true,
        },
        { path: 'demo', element: <MarketingListingURLDemo /> },
        { path: 'invalid-url', element: <MarketingInvalidURL /> },
        { path: 'failed-import', element: <MarketingFailedImport /> },
        {
          path: ':url',
          element: <MarketingSignupWizardPage />,
        },
      ],
    },
    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Dashboard />,
          index: true,
        },
        { path: 'sales', element: <GeneralAnalytics /> },
        { path: 'property/create', element: <PropertyWizard /> },
        { path: 'performance', element: <GeneralReport /> },
        {
          children: [
            { path: 'mprs', element: <MonthlyProfitReportListView /> },
            { path: 'owner/mprs', element: <InvestorMonthlyProfitReportListView /> },
          ],
        },
        { path: 'listings', element: <Listings /> },
        { path: 'listings/archive', element: <ArchivedListings /> },
        { path: 'listing/settings', element: <ListingsDefaultSettings /> },
        {
          path: 'property',
          children: [
            { element: <Navigate to="/listings" replace />, index: true },
            { path: ':propertyId', element: <ListingPage viewKey="property" /> },
            { path: ':propertyId/:tab', element: <ListingPage viewKey="property" /> },
          ],
        },
        {
          path: 'unit-type',
          children: [
            { element: <Navigate to="/listings" replace />, index: true },
            { path: ':unitTypeId', element: <ListingPage viewKey="unitType" /> },
            { path: ':unitTypeId/:tab', element: <ListingPage viewKey="unitType" /> },
          ],
        },
        {
          path: 'unit',
          children: [
            { element: <Navigate to="/listings" replace />, index: true },
            { path: ':unitId', element: <ListingPage viewKey="unit" /> },
            { path: ':unitId/:tab', element: <ListingPage viewKey="unit" /> },
          ],
        },
        {
          path: 'rate-rules',
          element: <RateRules />,
        },
        {
          path: 'rate-rule',
          children: [
            { element: <Navigate to="/rate-rules" replace />, index: true },
            // { path: ':id', element: <RateRule /> },
            // { path: 'create', element: <RateRuleCreate /> },
          ],
        },
        // {
        //   path: 'user',
        //   children: [
        //     { element: <Navigate to="/user/account" replace />, index: true },
        // { path: 'profile', element: <UserProfile /> },
        // { path: 'cards', element: <UserCards /> },
        // { path: 'list', element: <UserList /> },
        // { path: 'new', element: <UserCreate /> },
        // { path: ':name/edit', element: <UserCreate /> },
        // { path: 'account', element: <UserAccount />, index: true },
        // { path: 'account/:currentTab', element: <UserAccount /> },
        // { path: 'account/upgrade/:id', element: <AccountUpgradePlan /> },
        // { path: 'account/buy-more-licenses/:id', element: <AccountBuyMoreLicenses /> },
        // { path: 'financial-history', element: <UserFinance /> },
        // ],
        // },
        {
          path: 'booking',
          children: [
            { element: <Booking />, index: true },
            { path: 'list', element: <BookingListView /> },
            {
              path: ':bookingId',
              children: [
                { element: <Booking />, index: true },
                { path: ':tab', element: <Booking /> },
              ],
            },
          ],
        },
        {
          path: 'booking_import',
          children: [
            { element: <ImportBookingList />, index: true },
            { path: ':bookingImportId', element: <ImportBookingView /> },
          ],
        },
        {
          path: 'booking-request',
          children: [
            { element: <BookingRequest />, index: true },
            {
              path: ':bookingRequestId',
              children: [
                { element: <BookingRequest />, index: true },
                { path: ':tab', element: <BookingRequest /> },
              ],
            },
          ],
        },
        {
          path: 'booking/guest',
          children: [
            { element: <GuestGrid />, index: true },
            {
              path: ':guestId',
              children: [
                { element: <Guest />, index: true },
                { path: ':tab', element: <Guest /> },
              ],
            },
          ],
        },
        {
          children: [
            {
              path: 'hands',
              element: <HandsList />,
              index: true,
            },
            {
              path: 'hand/:handId',
              children: [{ element: <HandView />, index: true }],
            },
          ],
        },
        {
          children: [
            {
              path: 'management',
              children: [
                { element: <StaffList />, index: true },
                { path: ':tab', element: <StaffList /> },
              ],
            },
            {
              path: 'management/staff/:staffId',
              children: [{ element: <StaffView />, index: true }],
            },
          ],
        },
        {
          children: [
            { path: 'owners', element: <InvestorListView />, index: true },
            {
              path: 'owner/:investorId',
              children: [{ element: <InvestorView />, index: true }],
            },
          ],
        },
        {
          path: 'booking/corporation',
          children: [
            { element: <CompanyGrid />, index: true },
            {
              path: ':corporationId',
              children: [
                { element: <CorporationGuest />, index: true },
                { path: ':tab', element: <CorporationGuest /> },
              ],
            },
          ],
        },
        {
          path: 'booking/review',
          children: [{ element: <MarketingDashboard tab="reviews" />, index: true }],
        },
        {
          path: 'bookings',
          children: [
            { element: <BookingAnalytics />, index: true },
            { path: 'confirmation-page-settings', element: <ConfirmationPageSettings /> },
            {
              path: 'confirmation-page-settings/:brandName',
              element: <ConfirmationPageSettings />,
            },
          ],
        },
        { path: 'calendar', element: <OcCalendar /> },
        {
          path: 'blockdates',
          children: [
            { element: <BlockedDates />, index: true },
            { path: ':tab', element: <BlockedDates /> },
          ],
        },
        {
          path: 'inbox',
          children: [
            { element: <Inbox />, index: true },
            {
              path: 'saved-responses',
              children: [
                { element: <SavedResponses />, index: true },
                { path: 'create', element: <SavedResponseNew mode="create" /> },
                { path: 'edit/:id', element: <SavedResponseNew mode="edit" /> },
              ],
            },
          ],
        },
        {
          path: 'automated-messages',
          children: [
            { element: <AutomatedMessage />, index: true },
            { path: 'create', element: <AutomatedMessageNew /> },
            { path: 'edit/:automatedMessageId', element: <AutomatedMessageNew /> },
          ],
        },
        { path: 'tasks', element: <Tasks /> },
        {
          path: 'task-list',
          children: [
            { element: <Navigate to="/task-list/housekeeping" replace />, index: true },
            { path: 'housekeeping', element: <Housekeeping /> },
          ],
        },
        {
          path: 'task',
          children: [
            { element: <Navigate to="/tasks" replace />, index: true },
            { path: ':taskId', element: <TaskView /> },
            { path: 'maintenance', element: <MaintenanceTaskList /> },
          ],
        },
        {
          path: 'account',
          children: [
            { element: <Account />, index: true },
            { path: 'upgrade/:id', element: <AccountUpgradePlan /> },
            { path: 'buy-more-licenses/:id', element: <AccountBuyMoreLicenses /> },
            { path: 'payment/result/:id', element: <PaymentResult /> },
            { path: 'payment/:id', element: <LicensePayment /> },
            { path: 'invoice/:id', element: <InvoiceDetails /> },
            { path: ':tab', element: <Account /> },
          ],
        },
        {
          path: 'profile',
          children: [
            { element: <Profile />, index: true },
            { path: ':tab', element: <Profile /> },
          ],
        },
        {
          path: 'promotions',
          children: [
            { element: <Promotions />, index: true },
            { path: 'archive', element: <Promotions archived /> },
          ],
        },
        {
          path: 'promotion',
          children: [
            { element: <Navigate to="/promotions" replace />, index: true },
            { path: ':id', element: <Promotion /> },
            { path: 'create', element: <PromotionCreate /> },
          ],
        },
        {
          path: 'upsells',
          element: <Upsells />,
        },
        {
          path: 'preferences',
          children: [
            { element: <Preferences />, index: true },
            { path: ':brandName', element: <Preferences /> },
          ],
        },

        {
          path: 'integrations',
          children: [
            { element: <Integrations />, index: true },
            { path: 'keynest/:keyNestId', element: <KeySetView /> },
            { path: 'remote-lock/:remoteLockId', element: <RemoteLockView /> },
            { path: 'partner/4', element: <XeroIntegrationPartnerPage /> },
            { path: 'partner/13', element: <ClicksendIntegrationPartnerPage /> },
            { path: 'partner/5', element: <KeyNestIntegrationPartnerPage /> },
            { path: 'partner/31', element: <RemoteLockIngrationPartnerPage /> },
            { path: 'partner/3', element: <StripeIntegrationPartnerPage /> },
            { path: 'partner/19', element: <GoogleItemsAndiFrameIntegration id={19} /> },
            { path: 'partner/20', element: <GoogleItemsAndiFrameIntegration id={20} /> },
            { path: 'partner/16', element: <GoogleItemsAndiFrameIntegration id={16} /> },
            { path: 'partner/15', element: <GoogleItemsAndiFrameIntegration id={15} /> },
            { path: 'partner/17', element: <GoogleItemsAndiFrameIntegration id={17} /> },
            { path: 'partner/:id', element: <IntegrationPartnerPage /> },
            { path: 'check-xero', element: <RedirectPage redirectType="xero" /> },
            {
              path: 'check-stripe/:integrationId',
              element: <RedirectPage redirectType="stripe" />,
            },
            { path: 'check-keynest', element: <RedirectPage redirectType="keynest" /> },
            { path: 'check-remotelock', element: <RedirectPage redirectType="remotelock" /> },
          ],
        },
        {
          path: 'integration/xero',
          children: [
            { path: 'tax_schema', element: <XeroTaxSchema /> },
            { path: 'tracking_category', element: <XeroTrackingCategories /> },
            { path: 'accounting_schema', element: <XeroAccountingSchemaListView /> },
            { path: 'chart_of_account', element: <XeroAccountListView /> },
          ],
        },

        {
          path: 'tax',
          children: [
            { element: <TaxView />, index: true },
            { path: 'create', element: <TaxCreate /> },
            { path: 'update/:taxRateId', element: <TaxCreate /> },
          ],
        },
        {
          path: 'setting',
          children: [
            { element: <OrganisationConfiguration />, index: true },
            {
              path: ':brandId',
              element: <OrganisationConfiguration />,
              children: [{ path: ':tab', element: <OrganisationConfiguration /> }],
            },
          ],
        },
        {
          path: 'owner',
          children: [
            { path: 'contracts', element: <DealListView /> },
            { path: 'contract/:deal_id', element: <DealView /> },
            { path: 'packages', element: <ManagementPackageList /> },
          ],
        },
        {
          children: [
            {
              path: 'payments',
              element: <PaymentListView />,
              index: true,
            },
            {
              path: 'payment/:paymentId',
              children: [{ element: <PaymentView />, index: true }],
            },
          ],
        },
        {
          path: 'refund/types',
          children: [{ index: true, element: <RefundTypesListView /> }],
        },
        {
          children: [
            {
              path: 'refunds',
              element: <RefundListView />,
              index: true,
            },
            {
              path: 'refund/:refundId',
              children: [{ element: <RefundView />, index: true }],
            },
          ],
        },
        {
          path: 'expense/types',
          children: [{ index: true, element: <ExpenseTypesListView /> }],
        },
        {
          children: [
            { path: 'expenses', element: <ExpenseListView /> },
            { path: 'expense/create', element: <ExpenseCreate /> },
            { path: 'expense/create/batch', element: <ExpenseBatchCreate /> },
            { path: 'expense/:bill_id', element: <ExpensesView /> },
            { path: 'expense/clone/:bill_id', element: <ExpenseClone /> },
          ],
        },
        {
          children: [
            { path: 'invoices', element: <InvoiceListView /> },
            { path: 'invoice/:invoiceId', element: <InvoiceView /> },
          ],
        },
        {
          path: 'marketing',
          children: [
            { element: <MarketingDashboard />, index: true },
            {
              path: 'websites',
              children: [
                {
                  element: <MarketingDashboard tab="websites" />,
                  index: true,
                },
                {
                  path: ':brandId',
                  element: <Marketing />,
                  children: [
                    {
                      path: ':tab',
                      element: <Marketing />,
                      children: [
                        {
                          path: ':subTab',
                          element: <Marketing />,
                          children: [
                            {
                              path: ':pageId',
                              element: <Marketing />,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              path: 'reviews',
              children: [{ element: <MarketingDashboard tab="reviews" />, index: true }],
            },
            {
              path: 'newsletter_subscriptions',
              children: [
                {
                  element: <MarketingDashboard tab="newsletter_subscriptions" />,
                  index: true,
                },
              ],
            },
          ],
        },
        {
          children: [
            { path: 'mpr/:mprId', element: <HostMonthlyProfitReport /> },
            { path: 'owner/mpr/:mprId', element: <InvestorMonthlyProfitReport /> },
          ],
        },
        { path: 'issues', element: <IssueList /> },
        {
          children: [
            { path: 'suppliers', element: <SupplierList /> },
            { path: 'supplier/:supplierId', element: <SupplierView /> },
          ],
        },
        { path: 'leaves', element: <LeaveList /> },
        { path: 'expense_import/:expenseImportId', element: <ImportExpenseView /> },
      ],
    },
    {
      path: 'listings/import',
      children: [
        {
          path: ':wizardId',
          element: (
            <AuthGuard>
              <ListingWizardPage />
            </AuthGuard>
          ),
        },
        {
          path: 'no_license',
          element: <NoLicensePage />,
        },
        {
          path: 'success',
          element: <SuccessfulPage />,
        },
        {
          path: 'success/:propertyId',
          element: <SuccessfulPage />,
        },
      ],
    },
    {
      path: 'wizard',
      children: [
        {
          path: 'multi-rate',
          children: [
            { element: <Navigate to="/multi-rate/nightly" replace />, index: true },
            { path: 'nightly', element: <MultiRateWizardPage rateType="nightly" /> },
            { path: 'weekly', element: <MultiRateWizardPage rateType="weekly" /> },
            { path: 'monthly', element: <MultiRateWizardPage rateType="monthly" /> },
          ],
        },
        {
          path: 'property',
          element: (
            <AuthGuard>
              <PropertyWizardPage />
            </AuthGuard>
          ),
        },
        {
          path: 'booking',
          children: [
            {
              element: (
                <AuthGuard>
                  <BookingWizardPage />
                </AuthGuard>
              ),
              index: true,
            },
            { path: 'success', element: <SuccessForm /> },
            { path: 'success/:bookingId', element: <SuccessForm /> },
            { path: 'error', element: <ErrorForm /> },
            { path: 'error/:errorCode', element: <ErrorForm /> },
          ],
        },
        {
          path: 'create-unit-type/:property',
          element: (
            <AuthGuard>
              <CreateUnitTypeWizard />
            </AuthGuard>
          ),
        },
        {
          path: 'create/property',
          children: [
            {
              element: (
                <AuthGuard>
                  <CreatePropertyWizard />
                </AuthGuard>
              ),
              index: true,
            },
            {
              path: ':wizardId',
              element: (
                <AuthGuard>
                  <CreatePropertyWizard />
                </AuthGuard>
              ),
            },
            {
              path: 'no_license',
              element: <NoLicensePage />,
            },
            {
              path: 'success',
              element: <SuccessfulPage />,
            },
            {
              path: 'success/:propertyId',
              element: <SuccessfulPage />,
            },
          ],
        },
      ],
    },
    {
      path: 'channel-manager',
      element: (
        <AuthGuard>
          <RentalsUnitedAppPage />
        </AuthGuard>
      ),
    },

    {
      path: '*',
      // element: <LogoOnlyLayout/>,
      children: [
        // { path: 'coming-soon', element: <ComingSoon /> },
        // { path: 'maintenance', element: <Maintenance /> },
        { path: '503', element: <ErrorPage code="503" /> },
        { path: '500', element: <ErrorPage code="500" /> },
        { path: '400', element: <ErrorPage code="400" /> },
        { path: '403', element: <ErrorPage code="403" /> },
        { path: '404', element: <ErrorPage code="404" /> },
        { path: '*', element: <ErrorPage code="404" /> },
      ],
    },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
// const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
// const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const Dashboard = Loadable(lazy(() => import('../pages/dashboard/Dashboard')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const PropertyWizard = Loadable(lazy(() => import('../pages/wizard/PropertyWizardPage')));
const CreateUnitTypeWizard = Loadable(lazy(() => import('../pages/wizard/CreateUnitTypeWizard')));
const CreatePropertyWizard = Loadable(
  lazy(() => import('../pages/wizard/CreatePropertyWizardPage'))
);
const Booking = Loadable(lazy(() => import('../pages/dashboard/Booking')));
const BookingRequest = Loadable(lazy(() => import('../pages/dashboard/BookingRequest')));
const BookingAnalytics = Loadable(lazy(() => import('../pages/dashboard/BookingAnalytics')));
const BookingListView = Loadable(lazy(() => import('../pages/dashboard/BookingListView')));
const ImportBookingView = Loadable(lazy(() => import('../pages/dashboard/ImportBookingView')));
const ImportBookingList = Loadable(lazy(() => import('../pages/dashboard/ImportBookingList')));
const DealListView = Loadable(lazy(() => import('../pages/dashboard/DealListView')));
const DealView = Loadable(lazy(() => import('../pages/dashboard/DealView')));
const InvoiceListView = Loadable(lazy(() => import('../pages/dashboard/InvoiceListView')));
const InvoiceView = Loadable(lazy(() => import('../pages/dashboard/InvoiceView')));
const ExpenseListView = Loadable(lazy(() => import('../pages/dashboard/ExpenseListView')));
const ExpenseTypesListView = Loadable(
  lazy(() => import('../pages/dashboard/ExpenseTypesListView'))
);
const PaymentListView = Loadable(lazy(() => import('../pages/dashboard/PaymentListView')));
const PaymentView = Loadable(lazy(() => import('../pages/dashboard/PaymentView')));
const RefundListView = Loadable(lazy(() => import('../pages/dashboard/RefundListView')));
const RefundTypesListView = Loadable(lazy(() => import('../pages/dashboard/RefundTypesListView')));
const RefundView = Loadable(lazy(() => import('../pages/dashboard/RefundView')));
const GeneralReport = Loadable(lazy(() => import('../pages/dashboard/GeneralReport')));
const MonthlyProfitReportListView = Loadable(
  lazy(() => import('../pages/dashboard/MonthlyProfitReportListView'))
);
const InvestorMonthlyProfitReportListView = Loadable(
  lazy(() => import('../pages/dashboard/InvestorMonthlyProfitReportListView'))
);
const XeroIntegrationPartnerPage = Loadable(
  lazy(() => import('../pages/dashboard/XeroIntegrationPartner'))
);
const StripeIntegrationPartnerPage = Loadable(
  lazy(() => import('../pages/dashboard/StripeIntegrationPartner'))
);
const ClicksendIntegrationPartnerPage = Loadable(
  lazy(() => import('../pages/dashboard/ClicksendIntegration'))
);
const GoogleItemsAndiFrameIntegration = Loadable(
  lazy(() => import('../pages/dashboard/GoogleItemsAndiFrameIntegration'))
);
const XeroTaxSchema = Loadable(lazy(() => import('../pages/dashboard/XeroTaxSchema')));
const XeroTrackingCategories = Loadable(
  lazy(() => import('../pages/dashboard/XeroTrackingCategories'))
);
const XeroAccountingSchemaListView = Loadable(
  lazy(() => import('../pages/dashboard/XeroAccountingSchemaListView'))
);
const XeroAccountListView = Loadable(lazy(() => import('../pages/dashboard/XeroAccountListView')));
const Account = Loadable(lazy(() => import('../pages/dashboard/Account')));
const Profile = Loadable(lazy(() => import('../pages/dashboard/Profile')));
const Promotion = Loadable(lazy(() => import('../pages/dashboard/Promotion')));
const PromotionCreate = Loadable(lazy(() => import('../pages/dashboard/PromotionCreate')));
const Promotions = Loadable(lazy(() => import('../pages/dashboard/Promotions')));
const Preferences = Loadable(lazy(() => import('../pages/dashboard/Preferences')));
const Upsells = Loadable(lazy(() => import('../pages/dashboard/Upsells')));
// const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
// const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
// const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
// const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
// const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
// const UserFinance = Loadable(lazy(() => import('../pages/dashboard/UserFinance')));
const Integrations = Loadable(lazy(() => import('../pages/dashboard/Integrations')));
const LicensePayment = Loadable(
  lazy(() => import('src/sections/@dashboard/user/account/LicensePayment'))
);
const PaymentResult = Loadable(
  lazy(() => import('src/sections/@dashboard/user/account/PaymentResult'))
);
const OcCalendar = Loadable(lazy(() => import('../pages/dashboard/OcCalendar')));
const BlockedDates = Loadable(lazy(() => import('../pages/dashboard/BlockedDates')));
const Inbox = Loadable(lazy(() => import('../pages/dashboard/Inbox')));
const SavedResponses = Loadable(lazy(() => import('../pages/dashboard/SavedResponses')));
const SavedResponseNew = Loadable(lazy(() => import('../pages/dashboard/SavedResponseNew')));
const AutomatedMessage = Loadable(lazy(() => import('../pages/dashboard/AutomatedMessage')));
const AutomatedMessageNew = Loadable(lazy(() => import('../pages/dashboard/AutomatedMessageNew')));
const Tasks = Loadable(lazy(() => import('../pages/dashboard/Tasks')));
const Housekeeping = Loadable(lazy(() => import('../pages/dashboard/TasksHousekeeping')));
const TaskView = Loadable(lazy(() => import('../pages/dashboard/TaskView')));
const MaintenanceTaskList = Loadable(lazy(() => import('../pages/dashboard/MaintenanceTaskList')));
const ConfirmationPageSettings = Loadable(
  lazy(() => import('../pages/dashboard/ConfirmationPageSettings'))
);
const Listings = Loadable(lazy(() => import('../pages/dashboard/Listings')));
const ArchivedListings = Loadable(lazy(() => import('../pages/dashboard/ArchivedListings')));
const ListingPage = Loadable(lazy(() => import('../pages/dashboard/Listing')));
const ListingsDefaultSettings = Loadable(
  lazy(() => import('../pages/dashboard/ListingsDefaultSettings'))
);
const RateRule = Loadable(lazy(() => import('../pages/dashboard/RateRule')));
const RateRules = Loadable(lazy(() => import('../pages/dashboard/RateRules')));
const RateRuleCreate = Loadable(lazy(() => import('../pages/dashboard/RateRuleCreate')));
const TaxView = Loadable(lazy(() => import('../pages/dashboard/TaxView')));
const TaxCreate = Loadable(lazy(() => import('../pages/dashboard/TaxCreate')));
const Guest = Loadable(lazy(() => import('../pages/dashboard/Guest')));
const GuestGrid = Loadable(lazy(() => import('../pages/dashboard/GuestGrid')));
const CompanyGrid = Loadable(lazy(() => import('../pages/dashboard/CompanyGrid')));
const StaffList = Loadable(lazy(() => import('../pages/dashboard/StaffList')));
const StaffView = Loadable(lazy(() => import('../pages/dashboard/StaffView')));
const HandsList = Loadable(lazy(() => import('../pages/dashboard/HandsList')));
const HandView = Loadable(lazy(() => import('../pages/dashboard/HandView')));
const ExpensesView = Loadable(lazy(() => import('../pages/dashboard/ExpensesView')));
const ExpenseCreate = Loadable(lazy(() => import('../pages/dashboard/ExpenseCreate')));
const ExpenseBatchCreate = Loadable(lazy(() => import('../pages/dashboard/ExpenseBatchCreate')));
const ExpenseClone = Loadable(lazy(() => import('../pages/dashboard/ExpenseClone')));
const InvestorView = Loadable(lazy(() => import('../pages/dashboard/InvestorView')));
const InvestorListView = Loadable(lazy(() => import('../pages/dashboard/InvestorListView')));
const CorporationGuest = Loadable(lazy(() => import('../pages/dashboard/Corporation')));
const OrganisationConfiguration = Loadable(
  lazy(() => import('../pages/dashboard/OrganizationConfig'))
);
const Marketing = Loadable(lazy(() => import('../pages/dashboard/Marketing')));
const MarketingDashboard = Loadable(lazy(() => import('../pages/dashboard/MarketingDashboard')));
const ManagementPackageList = Loadable(lazy(() => import('../pages/dashboard/ManagementPackages')));
const IssueList = Loadable(lazy(() => import('../pages/dashboard/IssueList')));
const SupplierView = Loadable(lazy(() => import('../pages/dashboard/SupplierView')));
const SupplierList = Loadable(lazy(() => import('../pages/dashboard/SupplierList')));
const LeaveList = Loadable(lazy(() => import('../pages/dashboard/LeaveList')));
const ImportExpenseView = Loadable(lazy(() => import('../pages/dashboard/ImportExpenseView')));
// Main
// const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
// const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const ErrorPage = Loadable(lazy(() => import('../pages/ErrorPage')));
const SuccessfulPage = Loadable(
  lazy(() => import('src/sections/wizard/listing/formSteps/SuccessfulPage'))
);
const CompleteSignupWizard = Loadable(lazy(() => import('../pages/CompleteSignupWizard')));
const MarketingListingURLDemo = Loadable(
  lazy(() => import('../pages/marketing-website/MarketingListingURLDemo'))
);
const MarketingInvalidURL = Loadable(
  lazy(() => import('../pages/marketing-website/MarketingInvalidURL'))
);
const MarketingFailedImport = Loadable(
  lazy(() => import('../pages/marketing-website/MarketingFailedImport'))
);
const MarketingSignupWizardPage = Loadable(
  lazy(() => import('../pages/wizard/MarketingSignupWizardPage'))
);
const RentalsUnitedAppPage = Loadable(lazy(() => import('../pages/wizard/RentalsUnitedAppPage')));
const HostMonthlyProfitReport = Loadable(
  lazy(() => import('../pages/dashboard/HostMonthlyProfitReport'))
);
const InvestorMonthlyProfitReport = Loadable(
  lazy(() => import('../pages/dashboard/InvestorMonthlyProfitReport'))
);
const PropertyWizardPage = Loadable(lazy(() => import('../pages/wizard/PropertyWizardPage')));
const BookingWizardPage = Loadable(lazy(() => import('../pages/wizard/BookingWizardPage')));
const ListingWizardPage = Loadable(lazy(() => import('../pages/wizard/ListingWizardPage')));
const MultiRateWizardPage = Loadable(lazy(() => import('../pages/wizard/MultiRateWizardPage')));
const IntegrationPartnerPage = Loadable(
  lazy(() => import('../pages/dashboard/IntegrationsPartner'))
);
const SuccessForm = Loadable(
  lazy(() => import('../sections/wizard/booking/components/success/SuccessForm'))
);
const AccountUpgradePlan = Loadable(
  lazy(() => import('src/sections/@dashboard/user/account/AccountUpgradePlan'))
);
const AccountBuyMoreLicenses = Loadable(
  lazy(() => import('src/sections/@dashboard/user/account/AccountBuyMoreLicenses'))
);
const InvoiceDetails = Loadable(
  lazy(() => import('src/sections/@dashboard/user/finance/invoice/InvoiceDetails'))
);
const NoLicensePage = Loadable(
  lazy(() => import('src/sections/wizard/listing/formSteps/NoLicensePage'))
);
const ErrorForm = Loadable(
  lazy(() => import('../sections/wizard/booking/components/error/ErrorForm'))
);
const RedirectPage = Loadable(
  lazy(() => import('src/sections/@dashboard/setting/integration/RedirectPage'))
);
const KeyNestIntegrationPartnerPage = Loadable(
  lazy(() => import('../pages/dashboard/KeyNestIntegrationPage'))
);
const RemoteLockIngrationPartnerPage = Loadable(
  lazy(() => import('../pages/dashboard/RemoteLockIntegrationPage'))
);
const KeySetView = Loadable(lazy(() => import('../pages/dashboard/KeySetView')));
const RemoteLockView = Loadable(lazy(() => import('../pages/dashboard/RemoteLockView')));
