import { Drawer, SxProps, Typography, Box, IconButton, Divider, BoxProps } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import Iconify from 'src/components/Iconify';
import Scrollbar from './Scrollbar';

const HeaderDrawer = ({ title, closeIcon, onClose, headerActions, subheader }) => (
  <>
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      gap={1}
      sx={{ py: 2, pl: 3, pr: 1 }}
    >
      <Typography noWrap variant="title">{title}</Typography>
      <Box display="flex" alignItems="center" gap={2}>
        {headerActions}
        <IconButton onClick={onClose}>{closeIcon}</IconButton>
      </Box>
    </Box>
    <Divider />
    {subheader}
  </>
);

type FooterDrawerProps = {
  actions?: ReactNode;
  footerActionProps?: BoxProps;
};

const FooterDrawer = ({ actions, footerActionProps }: FooterDrawerProps) => (
  <>
    <Divider />
    <Box p={2} {...footerActionProps}>
      {actions}
    </Box>
  </>
);

export interface DrawerWithActionProps extends FooterDrawerProps {
  open: boolean;
  onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
  anchor?: 'right' | 'left' | 'top' | 'bottom';
  sx?: SxProps;
  title: string;
  closeIcon?: ReactNode;
  children?: ReactNode;
  headerActions?: ReactNode;
  subheader?: ReactNode;
}
export default function DrawerWithAction({
  open,
  onClose,
  anchor,
  sx,
  title,
  closeIcon,
  children,
  headerActions,
  subheader,
  ...other
}: DrawerWithActionProps) {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor={anchor || 'right'}
      sx={{ '& .MuiDrawer-paper': { width: 360 }, ...sx }}
    >
      <HeaderDrawer
        title={title}
        subheader={subheader}
        closeIcon={closeIcon || <Iconify icon={'ic:round-close'} />}
        headerActions={headerActions}
        onClose={onClose}
      />
      <Scrollbar sx={{ height: '100%' }}>{children}</Scrollbar>
      {other.actions && <FooterDrawer {...other} />}
    </Drawer>
  );
}
