import axios from '../utils/axios';

function filterAPI() {
  return {
    fetchStatus: () => {
      const url = `/apis/task/filter/status`;
      return axios.get<string[]>(url);
    },
    fetchProperty: (
      limit: number = 200,
      orderType: string = 'name',
      order: string = 'asc',
      is_active: string = '',
      cities?: string[],
      page?: number,
      searchKey?: string
    ) => {
      const url = `/apis/filter/properties`;
      const params = new URLSearchParams();
      params.append('limit', limit.toString());
      params.append(`order[${orderType}]`, order);
      params.append(`is_active`, is_active);
      page && params.append(`page`, page.toString());
      searchKey && params.append(`name`, searchKey);
      if (cities) cities.forEach((city) => params.append(`area.city[]`, city));
      return axios.get(url, {
        params,
      });
    },
    fetchPriority: () => {
      const url = `/apis/task/filter/priority`;
      return axios.get(url);
    },
    fetchTaskTypes: () => {
      const url = `/apis/task_types`;
      return axios.get(url);
    },
    fetchAssignee: (params?: { [key: string]: any }, limit: number = 200) => {
      const url = `/apis/filter/assignee`;
      return axios.get(url, {
        params: {
          limit,
          ...params,
        },
      });
    },
    fetchUnitTypes: (
      limit: number = 200,
      properties: string[] = [],
      unitTypeCategories: string[] = [],
      propertyUnitsIsActive?: number,
      page?: number,
      searchKey?: string
    ) => {
      const url = '/apis/filter/unit_types';
      return axios.get(url, {
        params: {
          limit: limit,
          property: properties,
          page,
          name: searchKey,
          unit_category_type: unitTypeCategories,
          'property_units.is_active': propertyUnitsIsActive ?? '',
        },
      });
    },
    fetchChannels: (
      limit: number = 200,
      isActive: string = '',
      canConnectToChannel: string = '',
      page?: number,
      searchKey?: string,
      orderType?: string,
      order?: string
    ) => {
      const url = '/apis/filter/channels';
      return axios.get(url, {
        params: {
          limit: limit,
          is_active: isActive,
          can_connect_to_channel: canConnectToChannel,
          page,
          name: searchKey,
          ...(order && { [`order[${orderType}]`]: order }),
        },
      });
    },
    fetchCities: (
      limit: number = 200,
      page?: number,
      searchKey?: string,
      orderType?: string,
      order?: string
    ) => {
      const url = '/apis/filter/city';
      return axios.get(url, {
        params: {
          limit: limit,
          page,
          name: searchKey,
          ...(order && { [`order[${orderType}]`]: order }),
        },
      });
    },
    fetchUnits: (
      limit: number = 200,
      unitTypes: string[] = [],
      properties: string[] = [],
      page: number = 1,
      searchKey?: string,
      isActive: string = ''
    ) => {
      const url = '/apis/filter/units';
      return axios.get(url, {
        params: {
          limit: limit,
          unitType: unitTypes,
          'unit_type.property': properties,
          page,
          name: searchKey,
          is_active: isActive,
        },
      });
    },
    fetchCorporation: (limit: number = 200) => {
      const url = '/apis/filter/corporation';
      return axios.get(url, {
        params: {
          limit: limit,
        },
      });
    },
    fetchBlockTypes: (limit: number = 200) => {
      const url = '/apis/block_dates/get_blocked_types';
      return axios.get(url, {
        params: {
          limit: limit,
        },
      });
    },
    fetchAccountTypes: (limit: number = 200) => {
      const url = '/apis/xero/account_type';
      return axios.get(url, {
        params: {
          limit: limit,
        },
      });
    },
    fetchIssueTypes: () => axios.get('/apis/issue/get_issue_type'),
  };
}

export default filterAPI();
