// reference https://dev.to/mindactuate/scroll-to-anchor-element-with-react-router-v6-38op

import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToAnchor() {
  const location = useLocation();
  const lastHash = useRef('');

  // listen to location change using useEffect with location as dependency
  // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        lastHash.current = '';
      }, 100);
    }
  }, [location]);

  return null;
}

export function useScrollToAnchor(dependencies: any[] = [], glow = true) {
  const location = useLocation();
  const lastHash = useRef('');
  const targetElement = useRef<HTMLElement | null>(null);
  const [isGlowing, setIsGlowing] = useState(false);

  // listen to location change using useEffect with location as dependency
  // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    targetElement.current = document.getElementById(lastHash.current);
    if (lastHash.current && targetElement.current) {
      setTimeout(() => {
        targetElement.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        lastHash.current = '';
        
        setIsGlowing(true);
        setTimeout(() => {
          setIsGlowing(false);
        }, 1000);
      }, 100);
    }
  }, [location, ...dependencies]);

  useEffect(() => {
    if (targetElement.current && glow) {
      targetElement.current.style.transition = 'transform 0.5s ease-in-out';
      targetElement.current.style.transform = isGlowing ? 'scale(1.02)' : 'scale(1)';
    }
  }, [isGlowing]);

  return { isGlowing };
}

export default ScrollToAnchor;