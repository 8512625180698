
import { Stack, Link, Typography } from '@mui/material';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useLocales from 'src/hooks/useLocales';

type Props = {
  footers: {
    links: { label: string, link: string, isOuter?: boolean }[];
    title: string;
  }[]
}

const AuthFormFooter: FC<Props> = ({ footers }) => {
  const { translate } = useLocales('common');
  return (
    <Stack mt={5} gap={1}>
      {footers.map(({ title, links }, index) => (
        <Stack key={index} direction="row" alignItems="center" justifyContent="center" gap={0.5}>
          <Typography variant="body2">
            {title}
          </Typography>
          <Stack direction="row" gap={0.5} divider={<Typography variant="body2">{translate('or').toLowerCase()}</Typography>}>
            {links.map(({ label, link, isOuter }, index) => (
              <Link
                key={index}
                variant="subtitle2"
                {...(isOuter ? {
                  target: '_blank',
                  href: link,
                } : {
                  component: RouterLink,
                  to: link,
                })}>
                {label}
              </Link>
            ))}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default AuthFormFooter;