import { Condition } from "src/@types/common"
import { isValid, isEqual } from "date-fns";

const isDateValid = (dateStr: string) => {
  return isValid(new Date(dateStr));
}

export const getCondition = (minValue: any, maxValue: any, equality = false, defaultCondition = Condition.BETWEEN): Condition => {
  const isNumber = typeof minValue === 'number' || typeof maxValue === 'number';
  if (minValue !== null && maxValue !== null) {
    if (minValue === maxValue || isEqual(minValue, maxValue)) {
      if (isNumber) return Condition.EQUAL;
      if (isDateValid(minValue) && isDateValid(maxValue)) return Condition.ON;
    }
    return Condition.BETWEEN;
  } else if (minValue !== null && maxValue === null) {
    if (isNumber) return equality ? Condition.MORE_THAN_OR_EQUAL : Condition.MORE_THAN;
    if (isDateValid(minValue)) return Condition.AFTER;
  } else if (minValue === null && maxValue !== null) {
    if (isNumber) return equality ? Condition.LESS_THAN_OR_EQUAL : Condition.LESS_THAN;
    if (isDateValid(maxValue)) return Condition.BEFORE;
  }
  return defaultCondition;
}