import { SettingsValueProps } from './components/settings/type';
// routes
import { PATH_DASHBOARD } from './routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX;

export const GOOGLE_ANALYTICS_API = process.env.REACT_APP_GA_MEASUREMENT_ID;
export const GOOGLE_API_API = process.env.REACT_APP_GOOGLE_API_KEY;

export const OAUTH2_API = {
  client_id: process.env.REACT_APP_OAUTH2_CLIENT_ID,
  client_secret: process.env.REACT_APP_OAUTH2_CLIENT_SECRET,
};

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.booking; // as '/bookings'

// PATHS OF DASHBOARD
export const OUT_OF_DASHBOARD_PATHS = [
  'marketing-website',
  'listings/import',
  'wizard',
  'channel-manager',
];

// FULLSCREEN PATHS
export const FULLSCREEN_PATHS = [
  '/blockdates/active',
  '/blockdates/removed',
  '/booking/guest',
  '/booking/list',
  '/calendar',
  '/expenses',
  '/invoices',
  '/issues',
  '/leaves',
  '/management/leaves',
  '/management/staff',
  '/mprs',
  '/owner/contracts',
  '/owner/mprs',
  '/owner/packages',
  '/owners',
  '/payments',
  '/refunds',
  '/suppliers',
  '/tasks',
  '/promotions',
];

// DATAGRID
export enum GRID_NAMES {
  BOOKING_ALL_BOOKINGS = 'all_bookings', //
  BOOKING_ARRIVALS = 'arrivals', //
  BOOKING_DEPARTURES = 'departures', //
  BOOKING_STAY_OVERS = 'stay-overs', //
  BOOKING_CANCELLATIONS = 'cancellations', //
  BOOKING_SCHEDULED_FOR_CANCELLATION = 'scheduled_for_cancellation', //
  BOOKING_UNALLOCATED_BOOKINGS = 'unallocated_bookings', //
  BOOKING_AWAITING_FINALISATION = 'awaiting_finalisation', //
  BOOKING_UNMAPPED_BOOKINGS = 'unmapped_bookings', //
  BOOKING_DASHBOARD_ALL_BOOKINGS = 'dashboard_all_bookings', //
  BOOKING_DASHBOARD_ARRIVALS = 'dashboard_arrivals', //
  BOOKING_DASHBOARD_DEPARTURES = 'dashboard_departures', //
  BOOKING_DASHBOARD_SCHEDULED_FOR_CANCELLATION = 'dashboard_scheduled_for_cancellation', //
  BOOKING_DASHBOARD_UNALLOCATED_BOOKINGS = 'dashboard_unallocated_bookings', //
  STAFF = 'staff', //
  HOUSEKEEPING = 'housekeeping',
  TASKS = 'tasks',
  ISSUES = 'issues', //
  SUPPLIERS = 'suppliers', //
  HANDS = 'hands',
  OWNERS = 'owners', //
  CONTRACTS = 'contracts', //
  PACKAGES = 'packages', //
  OWNER_MPR = 'owner_mpr', //
  OWNER_MPRS = 'owner_mprs', //
  MPR = 'mpr', //
  MPRS = 'mprs', //
  GUEST = 'guest', //
  BLOCK_DATES = 'blockdates', //
  PAYMENTS = 'payments', //
  REFUNDS = 'refunds', //
  REFUND_TYPES = 'refund_types', //
  EXPENSES = 'expenses', //
  EXPENSE_TYPES = 'expense_types', //
  INVOICES = 'invoices', //
  ARCHIVED_LISTINGS = 'archived_listings', //
  INTEGRATION_XERO_ACCOUNT = 'integration_xero_chart_of_account', //
  INTEGRATION_XERO_ACCOUNT_SCHEMA = 'integration_xero_accounting_schema', //
  INTEGRATION_XERO_TAX_SCHEMA = 'integration_xero_tax_schema', //
}

// LAYOUT
// ----------------------------------------------------------------------

export const DRAWER_WIDTH = 260;

export const DASHBOARD_HEADER_MOBILE = 64;
export const DASHBOARD_HEADER_DESKTOP = 64;
export const DASHBOARD_NAVBAR_WIDTH = 240;
export const DASHBOARD_NAVBAR_COLLAPSE_WIDTH = 88;
export const DASHBOARD_SUMMARY_WIDTH = 234;

export const DASHBOARD_NAVBAR_ROOT_ITEM_HEIGHT = 48;
export const DASHBOARD_NAVBAR_SUB_ITEM_HEIGHT = 40;
export const DASHBOARD_NAVBAR_ICON_ITEM_SIZE = 22;

export const MAIN_HEADER_DESKTOP = 88;
export const MAIN_HEADER_MOBILE = 64;

// Data Grid
export const DATAGRID_HEIGHT = `calc(100vh - ${DASHBOARD_HEADER_DESKTOP + 120}px)`;
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'zeevou',
  themeStretch: true,
};

// CONSTANTS
// ----------------------------------------------------------------------

export const NAME_MAX_LENGTH = 50;
export const DESCRIPTION_MAX_LENGTH = 200;

export const GOOGLE_LOGO_MIN_SIZE = 72;
export const GOOGLE_LOGO_MAX_SIZE = 1200;
export const POSTAL_CODE_MAX_LENGTH = 10;

export const MAX_ALLOWED_UNITS_UNDER_PROPERTY = 100;
