import axios from 'src/utils/axios';
import { FilterState, FilterValue } from 'src/@types/filter';
import { SortDirection } from 'src/@types/booking-list';
import { Filters } from 'src/@types/leaves';
import {
  getStartDateSearchParams,
  getEndDateSearchParams
} from 'src/sections/@dashboard/leaves/data-grid/LeavesDataGridCommon';

function leavesAPI() {
  return {
    fetchHeaderInfo: () => axios.get('/apis/leave/header_info'),
    fetchLeaveList: ({
                     page,
                     limit,
                     order,
                     signal,
                     filtersState,
                     defaultFilters,
                     advanceFilterParam,
                   }: {
      page: number;
      limit: number;
      order?: { field: string; sort: SortDirection };
      signal?: AbortSignal;
      filtersState: FilterState;
      defaultFilters: Filters;
      advanceFilterParam?: { key: string; value: string };
    }) => axios.get('/apis/list/leaves', {
        params: {
          page,
          limit,
          ...(order && { [`order[${order.field}]`]: order.sort }),
          ...(advanceFilterParam && { [`${advanceFilterParam.key}`]: advanceFilterParam.value }),
          ...(filtersState.pending_requests !== undefined && !Boolean(filtersState.pending_requests) && {
            status: defaultFilters.status.map((status) => status),
          }),
          ...(Boolean(filtersState.pending_requests) && {
            status: ['Pending'],
          }),
          ...(Array.isArray(filtersState.categories) && {
            reason: filtersState.categories.map((category) => category.value),
          }),
          ...(Array.isArray(filtersState.roles) && {
            'staff.relatedUser.roles.label': filtersState.roles.map((role) => role.value),
          }),
          ...(Array.isArray(filtersState.staff) && {
            staffName: filtersState.staff.map((staff) => staff.value),
          }),
          ...(Array.isArray(filtersState.statuses) && filtersState.statuses.length > 0 && {
            status: filtersState.statuses.map((status) => status.value),
          }),
          ...(filtersState.start_date && {
            ...getStartDateSearchParams(
              (filtersState.start_date as FilterValue).value
            ),
          }),
          ...(filtersState.end_date && {
            ...getEndDateSearchParams(
              (filtersState.end_date as FilterValue).value
            ),
          }),
        },
        signal,
      }),
    createEditLeave: (
      staffId: number,
      category: string,
      leaveType: string,
      from: string,
      to: string,
      description: string,
      leaveId: number | null,
    ) => {
      const url = '/apis/leave/create_and_edit_leave';
      return axios.post(url, {
        staff_id: staffId,
        category: category,
        leave_type: leaveType,
        from: from,
        to: to,
        description: description,
        leave_id: leaveId,
      });
    },
    fetchCategories: () => axios.get('/apis/leave/get_category'),
    fetchStaff: (searchQuery: string, page: number, limit: number = 50) => {
      const url = '/apis/get_staff';
      return axios.get(url, {
        params: { page, limit, userName: searchQuery, },
      });
    },
    fetchStatus: () => axios.get('/apis/leave/get_status'),
    acceptLeave: (leaveId: number) => axios.put(`/apis/leave/accept_leave/${leaveId}`),
    rejectLeave: (leaveId: number, rejectReason: string | null) => (
      axios.put(`/apis/leave/reject_leave/${leaveId}`, {
        reject_reason: rejectReason,
      })
    ),
    restoreLeave: (leaveId: number) => axios.put(`/apis/leave/restore_leave/${leaveId}`),
    cancelLeave: (leaveId: number) => axios.put(`/apis/leave/cancel_leave/${leaveId}`),
  };
}

export default leavesAPI();
