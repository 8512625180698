import { useState } from 'react';
import {
  Stack,
  Typography,
  Box,
  Avatar,
  Link,
  alpha,
  Tooltip,
  Button,
  ListItemText,
} from '@mui/material';
import { utcToZonedTime } from 'date-fns-tz';
import { Review as ReviewType } from 'src/@types/common';
import { CardAction } from 'src/components/card';
import useLocales from 'src/hooks/useLocales';
import ReadMore from 'src/components/ReadMore';
import Iconify from '../Iconify';
import { fDT } from 'src/utils/formatTime';
import { PATH_DASHBOARD } from 'src/routes/paths';
import EditReviewDrawer from './EditOrCreateReviewDrawer';
import useResponsive from 'src/hooks/useResponsive';
import commonAPI from 'src/api/api';
import DismissPublishDialog from './DismissPublishDialog';
import DialogBox from 'src/components/dialog-box/DialogBox';
import { LoadingButton } from '@mui/lab';
import useAuth from 'src/hooks/useAuth';
import { hasReviewEditAccess } from '.';
import Status from './Status';
import ReviewRating from './ReviewRating';
import useAcl from 'src/hooks/useAcl';

interface Props {
  review: ReviewType;
  update: (review: ReviewType) => void;
  remove: (id: number) => void;
  canEdit?: boolean;
}

export default function Review({ review, update, remove, canEdit }: Props) {
  const { user } = useAuth();
  const isManual = review.origin === 'manual';
  const { translate, shortDateFormat, currentLang } = useLocales(['listings', 'common']);
  const isDesktopView = useResponsive('up', 'md');
  const { DELETE } = useAcl('PeymansBookingBundleReview');

  const [isTextExpanded, setIsTextExpanded] = useState(false);
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [dialogProps, setDialogProps] = useState<{
    isOpen: boolean;
    action: 'dismiss' | 'publish' | '';
    isLoading: boolean;
  }>({ isOpen: false, action: '', isLoading: false });
  const [DeleteDialogProps, setDeleteDialogProps] = useState<{
    isOpen: boolean;
    isLoading: boolean;
  }>({ isOpen: false, isLoading: false });

  const publishOrDismiss = (approved: boolean) => {
    setDialogProps((prev) => ({ ...prev, isLoading: true }));
    commonAPI.updateAReview(review.id, { approved }).then((res) => {
      update(res.data);
      setDialogProps((prev) => ({ ...prev, isLoading: false, isOpen: false }));
    });
  };

  const markOrUnmarkAsFeatured = (featured: boolean) => {
    setDialogProps((prev) => ({ ...prev, isLoading: true }));
    commonAPI.updateAReview(review.id, { feature_on_home_page: featured }).then((res) => {
      update(res.data);
      setDialogProps((prev) => ({ ...prev, isLoading: false, isOpen: false }));
    });
  };

  const removeReview = () => {
    setDeleteDialogProps((prev) => ({ ...prev, isLoading: true }));
    commonAPI.deleteAReview(review.id).then((res) => {
      remove(review.id);
      setDeleteDialogProps({ isOpen: false, isLoading: false });
    });
  };

  const onPublish = () => {
    setDialogProps((prev) => ({ ...prev, isOpen: true, action: 'publish' }));
  };
  const onDismiss = () => {
    setDialogProps((prev) => ({ ...prev, isOpen: true, action: 'dismiss' }));
  };

  return (
    <>
      <Stack
        sx={(theme) => ({
          background: review.approved === null ? alpha(theme.palette.info.main, 0.08) : 'initial',
          mb: 1,
          py: 1.5,
          px: { xs: 0, md: 3 },
          position: 'relative',
        })}
      >
        <Box
          sx={(theme) => ({
            '&:hover': {
              background: theme.palette.grey[100],
            },
            py: 1.5,
            px: 2,
            borderRadius: '8px',
          })}
        >
          <Stack direction="row" justifyContent="space-between" mb={2}>
            <Stack direction="row">
              <Avatar />
              <Stack ml={2}>
                <Stack direction="row" alignItems="center" gap={1}>
                  <ReviewRating rate={review.rate} />
                  {review.approved !== null ? (
                    <Status
                      status={review.approved ? 'published' : 'dismissed'}
                      isFeaturedOnHomepage={!!review.feature_on_home_page}
                      sx={(theme) => ({ ml: 1, lineHeight: 2 })}
                    />
                  ) : null}
                </Stack>
                <Typography variant="caption" color="text.secondary" sx={(theme) => ({ mt: 1 })}>
                  {translate(review.channel ? 'added_by_on_channel' : 'added_by', {
                    reviewer:
                      !review.guest?.first_name && !review.guest?.last_name
                        ? translate('anonymous')
                        : `${review.guest.first_name || ''} ${review.guest.last_name || ''}`.trim(),
                    ...(review.channel && { channel: review.channel.name }),
                    date: fDT({
                      date: utcToZonedTime(new Date(review.review_date || ''), 'UTC'),
                      pattern: shortDateFormat,
                      options: { locale: currentLang.dateLocale },
                    }),
                  })}
                  {review.booking && review.booking.booking_no ? (
                    <>
                      &nbsp; &bull; &nbsp;
                      <Tooltip
                        title={
                          <Typography variant="caption" fontWeight="fontWeightBold">
                            {translate('go_to_booking')}
                          </Typography>
                        }
                        arrow
                        placement="top-start"
                      >
                        <Link
                          href={`${PATH_DASHBOARD.booking.booking}/${review.booking.id}/overview`}
                          underline="always"
                          variant="caption"
                          color="text.secondary"
                          target="_blank"
                        >
                          {review.booking.booking_no}
                        </Link>
                      </Tooltip>
                    </>
                  ) : null}
                </Typography>
              </Stack>
            </Stack>
            {canEdit && (
              <>
                {review.approved === null && isDesktopView ? (
                  <CardAction
                    actions={[
                      {
                        type: 'primary',
                        label: translate('dismiss'),
                        onAction: () => onDismiss(),
                        ButtonProps: {
                          color: 'info',
                        },
                        hidden: !hasReviewEditAccess(user),
                      },
                      {
                        type: 'primary',
                        label: translate('publish'),
                        onAction: () => onPublish(),
                        ButtonProps: {
                          color: 'info',
                        },
                        hidden: !hasReviewEditAccess(user),
                      },
                    ]}
                  />
                ) : (
                  <CardAction
                    mode="collapsed"
                    actions={[
                      {
                        type: 'primary',
                        label: (
                          <>
                            <ListItemText primary={translate('edit')} />
                            {!isManual ? <Iconify icon="material-symbols:info-outline" /> : null}
                          </>
                        ),
                        disabled: !isManual,
                        tooltipTitle: !isManual
                          ? translate('only_manually_created_reviews_can_be_edited')
                          : undefined,
                        hidden: review.approved === null || !hasReviewEditAccess(user),
                        onAction: () => {
                          setIsEditDrawerOpen(true);
                        },
                      },
                      {
                        type: 'primary',
                        label: (
                          <>
                            <ListItemText
                              primary={translate(
                                review.feature_on_home_page
                                  ? 'unmark_as_featured'
                                  : 'mark_as_featured'
                              )}
                            />
                            <Iconify icon="material-symbols-light:lightbulb-outline" />
                          </>
                        ),
                        tooltipTitle: translate('mark_as_featured_action_hint'),
                        hidden: !review.approved,
                        onAction: () => markOrUnmarkAsFeatured(!review.feature_on_home_page),
                      },
                      {
                        type: 'primary',
                        label: translate('publish'),
                        hidden: review.approved === true || !hasReviewEditAccess(user),
                        onAction: () => onPublish(),
                      },
                      {
                        type: 'primary',
                        label: translate('dismiss'),
                        hidden: review.approved === false || !hasReviewEditAccess(user),
                        onAction: () => onDismiss(),
                      },
                      {
                        type: 'primary',
                        label: (
                          <>
                            <ListItemText primary={translate('delete')} />
                            <Iconify icon="material-symbols:info-outline" />
                          </>
                        ),
                        hidden:
                          review.approved === null || !DELETE,
                        onAction: () => setDeleteDialogProps((prev) => ({ ...prev, isOpen: true })),
                        disabled: !isManual,
                        tooltipTitle: !isManual
                          ? translate('only_manually_created_reviews_can_be_deleted')
                          : undefined,
                      },
                    ]}
                  />
                )}
              </>
            )}
          </Stack>
          {review.title ? (
            <Typography variant="subtitle2" sx={(theme) => ({ mb: 1 })}>
              {review.title}
            </Typography>
          ) : null}
          {review.review_text ? (
            <ReadMore
              line={5}
              variant="body2"
              overriddenExpanded={isTextExpanded}
              ButtonProps={{
                endIcon: (
                  <Iconify
                    icon={isTextExpanded ? 'ic:round-expand-less' : 'ic:round-expand-more'}
                  />
                ),
                onClick: (e) => {
                  e.stopPropagation();
                  setIsTextExpanded((prev) => !prev);
                },
              }}
              WrapperProps={{
                sx: {
                  '&>div:first-of-type': {
                    minHeight: 'unset !important',
                    height: 'max-content !important',
                    mb: 2,
                  },
                },
              }}
            >
              <Box
                component={Typography}
                variant="body2"
                color={review.approved === false ? 'text.secondary' : 'text.primary'}
                dangerouslySetInnerHTML={{ __html: review.review_text || '' }}
              />
            </ReadMore>
          ) : (
            <Typography variant="body2" color="text.secondary" mb={2}>
              {translate('no_comments')}
            </Typography>
          )}
          {review.property?.name ? (
            <Typography variant="caption" color="text.disabled">
              {translate('review_of', { name: review.property.name })}
            </Typography>
          ) : null}
        </Box>
      </Stack>
      <EditReviewDrawer
        review={review}
        isOpen={isEditDrawerOpen}
        setClose={() => setIsEditDrawerOpen(false)}
        update={update}
      />
      <DismissPublishDialog
        action={dialogProps.action}
        isOpen={dialogProps.isOpen}
        isLoading={dialogProps.isLoading}
        setClose={() => setDialogProps((prev) => ({ ...prev, isOpen: false, action: '' }))}
        publishOrDismiss={publishOrDismiss}
      />
      <DialogBox
        severity="warning"
        title={translate('delete_the_review')}
        openState={DeleteDialogProps.isOpen}
        DialogProps={{
          fullWidth: true,
          maxWidth: 'sm',
          onClose: () => setDeleteDialogProps({ isLoading: false, isOpen: false }),
        }}
        ContentProps={(theme) => ({ p: 0 })}
        action={
          <Stack direction="row" justifyContent="end" alignItems="center">
            <Button
              onClick={() => setDeleteDialogProps({ isLoading: false, isOpen: false })}
              color="inherit"
            >
              {translate('cancel')}
            </Button>
            <LoadingButton
              color="warning"
              loading={DeleteDialogProps.isLoading}
              onClick={removeReview}
              sx={(theme) => ({
                minWidth: '64px',
                minHeight: '36px',
              })}
            >
              {translate('delete')}
            </LoadingButton>
          </Stack>
        }
      >
        <></>
      </DialogBox>
    </>
  );
}
