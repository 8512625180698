import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import { ArchiveListingsParams, ArchiveListingsState, Property } from 'src/@types/listing';
import listingsAPI from 'src/api/listings';

// ----------------------------------------------------------------------

const initialState: ArchiveListingsState = {
  loading: false,
  hasError: false,
  rowCount: 0,
  properties: [],
};

const slice = createSlice({
  name: 'archived-listings',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setHasError(state, action: PayloadAction<boolean>) {
      state.hasError = action.payload;
    },
    setArchivedListings(state, action: PayloadAction<Property[]>) {
      state.properties = action.payload;
    },
    setRowCount(state, action: PayloadAction<number>) {
      state.rowCount = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setLoading, setRowCount } = slice.actions;

export function getArchiveListings(params: ArchiveListingsParams, signal?: AbortSignal) {
  return async () => {
    dispatch(slice.actions.setLoading(true));
    try {
      const { data } = await listingsAPI.fetchInactiveProperties(
        { is_active: 'false', ...params },
        signal
      );
      dispatch(slice.actions.setArchivedListings(data.data));
      dispatch(slice.actions.setRowCount(data.meta.total_items));
      dispatch(slice.actions.setLoading(false));
    } catch (error) {
      dispatch(slice.actions.setHasError(false));
    }
  };
}