import { Theme } from '@mui/material/styles';


export default function DateRangePicker(theme: Theme) {
  return {
    MuiDateRangePicker: {
      defaultProps: {
        localeText: {
          fieldDayPlaceholder: () => '',
          fieldMonthPlaceholder: () => '',
          fieldYearPlaceholder: () => ''
        }
      }
    }
  };
}
