import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// utils
import settingAPI from 'src/api/setting';
// @types
import { NotificationSetting, NotificationSettingUpdateBody, settingState, SettingTemplate } from 'src/@types/setting';
//
import { dispatch } from '../store';
import { Dispatch, SetStateAction } from 'react';

// ----------------------------------------------------------------------

const initialState: settingState = {
  notificationSettings: [],
  notificationSettingsOrgId: 0, 
};

const slice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    setNotificationSettings(state, action: PayloadAction<NotificationSetting[]>) {
      state.notificationSettings = action.payload;
    },
    clearNotificationSettings(state, action: PayloadAction<{ organizationId: number }>) {
      state.notificationSettings = [];
      state.notificationSettingsOrgId = action.payload.organizationId;
    },
    updateNotificationSettingByTemplateIndex(state, action: PayloadAction<{
      categoryIndex: number, index: number, changed: NotificationSettingUpdateBody | SettingTemplate,
    }>) {
      const { categoryIndex, index, changed } = action.payload;
      state.notificationSettings[categoryIndex].templates[index] = {
        ...state.notificationSettings[categoryIndex].templates[index],
        ...changed,
      };
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setNotificationSettings,
  clearNotificationSettings,
} = slice.actions;

// ----------------------------------------------------------------------

export function updateNotificationSetting(
  categoryIndex: number,
  index: number,
  template: SettingTemplate,
  changed: NotificationSettingUpdateBody | null,
  setLoading: Dispatch<SetStateAction<boolean>>,
) {
  return async () => {
    setLoading(true);
    try {
      if (changed) {
        dispatch(slice.actions.updateNotificationSettingByTemplateIndex({ categoryIndex, index, changed }));
        await settingAPI.updateNotificationSetting(template.template_id, changed);
      }
    } catch (error) {
      dispatch(slice.actions.updateNotificationSettingByTemplateIndex({ categoryIndex, index, changed: template }));
    }
    setLoading(false);
  };
};
