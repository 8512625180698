import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LeavesState, Filters, Leave, LeavesTab } from 'src/@types/leaves';
import { leaveNormalizer } from 'src/utils/leaves';
import { FilterState } from 'src/@types/filter';
import { SortDirection } from 'src/@types/booking-list';
import LeavesApi from 'src/api/leaves';
import { dispatch } from 'src/redux/store';

const initialState: LeavesState = {
  loading: false,
  currentTab: 'leaves',
  headerInfo: null,
  leaves: {
    list: [],
    rowCount: 0,
    gridConfigs: null,
  },
  drawers: {
    leaveView: {
      open: false,
      defaultValue: null,
    },
    leaveCreateEdit: {
      open: false,
      defaultValue: undefined,
    },
  },
  dialogs: {
    leaveCancel: {
      open: false,
      loading: false,
      activeLeaveId: null,
    },
  },
};

const slice = createSlice({
  name: 'leaves',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    setCurrentTab(state, action: PayloadAction<LeavesTab>) {
      state.currentTab = action.payload;
    },

    setHeaderInfo(state, action: PayloadAction<{ requestsCount: number }>) {
      state.headerInfo = action.payload;
    },

    setIncreasePendingRequestsCount(state) {
      if (state.headerInfo) state.headerInfo.requestsCount += 1;
    },

    setDecreasePendingRequestsCount(state, action: PayloadAction<{ leaveId: number }>) {
      const { leaveId } = action.payload;
      const leaveIndex = state.leaves.list.findIndex(leave => leave.id === leaveId);
      if (state.headerInfo && leaveIndex >= 0 && state.leaves.list[leaveIndex]?.status === 'Pending') {
        state.headerInfo.requestsCount -= 1;
      }
    },

    setGridConfigs(state, action) {
      state.leaves.gridConfigs = action.payload;
    },

    setRowCount(state, action) {
      state.leaves.rowCount = action.payload;
    },

    setList(state, action: PayloadAction<Leave[]>) {
      state.loading = false;
      state.leaves.list = action.payload;
    },

    setLeaveViewDrawerOpen(state, action: PayloadAction<{ open: boolean; activeLeave: Leave | null }>) {
      state.drawers.leaveView.open = action.payload.open;
      state.drawers.leaveView.defaultValue = action.payload.activeLeave;
    },

    setLeaveCreateEditDrawerOpen(state, action: PayloadAction<{ open: boolean; defaultValue?: Leave}>) {
      state.drawers.leaveCreateEdit.open = action.payload.open;
      state.drawers.leaveCreateEdit.defaultValue = action.payload.defaultValue;
    },

    updateLeave(state, action: PayloadAction<{ leaveId: number, newValue: Partial<Leave> }>) {
      const { leaveId, newValue } = action.payload;
      const leaveIndex = state.leaves.list.findIndex(leave => leave.id === leaveId);
      if (leaveIndex >= 0 && state.leaves.list[leaveIndex]) {
        state.leaves.list[leaveIndex] = { ...state.leaves.list[leaveIndex], ...newValue };
      }
      if (state.drawers.leaveView.open && state.drawers.leaveView.defaultValue) {
        state.drawers.leaveView.defaultValue = { ...state.drawers.leaveView.defaultValue, ...newValue };
      }
    },

    setLeaveCancelDialogLoading(state, action: PayloadAction<boolean>) {
      state.dialogs.leaveCancel.loading = action.payload;
    },

    setLeaveCancelDialogOpen(state, action: PayloadAction<{
      open?: boolean;
      loading?: boolean;
      activeLeaveId?: number
    }>) {
      if (action.payload.open !== undefined) state.dialogs.leaveCancel.open = action.payload.open;
      if (action.payload.loading !== undefined) state.dialogs.leaveCancel.loading = action.payload.loading;
      if (action.payload.activeLeaveId !== undefined) state.dialogs.leaveCancel.activeLeaveId = action.payload.activeLeaveId;
    },

    setAddLeave(state, action: PayloadAction<Leave>) {
      state.leaves.rowCount += 1;
      state.leaves.list = [action.payload, ...state.leaves.list];
    },
  },
});

// reducer
export default slice.reducer;

// actions
export const {
  setCurrentTab,
  setRowCount,
  setGridConfigs,
  updateLeave,
  setLeaveViewDrawerOpen,
  setLeaveCreateEditDrawerOpen,
  setLeaveCancelDialogOpen,
  setDecreasePendingRequestsCount,
} = slice.actions;

export function getHeaderInfo() {
  return async () => {
    try {
      const response = await LeavesApi.fetchHeaderInfo();
      dispatch(slice.actions.setHeaderInfo({
        requestsCount: +response.data.pending_request_count,
      }));
    } catch (error) {
      console.error(error);
    }
  };
}

export function getLeaveList({
  page,
  limit,
  filtersState,
  defaultFilters,
  order,
  signal,
  advanceFilterParam,
}: {
  page: number;
  limit: number;
  filtersState: FilterState;
  defaultFilters: Filters;
  order?: { field: string; sort: SortDirection };
  signal?: AbortSignal;
  advanceFilterParam?: { key: string; value: string };
}) {
  return async () => {
    dispatch(slice.actions.setLoading(true));
    try {
      const response = await LeavesApi.fetchLeaveList({
        page,
        limit,
        filtersState,
        defaultFilters,
        order,
        signal,
        advanceFilterParam,
      });
      dispatch(slice.actions.setList(response.data.data.map(item => leaveNormalizer(item))));
      dispatch(slice.actions.setRowCount(response.data.meta.total_items));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };
}

export function createLeave(
  staffId: number,
  category: string,
  leaveType: string,
  from: string,
  to: string,
  description: string
) {
  return async () => {
    const response = await LeavesApi.createEditLeave(
      staffId,
      category,
      leaveType,
      from,
      to,
      description,
      null,
    );
    const normalizedResponse = leaveNormalizer(response.data);
    dispatch(slice.actions.setIncreasePendingRequestsCount());
    dispatch(slice.actions.setAddLeave(normalizedResponse));
    dispatch(slice.actions.setLeaveCreateEditDrawerOpen({ open: false }));
  };
}

export function editLeave(
  staffId: number,
  category: string,
  leaveType: string,
  from: string,
  to: string,
  description: string,
  leaveId: number,
) {
  return async () => {
    const response = await LeavesApi.createEditLeave(
      staffId,
      category,
      leaveType,
      from,
      to,
      description,
      leaveId,
    );
    const normalizedResponse = leaveNormalizer(response.data);
    dispatch(slice.actions.updateLeave({
      leaveId: leaveId,
      newValue: normalizedResponse
    }));
    dispatch(slice.actions.setLeaveCreateEditDrawerOpen({ open: false }));
  };
}

export function acceptLeave(leaveId: number) {
  return async () => {
    await LeavesApi.acceptLeave(leaveId);
    dispatch(slice.actions.setDecreasePendingRequestsCount({ leaveId }));
    dispatch(slice.actions.updateLeave({
      leaveId: leaveId,
      newValue: { status: 'Accepted', updatesAt: new Date().toISOString() }
    }));
  };
}

export function restoreLeave(leaveId: number) {
  return async () => {
    await LeavesApi.restoreLeave(leaveId);
    dispatch(slice.actions.setIncreasePendingRequestsCount());
    dispatch(slice.actions.updateLeave({
      leaveId: leaveId,
      newValue: { status: 'Pending', updatesAt: new Date().toISOString() }
    }));
  };
}

export function cancelLeave(leaveId: number) {
  return async () => {
    dispatch(slice.actions.setLeaveCancelDialogOpen({ loading: true }));
    try {
      await LeavesApi.cancelLeave(leaveId);
      dispatch(slice.actions.setDecreasePendingRequestsCount({ leaveId }));
      dispatch(slice.actions.updateLeave({
        leaveId: leaveId,
        newValue: { status: 'Cancel', updatesAt: new Date().toISOString() }
      }));
      dispatch(slice.actions.setLeaveCancelDialogOpen({ open: false, loading: false }));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setLeaveCancelDialogOpen({ loading: false }));
    }
  };
}