import { Staff } from 'src/@types/common';
import axios from '../utils/axios';
import { Brand } from 'src/@types/org-config';

export default function orgConfigAPI() {
  return {
    fetchAllBrands: () =>
      axios.get<Brand[]>('/apis/brands', {
        params: {
          properties: ['id', 'name'],
          'properties[organization_config][]': 'id',
          'properties[frontend_style][]': 'image',
        },
      }),

    fetchCheckInReadyConditions: (brandId?: number) => {
      const url = '/apis/booking/get_check_in_ready_settings';
      const params = new URLSearchParams();
      if (brandId) params.append('brand_id', brandId.toString());
      return axios.get(url, {
        params,
      });
    },
    fetchStaff: (organizationId: number) =>
      axios.get<(Staff & { brands: Brand[] })[]>(
        `/apis/staff_by_role?organization_id=${organizationId}&roles[]=Check-in Staff&roles[]=Host (Blaze)&roles[]=Host (Spark)&roles[]=Host (Glow)&roles[]=Office Staff (Financial)&roles[]=Office Staff (No Finance)&roles[]=Receptionist`
      ),
    createForwardingEmail: (brandId: number) =>
      axios.post<{ email: string }>(`/apis/generate-forwarding-email/${brandId}`),
    setPreferences: (brandId: number, key: string, value: boolean | string | string[] | number) =>
      axios.put('/apis/preferences/set', {
        brand: brandId,
        key: key,
        value: value,
      }),
    fetchPreferences: () => axios.get('/apis/currentOrgPreferences/get'),
    fetchBrandPreferences: (brandId: number) =>
      axios.get('/apis/preferences/get', {
        params: { brand: brandId },
      }),
    fetchContactContactDetails: () => axios.get('/apis/currentOrgContactSettings/get'),
    fetchCountryConfigs: () => axios.get('/apis/country_configs'),
    updateCountryConfigs: (id: number | string, body: { weekend_days: string | null }) => {
      const url = `/apis/country_configs/${id}`;
      return axios.put(url, body);
    },
    setSocialMedia: (brandId: number, mediaKey: string, mediaValue: string) =>
      axios.post('/apis/contactSettings/set', {
        brand: brandId,
        mediaKey,
        mediaValue,
      }),
    setContactInfo: (body: any) => axios.post('/apis/contactSettings/set', body),
    deleteContactInfo: (id: number, type: string) =>
      axios.post('/apis/contactSettings/remove', { id, type }),
    deleteSocialMedia: (brandId: number, type: string) =>
      axios.post('/apis/contactSettings/remove', { brand: brandId, type }),
    setPrimary: (id: number, type: string) =>
      axios.post('/apis/contactSettings/setPrimary', { id, type }),
  };
}
