import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Divider, MenuItem, Stack, Link } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
// components
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import useLocales from '../../../hooks/useLocales';
import Iconify from 'src/components/Iconify';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------
type SettingOption = {
  type: 'menuItem' | 'divider';
  title: string;
  path: string;
};

const isExternalLink = (path: string) => path.includes('http');

const MENU_OPTIONS = [
  {
    label: 'confirmation_process',
    linkTo: PATH_DASHBOARD.booking.confirmationPageSettings,
  },
  {
    label: 'automated_message',
    linkTo: PATH_DASHBOARD.automatedMessage.root,
  },
];

// ----------------------------------------------------------------------

export default function SettingsPopover() {
  const { user } = useAuth();
  const { translate } = useLocales('common');
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const renderOption = (option: SettingOption, key) => {
    if (option.type === 'menuItem') {
      if (isExternalLink(option.path)) {
        return (
          <MenuItem
            key={key}
            component={Link}
            href={option.path}
            target="_blank"
            rel="noopener"
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2 }}
          >
            {translate(option.title)}
          </MenuItem>
        );
      } else {
        return (
          <MenuItem
            key={key}
            to={option.path}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2 }}
          >
            {translate(option.title)}
          </MenuItem>
        );
      }
    }

    if (option.type === 'divider') {
      return <Divider key={key} />;
    }
  };

  return (
    <>
      <IconButtonAnimate
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
            },
          }),
        }}
      >
        <Iconify icon={'ic:round-settings'} />
      </IconButtonAnimate>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Stack spacing={0.5} sx={{ py: 1 }}>
          {user?.navbar_setting.map((option, index) => renderOption(option, index))}
          {/* {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
              sx={{ typography: 'body2', py: 1, px: 2 }}
            >
              {translate(option.label.toLowerCase())}
            </MenuItem>
          ))}
          <Divider />
          <MenuItem
            component={Link}
            href={PATH_HUB.integrations.integrations}
            target="_blank"
            rel="noopener"
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2 }}
          >
            {translate('integrations')}
          </MenuItem>
          <MenuItem
            component={Link}
            href={PATH_HUB.settings.userManagement.organization}
            target="_blank"
            rel="noopener"
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2 }}
          >
            {translate('user_management')}
          </MenuItem> */}
        </Stack>
      </MenuPopover>
    </>
  );
}
