import { FilterApiKey, TaskApiParams, TaskPriority, TaskSetting, TaskStatus } from 'src/@types/tasks';
import axios from '../utils/axios';
import { utcToZonedTime } from 'date-fns-tz';
import { SortDirection } from 'src/@types/booking-list';
import { FilterValue } from 'src/@types/filter';

function tasksAPI() {
  return {
    fetchTaskStatus: () => axios.get('/apis/task/tasks_landing_page/get_task_status'),
    fetchNotifications: () => axios.get('/apis/task/tasks_landing_page/get_smart_notifications'),
    fetchTaskAmount: (from: string, to: string) => {
      const url = 'apis/tasks_landing_page/tasks_numbers';
      return axios.get(url, { params: { from, to } });
    },
    fetchFilterTypeOptions: () => axios.get('/apis/task/filter/type'),
    fetchFilterOptions: (filterApiKey: FilterApiKey, page: number, name: string, limit: number) => {
      const url = `/apis/filter/${filterApiKey}`;
      return axios.get(url, {
        params : {
          limit,
          page: page === undefined ? page : page + 1,
          name
        }
      });
    },
    fetchUnassignedTasks: (days: number) => {
      const url = '/apis/task/tasks_landing_page/get_unassigned_tasks';
      return axios.get(url, { params: { days } });
    },
    fetchEarlyCheckInTasks: (days: number) => {
      const url = '/apis/task/tasks_landing_page/get_early_checkin_tasks';
      return axios.get(url, { params: { days } });
    },
    HousekeepingTasksSetting: (settings: TaskSetting[]) => {
      const url = `/apis/task_list/settings`;
      return axios.post(url, { key: 'settings', settings });
    },
    updateTaskAutoApproval:(brandId: number, autoApproval: boolean)=>{
      return axios.post(`/apis/task/change_auto_approval`, {
        brand_id: brandId,
        auto_approval: autoApproval
      })
    },
    updateTaskMandatoryHousekeepingPhotos: (brandId: number, mandatoryHousekeepingPhotos: boolean) => {
      return axios.post(`/apis/task/change_mandatory_housekeeping_photos`, {
        brand_id: brandId,
        mandatory_housekeeping_photos: mandatoryHousekeepingPhotos
      })
    },
    updateTaskMidStayHousekeepingPhotos: (brandId: number, midStayHousekeepingPhotos: number) =>{
    return axios.post(`/apis/task/change_mid_stay_housekeeping_period`, {
      brand_id: brandId,
      mid_stay_housekeeping_period: midStayHousekeepingPhotos
    })
  },
    fetchSetting: (key: string) => axios.get('/apis/task_list/retrieve_settings', { params: { key } }),
    fetchOrganizationBrands: () => axios.get('/apis/organization/all_brands'),
    fetchHousekeepingTasks: ({
      limit, page = 1, timeFrameOrder, showCancelled, showOnlyUnassigned, showOnlyEarlyCheckIn, showOnlyNotCompleted,
      booking, cities, property, unit, assignee, status, priority, type, taskType, dueDateFrom, dueDateTo, signal,
    }: TaskApiParams) => {
      const url = `/apis/v2/tasks/housekeeping?${type || ''}`;
      return axios.get(url, {
        signal,
        params: {
          'page': page,
          'limit': limit,
          'type': taskType,
          'booking.id': booking,
          ...(Array.isArray(cities) && {
            'booking.property.area.city.id': cities.map((type) => type.value),
          }),
          'booking.property.id': property,
          'booking.unit.id': unit,
          'owner.id': assignee,
          'progressStatus': status,
          'priority': priority,
          ...(dueDateFrom && { 'not_sooner[after]': utcToZonedTime(dueDateFrom, 'UTC') }),
          ...(dueDateTo && { 'not_sooner[before]': utcToZonedTime(dueDateTo, 'UTC') }),
          'order[not_sooner]': timeFrameOrder,
          ...(showOnlyUnassigned && { unassigned: '' }),
          ...(showOnlyEarlyCheckIn && { early_checkin: '' }),
          'status[neq]': [
            ...(showOnlyNotCompleted ? ['completed'] : []),
            ...(showCancelled ? [] : ['cancelled']),
        ],
        },
      });
    },
    fetchTask: (taskId: number) => axios.get(`/apis/tasks_view/get_task_setting/${taskId}`),
    addNote: (taskId: number, text: string) => {
      const url = `/apis/tasks_view/add_note/${taskId}`;
      return axios.post(url, { text });
    },
    editNote: (taskId: number, noteId: number | null, text: string) => {
      const url = `/apis/tasks_view/edit_note/${taskId}/${noteId}`;
      return axios.put(url, { text });
    },
    deleteNote: (taskId: number, noteId: number) => axios.delete(`/apis/tasks_view/note/${taskId}/${noteId}`),
    fetchHousekeepers: (page: number, search_key: string) => {
      const url = `/apis/housekeepers`;
      return axios.get(url, {
        params: { page, search_key }
      });
    },
    changeTaskPriority: (taskId: number, priority: TaskPriority) => {
      const url = `/apis/tasks_view/change_priority/${taskId}`;
      return axios.put(url, { priority });
    },
    changeTaskStatus: (taskId: number, status: TaskStatus, started_at?: string, finished_at?: string) => {
      const url = `/apis/tasks_view/change_status/${taskId}`;
      return axios.put(url, { status, started_at, finished_at });
    },
    approveTask: (taskId: number) => {
      const url = `/apis/tasks_view/approve_task/${taskId}`;
      return axios.put(url, { approved: true });
    },
    fetchStaffList: (task_id?: number, property_id?: number, unit_id?: number, key: 'staff' | 'company' = 'staff') => {
      const url = task_id ? '/apis/tasks_view/assigned_to' : '/apis/tasks_view/create_new_task_staff';
      return axios.get(url, { params: { task_id, property_id, unit_id, key } });
    },
    changeTaskAssignee: (taskId: number, housekeeperId: number | null) => {
      const url = `/apis/tasks_view/change_assignee/${taskId}`;
      return axios.put(url, { id: housekeeperId });
    },
    changeTaskTimeFrame: (taskId: number, can_start_from: string, must_finish_by: string, estimation: number) => {
      const url = `/apis/tasks_view/change_time_frame/${taskId}`;
      return axios.put(url, { can_start_from, must_finish_by, estimation });
    },
    createTask: (body: {
      property_id: number,
      unit_id: number,
      can_start_from: string,
      must_finish_by: string,
      estimation: number,
      housekeeper_id?: number,
      priority: TaskPriority,
      note?: string,
    }) => axios.post('/apis/tasks_view/create_task', body),
    reopenTaskManually: (
      taskId: number,
      body: {
        can_start_from: string,
        must_finish_by: string,
        estimation: number,
        housekeeper_id?: number,
        priority: TaskPriority,
        note?: string,
      },
    ) => axios.put(`/apis/tasks_view/reopen_manually_task/${taskId}`, body),
    reopenTaskAutomatically: (taskId: number) => axios.put(`/apis/tasks_view/reopen_automatically_task/${taskId}`),
    fetchMaintenanceTasks: ({ page, limit, order, signal, filtersState, advanceFilterParam }: {
      page: number;
      limit: number;
      order?: { field: string; sort: SortDirection };
      signal?: AbortSignal;
      filtersState: {
        priorities?: FilterValue[],
        statuses?: FilterValue[],
        types?: FilterValue[],
        cities?: FilterValue[],
        show_completed?: boolean,
      };
      advanceFilterParam?: { key: string; value: string };
    }) =>
      axios.get('/apis/list/maintenanceTasks?maintenanceTask', {
        signal,
        params: {
          page,
          limit,
          ...(order && {[`order[${order.field}]`]: order.sort}),
          ...(advanceFilterParam && {[`${advanceFilterParam.key}`]: advanceFilterParam.value}),
          ...(!Boolean(filtersState.show_completed) && {
            progressStatus: 'not_started',
          }),
          ...(Array.isArray(filtersState.statuses) && filtersState.statuses.length > 0 && {
            progressStatus: filtersState.statuses.map((status) => status.value),
          }),
          ...(Array.isArray(filtersState?.priorities) && {
            priority: filtersState.priorities.map((priority) => priority.value),
          }),
          ...(Array.isArray(filtersState?.types) && {
            type: filtersState.types.map((type) => type.value),
          }),
          ...(Array.isArray(filtersState?.cities) && {
            'property.area.city.id': filtersState.cities.map((type) => type.value),
          }),
        },
      }),
    fetchTasks: ({ page, limit, order, signal, filtersState, advanceFilterParam, type }: {
      page: number;
      limit: number;
      order?: { field: string; sort: SortDirection };
      signal?: AbortSignal;
      filtersState: {
        priorities?: FilterValue[],
        statuses?: FilterValue[],
        types?: FilterValue[],
      };
      advanceFilterParam?: { key: string; value: string };
      type?: 'payment_reminder' | string;
    }) =>
      axios.get(`/apis/v2/tasks/housekeeping${type ? '?' + type : ''}`, {
        signal,
        params: {
          page,
          limit,
          ...(order && {[`order[${order.field}]`]: order.sort}),
          ...(advanceFilterParam && {[`${advanceFilterParam.key}`]: advanceFilterParam.value}),
          ...(Array.isArray(filtersState.statuses) && filtersState.statuses.length > 0 && {
            progressStatus: filtersState.statuses.map((status) => status.value),
          }),
          ...(Array.isArray(filtersState?.priorities) && filtersState.priorities.length > 0 && {
            priority: filtersState.priorities.map((priority) => priority.value),
          }),
          ...(Array.isArray(filtersState?.types) && filtersState.types.length > 0 && {
            type: filtersState.types.map((type) => type.value),
          }),
        },
      }),
  };
}

export default tasksAPI();
