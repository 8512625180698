import { Folder } from 'src/@types/unified-inbox';
import axios from '../utils/axios';

function UnifiedInboxApi() {
  return {
    fetchConversations: (
      filter,
      page,
      search,
      hrefConversationId,
      folderId?: number,
      signal?: AbortSignal,
      offset: number = 15,
    ) => {
      const url = '/apis/inbox/conversations';
      return axios.get(url, {
        signal,
        params: { 
          page,
          search,
          offset,
          prefix: 'in',
          key_word: filter,
          conversation_id: hrefConversationId,
          folder_id: folderId,
        },
      });
    },
    getContactsOfOrganizationRoute: (page, key, withBooking) => {
      const url = '/apis/inbox/get-contacts-of-organization';
      return axios.get(`${url}?offset=15&page=${page}&key=${encodeURIComponent(key)}&withBooking=${withBooking}`);
    },
    createAndUpdateContactRoute: (body) => axios.post('/apis/inbox/create-update-contact', body),
    createNewConversationRoute: (body) => axios.post('/apis/inbox/create-new-conversation', body),
    getBookingDetailsRoute: (id) => {
      const url = '/apis/inbox/booking';
      return axios.get(`${url}/${id}/details`);
    },
    getConversationMessagesRoute: (id, contactId) => {
      const url = '/apis/inbox/conversation';
      return axios.get(`${url}/${id}/messages?contact_id=${contactId}`);
    },
    getUserSenderEmailsRoute: (id) => {
      const url = '/apis/inbox/get-sender-emails';
      return axios.get(`${url}?conversation_id=${id}`);
    },
    getCcBccEmailsRoute: (key, page) => {
      const url = '/apis/inbox/get-email-addresses';
      return axios.get(`${url}?key=${key}&page=${page}&offset=10`);
    },
    // getGuestProfileRoute(id) {
    //   return `/booking/guest/${id}`;
    // },
    getSeenMessagesRoute: (body) => axios.post('/apis/inbox/seen_messages', body),
    getSeenConversationRoute: (body) => axios.post('/apis/inbox/seen_conversation', body),
    getUnseenConversationRoute: (body) => axios.post('/apis/inbox/unseen_conversation', body),
    getCurrentUserInformation: () => axios.get('/apis/inbox/user'),
    getSendEmailRoute: (body) => axios.post<{ message_id: number }>('/apis/inbox/send-email', body),
    getSendSmsRoute: (body) => axios.post<{ message_id: number }>('/apis/inbox/send-sms', body),
    getSendApiMessageRoute: (body) => axios.post<{ message_id: number }>('/apis/inbox/send-api', body),
    moveToTrash: (body) => axios.post('/apis/inbox/move-to-trash', body),
    removeFromTrash: (body) => axios.post('/apis/inbox/undo-trash', body),
    addToArchive: (body) => axios.post('/apis/inbox/move-to-archive', body),
    removeFromArchive: (body) => axios.post('/apis/inbox/undo-archive', body),
    // getSearchRoute() {
    //   return '/apis/inbox/search';
    // },
    getContactsWithGuestRoute: (page, key, withBooking) => {
      const url = '/apis/inbox/get-contact-with-guest';
      return axios.get(`${url}?offset=15&page=${page}&key=${encodeURIComponent(key)}&withBooking=${withBooking}`);
    },
    getBookingsWithContactRoute: (contactId) => {
      const url = '/apis/inbox/get-booking-with-contact';
      return axios.get(`${url}?id=${contactId}`);
    },
    getBookingsWithCodeRoute: (page, bookingCode) => {
      const url = '/apis/inbox/get-bookings-with-code';
      return axios.get(`${url}?offset=15&page=${page}&booking_code=${bookingCode}`);
    },
    getContactWithBookingRoute: (bookingId) => {
      const url = '/apis/inbox/get-contact-with-booking';
      return axios.get(`${url}?booking_id=${bookingId}`);
    },
    getConversationWithBookingRoute: (bookingId) => {
      const url = '/apis/inbox/get-conversation-with-booking';
      return axios.get(`${url}?booking_id=${bookingId}`);
    },
    getEmailTemplatesRoute(data) {
      const url = '/apis/inbox/get-email-templates';
      return axios.get(`${url}?offset=15&page=${data.page}&key_word=${data.keyword}&type=${data.type}`);
    },
    getEmailTemplatesRouteV2(type) {
      const url = '/apis/inbox/v2/get-email-templates';
      return axios.get(url, {
        params: { type }
      });
    },
    getAutomatedVariables(bookingId: number) {
      const url = '/apis/automated_message/v1/get_variables';
      return axios.get(url, {
        params: { /*id: bookingId,*/ type: 'automated_message' }
      });
    },
    getLanguages: () => axios.get('/apis/automated_message/v1/get_language'),
    getRenderedEmailRoute(templateId: number, bookingId: number) {
      const url = '/apis/inbox/render-email-template';
      return axios.get(url, {
        params: { 
          template_id: templateId,
          booking_id: bookingId,
        }
      })
    },
    getRenderedEmailRouteV2(templateId: number, bookingId: number) {
      const url = '/apis/inbox/v2/render-email-template';
      return axios.get(url, {
        params: { 
          template_id: templateId,
          booking_id: bookingId,
        }
      });
    },
    getSaveDraftRoute(conversationId, body) {
      const url = '/apis/inbox/save-draft';
      return axios.post(`${url}/${conversationId}`, body);
    },
    getDeleteDraftRoute(conversationId) {
      const url = '/apis/inbox/delete-draft';
      return axios.delete(`${url}/${conversationId}`);
    },
    getFolders() {
      return axios.get<Folder[]>('/apis/inbox/get_folder');
    },
    createFolder(name: string) {
      return axios.post<{ result: string, id: number }>(`/apis/inbox/create_folder?name=${name}`);
    },
    checkExistFolder(name: string) {
      return axios.get<{ result: boolean }>(`/apis/inbox/check_exist_folder?name=${name}`);
    },
    renameFolderName(id: number, newName: string) {
      return axios.get<{ result: boolean }>(`/apis/inbox/check_exist_folder/${id}?name=${newName}`);
    },
    deleteFolder(id: number) {
      return axios.delete(`/apis/inbox/delete_folder/${id}`);
    },
    moveToFolder(conversationId: number, folderId: number) {
      return axios.post('/apis/inbox/move_to_folder', { conversationId, folderId });
    },
    undoFromFolder(conversationId: number) {
      return axios.post('/apis/inbox/undo_folder', { conversationId });
    },
    changeDefaultFolder(id: number | null) {
      return axios.post('/apis/inbox/change_default_state', { id, is_default: true });
    },
    getNumberOfUnseenMessages() {
      return axios.get('/apis/inbox/number_of_unseen_messages');
    }
  }
}

export default UnifiedInboxApi();