// hooks
import useAuth from '../hooks/useAuth';
// utils
//
import Avatar, { Props as AvatarProps } from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: AvatarProps) {
  const { user } = useAuth();

  return (
    <Avatar
      src={user?.avatar}
      // alt={user?.firstName}
      // color={user?.avatar ? 'default' : createAvatar(`${user?.firstName} ${user?.lastName}`).color}
      {...other}
    >
      {/* {createAvatar(`${user?.firstName} ${user?.lastName}`).name} */}
    </Avatar>
  );
}
