import { Theme } from '@mui/material/styles';
import { AccordionExpandIcon } from './CustomIcons';

// ----------------------------------------------------------------------

export default function Accordion(theme: Theme) {
  return {
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: 'transparent'
          },
          '&::before': {
            display: 'none'
          }
        }
      }
    },
    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: <AccordionExpandIcon />,
      },
      styleOverrides: {
        root: {
          ...theme.typography.subtitle2,
          minHeight: 32,
          '&.Mui-expanded': { minHeight: 32 },
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(1),
          '&.Mui-disabled': {
            opacity: 1,
            color: theme.palette.action.disabled,
            '& .MuiTypography-root': {
              color: 'inherit'
            }
          }
        },
        expandIconWrapper: {
          color: 'inherit',
          '&.Mui-expanded': {
            color: theme.palette.primary.main,
          }
        },
        content: {
          marginTop: theme.spacing(0.5),
          marginBottom: theme.spacing(0.5),
          '&.Mui-expanded': {
            marginTop: 'unset',
            marginBottom: 'unset',
          },
        }
      }
    }
  };
}
