import { Button, ButtonProps, Box, IconButton } from '@mui/material'
import { FC } from 'react'
import Iconify from 'src/components/Iconify';
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
import { ColorSchema } from 'src/theme/palette';

interface Props extends ButtonProps {
  expanded: boolean;
  title?: string;
  expandedTitle?: string;
  expandedColor?: ColorSchema | 'inherit';
  disableResponsive?: boolean;
  showEndIcon?:boolean;
};

const ExpandButton: FC<Props> = ({ expanded, title, expandedTitle, expandedColor, disableResponsive = false, showEndIcon = false, ...other }) => {
  const { translate } = useLocales('common');
  const isMobile = useResponsive('down', 'sm');
  return (
    <Box color="text.secondary">
      {isMobile && !disableResponsive ? (
        <IconButton
          size="small"
          color={expanded ? expandedColor || 'primary' : 'inherit'}
          {...other}
        >
          <Iconify icon={`mdi:chevron-${expanded ? 'up' : 'down'}`} />
        </IconButton>
      ) : (
        <Button
          size="small"
          color={expanded ? expandedColor || 'info' : 'inherit'}
          {...showEndIcon && { endIcon: <Iconify icon={`ic:round-expand-${expanded ? 'less' : 'more'}`} /> }}
          {...other}
        >
          {expanded ? expandedTitle || translate('hide_details') : title || translate('show_details')}
        </Button>
      )}
    </Box>
  )
}

export default ExpandButton;