
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Link,
  LinkProps,
  ListItemText,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemButtonProps,
  IconButton, SxProps,
} from '@mui/material';
// config
import {
  DASHBOARD_NAVBAR_SUB_ITEM_HEIGHT,
  DASHBOARD_NAVBAR_ICON_ITEM_SIZE,
} from '../../config';
//
import Iconify from '../Iconify';
// type
import { NavItemProps } from './type';
import useLocales from "../../hooks/useLocales";
import SvgIconStyle from '../SvgIconStyle';
// utils
import { handleOpenNewWindow } from "src/utils/navigation";

// ----------------------------------------------------------------------

type IProps = LinkProps & ListItemButtonProps;

interface ListItemStyleProps extends IProps {
  to?: string;
  activeRoot?: boolean;
  activeSub?: boolean;
  subItem?: boolean;
}

const ListItemStyle = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'activeRoot' && prop !== 'activeSub' && prop !== 'subItem',
})<ListItemStyleProps>(({ activeRoot, activeSub, subItem, theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.common.white,
  },
  ...theme.typography.body,
  position: 'relative',
  width: '100%',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  marginBottom: theme.spacing(0.5),
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  // activeRoot
  ...(activeRoot && {
    ...theme.typography.subtitle2,
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  }),
  // activeSub
  ...(activeSub && {
    ...theme.typography.subtitle2,
    color: theme.palette.text.primary,
  }),
  // subItem
  ...(subItem && {
    height: DASHBOARD_NAVBAR_SUB_ITEM_HEIGHT,
  }),
}));

interface ListItemTextStyleProps extends ListItemButtonProps {
  isCollapse?: boolean;
}

const ListItemTextStyle = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'isCollapse',
})<ListItemTextStyleProps>(({ isCollapse, theme }) => ({
  transition: theme.transitions.create(['width', 'opacity'], {
    duration: theme.transitions.duration.shorter,
  }),
  ...(isCollapse && {
    width: 0,
    opacity: 0,
  }),
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: DASHBOARD_NAVBAR_ICON_ITEM_SIZE,
  height: DASHBOARD_NAVBAR_ICON_ITEM_SIZE,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': { width: '100%', height: '100%' },
});

// ----------------------------------------------------------------------

const isExternalLink = (path: string) => path.includes('http');
const getIcon = (name: string) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }}/>
);

export function NavItemRoot({ item, isCollapse, open = false, active, onOpen, setOpen }: NavItemProps) {
  const { title, subTitle, path, icon, info, children } = item;
  const { translate } = useLocales('common')

  const renderContent = (
    <>
      {icon && <ListItemIconStyle sx={{ mr: isCollapse ? 0 : 1.5 }}>{getIcon(icon)}</ListItemIconStyle>}
      <ListItemTextStyle title={translate(title)} primary={translate(title)}
                         secondary={subTitle && `(${translate(subTitle)})`}
                         isCollapse={isCollapse}
                         primaryTypographyProps={{ variant: 'body', color: 'text.primary' }}
                         secondaryTypographyProps={{ variant: 'caption', color: 'text.secondary' }}/>
      {!isCollapse && (
        <>
          {info && info}
          {/* {children && <ArrowIcon open={open} />} */}
        </>
      )}
    </>
  );

  if (children) {
    return path !== '' ? (
      <ListItem
        sx={{ p: 0 }}
        secondaryAction={
          !isCollapse && (
            <IconButton edge="end" onClick={onOpen} sx={{ top: -1 }}>
              <ArrowIcon open={open}/>
            </IconButton>
          )
        }
      >
        <ListItemStyle component={RouterLink} to={path} activeRoot={active} onClick={setOpen}>
          {renderContent}
        </ListItemStyle>
      </ListItem>
    ) : (
      <ListItem
        sx={{ p: 0 }}
        secondaryAction={
          !isCollapse && (
            <IconButton edge="end" onClick={onOpen} sx={{ top: -1 }}>
              <ArrowIcon open={open}/>
            </IconButton>
          )
        }
      >
        <ListItemStyle activeRoot={active} onClick={setOpen}>
          {renderContent}
        </ListItemStyle>
      </ListItem>
    );
  }

  return isExternalLink(path) ? (
    <ListItemStyle component={Link} href={path} target="_blank" rel="noopener">
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle component={RouterLink} to={path} activeRoot={active}>
      {renderContent}
    </ListItemStyle>
  );
}

// ----------------------------------------------------------------------

type NavItemSubProps = Omit<NavItemProps, 'isCollapse'>;

export function NavItemSub({ item, open = false, active, onOpen }: NavItemSubProps) {
  const { title, subTitle, path, info, newWindow, children } = item;
  const { translate } = useLocales('common')

  const renderContent = (
    <>
      {/* <DotIcon active={active} /> */}
      <ListItemTextStyle
        primary={translate(title)}
        secondary={subTitle && `(${translate(subTitle)})`}
        primaryTypographyProps={{ variant: 'body2', color: 'text.primary' }}
        secondaryTypographyProps={{ variant: 'caption', color: 'text.secondary' }}
      />
      {info && info}
      {children && (
        <IconButton edge="end" onClick={onOpen} sx={{ top: -1 }}>
          <ArrowIcon open={open}/>
        </IconButton>
      )}
    </>
  );

  if (children) {
    return (
      <ListItemStyle onClick={onOpen} activeSub={active} subItem>
        {renderContent}
      </ListItemStyle>
    );
  }

  return isExternalLink(path) ? (
    newWindow ? (
      <ListItemStyle onClick={() => handleOpenNewWindow(path)} subItem>
        {renderContent}
      </ListItemStyle>
    ) : (
      <ListItemStyle component={Link} href={path} target="_blank" rel="noopener" subItem>
        {renderContent}
      </ListItemStyle>
    )
  ) : (
    <ListItemStyle component={RouterLink} to={path} activeSub={active} subItem>
      {renderContent}
    </ListItemStyle>
  );
}

// ----------------------------------------------------------------------

type DotIconProps = {
  active: boolean;
  sx?: SxProps;
  bulletSx?: SxProps;
};

export function DotIcon({ active, sx, bulletSx }: DotIconProps) {
  return (
    <ListItemIconStyle sx={{ ...sx }}>
      <Box
        component="span"
        sx={{
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: 'scale(2)',
            bgcolor: 'primary.main',
          }),
          ...bulletSx
        }}
      />
    </ListItemIconStyle>
  );
}

// ----------------------------------------------------------------------

type ArrowIconProps = {
  open: boolean;
};

export function ArrowIcon({ open }: ArrowIconProps) {
  return (
    <Iconify
      icon={open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-forward-fill'}
      sx={{ width: 16, height: 16 }}
    />
  );
}
