import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
//
import enLocales from './en.json';
import deLocales from './de.json';

import commonEnLocales from './en/common.json';
import commonDeLocales from './de/common.json';
import authEnLocals from './en/auth.json';
import authDeLocals from './de/auth.json';
import accountEnLocales from './en/account.json';
import accountDeLocales from './de/account.json';
import calendarEnLocales from './en/calendar.json';
import calendarDeLocales from './de/calendar.json';
import automatedMessageEnLocales from './en/automatedMessage.json';
import automatedMessageDeLocales from './de/automatedMessage.json';
import unifiedInboxEnLocales from './en/unifiedInbox.json';
import unifiedInboxDeLocales from './de/unifiedInbox.json';
import tasksEnLocales from './en/tasks.json';
import tasksDeLocales from './de/tasks.json';
import bookingWizardEnLocales from './en/booking/wizard.json';
import bookingWizardDeLocales from './de/booking/wizard.json';
import bookingLandingPageEnLocals from './en/booking/landingPage.json';
import bookingLandingPageDeLocals from './de/booking/landingPage.json';
import confirmationPageSettingsEnLocals from './en/confirmationPageSettings.json';
import confirmationPageSettingsDeLocals from './de/confirmationPageSettings.json';
import listingsEnLocals from './en/listings.json';
import listingsDeLocals from './de/listings.json';
import upsellEnLocals from './en/upsell.json';
import upsellDeLocals from './de/upsell.json';
import rateEnLocals from './en/rate.json';
import rateDeLocals from './de/rate.json';
import rateRuleEnLocals from './en/rateRule.json';
import rateRuleDeLocals from './de/rateRule.json';
import reportEnLocals from './en/report.json';
import reportDeLocals from './de/report.json';
import bookingEnLocals from './en/booking/booking.json';
import bookingDeLocals from './de/booking/booking.json';
import preferenceEnLocales from './en/preference.json';
import preferenceDeLocales from './de/preference.json';
import changeHistoryEnLocals from './en/changeHistory.json';
import changeHistoryDeLocals from './de/changeHistory.json';
import taxEnLocals from './en/tax.json';
import taxDeLocals from './de/tax.json';
import marketingWebsiteEnLocales from './en/marketingWebsite.json';
import marketingWebsiteDeLocals from './de/marketingWebsite.json';
import guestEnLocales from './en/guest.json';
import guestDeLocales from './de/guest.json';
import bookingListEnLocales from './en/booking/list.json';
import bookingListDeLocales from './de/booking/list.json';
import blockedDatesEnLocales from './en/blockedDates.json';
import blockedDatesDeLocales from './de/blockedDates.json';
import organizationConfigurationEn from 'src/locales/en/organizationConfig.json';
import organizationConfigurationDe from 'src/locales/de/organizationConfig.json';
import investorDealEnLocales from './en/investorDeal.json';
import investorDealDeLocales from './de/investorDeal.json';
import invoiceListEnLocales from './en/invoiceList.json';
import invoiceListDeLocales from './de/invoiceList.json';
import expenseListEnLocales from './en/expenseList.json';
import expenseListDeLocales from './de/expenseList.json';
import paymentEnLocales from './en/payment.json';
import paymentDeLocales from './de/payment.json';
import refundsEnLocales from './en/refunds.json';
import refundsDeLocales from './de/refunds.json';
import staffListEnLocales from './en/staffList.json';
import staffListDeLocales from './de/staffList.json';
import marketingEnLocales from './en/marketing.json';
import marketingDeLocales from './de/marketing.json';
import bookingRequestEnLocals from './en/bookingRequest.json';
import bookingRequestDeLocals from './de/bookingRequest.json';
import investorEnLocals from './en/investor.json';
import investorDeLocals from './de/investor.json';
import monthlyProfitReportEnLocales from './en/monthlyProfitReport.json';
import monthlyProfitReportDeLocales from './de/monthlyProfitReport.json';
import invoiceEnLocals from './en/invoice.json';
import invoiceDeLocals from './de/invoice.json';
import managementPackagesEnLocales from './en/managementPackages.json';
import managementPackagesDeLocales from './de/managementPackages.json';
import promotionEnLocales from './en/promotion.json';
import promotionDeLocales from './de/promotion.json';
import issuesEnLocales from './en/issues.json';
import issuesDeLocales from './de/issues.json';
import handsDeLocales from './de/hands.json';
import handsEnLocales from './en/hands.json';
import suppliersEnLocales from './en/suppliers.json';
import suppliersDeLocales from './de/suppliers.json';
import leavesEnLocales from './en/leaves.json';
import leavesDeLocales from './de/leaves.json';
// ----------------------------------------------------------------------

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations: enLocales,
        common: commonEnLocales,
        auth: authEnLocals,
        account: accountEnLocales,
        calendar: calendarEnLocales,
        automatedMessage: automatedMessageEnLocales,
        unifiedInbox: unifiedInboxEnLocales,
        tasks: tasksEnLocales,
        bookingWizard: bookingWizardEnLocales,
        bookingLandingPage: bookingLandingPageEnLocals,
        confirmationPageSettings: confirmationPageSettingsEnLocals,
        listings: listingsEnLocals,
        upsell: upsellEnLocals,
        rate: rateEnLocals,
        rateRule: rateRuleEnLocals,
        report: reportEnLocals,
        booking: bookingEnLocals,
        preference: preferenceEnLocales,
        changeHistory: changeHistoryEnLocals,
        tax: taxEnLocals,
        marketingWebsite: marketingWebsiteEnLocales,
        guest: guestEnLocales,
        bookingList: bookingListEnLocales,
        blockedDates: blockedDatesEnLocales,
        orgConfig: organizationConfigurationEn,
        investorDeal: investorDealEnLocales,
        invoiceList: invoiceListEnLocales,
        expenseList: expenseListEnLocales,
        payment: paymentEnLocales,
        refunds: refundsEnLocales,
        staffList: staffListEnLocales,
        marketing: marketingEnLocales,
        bookingRequest: bookingRequestEnLocals,
        investor: investorEnLocals,
        monthlyProfitReport: monthlyProfitReportEnLocales,
        invoice: invoiceEnLocals,
        managementPackages: managementPackagesEnLocales,
        promotion: promotionEnLocales,
        issues: issuesEnLocales,
        hands: handsEnLocales,
        suppliers: suppliersEnLocales,
        leaves: leavesEnLocales,
      },
      de: {
        translations: deLocales,
        common: commonDeLocales,
        auth: authDeLocals,
        account: accountDeLocales,
        calendar: calendarDeLocales,
        automatedMessage: automatedMessageDeLocales,
        unifiedInbox: unifiedInboxDeLocales,
        tasks: tasksDeLocales,
        bookingWizard: bookingWizardDeLocales,
        bookingLandingPage: bookingLandingPageDeLocals,
        confirmationPageSettings: confirmationPageSettingsDeLocals,
        listings: listingsDeLocals,
        upsell: upsellDeLocals,
        rate: rateDeLocals,
        rateRule: rateRuleDeLocals,
        report: reportDeLocals,
        booking: bookingDeLocals,
        preference: preferenceDeLocales,
        changeHistory: changeHistoryDeLocals,
        tax: taxDeLocals,
        marketingWebsite: marketingWebsiteDeLocals,
        guest: guestDeLocales,
        bookingList: bookingListDeLocales,
        blockedDates: blockedDatesDeLocales,
        orgConfig: organizationConfigurationDe,
        investorDeal: investorDealDeLocales,
        invoiceList: invoiceListDeLocales,
        expenseList: expenseListDeLocales,
        payment: paymentDeLocales,
        refunds: refundsDeLocales,
        staffList: staffListDeLocales,
        marketing: marketingDeLocales,
        bookingRequest: bookingRequestDeLocals,
        investor: investorDeLocals,
        monthlyProfitReport: monthlyProfitReportDeLocales,
        invoice: invoiceDeLocals,
        managementPackages: managementPackagesDeLocales,
        promotion: promotionDeLocales,
        issues: issuesDeLocales,
        hands: handsDeLocales,
        suppliers: suppliersDeLocales,
        leaves: leavesDeLocales,
      },
    },
    lng: localStorage.getItem('i18nextLng') || 'en',
    fallbackLng: 'en',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
