import SimpleBarReact, { Props as ScrollbarProps } from 'simplebar-react';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, SxProps, Theme } from '@mui/material';
import { sxConvertor } from 'src/utils/sxConvertor';
import { useEffect, useRef, useState } from 'react';

// ----------------------------------------------------------------------

const RootStyle = styled('div', {
  shouldForwardProp: (prop) => prop !== 'showMask'
})<{ showMask: boolean }>(({ showMask }) => ({
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
  ...(showMask && {
    "-webkit-mask-image": 'linear-gradient(rgba(0,0,0,0.86) 85%, rgba(0,0,0,0.66) 87%, transparent)',
  })
}));

const SimpleBarStyle = styled(SimpleBarReact)(({ theme }) => ({
  maxHeight: '100%',
  '& .simplebar-scrollbar': {
    '&:before': {
      backgroundColor: alpha(theme.palette.grey[600], 0.48),
      width: 6,
    },
    '&.simplebar-visible:before': {
      opacity: 1,
    },
  },
  '& .simplebar-track.simplebar-vertical': {
    width: 12,
  },
  '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
    height: 12,
  },
  '& .simplebar-mask': {
    zIndex: 'inherit',
  },
}));

// ----------------------------------------------------------------------

interface Props extends ScrollbarProps {
  sx?: SxProps<Theme>;
  showMask?: boolean;
  disableHidingMask?: boolean;
}

export default function Scrollbar({ children, showMask = false, disableHidingMask = false, sx, ...other }: Props) {
  const ref = useRef<SimpleBarReact>(null);
  const [reachedBottom, setReachedBottom] = useState(false);
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  useEffect(() => {
    const scrollElement = ref?.current?.getScrollElement();
    if (scrollElement) {
      const  scrollHandler = (event) => {
        const { scrollHeight, scrollTop, clientHeight } = event.target;
        const isBottomReached = (scrollHeight - Math.round(scrollTop + 5) <= clientHeight);
        setReachedBottom(isBottomReached);
      }
      scrollHandler({ target: scrollElement });
      scrollElement?.addEventListener('scroll', scrollHandler, { passive: true });
      return () => scrollElement?.removeEventListener('scroll', scrollHandler);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  if (isMobile) {
    return (
      <Box sx={[{ overflowX: 'auto' }, ...sxConvertor(sx)]} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <RootStyle showMask={showMask && (disableHidingMask || !reachedBottom)}>
      <SimpleBarStyle ref={ref} timeout={500} autoHide={false} clickOnTrack={false} sx={sx} {...other}>
        {children}
      </SimpleBarStyle>
    </RootStyle>
  );
}
