import { Button, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DialogBox from 'src/components/dialog-box/DialogBox';
import useLocales from 'src/hooks/useLocales';

type Props = {
  action: 'dismiss' | 'publish' | '';
  isOpen: boolean;
  isLoading: boolean;
  setClose: VoidFunction;
  publishOrDismiss: (approved: boolean) => void;
};

export default function DismissPublishDialog({
  action,
  isOpen,
  isLoading,
  setClose,
  publishOrDismiss,
}: Props) {
  const isActionDismiss = !!(action && action === 'dismiss');
  const { translate } = useLocales(['listings', 'common']);

  return (
    <DialogBox
      severity="warning"
      title={
        <Stack>
          {translate(isActionDismiss ? 'dismiss_review' : 'publish_review')}
          <Typography mt={2} mb={1}>
            {isActionDismiss
              ? translate('dismiss_review_description')
              : translate('publish_review_description')}
          </Typography>
        </Stack>
      }
      openState={isOpen}
      DialogProps={{
        fullWidth: true,
        maxWidth: 'sm',
        onClose: () => setClose(),
      }}
      ContentProps={(theme) => ({ p: 0 })}
      action={
        <Stack direction="row" justifyContent="end" alignItems="center">
          <Button onClick={() => setClose()} color="inherit">
            {translate('cancel')}
          </Button>
          <LoadingButton
            color="warning"
            loading={isLoading}
            onClick={() => publishOrDismiss(isActionDismiss ? false : true)}
            sx={(theme) => ({
              minWidth: '64px',
              minHeight: '36px',
            })}
          >
            {translate(isActionDismiss ? 'dismiss' : 'publish')}
          </LoadingButton>
        </Stack>
      }
    >
      <></>
    </DialogBox>
  );
}
